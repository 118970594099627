/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import demandCollectionActionTypes from './demandCollection.types';
import { fetchDemandLoanList, demandCollectionRevertAPI } from './demandCollection.service';

import {
	fetchDemandMemberLoanInfoSuccess,
	fetchDemandMemberLoanInfoFailure,
	fetchDemandMemberLoanInfoResponseChanged,
	updateDemandCollectionRevertResponseChanged,
	updateDemandCollectionRevertSuccess,
	updateDemandCollectionRevertFailure,
} from './demandCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START MEMBER LOAN LIST DETAILS  ******************** */
export function* getDemandMemberLoanList({ payload }) {
	try {
		const key = 'FetchDemandForTodayCollectionKey';
		const jsonData = {
			GroupOfficerID: payload.OfficerID,
			DatabaseName: payload.DatabaseName,
			RepayDate: payload.DemandDate,
			FromDate: payload.DemandDate,
			ToDate: payload.DemandDate,
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			LocationID: payload.CenterID,
			GroupID: payload.GroupID,
			UserLevel: payload.UserLevel,
			UserID: payload.UserID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchDemandLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchDemandMemberLoanInfoFailure(responseData.data));
		} else {
			yield put(fetchDemandMemberLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchDemandMemberLoanInfoFailure(error));
	}
}

export function* resetGetDemandMemberLoanListResponse() {
	yield put(fetchDemandMemberLoanInfoResponseChanged());
}

export function* onGetDemandMemberLoanListDetails() {
	yield takeLatest(
		demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_START,
		getDemandMemberLoanList,
	);
}

export function* onGetDemandMemberLoanListResponseReset() {
	yield takeLatest(
		demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_RESPONSE_RESET,
		resetGetDemandMemberLoanListResponse,
	);
}
/** *********************  END GET MEMBER LOAN LIST  ******************** */

/** *********************  START DEMAND COLLECTION REVERT  ******************** */
export function* revertDemandCollectionList({ payload }) {
	try {
		const key = 'RevertDemandForTodayCollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			CollectionDate: payload.collectionDate,
			ToDate: payload.collectionDate,
			CenterID: payload.centerID,
			RepayDate: payload.collectionDate,
			GroupID: payload.groupID,
			UserID: payload.userID,
			UserName: payload.userName,
			LoanIndexGroupMemberIDs: payload.loanIndexGroupMemberIDs,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(demandCollectionRevertAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateDemandCollectionRevertFailure(responseData.data));
		} else {
			yield put(updateDemandCollectionRevertSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateDemandCollectionRevertFailure(error));
	}
}

export function* resetDemandCollectionRevertResponse() {
	yield put(updateDemandCollectionRevertResponseChanged());
}

export function* onDemandCollectionRevert() {
	yield takeLatest(
		demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_START,
		revertDemandCollectionList,
	);
}

export function* onDemandCollectionRevertResponseReset() {
	yield takeLatest(
		demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_RESET,
		resetDemandCollectionRevertResponse,
	);
}
/** *********************  END DEMAND COLLECTION UPDATE  ******************** */

export function* demandCollectionSaga() {
	yield all([
		call(onGetDemandMemberLoanListDetails),
		call(onGetDemandMemberLoanListResponseReset),
		call(onDemandCollectionRevert),
		call(onDemandCollectionRevertResponseReset),
	]);
}
