const ReconciliationForQRTypes = {
	GET_QR_PAYMENTS_RECONCILIATION_LIST_START: 'GET_QR_PAYMENTS_RECONCILIATION_LIST_START',
	GET_QR_PAYMENTS_RECONCILIATION_LIST_SUCCESS: 'GET_QR_PAYMENTS_RECONCILIATION_LIST_SUCCESS',
	GET_QR_PAYMENTS_RECONCILIATION_LIST_FAILURE: 'GET_QR_PAYMENTS_RECONCILIATION_LIST_FAILURE',
	GET_QR_PAYMENTS_RECONCILIATION_LIST_RESET: 'GET_QR_PAYMENTS_RECONCILIATION_LIST_RESET',
	GET_QR_PAYMENTS_RECONCILIATION_LIST_CHANGED: 'GET_QR_PAYMENTS_RECONCILIATION_LIST_CHANGED',

	UPDATE_QR_PAYMENTS_RECONCILIATION_START: 'UPDATE_QR_PAYMENTS_RECONCILIATION_START',
	UPDATE_QR_PAYMENTS_RECONCILIATION_SUCCESS: 'UPDATE_QR_PAYMENTS_RECONCILIATION_SUCCESS',
	UPDATE_QR_PAYMENTS_RECONCILIATION_FAILURE: 'UPDATE_QR_PAYMENTS_RECONCILIATION_FAILURE',
	UPDATE_QR_PAYMENTS_RECONCILIATION_RESET: 'UPDATE_QR_PAYMENTS_RECONCILIATION_RESET',
	UPDATE_QR_PAYMENTS_RECONCILIATION_CHANGED: 'UPDATE_QR_PAYMENTS_RECONCILIATION_CHANGED',
};
export default ReconciliationForQRTypes;
