/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import RuleEngineActionTypes from './ruleEngine.types';
import { AllRuleEngineGet, AddRuleEngine, DeleteRuleEngine } from './ruleEngine.service';

import {
	getRuleEngineListSuccess,
	getRuleEngineListFailure,
	getRuleEngineListChanged,
	addRuleEngineListSuccess,
	addRuleEngineListFailure,
	addRuleEngineListChanged,
	deleteRuleEngineListSuccess,
	deleteRuleEngineListFailure,
	deleteRuleEngineListChanged,
} from './ruleEngine.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START GET RULE ENGINE LIST  ******************** */
export function* RuleEngineListGet({ payload }) {
	try {
		const key = 'getRuledetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			RuleEngineType: payload.activeTab,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllRuleEngineGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getRuleEngineListFailure(responseData.data));
		} else {
			yield put(getRuleEngineListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRuleEngineListFailure(error));
	}
}

export function* resetGetRuleEngineListResponse() {
	yield put(getRuleEngineListChanged());
}

export function* onGetRuleEngineListDetails() {
	yield takeLatest(RuleEngineActionTypes.GET_RULE_ENGINE_LIST_START, RuleEngineListGet);
}

export function* onGetRuleEngineListResponseReset() {
	yield takeLatest(
		RuleEngineActionTypes.GET_RULE_ENGINE_LIST_RESET,
		resetGetRuleEngineListResponse,
	);
}
/** *********************  END GET RULE ENGINE LIST ******************** */
/** *********************  START ADD RULE ENGINE LIST ******************** */
export function* RuleEngineListAdd({ payload }) {
	try {
		const key = 'addRuledetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			RuleEngineArrayData: JSON.stringify(payload.ruleEngineArrayData).replace(/"/g, "'"),
			RuleEngineType: payload.activeTab,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AddRuleEngine, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addRuleEngineListFailure(responseData.data));
		} else {
			yield put(addRuleEngineListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addRuleEngineListFailure(error));
	}
}

export function* resetAddRuleEngineListResponse() {
	yield put(addRuleEngineListChanged());
}

export function* onAddRuleEngineListDetails() {
	yield takeLatest(RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_START, RuleEngineListAdd);
}

export function* onAddRuleEngineListResponseReset() {
	yield takeLatest(
		RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_RESET,
		resetAddRuleEngineListResponse,
	);
}
/** *********************  END ADD RULE ENGINE LIST******************** */

/** *********************  START DELETE RULE ENGINE LIST ******************** */
export function* RuleEngineListDelete({ payload }) {
	try {
		const key = 'deleteRuleDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			RuleEngineID: payload.ruleEngineID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DeleteRuleEngine, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteRuleEngineListFailure(responseData.data));
		} else {
			yield put(deleteRuleEngineListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteRuleEngineListFailure(error));
	}
}

export function* resetDeleteRuleEngineListResponse() {
	yield put(deleteRuleEngineListChanged());
}

export function* onDeleteRuleEngineListDetails() {
	yield takeLatest(RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_START, RuleEngineListDelete);
}

export function* onDeleteRuleEngineListResponseReset() {
	yield takeLatest(
		RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_RESET,
		resetDeleteRuleEngineListResponse,
	);
}
/** *********************  END DELETE RULE ENGINE LIST******************** */

export function* ruleEngineSaga() {
	yield all([
		call(onGetRuleEngineListDetails),
		call(onGetRuleEngineListResponseReset),
		call(onAddRuleEngineListDetails),
		call(onAddRuleEngineListResponseReset),
		call(onDeleteRuleEngineListDetails),
		call(onDeleteRuleEngineListResponseReset),
	]);
}
