/** *** Collection Approval Page All the Types declared over here **** */
/** *** Version : 2.0.1 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Common Collection Update Types added **** */
/** *** Version : 2.0.2 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Branch EOD Check Types added **** */

const CollectionActionTypes = {
	GET_OFFICER_LIST_CENTERS_START: 'GET_OFFICER_LIST_CENTERS_START',
	GET_OFFICER_LIST_CENTERS_SUCCESS: 'GET_OFFICER_LIST_CENTERS_SUCCESS',
	GET_OFFICER_LIST_CENTERS_FAILURE: 'GET_OFFICER_LIST_CENTERS_FAILURE',
	GET_OFFICER_LIST_CENTERS_RESPONSE_RESET: 'GET_OFFICER_LIST_CENTERS_RESPONSE_RESET',
	GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED: 'GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED',

	GET_OFFICER_LIST_ARREAR_CENTERS_START: 'GET_OFFICER_LIST_ARREAR_CENTERS_START',
	GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS: 'GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS',
	GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE: 'GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE',
	GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET:
		'GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET',
	GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED:
		'GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED',

	GET_BRANCH_COLLECTION_USERS_LIST_START: 'GET_BRANCH_COLLECTION_USERS_LIST_START',
	GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS: 'GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS',
	GET_BRANCH_COLLECTION_USERS_LIST_FAILURE: 'GET_BRANCH_COLLECTION_USERS_LIST_FAILURE',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED',

	GET_CENTER_LIST_OFFICER_COLLECTIONS_START: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_START',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET:
		'GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED:
		'GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED',

	GET_BRANCH_TODAY_COLLECTION_DETAILS_START: 'GET_BRANCH_TODAY_COLLECTION_DETAILS_START',
	GET_BRANCH_TODAY_COLLECTION_DETAILS_SUCCESS: 'GET_BRANCH_TODAY_COLLECTION_DETAILS_SUCCESS',
	GET_BRANCH_TODAY_COLLECTION_DETAILS_FAILURE: 'GET_BRANCH_TODAY_COLLECTION_DETAILS_FAILURE',
	GET_BRANCH_TODAY_COLLECTION_DETAILS_RESET: 'GET_BRANCH_TODAY_COLLECTION_DETAILS_RESET',
	GET_BRANCH_TODAY_COLLECTION_DETAILS_CHANGED: 'GET_BRANCH_TODAY_COLLECTION_DETAILS_CHANGED',

	GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_START:
		'GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_START',
	GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_SUCCESS:
		'GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_SUCCESS',
	GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_FAILURE:
		'GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_FAILURE',
	GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_RESET:
		'GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_RESET',
	GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_CHANGED:
		'GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_CHANGED',

	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_START:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_START',
	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_SUCCESS:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_SUCCESS',
	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_FAILURE:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_FAILURE',
	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_RESET:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_RESET',
	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED',
	GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED_DATA:
		'GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED_DATA',

	GET_CENTER_COLLECTION_DENOMINATION_DETAILS_START:
		'GET_CENTER_COLLECTION_DENOMINATION_DETAILS_START',
	GET_CENTER_COLLECTION_DENOMINATION_DETAILS_SUCCESS:
		'GET_CENTER_COLLECTION_DENOMINATION_DETAILS_SUCCESS',
	GET_CENTER_COLLECTION_DENOMINATION_DETAILS_FAILURE:
		'GET_CENTER_COLLECTION_DENOMINATION_DETAILS_FAILURE',
	GET_CENTER_COLLECTION_DENOMINATION_DETAILS_RESET:
		'GET_CENTER_COLLECTION_DENOMINATION_DETAILS_RESET',
	GET_CENTER_COLLECTION_DENOMINATION_DETAILS_CHANGED:
		'GET_CENTER_COLLECTION_DENOMINATION_DETAILS_CHANGED',

	GET_ATTENDANCE_OFFICER_COLLECTION_START: 'GET_ATTENDANCE_OFFICER_COLLECTION_START',
	GET_ATTENDANCE_OFFICER_COLLECTION_SUCCESS: 'GET_ATTENDANCE_OFFICER_COLLECTION_SUCCESS',
	GET_ATTENDANCE_OFFICER_COLLECTION_FAILURE: 'GET_ATTENDANCE_OFFICER_COLLECTION_FAILURE',
	GET_ATTENDANCE_OFFICER_COLLECTION_RESET: 'GET_ATTENDANCE_OFFICER_COLLECTION_RESET',
	GET_ATTENDANCE_OFFICER_COLLECTION_CHANGED: 'GET_ATTENDANCE_OFFICER_COLLECTION_CHANGED',

	GET_BRANCH_CLOSED_LOAN_LIST_START: 'GET_BRANCH_CLOSED_LOAN_LIST_START',
	GET_BRANCH_CLOSED_LOAN_LIST_SUCCESS: 'GET_BRANCH_CLOSED_LOAN_LIST_SUCCESS',
	GET_BRANCH_CLOSED_LOAN_LIST_FAILURE: 'GET_BRANCH_CLOSED_LOAN_LIST_FAILURE',
	GET_BRANCH_CLOSED_LOAN_LIST_RESET: 'GET_BRANCH_CLOSED_LOAN_LIST_RESET',
	GET_BRANCH_CLOSED_LOAN_LIST_CHANGED: 'GET_BRANCH_CLOSED_LOAN_LIST_CHANGED',

	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_START:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_START',
	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_SUCCESS:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_SUCCESS',
	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_FAILURE:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_FAILURE',
	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_RESET:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_RESET',
	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED',
	GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED_DATA:
		'GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED_DATA',

	UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_START:
		'UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_START',
	UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_SUCCESS:
		'UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_SUCCESS',
	UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_FAILURE:
		'UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_FAILURE',
	UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_RESPONSE_RESET:
		'UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_RESPONSE_RESET',
	UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_CHANGED:
		'UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_CHANGED',

	UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_START:
		'UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_START',
	UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_SUCCESS:
		'UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_SUCCESS',
	UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_FAILURE:
		'UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_FAILURE',
	UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_RESPONSE_RESET:
		'UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_RESPONSE_RESET',
	UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_CHANGED:
		'UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_CHANGED',

	COMMON_COLLECTION_UPDATE_START: 'COMMON_COLLECTION_UPDATE_START',
	COMMON_COLLECTION_UPDATE_SUCCESS: 'COMMON_COLLECTION_UPDATE_SUCCESS',
	COMMON_COLLECTION_UPDATE_FAILURE: 'COMMON_COLLECTION_UPDATE_FAILURE',
	COMMON_COLLECTION_UPDATE_RESPONSE_RESET: 'COMMON_COLLECTION_UPDATE_RESPONSE_RESET',
	COMMON_COLLECTION_UPDATE_CHANGED: 'COMMON_COLLECTION_UPDATE_CHANGED',

	CHECK_BRANCH_EOD_EXIST_START: 'CHECK_BRANCH_EOD_EXIST_START',
	CHECK_BRANCH_EOD_EXIST_SUCCESS: 'CHECK_BRANCH_EOD_EXIST_SUCCESS',
	CHECK_BRANCH_EOD_EXIST_FAILURE: 'CHECK_BRANCH_EOD_EXIST_FAILURE',
	CHECK_BRANCH_EOD_EXIST_RESPONSE_RESET: 'CHECK_BRANCH_EOD_EXIST_RESPONSE_RESET',
	CHECK_BRANCH_EOD_EXIST_CHANGED: 'CHECK_BRANCH_EOD_EXIST_CHANGED',

	UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_START: 'UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_START',
	UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_SUCCESS:
		'UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_SUCCESS',
	UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_FAILURE:
		'UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_FAILURE',
	UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_RESPONSE_RESET:
		'UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_RESPONSE_RESET',
	UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_CHANGED:
		'UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_CHANGED',

	UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_START:
		'UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_START',
	UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_SUCCESS:
		'UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_SUCCESS',
	UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_FAILURE:
		'UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_FAILURE',
	UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_RESPONSE_RESET:
		'UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_RESPONSE_RESET',
	UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_CHANGED:
		'UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_CHANGED',

	UPDATE_OVERALL_ALLOCATE_FOR_EOD_START: 'UPDATE_OVERALL_ALLOCATE_FOR_EOD_START',
	UPDATE_OVERALL_ALLOCATE_FOR_EOD_SUCCESS: 'UPDATE_OVERALL_ALLOCATE_FOR_EOD_SUCCESS',
	UPDATE_OVERALL_ALLOCATE_FOR_EOD_FAILURE: 'UPDATE_OVERALL_ALLOCATE_FOR_EOD_FAILURE',
	UPDATE_OVERALL_ALLOCATE_FOR_EOD_RESPONSE_RESET:
		'UPDATE_OVERALL_ALLOCATE_FOR_EOD_RESPONSE_RESET',
	UPDATE_OVERALL_ALLOCATE_FOR_EOD_CHANGED: 'UPDATE_OVERALL_ALLOCATE_FOR_EOD_CHANGED',
};
export default CollectionActionTypes;
