/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectUser = (state) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

export const selectCurrentDecodedUser = createSelector(
	[selectUser],
	(login) => login.loginDeocodedResponse,
);

export const selectForgotPasswordResp = createSelector(
	[selectUser],
	(login) => login.forgotPasswordResponse,
);

export const selectForgotPasswordRespDetails = createSelector(
	[selectUser],
	(login) => login.forgotPasswordDeocodedResponse,
);

// USER DETAILS SECTION
export const selectGetUserDetailsStatus = createSelector(
	[selectUser],
	(login) => login.getUserDetailsStatus,
);

export const selectGetUserDetailsResp = createSelector(
	[selectUser],
	(login) => login.getUserDetailsResponse,
);
// UPDATE USER PASSWORD DETAILS SECTION
export const selectUpdateUserPasswordStatus = createSelector(
	[selectUser],
	(login) => login.updateUserPasswordStatus,
);

export const selectUpdateUserPasswordResp = createSelector(
	[selectUser],
	(login) => login.updateUserPasswordResponse,
);

// export const getLoginDetails = createSelector([selectUser], (login) => {
// 	const { loginDeocodedResponse } = login;

// 	let userID = '';
// 	let companyID = '';
// 	let databaseName = '';
// 	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined) {
// 		const loginData = loginDeocodedResponse.data[0];
// 		userID = loginData.UserID;
// 		companyID = loginData.CompanyID;
// 		databaseName = loginData.DatabaseName;
// 	}
// 	return [userID, companyID, databaseName];
// });

export const getLoginObjDetails = createSelector([selectUser], (login) => {
	const { loginDeocodedResponse, currencySymbols } = login;
	let loginObj = '';
	if (
		loginDeocodedResponse !== null &&
		loginDeocodedResponse !== undefined &&
		loginDeocodedResponse?.data.length > 0
	) {
		const loginData = loginDeocodedResponse.data[0];
		loginObj = {
			userID: loginData.UserID,
			userName: loginData.UserName,
			databaseName: loginData.DatabaseName,
			userAccessBranchID: loginData.StaffBranchID === null ? '0' : loginData.StaffBranchID,
			UserAccessBranchName: loginData.UserAccessBranchName,
			userRoleID: loginData.UserRoleID,
			userRole: loginData.UserRoleName,
			userLevel: loginData.UserLevel,
			generalCompanyID: loginData.GeneralCompanyID,
			UserPhoneNumber: loginData.UserPhoneNumber,
			UserAddress: loginData.UserAddress,
			UserEmailID: loginData.UserEmailID,
			UserPhoto: loginData.UserPhoto,
			IsAadharMaskingNeeded: loginData.Company[0].IsAadharMaskingNeeded,
			limit: 0,
			branchIDs:
				loginData.BranchAccessIDs !== undefined && loginData.BranchAccessIDs.length > 0
					? loginData.BranchAccessIDs
					: 'All',
			isAuthenticationNeeded: loginData.Company[0].isAuthenticationNeeded,
			companyPhoneNumber: loginData.Company[0].CompanyPhoneNumber,
			isNbfcLogin: loginData.Company[0].ProvisionType === 'NBFC' ? 1 : 0,
			companyID: loginData.Company[0].CompanyID,
			companyName: loginData.Company[0].CompanyName,
			infogCompanyID: loginData.Company[0].InfogID,
			isWorkFlowManagementNeeded: loginData.Company[0].isWorkFlowManagementNeeded,
			ISHighMarkCheck: loginData.Company[0].ISHighMarkCheck,
			isEquifaxCheck: loginData.Company[0].isEquifaxCheck,
			isThrift: loginData.Company[0].isThrift,
			IsTenureClosedArrearsinDemandCollectionReport:
				loginData.Company[0].IsTenureClosedArrearsinDemandCollectionReport,
			IsActiveLoanArrearsinDemandCollectionReport:
				loginData.Company[0].IsActiveLoanArrearsinDemandCollectionReport,
			currency: currencySymbols,
			ProvisionType: loginData.Company[0].ProvisionType,
			IsHavingConsumer: loginData.Company[0].isHavingConsumer
				? loginData.Company[0].isHavingConsumer
				: 0,
			IsEquifaxCheck: loginData.Company[0].IsEquifaxCheck
				? loginData.Company[0].IsEquifaxCheck
				: 0,
			companyModal: loginData.Company[0].companyModal,
			isGoldLoanAvailed: loginData.Company[0].isGoldLoanAvailed,
			PercentageOnSellingPrice: loginData.Company[0].PercentageOnSellingPrice,
			IsProductConfigNeed: loginData.Company[0].IsProductConfigNeed,
			isIndividualSavings: loginData.Company[0].isIndividualSavings,
			CityVersion: loginData.Company[0].CityVersion,
			StateVersion: loginData.Company[0].StateVersion,
			CountryVersion: loginData.Company[0].CountryVersion,
			BranchVersion: loginData.Company[0].BranchVersion,
			CenterVersion: loginData.Company[0].CenterVersion,
			GroupVersion: loginData.Company[0].GroupVersion,
			IsExperianCheck: loginData.Company[0].IsExperianCheck,
			IsTopupLoanNeeded: loginData.Company[0].IsTopupLoanNeeded,
			IsKYCNeed: loginData.Company[0].IsKYCNeed,
			UserApplicationAmount:
				parseFloat(loginData.UserApplicationAmount) > 0
					? loginData.UserApplicationAmount
					: 0,
			isSubGroupNeeded: loginData.Company[0].isSubGroupNeeded,
			UserAccessData: loginData?.UserAccessData ? loginData.UserAccessData : [],
			IsTrainingMandatoryForLoan: loginData.Company[0].isTrainingMandatoryForLoan,
			IsNomineeLoanApproval: loginData.Company[0].isNomineeLoanApproval,
			IsRestrictLoanAlreadyExist: loginData.Company[0].IsRestrictLoanAlreadyExist,
			SecrateAuth: loginData.SecrateAuth,
			UserAccess: loginData.UserAccess,
			isDailyEODNeeded: loginData.Company[0].isDailyEODNeeded,
			IsFinanceApproveNeededforEOD: loginData.Company[0].IsFinanceApproveNeededforEOD,
			isAutoEODNeeded: loginData.Company[0].isAutoEODNeeded,
			isAutoEODNeededSat: loginData.Company[0].isAutoEODNeededSat,
		};
	}
	return loginObj;
});

export const getVersionDetails = createSelector([selectUser], (login) => {
	const { versionDeocodedResponse } = login;
	let loginObj = '';
	if (
		versionDeocodedResponse !== null &&
		versionDeocodedResponse !== undefined &&
		versionDeocodedResponse?.data.length > 0
	) {
		const versionData = versionDeocodedResponse.data[0];
		loginObj = {
			CityVersion: versionData.Company[0].CityVersion,
			StateVersion: versionData.Company[0].StateVersion,
			CountryVersion: versionData.Company[0].CountryVersion,
			BranchVersion: versionData.Company[0].BranchVersion,
			CenterVersion: versionData.Company[0].CenterVersion,
			GroupVersion: versionData.Company[0].GroupVersion,
		};
	}
	return loginObj;
});

export const getUserPasswordDetails = createSelector([selectUser], (login) => {
	const { userPasswordResponse } = login;
	let loginObj = '';
	if (
		userPasswordResponse !== null &&
		userPasswordResponse !== undefined &&
		userPasswordResponse?.data.length > 0
	) {
		console.log('userPasswordResponse', userPasswordResponse);

		const passwordData = userPasswordResponse.data[0];
		loginObj = {
			UserPassword: passwordData.UserPassword,
		};
	}
	return loginObj;
});

export const selectLoginStatus = createSelector([selectUser], (login) => login.loginStatus);
