const FamilyActionType = {
	FAMILY_MEMBER_COUNT_CHANGED: 'family_member_count_changed',
	ADD_FAMILY_MEMBER_START: 'add_family_member_start',
	REMOVE_FAMILY_MEMBER_START: 'remove_family_member_start',

	FAMILY_MEMBER_INCOME_START: 'family_member_income_start',
	FAMILY_MEMBER_INCOME_SUCCESS: 'family_member_income_success',
	FAMILY_MEMBER_INCOME_FAILURE: 'family_member_income_failure',
	FAMILY_MEMBER_INCOME_RESPONSE_REST_START: 'family_member_income_response_reset_start',
	FAMILY_MEMBER_INCOME_RESPONSE_CHANGED: 'family_member_income_response_changed',

	FETCH_FAMILY_MEMBERS_START: 'fetch_family_members_start',
	FETCH_FAMILY_MEMBERS_SUCCESS: 'fetch_family_members_success',
	FETCH_FAMILY_MEMBERS_FAILURE: 'fetch_family_members_failure',
	FETCH_FAMILY_MEMBERS_RESPONSE_REST_START: 'fetch_family_members_response_reset_start',
	FETCH_FAMILY_MEMBERS_RESPONSE_CHANGED: 'fetch_family_members_response_changed',

	FETCH_PARTICULAR_FAMILY_MEMBERS_START: 'fetch_particular_family_members_start',
	FETCH_PARTICULAR_FAMILY_MEMBERS_SUCCESS: 'fetch_particular_family_members_success',
	FETCH_PARTICULAR_FAMILY_MEMBERS_FAILURE: 'fetch_particular_family_members_failure',
	FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_REST_START:
		'fetch_particular_family_members_response_reset_start',
	FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_CHANGED:
		'fetch_particular_family_members_response_changed',

	ADD_FAMILY_MEMBERS_START: 'add_family_members_start',
	ADD_FAMILY_MEMBERS_SUCCESS: 'add_family_members_success',
	ADD_FAMILY_MEMBERS_FAILURE: 'add_family_members_failure',
	ADD_FAMILY_MEMBERS_RESPONSE_REST_START: 'add_family_members_response_reset_start',
	ADD_FAMILY_MEMBERS_RESPONSE_CHANGED: 'add_family_members_response_changed',

	DELETE_FAMILY_MEMBERS_START: 'delete_family_members_start',
	DELETE_FAMILY_MEMBERS_SUCCESS: 'delete_family_members_success',
	DELETE_FAMILY_MEMBERS_FAILURE: 'delete_family_members_failure',
	DELETE_FAMILY_MEMBERS_RESPONSE_REST_START: 'delete_family_members_response_reset_start',
	DELETE_FAMILY_MEMBERS_RESPONSE_CHANGED: 'delete_family_members_response_changed',

	SET_FAMILY_MEMBER_ARR_START: 'set_family_member_arr_start',
	FETCH_PARTICULAR_FAMILY_MEMBERS_OBJ_RESET: 'fetch_particular_family_members_obj_rese',

	VERIFY_FAMILY_AADHAR_OTP_START: 'verify_family_aadhar_otp_start',
	VERIFY_FAMILY_AADHAR_OTP_SUCCESS: 'verify_family_aadhar_otp_success',
	VERIFY_FAMILY_AADHAR_OTP_FAILURE: 'verify_family_aadhar_otp_failure',
	VERIFY_FAMILY_AADHAR_OTP_RESPONSE_RESET_START: 'verify_family_aadhar_otp_response_reset_start',
	VERIFY_FAMILY_AADHAR_OTP_RESPONSE_CHANGED: 'verify_family_aadhar_otp_response_changed',

	VERIFY_VALIDATE_FAMILY_AADHAR_OTP_START: 'verify_validate_family_aadhar_otp_start',
	VERIFY_VALIDATE_FAMILY_AADHAR_OTP_SUCCESS: 'verify_validate_family_aadhar_otp_success',
	VERIFY_VALIDATE_FAMILY_AADHAR_OTP_FAILURE: 'verify_validate_family_aadhar_otp_failure',
	VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_validate_family_aadhar_otp_response_reset_start',
	VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_validate_family_aadhar_otp_response_changed',
};

export default FamilyActionType;
