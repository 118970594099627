/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import AadharDetailsActionType from './aadhar-details.types';
import {
	updateAadharSuccess,
	updateAadharFailure,
	updateAadharResponseChanged,
} from './aadhar-details.action';
import { encodeJson } from '../../../enode-decode';
import { aadharUpdateDetails } from './aadhar-details.service';

// Start Aadhar Details Add
export function* updateAadharIdDetails({ payload }) {
	try {
		const key = 'updateAadharID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberAadharFrontImg: payload.memberAadharFrontImg,
			MemberAadharBackImg: payload.memberAadharBackImg,
			DocumantType: payload.docType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const aadharData = encodeJson(jsonData, key);
		const responseData = yield call(aadharUpdateDetails, aadharData);
		if (responseData.data.statusCode === '100') {
			yield put(updateAadharFailure(responseData.data));
		} else {
			yield put(updateAadharSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateAadharFailure(error));
	}
}

export function* updateAadharDetailsResponseClear() {
	yield put(updateAadharResponseChanged());
}

export function* onAadharDetailsUpdateStart() {
	yield takeLatest(AadharDetailsActionType.UPDATE_AADHAR_START, updateAadharIdDetails);
}

export function* onAadharDetailsUpdateResponseRest() {
	yield takeLatest(
		AadharDetailsActionType.UPDATE_AADHAR_RESPONSE_REST_START,
		updateAadharDetailsResponseClear,
	);
}
// End Aadhar Details Add

export function* aadharDetailsSaga() {
	yield all([call(onAadharDetailsUpdateStart), call(onAadharDetailsUpdateResponseRest)]);
}
