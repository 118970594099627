/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const postAllMemberDetailReport = (fetchData) => {
	const data = {
		PostAllMemberDetailReportToken: fetchData,
	};
	const url = `${API_URL}PostAllMemberDetailReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const MemberDetailsExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchMemberDetailsListToken', listData);
	const url = `${API_URL}Report/Excel/MemberDetailsReportsPhpExcelWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const MemberExperianExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchMemberExperianListToken', listData);
	const url = `${API_URL}Report/Excel/ExperianExportWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const HighMarkExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchHighmarkExportListToken', listData);
	const url = `${API_URL}Report/Excel/HighmarkExportWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const CommonExcelListGet = (listData, requestPage) => {
	const params = new URLSearchParams();
	params.append('CommonMemberExportListToken', listData);
	const url =
		requestPage === 'hmmHighMarkReport'
			? `${API_URL}Report/Excel/MemberExportWithToken.php`
			: requestPage === 'highMarkMemberAmount'
			? `${API_URL}Report/Excel/MemberAmountDetailsWithToken.php`
			: requestPage === 'highMarkMemberAddress'
			? `${API_URL}Report/Excel/MemberAddressDetailsWithToken.php`
			: requestPage === 'equifaxData'
			? `${API_URL}Report/Excel/EquifaxExportWithToken.php`
			: requestPage === 'equifaxHmmData'
			? `${API_URL}Report/Excel/MemberExportEquiFaxWithToken.php`
			: requestPage === 'equifaxMemberAmountData'
			? `${API_URL}Report/Excel/MemberAmountDetailsEquiFaxWithToken.php`
			: requestPage === 'equifaxMemberAddressData'
			? `${API_URL}Report/Excel/MemberAddressDetailsWithToken.php`
			: requestPage === 'memberEnquiry'
			? `${API_URL}Report/Excel/EnquiryMemberWithToken.php`
			: requestPage === 'mprData'
			? `${API_URL}Report/Excel/ExportMPRWithToken.php`
			: requestPage === 'bankBIMainBBI'
			? `${API_URL}Report/Excel/bankBiMainBBIWithToken.php`
			: requestPage === 'bankBIWriteOff'
			? `${API_URL}Report/Excel/bankBiWriteoffWithToken.php`
			: `${API_URL}Report/Excel/MemberDetailsReportsAllBranchCSVWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
