import { takeLatest, put, all, call } from 'redux-saga/effects';
import ResidentialDetailsActionType from './residential-details.types';
import {
	residentialDetailsAddSuccess,
	residentialDetailsAddFailure,
	residentialDetailsAddUserResponseChanged,
	fetchCitiesListUserResponseChanged,
	fetchCitiesListFailure,
	fetchStateListUserResponseChanged,
	fetchStateListFailure,
	fetchStateListSuccess,
	fetchCitiesListSuccess,
} from './residential-details.action';
import { encodeJson } from '../../../enode-decode';
import {
	residentialMemberDetailsAdd,
	fetchCitiesListApiCall,
	fetchStateListApiCall,
} from './residential-details.service';

// Start Residential Details Add
export function* AddResidentialDetails({ payload }) {
	try {
		const key = 'AddMemberResidentialDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberPincode: payload.pincode,
			MemberDoorNo: payload.doorNo,
			MemberAddressStreet: payload.addressStreet,
			MemberCountry: payload.country,
			MemberState: payload.state,
			MemberDistrict: payload.district,
			MemberCity: payload.city,
			MemberVillage: payload.village,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(residentialMemberDetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(residentialDetailsAddFailure(responseData.data));
		} else {
			yield put(residentialDetailsAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(residentialDetailsAddFailure(error));
	}
}

export function* AddResidentialDetailsResponseClear() {
	yield put(residentialDetailsAddUserResponseChanged());
}

export function* onResidentialDetailsAddStart() {
	yield takeLatest(
		ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_START,
		AddResidentialDetails,
	);
}

export function* onResidentialDetailsAddResponseRest() {
	yield takeLatest(
		ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_RESPONSE_REST_START,
		AddResidentialDetailsResponseClear,
	);
}

export function* fetchCitiesListDetails({ payload }) {
	try {
		const key = 'FetchCitiesDataKey';
		const jsonData = {
			StateID: payload.stateID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCitiesListApiCall, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCitiesListFailure(responseData.data));
		} else {
			yield put(fetchCitiesListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCitiesListFailure(error));
	}
}

export function* fetchCitiesResponseClear() {
	yield put(fetchCitiesListUserResponseChanged());
}

export function* onFetchCitiesStart() {
	yield takeLatest(ResidentialDetailsActionType.FETCH_CITIES_LIST_START, fetchCitiesListDetails);
}

export function* onFetchCitiesListResponseRest() {
	yield takeLatest(
		ResidentialDetailsActionType.FETCH_CITIES_LIST_RESPONSE_REST_START,
		fetchCitiesResponseClear,
	);
}

export function* fetchStateListDetails({ payload }) {
	try {
		const key = 'FetchStateDataKey';
		const jsonData = {
			CountryID: payload.countryID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(fetchStateListApiCall, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStateListFailure(responseData.data));
		} else {
			yield put(fetchStateListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStateListFailure(error));
	}
}

export function* fetchStateResponseClear() {
	yield put(fetchStateListUserResponseChanged());
}

export function* onFetchStateStart() {
	yield takeLatest(ResidentialDetailsActionType.FETCH_STATE_LIST_START, fetchStateListDetails);
}

export function* onFetchStateListResponseRest() {
	yield takeLatest(
		ResidentialDetailsActionType.FETCH_STATE_LIST_RESPONSE_REST_START,
		fetchStateResponseClear,
	);
}
// End Residential Details Add

export function* residentialDetailsSaga() {
	yield all([
		call(onResidentialDetailsAddStart),
		call(onResidentialDetailsAddResponseRest),
		call(onFetchCitiesStart),
		call(onFetchCitiesListResponseRest),
		call(onFetchStateStart),
		call(onFetchStateListResponseRest),
	]);
}
