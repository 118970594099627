/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import CloseLoanActionTypes from './closeLoan.types';

import {
	CloseLoanMemberListGet,
	CloseLoanMemberListUpdate,
	CloseLoanMemberListRevert,
} from './closeLoan.service';

import {
	getCloseLoanDetailsSuccess,
	getCloseLoanDetailsFailure,
	getCloseLoanDetailsResponseChanged,
	updateCloseLoanDetailsSuccess,
	updateCloseLoanDetailsFailure,
	updateCloseLoanDetailsResponseChanged,
	revertCloseLoanDetailsSuccess,
	revertCloseLoanDetailsFailure,
	revertCloseLoanDetailsResponseChanged,
} from './closeLoan.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET Close Loan MEMBER REPORT DETAILS  ******************** */
export function* getcloseLoanMemberList({ payload }) {
	try {
		const key = 'GetGroupMemberDetailsForCloseDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			CloseDate: payload.closeDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(CloseLoanMemberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(getCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCloseLoanDetailsFailure(error));
	}
}

export function* resetGetInActiveMemberListResponse() {
	yield put(getCloseLoanDetailsResponseChanged());
}

export function* onGetInActiveMemberListDetails() {
	yield takeLatest(CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_START, getcloseLoanMemberList);
}

export function* onGetInActiveMemberListResponseReset() {
	yield takeLatest(
		CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_RESPONSE_RESET,
		resetGetInActiveMemberListResponse,
	);
}
/** *********************  END GET CloseLoan MEMBER REPORT LIST ******************** */

/** *********************  START UPDATE Close Loan MEMBER REPORT DETAILS  ******************** */
export function* updatecloseLoanMemberList({ payload }) {
	try {
		const key = 'UpdateGroupMemberDetailsForCloseKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserLevel: payload.userLevel,
			InfogCompanyID: payload.infogCompanyID,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			DatabaseName: payload.databaseName,
			CloseDate: payload.CloseDate,
			CloseLoanArr: JSON.stringify(payload.closeloansubmitlistArr).replace(/"/g, "'"),
			CloseCollectAll: payload.closeCollectAll,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(CloseLoanMemberListUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(updateCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCloseLoanDetailsFailure(error));
	}
}

export function* resetUpdateInActiveMemberListResponse() {
	yield put(updateCloseLoanDetailsResponseChanged());
}

export function* onUpdateInActiveMemberListDetails() {
	yield takeLatest(CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_START, updatecloseLoanMemberList);
}

export function* onUpdateInActiveMemberListResponseReset() {
	yield takeLatest(
		CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_RESPONSE_RESET,
		resetUpdateInActiveMemberListResponse,
	);
}
/** *********************  END UPDATE CloseLoan MEMBER REPORT LIST ******************** */

/** *********************  START REVERT Close Loan MEMBER REPORT DETAILS  ******************** */
export function* revertcloseLoanMemberList({ payload }) {
	try {
		const key = 'RevertGroupMemberDetailsForCloseKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserLevel: payload.userLevel,
			InfogCompanyID: payload.infogCompanyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			CloseDate: payload.closeDate,
			LoanIndexID: payload.revertData.LoanIndexID,
			LoanIndexGroupMemberID: payload.revertData.LoanIndexGroupMemberID,
			MemberID: payload.revertData.MemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(CloseLoanMemberListRevert, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(revertCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(revertCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertCloseLoanDetailsFailure(error));
	}
}

export function* resetRevertInActiveMemberListResponse() {
	yield put(revertCloseLoanDetailsResponseChanged());
}

export function* onRevertInActiveMemberListDetails() {
	yield takeLatest(CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_START, revertcloseLoanMemberList);
}

export function* onRevertInActiveMemberListResponseReset() {
	yield takeLatest(
		CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_RESPONSE_RESET,
		resetRevertInActiveMemberListResponse,
	);
}
/** *********************  END REVERT CloseLoan MEMBER REPORT LIST ******************** */

export function* CloseLoanMemberSaga() {
	yield all([
		call(onGetInActiveMemberListDetails),
		call(onGetInActiveMemberListResponseReset),
		call(onUpdateInActiveMemberListDetails),
		call(onUpdateInActiveMemberListResponseReset),
		call(onRevertInActiveMemberListDetails),
		call(onRevertInActiveMemberListResponseReset),
	]);
}
