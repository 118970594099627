// eslint-disable-next-line import/no-unresolved
import CreditCheckActionType from './credit-check.types';

// Credit Check
export const creditCheckMemberAddStart = (creditCheckCredentials) => ({
	type: CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_START,
	payload: creditCheckCredentials,
});

export const creditCheckMemberAddSuccess = (creditCheckSuccess) => ({
	type: CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_SUCCESS,
	payload: creditCheckSuccess,
});

export const creditCheckMemberAddFailure = (creditCheckFailure) => ({
	type: CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_FAILURE,
	payload: creditCheckFailure,
});

export const creditCheckMemberAddResponseResetStart = () => ({
	type: CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_RESPONSE_REST_START,
});

export const creditCheckMemberAddUserResponseChanged = () => ({
	type: CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_RESPONSE_CHANGED,
});

// Credit Check Call
export const creditCheckCallStart = (creditCheckCredentials) => ({
	type: CreditCheckActionType.CREDIT_CHECK_CALL_START,
	payload: creditCheckCredentials,
});

export const creditCheckCallSuccess = (creditCheckSuccess) => ({
	type: CreditCheckActionType.CREDIT_CHECK_CALL_SUCCESS,
	payload: creditCheckSuccess,
});

export const creditCheckCallFailure = (creditCheckFailure) => ({
	type: CreditCheckActionType.CREDIT_CHECK_CALL_FAILURE,
	payload: creditCheckFailure,
});

export const creditCheckCallResponseResetStart = () => ({
	type: CreditCheckActionType.CREDIT_CHECK_CALL_RESPONSE_REST_START,
});

export const creditCheckCallUserResponseChanged = () => ({
	type: CreditCheckActionType.CREDIT_CHECK_CALL_RESPONSE_CHANGED,
});

// Verify Member Details
export const verifyMemberStart = (memberCredentials) => ({
	type: CreditCheckActionType.VERIFY_MEMBER_START,
	payload: memberCredentials,
});

export const verifyMemberSuccess = (verifiedSuccess) => ({
	type: CreditCheckActionType.VERIFY_MEMBER_SUCCESS,
	payload: verifiedSuccess,
});

export const verifyMemberFailure = (verifiedFailure) => ({
	type: CreditCheckActionType.VERIFY_MEMBER_FAILURE,
	payload: verifiedFailure,
});

export const verifyMemberResponseResetStart = () => ({
	type: CreditCheckActionType.VERIFY_MEMBER_RESPONSE_RESET_START,
});

export const verifyMemberUserResponseChanged = () => ({
	type: CreditCheckActionType.VERIFY_MEMBER_RESPONSE_CHANGED,
});

// Phone Number Verify OTP Send
export const sendVerificationOtpStart = (memberCredentials) => ({
	type: CreditCheckActionType.SEND_VERIFICATION_OTP_START,
	payload: memberCredentials,
});

export const sendVerificationOtpSuccess = (verifiedSuccess) => ({
	type: CreditCheckActionType.SEND_VERIFICATION_OTP_SUCCESS,
	payload: verifiedSuccess,
});

export const sendVerificationOtpFailure = (verifiedFailure) => ({
	type: CreditCheckActionType.SEND_VERIFICATION_OTP_FAILURE,
	payload: verifiedFailure,
});

export const sendVerificationOtpResponseResetStart = () => ({
	type: CreditCheckActionType.SEND_VERIFICATION_OTP_RESPONSE_RESET_START,
});

export const sendVerificationOtpResponseChanged = () => ({
	type: CreditCheckActionType.SEND_VERIFICATION_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR OTP DETAILS
export const verifyCreditCheckAadharOtpStart = (DetailsCredentials) => ({
	type: CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyCreditCheckAadharOtpSuccess = (DetailsSuccess) => ({
	type: CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyCreditCheckAadharOtpFailure = (DetailsFailure) => ({
	type: CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyCreditCheckAadharOtpResponseResetStart = () => ({
	type: CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyCreditCheckAadharOtpResponseChanged = () => ({
	type: CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR VALIDATE OTP DETAILS
export const verifyValidateCreditCheckAadharOtpStart = (DetailsCredentials) => ({
	type: CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyValidateCreditCheckAadharOtpSuccess = (DetailsSuccess) => ({
	type: CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyValidateCreditCheckAadharOtpFailure = (DetailsFailure) => ({
	type: CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyValidateCreditCheckAadharOtpResponseResetStart = () => ({
	type: CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyValidateCreditCheckAadharOtpResponseChanged = () => ({
	type: CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED,
});

// RECHECK MEMBER DETAILS FOR CRIF
export const recheckMemberDetailsStart = (DetailsCredentials) => ({
	type: CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_START,
	payload: DetailsCredentials,
});

export const recheckMemberDetailsSuccess = (DetailsSuccess) => ({
	type: CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_SUCCESS,
	payload: DetailsSuccess,
});

export const recheckMemberDetailsFailure = (DetailsFailure) => ({
	type: CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_FAILURE,
	payload: DetailsFailure,
});

export const recheckMemberDetailsResponseResetStart = () => ({
	type: CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_RESET_START,
});

export const recheckMemberDetailsResponseChanged = () => ({
	type: CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_CHANGED,
});

// Equifax Check Call
export const equifaxCheckCallStart = (creditCheckCredentials) => ({
	type: CreditCheckActionType.EQUIFAX_CHECK_CALL_START,
	payload: creditCheckCredentials,
});

export const equifaxCheckCallSuccess = (creditCheckSuccess) => ({
	type: CreditCheckActionType.EQUIFAX_CHECK_CALL_SUCCESS,
	payload: creditCheckSuccess,
});

export const equifaxCheckCallFailure = (creditCheckFailure) => ({
	type: CreditCheckActionType.EQUIFAX_CHECK_CALL_FAILURE,
	payload: creditCheckFailure,
});

export const equifaxCheckCallResponseResetStart = () => ({
	type: CreditCheckActionType.EQUIFAX_CHECK_CALL_RESPONSE_REST_START,
});

export const equifaxCheckCallUserResponseChanged = () => ({
	type: CreditCheckActionType.EQUIFAX_CHECK_CALL_RESPONSE_CHANGED,
});
