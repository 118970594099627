/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanActionTypes from './custom-product-loan-proposal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getProductProposalResponse: null,
	getProductProposalListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				getProductProposalResponse: action.payload,
				getProductProposalListArr: docodeResponse(
					action.payload,
					'getCommonLoanIndexListKey',
				),
			};

		case LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_FAILURE:
			return { ...state, getProductProposalResponse: action.payload };

		case LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, getProductProposalResponse: null };

		

		default:
			return state;
	}
};
