const VerifyOtpActionType = {
	VERIFY_MEMBER_NUMBER_OTP_START: 'verify_member_number_otp_start',
	VERIFY_MEMBER_NUMBER_OTP_SUCCESS: 'verify_member_number_otp_success',
	VERIFY_MEMBER_NUMBER_OTP_FAILURE: 'verify_member_number_otp_failure',
	VERIFY_MEMBER_NUMBER_OTP_RESPONSE_REST_START: 'verify_member_number_otp_response_reset_start',
	VERIFY_MEMBER_NUMBER_OTP_RESPONSE_CHANGED: 'verify_member_number_otp_response_changed',

	RE_SEND_OTP_START: 're_send_otp_start',
	RE_SEND_OTP_START_RESPONSE_SUCCESS: 're_send_otp_start_response_success',
	RE_SEND_OTP_START_RESPONSE_FAILURE: 're_send_otp_start_response_failure',
	RE_SEND_OTP_START_RESPONSE_RESET_START: 're_send_otp_start_response_reset_start',
	RE_SEND_OTP_START_RESPONSE_CHANGED: 're_send_otp_start_response_changed',
};

export default VerifyOtpActionType;
