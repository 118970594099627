/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const AllTrainingProposalDetailsForBranch = (trainingData) => {
	const data = {
		AllTrainingBranchDetails: trainingData,
	};
	const url = `${API_URL}GetTrainingProposalDetailsForBranchWithToken`;
	// eslint-disable-next-line no-debugger
	// debugger;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const TrainingProposalDetailsForBranchExcelListGet = (listData) => {
	const params = new URLSearchParams();
	const url = `${API_URL}Report/Excel/AuditingToolReportExportwithToken.php`;
	params.append('FetchAuditingToolReportExcelToken', listData);

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
