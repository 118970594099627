/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import LoanCardReportActionTypes from './loanCardReport.types';

/** ***************** GET BRANCH PROFILE REPORT START *********************** */
export const getLoanCardReportStart = (getData) => ({
	type: LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_START,
	payload: getData,
});

export const getLoanCardReportSuccess = (successData) => ({
	type: LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_SUCCESS,
	payload: successData,
});

export const getLoanCardReportFailure = (failureData) => ({
	type: LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_FAILURE,
	payload: failureData,
});

export const getLoanCardReportResponseResetStart = () => ({
	type: LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_RESPONSE_RESET,
});

export const getLoanCardReportResponseChanged = () => ({
	type: LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH PROFILE REPORT END *********************** */

/** ***************** GET MEMBER LOAN CARD START *********************** */
export const getMemberLoanCardStart = (getData) => ({
	type: LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_START,
	payload: getData,
});

export const getMemberLoanCardSuccess = (successData) => ({
	type: LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_SUCCESS,
	payload: successData,
});

export const getMemberLoanCardFailure = (failureData) => ({
	type: LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_FAILURE,
	payload: failureData,
});

export const getMemberLoanCardResponseResetStart = () => ({
	type: LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_RESPONSE_RESET,
});

export const getMemberLoanCardResponseChanged = () => ({
	type: LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN CARD END *********************** */

/** ***************** GET PARTICULAR LOAN CARD START *********************** */
export const getParticularLoanCardStart = (getData) => ({
	type: LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_START,
	payload: getData,
});

export const getParticularLoanCardSuccess = (successData) => ({
	type: LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_SUCCESS,
	payload: successData,
});

export const getParticularLoanCardFailure = (failureData) => ({
	type: LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_FAILURE,
	payload: failureData,
});

export const getParticularLoanCardResponseResetStart = () => ({
	type: LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_RESPONSE_RESET,
});

export const getParticularLoanCardResponseChanged = () => ({
	type: LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_RESPONSE_CHANGED,
});
/** ***************** GET PARTICULAR LOAN CARD END *********************** */


/** ***************** FETCH LOAN CARD EXCEL DETAILS START *********************** */
export const fetchLoanCardExcelStart = (getData) => ({
	type: LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_START,
	payload: getData,
});

export const fetchLoanCardExcelSuccess = (successData) => ({
	type: LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchLoanCardExcelFailure = (failureData) => ({
	type: LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchLoanCardExcelReset = () => ({
	type: LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_RESET,
});

export const fetchLoanCardExcelChanged = () => ({
	type: LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_CHANGED,
});
/** ***************** FETCH LOAN CARD EXCEL DETAILS END *********************** */
