/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import AssignUserRightsTypes from './assignUserRights.types';
import {
	createAssignUserRightsForPage,
	updateAssignUserRightsForPage,
	saveAssignUserRightsForAllPage,
	fetchListUserControlDetails,
} from './assignUserRights.service';

import {
	createUserControlRightsPageSuccess,
	createUserControlRightsPageFailure,
	createUserControlRightsPageResponseChanged,
	updateUserControlRightsPageSuccess,
	updateUserControlRightsPageFailure,
	updateUserControlRightsPageResponseChanged,
	saveUserControlRightsForAllPageSuccess,
	saveUserControlRightsForAllPageFailure,
	saveUserControlRightsForAllPageResponseChanged,
	fetchUserControlDetailsListSuccess,
	fetchUserControlDetailsListFailure,
	fetchUserControlDetailsListResponseChanged,
	fetchUserControlDetailsListArrChanged,
} from './assignUserRights.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD USER ASSIGN CONTROL RIGHTS PAGE DETAILS  ******************** */
export function* createUserControlRightsPageDetails({ payload }) {
	try {
		const key = 'CreateAsignControMenuKey';
		const jsonData = {
			CompanyID: payload.companyID,
			IsShowhide: payload.IsShowhide,
			IsAdd: payload.IsAdd,
			IsEdit: payload.IsEdit,
			IsDelete: payload.IsDelete,
			SubMenuId: payload.SubMenuId,
			MenuId: payload.MenuId,
			IsApprove: payload.IsApprove,
			UserRole: payload.UserRole,
			MainMenuID: payload.MainMenuID,
			CustomName: payload.CustomName,
			CreatedBy: payload.CreatedBy,
			LogNotes: payload.LogNotes,
			MapMenuId: payload.MapMenuId,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createAssignUserRightsForPage, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createUserControlRightsPageFailure(responseData.data));
		} else {
			yield put(createUserControlRightsPageSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createUserControlRightsPageFailure(error));
	}
}

export function* resetCreateAssignUserControlRightsPageResponse() {
	yield put(createUserControlRightsPageResponseChanged());
}

export function* onCreateAssignUserControlRightsPageDetails() {
	yield takeLatest(
		AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START,
		createUserControlRightsPageDetails,
	);
}

export function* onCreateAssignUserControlRightsPageResponseReset() {
	yield takeLatest(
		AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET,
		resetCreateAssignUserControlRightsPageResponse,
	);
}
/** *********************  END ADD USER ASSIGN CONTROL RIGHTS PAGE DETAILS ******************** */

/** *********************  START UPDATE USER ASSIGN CONTROL RIGHTS PAGE DETAILS  ******************** */
export function* updateUserControlRightsPageDetails({ payload }) {
	try {
		const key = 'UpdateAssignControlUserMenuKey';
		const jsonData = {
			CompanyID: payload.companyID,
			IsShowhide: payload.IsShowhide,
			IsAdd: payload.IsAdd,
			IsEdit: payload.IsEdit,
			IsDelete: payload.IsDelete,
			SubMenuId: payload.SubMenuId,
			MenuId: payload.MenuId,
			IsApprove: payload.IsApprove,
			UserRole: payload.UserRole,
			MainMenuID: payload.MainMenuID,
			CustomName: payload.CustomName,
			CreatedBy: payload.CreatedBy,
			LogNotes: payload.LogNotes,
			MapMenuId: payload.MapMenuId,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateAssignUserRightsForPage, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateUserControlRightsPageFailure(responseData.data));
		} else {
			yield put(updateUserControlRightsPageSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateUserControlRightsPageFailure(error));
	}
}

export function* resetUpdateAssignUserControlRightsPageResponse() {
	yield put(updateUserControlRightsPageResponseChanged());
}

export function* onUpdateAssignUserControlRightsPageDetails() {
	yield takeLatest(
		AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START,
		updateUserControlRightsPageDetails,
	);
}

export function* onUpdateAssignUserControlRightsPageResponseReset() {
	yield takeLatest(
		AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET,
		resetUpdateAssignUserControlRightsPageResponse,
	);
}
/** *********************  END UPDATE USER ASSIGN CONTROL RIGHTS PAGE DETAILS ******************** */

/** *********************  START SAVE USER ASSIGN CONTROL RIGHTS FOR ALL PAGE DETAILS  ******************** */
export function* saveUserControlRightsForAllPageDetails({ payload }) {
	try {
		const key = 'SaveAssignControlUserAllMenuKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserAccessDetails: payload.UserAccessDetails,
			UserRole: payload.UserRoleID,
			CreatedBy: payload.CreatedBy,
			LogNotes: payload.LogNotes,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(saveAssignUserRightsForAllPage, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(saveUserControlRightsForAllPageFailure(responseData.data));
		} else {
			yield put(saveUserControlRightsForAllPageSuccess(responseData.data));
		}
	} catch (error) {
		yield put(saveUserControlRightsForAllPageFailure(error));
	}
}

export function* resetSaveUserControlRightsForAllPageResponse() {
	yield put(saveUserControlRightsForAllPageResponseChanged());
}

export function* onSaveUserControlRightsForAllPageDetails() {
	yield takeLatest(
		AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_START,
		saveUserControlRightsForAllPageDetails,
	);
}

export function* onSaveUserControlRightsForAllPageResponseReset() {
	yield takeLatest(
		AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_RESET,
		resetSaveUserControlRightsForAllPageResponse,
	);
}
/** *********************  END UPDATE USER ASSIGN CONTROL RIGHTS PAGE DETAILS ******************** */

/** *********************  START FETCH USER CONTROL DETAILS LIST  ******************** */
export function* fetchUserControlDetailsList({ payload }) {
	try {
		const key = 'FetchUserControlDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserRoleID: payload.userRoleID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchListUserControlDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchUserControlDetailsListFailure(responseData.data));
		} else {
			yield put(fetchUserControlDetailsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchUserControlDetailsListFailure(error));
	}
}

export function* resetFetchListUserControlDetailsResponse() {
	yield put(fetchUserControlDetailsListResponseChanged());
}

export function* resetFetchListUserControlDetailsArr() {
	yield put(fetchUserControlDetailsListArrChanged());
}

export function* onFetchListUserControlDetails() {
	yield takeLatest(
		AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_START,
		fetchUserControlDetailsList,
	);
}

export function* onFetchListUserControlDetailsResponseReset() {
	yield takeLatest(
		AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_RESET,
		resetFetchListUserControlDetailsResponse,
	);
}

export function* onFetchListUserControlDetailsArrReset() {
	yield takeLatest(
		AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_ARR_RESET,
		resetFetchListUserControlDetailsArr,
	);
}
/** *********************  END FETCH USER CONTROL DETAILS LIST ******************** */

export function* assignUserRightsMasterSaga() {
	yield all([
		call(onCreateAssignUserControlRightsPageDetails),
		call(onCreateAssignUserControlRightsPageResponseReset),
		call(onUpdateAssignUserControlRightsPageDetails),
		call(onUpdateAssignUserControlRightsPageResponseReset),
		call(onSaveUserControlRightsForAllPageDetails),
		call(onSaveUserControlRightsForAllPageResponseReset),
		call(onFetchListUserControlDetails),
		call(onFetchListUserControlDetailsResponseReset),
		call(onFetchListUserControlDetailsArrReset),
	]);
}
