// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-undef */
import React, { lazy } from 'react';
import { componentsMenu, dashboardMenu, demoPages, profileMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

// USERCONTROLACCESS
const ASSIGN_USER_RIGHTS = {
	ASSIGN_USER_RIGHTS_PAGE: lazy(() =>
		import('../pages/userControlAccess/assignUserRights/assignUserRights.component'),
	),
};

const MEMBER_MANDATORY_ACCESS = {
	MEMBER_MANDATORY_ACCESS_PAGE: lazy(() =>
		import('../pages/userControlAccess/memberMandatoryAccess/memberMandatoryAccess.component'),
	),
};

const HIGHMARK_RULES = {
	HIGHMARK_RULES_PAGE: lazy(() =>
		import('../pages/userControlAccess/highmarkRules/highmarkRules.component'),
	),
};

const WORK_FLOW_MANAGEMENT = {
	WORK_FLOW_MANAGEMENT_PAGE: lazy(() =>
		import('../pages/userControlAccess/workflowmangement/workflowmangement.component'),
	),
};
const RULE_ENGINE = {
	RULE_ENGINE_PAGE: lazy(() =>
		import('../pages/userControlAccess/ruleEngine/ruleEngine.component'),
	),
};

// MASTER
const BRANCH = {
	BRANCH_PAGE: lazy(() => import('../pages/masters/branch/branch.component')),
};

const CENTER = {
	CENTER_PAGE: lazy(() => import('../pages/masters/center/center.component')),
};

// const MEMBER_APPROVE = {
// 	MEMBER_APPROVE_PAGE: lazy(() =>
// 		import('../pages/masters/member-approve/member-approve.component'),
// 	),
// };

const MEMBER = {
	MEMBER_PAGE: lazy(() => import('../pages/masters/member/member-list/member-list.component')),
};

// const LOAN_APPLICATION = {
// 	LOAN_APPLICATION_PAGE: lazy(() =>
// 		import('../pages/masters/loan-application/loan-application-table.component'),
// 	),
// };
const PURPOSE_OF_LOAN = {
	PURPOSE_OF_LOAN_PAGE: lazy(() =>
		import('../pages/masters/purpose-of-loan/purpose-of-loan.component'),
	),
};

const ROLE = {
	ROLE_PAGE: lazy(() => import('../pages/masters/role/role.component')),
};

const STAFF = {
	STAFF_PAGE: lazy(() => import('../pages/masters/staff/staff.component')),
};

const GROUP = {
	GROUP_PAGE: lazy(() => import('../pages/masters/group/group.component')),
};

const THRIFT = {
	THRIFT_PAGE: lazy(() => import('../pages/masters/thrift/thrift.component')),
};

const PRODUCT_TYPE = {
	PRODUCT_TYPE_PAGE: lazy(() => import('../pages/masters/product-type/product-type.component')),
};

const INSURANCE_COMPANY = {
	INSURANCE_COMPANYE_PAGE: lazy(() =>
		import('../pages/masters/insurance-company/insurance-company.component'),
	),
};

const PRODUCT = {
	PRODUCT_PAGE: lazy(() => import('../pages/masters/product/product.component')),
};

const ECONOMIC_ACTIVITY_TYPE = {
	ECONOMIC_ACTIVITY_TYPE_PAGE: lazy(() =>
		import('../pages/masters/econamic-activity-type/econamic-activity-type.component'),
	),
};

const ECONOMIC_ACTIVITY = {
	ECONOMIC_ACTIVITY_PAGE: lazy(() =>
		import('../pages/masters/econamic-activity/econamic-activity.component'),
	),
};

const QUESTIONNAIRE = {
	QUESTIONNAIRE_PAGE: lazy(() =>
		import('../pages/masters/questionnaire/questionnaire.component'),
	),
};

const BUSINESS_FUND = {
	BUSINESS_FUND_PAGE: lazy(() =>
		import('../pages/masters/business-fund/business-fund.component'),
	),
};

const BUSINESS = {
	BUSINESS_PAGE: lazy(() => import('../pages/business/business.component')),
};

// OPERATIONS

const AUDIT_TOOL = {
	AUDIT_TOOL_PAGE: lazy(() =>
		import('../pages/operations/auditing-tool/auditing-tool.component'),
	),
};
const TODAY_COLLECTION = {
	TODAY_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/todayCollection/todayCollection.component'),
	),
};

const DEMAND_COLLECTION = {
	DEMAND_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/demandCollection/demandCollection.component'),
	),
};

const ADVANCE_COLLECTION = {
	ADVANCE_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/advanceCollection/advanceCollection.component'),
	),
};

const ARREAR_COLLECTION = {
	ARREAR_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/arrearCollection/arrearCollection.component'),
	),
};

const COLLECTION_APPROVE = {
	COLLECTION_APPROVE_PAGE: lazy(() =>
		import('../pages/operations/collectionApprove/collectionApprove.component'),
	),
};

const WRITE_OFF_COLLECTION = {
	WRITE_OFF_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/writeOffCollection/writeOffCollection.component'),
	),
};

const PRE_CLOSE_LOAN = {
	PRE_CLOSE_LOAN_PAGE: lazy(() =>
		import('../pages/operations/preCloseLoan/preCloseLoan.component'),
	),
};

const CLOSE_LOAN = {
	CLOSE_LOAN_PAGE: lazy(() => import('../pages/operations/closeLoan/closeLoan.component')),
};

const END_OF_DAY = {
	END_OF_DAY_PAGE: lazy(() => import('../pages/operations/endOfDay/endOfDay.component')),
};

const CHANGE_GROUP_OFFICER = {
	CHANGE_GROUP_OFFICER_PAGE: lazy(() =>
		import('../pages/operations/changeGroupOfficer/changeGroupOfficer.component'),
	),
};

const CHANGE_MEETING_DATE = {
	CHANGE_MEETING_DATE_PAGE: lazy(() =>
		import('../pages/operations/changeMeetingDate/changeMeetingDate.component'),
	),
};

const MEMBER_COLLECTION = {
	MEMBER_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/memberCollection/memberCollection.component'),
	),
};

// LOAN PROPOSAL
const BUSINESS_LOAN_PROPOSAL = {
	BUSINESS_LOAN_PROPOSAL_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-proposal/business-loan-proposal/business-loan-proposal.component'
		),
	),
};

const CENTER_WISE_LOAN_PROPOSAL = {
	CENTER_WISE_LOAN_PROPOSAL_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-proposal/centerWiseLoanProposal/centerWiseLoanProposal.component'
		),
	),
};

const PRODUCT_LOAN_PROPOSAL = {
	PRODUCT_LOAN_PROPOSAL_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-proposal/product-loan-proposal/product-loan-proposal.component'
		),
	),
};

const CUSTOM_PRODUCT_LOAN_PROPOSAL = {
	CUSTOM_PRODUCT_LOAN_PROPOSAL_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-proposal/custom-product-loan-proposal/custom-product-loan-proposal.component'
		),
	),
};

const GOLD_LOAN_PROPOSAL = {
	GOLD_LOAN_PROPOSAL_PAGE: lazy(() =>
		import('../pages/operations/loan-proposal/gold-loan-proposal/gold-loan-proposal.component'),
	),
};

const RANGE_LOAN_PROPOSAL = {
	RANGE_LOAN_PROPOSAL_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-proposal/range-loan-proposal/range-loan-proposal.component'
		),
	),
};

const BUSINESS_LOAN_DISBURSEMENT = {
	BUSINESS_LOAN_DISBURSEMENT_PAGE: lazy(() =>
		import('../pages/operations/loan-disbursement/business-loan/businss-loan-disbmt.component'),
	),
};

const LOAN_DISBURSEMENT = {
	LOAN_DISBURSEMENT_PAGE: lazy(() =>
		import(
			'../pages/operations/loan-disbursement/loan-index-disbursement/loan-index-disbursement.component'
		),
	),
};

const FUNDER = {
	FUNDER_PAGE: lazy(() => import('../pages/masters/funder/funder.component')),
};

const REQUEST_REPORT = {
	REQUEST_REPORT_PAGE: lazy(() =>
		import('../pages/report/requestReport/requestReport.component'),
	),
};

const REPAYMENT_COLLECTION_REPORT = {
	REPAYMENT_COLLECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/repaymentReport/repayment-Report.component'),
	),
};

const PRECLOSE_LOAN_REPORT = {
	PRECLOSE_LOAN_REPORT_PAGE: lazy(() =>
		import('../pages/report/preclose-loan/preclose-loan.component'),
	),
};

const CLOSED_LOAN_DETAILS = {
	CLOSED_LOAN_DETAILS_PAGE: lazy(() =>
		import('../pages/report/closed-loan-details/closed-loan-details.component'),
	),
};

const DEAD_MEMBER_DETAILS = {
	DEAD_MEMBER_DETAILS_PAGE: lazy(() =>
		import('../pages/report/dead-member-report/dead-member-report.component'),
	),
};

const FUTURE_DEMAND_REPORT = {
	FUTURE_DEMAND_REPORT_PAGE: lazy(() =>
		import('../pages/report/future-demand-report/future-demand-report.component'),
	),
};

const MEMBER_DETAILS_REPORT = {
	MEMBER_DETAILS_REPORT_PAGE: lazy(() =>
		import('../pages/report/member-details-report/member-details-report.component'),
	),
};

const PORTFOLIO_REPORT = {
	PORTFOLIO_REPORT_PAGE: lazy(() =>
		import('../pages/report/portfolioReport/portfolioReport.component'),
	),
};

const BRANCH_PROFILE_REPORT = {
	BRANCH_PROFILE_REPORT_PAGE: lazy(() =>
		import('../pages/report/branchProfileReport/branchProfileReport.component'),
	),
};
const LOAN_CARD_REPORT = {
	LOAN_CARD_REPORT_PAGE: lazy(() =>
		import('../pages/report/loanCardReport/loanCardReport.component'),
	),
};

const DAILY_COLLECTION_REPORT = {
	DAILY_COLLECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/dailyCollectionReport/dailyCollectionReport.component'),
	),
};

const GROUP_REPORT = {
	GROUP_REPORT_PAGE: lazy(() => import('../pages/report/groupReport/groupReport.component')),
};

const BRANCH_COLLECTION_REPORT = {
	BRANCH_COLLECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/branchCollectionReport/branchCollectionReport.component'),
	),
};

const DAY_BOOK_REPORT = {
	DAY_BOOK_REPORT_PAGE: lazy(() =>
		import('../pages/report/dayBookReport/dayBookReport.component'),
	),
};

const BRANCH_DCB_REPORT = {
	BRANCH_DCB_REPORT_PAGE: lazy(() =>
		import('../pages/report/branchDCBReport/branchDCBReport.component'),
	),
};

const DEMAND_COLLECTION_REPORT = {
	DEMAND_COLLECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/demand-collection-report/demandcollection.Component'),
	),
};

const DEMAND_FOLLOWUP_REPORT = {
	DEMAND_FOLLOWUP_REPORT_PAGE: lazy(() =>
		import('../pages/report/demand-followup-report/demandfollowup.Component'),
	),
};

const ARREAR_FOLLOWUP_REPORT = {
	ARREAR_FOLLOWUP_REPORT_PAGE: lazy(() =>
		import('../pages/report/arrear-followup-report/arrearfollowup.Component'),
	),
};

const PORT_FOLIO_AT_RISK_REPORT = {
	PORT_FOLIO_AT_RISK_REPORT_PAGE: lazy(() =>
		import('../pages/report/portFlioAtRiskReport/portflioAtRiskReport.component'),
	),
};

const ACTIVE_MEMBER = {
	ACTIVE_MEMBER_PAGE: lazy(() => import('../pages/report/active-member/active-member.component')),
};

const PROFILE = {
	PROFILE_PAGE: lazy(() => import('../layout/Profile/Profile')),
};

const MONTHLY_PROGRESS = {
	MONTHLY_PROGRESS_PAGE: lazy(() =>
		import('../pages/report/monthlyProgress/monthlyProgress.component'),
	),
};

const LOAN_DISBURSEMENT_REPORT = {
	LOAN_DISBURSEMENT_REPORT_PAGE: lazy(() =>
		import('../pages/report/loan-disbursement/loandisbursementReport.component'),
	),
};

const OVER_DUE_REPORT = {
	OVER_DUE_REPORT_PAGE: lazy(() =>
		import('../pages/report/overDueReport/overDueReport.component'),
	),
};

const WALLET_COLLECTION_REPORT = {
	WALLET_COLLECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/walletReport/walletReport.component'),
	),
};

const MEMBER_REJECTION_REPORT = {
	MEMBER_REJECTION_REPORT_PAGE: lazy(() =>
		import('../pages/report/memberRejectionReport/MemberRejectionReport.component'),
	),
};

const QR_REPORT = {
	QR_REPORT_PAGE: lazy(() => import('../pages/report/qr-report/qr-report.component')),
};

const LEAD_REPORT = {
	LEAD_REPORT_PAGE: lazy(() => import('../pages/report/LeadReport/LeadReport.component')),
};

const INSURANCE_REPORT = {
	INSURANCE_REPORT_PAGE: lazy(() =>
		import('../pages/report/insurance-report/insurance-report.component'),
	),
};

const CHANGE_MEETING_REPORT = {
	CHANGE_MEETING_REPORT_PAGE: lazy(() =>
		import('../pages/report/ChangeMeetingReport/ChangeMeetingReport.component'),
	),
};

const AUDIT_TRAIL_REPORT = {
	AUDIT_TRAIL_REPORT_PAGE: lazy(() =>
		import('../pages/report/AuditTrailReport/AuditTrailReport.component'),
	),
};

const CREDIT_BUREAU_REPORT = {
	CREDIT_BUREAU_REPORT_PAGE: lazy(() =>
		import('../pages/report/creditBureauReport/creditBureauReport.component'),
	),
};

const AUDITING_TOOL_REPORT = {
	AUDITING_TOOL_REPORT_PAGE: lazy(() =>
		import('../pages/report/auditingToolReport/auditingToolReport.component'),
	),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const EXTRA = {
	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	// PROFILE
	{
		path: profileMenu.profile.path,
		element: <PROFILE.PROFILE_PAGE />,
		exact: true,
	},

	// userControlAccess
	{
		path: demoPages.userControlAccess.subMenu.assignUserRights.path,
		element: <ASSIGN_USER_RIGHTS.ASSIGN_USER_RIGHTS_PAGE />,
		exact: true,
	},
	{
		path: demoPages.userControlAccess.subMenu.memberMandatoryAccess.path,
		element: <MEMBER_MANDATORY_ACCESS.MEMBER_MANDATORY_ACCESS_PAGE />,
		exact: true,
	},
	{
		path: demoPages.userControlAccess.subMenu.highmarkRules.path,
		element: <HIGHMARK_RULES.HIGHMARK_RULES_PAGE />,
		exact: true,
	},
	{
		path: demoPages.userControlAccess.subMenu.workflowmanagement.path,
		element: <WORK_FLOW_MANAGEMENT.WORK_FLOW_MANAGEMENT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.userControlAccess.subMenu.ruleEngine.path,
		element: <RULE_ENGINE.RULE_ENGINE_PAGE />,
		exact: true,
	},

	// Master
	{
		path: demoPages.masterPages.subMenu.branch.path,
		element: <BRANCH.BRANCH_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.center.path,
		element: <CENTER.CENTER_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.member.path,
		element: <MEMBER.MEMBER_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.masterPages.subMenu.memberApprove.path,
	// 	element: <MEMBER_APPROVE.MEMBER_APPROVE_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.masterPages.subMenu.loan_application.path,
	// 	element: <LOAN_APPLICATION.LOAN_APPLICATION_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.masterPages.subMenu.purpose_of_loan.path,
		element: <PURPOSE_OF_LOAN.PURPOSE_OF_LOAN_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.role.path,
		element: <ROLE.ROLE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.staff.path,
		element: <STAFF.STAFF_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.group.path,
		element: <GROUP.GROUP_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.thrift.path,
		element: <THRIFT.THRIFT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.fetchProductType.path,
		element: <PRODUCT_TYPE.PRODUCT_TYPE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.insurance_company.path,
		element: <INSURANCE_COMPANY.INSURANCE_COMPANYE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.product.path,
		element: <PRODUCT.PRODUCT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.economic_activity_type.path,
		element: <ECONOMIC_ACTIVITY_TYPE.ECONOMIC_ACTIVITY_TYPE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.economicActivity.path,
		element: <ECONOMIC_ACTIVITY.ECONOMIC_ACTIVITY_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.questionnaire.path,
		element: <QUESTIONNAIRE.QUESTIONNAIRE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.business_fund.path,
		element: <BUSINESS_FUND.BUSINESS_FUND_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.business.path,
		element: <BUSINESS.BUSINESS_PAGE />,
		exact: true,
	},
	// Opertions
	{
		path: demoPages.operationPages.subMenu.auditTool.path,
		element: <AUDIT_TOOL.AUDIT_TOOL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.todayCollection.path,
		element: <TODAY_COLLECTION.TODAY_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.demandCollection.path,
		element: <DEMAND_COLLECTION.DEMAND_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.advanceCollection.path,
		element: <ADVANCE_COLLECTION.ADVANCE_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.arrearCollection.path,
		element: <ARREAR_COLLECTION.ARREAR_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.writeOffCollection.path,
		element: <WRITE_OFF_COLLECTION.WRITE_OFF_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.collectionApprove.path,
		element: <COLLECTION_APPROVE.COLLECTION_APPROVE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.preCloseLoan.path,
		element: <PRE_CLOSE_LOAN.PRE_CLOSE_LOAN_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.closeLoan.path,
		element: <CLOSE_LOAN.CLOSE_LOAN_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.endOfDay.path,
		element: <END_OF_DAY.END_OF_DAY_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.changeGroupOfficer.path,
		element: <CHANGE_GROUP_OFFICER.CHANGE_GROUP_OFFICER_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.changeMeetingDate.path,
		element: <CHANGE_MEETING_DATE.CHANGE_MEETING_DATE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.memberCollection.path,
		element: <MEMBER_COLLECTION.MEMBER_COLLECTION_PAGE />,
		exact: true,
	},
	// Loan Proposal
	{
		path: demoPages.loanProposalPages.subMenu.BusinessLoanProposal.path,
		element: <BUSINESS_LOAN_PROPOSAL.BUSINESS_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.loanProposalPages.subMenu.CenterWiseLoanProposal.path,
		element: <CENTER_WISE_LOAN_PROPOSAL.CENTER_WISE_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.loanProposalPages.subMenu.ProductLoanProposal.path,
		element: <PRODUCT_LOAN_PROPOSAL.PRODUCT_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.loanProposalPages.subMenu.CustomProductLoanProposal.path,
		element: <CUSTOM_PRODUCT_LOAN_PROPOSAL.CUSTOM_PRODUCT_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.loanProposalPages.subMenu.GoldLoanProposal.path,
		element: <GOLD_LOAN_PROPOSAL.GOLD_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	{
		path: demoPages.loanProposalPages.subMenu.RangeLoanProposal.path,
		element: <RANGE_LOAN_PROPOSAL.RANGE_LOAN_PROPOSAL_PAGE />,
		exact: true,
	},
	// Business Loan Disbursement
	{
		path: demoPages.loanDisbursementPages.subMenu.BusinessLoanDisbursement.path,
		element: <BUSINESS_LOAN_DISBURSEMENT.BUSINESS_LOAN_DISBURSEMENT_PAGE />,
		exact: true,
	},
	// Business Loan Disbursement
	{
		path: demoPages.loanDisbursementPages.subMenu.LoanDisbursement.path,
		element: <LOAN_DISBURSEMENT.LOAN_DISBURSEMENT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.funder.path,
		element: <FUNDER.FUNDER_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.RequestReport.path,
		element: <REQUEST_REPORT.REQUEST_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.RepaymentCollection.path,
		element: <REPAYMENT_COLLECTION_REPORT.REPAYMENT_COLLECTION_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.PreCloseLoanReport.path,
		element: <PRECLOSE_LOAN_REPORT.PRECLOSE_LOAN_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.ClosedLoanDetails.path,
		element: <CLOSED_LOAN_DETAILS.CLOSED_LOAN_DETAILS_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.DeadMemberDetails.path,
		element: <DEAD_MEMBER_DETAILS.DEAD_MEMBER_DETAILS_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.DemandCollectionReport.path,
		element: <DEMAND_COLLECTION_REPORT.DEMAND_COLLECTION_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.DemandFollowupReport.path,
		element: <DEMAND_FOLLOWUP_REPORT.DEMAND_FOLLOWUP_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.ArrearFollowupReport.path,
		element: <ARREAR_FOLLOWUP_REPORT.ARREAR_FOLLOWUP_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.FutureDemandReport.path,
		element: <FUTURE_DEMAND_REPORT.FUTURE_DEMAND_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.memberDetailsReport.path,
		element: <MEMBER_DETAILS_REPORT.MEMBER_DETAILS_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.PortfolioReport.path,
		element: <PORTFOLIO_REPORT.PORTFOLIO_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.BranchProfile.path,
		element: <BRANCH_PROFILE_REPORT.BRANCH_PROFILE_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.LoanCardReport.path,
		element: <LOAN_CARD_REPORT.LOAN_CARD_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.DailyCollection.path,
		element: <DAILY_COLLECTION_REPORT.DAILY_COLLECTION_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.GroupReport.path,
		element: <GROUP_REPORT.GROUP_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.BranchCollection.path,
		element: <BRANCH_COLLECTION_REPORT.BRANCH_COLLECTION_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.DayBookReport.path,
		element: <DAY_BOOK_REPORT.DAY_BOOK_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.BranchDCBReport.path,
		element: <BRANCH_DCB_REPORT.BRANCH_DCB_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.PortFolioAtRiskReport.path,
		element: <PORT_FOLIO_AT_RISK_REPORT.PORT_FOLIO_AT_RISK_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.ActiveMember.path,
		element: <ACTIVE_MEMBER.ACTIVE_MEMBER_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.MonthlyProgress.path,
		element: <MONTHLY_PROGRESS.MONTHLY_PROGRESS_PAGE />,
		exact: true,
	},

	{
		path: demoPages.reportPages.subMenu.OverdueReport.path,
		element: <OVER_DUE_REPORT.OVER_DUE_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.LoanDisbursement.path,
		element: <LOAN_DISBURSEMENT_REPORT.LOAN_DISBURSEMENT_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.AuditTrailReport.path,
		element: <AUDIT_TRAIL_REPORT.AUDIT_TRAIL_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.CreditBureauReport.path,
		element: <CREDIT_BUREAU_REPORT.CREDIT_BUREAU_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.AuditingToolReport.path,
		element: <AUDITING_TOOL_REPORT.AUDITING_TOOL_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.WalletCollectionReport.path,
		element: <WALLET_COLLECTION_REPORT.WALLET_COLLECTION_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.MemberRejectionReport.path,
		element: <MEMBER_REJECTION_REPORT.MEMBER_REJECTION_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.QrReport.path,
		element: <QR_REPORT.QR_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.LeadReport.path,
		element: <LEAD_REPORT.LEAD_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.InsuranceReport.path,
		element: <INSURANCE_REPORT.INSURANCE_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.ChangeMeetingReport.path,
		element: <CHANGE_MEETING_REPORT.CHANGE_MEETING_REPORT_PAGE />,
		exact: true,
	},

	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];
const documentation = [
	{
		path: componentsMenu.notification.path,
		element: <EXTRA.NOTIFICATION />,
		exact: true,
	},
	{
		path: componentsMenu.hooks.path,
		element: <EXTRA.HOOKS />,
		exact: true,
	},
];
const contents = [...presentation, ...documentation];

export default contents;
