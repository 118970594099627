/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import CurrentAddressActionType from './current-address.types';
import {
	currentAddressAddSuccess,
	currentAddressAddFailure,
	currentAddressAddUserResponseChanged,
} from './current-address.action';
import { encodeJson } from '../../../enode-decode';
import { currentMemberAddressAdd } from './current-address.service';

// Start Residential Details Add
export function* AddCurrentAddress({ payload }) {
	try {
		const key = 'AddMemberCurrentAddress';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberCurrentPinCode: payload.pincode,
			MemberCurrentDoorNo: payload.doorNo,
			MemberCurrentAddressStreet: payload.addressStreet,
			MemberCurrentCountry: payload.country,
			MemberCurrentState: payload.state,
			MemberCurrentDistrict: payload.district,
			MemberCurrentCity: payload.city,
			MemberCurrentLocalArea: payload.village,
			MemberCurrentSinceYr: payload.since,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(currentMemberAddressAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(currentAddressAddFailure(responseData.data));
		} else {
			yield put(currentAddressAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(currentAddressAddFailure(error));
	}
}

export function* AddCurrentAddressResponseClear() {
	yield put(currentAddressAddUserResponseChanged());
}

export function* onCurrentAddressAddStart() {
	yield takeLatest(CurrentAddressActionType.CURRENT_ADDRESS_ADD_START, AddCurrentAddress);
}

export function* onCurrentAddressAddResponseRest() {
	yield takeLatest(
		CurrentAddressActionType.CURRENT_ADDRESS_ADD_RESPONSE_REST_START,
		AddCurrentAddressResponseClear,
	);
}
// End Residential Details Add

export function* currentAddressSaga() {
	yield all([call(onCurrentAddressAddStart), call(onCurrentAddressAddResponseRest)]);
}
