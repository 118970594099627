const trainingActionTypes = {
	TRAINING_PROPOSAL_BRANCH_START: 'TRAINING_PROPOSAL_BRANCH_START',
	TRAINING_PROPOSAL_BRANCH_SUCCESS: 'TRAINING_PROPOSAL_BRANCH_SUCCESS',
	TRAINING_PROPOSAL_BRANCH_FAILURE: 'TRAINING_PROPOSAL_BRANCH_FAILURE',
	TRAINING_PROPOSAL_BRANCH_RESET: 'TRAINING_PROPOSAL_BRANCH_RESET',
	TRAINING_PROPOSAL_BRANCH_RESPONSE_CHANGED: 'TRAINING_PROPOSAL_BRANCH_RESPONSE_CHANGED',

	TRAINING_PROPOSAL_EXCEL_REPORT_START: 'TRAINING_PROPOSAL_EXCEL_REPORT_START',
	TRAINING_PROPOSAL_EXCEL_REPORT_SUCCESS: 'TRAINING_PROPOSAL_EXCEL_REPORT_SUCCESS',
	TRAINING_PROPOSAL_EXCEL_REPORT_FAILURE: 'TRAINING_PROPOSAL_EXCEL_REPORT_FAILURE',
	TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_RESET: 'TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_RESET',
	TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_CHANGED:
		'TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_CHANGED',
};
export default trainingActionTypes;
