/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import NomineeDetailsActionType from './basic-nominee-details.types';
import { decodeApiResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	nomineeAddressData: null,
	nomineeDetailsResponse: null,
	nomineeAddressListResponse: null,
	nomineeAddressList: [],
	verifyNomineeAadharOtpResp: null,
	verifyNomineeAadharOtpResult: [],
	verifyValidateNomineeAadharOtpResp: null,
	verifyValidateNomineeAadharOtpResult: [],
	fetchNomineeRelationshipResp: null,
	fetchNomineeRelationshipArr: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NomineeDetailsActionType.NOMINEE_DETAILS_ADD_CHANGED:
			return { ...state, nomineeAddressData: action.payload };

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADD_SUCCESS:
			return { ...state, nomineeDetailsResponse: action.payload };

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADD_FAILURE:
			return { ...state, nomineeDetailsResponse: action.payload };

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADD_RESPONSE_CHANGED:
			return { ...state, nomineeDetailsResponse: null };

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_SUCCESS:
			return {
				...state,
				nomineeAddressListResponse: action.payload,
				nomineeAddressList: decodeApiResponse(action.payload, 'FetchMemberDataKey'),
			};

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_FAILURE:
			return { ...state, nomineeAddressListResponse: action.payload };

		case NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_CHANGED:
			return { ...state, nomineeAddressListResponse: null };

		case NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyNomineeAadharOtpResp: action.payload,
				verifyNomineeAadharOtpResult: decodeApiResponse(action.payload, 'AppVerifyKYCKey'),
			};

		case NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_FAILURE:
			return { ...state, verifyNomineeAadharOtpResp: action.payload };

		case NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyNomineeAadharOtpResp: null };

		case NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyValidateNomineeAadharOtpResp: action.payload,
				verifyValidateNomineeAadharOtpResult: decodeApiResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_FAILURE:
			return { ...state, verifyValidateNomineeAadharOtpResp: action.payload };

		case NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyValidateNomineeAadharOtpResp: null };

		case NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_SUCCESS:
			return {
				...state,
				fetchNomineeRelationshipResp: action.payload,
				fetchNomineeRelationshipArr: decodeApiResponse(
					action.payload,
					'FetchMemberDataKey',
				),
			};

		case NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_FAILURE:
			return { ...state, fetchNomineeRelationshipResp: action.payload };

		case NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_CHANGED:
			return { ...state, fetchNomineeRelationshipResp: null };

		default:
			return state;
	}
};
