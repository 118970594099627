/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import MemberRejectionReportActionTypes from './memberRejectionReport.types';

import { fetchMemberRejectionReport, exportMemberRejectionExcel, fetchMemberRejectionReasoncount } from './memberRejectionReport.service';

import {
	fetchMemberRejectionReportSuccess,
	fetchMemberRejectionReportFailure,
	fetchMemberRejectionReportResponseChanged,
	exportMemberRejectionReportResponseChanged,
	exportMemberRejectionReportSuccess,
	exportMemberRejectionReportFailure,
	fetchMemberRejectionReasoncountSuccess,
	fetchMemberRejectionReasoncountFailure,
	fetchMemberRejectionReasoncountResponseChanged,
} from './memberRejectionReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* fetchMemberrejectionReportDetails({ payload }) {
	try {
		const key = 'MemberRejectionReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			Reason: payload.reason,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberRejectionReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberRejectionReportFailure(responseData.data));
		} else {
			yield put(fetchMemberRejectionReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberRejectionReportFailure(error));
	}
}

export function* resetFetchMRRReportResponse() {
	yield put(fetchMemberRejectionReportResponseChanged());
}

export function* onFetchMemberRejectionReport() {
	yield takeLatest(
		MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_START,
		fetchMemberrejectionReportDetails,
	);
}

export function* onFetchMemberRejectionReportResponseReset() {
	yield takeLatest(
		MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_RESPONSE_RESET,
		resetFetchMRRReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* exportMemberrejectionReportDetails({ payload }) {
	try {
		const key = 'RejectionMembersExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			Reason: payload.reason,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			RequestID: payload.requestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(exportMemberRejectionExcel, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(exportMemberRejectionReportFailure(responseData.data));
		} else {
			yield put(exportMemberRejectionReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(exportMemberRejectionReportFailure(error));
	}
}

export function* resetExportMRRReportResponse() {
	yield put(exportMemberRejectionReportResponseChanged());
}

export function* onExportMemberRejectionReport() {
	yield takeLatest(
		MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_START,
		exportMemberrejectionReportDetails,
	);
}

export function* onExportMemberRejectionReportResponseReset() {
	yield takeLatest(
		MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_RESET,
		resetExportMRRReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */

/** *********************  START FETCH REJECTION REASON COUNT DETAILS ******************** */
export function* fetchMemberrejectionReasoncountDetails({ payload }) {
	try {
		const key = 'MemberRejectionReasoncountKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			Reason: payload.reason,
			DatabaseName: payload.databaseName,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberRejectionReasoncount, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberRejectionReasoncountFailure(responseData.data));
		} else {
			yield put(fetchMemberRejectionReasoncountSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberRejectionReasoncountFailure(error));
	}
}

export function* resetFetchMRRReasoncountResponse() {
	yield put(fetchMemberRejectionReasoncountResponseChanged());
}

export function* onFetchMemberRejectionReasoncount() {
	yield takeLatest(
		MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_START,
		fetchMemberrejectionReasoncountDetails,
	);
}

export function* fetchMemberRejectionReasoncountResponseReset() {
	yield takeLatest(
		MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_RESET,
		resetFetchMRRReasoncountResponse,
	);
}
/** ********************* END FETCH REJECTION REASON COUNT DETAILS ******************** */

export function* memberRejectionReportSaga() {
	yield all([
		call(onFetchMemberRejectionReport),
		call(onFetchMemberRejectionReportResponseReset),
		call(onExportMemberRejectionReport),
		call(onExportMemberRejectionReportResponseReset),
		call(onFetchMemberRejectionReasoncount),
		call(fetchMemberRejectionReasoncountResponseReset),
	]);
}
