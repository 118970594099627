/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import arrearCollectionActionTypes from './arrearCollection.types';

/** ***************** GET MEMBER LOAN INFO START *********************** */
export const fetchArrearMemberLoanInfoStart = (getData) => ({
	type: arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_START,
	payload: getData,
});

export const fetchArrearMemberLoanInfoSuccess = (successData) => ({
	type: arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const fetchArrearMemberLoanInfoFailure = (failureData) => ({
	type: arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchArrearMemberLoanInfoResponseResetStart = () => ({
	type: arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_RESPONSE_RESET,
});

export const fetchArrearMemberLoanInfoResponseChanged = () => ({
	type: arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN INFO END *********************** */

/** ***************** UPDATE ARREAR COLLECTION START *********************** */
export const updateArrearCollectionStart = (getData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_START,
	payload: getData,
});

export const updateArrearCollectionSuccess = (successData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_SUCCESS,
	payload: successData,
});

export const updateArrearCollectionFailure = (failureData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_FAILURE,
	payload: failureData,
});

export const updateArrearCollectionReset = () => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_RESET,
});

export const updateArrearCollectionChanged = () => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_CHANGED,
});
/** ***************** UPDATE ARREAR COLLECTION END *********************** */

/** ***************** UPDATE ARREAR COLLECTION START *********************** */
export const updateArrearCollectionRevertStart = (getData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_START,
	payload: getData,
});

export const updateArrearCollectionRevertSuccess = (successData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_SUCCESS,
	payload: successData,
});

export const updateArrearCollectionRevertFailure = (failureData) => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_FAILURE,
	payload: failureData,
});

export const updateArrearCollectionRevertResponseResetStart = () => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_RESET,
});

export const updateArrearCollectionRevertResponseChanged = () => ({
	type: arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_CHANGED,
});
/** ***************** UPDATE ARREAR COLLECTION END *********************** */
