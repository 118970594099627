/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import MemberMandatoryAccessActionTypes from './memberMandatoryAccess.types';

import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchMandatoryMemberAccessResponse: null,
	fetchMandatoryMemberAccessArr: [],
	updateMandatoryMemberAccessResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_SUCCESS:
			return {
				...state,
				fetchMandatoryMemberAccessResponse: action.payload,
				fetchMandatoryMemberAccessArr: docodeResponse(action.payload, 'MemberMandatoryAccessKey'),
			};

		case MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_FAILURE:
			return { ...state, fetchMandatoryMemberAccessResponse: action.payload,
				fetchMandatoryMemberAccessArr: [],
			 };

		case MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED:
			return {
				...state,
				fetchMandatoryMemberAccessResponse: null,
			};

		case MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_SUCCESS:
			return {
				...state,
				updateMandatoryMemberAccessResponse: action.payload,
				
			};

		case MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_FAILURE:
			return { ...state, updateMandatoryMemberAccessResponse: action.payload };

		case MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED:
			return {
				...state,
				updateMandatoryMemberAccessResponse: null,
			};
		default:
			return state;
	}
};
