import { takeLatest, put, all, call } from 'redux-saga/effects';

import DashboardTypes from './Dashboard.types';
import {
	dashboardDetailsGet,
	loanDetailsGet,
	loanIndexDetailsGet,
	companyEodStatusGet,
	dashboardWorkflowDetailsGet,
	todayGoldPriceGet,
	dashboardDayWiseExcelApi,
	dashboardDayWiseExcelJsonApi,
	todayGoldPriceUpdate,
	pdfEodStatus,
	rejectMemberListCall,
	dashboardParticularListCall,
	dashboardParticularDemandListCall,
	dashboardStaffCollectionListCall,
	dashboardStaffLocationListCall,
	dashboardParticularDateDemandListCall,
	dashboardParticularDateDemandMemberListCall,
	dashboardParticularDateMemberAbsentListCall,
	dashboardParticularDateCollectionListCall,
	dashboardParticularMemberCollectionListCall,
	dashboardWeekCollectionListCall,
	dashboardWeekYTCollectionListCall,
	smsUsedListCall,
	kycUsedListCall,
	arrearCollectionListCall,
	dashboardEodExportCall,
	dashboardStaffMapCollectionCall,
	dashboardCollectionNoteInfoCall,
} from './Dashboard.sarvices';
import {
	getLoanDetailsSuccess,
	getLoanDetailsFailure,
	getLoanDetailsResponseChanged,
	getDashboardDetailsSuccess,
	getDashboardDetailsFailure,
	getDashboardDetailsResponseChanged,
	getLoanIndexDetailsSuccess,
	getLoanIndexDetailsFailure,
	getLoanIndexDetailsResponseChanged,
	companyEodStatusSuccess,
	companyEodStatusFailure,
	companyEodStatusResponseChanged,
	getWorkflowDashboardDetailsSuccess,
	getWorkflowDashboardDetailsFailure,
	getWorkflowDashboardDetailsResponseChanged,
	getTodayGoldPriceSuccess,
	getTodayGoldPriceFailure,
	getTodayGoldPriceResponseChanged,
	getLoanDetailsExportSuccess,
	getLoanDetailsExportFailure,
	getLoanDetailsExportResponseChanged,
	getLoanDetailsExportJsonSuccess,
	getLoanDetailsExportJsonFailure,
	getLoanDetailsExportJsonChanged,
	updateTodayGoldPriceSuccess,
	updateTodayGoldPriceFailure,
	updateTodayGoldPriceResponseChanged,
	eodStatusPdfSuccess,
	eodStatusPdfFailure,
	eodStatusPdfResponseChanged,
	rejectMemberSuccess,
	rejectMemberFailure,
	rejectMemberChanged,
	getDashboardParticularDetailsSuccess,
	getDashboardParticularDetailsFailure,
	getDashboardParticularDetailsChanged,
	getDashboardParticularDemandDetailsSuccess,
	getDashboardParticularDemandDetailsFailure,
	getDashboardParticularDemandDetailsChanged,
	getDashboardStaffCollectionSuccess,
	getDashboardStaffCollectionFailure,
	getDashboardStaffCollectionChanged,
	getStaffLocationSuccess,
	getStaffLocationFailure,
	getStaffLocationChanged,
	rejectNotesMemberSuccess,
	rejectNotesMemberFailure,
	rejectNotesMemberChanged,
	// DEMAND SECTION
	getParticularDateDemandSuccess,
	getParticularDateDemandFailure,
	getParticularDateDemandChanged,
	getParticularDateDemandInfoSuccess,
	getParticularDateDemandInfoFailure,
	getParticularDateDemandInfoChanged,
	getParticularDateMemberAbsentSuccess,
	getParticularDateMemberAbsentFailure,
	getParticularDateMemberAbsentChanged,
	// COLLECTION SECTION
	getParticularDateCollectionSuccess,
	getParticularDateCollectionFailure,
	getParticularDateCollectionChanged,
	getParticularDateCollectionInfoSuccess,
	getParticularDateCollectionInfoFailure,
	getParticularDateCollectionInfoChanged,
	getWeekCollectionSuccess,
	getWeekCollectionFailure,
	getWeekCollectionChanged,
	getWeekYTCollectionSuccess,
	getWeekYTCollectionFailure,
	getWeekYTCollectionChanged,
	getSMSUsedListSuccess,
	getSMSUsedListFailure,
	getSMSUsedListChanged,
	getKYCUsedListSuccess,
	getKYCUsedListFailure,
	getKYCUsedListChanged,
	getArrearCollectionListSuccess,
	getArrearCollectionListFailure,
	getArrearCollectionListChanged,
	getDashboardEodExportSuccess,
	getDashboardEodExportFailure,
	getDashboardEodExportChanged,
	getDashboardStaffMapCollectionSuccess,
	getDashboardStaffMapCollectionFailure,
	getDashboardStaffMapCollectionChanged,
	getDashboardCollectionNoteInfoSuccess,
	getDashboardCollectionNoteInfoFailure,
	getDashboardCollectionNoteInfoChanged,
} from './Dashboard.actions';
import { encodeJson } from '../enode-decode';

/** *********************  START LOAN DETAILS LIST  ******************** */
export function* getDashboardDetails({ payload }) {
	try {
		const key = 'DASHBOARDCARDKEY';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			Level: payload.userLevel,
			AreaID: payload.userAccessBranchID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardDetailsFailure(responseData.data));
		} else {
			yield put(getDashboardDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardDetailsFailure(error));
	}
}

export function* resetDashboardDetailsResponse() {
	yield put(getDashboardDetailsResponseChanged());
}

export function* onGetDashboardDetails() {
	yield takeLatest(DashboardTypes.GET_DASHBOARD_DETAILS_START, getDashboardDetails);
}

export function* onGetDashboardDetailsResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_DETAILS_RESPONSE_RESET,
		resetDashboardDetailsResponse,
	);
}

/** *********************  END LOAN DETAILS LIST  ******************** */

/** *********************  START LOAN DETAILS LIST  ******************** */
export function* getLoanDetails({ payload }) {
	try {
		const key = 'DASHBOARDLOANKEY';
		const SetWeekArray = JSON.stringify(payload.SetWeekArray).replace(/"/g, "'");
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			UserRoleName: payload.userRole,
			UserLevel: payload.userLevel,
			LoanType: payload.loanType,
			SetWeekArray,
			DatabaseName: payload.databaseName,
			ByCenterGroup: payload.byCenterGroup,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDetailsFailure(responseData.data));
		} else {
			yield put(getLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDetailsFailure(error));
	}
}

export function* resetLoanDetailsResponse() {
	yield put(getLoanDetailsResponseChanged());
}

export function* onGetLoanDetails() {
	yield takeLatest(DashboardTypes.GET_LOAN_DETAILS_START, getLoanDetails);
}

export function* onGetLoanDetailsResponseReset() {
	yield takeLatest(DashboardTypes.GET_LOAN_DETAILS_RESPONSE_RESET, resetLoanDetailsResponse);
}

/** *********************  END LOAN DETAILS LIST  ******************** */

/** *********************  START LOAN INDEX DETAILS LIST  ******************** */
export function* getLoanIndexDetails({ payload }) {
	try {
		const key = 'DashboardLoanIndexDetailsKey';
		const SetWeekArray = JSON.stringify(payload.SetWeekArray).replace(/"/g, "'");
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			UserRoleName: payload.userRole,
			UserLevel: payload.userLevel,
			LoanType: payload.loanType,
			SetWeekArray,
			DatabaseName: payload.databaseName,
			Limit: payload.limit,
			SearchLoanIndex: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanIndexDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanIndexDetailsFailure(responseData.data));
		} else {
			yield put(getLoanIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanIndexDetailsFailure(error));
	}
}

export function* resetLoanIndexDetailsResponse() {
	yield put(getLoanIndexDetailsResponseChanged());
}

export function* onGetLoanIndexDetails() {
	yield takeLatest(DashboardTypes.GET_LOAN_INDEX_DETAILS_START, getLoanIndexDetails);
}

export function* onGetLoanIndexDetailsResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_LOAN_INDEX_DETAILS_RESPONSE_RESET,
		resetLoanIndexDetailsResponse,
	);
}

/** *********************  END LOAN INDEX DETAILS LIST  ******************** */

/** *********************  START COMPANY EOD STATUS  ******************** */
export function* companyEodStatus({ payload }) {
	try {
		const key = 'DashboardEODStatusDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			eodDate: payload.date,
			BranchID: payload.branchID,
			LimitEOD: payload.limitEOD,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(companyEodStatusGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(companyEodStatusFailure(responseData.data));
		} else {
			yield put(companyEodStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(companyEodStatusFailure(error));
	}
}

export function* resetCompanyEodStatusDetailsResponse() {
	yield put(companyEodStatusResponseChanged());
}

export function* onGetCompanyEodStatus() {
	yield takeLatest(DashboardTypes.COMPANY_EOD_STATUS_START, companyEodStatus);
}

export function* onGetCompanyEodStatusResponseReset() {
	yield takeLatest(
		DashboardTypes.COMPANY_EOD_STATUS_RESPONSE_RESET,
		resetCompanyEodStatusDetailsResponse,
	);
}

/** *********************  END COMPANY EOD STATUS ******************** */

/** *********************  START WORKFLOW DASHBOARD DETAILS LIST  ******************** */
export function* getWorkflowDashboardDetails({ payload }) {
	try {
		const key = 'GetWorkFlowDashboardDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			setWorkFlowFromDate: payload.date,
			setWorkFlowToDate: payload.todate,
			funderWorkFlow: payload.loanFunder,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardWorkflowDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanIndexDetailsFailure(responseData.data));
		} else {
			yield put(getWorkflowDashboardDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getWorkflowDashboardDetailsFailure(error));
	}
}

export function* resetWorkflowDashboardDetailsResponse() {
	yield put(getWorkflowDashboardDetailsResponseChanged());
}

export function* onGetWorkflowDashboardDetails() {
	yield takeLatest(
		DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_START,
		getWorkflowDashboardDetails,
	);
}

export function* onGetWorkflowDashboardDetailsResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_RESET,
		resetWorkflowDashboardDetailsResponse,
	);
}

/** *********************  END WORKFLOW DASHBOARD DETAILS LIST  ******************** */

/** *********************  START TODAY GOLG PRICE LIST  ******************** */
export function* getTodayGoldPrice({ payload }) {
	try {
		const key = 'GetTodayGoldPriceKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(todayGoldPriceGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getTodayGoldPriceFailure(responseData.data));
		} else {
			yield put(getTodayGoldPriceSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTodayGoldPriceFailure(error));
	}
}

export function* resetTodayGoldPriceResponse() {
	yield put(getTodayGoldPriceResponseChanged());
}

export function* onGetTodayGoldPrice() {
	yield takeLatest(DashboardTypes.GET_TODAY_GOLD_PRICE_START, getTodayGoldPrice);
}

export function* onGetTodayGoldPriceResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_TODAY_GOLD_PRICE_RESPONSE_RESET,
		resetTodayGoldPriceResponse,
	);
}

/** *********************  END TODAY GOLG PRICE LIST  ******************** */

/** *********************  START LOAN DETAILS EXPORT LIST  ******************** */
export function* getLoanDetailsExport({ payload }) {
	try {
		const key = 'DashboardDayWiseExcelKey';
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			FunderID: payload.funderID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardDayWiseExcelApi, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDetailsExportFailure(responseData.data));
		} else {
			yield put(getLoanDetailsExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDetailsExportFailure(error));
	}
}

export function* resetLoanDetailsExportResponse() {
	yield put(getLoanDetailsExportResponseChanged());
}

export function* onGetLoanDetailsExport() {
	yield takeLatest(DashboardTypes.GET_LOAN_DETAILS_EXPORT_START, getLoanDetailsExport);
}

export function* onGetLoanDetailsExportResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_LOAN_DETAILS_EXPORT_RESPONSE_RESET,
		resetLoanDetailsExportResponse,
	);
}

export function* getLoanDetailsExportJson({ payload }) {
	try {
		const key = 'DashboardLoanDetailsExportKey';
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			LoanType: payload.loanType === 'IndividualLoan' ? 'PersonalLoan' : payload.loanType,
			BranchID: payload.branchID,
			FunderID: payload.funderID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardDayWiseExcelJsonApi, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDetailsExportJsonFailure(responseData.data));
		} else {
			yield put(getLoanDetailsExportJsonSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDetailsExportJsonFailure(error));
	}
}

export function* resetLoanDetailsExportJsonResponse() {
	yield put(getLoanDetailsExportJsonChanged());
}

export function* onGetLoanDetailsExportJson() {
	yield takeLatest(DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_START, getLoanDetailsExportJson);
}

export function* onGetLoanDetailsExportJsonResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_RESET,
		resetLoanDetailsExportJsonResponse,
	);
}

/** *********************  END LOAN DETAILS EXPORT LIST  ******************** */

/** *********************  START TODAY GOLG PRICE LIST  ******************** */
export function* updateTodayGoldPrice({ payload }) {
	try {
		const key = 'UpdateTodayGoldPriceKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			TodayGoldPrice: payload.goldPriceToday,
			TodayGoldPrice22k: payload.goldPrice22kToday,
			TodayGoldPrice20k: payload.goldPrice20kToday,
			TodayGoldPrice18k: payload.goldPrice18kToday,
			TodayGoldPrice16k: payload.goldPrice16kToday,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(todayGoldPriceUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateTodayGoldPriceFailure(responseData.data));
		} else {
			yield put(updateTodayGoldPriceSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateTodayGoldPriceFailure(error));
	}
}

export function* resetUpdateTodayGoldPriceResponse() {
	yield put(updateTodayGoldPriceResponseChanged());
}

export function* onUpdateTodayGoldPrice() {
	yield takeLatest(DashboardTypes.UPDATE_TODAY_GOLD_PRICE_START, updateTodayGoldPrice);
}

export function* onUpdateTodayGoldPriceResponseReset() {
	yield takeLatest(
		DashboardTypes.UPDATE_TODAY_GOLD_PRICE_RESPONSE_RESET,
		resetUpdateTodayGoldPriceResponse,
	);
}

/** *********************  END TODAY GOLG PRICE LIST  ******************** */

/** *********************  START TODAY GOLG PRICE LIST  ******************** */
export function* eodStatusPdf({ payload }) {
	try {
		const key = 'EODStatusPDFKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			branchID: payload.branchID,
			CompanyID: payload.companyID,
			eodDate: payload.eodDate,
			Level: payload.Level,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(pdfEodStatus, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(eodStatusPdfFailure(responseData.data));
		} else {
			yield put(eodStatusPdfSuccess(responseData.data));
		}
	} catch (error) {
		yield put(eodStatusPdfFailure(error));
	}
}

export function* resetEodStatusPdfResponse() {
	yield put(eodStatusPdfResponseChanged());
}

export function* onEodStatusPdf() {
	yield takeLatest(DashboardTypes.EOD_STATUS_PDF_START, eodStatusPdf);
}

export function* onEodStatusPdfResponseReset() {
	yield takeLatest(DashboardTypes.EOD_STATUS_PDF_RESPONSE_RESET, resetEodStatusPdfResponse);
}

/** *********************  END TODAY GOLG PRICE LIST  ******************** */

/** *********************  REJECT MEMBER LIST  ******************** */
export function* rejectMemberList({ payload }) {
	try {
		const key = 'RejectedMembersDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			Level: payload.Level,
			BranchID: payload.branchIDs,
			CenterID: 'All',
			UserID: payload.userID,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rejectMemberListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(rejectMemberFailure(responseData.data));
		} else {
			yield put(rejectMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(rejectMemberFailure(error));
	}
}

export function* resetRejectMemberList() {
	yield put(rejectMemberChanged());
}

export function* onRejectMemberList() {
	yield takeLatest(DashboardTypes.REJECT_MEMBER_LIST_START, rejectMemberList);
}

export function* onRejectMemberListResponseReset() {
	yield takeLatest(DashboardTypes.REJECT_MEMBER_LIST_RESET, resetRejectMemberList);
}

/** *********************  END REJECT MEMBER LIST   ******************** */

/** *********************  REJECT NOTES MEMBER LIST  ******************** */
export function* rejectNotesMemberList({ payload }) {
	try {
		const key = 'RejectedMembersDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			Level: payload.Level,
			BranchID: payload.branchIDs,
			CenterID: 'All',
			UserID: payload.userID,
			UserRole: payload.userRole,
			RejectNotes: payload.notes,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rejectMemberListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(rejectNotesMemberFailure(responseData.data));
		} else {
			yield put(rejectNotesMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(rejectNotesMemberFailure(error));
	}
}

export function* resetRejectNotesMemberList() {
	yield put(rejectNotesMemberChanged());
}

export function* onRejectNotesMemberList() {
	yield takeLatest(DashboardTypes.REJECT_NOTES_MEMBER_LIST_START, rejectNotesMemberList);
}

export function* onRejectNotesMemberListResponseReset() {
	yield takeLatest(DashboardTypes.REJECT_NOTES_MEMBER_LIST_RESET, resetRejectNotesMemberList);
}

/** *********************  END REJECT NOTES MEMBER LIST   ******************** */

/** *********************  DASHBOARD PARTICULAR LIST  ******************** */
export function* dashboardParticularList({ payload }) {
	try {
		const key = 'DashboardCardBranchwiseKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			FunderID: payload.funderID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardParticularDetailsFailure(responseData.data));
		} else {
			yield put(getDashboardParticularDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardParticularDetailsFailure(error));
	}
}

export function* resetDashboardParticularList() {
	yield put(getDashboardParticularDetailsChanged());
}

export function* onDashboardParticularDetails() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_START,
		dashboardParticularList,
	);
}

export function* onDashboardParticularDetailsResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_RESET,
		resetDashboardParticularList,
	);
}

export function* dashboardParticularDemandList({ payload }) {
	try {
		const key = 'DashboardAmountBasedonlocationKey';
		const SetWeekArray = JSON.stringify(payload.SetWeekArray).replace(/"/g, "'");
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			FunderID: payload.funderID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			ByCenterGroup: payload.byCenterGroup,
			LoanType: payload.loanType,
			SetWeekArray,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularDemandListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardParticularDemandDetailsFailure(responseData.data));
		} else {
			yield put(getDashboardParticularDemandDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardParticularDemandDetailsFailure(error));
	}
}

export function* resetDashboardParticularDemandList() {
	yield put(getDashboardParticularDemandDetailsChanged());
}

export function* onDashboardParticularDemandDetails() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_START,
		dashboardParticularDemandList,
	);
}

export function* onDashboardParticularDemandDetailsResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_RESET,
		resetDashboardParticularDemandList,
	);
}

/** *********************  END DASHBOARD PARTICULAR LIST   ******************** */

/** *********************  DASHBOARD STAFF COLLECTION LIST  ******************** */
export function* dashboardStaffCollectionList({ payload }) {
	try {
		const key = 'StaffTotalcollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			FunderID: payload.funderID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardStaffCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardStaffCollectionFailure(responseData.data));
		} else {
			yield put(getDashboardStaffCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardStaffCollectionFailure(error));
	}
}

export function* resetDashboardStaffCollectionList() {
	yield put(getDashboardStaffCollectionChanged());
}

export function* onDashboardStaffCollection() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_START,
		dashboardStaffCollectionList,
	);
}

export function* onDashboardStaffCollectionResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_RESET,
		resetDashboardStaffCollectionList,
	);
}

/** *********************  END DASHBOARD STAFF COLLECTION LIST   ******************** */
/** *********************  DASHBOARD STAFF LOCATION LIST  ******************** */
export function* dashboardStaffLocationList({ payload }) {
	try {
		const key = 'GetCompanyBasedOnStaffLocationKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			BranchID: payload.branchID !== undefined ? payload.branchID : 0,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardStaffLocationListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffLocationFailure(responseData.data));
		} else {
			yield put(getStaffLocationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffLocationFailure(error));
	}
}

export function* resetDashboardStaffLocationList() {
	yield put(getStaffLocationChanged());
}

export function* onDashboardStaffLocation() {
	yield takeLatest(DashboardTypes.GET_STAFF_LOCATION_START, dashboardStaffLocationList);
}

export function* onDashboardStaffLocationResponseReset() {
	yield takeLatest(DashboardTypes.GET_STAFF_LOCATION_RESET, resetDashboardStaffLocationList);
}

/** *********************  END DASHBOARD STAFF LOCATION LIST   ******************** */

// DEMAND SECTION

/** *********************  DASHBOARD PARTICULAR DATE DEMAND LIST  ******************** */
export function* dashboardParticularDateDemandList({ payload }) {
	try {
		const key = 'ParticularDateGroupDemandInfoKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			ByCenterGroup: payload.byCenterGroup,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularDateDemandListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularDateDemandFailure(responseData.data));
		} else {
			yield put(getParticularDateDemandSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularDateDemandFailure(error));
	}
}

export function* resetDashboardParticularDateDemandList() {
	yield put(getParticularDateDemandChanged());
}

export function* onParticularDateDemand() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_DEMAND_START,
		dashboardParticularDateDemandList,
	);
}

export function* onParticularDateDemandResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_DEMAND_RESET,
		resetDashboardParticularDateDemandList,
	);
}

/** *********************  END DASHBOARD PARTICULAR DATE DEMAND LIST   ******************** */

/** *********************  DASHBOARD PARTICULAR DATE DEMAND INFO LIST  ******************** */
export function* dashboardParticularDateDemandInfoList({ payload }) {
	try {
		const key = 'ParticularDateGroupMemberInfoKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			ByCenterGroup: payload.byCenterGroup,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularDateDemandMemberListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularDateDemandInfoFailure(responseData.data));
		} else {
			yield put(getParticularDateDemandInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularDateDemandInfoFailure(error));
	}
}

export function* resetDashboardParticularDateDemandInfoList() {
	yield put(getParticularDateDemandInfoChanged());
}

export function* onParticularDateDemandInfo() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_START,
		dashboardParticularDateDemandInfoList,
	);
}

export function* onParticularDateDemandInfoResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_RESET,
		resetDashboardParticularDateDemandInfoList,
	);
}

/** *********************  END DASHBOARD PARTICULAR DATE DEMAND INFO LIST   ******************** */

/** *********************  DASHBOARD PARTICULAR DATE MEMBER ABSENT LIST  ******************** */
export function* dashboardParticularDateMemberAbsentList({ payload }) {
	try {
		const key = 'DashboardMemberAbsentDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DateFilter: payload.DateFilter,
			FromDate: payload.payDate,
			LoanIndexGroupMemberID: payload.ligmID,
			MemberID: payload.memberID,
			ToDate: payload.payDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularDateMemberAbsentListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularDateMemberAbsentFailure(responseData.data));
		} else {
			yield put(getParticularDateMemberAbsentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularDateMemberAbsentFailure(error));
	}
}

export function* resetDashboardParticularDateMemberAbsentList() {
	yield put(getParticularDateMemberAbsentChanged());
}

export function* onParticularDateMemberAbsent() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_START,
		dashboardParticularDateMemberAbsentList,
	);
}

export function* onParticularDateMemberAbsentResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_RESET,
		resetDashboardParticularDateMemberAbsentList,
	);
}

/** *********************  END DASHBOARD PARTICULAR DATE MEMBER ABSENT LIST   ******************** */

// COLLECTION SECTION

/** *********************  DASHBOARD PARTICULAR DATE COLLECTION LIST  ******************** */
export function* dashboardParticularDateCollectionList({ payload }) {
	try {
		const key = 'DashboardParticularDateGroupCollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			ByCenterGroup: payload.byCenterGroup,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularDateCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularDateCollectionFailure(responseData.data));
		} else {
			yield put(getParticularDateCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularDateCollectionFailure(error));
	}
}

export function* resetDashboardParticularDateCollectionList() {
	yield put(getParticularDateCollectionChanged());
}

export function* onParticularDateCollection() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_START,
		dashboardParticularDateCollectionList,
	);
}

export function* onParticularDateCollectionResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_RESET,
		resetDashboardParticularDateCollectionList,
	);
}

/** *********************  END DASHBOARD PARTICULAR DATE COLLECTION LIST   ******************** */

/** *********************  DASHBOARD PARTICULAR DATE COLLECTION INFO LIST  ******************** */
export function* dashboardParticularDateCollectionInfoList({ payload }) {
	try {
		const key = 'GetParticularDateGroupMemberCollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			ByCenterGroup: payload.byCenterGroup,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardParticularMemberCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularDateCollectionInfoFailure(responseData.data));
		} else {
			yield put(getParticularDateCollectionInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularDateCollectionInfoFailure(error));
	}
}

export function* resetDashboardParticularDateCollectionInfoList() {
	yield put(getParticularDateCollectionInfoChanged());
}

export function* onParticularDateCollectionInfo() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_START,
		dashboardParticularDateCollectionInfoList,
	);
}

export function* onParticularDateCollectionInfoResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_RESET,
		resetDashboardParticularDateCollectionInfoList,
	);
}

/** *********************  END DASHBOARD PARTICULAR DATE COLLECTION INFO LIST   ******************** */
/** *********************  DASHBOARD WEEK COLLECTION LIST  ******************** */
export function* dashboardWeekCollectionList({ payload }) {
	try {
		const key = 'CollectionAmountBranchMemberWiseKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			CollectionDate: payload.date,
			CollectionMode: 'All',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardWeekCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getWeekCollectionFailure(responseData.data));
		} else {
			yield put(getWeekCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getWeekCollectionFailure(error));
	}
}

export function* resetDashboardWeekCollectionList() {
	yield put(getWeekCollectionChanged());
}

export function* onWeekCollection() {
	yield takeLatest(DashboardTypes.GET_WEEK_COLLECTION_START, dashboardWeekCollectionList);
}

export function* onWeekCollectionResponseReset() {
	yield takeLatest(DashboardTypes.GET_WEEK_COLLECTION_RESET, resetDashboardWeekCollectionList);
}

/** *********************  END DASHBOARD WEEK COLLECTION LIST   ******************** */

/** *********************  DASHBOARD WEEK YET TO COLLECTION LIST  ******************** */
export function* dashboardWeekYTCollectionList({ payload }) {
	try {
		const key = 'GetParticularCollectionPendingGroupMemberKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
			ValueType: 'Yet to Collect',
			LoanIndexGroupMemberIDs: payload.ligms,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardWeekYTCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getWeekYTCollectionFailure(responseData.data));
		} else {
			yield put(getWeekYTCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getWeekYTCollectionFailure(error));
	}
}

export function* resetDashboardWeekYTCollectionList() {
	yield put(getWeekYTCollectionChanged());
}

export function* onWeekYTCollection() {
	yield takeLatest(DashboardTypes.GET_WEEK_YT_COLLECTION_START, dashboardWeekYTCollectionList);
}

export function* onWeekYTCollectionResponseReset() {
	yield takeLatest(
		DashboardTypes.GET_WEEK_YT_COLLECTION_RESET,
		resetDashboardWeekYTCollectionList,
	);
}

/** *********************  END DASHBOARD WEEK YET TO COLLECTION LIST   ******************** */

/** *********************  DASHBOARD SMS USED LIST  ******************** */
export function* smsUsedList({ payload }) {
	try {
		const key = 'DashboardSMSUsedListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			GeneralCompanyID: payload.generalCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(smsUsedListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSMSUsedListFailure(responseData.data));
		} else {
			yield put(getSMSUsedListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSMSUsedListFailure(error));
	}
}

export function* resetSMSUsedList() {
	yield put(getSMSUsedListChanged());
}

export function* onGetSMSUsedList() {
	yield takeLatest(DashboardTypes.SMS_USED_LIST_START, smsUsedList);
}

export function* onGetSMSUsedListResponseReset() {
	yield takeLatest(DashboardTypes.SMS_USED_LIST_RESET, resetSMSUsedList);
}

/** *********************  END DASHBOARD SMS USED LIST   ******************** */

/** *********************  DASHBOARD KYC USED LIST  ******************** */
export function* kycUsedList({ payload }) {
	try {
		const key = 'DashboardKYCUsedListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			GeneralCompanyID: payload.generalCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kycUsedListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getKYCUsedListFailure(responseData.data));
		} else {
			yield put(getKYCUsedListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getKYCUsedListFailure(error));
	}
}

export function* resetKYCUsedList() {
	yield put(getKYCUsedListChanged());
}

export function* onGetKYCUsedList() {
	yield takeLatest(DashboardTypes.KYC_USED_LIST_START, kycUsedList);
}

export function* onGetKYCUsedListResponseReset() {
	yield takeLatest(DashboardTypes.KYC_USED_LIST_RESET, resetKYCUsedList);
}

/** *********************  END DASHBOARD SMS USED LIST   ******************** */

/** *********************  DASHBOARD ARREAR COLLECTION LIST  ******************** */
export function* arrearCollectionList({ payload }) {
	try {
		const key = 'DashboardArrearCollectionListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PayDay: payload.payDay,
			WhenToPay: payload.date,
			ValueType: payload.valueType,
			LoanIndexGroupMemberIDs: payload.ligms,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(arrearCollectionListCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getArrearCollectionListFailure(responseData.data));
		} else {
			yield put(getArrearCollectionListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getArrearCollectionListFailure(error));
	}
}

export function* resetArrearCollectionList() {
	yield put(getArrearCollectionListChanged());
}

export function* onGetArrearCollectionList() {
	yield takeLatest(DashboardTypes.ARREAR_COLLECTION_LIST_START, arrearCollectionList);
}

export function* onGetArrearCollectionListResponseReset() {
	yield takeLatest(DashboardTypes.ARREAR_COLLECTION_LIST_RESET, resetArrearCollectionList);
}

/** *********************  END DASHBOARD ARREAR COLLECTION LIST   ******************** */

/** *********************  DASHBOARD EOD EXPORT S  ******************** */
export function* dashboardEodExport({ payload }) {
	try {
		const key = 'DashboardEodStatusExportKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			FunderID: payload.funderID,
			LoanType: payload.loanType,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			eodDate: payload.eodDate,
			UserAccessBranchID: payload.userAccessBranchID,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardEodExportCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardEodExportFailure(responseData.data));
		} else {
			yield put(getDashboardEodExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardEodExportFailure(error));
	}
}

export function* resetDashboardEodExport() {
	yield put(getDashboardEodExportChanged());
}

export function* onGetDashboardEodExportStart() {
	yield takeLatest(DashboardTypes.DASHBOARD_EOD_EXPORT_START, dashboardEodExport);
}

export function* onGetDashboardEodExportStartResponseReset() {
	yield takeLatest(DashboardTypes.DASHBOARD_EOD_EXPORT_RESET, resetDashboardEodExport);
}

/** *********************  END DASHBOARD EOD EXPORT S   ******************** */

/** *********************  DASHBOARD STAFF MAP COLLECTION  ******************** */
export function* dashboardStaffMapCollection({ payload }) {
	try {
		const key = 'DashboardStaffMapCollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			Day: payload.day,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardStaffMapCollectionCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardStaffMapCollectionFailure(responseData.data));
		} else {
			yield put(getDashboardStaffMapCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardStaffMapCollectionFailure(error));
	}
}

export function* resetDashboardStaffMapCollection() {
	yield put(getDashboardStaffMapCollectionChanged());
}

export function* onGetDashboardStaffMapCollectionStart() {
	yield takeLatest(
		DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_START,
		dashboardStaffMapCollection,
	);
}

export function* onGetDashboardStaffMapCollectionStartResponseReset() {
	yield takeLatest(
		DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_RESET,
		resetDashboardStaffMapCollection,
	);
}

/** *********************  END DASHBOARD STAFF MAP COLLECTION   ******************** */

/** *********************  DASHBOARD COLLECTION NOTE INFO  ******************** */
export function* dashboardCollectionNoteInfo({ payload }) {
	try {
		const key = 'GetCollectionNotesInfoKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			WhenToPay: payload.WhenToPay,
			LoanIndex: payload.LoanIndex,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			Type: payload.type,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardCollectionNoteInfoCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDashboardCollectionNoteInfoFailure(responseData.data));
		} else {
			yield put(getDashboardCollectionNoteInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDashboardCollectionNoteInfoFailure(error));
	}
}

export function* resetDashboardCollectionNoteInfo() {
	yield put(getDashboardCollectionNoteInfoChanged());
}

export function* onGetDashboardCollectionNoteInfoStart() {
	yield takeLatest(
		DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_START,
		dashboardCollectionNoteInfo,
	);
}

export function* onGetDashboardCollectionNoteInfoStartResponseReset() {
	yield takeLatest(
		DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_RESET,
		resetDashboardCollectionNoteInfo,
	);
}

/** *********************  END DASHBOARD COLLECTION NOTE INFO   ******************** */

export function* dashboardSaga() {
	yield all([
		call(onGetDashboardDetails),
		call(onGetDashboardDetailsResponseReset),
		call(onGetLoanDetails),
		call(onGetLoanDetailsResponseReset),
		call(onGetLoanIndexDetails),
		call(onGetLoanIndexDetailsResponseReset),
		call(onGetCompanyEodStatus),
		call(onGetCompanyEodStatusResponseReset),
		call(onGetWorkflowDashboardDetails),
		call(onGetWorkflowDashboardDetailsResponseReset),
		call(onGetTodayGoldPrice),
		call(onGetTodayGoldPriceResponseReset),
		call(onGetLoanDetailsExport),
		call(onGetLoanDetailsExportResponseReset),
		call(onGetLoanDetailsExportJson),
		call(onGetLoanDetailsExportJsonResponseReset),
		call(onUpdateTodayGoldPrice),
		call(onUpdateTodayGoldPriceResponseReset),
		call(onEodStatusPdf),
		call(onEodStatusPdfResponseReset),
		call(onRejectMemberList),
		call(onRejectMemberListResponseReset),
		call(onRejectNotesMemberList),
		call(onRejectNotesMemberListResponseReset),
		call(onDashboardParticularDetails),
		call(onDashboardParticularDetailsResponseReset),
		call(onDashboardParticularDemandDetails),
		call(onDashboardParticularDemandDetailsResponseReset),
		call(onDashboardStaffCollection),
		call(onDashboardStaffCollectionResponseReset),
		call(onDashboardStaffLocation),
		call(onDashboardStaffLocationResponseReset),
		// DEMAND SECTION
		call(onParticularDateDemand),
		call(onParticularDateDemandResponseReset),
		call(onParticularDateDemandInfo),
		call(onParticularDateDemandInfoResponseReset),
		call(onParticularDateMemberAbsent),
		call(onParticularDateMemberAbsentResponseReset),
		// COLLECTION SECTION
		call(onParticularDateCollection),
		call(onParticularDateCollectionResponseReset),
		call(onParticularDateCollectionInfo),
		call(onParticularDateCollectionInfoResponseReset),
		call(onWeekCollection),
		call(onWeekCollectionResponseReset),
		call(onWeekYTCollection),
		call(onWeekYTCollectionResponseReset),
		// SMS USED LIST SECTION
		call(onGetSMSUsedList),
		call(onGetSMSUsedListResponseReset),
		// KYC USED LIST SECTION
		call(onGetKYCUsedList),
		call(onGetKYCUsedListResponseReset),
		// ARREAR COLLECTION LIST SECTION
		call(onGetArrearCollectionList),
		call(onGetArrearCollectionListResponseReset),
		// DASHBOARD EOD STATUS EXPORT SECTION
		call(onGetDashboardEodExportStart),
		call(onGetDashboardEodExportStartResponseReset),
		// DASHBOARD STAFF MAP COLLECTION SECTION
		call(onGetDashboardStaffMapCollectionStart),
		call(onGetDashboardStaffMapCollectionStartResponseReset),
		call(onGetDashboardCollectionNoteInfoStart),
		call(onGetDashboardCollectionNoteInfoStartResponseReset),
	]);
}
