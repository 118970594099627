/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CenterWiseLoanProposalActionTypes from './centerWiseLoanProposal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addCenterWiseLoanPropsalResponse: null,
	updateCenterWiseLoanProposalResponse: null,
	getCenterWiseLoanProposalResponse: null,
	getCenterWiseLoanProposalListArr: [],
	approveCenterWiseLoanPropsalResponse: null,
	fetchCenterWiseLoanPropsalResponse: null,
	fetchCenterWiseLoanProposalArr: [],
	deleteCenterWiseLoanPropsalResponse: null,
	fetchMembersListForCenterWiseLoanResponse: null,
	fetchMembersListForCenterWiseLoanArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_SUCCESS:
			return { ...state, addCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_FAILURE:
			return { ...state, addCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addCenterWiseLoanPropsalResponse: null };

		case CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS:
			return { ...state, updateCenterWiseLoanProposalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_FAILURE:
			return { ...state, updateCenterWiseLoanProposalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, updateCenterWiseLoanProposalResponse: null };

		case CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_SUCCESS:
			return {
				...state,
				getCenterWiseLoanProposalResponse: action.payload,
				getCenterWiseLoanProposalListArr: docodeResponse(
					action.payload,
					'GetCenterWiseLoanProposalListKey',
				),
			};

		case CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_FAILURE:
			return { ...state, getCenterWiseLoanProposalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
			return { ...state, getCenterWiseLoanProposalResponse: null };

		case CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_SUCCESS:
			return {
				...state,
				approveCenterWiseLoanPropsalResponse: action.payload,
			};

		case CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_FAILURE:
			return { ...state, approveCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, approveCenterWiseLoanPropsalResponse: null };

		case CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				fetchCenterWiseLoanPropsalResponse: action.payload,
				fetchCenterWiseLoanProposalArr: docodeResponse(
					action.payload,
					'FetchCenterWiseLoanLoanProposalKey',
				),
			};

		case CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_FAILURE:
			return { ...state, fetchCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return {
				...state,
				fetchCenterWiseLoanPropsalResponse: null,
				fetchCenterWiseLoanProposalArr: [],
			};

		case CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS:
			return { ...state, deleteCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_FAILURE:
			return { ...state, deleteCenterWiseLoanPropsalResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, deleteCenterWiseLoanPropsalResponse: null };

		case CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_SUCCESS:
			return {
				...state,
				fetchMembersListForCenterWiseLoanResponse: action.payload,
				fetchMembersListForCenterWiseLoanArr: docodeResponse(
					action.payload,
					'GetCenterWiseMembersListKey',
				),
			};

		case CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_FAILURE:
			return { ...state, fetchMembersListForCenterWiseLoanResponse: action.payload };

		case CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_CHANGED:
			return {
				...state,
				fetchMembersListForCenterWiseLoanResponse: null,
				fetchMembersListForCenterWiseLoanArr: [],
			};

		default:
			return state;
	}
};
