// /* eslint-disable eslint-comments/disable-enable-pair */
// /* eslint-disable import/prefer-default-export */
// import axios from 'axios';
// import { API_URL } from '../../const';

// export const portfolioAtRiskReportDetailsGet = (fetchData) => {
// 	const data = {
// 		GetPortfolioAtRiskReportToken: fetchData,
// 	};
// 	const url = `${API_URL}PostPortfolioProfitRiskReportWithToken`;
// 	// console.log('GetPortfolioAtRiskReport',data,url);
// 	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
// };

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const PortflioAtRiskDetailsGet = (fetchData) => {
	const data = {
		GetPortfolioAtRiskReportToken: fetchData,
	};
	const url = `${API_URL}PostPortfolioProfitRiskReportWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const PortflioAtRiskDetailsExportGet = (listData) => {
	const params = new URLSearchParams();
	params.append('PortfolioAtRiskReportToken', listData);
	const url = `${API_URL}Report/Excel/PortfolioAtRiskReportWithToken.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const UserDetailsGet = (fetchData) => {
	const data = {
		UserDetailstToken: fetchData,
	};
	const url = `${API_URL}GetUserDetailsWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const LoanInfoBasedOnMemberGet = (fetchData) => {
	const data = {
		LoanInforBasedOnMemberBothClosedToken: fetchData,
	};
	const url = `${API_URL}GetLoanInfoBasedOnMemebersBothclosedWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
