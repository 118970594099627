/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import trainingActionTypes from './training.types';

export const trainingProposalBranchStart = (data) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_BRANCH_START,
	payload: data,
});

export const trainingProposalBranchSuccess = (data) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_BRANCH_SUCCESS,
	payload: data,
});

export const trainingProposalBranchFailure = (error) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_BRANCH_FAILURE,
	payload: error,
});

export const trainingProposalBranchReset = () => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_BRANCH_RESET,
});

export const trainingProposalBranchChanged = () => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_BRANCH_RESPONSE_CHANGED,
});

// Excel Export
export const getTrainingProposalExcelReportDetailsStart = (getData) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_START,
	payload: getData,
});

export const getTrainingProposalExcelReportDetailsSuccess = (successData) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_SUCCESS,
	payload: successData,
});

export const getTrainingProposalExcelReportDetailsFailure = (failureData) => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_FAILURE,
	payload: failureData,
});

export const getTrainingProposalExcelReportDetailsResponseResetStart = () => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_RESET,
});

export const getTrainingProposalExcelReportDetailsResponseChanged = () => ({
	type: trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_CHANGED,
});