import LeadReportActionTypes from './leadReport.types';

/** ***************** GET LEAD LIST START *********************** */
export const fetchLeadListStart = (getData) => ({
	type: LeadReportActionTypes.FETCH_LEAD_LIST_START,
	payload: getData,
});

export const fetchLeadListSuccess = (successData) => ({
	type: LeadReportActionTypes.FETCH_LEAD_LIST_SUCCESS,
	payload: successData,
});

export const fetchLeadListFailure = (failureData) => ({
	type: LeadReportActionTypes.FETCH_LEAD_LIST_FAILURE,
	payload: failureData,
});

export const fetchLeadListResponseResetStart = () => ({
	type: LeadReportActionTypes.FETCH_LEAD_LIST_RESET_START,
});

export const fetchLeadListResponseChanged = () => ({
	type: LeadReportActionTypes.FETCH_LEAD_LIST_CHANGED,
});
/** ***************** GET LEAD LIST END *********************** */

/** ***************** GET OFFICER LIST START *********************** */
export const fetchOfficerListStart = (getData) => ({
	type: LeadReportActionTypes.FETCH_OFFICER_LIST_START,
	payload: getData,
});

export const fetchOfficerListSuccess = (successData) => ({
	type: LeadReportActionTypes.FETCH_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const fetchOfficerListFailure = (failureData) => ({
	type: LeadReportActionTypes.FETCH_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const fetchOfficerListResponseResetStart = () => ({
	type: LeadReportActionTypes.FETCH_OFFICER_LIST_RESET_START,
});

export const fetchOfficerListResponseChanged = () => ({
	type: LeadReportActionTypes.FETCH_OFFICER_LIST_CHANGED,
});
/** ***************** GET OFFICER LIST END *********************** */
