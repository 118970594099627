/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const memberDetailsAdd = (addData) => {
	const data = {
		CreateMemberToken: addData,
	};
	const url = `${API_URL}addMemberPhoneNumber`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberDetailsToken: updateData,
	};
	const url = `${API_URL}updateMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberListGet = (listData) => {
	const data = {
		GetMemberListToken: listData,
	};
	const url = `${API_URL}getMemberList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsFetch = (fetchData) => {
	const data = {
		FetchMemberToken: fetchData,
	};
	const url = `${API_URL}fetchMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsDelete = (deleteData) => {
	const data = {
		DeleteMemberDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CO-APPLICANT HIGHMARK

export const coApplicantHighMarkDetailsFetch = (fetchData) => {
	const data = {
		FetchCoApplicantHighMarkToken: fetchData,
	};
	const url = `${API_URL}fetchCoApplicantHighMarkDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK HIGHMARK

export const checkHighmarkDetailsFetch = (fetchData) => {
	const data = {
		CheckMemberHighMarkToken: fetchData,
	};
	const url = `${API_URL}callHighMark`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK EQUIFAX

export const checkEquifaxDetailsFetch = (fetchData) => {
	const data = {
		checkEquifaxToken: fetchData,
	};
	const url = `${API_URL}UploadEquifaxDetailsWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// CHECK CO APPLICANT HIGHMARK

export const checkCoApplicantHighmarkDetailsFetch = (fetchData) => {
	const data = {
		CheckCoAppliantHighMarkToken: fetchData,
	};
	const url = `${API_URL}callCoApplicantHighMarkWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// UPDATE NO LOAN MEMBER
export const updateNoLoanMember = (fetchData, documentType) => {
	let API_NAME = '';
	if (documentType === 'updateNoLoanMemberWeb') {
		API_NAME = 'UpdateIsNoLoanMemberWithToken';
	}
	if (documentType === 'updateTopupLoanMemberWeb') {
		API_NAME = 'UpdateTopupLoanNeededWithToken';
	}
	const data = {
		updateNoLoanMemberToken: fetchData,
	};
	const url = `${API_URL}${API_NAME}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// UPDATE REJECT MEMBER
export const updateMemberIsApproveService = (fetchData) => {
	const data = {
		updateIsApproveMemberToken: fetchData,
	};
	const url = `${API_URL}UpdateMemberIsApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// VERIFY DOCUMENTS

export const verifyDocumentDetailsFetch = (fetchData, documentType) => {
	const data = {
		DocumentVerificationToken: fetchData,
	};
	let API_NAME = '';
	if (documentType === 'Aadhar') {
		API_NAME = 'VerifyAadharKYCForWeb';
	}
	if (documentType === 'AadharOTP') {
		API_NAME = 'AadharOtpVerifyForWeb';
	}
	if (documentType === 'PAN' || documentType === 'PanCard') {
		API_NAME = 'VerifyPanDetailedKYCForWeb';
	}
	if (documentType === 'PassPort') {
		API_NAME = 'VerifyPanDetailedKYCForWeb';
	}
	if (documentType === 'DrivingLicense') {
		API_NAME = 'VerifyDrivingKYCForWeb';
	}
	if (documentType === 'VoterID') {
		API_NAME = 'VerifyVoterIDKYCForWeb';
	}
	if (documentType === 'AadharOTPValidation') {
		API_NAME = 'AadharOtpValidateVerifyForWeb';
	}
	if (documentType === 'PENNYDROP') {
		API_NAME = 'VerifyPennyDropKYCForWeb';
	}
	const url = `${API_URL}${API_NAME}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// PAYSSPRINT LOG FETCH

export const PaySprintLogDetailsFetch = (fetchData) => {
	const data = {
		FetchPaySprintLogToken: fetchData,
	};
	const url = `${API_URL}fetchPaySprintLogDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// MEMBER AUDIT LOG FETCH

export const MemberAuditDetailsFetch = (fetchData) => {
	const data = {
		MemberAuditFetchApproveToken: fetchData,
	};
	const url = `${API_URL}GetMemberAuditAnswersForApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK TOPUPLOAN

export const checkTopupLoanMember = (fetchData) => {
	const data = {
		checkMemberTopupToken: fetchData,
	};
	const url = `${API_URL}checkMemberTopupWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// MEMBER VERIFY LOG FETCH

export const MemberVerifyDetailsFetch = (fetchData) => {
	const data = {
		MemberVerifyFetchApproveToken: fetchData,
	};
	const url = `${API_URL}GetMemberVerifyDetailsForApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// MEMBER VERIFY LOG FETCH

export const MemberVerifyDetailsUpdate = (fetchData) => {
	const data = {
		MemberVerifyUpdateApproveToken: fetchData,
	};
	const url = `${API_URL}UpdateMemberVerifyDetailsForApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const MemberKYCDetailsUpdate = (fetchData) => {
	const data = {
		MemberKYCUpdateToken: fetchData,
	};
	const url = `${API_URL}UpdateMemberKYCDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const MemberTransferDetailsUpdate = (fetchData) => {
	const data = {
		MemberTransferUpdateToken: fetchData,
	};
	const url = `${API_URL}UpdateMemberTransferDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
