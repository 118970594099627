// eslint-disable-next-line import/no-unresolved
import ResidentialDetailsActionType from './residential-details.types';

export const residentialDetailsAddStart = (residentialDetailsCredentials) => ({
	type: ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_START,
	payload: residentialDetailsCredentials,
});

export const residentialDetailsAddSuccess = (residentialDetailsSuccess) => ({
	type: ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_SUCCESS,
	payload: residentialDetailsSuccess,
});

export const residentialDetailsAddFailure = (residentialDetailsFailure) => ({
	type: ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_FAILURE,
	payload: residentialDetailsFailure,
});

export const residentialDetailsAddResponseResetStart = () => ({
	type: ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_RESPONSE_REST_START,
});

export const residentialDetailsAddUserResponseChanged = () => ({
	type: ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_RESPONSE_CHANGED,
});

// CITIES LIST ACTION
export const fetchCitiesListStart = (residentialDetailsCredentials) => ({
	type: ResidentialDetailsActionType.FETCH_CITIES_LIST_START,
	payload: residentialDetailsCredentials,
});

export const fetchCitiesListSuccess = (residentialDetailsSuccess) => ({
	type: ResidentialDetailsActionType.FETCH_CITIES_LIST_SUCCESS,
	payload: residentialDetailsSuccess,
});

export const fetchCitiesListFailure = (residentialDetailsFailure) => ({
	type: ResidentialDetailsActionType.FETCH_CITIES_LIST_FAILURE,
	payload: residentialDetailsFailure,
});

export const fetchCitiesListResponseResetStart = () => ({
	type: ResidentialDetailsActionType.FETCH_CITIES_LIST_RESPONSE_REST_START,
});

export const fetchCitiesListUserResponseChanged = () => ({
	type: ResidentialDetailsActionType.FETCH_CITIES_LIST_RESPONSE_CHANGED,
});

// STATE LIST ACTION
export const fetchStateListStart = (residentialDetailsCredentials) => ({
	type: ResidentialDetailsActionType.FETCH_STATE_LIST_START,
	payload: residentialDetailsCredentials,
});

export const fetchStateListSuccess = (residentialDetailsSuccess) => ({
	type: ResidentialDetailsActionType.FETCH_STATE_LIST_SUCCESS,
	payload: residentialDetailsSuccess,
});

export const fetchStateListFailure = (residentialDetailsFailure) => ({
	type: ResidentialDetailsActionType.FETCH_STATE_LIST_FAILURE,
	payload: residentialDetailsFailure,
});

export const fetchStateListResponseResetStart = () => ({
	type: ResidentialDetailsActionType.FETCH_STATE_LIST_RESPONSE_REST_START,
});

export const fetchStateListUserResponseChanged = () => ({
	type: ResidentialDetailsActionType.FETCH_STATE_LIST_RESPONSE_CHANGED,
});

export const updateSelectedAddressData = (residentialDetailsFailure) => ({
	type: ResidentialDetailsActionType.UPDATE_SELECTED_ADDRESS_DATA,
	payload: residentialDetailsFailure,
});
