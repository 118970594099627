// eslint-disable-next-line import/no-unresolved
import CoApplicantActionType from './co-applicant.types';

export const addCoApplicantDetailsStart = (addCoApplicant) => ({
	type: CoApplicantActionType.ADD_CO_APPICANT_DETAILS_START,
	payload: addCoApplicant,
});

export const updateCoApplicantDetailsStart = () => ({
	type: CoApplicantActionType.UPDATE_CO_APPICANT_DETAILS_START,
});

export const removeCoApplicantDetailsStart = (removeCoApplicant) => ({
	type: CoApplicantActionType.REMOVE_CO_APPICANT_DETAILS_START,
	payload: removeCoApplicant,
});

export const updateCoApplicantDetailsArrStart = (updateCoApplicantArr) => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_ARR_START,
	payload: updateCoApplicantArr,
});

// update coApplicant
export const updateCoApplicantStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_START,
	payload: coApplicantCredentials,
});

export const updateCoApplicantSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_SUCCESS,
	payload: coApplicantSuccess,
});

export const updateCoApplicantFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_FAILURE,
	payload: coApplicantFailure,
});

export const updateCoApplicantResponseResetStart = () => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_RESPONSE_REST_START,
});

export const updateCoApplicantResponseChanged = () => ({
	type: CoApplicantActionType.UPDATE_CO_APPLICANT_RESPONSE_CHANGED,
});

// Add coApplicant
export const addCoBorrowerStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.ADD_COBORROWER_START,
	payload: coApplicantCredentials,
});

export const addCoBorrowerSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.ADD_COBORROWER_SUCCESS,
	payload: coApplicantSuccess,
});

export const addCoBorrowerFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.ADD_COBORROWER_FAILURE,
	payload: coApplicantFailure,
});

export const addCoBorrowerResponseResetStart = () => ({
	type: CoApplicantActionType.ADD_COBORROWER_RESPONSE_RESET_START,
});

export const addCoBorrowerResponseChanged = () => ({
	type: CoApplicantActionType.ADD_COBORROWER_RESPONSE_CHANGED,
});

// Fetch coApplicant
export const fetchCoBorrowerStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.FETCH_COBORROWER_START,
	payload: coApplicantCredentials,
});

export const fetchCoBorrowerSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.FETCH_COBORROWER_SUCCESS,
	payload: coApplicantSuccess,
});

export const fetchCoBorrowerFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.FETCH_COBORROWER_FAILURE,
	payload: coApplicantFailure,
});

export const fetchCoBorrowerResponseResetStart = () => ({
	type: CoApplicantActionType.FETCH_COBORROWER_RESPONSE_RESET_START,
});

export const fetchCoBorrowerResponseChanged = () => ({
	type: CoApplicantActionType.FETCH_COBORROWER_RESPONSE_CHANGED,
});

// Delete coApplicant
export const deleteCoBorrowerStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.DELETE_CO_APPLICANT_START,
	payload: coApplicantCredentials,
});

export const deleteCoBorrowerSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.DELETE_CO_APPLICANT_SUCCESS,
	payload: coApplicantSuccess,
});

export const deleteCoBorrowerFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.DELETE_CO_APPLICANT_FAILURE,
	payload: coApplicantFailure,
});

export const deleteCoBorrowerResponseResetStart = () => ({
	type: CoApplicantActionType.DELETE_CO_APPLICANT_RESPONSE_REST_START,
});

export const deleteCoBorrowerResponseChanged = () => ({
	type: CoApplicantActionType.DELETE_CO_APPLICANT_RESPONSE_CHANGED,
});

export const coBorrowerObjReset = () => ({
	type: CoApplicantActionType.COBORROWER_OBJECT_RESET,
});

// HighMark Check coApplicant
export const checkCoBorrowerHighMarkStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_START,
	payload: coApplicantCredentials,
});

export const checkCoBorrowerHighMarkSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_SUCCESS,
	payload: coApplicantSuccess,
});

export const checkCoBorrowerHighMarkFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_FAILURE,
	payload: coApplicantFailure,
});

export const checkCoBorrowerHighMarkRespResetStart = () => ({
	type: CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_RESPONSE_RESET_START,
});

export const checkCoBorrowerHighMarkRespChanged = () => ({
	type: CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR OTP DETAILS
export const verifyCoApplicantAadharOtpStart = (DetailsCredentials) => ({
	type: CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyCoApplicantAadharOtpSuccess = (DetailsSuccess) => ({
	type: CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyCoApplicantAadharOtpFailure = (DetailsFailure) => ({
	type: CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyCoApplicantAadharOtpResponseResetStart = () => ({
	type: CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyCoApplicantAadharOtpResponseChanged = () => ({
	type: CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR VALIDATE OTP DETAILS
export const verifyValidateCoApplicantAadharOtpStart = (DetailsCredentials) => ({
	type: CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyValidateCoApplicantAadharOtpSuccess = (DetailsSuccess) => ({
	type: CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyValidateCoApplicantAadharOtpFailure = (DetailsFailure) => ({
	type: CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyValidateCoApplicantAadharOtpResponseResetStart = () => ({
	type: CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyValidateCoApplicantAadharOtpResponseChanged = () => ({
	type: CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED,
});

// Fetch coApplicant relationship
export const fetchRelationshipStart = (coApplicantCredentials) => ({
	type: CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_START,
	payload: coApplicantCredentials,
});

export const fetchRelationshipSuccess = (coApplicantSuccess) => ({
	type: CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_SUCCESS,
	payload: coApplicantSuccess,
});

export const fetchRelationshipFailure = (coApplicantFailure) => ({
	type: CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_FAILURE,
	payload: coApplicantFailure,
});

export const fetchRelationshipResponseResetStart = () => ({
	type: CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_RESET_START,
});

export const fetchRelationshipResponseChanged = () => ({
	type: CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_CHANGED,
});
