/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import CenterWiseLoanProposalActionTypes from './centerWiseLoanProposal.types';
import {
	createNewCenterWiseLoan,
	updateCenterWiseLoan,
	centerWiseLoanProposalListFetch,
	centerWiseLoanProposalApprove,
	centerWiseLoanProposalDetailsFetch,
	centerWiseLoanProposalDelete,
	fetchMembersListForCenterLoan,
} from './centerWiseLoanProposal.service';

import {
	addCenterWiseLoanPropsalSuccess,
	addCenterWiseLoanPropsalFailure,
	addCenterWiseLoanPropsalResponseChanged,
	updateCenterWiseLoanProposalSuccess,
	updateCenterWiseLoanProposalFailure,
	updateCenterWiseLoanProposalResponseChanged,
	getCenterWiseLoanPropsalListSuccess,
	getCenterWiseLoanPropsalListFailure,
	getCenterWiseLoanPropsalListResponseChanged,
	approveCenterWiseProposalSuccess,
	approveCenterWiseProposalFailure,
	approveCenterWiseProposalResponseChanged,
	fetchCenterWiseLoanProposalSuccess,
	fetchCenterWiseLoanProposalFailure,
	fetchCenterWiseLoanProposalResponseChanged,
	deleteCenterWiseLoanProposalSuccess,
	deleteCenterWiseLoanProposalFailure,
	deleteCenterWiseLoanProposalResponseChanged,
	fetchMembersListForCenterWiseLoanSuccess,
	fetchMembersListForCenterWiseLoanFailure,
	fetchMembersListForCenterWiseLoanResponseChanged,
} from './centerWiseLoanProposal.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS LOAN PROPOSAL   ******************** */
export function* craeteNewLoanProposal({ payload }) {
	try {
		const key = 'AddCenterWiseLoanProposalKey';
		const jsonData = {
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupOfficerID: payload.officerID,
			BusinessID: payload.businessID,
			LoanProposalDate: payload.loanProposalDate,
			LoanStartDate: payload.loanStartDate,
			LoanEndDate: payload.loanEndDate,
			MeetUpLocation: payload.memberMeetUpLocation,
			MemberMeetupLat: payload.meetUpLat,
			MemberMeetupLong: payload.meetUpLog,
			MeetUpTime: payload.meetUpTime,
			ProductID: payload.productID,
			LoanIndexType: payload.loanIndexType,
			ProductAmount: payload.principalAmount,
			ProductPrincipal: payload.interestAmount,
			ProductInterest: payload.interestPercentage,
			ProductROI: payload.productROI,
			InstalmentType: payload.instalmentType,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.installmentReturnType,
			NoOfInstallment: payload.noOfInstallment,
			DocumentCharges: payload.documentCharges,
			ProcessingFees: payload.processingFees,
			ProcessingFeesPercentage: payload.ProcessingFeesPerc,
			InsurenseFeePercentage: payload.InsuranceFeesPerc,
			CGST: payload.cgst,
			SGST: payload.sgst,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insurenseFee,
			Noofstartday: payload.noOfStartday,
			LoanType: payload.loanType,
			PurposeOfLoanID: payload.purposeOfLoanID,
			AmountReceived: payload.productAmountReceived,
			AmountOutStanding: payload.productOutstanding,
			CreatedUser: payload.userID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createNewCenterWiseLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addCenterWiseLoanPropsalFailure(responseData.data));
		} else {
			yield put(addCenterWiseLoanPropsalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCenterWiseLoanPropsalFailure(error));
	}
}

export function* resetAddCenterWiseLoanProposalResponse() {
	yield put(addCenterWiseLoanPropsalResponseChanged());
}

export function* onCreateCenterWiseLoanProposal() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_START,
		craeteNewLoanProposal,
	);
}

export function* onCreateCenterWiseLoanProposalResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetAddCenterWiseLoanProposalResponse,
	);
}
/** *********************  END ADD CENTER WISE LOAN PROPOSAL  ******************** */

/** *********************  START UPDATE CENTER WISE LOAN PROPOSAL   ******************** */
export function* CenterWiseLoanProposal({ payload }) {
	try {
		const key = 'UpdateCenterWiseLoanProposalKey';
		const jsonData = {
			LoanIndexID: payload.businessLoanProposalID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupOfficerID: payload.officerID,
			BusinessID: payload.businessID,
			LoanProposalDate: payload.loanProposalDate,
			LoanStartDate: payload.loanStartDate,
			LoanEndDate: payload.loanEndDate,
			MeetUpLocation: payload.memberMeetUpLocation,
			MemberMeetupLat: payload.meetUpLat,
			MemberMeetupLong: payload.meetUpLog,
			MeetUpTime: payload.meetUpTime,
			ProductID: payload.productID,
			LoanIndexType: payload.loanIndexType,
			ProductAmount: payload.principalAmount,
			ProductPrincipal: payload.interestAmount,
			ProductInterest: payload.interestPercentage,
			ProductROI: payload.productROI,
			InstalmentType: payload.instalmentType,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.installmentReturnType,
			NoOfYears: payload.noOfYears,
			DocumentCharges: payload.documentCharges,
			ProcessingFees: payload.processingFees,
			ProcessingFeesPercentage: payload.ProcessingFeesPerc,
			InsurenseFeePercentage: payload.InsuranceFeesPerc,
			CGST: payload.cgst,
			SGST: payload.sgst,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insurenseFee,
			Noofstartday: payload.noOfStartday,
			LoanType: payload.loanType,
			PurposeOfLoanID: payload.purposeOfLoanID,
			AmountReceived: payload.productAmountReceived,
			AmountOutStanding: payload.productOutstanding,
			LoanDisbursementDate: payload.loanDisbursementDate,
			CreatedUser: payload.userID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateCenterWiseLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCenterWiseLoanProposalFailure(responseData.data));
		} else {
			yield put(updateCenterWiseLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCenterWiseLoanProposalFailure(error));
	}
}

export function* resetUpdateCenterWiseLoanProposalResponse() {
	yield put(updateCenterWiseLoanProposalResponseChanged());
}

export function* onUpdateCenterWiseLoanProposal() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_START,
		CenterWiseLoanProposal,
	);
}

export function* onUpdateCenterWiseLoanProposalResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetUpdateCenterWiseLoanProposalResponse,
	);
}
/** *********************  END UPDATE CENTER WISE LOAN PROPOSAL  ******************** */

/** *********************  START GET CENTER WISE LOAN PROPOSAL  ******************** */
export function* centerWiseLoanProposalListGet({ payload }) {
	try {
		const key = 'GetCenterWiseLoanProposalListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			LoginUserRoleID: payload.userRoleID,
			Level: payload.userLevel,
			LoanIndexType: payload.loanIndexType,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerWiseLoanProposalListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterWiseLoanPropsalListFailure(responseData.data));
		} else {
			yield put(getCenterWiseLoanPropsalListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterWiseLoanPropsalListFailure(error));
	}
}

export function* resetGetCenterWiseLoanProposalListResponse() {
	yield put(getCenterWiseLoanPropsalListResponseChanged());
}

export function* onGetCenterWiseLoanProposal() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_START,
		centerWiseLoanProposalListGet,
	);
}

export function* onGetCenterWiseLoanProposalListResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
		resetGetCenterWiseLoanProposalListResponse,
	);
}
/** *********************  END GET CENTER WISE LOAN PROPOSAL ******************** */

/** *********************  START APPROVE CENTER WISE LOAN PROPOSAL  ******************** */
export function* approveCenterWiseProposalData({ payload }) {
	try {
		const key = 'CenterWiseLoanProposalApprovalKey';
		const jsonData = {
			LoanIndexID: payload.loanIndexID,
			AppraisalLevel: payload.appraisalLevel,
			AppraisalDoneBy: payload.userID,
			AppraisalDoneByUserName: payload.userName,
			AppraisalApproveSetupID: payload.appraisalApproveSetupID,
			AppraisalApproveID: payload.appraisalApproveID,
			IsAppraisalApproveNeeded: payload.isAppraisalApproveNeeded,
			IsAllAppraisalApproved: payload.isAllApproved,
			NoOfAppraisalApproval: payload.noOfAppraisalApproval,
			AppraisalNotes: payload.appraisalNotes,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerWiseLoanProposalApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(approveCenterWiseProposalFailure(responseData.data));
		} else {
			yield put(approveCenterWiseProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveCenterWiseProposalFailure(error));
	}
}

export function* resetApproveCenterWiseProposalResponse() {
	yield put(approveCenterWiseProposalResponseChanged());
}

export function* onApproveCenterWiseProposalDetails() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_START,
		approveCenterWiseProposalData,
	);
}

export function* onApproveCenterWiseProposalResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_RESET_START,
		resetApproveCenterWiseProposalResponse,
	);
}
/** *********************  END APPROVE CENTER WISE LOAN PROPOSAL  ******************** */

/** *********************  START FETCH CENTER WISE LOAN PROPOSAL  ******************** */
export function* fetchCenterWiseLoanProposalData({ payload }) {
	try {
		const key = 'FetchCenterWiseLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerWiseLoanProposalDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterWiseLoanProposalFailure(responseData.data));
		} else {
			yield put(fetchCenterWiseLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterWiseLoanProposalFailure(error));
	}
}

export function* resetFetchCenterWiseLoanProposalResponse() {
	yield put(fetchCenterWiseLoanProposalResponseChanged());
}

export function* onFetchCenterWiseLoanProposalDetails() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_START,
		fetchCenterWiseLoanProposalData,
	);
}

export function* onFetchCenterWiseLoanProposalResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetFetchCenterWiseLoanProposalResponse,
	);
}

/** *********************  END FETCH CENTER WISE LOAN PROPOSAL  ******************** */

/** *********************  START DELETE CENTER WISE LOAN PROPOSAL   ******************** */
export function* deleteCenterWiseLoanProposal({ payload }) {
	try {
		const key = 'DeleteCenterWiseLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerWiseLoanProposalDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteCenterWiseLoanProposalFailure(responseData.data));
		} else {
			yield put(deleteCenterWiseLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteCenterWiseLoanProposalFailure(error));
	}
}

export function* resetDeleteCenterWiseLoanProposalResponse() {
	yield put(deleteCenterWiseLoanProposalResponseChanged());
}

export function* onDeleteCenterWiseLoanProposalDetail() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_START,
		deleteCenterWiseLoanProposal,
	);
}

export function* onDeleteCenterWiseLoanProposalResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetDeleteCenterWiseLoanProposalResponse,
	);
}
/** *********************  END DELETE CENTER WISE LOAN PROPOSAL  ******************** */

/** *********************  START FETCH MEMBERS LIST FOR CENTER WISE LOAN  ******************** */
export function* fetchMembersListForCenterWiseLoanData({ payload }) {
	try {
		const key = 'GetCenterWiseMembersListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			SearchData: payload.SearchData,
			Limit: payload.Limit,
			DatabaseName: payload.databaseName,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMembersListForCenterLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMembersListForCenterWiseLoanFailure(responseData.data));
		} else {
			yield put(fetchMembersListForCenterWiseLoanSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMembersListForCenterWiseLoanFailure(error));
	}
}

export function* resetFetchMembersListForCenterWiseLoanResponse() {
	yield put(fetchMembersListForCenterWiseLoanResponseChanged());
}

export function* onFetchMembersListForCenterWiseLoanDetails() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_START,
		fetchMembersListForCenterWiseLoanData,
	);
}

export function* onFetchMembersListForCenterWiseLoanResponseReset() {
	yield takeLatest(
		CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_RESET,
		resetFetchMembersListForCenterWiseLoanResponse,
	);
}

/** *********************  END FETCH MEMBERS LIST FOR CENTER WISE LOAN  ******************** */

export function* centerWiseLoanProposalSaga() {
	yield all([
		call(onCreateCenterWiseLoanProposal),
		call(onCreateCenterWiseLoanProposalResponseReset),
		call(onUpdateCenterWiseLoanProposal),
		call(onUpdateCenterWiseLoanProposalResponseReset),
		call(onGetCenterWiseLoanProposal),
		call(onGetCenterWiseLoanProposalListResponseReset),
		call(onApproveCenterWiseProposalDetails),
		call(onApproveCenterWiseProposalResponseReset),
		call(onFetchCenterWiseLoanProposalDetails),
		call(onFetchCenterWiseLoanProposalResponseReset),
		call(onDeleteCenterWiseLoanProposalDetail),
		call(onDeleteCenterWiseLoanProposalResponseReset),
		call(onGetCenterWiseLoanProposalListResponseReset),
		call(onFetchMembersListForCenterWiseLoanDetails),
		call(onFetchMembersListForCenterWiseLoanResponseReset),
	]);
}
