// eslint-disable-next-line import/no-unresolved
import VerifyOtpActionType from './verify-otp.types';

// Otp Verify Mobile Number
export const verifyMemberNumberOtpStart = (otpCredentials) => ({
	type: VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_START,
	payload: otpCredentials,
});

export const verifyMemberNumberOtpSuccess = (otpSuccess) => ({
	type: VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_SUCCESS,
	payload: otpSuccess,
});

export const verifyMemberNumberOtpFailure = (otpFailure) => ({
	type: VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_FAILURE,
	payload: otpFailure,
});

export const verifyMemberNumberOtpResponseResetStart = () => ({
	type: VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_RESPONSE_REST_START,
});

export const verifyMemberNumberOtpUserResponseChanged = () => ({
	type: VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_RESPONSE_CHANGED,
});

// START RE-SEND OTP
export const reSendOtpStart = (otpCredentials) => ({
	type: VerifyOtpActionType.RE_SEND_OTP_START,
	payload: otpCredentials,
});

export const reSendOtpSuccess = (otpSuccess) => ({
	type: VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_SUCCESS,
	payload: otpSuccess,
});

export const reSendOtpFailure = (error) => ({
	type: VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_FAILURE,
	payload: error,
});

export const reSendOtpRestResponseStart = () => ({
	type: VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_RESET_START,
});

export const reSendOtpResponseChanged = () => ({
	type: VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_CHANGED,
});
