/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import QrReportActionTypes from './qr-report.types';

/** ***************** GET QR REPORT DETAILS START *********************** */
export const fetchQrReportStart = (getData) => ({
	type: QrReportActionTypes.FETCH_QR_REPORT_START,
	payload: getData,
});

export const fetchQrReportSuccess = (successData) => ({
	type: QrReportActionTypes.FETCH_QR_REPORT_SUCCESS,
	payload: successData,
});

export const fetchQrReportFailure = (failureData) => ({
	type: QrReportActionTypes.FETCH_QR_REPORT_FAILURE,
	payload: failureData,
});

export const fetchQrReportResponseReset = () => ({
	type: QrReportActionTypes.FETCH_QR_REPORT_RESPONSE_RESET,
});

export const fetchQrReportResponseChanged = () => ({
	type: QrReportActionTypes.FETCH_QR_REPORT_RESPONSE_CHANGED,
});

export const fetchParticularQrReportStart = (getData) => ({
	type: QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_START,
	payload: getData,
});

export const fetchParticularQrReportSuccess = (successData) => ({
	type: QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_SUCCESS,
	payload: successData,
});

export const fetchParticularQrReportFailure = (failureData) => ({
	type: QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_FAILURE,
	payload: failureData,
});

export const fetchParticularQrReportResponseReset = () => ({
	type: QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_RESPONSE_RESET,
});

export const fetchParticularQrReportResponseChanged = () => ({
	type: QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_RESPONSE_CHANGED,
});

export const exportQrReportStart = (getData) => ({
	type: QrReportActionTypes.EXPORT_QR_REPORT_START,
	payload: getData,
});

export const exportQrReportSuccess = (successData) => ({
	type: QrReportActionTypes.EXPORT_QR_REPORT_SUCCESS,
	payload: successData,
});

export const exportQrReportFailure = (failureData) => ({
	type: QrReportActionTypes.EXPORT_QR_REPORT_FAILURE,
	payload: failureData,
});

export const exportQrReportResponseReset = () => ({
	type: QrReportActionTypes.EXPORT_QR_REPORT_RESPONSE_RESET,
});

export const exportQrReportResponseChanged = () => ({
	type: QrReportActionTypes.EXPORT_QR_REPORT_RESPONSE_CHANGED,
});

/** ***************** GET QR REPORT DETAILS END *********************** */
