/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import PreCloseReportActionTypes from './preclose-loan.types';

import {
	precloseReportListGet,
	officerBranchListGet,
	PrecloseExcelListGet,
} from './preclose-loan.service';

import {
	getPrecloseReportDetailsSuccess,
	getPrecloseReportDetailsFailure,
	getPrecloseReportDetailsResponseChanged,
	getOfficerBranchDetailsSuccess,
	getOfficerBranchDetailsFailure,
	getOfficerBranchDetailsResponseChanged,
	fetchPrecloseReportExcelFailure,
	fetchPrecloseReportExcelSuccess,
	fetchPrecloseReportResponseExcelResetChanged,
} from './preclose-loan.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PRECLOSE REPORT DETAILS  ******************** */
export function* getPrecloseReportList({ payload }) {
	try {
		const key = 'GetMemberPreCloseKey';
		debugger;
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			TillDate: payload.TillDate,
			DateFilter: payload.datefilter,
		};
		const encodeData = encodeJson(jsonData, key);

		const responseData = yield call(precloseReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPrecloseReportDetailsFailure(responseData.data));
		} else {
			yield put(getPrecloseReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPrecloseReportDetailsFailure(error));
	}
}

export function* resetGetPrecloseReportListResponse() {
	yield put(getPrecloseReportDetailsResponseChanged());
}

export function* onGetPrecloseReportListDetails() {
	yield takeLatest(
		PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_START,
		getPrecloseReportList,
	);
}

export function* onGetPrecloseReportListResponseReset() {
	yield takeLatest(
		PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_RESPONSE_RESET,
		resetGetPrecloseReportListResponse,
	);
}
/** *********************  END GET PRECLOSE REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(
		PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
		resetGetPrecloseReportListResponse,
	);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

/** *********************  START GET PRECLOSE EXCEL REPORT DETAILS  ******************** */
export function* getPrecloseReportExcelList({ payload }) {
	try {
		const key = 'GetPrecloseReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
			DateFilter: payload.datefilter,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PrecloseExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPrecloseReportExcelFailure(responseData.data));
		} else {
			yield put(fetchPrecloseReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPrecloseReportExcelFailure(error));
	}
}

export function* resetPrecloseReportExcelListResponse() {
	yield put(fetchPrecloseReportResponseExcelResetChanged());
}

export function* onGetPrecloseExcelListDetails() {
	yield takeLatest(
		PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_EXCEL_START,
		getPrecloseReportExcelList,
	);
}

export function* onGetPrecloseExcelListResponseReset() {
	yield takeLatest(
		PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_RESPONSE_EXCEL_RESET_START,
		resetPrecloseReportExcelListResponse,
	);
}
/** *********************  END GET ACTIVE MEMBER EXCEL REPORT LIST ******************** */

export function* reportPrecloseSaga() {
	yield all([
		call(onGetPrecloseReportListDetails),
		call(onGetPrecloseReportListResponseReset),
		call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
		call(onGetPrecloseExcelListDetails),
		call(onGetPrecloseExcelListResponseReset),
	]);
}
