import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './login/login.reducer';
import AssignUserRightsReducer from './userControlAccess/assignUserRights/assignUserRights.reducer';
import MemberMandatoryAccessReducer from './userControlAccess/memberMandatoryAccess/memberMandatoryAccess.reducer';

// import staffDashboardReducer from './staffDashboard/staffDashboard.reducer';
import staffReducer from './operations/staff/staff.reducer';
import executiveReducer from './operations/executive/executive.reducer';
import teamReducer from './operations/team/team.reducer';
import repaymentCollectionReportReducer from './reports/RepaymentCollectionReport/RepaymentCollectionReport.reducer';
import progressReportReducer from './reports/ProgressReport/ProgressReport.reducer';
import GeneralReducer from './general/general.reducer';
import BusinessDetailsReducer from './operations/business/business-details/business-details.reducer';
import BrachDetailsReducer from './operations/business/branch-details/branch-details.reducer';
import PartnerDetailsReducer from './operations/business/partner-details/partner-details.reducer';
import BankDetailsReducer from './operations/business/bank-details/bank-details.reducer';
import ProofDetailsReducer from './operations/business/proof-details/proof-details.reducer';
import VerificationDetailsReducer from './operations/business/verification-details/verification-details.reducer';
import RelatedPartiesReducer from './operations/business/related-parties/related-parties.reducer';
/** ******************* MASTERS ************************** */
import BranchReducer from './masters/branch/branch.reducer';
import MemberReducer from './masters/member/member.reducer';
import VerifyOtpReducer from './masters/member-add/verify-otp/verify-otp.reducer';
import BasicDetailsReducer from './masters/member-add/basic-details/basic-details.reducer';
import PhotoVerificationReducer from './masters/member-add/photo-verification/photo-verification.reducer';
import SignatureReducer from './masters/member-add/signature/signature.reducer';
import PersonalDetailsReducer from './masters/member-add/personal-details/personal-details.reducer';
import FamilyDetailsReducer from './masters/member-add/family-details/family-details.reducer';
import BasicNomineeDetailsReducer from './masters/member-add/basic-nominee-details/basic-nominee-details.reducer';
import BankReducer from './masters/member-add/bank-details/bank-details.reducer';
import HouseDetailsReducer from './masters/member-add/house-details/house-details.reducer';
import AadharDetailsReducer from './masters/member-add/aadhar-details/aadhar-details.reducer';
import DrivingLicenceDetailsReducer from './masters/member-add/driving-licence-details/driving-licence-details.reducer';
import PancardDetailsReducer from './masters/member-add/pancard-details/pancard-details.reducer';
import PassbookChequeReducer from './masters/member-add/passbook-cheque-details/passbook-cheque-details.reducer';
import PassportDetailsReducer from './masters/member-add/passport-details/passport-details.reducer';
import RationCardDetailsReducer from './masters/member-add/ration-card-details/ration-card-details.reducer';
import VoterIdDetailsReducer from './masters/member-add/voter-id-details/voter-id-details.reducer';
import AddressExtraProofReducer from './masters/member-add/address-extra-proof/address-extra-proof.reducer';
import HousePhotosReducer from './masters/member-add/house-photos/house-photos.reducer';
import ExtraInformationReducer from './masters/member-add/extra-infromation/extra-information.reducer';
import OfficialDetailsReducer from './masters/member-add/official-details/official-details.reducer';
import NocPhotosReducer from './masters/member-add/noc-photos/noc-photos.reducer';
import ResidentialDetailsReducer from './masters/member-add/residential-details/residential-details.reducer';
import CurrentAddressReducer from './masters/member-add/current-address/current-address.reducer';
import CoApplicantReducer from './masters/member-add/co-applicant/co-applicant.reducer';
import CreditCheckReducer from './masters/member-add/credit-check/credit-check.reducer';
import MemberDetailsReducer from './masters/member-add/add-member/add-member.reducer';
import CenterReducer from './masters/center/center.reducer';
import loanApplicationReducer from './masters/loanApplication/loanApplication.reducer';
import RoleReducer from './masters/role/role.reducer';
import StaffReducer from './masters/staff/staff.reducer';
import GroupReducer from './masters/group/group.reducer';
import ThriftReducer from './masters/thrift/thrift.reducer';
import ProductTypeReducer from './masters/product-type/product-type.reducer';
import InsuranceCompanyReducer from './masters/insurance-company/insurance-company.reducer';
import ProductReducer from './masters/product/product.reducer';
import EconomicActivityReducer from './masters/economic-activity/economic-activity.reducer';
import EconomicActivityTypeReducer from './masters/economic-activity-type/economic-activity-type.reducer';
import purposeofloanReducer from './masters/purpose-of-loan/purposeofloan.reducer';
import businessfundReducer from './masters/business-fund/business-fund.reducer';
import FunderReducer from './masters/funder/funder.reducer';
import QuestionnaireReducer from './masters/questionnaire/questionnaire.reducer';

/** ******************* OPERATIONS - COLLECTION ************************** */
import collectionReducer from './operations/collection/collection.reducer';
import memberCollectionReducer from './operations/collection/memberCollection/memberCollection.reducer';
import advanceCollectionReducer from './operations/collection/advanceCollection/advanceCollection.reducer';
import todayCollectionReducer from './operations/todayCollection/todayCollection.reducer';
import AuditingToolReducer from './operations/auditing-tool/auditing-tool.reducer';

/** ******************* BUSINESS LOAN PROPOSAL ************************** */
import businessLoanProposalReducer from './operations/loan-proposal/business-loan-proposal/business-loan-proposal.reducer';

/** ******************* CENTER WISE LOAN PROPOSAL ************************** */
import centerWiseLoanProposalReducer from './operations/loan-proposal/centerWiseLoanProposal/centerWiseLoanProposal.reducer';

/** ******************* GOLD LOAN PROPOSAL ************************** */
import goldLoanProposalReducer from './operations/loan-proposal/goldLoanProposal/goldLoanProposal.reducer';

/** ******************* PRODUCT LOAN PROPOSAL ************************** */
import productLoanProposalReducer from './operations/loan-proposal/product-loan-proposal/product-loan-proposal.reducer';

/** *******************CUSTOM PRODUCT LOAN PROPOSAL ************************** */
import customproductLoanProposalReducer from './operations/loan-proposal/custom-product-loan-proposal/custom-product-loan-proposal.reducer';

/** *******************RANGE LOAN PROPOSAL ************************** */
import RangeLoanProposalReducer from './operations/loan-proposal/range-loan-proposal/range-loan-proposal.reducer';

/** ******************* BUSINESS LOAN DISBURSEMENT ************************** */
import businessLoanDisbursementReducer from './operations/loan-disbursement/business-loan-disbursment/business-loan-disbmt.reducer';
import LoanIndexDisbursementReducer from './operations/loan-disbursement/loan-index-disbursement/loan-index-disbursement.reducer';

/** ******************* REPORTS ************************** */
import TatReportReducer from './reports/tat-report/tat-report.reducer';
import RequestReportReducer from './reports/requestReport/requestReport.reducer';
import AttendanceReportReducer from './reports/attendanceReport/attendanceReport.reducer';
import ProgressReportReducer from './reports/reportProgress/reportProgresst.reducer';
import MonthlyProgressReducer from './reports/monthlyProgress/monthlyProgress.reducer';
import BranchReportReducer from './reports/branchReport/branchReport.reducer';
import BranchProfileReducer from './reports/branchProfileReport/branchProfileReport.reducer';
import loanCardReportReducer from './reports/loanCardReport/loanCardReport.reducer';
import DailyCollectionReportReducer from './reports/dailyCollectionReport/dailyCollectionReport.reducer';
import TrainingReducer from './reports/training/training.reducer';
import PostAuditingReportReducer from './reports/postauditingReport/postauditingReport.reducer';
import PreCloseReportReducer from './reports/preclose-loan/preclose-loan.reducer';
import ClosedLoanDetailsReducer from './reports/closed-loan-details/closed-loan-details.reducer';
import WalletReportReducer from './reports/walletReport/walletReport.reducer';
import BranchCollectionReportReducer from './reports/branchCollectionReport/branchCollectionReport.reducer';
import futureDemandReportReducer from './reports/future-demand-report/future-demand-report.reducer';
import demandCollectionReportReducer from './reports/demand-collection-report/demand-collection-report.reducer';
import demandFollowupReportReducer from './reports/demand-followup-report/demand-followup-report.reducer';
import arrearFollowupReportReducer from './reports/arrear-followup-report/arrear-followup-report.reducer';
import regularDemandReportReducer from './reports/regular-demand-report/regular-demand-report.reducer';
import sanctionReportReducer from './reports/sanction-report/sanction-report.reducer';
import kycSourcedReportReducer from './reports/kyc-sourced-report/kyc-sourced-report.reducer';
import insuranceReportReducer from './reports/insurance-report/insurance-report.reducer';
import LTVReportReducer from './reports/ltv-report/ltv-report.reducer';
import NPAReportReducer from './reports/npa-report/npa-report.reducer';
import PortFolioAtRiskReducer from './reports/portflioAtRistReport/portflioAtRiskReport.reducer';
import portfolioReportReducer from './reports/portfolioReport/portfolioReport.reducer';
import ActiveMemberReducer from './reports/active-member/active-member.reducer';
import InActiveMemberReducer from './reports/in-active-member/in-active-member.reducer';
import DataVerificationReducer from './reports/data-verification/data-verification.reducer';
import ChangeMeetingReducer from './reports/change-meeting/change-meeting.reducer';
import MonthWiseInterestReducer from './reports/MonthWiseInterestReport/MonthWiseInterestReport.reducer';
import LoanDisbursementReducer from './reports/loan-disbursement/loan-disbursement.reducer';
import AuditTrailReportReducer from './reports/auditTrailReport/auditTrailReport.reducer';
import overDueReportReducer from './reports/overDueReport/overDueReport.reducer';
import staffReportReducer from './reports/staffReport/staffReport.reducer';
import accountDayBookReportReducer from './reports/accountDayBookReport/accountDayBookReport.reducer';
import branchDCBReportReducer from './reports/branchDCBReport/branchDCBReport.reducer';
import branchCBIReportReducer from './reports/creditBureauReport/creditBureauReport.reducer';
import memberRejectionReportReducer from './reports/memberRejectionReport/memberRejectionReport.reducer';
import memberDetailsReportReducer from './reports/member-details-report/member-details-report.reducer';
import LeadReportReducer from './reports/leadReport/leadReport.reducer';
import DeadMemberDetailsReducer from './reports/deadMemberReport/dead-member-report.reducer';
// verifications

import kycVerificationReducer from './operations/business/kyc-verification/kyc-verification.reducer';
import demandCollectionReducer from './operations/collection/demandCollection/demandCollection.reducer';
import arrearCollectionReducer from './operations/collection/arrearCollection/arrearCollection.reducer';
import closeLoanReducer from './operations/closeLoan/closeLoan.reducer';
import preCloseLoanReducer from './operations/preCloseLoan/preCloseLoan.reducer';
import changeGroupOfficerReducer from './operations/changeGroupOfficer/changeGroupOfficer.reducer';
import changeMeetingDateReducer from './operations/ChangeMeetingDate/changeMeetingDate.reducer';
import endOfDayReducer from './operations/endOfDay/endOfDay.reducer';

// PROFILE

import profileReducer from './profile/profile.reducer';

// DASHBOARD
import dashboardReducer from './Dashboard/Dashboard.reducer';
import qrReportReducer from './reports/qr-report/qr-report.reducer';
import highmarkRulesReducer from './userControlAccess/highmarkRules/highmarkRules.reducer';
import WorkFlowmanagement from './userControlAccess/workflowManagement/workflowManagement.reducer';
import ruleEngineReducer from './userControlAccess/ruleEngine/ruleEngine.reducer';
import reconciliationForQRReducer from './operations/reconciliationForQR/reconciliationForQR.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: userReducer,
	teamPage: teamReducer,
	staffPage: staffReducer,
	executivePage: executiveReducer,
	repaymentCollection: repaymentCollectionReportReducer,
	progressReportSelector: progressReportReducer,
	general: GeneralReducer,
	business_details: BusinessDetailsReducer,
	branch_details: BrachDetailsReducer,
	partner_details: PartnerDetailsReducer,
	bank_details: BankDetailsReducer,
	proof_details: ProofDetailsReducer,
	verify_details: VerificationDetailsReducer,
	parties_details: RelatedPartiesReducer,

	// Masters
	branch_master: BranchReducer,
	member_master: MemberReducer,
	verify_otp: VerifyOtpReducer,
	basic_details: BasicDetailsReducer,
	photo_verify: PhotoVerificationReducer,
	upload_signature: SignatureReducer,
	personal_details: PersonalDetailsReducer,
	family_details: FamilyDetailsReducer,
	nominee_details: BasicNomineeDetailsReducer,
	member_bank_details: BankReducer,
	house_details: HouseDetailsReducer,
	aadhar_details: AadharDetailsReducer,
	driving_licence: DrivingLicenceDetailsReducer,
	pancard_details: PancardDetailsReducer,
	passport: PassportDetailsReducer,
	ration_card_details: RationCardDetailsReducer,
	voter_Id_details: VoterIdDetailsReducer,
	address_extra_proof: AddressExtraProofReducer,
	passbook_cheque_details: PassbookChequeReducer,
	house_photos: HousePhotosReducer,
	extra_information: ExtraInformationReducer,
	official_details: OfficialDetailsReducer,
	noc_photos: NocPhotosReducer,
	residential_details: ResidentialDetailsReducer,
	current_address: CurrentAddressReducer,
	co_applicant: CoApplicantReducer,
	credit_check_details: CreditCheckReducer,
	fetch_member: MemberDetailsReducer,
	center_master: CenterReducer,
	loan_master: loanApplicationReducer,
	role_master: RoleReducer,
	listUserControl_details: AssignUserRightsReducer,
	memberMandatoryAccess_details: MemberMandatoryAccessReducer,
	staff_master: StaffReducer,
	purpose_of_loan: purposeofloanReducer,
	group_master: GroupReducer,
	thrift_master: ThriftReducer,
	product_type: ProductTypeReducer,
	insurance_company: InsuranceCompanyReducer,
	product_master: ProductReducer,
	economic_activity: EconomicActivityReducer,
	economic_activity_type: EconomicActivityTypeReducer,
	businessfund_master: businessfundReducer,
	funder_master: FunderReducer,
	questionnaire: QuestionnaireReducer,
	highmark_rules: highmarkRulesReducer,
	WorkFlow_management: WorkFlowmanagement,
	rule_engine: ruleEngineReducer,

	// operation - collection
	collection: collectionReducer,
	todayCollection: todayCollectionReducer,
	memberCollection: memberCollectionReducer,
	advanceCollection: advanceCollectionReducer,
	demandCollection: demandCollectionReducer,
	arrearCollection: arrearCollectionReducer,
	CloseLoan_member: closeLoanReducer,
	PreCloseLoan_member: preCloseLoanReducer,
	ChangeGroupOfficer: changeGroupOfficerReducer,
	ChangeMeetingDate: changeMeetingDateReducer,
	EndOfDayDetails: endOfDayReducer,
	Reconciliation_For_QR: reconciliationForQRReducer,

	// Loan Proposal
	business_loan_proposal: businessLoanProposalReducer,
	center_wise_loan_proposal: centerWiseLoanProposalReducer,
	gold_loan_proposal: goldLoanProposalReducer,
	product_loan_proposal: productLoanProposalReducer,
	custom_product_loan_proposal: customproductLoanProposalReducer,
	range_loan_proposal: RangeLoanProposalReducer,

	// Loan Disbursement
	business_loan_disbursment: businessLoanDisbursementReducer,
	loan_index_disbursement: LoanIndexDisbursementReducer,
	// Reports
	tat_report: TatReportReducer,
	request_report: RequestReportReducer,
	attendance_report: AttendanceReportReducer,
	progress_report: ProgressReportReducer,
	monthly_progress: MonthlyProgressReducer,
	branch_report: BranchReportReducer,
	branch_profile_report: BranchProfileReducer,
	loan_card_report: loanCardReportReducer,
	daily_collection_report: DailyCollectionReportReducer,
	training_report: TrainingReducer,
	post_auditing_report: PostAuditingReportReducer,
	preclose_report: PreCloseReportReducer,
	closed_loan_details: ClosedLoanDetailsReducer,
	wallet_report: WalletReportReducer,
	branch_collection_report: BranchCollectionReportReducer,
	future_demand_report: futureDemandReportReducer,
	demand_collection: demandCollectionReportReducer,
	demand_followup: demandFollowupReportReducer,
	arrear_followup: arrearFollowupReportReducer,
	regular_demand_report: regularDemandReportReducer,
	sanction_report: sanctionReportReducer,
	kyc_sourced_report: kycSourcedReportReducer,
	insurance_report: insuranceReportReducer,
	LTV_report: LTVReportReducer,
	NPA_report: NPAReportReducer,
	portfolio_at_risk_report: PortFolioAtRiskReducer,
	portfolio_report: portfolioReportReducer,
	active_member: ActiveMemberReducer,
	inactive_member: InActiveMemberReducer,
	data_verification: DataVerificationReducer,
	auditing_tool: AuditingToolReducer,
	change_meeting: ChangeMeetingReducer,
	month_wise_interest: MonthWiseInterestReducer,
	loan_disbursement: LoanDisbursementReducer,
	overDueReport: overDueReportReducer,
	audit_trail_report: AuditTrailReportReducer,
	Staff_Details: staffReportReducer,
	accountDayBookReport: accountDayBookReportReducer,
	branchDCBReport: branchDCBReportReducer,
	creditBureauReport: branchCBIReportReducer,
	memberRejectionReport: memberRejectionReportReducer,
	member_details_report: memberDetailsReportReducer,
	LeadReport: LeadReportReducer,
	QRReport: qrReportReducer,
	dead_member_details: DeadMemberDetailsReducer,
	// verifications

	kycverification_details: kycVerificationReducer,

	// PROFILE
	profile: profileReducer,

	// DASHBOARD
	dashboard: dashboardReducer,
});

export default persistReducer(persistConfig, rootReducer);
