/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchQrReport = (fetchData) => {
	const data = {
		QRPaymentReportToken: fetchData,
	};
	const url = `${API_URL}GetQRPaymentReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchParticularQrReport = (fetchData) => {
	const data = {
		ParticularQRPaymentReportToken: fetchData,
	};
	const url = `${API_URL}GetQRPaymentReportDueWiseWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const exportQrExcel = (postData) => {
	const params = new URLSearchParams();
	params.append('FetchQrRepaymentReportToken', postData);
	const url = `${API_URL}Report/Excel/QRPaymentReportExcelWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
