/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PreCloseLoanActionTypes from './preCloseLoan.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getPreCloseLoanMemberListArr: [],
	getPreCloseLoanMemberListStatus: null,
	updatePreCloseLoanMemberListStatus: null,
	updatePreCloseLoanMemberListArr: [],
	getBranchEODArr: [],
	getBranchEODStatus: null,
	revertPreCloseLoanMemberListStatus: null,
	revertPreCloseLoanMemberListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				getPreCloseLoanMemberListArr: docodeResponse(
					action.payload,
					'GetGroupMemberDetailsForPreCloseDetailsKey',
				),
				getPreCloseLoanMemberListStatus: 'SUCCESS',
			};

		case PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, getPreCloseLoanMemberListStatus: 'FAILURE' };

		case PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_CHANGED:
			return { ...state, getPreCloseLoanMemberListStatus: null };

		case PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				updatePreCloseLoanMemberListStatus: 'SUCCESS',
				updatePreCloseLoanMemberListArr: docodeResponse(
					action.payload,
					'UpdateGroupMemberDetailsForPreCloseKey',
				),
			};

		case PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, updatePreCloseLoanMemberListStatus: 'FAILURE' };

		case PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_CHANGED:
			return { ...state, updatePreCloseLoanMemberListStatus: null };

		case PreCloseLoanActionTypes.GET_BRANCH_EOD_SUCCESS:
			return {
				...state,
				getBranchEODArr: docodeResponse(
					action.payload,
					'GetBranchEODExistPreCloseDetailsKey',
				),
				getBranchEODStatus: 'SUCCESS',
			};

		case PreCloseLoanActionTypes.GET_BRANCH_EOD_FAILURE:
			return {
				...state,
				getBranchEODStatus: 'FAILURE',
				getBranchEODArr: docodeResponse(
					action.payload,
					'GetBranchEODExistPreCloseDetailsKey',
				),
			};

		case PreCloseLoanActionTypes.GET_BRANCH_EOD_CHANGED:
			return { ...state, getBranchEODStatus: null, getBranchEODArr: [] };

			case PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				revertPreCloseLoanMemberListStatus: 'SUCCESS',
				revertPreCloseLoanMemberListArr: docodeResponse(
					action.payload,
					'UpdateGroupMemberDetailsForPreCloseKey',
				),
			};

		case PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, revertPreCloseLoanMemberListStatus: 'FAILURE' };

		case PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_CHANGED:
			return { ...state, revertPreCloseLoanMemberListStatus: null };

		default:
			return state;
	}
};
