import axios from 'axios';
import { API_URL } from '../../../const';

export const nomineeDetailsAdd = (verifyData) => {
	const data = {
		CreateNomineeDetailsToken: verifyData,
	};
	const url = `${API_URL}addNomineeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getNomineeAddressList = (verifyData) => {
	const data = {
		FetchMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}fetchResidentialDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyNomineeAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyValidateNomineeAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharValidateOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getFetchNomineeRelationship = (verifyData) => {
	const data = {
		FetchMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}fetchNomineeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
