/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import demandCollectionActionTypes from './demandCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getDemandMemberLoanInfoResponse: null,
	getDemandMemberLoanInfoArr: [],
	updatedDemandCollectionRevertResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_SUCCESS:
			return {
				...state,
				getDemandMemberLoanInfoResponse: action.payload,
				getDemandMemberLoanInfoArr: docodeResponse(
					action.payload,
					'FetchDemandForTodayCollectionKey',
				),
			};

		case demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_FAILURE:
			return { ...state, getDemandMemberLoanInfoResponse: action.payload };

		case demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getDemandMemberLoanInfoResponse: null };

		case demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_SUCCESS:
			return {
				...state,
				updatedDemandCollectionRevertResponse: action.payload,
			};

		case demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_FAILURE:
			return { ...state, updatedDemandCollectionRevertResponse: action.payload };

		case demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_CHANGED:
			return { ...state, updatedDemandCollectionRevertResponse: null };

		default:
			return state;
	}
};
