/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanActionTypes from './product-loan-proposal.types';
import {
	fetchAllProductLoanProposalListGet,
	fetchParticularProductLoanProposalListGet,
	fetchWholeGroupMemberListGet,
	addProductLoanProposal
} from './product-loan-proposal.service';

import {
	getProductLoanProposalFailure,
	getProductLoanProposalSuccess,
	getProductLoanProposalListResponseChanged,
	getParticularProductLoanProposalFailure,
	getParticularProductLoanProposalSuccess,
	getParticularProductLoanProposalResponseChanged,
	getWholeGroupMemberFailure,
	getWholeGroupMemberSuccess,
	getWholeGroupMemberResponseChanged,
	addProductLoanProposalFailure,
	addProductLoanProposalSuccess,
	addProductLoanProposalResponseChanged

} from './product-loan-proposal.action';
import { encodeJson } from '../../../enode-decode';

/**   ******************* GET PRODUCT LOAN PROPOSAL *************** */
export function* productLoanProposalGet({ payload }) {
	try {
		const key = 'GetProductLoanProposalKey';
		const jsonData = {
			BranchID: payload.branchID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			LoanIndexType: payload.loanIndexType,
			UserID: payload.userID,
			Level: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllProductLoanProposalListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductLoanProposalFailure(responseData.data));
		} else {
			yield put(getProductLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductLoanProposalFailure(error));
	}
}

export function* resetGetProductLoanProposalResponse() {
	yield put(getProductLoanProposalListResponseChanged());
}

export function* onGetProductLoanProposal() {
	yield takeLatest(LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_START, productLoanProposalGet);
}

export function* onGetProductLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
		resetGetProductLoanProposalResponse,
	);
}
/**   ******************* GET PARTICULAR PRODUCT LOAN PROPOSAL *************** */
export function* particularProductLoanProposalGet({ payload }) {
	try {
		const key = 'GetParticularProductLoanProposalKey';
		const jsonData = {
			BranchID: payload.branchID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			LoanIndexType: payload.loanIndexType,
			UserID: payload.userID,
			Level: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchParticularProductLoanProposalListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularProductLoanProposalFailure(responseData.data));
		} else {
			yield put(getParticularProductLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularProductLoanProposalFailure(error));
	}
}

export function* resetGetParticularProductLoanProposalResponse() {
	yield put(getParticularProductLoanProposalResponseChanged());
}

export function* onGetParticularProductLoanProposal() {
	yield takeLatest(
		LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_START,
		particularProductLoanProposalGet,
	);
}

export function* onGetParticularProductLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
		resetGetParticularProductLoanProposalResponse,
	);
}

/**   ******************* GET WHOLE GROUP MEMBER PROPOSAL *************** */
export function* wholeGroupMemberGet({ payload }) {
	try {
		const key = 'FetchWholeGroupMemberDetailsKey';
		const jsonData = {
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndex: '-',
			Type: 'New',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchWholeGroupMemberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getWholeGroupMemberFailure(responseData.data));
		} else {
			yield put(getWholeGroupMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getWholeGroupMemberFailure(error));
	}
}

export function* resetGetWholeGroupMemberResponse() {
	yield put(getWholeGroupMemberResponseChanged());
}

export function* onGetWholeGroupMember() {
	yield takeLatest(LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_START, wholeGroupMemberGet);
}

export function* onGetWholeGroupMemberResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_RESPONSE_RESET,
		resetGetWholeGroupMemberResponse,
	);
}

/** *********************  START ADD PRODUCT DETAILS  ******************** */
export function* createProductLoanProposal({ payload }) {
	try {
		const key = 'AddLoanIndexTokenKey';
		const MemberlistJson = JSON.stringify(payload.groupMemberArr).replace(/"/g, "'");
		const InvestorlistJson = JSON.stringify(payload.ProductInvestorDetails).replace(/"/g, "'");
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
			AreaID: payload.BranchID,
			BranchID: payload.CenterID,
			LoanProposalDate: payload.LoanProposalDate,
			TotalLoanAmount: payload.TotalLoanAmount,
			LoanStartDate: payload.LoanStartDate,
			LoanEndDate: payload.LoanEndDate,
			MeetUpLocation: payload.MeetUpLocation,
			MemberMeetupLat: payload.MemberMeetupLat,
			MemberMeetupLong: payload.MemberMeetupLong,
			MeetUpTime: payload.MeetUpTime,
			GroupID: payload.GroupID,
			GroupProductID: payload.GroupProductID,
			GroupMemberLeadID: payload.GroupMemberLeadID,
			GroupOfficerID: payload.GroupOfficerID,
			CreatedUser: payload.userID,
			LoanIndexGroupMember: MemberlistJson,
			ProductInvestorDetails: InvestorlistJson,
			LoanIndexType: payload.LoanIndexType,
			FunderName: payload.FunderName,
			LoanType: payload.LoanType,
			LoanOfficerID: payload.LoanOfficerID,
			memberLeadID: payload.memberLeadID,

		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addProductLoanProposal, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductLoanProposalFailure(responseData.data));
		} else {
			yield put(addProductLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductLoanProposalFailure(error));
	}
}

export function* resetAddProductLoanProposalResponse() {
	yield put(addProductLoanProposalResponseChanged());
}

export function* onCreateProductLoanProposal() {
	yield takeLatest(LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_START, createProductLoanProposal);
}

export function* onCreateProductLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
		resetAddProductLoanProposalResponse,
	);
}
/** *********************  END ADD PRODUCT DETAILS ******************** */

export function* productLoanProposalSaga() {
	yield all([
		call(onGetProductLoanProposal),
		call(onGetProductLoanProposalResponseReset),
		call(onGetProductLoanProposal),
		call(onGetProductLoanProposalResponseReset),
		call(onGetWholeGroupMember),
		call(onGetWholeGroupMemberResponseReset),
		call(onCreateProductLoanProposal),
		call(onCreateProductLoanProposalResponseReset),
	]);
}
