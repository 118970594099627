/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import VoterIdDetailsActionType from './voter-id-details.types';

const INITIAL_STATE = {
	updateVoterIdResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case VoterIdDetailsActionType.UPDATE_VOTER_ID_SUCCESS:
			return { ...state, updateVoterIdResponse: action.payload };

		case VoterIdDetailsActionType.UPDATE_VOTER_ID_FAILURE:
			return { ...state, updateVoterIdResponse: action.payload };

		case VoterIdDetailsActionType.UPDATE_VOTER_ID_RESPONSE_CHANGED:
			return { ...state, updateVoterIdResponse: null };

		default:
			return state;
	}
};
