/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const ReconciliationForQRListGet = (listData) => {
	const data = {
		fetchQRPaymentForReconciliationDataToken: listData,
	};
	const url = `${API_URL}GetQRPaymentForReconciliationToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ReconciliationForQRListUpdate = (listData) => {
	const data = {
		updateQRPaymentForReconciliationDataToken: listData,
	};
	const url = `${API_URL}UpdateQRPaymentForReconciliationToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
