import RationCardDetailsActionType from './ration-card-details.types';

// update RationCard
export const updateRationCardStart = (rationCardCredentials) => ({
	type: RationCardDetailsActionType.UPDATE_RATION_CARD_START,
	payload: rationCardCredentials,
});

export const updateRationCardSuccess = (rationCardSuccess) => ({
	type: RationCardDetailsActionType.UPDATE_RATION_CARD_SUCCESS,
	payload: rationCardSuccess,
});

export const updateRationCardFailure = (rationCardFailure) => ({
	type: RationCardDetailsActionType.UPDATE_RATION_CARD_FAILURE,
	payload: rationCardFailure,
});

export const updateRationCardResponseResetStart = () => ({
	type: RationCardDetailsActionType.UPDATE_RATION_CARD_RESPONSE_REST_START,
});

export const updateRationCardResponseChanged = () => ({
	type: RationCardDetailsActionType.UPDATE_RATION_CARD_RESPONSE_CHANGED,
});
