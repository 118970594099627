import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

import { selectLoginStatus, getLoginObjDetails } from '../../redux/login/login.selector';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const LOGIN = lazy(() => import('../../pages/presentation/auth/Login'));
const ContentRoutes = () => {
	const loginStatus = useSelector(selectLoginStatus);
	const loginData = useSelector(getLoginObjDetails);

	const { databaseName } = loginData;

	return (
		<Routes>
			{loginStatus === 'Success' &&
				contents.map((page) => {
					if (
						databaseName !== 'SMCF' ||
						(databaseName === 'SMCF' && page.path === '/reportPages/LeadReport')
					) {
						return (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Route key={page.path} {...page} />
						);
					}
					return null;
				})}
			<Route path='/' element={<LOGIN />} />
			<Route path='*' element={<Navigate to='/' />} />
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
