/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AccountDayBookReportActionTypes from './accountDayBookReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAccountDayBookReportResponse: null,
	getAccountDayBookReportArr: [],
	getAccountDayBookReportData: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_SUCCESS:
			return {
				...state,
				getAccountDayBookReportResponse: action.payload,
				getAccountDayBookReportData: action.payload,
				getAccountDayBookReportArr: docodeResponse(
					action.payload,
					'FetchAccountDayBookKey',
				),
			};

		case AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_FAILURE:
			return { ...state, getAccountDayBookReportResponse: action.payload,
				getAccountDayBookReportData: action.payload,
				getAccountDayBookReportArr: [],
			 };

		case AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED:
			return { ...state, getAccountDayBookReportResponse: null };
		default:
			return state;
	}
};
