const ChangeMeetingDateActionTypes = {
	UPDATE_BRANCH_MEETING_DATE_START: 'update_branch_meeting_date_start',
	UPDATE_BRANCH_MEETING_DATE_SUCCESS: 'update_branch_meeting_date_success',
	UPDATE_BRANCH_MEETING_DATE_FAILURE: 'update_branch_meeting_date_failure',
	UPDATE_BRANCH_MEETING_DATE_RESPONSE_RESET: 'update_branch_meeting_date_response_reset',
	UPDATE_BRANCH_MEETING_DATE_RESPONSE_CHANGED: 'update_branch_meeting_date_response_changed',

	GET_BRANCH_MEETING_DATE_DETAILS_START: 'get_branch_meeting_date_details_start',
	GET_BRANCH_MEETING_DATE_DETAILS_SUCCESS: 'get_branch_meeting_date_details_success',
	GET_BRANCH_MEETING_DATE_DETAILS_FAILURE: 'get_branch_meeting_date_details_failure',
	GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_RESET:
		'get_branch_meeting_date_details_response_reset',
	GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_CHANGED:
		'get_branch_meeting_date_details_response_changed',

	GET_LOAN_CENTER_GROUP_DETAILS_START: 'get_loan_center_group_details_start',
	GET_LOAN_CENTER_GROUP_DETAILS_SUCCESS: 'get_loan_center_group_details_success',
	GET_LOAN_CENTER_GROUP_DETAILS_FAILURE: 'get_loan_center_group_details_failure',
	GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET: 'get_loan_center_group_details_response_reset',
	GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED:
		'get_loan_center_group_details_response_changed',

	GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_START:
		'get_particular_loan_center_group_details_start',
	GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_SUCCESS:
		'get_particular_loan_center_group_details_success',
	GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_FAILURE:
		'get_particular_loan_center_group_details_failure',
	GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET:
		'get_particular_loan_center_group_details_response_reset',
	GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED:
		'get_particular_loan_center_group_details_response_changed',

	UPDATE_SINGLE_BRANCH_MEETING_DATE_START: 'update_single_branch_meeting_date_start',
	UPDATE_SINGLE_BRANCH_MEETING_DATE_SUCCESS: 'update_single_branch_meeting_date_success',
	UPDATE_SINGLE_BRANCH_MEETING_DATE_FAILURE: 'update_single_branch_meeting_date_failure',
	UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_RESET:
		'update_single_branch_meeting_date_response_reset',
	UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_CHANGED:
		'update_single_branch_meeting_date_response_changed',

	UPDATE_SINGLE_CENTER_MEETING_DATE_START: 'update_single_center_meeting_date_start',
	UPDATE_SINGLE_CENTER_MEETING_DATE_SUCCESS: 'update_single_center_meeting_date_success',
	UPDATE_SINGLE_CENTER_MEETING_DATE_FAILURE: 'update_single_center_meeting_date_failure',
	UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_RESET:
		'update_single_center_meeting_date_response_reset',
	UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_CHANGED:
		'update_single_center_meeting_date_response_changed',

	UPDATE_SINGLE_MEMBER_MEETING_DATE_START: 'update_single_member_meeting_date_start',
	UPDATE_SINGLE_MEMBER_MEETING_DATE_SUCCESS: 'update_single_member_meeting_date_success',
	UPDATE_SINGLE_MEMBER_MEETING_DATE_FAILURE: 'update_single_member_meeting_date_failure',
	UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_RESET:
		'update_single_member_meeting_date_response_reset',
	UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_CHANGED:
		'update_single_member_meeting_date_response_changed',
};
export default ChangeMeetingDateActionTypes;
