/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import highmarkRulesTypes from './highmarkRules.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addHighmarkRulesResponse: null,
	fetchHighmarkRulesResponse: null,
	fetchHighmarkRulesArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case highmarkRulesTypes.ADD_HIGHMARK_RULES_SUCCESS:
			return { ...state, addHighmarkRulesResponse: action.payload };

		case highmarkRulesTypes.ADD_HIGHMARK_RULES_FAILURE:
			return { ...state, addHighmarkRulesResponse: action.payload };

		case highmarkRulesTypes.ADD_HIGHMARK_RULES_RESPONSE_CHANGED:
			return { ...state, addHighmarkRulesResponse: null };

		case highmarkRulesTypes.FETCH_HIGHMARK_RULES_SUCCESS:
			return {
				...state,
				fetchHighmarkRulesResponse: action.payload,
				fetchHighmarkRulesArr: docodeResponse(action.payload, 'FetchHighmarkRulesKey'),
			};

		case highmarkRulesTypes.FETCH_HIGHMARK_RULES_FAILURE:
			return {
				...state,
				fetchHighmarkRulesResponse: action.payload,
				fetchHighmarkRulesArr: [],
			};

		case highmarkRulesTypes.FETCH_HIGHMARK_RULES_RESPONSE_CHANGED:
			return { ...state, fetchHighmarkRulesResponse: null };

		default:
			return state;
	}
};
