/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import BankDetailsActionType from './bank-details.types';

const INITIAL_STATE = {
	bankResponse: null,
	bankISFCcode: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BankDetailsActionType.BANK_DETAILS_CREATE_SUCCESS:
			return { ...state, bankResponse: action.payload };

		case BankDetailsActionType.BANK_DETAILS_CREATE_FAILURE:
			return { ...state, bankResponse: action.payload };

		case BankDetailsActionType.BANK_DETAILS_CREATE_RESPONSE_CHANGED:
			return { ...state, bankResponse: null };

		case BankDetailsActionType.BANK_IFSC_CODE_RESPONSE_SUCCESS:
			return { ...state, bankISFCcode: action.payload };

		case BankDetailsActionType.BANK_IFSC_CODE_RESPONSE_FAILURE:
			return { ...state, bankISFCcode: action.payload };

		case BankDetailsActionType.BANK_IFSC_CODE_RESPONSE_CHANGED:
			return { ...state, bankISFCcode: null };

		default:
			return state;
	}
};
