/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import CurrentAddressActionType from './current-address.types';

const INITIAL_STATE = {
	saveAddressData: null,
	currentAddressResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CurrentAddressActionType.CURRENT_ADDRESS_ADD_CHANGED:
			return { ...state, saveAddressData: action.payload };

		case CurrentAddressActionType.CURRENT_ADDRESS_ADD_SUCCESS:
			return { ...state, currentAddressResponse: action.payload };

		case CurrentAddressActionType.CURRENT_ADDRESS_ADD_FAILURE:
			return { ...state, currentAddressResponse: action.payload };

		case CurrentAddressActionType.CURRENT_ADDRESS_ADD_RESPONSE_CHANGED:
			return { ...state, currentAddressResponse: null };

		default:
			return state;
	}
};
