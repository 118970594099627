/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const ChangeBranchMeetingDateUpdate = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}UpdateBranchMeetingDateToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeBranchMeetingDateGet = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}GetBranchMeetingDateDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const FetchLoanCenterGroupDetails = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}GetLoanCenterGroupDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const FetchParticularLoanCenterGroupDetails = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}GetLoanDetailsBasedOnCenterGroupToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeSingleBranchMeetingDateUpdate = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}UpdateBranchNewMeetingDateToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeSingleCenterMeetingDateUpdate = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}UpdateLoanIndexMeetingDateTimeToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeSingleMemberMeetingDateUpdate = (listData) => {
	const data = {
		ChangeBranchMeetingDateToken: listData,
	};
	const url = `${API_URL}UpdateLoanIndexMeetingDateTimeMemberWiseToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
