/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChangeMeetingDateActionTypes from './changeMeetingDate.types';

import {
	ChangeBranchMeetingDateUpdate,
	ChangeBranchMeetingDateGet,
	FetchLoanCenterGroupDetails,
	FetchParticularLoanCenterGroupDetails,
	ChangeSingleBranchMeetingDateUpdate,
	ChangeSingleCenterMeetingDateUpdate,
	ChangeSingleMemberMeetingDateUpdate,
} from './changeMeetingDate.service';

import {
	updateBranchMeetingDateSuccess,
	updateBranchMeetingDateFailure,
	updateBranchMeetingDateResponseChanged,
	getBranchMeetingDateDetailsSuccess,
	getBranchMeetingDateDetailsFailure,
	getBranchMeetingDateDetailsResponseChanged,
	getLoanCenterGroupDetailsSuccess,
	getLoanCenterGroupDetailsFailure,
	getLoanCenterGroupDetailsResponseChanged,
	getParticularLoanCenterGroupDetailsSuccess,
	getParticularLoanCenterGroupDetailsFailure,
	getParticularLoanCenterGroupDetailsResponseChanged,
	updateSingleBranchMeetingDateSuccess,
	updateSingleBranchMeetingDateFailure,
	updateSingleBranchMeetingDateResponseChanged,
	updateSingleCenterMeetingDateSuccess,
	updateSingleCenterMeetingDateFailure,
	updateSingleCenterMeetingDateResponseChanged,
	updateSingleMemberMeetingDateSuccess,
	updateSingleMemberMeetingDateFailure,
	updateSingleMemberMeetingDateResponseChanged,
} from './changeMeetingDate.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START UPDATE BRANCH MEETING DATE ******************** */
export function* updateChangeBranchMeetingDateDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';

		const jsonData = {
			BranchID: payload.branchID,
			MeetingDate: payload.MeetingDate,
			ChangeDate: payload.ChangeMeetingDate,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeBranchMeetingDateUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchMeetingDateFailure(responseData.data));
		} else {
			yield put(updateBranchMeetingDateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchMeetingDateFailure(error));
	}
}

export function* resetUpdateChangeBranchMeetingDateResponse() {
	yield put(updateBranchMeetingDateResponseChanged());
}

export function* onUpdateBranchMeetingDateDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_START,
		updateChangeBranchMeetingDateDetails,
	);
}

export function* onUpdateBranchMeetingDateDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_RESPONSE_RESET,
		resetUpdateChangeBranchMeetingDateResponse,
	);
}
/** *********************  END UPDATE BRANCH MEETING DATE ******************** */

/** *********************  START GET BRANCH MEETING DATE DETIALS ******************** */
export function* getChangeBranchMeetingDateDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';

		const jsonData = {
			BranchID: payload.branchID,
			MeetingDate: payload.MeetingDate,
			ChangeDate: payload.ChangeMeetingDate,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeBranchMeetingDateGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchMeetingDateDetailsFailure(responseData.data));
		} else {
			yield put(getBranchMeetingDateDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchMeetingDateDetailsFailure(error));
	}
}

export function* resetGetChangeBranchMeetingDateResponse() {
	yield put(getBranchMeetingDateDetailsResponseChanged());
}

export function* onGetBranchMeetingDateDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_START,
		getChangeBranchMeetingDateDetails,
	);
}

export function* onGetBranchMeetingDateDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_RESET,
		resetGetChangeBranchMeetingDateResponse,
	);
}
/** *********************  END GET BRANCH MEETING DATE DETIALS ******************** */

/** *********************  START GET LOAN CENTER GROUP DETAILS ******************** */
export function* getLoanCenterGroupDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';

		const jsonData = {
			CenterID: payload.centerID,
			ChangeType: payload.GroupBy,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(FetchLoanCenterGroupDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanCenterGroupDetailsFailure(responseData.data));
		} else {
			yield put(getLoanCenterGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanCenterGroupDetailsFailure(error));
	}
}

export function* resetGetLoanCenterGroupResponse() {
	yield put(getLoanCenterGroupDetailsResponseChanged());
}

export function* onGetLoanCenterGroupDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_START,
		getLoanCenterGroupDetails,
	);
}

export function* onGetLoanCenterGroupDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET,
		resetGetLoanCenterGroupResponse,
	);
}
/** *********************  END GET LOAN CENTER GROUP DETAILS ******************** */

/** *********************  START PARTICULAR GET LOAN CENTER GROUP DETAILS ******************** */
export function* getParticularLoanCenterGroupDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';

		const jsonData = {
			GroupID: payload.GroupID,
			LoanIndexID: payload.LoanIndexID,
			ChangeType: payload.ChangeType,
			CenterID: payload.CenterID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(FetchParticularLoanCenterGroupDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularLoanCenterGroupDetailsFailure(responseData.data));
		} else {
			yield put(getParticularLoanCenterGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularLoanCenterGroupDetailsFailure(error));
	}
}

export function* resetGetParticularLoanCenterGroupResponse() {
	yield put(getParticularLoanCenterGroupDetailsResponseChanged());
}

export function* onGetParticularLoanCenterGroupDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_START,
		getParticularLoanCenterGroupDetails,
	);
}

export function* onGetParticularLoanCenterGroupDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET,
		resetGetParticularLoanCenterGroupResponse,
	);
}
/** *********************  END PARTICULAR GET LOAN CENTER GROUP DETAILS ******************** */

/** *********************  START UPDATE SINGLE BRANCH MEETING DATE ******************** */
export function* updateSingleBranchMeetingDateDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';
		const jsonData = {
			BranchID: payload.BranchID,
			MeetingDate: payload.MeetingDate,
			ChangeDate: payload.ChangeDate,
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			BranchChangeType: payload.BranchChangeType,
			WhichWeek: payload.WhichWeek,
			WhichDay: payload.WhichDay,
			UserID: payload.UserID,
			BranchChangeReason: payload.BranchChangeReason,
			ChangeMeetingCenterArr: payload.ChangeMeetingCenterArr,
			IsSameDueDateBranch: payload.IsSameDueDateBranch,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeSingleBranchMeetingDateUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateSingleBranchMeetingDateFailure(responseData.data));
		} else {
			yield put(updateSingleBranchMeetingDateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateSingleBranchMeetingDateFailure(error));
	}
}

export function* resetUpdateSingleBranchMeetingDateResponse() {
	yield put(updateSingleBranchMeetingDateResponseChanged());
}

export function* onUpdateSingleBranchMeetingDateDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_START,
		updateSingleBranchMeetingDateDetails,
	);
}

export function* onUpdateSingleBranchMeetingDateDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_RESET,
		resetUpdateSingleBranchMeetingDateResponse,
	);
}
/** *********************  END UPDATE SINGLE BRANCH MEETING DATE ******************** */

/** *********************  START UPDATE SINGLE CENTER MEETING DATE ******************** */
export function* updateSingleCenterMeetingDateDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';
		const jsonData = {
			GroupID: payload.GroupID,
			GroupOfficerID: payload.GroupOfficerID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
			PreviousMeetingTime: payload.PreviousMeetingTime,
			CenterID: payload.CenterID,
			BranchID: payload.BranchID,
			ChangeType: payload.ChangeType,
			ChangeCenterGroup: payload.ChangeCenterGroup,
			ChangeDate: payload.ChangeDate,
			MeetingTime: payload.MeetingTime,
			ChangeReason: payload.ChangeReason,
			ChangedBy: payload.ChangedBy,
			WhichWeek: payload.WhichWeek,
			WhichDay: payload.WhichDay,
			IsSameDueDate: payload.IsSameDueDate,
			PreviousMeetingDate: payload.PreviousMeetingDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeSingleCenterMeetingDateUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateSingleCenterMeetingDateFailure(responseData.data));
		} else {
			yield put(updateSingleCenterMeetingDateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateSingleCenterMeetingDateFailure(error));
	}
}

export function* resetUpdateSingleCenterMeetingDateResponse() {
	yield put(updateSingleCenterMeetingDateResponseChanged());
}

export function* onUpdateSingleCenterMeetingDateDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_START,
		updateSingleCenterMeetingDateDetails,
	);
}

export function* onUpdateSingleCenterhMeetingDateDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_RESET,
		resetUpdateSingleCenterMeetingDateResponse,
	);
}
/** *********************  END UPDATE SINGLE CENTER MEETING DATE ******************** */

/** *********************  START UPDATE SINGLE MEMBER MEETING DATE ******************** */
export function* updateSingleMemberMeetingDateDetails({ payload }) {
	try {
		const key = 'ChangeMeetingDateKey';
		const jsonData = {
			GroupID: payload.GroupID,
			GroupOfficerID: payload.GroupOfficerID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
			PreviousMeetingTime: payload.PreviousMeetingTime,
			CenterID: payload.CenterID,
			BranchID: payload.BranchID,
			ChangeType: payload.ChangeType,
			ChangeCenterGroup: payload.ChangeCenterGroup,
			ChangeDate: payload.ChangeDate,
			MeetingTime: payload.MeetingTime,
			ChangeReason: payload.ChangeReason,
			ChangedBy: payload.ChangedBy,
			WhichWeek: payload.WhichWeek,
			WhichDay: payload.WhichDay,
			IsSameDueDate: payload.IsSameDueDate,
			PreviousMeetingDate: payload.PreviousMeetingDate,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeSingleMemberMeetingDateUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateSingleMemberMeetingDateFailure(responseData.data));
		} else {
			yield put(updateSingleMemberMeetingDateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateSingleMemberMeetingDateFailure(error));
	}
}

export function* resetUpdateSingleMemberMeetingDateResponse() {
	yield put(updateSingleMemberMeetingDateResponseChanged());
}

export function* onUpdateSingleMemberMeetingDateDetails() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_START,
		updateSingleMemberMeetingDateDetails,
	);
}

export function* onUpdateSingleMemberhMeetingDateDetailsResponseReset() {
	yield takeLatest(
		ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_RESET,
		resetUpdateSingleMemberMeetingDateResponse,
	);
}
/** *********************  END UPDATE SINGLE MEMBER MEETING DATE ******************** */

export function* ChangeMeetingDateSaga() {
	yield all([
		call(onUpdateBranchMeetingDateDetails),
		call(onUpdateBranchMeetingDateDetailsResponseReset),
		call(onGetBranchMeetingDateDetails),
		call(onGetBranchMeetingDateDetailsResponseReset),
		call(onGetLoanCenterGroupDetails),
		call(onGetLoanCenterGroupDetailsResponseReset),
		call(onGetParticularLoanCenterGroupDetails),
		call(onGetParticularLoanCenterGroupDetailsResponseReset),
		call(onUpdateSingleBranchMeetingDateDetails),
		call(onUpdateSingleBranchMeetingDateDetailsResponseReset),
		call(onUpdateSingleCenterMeetingDateDetails),
		call(onUpdateSingleCenterhMeetingDateDetailsResponseReset),
		call(onUpdateSingleMemberMeetingDateDetails),
		call(onUpdateSingleMemberhMeetingDateDetailsResponseReset),
	]);
}
