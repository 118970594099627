import { takeLatest, put, all, call } from 'redux-saga/effects';
import OfficialDetailsActionType from './official-details.types';
import {
	officialDetailsAddSuccess,
	officialDetailsAddFailure,
	officialDetailsAddUserResponseChanged,
	economicActivityListSuccess,
	economicActivityListFailure,
	economicActivityListUserResponseChanged,
	centerListSuccess,
	centerListFailure,
	centerListUserResponseChanged,
	productTypeListSuccess,
	productTypeListFailure,
	productTypeListResponseChanged,
} from './official-details.action';
import { encodeJson } from '../../../enode-decode';
import {
	officialMemberdetailsAdd,
	getEconomicActivityList,
	getCenterList,
	getProductTypeList,
} from './official-details.service';

// Start Official Details Add
export function* OfficialDetails({ payload }) {
	try {
		const key = 'OfficialDetailsToken';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			EconomicActivity: payload.economicActivity,
			EconomicActivityID: payload.economicActivitySelectValue,
			Center: payload.center,
			LocationId: payload.centerSelectValue,
			ApplicationAmount: payload.applicationAmount,
			AnnualIncome: payload.annualIncome,
			MonthlyIncome: payload.monthlyIncome,
			OtherMonthlyIncome: payload.otherMonthlyIncome,
			PerferredLanguage: payload.perferredLanguage,
			AppliedAmount: payload.appliedAmount,
			ProductTypeID: payload.productTypeSelectValue,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(officialMemberdetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(officialDetailsAddFailure(responseData.data));
		} else {
			yield put(officialDetailsAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(officialDetailsAddFailure(error));
	}
}

export function* OfficialDetailsResponseClear() {
	yield put(officialDetailsAddUserResponseChanged());
}

export function* onOfficialDetailsAddStart() {
	yield takeLatest(OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_START, OfficialDetails);
}

export function* onOfficialDetailsAddResponseRest() {
	yield takeLatest(
		OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_RESPONSE_REST_START,
		OfficialDetailsResponseClear,
	);
}
// End Official Details Add

// Start Economic Activity List
export function* EconomicActivityList({ payload }) {
	try {
		const key = 'FetchAllEconomicActivityListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getEconomicActivityList, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(economicActivityListFailure(responseData.data));
		} else {
			yield put(economicActivityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(economicActivityListFailure(error));
	}
}

export function* EconomicActivityListResponseClear() {
	yield put(economicActivityListUserResponseChanged());
}

export function* oneconomicActivityListStart() {
	yield takeLatest(OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_START, EconomicActivityList);
}

export function* oneconomicActivityListResponseRest() {
	yield takeLatest(
		OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_RESPONSE_REST_START,
		EconomicActivityListResponseClear,
	);
}
// End Economic Activity List

// Start Center List
export function* LocationList({ payload }) {
	try {
		const key = 'CenterListStaffKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			UserID: payload.userID,
			Level: payload.userLevel,
			BranchID: payload.userAccessBranchID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getCenterList, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(centerListFailure(responseData.data));
		} else {
			yield put(centerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(centerListFailure(error));
	}
}

export function* LocationListResponseClear() {
	yield put(centerListUserResponseChanged());
}

export function* onlocationListStart() {
	yield takeLatest(OfficialDetailsActionType.CENTER_LIST_START, LocationList);
}

export function* onlocationListResponseRest() {
	yield takeLatest(
		OfficialDetailsActionType.CENTER_LIST_RESPONSE_REST_START,
		LocationListResponseClear,
	);
}
// End Center List

// Start Product Type List
export function* fetchProductTypeList({ payload }) {
	try {
		const key = 'FetchProductTypeDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const postData = encodeJson(jsonData, key);
		const responseData = yield call(getProductTypeList, postData);
		if (responseData.data.statusCode === '100') {
			yield put(productTypeListFailure(responseData.data));
		} else {
			yield put(productTypeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productTypeListFailure(error));
	}
}

export function* productTypeListResponseClear() {
	yield put(productTypeListResponseChanged());
}

export function* onProductTypeListStart() {
	yield takeLatest(OfficialDetailsActionType.PRODUCT_TYPE_LIST_START, fetchProductTypeList);
}

export function* onProductTypeListResponseRest() {
	yield takeLatest(
		OfficialDetailsActionType.PRODUCT_TYPE_LIST_RESPONSE_RESET_START,
		productTypeListResponseClear,
	);
}
// End Product Type List

export function* officialDetailsSaga() {
	yield all([
		call(onOfficialDetailsAddStart),
		call(onOfficialDetailsAddResponseRest),
		call(oneconomicActivityListStart),
		call(oneconomicActivityListResponseRest),
		call(onlocationListStart),
		call(onlocationListResponseRest),
		call(onProductTypeListStart),
		call(onProductTypeListResponseRest),
	]);
}
