import axios from 'axios';
import { API_URL } from '../../../const';

export const officialMemberdetailsAdd = (verifyData) => {
	const data = {
		CreateOfficialDetailsToken: verifyData,
	};
	const url = `${API_URL}addOfficialDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEconomicActivityList = (verifyData) => {
	const data = {
		GetAllEconomicActivityListToken: verifyData,
	};
	const url = `${API_URL}GetAllEconomicActivity`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getCenterList = (verifyData) => {
	const data = {
		StaffCenterListToken: verifyData,
	};
	const url = `${API_URL}GetAllCenterListForMemberToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getProductTypeList = (Data) => {
	const data = {
		FetchProductTypeToken: Data,
	};
	const url = `${API_URL}fetchProductTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
