/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import EndOfDayActionTypes from './endOfDay.types';

import {
	AllCollectionDetailsGet,
	BranchEODWalletDetailsGet,
	BranchEODThriftDetailsGet,
	BranchEODSavingsDetailsGet,
	BranchEODFixedDepositDetailsGet,
	BranchEODYetToCollectArrearDetailsGet,
	DemandCollectionTotalDetailsGet,
	AdvanceCollectionTotalDetailsGet,
	ArrearCollectionTotalDetailsGet,
	WalletCollectionTotalDetailsGet,
	PrecloseCollectionTotalDetailsGet,
	LoanAdvanceUsedTotalDetailsGet,
	CloseLoanListDetailsGet,
	BranchEODDetailsGet,
	accountDayBookReportGet,
	PrecloseCollectionDetailsGet,
	AdvanceCollectionDetailsGet,
	ArrearCollectionDetailsGet,
	VoucherApprovedDetailsGet,
	SubmitEODDetailsGet,
	RevertEODDetailsGet,
} from './endOfDay.service';

import {
	getAllCollectionDetailsSuccess,
	getAllCollectionDetailsFailure,
	getAllCollectionDetailsResponseChanged,
	getBranchEODWalletDetailsSuccess,
	getBranchEODWalletDetailsFailure,
	getBranchEODWalletDetailsResponseChanged,
	getBranchEODThriftDetailsSuccess,
	getBranchEODThriftDetailsFailure,
	getBranchEODThriftDetailsResponseChanged,
	getBranchEODSavingsDetailsSuccess,
	getBranchEODSavingsDetailsFailure,
	getBranchEODSavingsDetailsResponseChanged,
	getBranchEODFixedDepositDetailsSuccess,
	getBranchEODFixedDepositDetailsFailure,
	getBranchEODFixedDepositDetailsResponseChanged,
	getBranchEODYetToCollectArrearDetailsSuccess,
	getBranchEODYetToCollectArrearDetailsFailure,
	getBranchEODYetToCollectArrearDetailsResponseChanged,
	getDemandCollectionTotalDetailsSuccess,
	getDemandCollectionTotalDetailsFailure,
	getDemandCollectionTotalDetailsResponseChanged,
	getAdvanceCollectionTotalDetailsSuccess,
	getAdvanceCollectionTotalDetailsFailure,
	getAdvanceCollectionTotalDetailsResponseChanged,
	getArrearCollectionTotalDetailsSuccess,
	getArrearCollectionTotalDetailsFailure,
	getArrearCollectionTotalDetailsResponseChanged,
	getWalletCollectionTotalDetailsSuccess,
	getWalletCollectionTotalDetailsFailure,
	getWalletCollectionTotalDetailsResponseChanged,
	getPrecloseCollectionTotalDetailsSuccess,
	getPrecloseCollectionTotalDetailsFailure,
	getPrecloseCollectionTotalDetailsResponseChanged,
	getLoanAdvanceUsedTotalDetailsSuccess,
	getLoanAdvanceUsedTotalDetailsFailure,
	getLoanAdvanceUsedTotalDetailsResponseChanged,
	getCloseLoanListDetailsSuccess,
	getCloseLoanListDetailsFailure,
	getCloseLoanListDetailsResponseChanged,
	getBranchEODDetailsSuccess,
	getBranchEODDetailsFailure,
	getBranchEODDetailsResponseChanged,
	getPreviousDayBankAccountDayBookReportSuccess,
	getPreviousDayBankAccountDayBookReportFailure,
	getPreviousDayBankAccountDayBookReportResponseChanged,
	getBankAccountDayBookReportSuccess,
	getBankAccountDayBookReportFailure,
	getBankAccountDayBookReportResponseChanged,
	getPreviousDayCashAccountDayBookReportSuccess,
	getPreviousDayCashAccountDayBookReportFailure,
	getPreviousDayCashAccountDayBookReportResponseChanged,
	getCashAccountDayBookReportSuccess,
	getCashAccountDayBookReportFailure,
	getCashAccountDayBookReportResponseChanged,
	getPrecloseCollectionDetailsSuccess,
	getPrecloseCollectionDetailsFailure,
	getPrecloseCollectionDetailsResponseChanged,
	getAdvanceCollectionDetailsSuccess,
	getAdvanceCollectionDetailsFailure,
	getAdvanceCollectionDetailsResponseChanged,
	getArrearCollectionDetailsSuccess,
	getArrearCollectionDetailsFailure,
	getArrearCollectionDetailsResponseChanged,
	getVoucherApprovedDetailsSuccess,
	getVoucherApprovedDetailsFailure,
	getVoucherApprovedDetailsResponseChanged,
	getSubmitEODDetailsSuccess,
	getSubmitEODDetailsFailure,
	getSubmitEODDetailsResponseChanged,
	getRevertEODDetailsSuccess,
	getRevertEODDetailsFailure,
	getRevertEODDetailsResponseChanged,
} from './endOfDay.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET ALL COLLECTION DETAILS  ******************** */
export function* getAllCollectionList({ payload }) {
	try {
		const key = 'GetAllCollectionDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllCollectionDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getAllCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllCollectionDetailsFailure(error));
	}
}

export function* resetAllCollectionDetailsResponse() {
	yield put(getAllCollectionDetailsResponseChanged());
}

export function* onGetAllCollectionDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_START, getAllCollectionList);
}

export function* onGetAllCollectionDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_RESPONSE_RESET,
		resetAllCollectionDetailsResponse,
	);
}
/** *********************  END GET ALL COLLECTION DETAILS ******************** */

/** *********************  START GET BRANCH EOD WALLET DETAILS  ******************** */
export function* getBranchEODWalletList({ payload }) {
	try {
		const key = 'GetEODWalletAmountDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			InputDate: payload.InputDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODWalletDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODWalletDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODWalletDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODWalletDetailsFailure(error));
	}
}

export function* resetBranchEODWalletDetailsResponse() {
	yield put(getBranchEODWalletDetailsResponseChanged());
}

export function* onGetBranchEODWalletDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_START,
		getBranchEODWalletList,
	);
}

export function* onGetBranchEODWalletDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_RESET,
		resetBranchEODWalletDetailsResponse,
	);
}
/** *********************  END GET ALL COLLECTION DETAILS ******************** */

/** *********************  START GET BRANCH EOD THRIFT DETAILS  ******************** */
export function* getBranchEODThriftList({ payload }) {
	try {
		const key = 'GetBranchEODThriftDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODThriftDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODThriftDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODThriftDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODThriftDetailsFailure(error));
	}
}

export function* resetBranchEODThriftDetailsResponse() {
	yield put(getBranchEODThriftDetailsResponseChanged());
}

export function* onGetBranchEODThriftDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_START,
		getBranchEODThriftList,
	);
}

export function* onGetBranchEODThriftDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_RESET,
		resetBranchEODThriftDetailsResponse,
	);
}
/** *********************  END GET ALL COLLECTION DETAILS ******************** */

/** *********************  START GET BRANCH EOD SAVINGS DETAILS  ******************** */
export function* getBranchEODSavingsList({ payload }) {
	try {
		const key = 'GetBranchEODSavingsDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODSavingsDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODSavingsDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODSavingsDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODSavingsDetailsFailure(error));
	}
}

export function* resetBranchEODSavingsDetailsResponse() {
	yield put(getBranchEODSavingsDetailsResponseChanged());
}

export function* onGetBranchEODSavingsDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_START,
		getBranchEODSavingsList,
	);
}

export function* onGetBranchEODSavingsDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_RESET,
		resetBranchEODSavingsDetailsResponse,
	);
}
/** *********************  END GET ALL COLLECTION DETAILS ******************** */

/** *********************  START GET BRANCH EOD FIXED DEPOSIT DETAILS  ******************** */
export function* getBranchEODFixedDepositList({ payload }) {
	try {
		const key = 'GetBranchEODFixedDepositDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODFixedDepositDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODFixedDepositDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODFixedDepositDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODFixedDepositDetailsFailure(error));
	}
}

export function* resetBranchEODFixedDepositDetailsResponse() {
	yield put(getBranchEODFixedDepositDetailsResponseChanged());
}

export function* onGetBranchEODFixedDepositDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_START,
		getBranchEODFixedDepositList,
	);
}

export function* onGetBranchEODFixedDepositDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_RESET,
		resetBranchEODFixedDepositDetailsResponse,
	);
}
/** *********************  END GET ALL FIXED DEPOSIT DETAILS ******************** */



/** **************  START GET BRANCH EOD YETTOCOLLECT ARREAR DETAILS  **************** */
export function* getBranchEODYetToCollectArrearList({ payload }) {
	try {
		const key = 'GetBranchEODYetToCollectArrearDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			DemandDate: payload.FromDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODYetToCollectArrearDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODYetToCollectArrearDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODYetToCollectArrearDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODYetToCollectArrearDetailsFailure(error));
	}
}

export function* resetBranchEODYetToCollectArrearDetailsResponse() {
	yield put(getBranchEODYetToCollectArrearDetailsResponseChanged());
}

export function* onGetBranchEODYetToCollectArrearDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_START,
		getBranchEODYetToCollectArrearList,
	);
}

export function* onGetBranchEODYetToCollectArrearDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_RESPONSE_RESET,
		resetBranchEODYetToCollectArrearDetailsResponse,
	);
}
/** *********************  END GET ALL YETTOCOLLECT ARREAR DETAILS ******************** */


/** *********************  START GET DEMAND COLLECTION TOTAL DETAILS  ******************** */
export function* getDemandCollectionTotalList({ payload }) {
	try {
		const key = 'GetDemandCollectionTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DemandCollectionTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDemandCollectionTotalDetailsFailure(responseData.data));
		} else {
			yield put(getDemandCollectionTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandCollectionTotalDetailsFailure(error));
	}
}

export function* resetDemandCollectionTotalDetailsResponse() {
	yield put(getDemandCollectionTotalDetailsResponseChanged());
}

export function* onGetDemandCollectionTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_START, getDemandCollectionTotalList);
}

export function* onGetDemandCollectionTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetDemandCollectionTotalDetailsResponse,
	);
}
/** *********************  END GET DEMAND COLLECTION TOTAL DETAILS ******************** */

/** *********************  START GET ADVANCE COLLECTION TOTAL DETAILS  ******************** */
export function* getAdvanceCollectionTotalList({ payload }) {
	try {
		const key = 'GetAdvanceCollectionTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AdvanceCollectionTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAdvanceCollectionTotalDetailsFailure(responseData.data));
		} else {
			yield put(getAdvanceCollectionTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAdvanceCollectionTotalDetailsFailure(error));
	}
}

export function* resetAdvanceCollectionTotalDetailsResponse() {
	yield put(getAdvanceCollectionTotalDetailsResponseChanged());
}

export function* onGetAdvanceCollectionTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_START, getAdvanceCollectionTotalList);
}

export function* onGetAdvanceCollectionTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetAdvanceCollectionTotalDetailsResponse,
	);
}
/** *********************  END GET ADVANCE COLLECTION TOTAL DETAILS ******************** */

/** *********************  START GET ARREAR COLLECTION TOTAL DETAILS  ******************** */
export function* getArrearCollectionTotalList({ payload }) {
	try {
		const key = 'GetArrearCollectionTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ArrearCollectionTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getArrearCollectionTotalDetailsFailure(responseData.data));
		} else {
			yield put(getArrearCollectionTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getArrearCollectionTotalDetailsFailure(error));
	}
}

export function* resetArrearCollectionTotalDetailsResponse() {
	yield put(getArrearCollectionTotalDetailsResponseChanged());
}

export function* onGetArrearCollectionTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_START, getArrearCollectionTotalList);
}

export function* onGetArrearCollectionTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetArrearCollectionTotalDetailsResponse,
	);
}
/** *********************  END GET ARREAR COLLECTION TOTAL DETAILS ******************** */

/** *********************  START GET WALLET COLLECTION TOTAL DETAILS  ******************** */
export function* getWalletCollectionTotalList({ payload }) {
	try {
		const key = 'GetWalletCollectionTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(WalletCollectionTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getWalletCollectionTotalDetailsFailure(responseData.data));
		} else {
			yield put(getWalletCollectionTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getWalletCollectionTotalDetailsFailure(error));
	}
}

export function* resetWalletCollectionTotalDetailsResponse() {
	yield put(getWalletCollectionTotalDetailsResponseChanged());
}

export function* onGetWalletCollectionTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_START, getWalletCollectionTotalList);
}

export function* onGetWalletCollectionTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetWalletCollectionTotalDetailsResponse,
	);
}
/** *********************  END GET WALLET COLLECTION TOTAL DETAILS ******************** */

/** *********************  START GET PRECLOSE COLLECTION TOTAL DETAILS  ******************** */
export function* getPrecloseCollectionTotalList({ payload }) {
	try {
		const key = 'GetPrecloseCollectionTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PrecloseCollectionTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPrecloseCollectionTotalDetailsFailure(responseData.data));
		} else {
			yield put(getPrecloseCollectionTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPrecloseCollectionTotalDetailsFailure(error));
	}
}

export function* resetPrecloseCollectionTotalDetailsResponse() {
	yield put(getPrecloseCollectionTotalDetailsResponseChanged());
}

export function* onGetPrecloseCollectionTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_START, getPrecloseCollectionTotalList);
}

export function* onGetPrecloseCollectionTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetPrecloseCollectionTotalDetailsResponse,
	);
}
/** *********************  END GET PRECLOSE COLLECTION TOTAL DETAILS ******************** */

/** *********************  START GET LOAN ADVANCE USED TOTAL DETAILS  ******************** */
export function* getLoanAdvanceUsedTotalList({ payload }) {
	try {
		const key = 'GetLoanAdvanceUsedTotalDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			EODDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanAdvanceUsedTotalDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanAdvanceUsedTotalDetailsFailure(responseData.data));
		} else {
			yield put(getLoanAdvanceUsedTotalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanAdvanceUsedTotalDetailsFailure(error));
	}
}

export function* resetLoanAdvanceUsedTotalDetailsResponse() {
	yield put(getLoanAdvanceUsedTotalDetailsResponseChanged());
}

export function* onGetLoanAdvanceUsedTotalDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_START, getLoanAdvanceUsedTotalList);
}

export function* onGetLoanAdvanceUsedTotalDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
		resetLoanAdvanceUsedTotalDetailsResponse,
	);
}
/** *********************  END GET PRECLOSE COLLECTION TOTAL DETAILS ******************** */


/** *********************  START GET CLOSE LOAN LIST DETAILS  ******************** */
export function* getCloseLoanDetailsList({ payload }) {
	try {
		const key = 'CloseLoanListFetchKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			RePayDate: payload.FromDate,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			CollectionMode: "closeLoans",
			OfficerID: 0,
			CenterID: 'All',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(CloseLoanListDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCloseLoanListDetailsFailure(responseData.data));
		} else {
			yield put(getCloseLoanListDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCloseLoanListDetailsFailure(error));
	}
}

export function* resetCloseLoanListDetailsResponse() {
	yield put(getCloseLoanListDetailsResponseChanged());
}

export function* onGetCloseLoanListDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_START, getCloseLoanDetailsList);
}

export function* onGetCloseLoanListDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_RESET,
		resetCloseLoanListDetailsResponse,
	);
}
/** *********************  END GET CLOSE LOAN LIST DETAILS ******************** */


/** *********************  START GET BRANCH EOD DETAILS  ******************** */
export function* getBranchEODDetailsList({ payload }) {
	try {
		const key = 'GetBranchEODDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			InputDate: payload.FromDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODDetailsFailure(responseData.data));
		} else {
			yield put(getBranchEODDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODDetailsFailure(error));
	}
}

export function* resetBranchEODDetailsResponse() {
	yield put(getBranchEODDetailsResponseChanged());
}

export function* onGetBranchEODDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_START, getBranchEODDetailsList);
}

export function* onGetBranchEODDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_RESPONSE_RESET,
		resetBranchEODDetailsResponse,
	);
}
/** *********************  END GET CLOSE LOAN LIST DETAILS ******************** */


/** *********************  START PREVIOUS DAY BANK ACCOUNT DAY BOOK DETAILS  ******************** */
export function* getPreviousDayBankAccountDayBookReport({ payload }) {
	try {
		const key = 'FetchAccountDayBookKey';
		const jsonData = {
			EntryDate: payload.EntryDate,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			AccountHeadID: payload.AccountHeadID,
			Infog_CompanyID: payload.Infog_CompanyID,
			AccountType: payload.AccountType,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			SearchbyDateType: payload.SearchbyDateType,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(accountDayBookReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPreviousDayBankAccountDayBookReportFailure(responseData.data));
		} else {
			yield put(getPreviousDayBankAccountDayBookReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPreviousDayBankAccountDayBookReportFailure(error));
	}
}

export function* resetGetPreviousDayBankAccountDayBookReportListResponse() {
	yield put(getPreviousDayBankAccountDayBookReportResponseChanged());
}

export function* onGetPreviousDayBankAccountDayBookReportDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_START,
		getPreviousDayBankAccountDayBookReport,
	);
}

export function* onGetPreviousDayBankAccountDayBookReportResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
		resetGetPreviousDayBankAccountDayBookReportListResponse,
	);
}
/** *********************  END GET PREVIOUS DAY BANK ACCOUNT DAY BOOK DETAILS ******************** */


/** *********************  START BANK ACCOUNT DAY BOOK DETAILS  ******************** */
export function* getBankAccountDayBookReport({ payload }) {
	try {
		const key = 'FetchAccountDayBookKey';
		const jsonData = {
			EntryDate: payload.EntryDate,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			AccountHeadID: payload.AccountHeadID,
			Infog_CompanyID: payload.Infog_CompanyID,
			AccountType: payload.AccountType,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			SearchbyDateType: payload.SearchbyDateType,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(accountDayBookReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBankAccountDayBookReportFailure(responseData.data));
		} else {
			yield put(getBankAccountDayBookReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBankAccountDayBookReportFailure(error));
	}
}

export function* resetGetBankAccountDayBookReportListResponse() {
	yield put(getBankAccountDayBookReportResponseChanged());
}

export function* onGetBankAccountDayBookReportDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_START,
		getBankAccountDayBookReport,
	);
}

export function* onGetBankAccountDayBookReportResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
		resetGetBankAccountDayBookReportListResponse,
	);
}
/** *********************  END GET BANK ACCOUNT DAY BOOK DETAILS ******************** */


/** *********************  START PREVIOUS DAY CASH ACCOUNT DAY BOOK DETAILS  ******************** */
export function* getPreviousDayCashAccountDayBookReport({ payload }) {
	try {
		const key = 'FetchAccountDayBookKey';
		const jsonData = {
			EntryDate: payload.EntryDate,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			AccountHeadID: payload.AccountHeadID,
			Infog_CompanyID: payload.Infog_CompanyID,
			AccountType: payload.AccountType,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			SearchbyDateType: payload.SearchbyDateType,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(accountDayBookReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPreviousDayCashAccountDayBookReportFailure(responseData.data));
		} else {
			yield put(getPreviousDayCashAccountDayBookReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPreviousDayCashAccountDayBookReportFailure(error));
	}
}

export function* resetGetPreviousDayCashAccountDayBookReportListResponse() {
	yield put(getPreviousDayCashAccountDayBookReportResponseChanged());
}

export function* onGetPreviousDayCashAccountDayBookReportDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_START,
		getPreviousDayCashAccountDayBookReport,
	);
}

export function* onGetPreviousDayCashAccountDayBookReportResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
		resetGetPreviousDayCashAccountDayBookReportListResponse,
	);
}
/** *********************  END GET PREVIOUS DAY CASH ACCOUNT DAY BOOK DETAILS ******************** */



/** *********************  START CASH ACCOUNT DAY BOOK DETAILS  ******************** */
export function* getCashAccountDayBookReport({ payload }) {
	try {
		const key = 'FetchAccountDayBookKey';
		const jsonData = {
			EntryDate: payload.EntryDate,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			AccountHeadID: payload.AccountHeadID,
			Infog_CompanyID: payload.Infog_CompanyID,
			AccountType: payload.AccountType,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			SearchbyDateType: payload.SearchbyDateType,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(accountDayBookReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCashAccountDayBookReportFailure(responseData.data));
		} else {
			yield put(getCashAccountDayBookReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCashAccountDayBookReportFailure(error));
	}
}

export function* resetGetCashAccountDayBookReportListResponse() {
	yield put(getCashAccountDayBookReportResponseChanged());
}

export function* onGetCashAccountDayBookReportDetails() {
	yield takeLatest(
		EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_START,
		getCashAccountDayBookReport,
	);
}

export function* onGetCashAccountDayBookReportResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
		resetGetCashAccountDayBookReportListResponse,
	);
}
/** *********************  END GET CASH ACCOUNT DAY BOOK DETAILS ******************** */


/** *********************  START GET PRECLOSE COLLECTION DETAILS  ******************** */
export function* getPrecloseCollectionList({ payload }) {
	try {
		const key = 'GetPrecloseCollectionDetailsKey';
		debugger;
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			InputDate: payload.InputDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PrecloseCollectionDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPrecloseCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getPrecloseCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPrecloseCollectionDetailsFailure(error));
	}
}

export function* resetPrecloseCollectionDetailsResponse() {
	yield put(getPrecloseCollectionDetailsResponseChanged());
}

export function* onGetPrecloseCollectionDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_START, getPrecloseCollectionList);
}

export function* onGetPrecloseCollectionDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_RESET,
		resetPrecloseCollectionDetailsResponse,
	);
}
/** *********************  END GET PRECLOSE COLLECTION DETAILS ******************** */

/** *********************  START GET ADVANCE COLLECTION DETAILS  ******************** */
export function* getAdvanceCollectionList({ payload }) {
	try {
		const key = 'GetAdvanceCollectionDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			InputDate: payload.InputDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AdvanceCollectionDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAdvanceCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getAdvanceCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAdvanceCollectionDetailsFailure(error));
	}
}

export function* resetAdvanceCollectionDetailsResponse() {
	yield put(getAdvanceCollectionDetailsResponseChanged());
}

export function* onGetAdvanceCollectionDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_START, getAdvanceCollectionList);
}

export function* onGetAdvanceCollectionDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_RESET,
		resetAdvanceCollectionDetailsResponse,
	);
}
/** *********************  END GET ADVANCE COLLECTION DETAILS ******************** */


/** *********************  START GET ARREAR COLLECTION DETAILS  ******************** */
export function* getArrearCollectionList({ payload }) {
	try {
		const key = 'GetArrearCollectionDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			InputDate: payload.InputDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ArrearCollectionDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getArrearCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getArrearCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getArrearCollectionDetailsFailure(error));
	}
}

export function* resetArrearCollectionDetailsResponse() {
	yield put(getArrearCollectionDetailsResponseChanged());
}

export function* onGetArrearCollectionDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_START, getArrearCollectionList);
}

export function* onGetArrearCollectionDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_RESPONSE_RESET,
		resetArrearCollectionDetailsResponse,
	);
}
/** *********************  END GET ARREAR COLLECTION DETAILS ******************** */


/** *********************  START GET VOUCHER APPROVED DETAILS  ******************** */
export function* getVoucherApprovedList({ payload }) {
	try {
		const key = 'GetVoucherApprovedDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			InputDate: payload.inputDate,
			Infog_CompanyID: payload.Infog_CompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(VoucherApprovedDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getVoucherApprovedDetailsFailure(responseData.data));
		} else {
			yield put(getVoucherApprovedDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getVoucherApprovedDetailsFailure(error));
	}
}

export function* resetVoucherApprovedDetailsResponse() {
	yield put(getVoucherApprovedDetailsResponseChanged());
}

export function* onGetVoucherApprovedDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_START, getVoucherApprovedList);
}

export function* onGetVoucherApprovedDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_RESPONSE_RESET,
		resetVoucherApprovedDetailsResponse,
	);
}
/** *********************  END GET VOUCHER APPROVED DETAILS ******************** */


/** *********************  START GET EOD SUBMIT DETAILS  ******************** */
export function* getSubmitEODList({ payload }) {
	try {
		const key = 'GetSubmitEODDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			InputDate: payload.InputDate,
			UserID: payload.UserID,
			CreatedBy: payload.CreatedBy,
			UserLevel: payload.UserLevel,
			HolidayDate: JSON.stringify(payload.HolidayDate).replace(/"/g, "'"),  //	pending to check this scenario.
			twoThousandValue: payload.twoThousandValue,
			fiveHundredValue: payload.fiveHundredValue,
			twoHundredValue: payload.twoHundredValue,
			hundredValue: payload.hundredValue,
			fiftyValue: payload.fiftyValue,
			twentyValue: payload.twentyValue,
			tenValue: payload.tenValue,
			fiveValue: payload.fiveValue,
			twoValue: payload.twoValue,
			oneValue: payload.oneValue,
			totalDenominationAmount: payload.totalDenominationAmount,
			UserName: payload.UserName,
			BranchName: payload.BranchName,
			BranchCashAccountID: payload.BranchCashAccountID,
			BranchBankAccountID: payload.BranchBankAccountID,
			BranchBankClosingBalance: payload.BranchBankClosingBalance,
			ClosingBalanceIsDrCr: payload.ClosingBalanceIsDrCr,
			isAutoEODNeeded: payload.isAutoEODNeeded,
			isAutoEODNeededSat: payload.isAutoEODNeededSat,
			InfogID: payload.InfogID,
			BankClosingBalanceIsDrCr: payload.BankClosingBalanceIsDrCr,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(SubmitEODDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSubmitEODDetailsFailure(responseData.data));
		} else {
			yield put(getSubmitEODDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSubmitEODDetailsFailure(error));
	}
}

export function* resetSubmitEODDetailsResponse() {
	yield put(getSubmitEODDetailsResponseChanged());
}

export function* onGetSubmitEODDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_START, getSubmitEODList);
}

export function* onGetSubmitEODDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_RESPONSE_RESET,
		resetSubmitEODDetailsResponse,
	);
}
/** *********************  END GET EOD SUBMIT DETAILS ******************** */

/** *********************  START GET EOD REVERT DETAILS  ******************** */
export function* getRevertEODList({ payload }) {
	try {
		const key = 'GetRevertEODDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			BranchName: payload.BranchName,
			EodDate: payload.EodDate,
			EodAmount: payload.EodAmount,
			UserID: payload.UserID,
			UserName: payload.UserName,
			CreatedBy: payload.CreatedBy,
			InfogID: payload.InfogID,
			TotalPrincipalReceived: payload.TotalPrincipalReceived,
			TotalInterestReceived: payload.TotalInterestReceived,
			BankOpeningBalance: payload.BankOpeningBalance,
			BankClosingBalance: payload.BankClosingBalance,
			CashOpeningBalance: payload.CashOpeningBalance,
			CashClosingBalance: payload.CashClosingBalance,
			twoThousandValue: payload.twoThousandValue,
			fiveHundredValue: payload.fiveHundredValue,
			twoHundredValue: payload.twoHundredValue,
			hundredValue: payload.hundredValue,
			fiftyValue: payload.fiftyValue,
			twentyValue: payload.twentyValue,
			tenValue: payload.tenValue,
			fiveValue: payload.fiveValue,
			twoValue: payload.twoValue,
			oneValue: payload.oneValue,
			totalDenominationAmount: payload.totalDenominationAmount,
			BranchCashAccountID: payload.BranchCashAccountID,
			BranchBankAccountID: payload.BranchBankAccountID,
			BranchBankClosingBalance: payload.BranchBankClosingBalance,
			ClosingBalanceIsDrCr: payload.ClosingBalanceIsDrCr,
			OpeningBalanceIsDrCr: payload.OpeningBalanceIsDrCr,
			BankOpeningBalanceIsDrCr: payload.BankOpeningBalanceIsDrCr,
			BankClosingBalanceIsDrCr: payload.BankClosingBalanceIsDrCr,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(RevertEODDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getRevertEODDetailsFailure(responseData.data));
		} else {
			yield put(getRevertEODDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRevertEODDetailsFailure(error));
	}
}

export function* resetRevertEODDetailsResponse() {
	yield put(getRevertEODDetailsResponseChanged());
}

export function* onGetRevertEODDetails() {
	yield takeLatest(EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_START, getRevertEODList);
}

export function* onGetRevertEODDetailsResponseReset() {
	yield takeLatest(
		EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_RESPONSE_RESET,
		resetRevertEODDetailsResponse,
	);
}
/** *********************  END GET EOD REVERT DETAILS ******************** */

export function* EndOfDaySaga() {
	yield all([
		call(onGetAllCollectionDetails),
		call(onGetAllCollectionDetailsResponseReset),
		call(onGetBranchEODWalletDetails),
		call(onGetBranchEODWalletDetailsResponseReset),
		call(onGetBranchEODThriftDetails),
		call(onGetBranchEODThriftDetailsResponseReset),
		call(onGetBranchEODSavingsDetails),
		call(onGetBranchEODSavingsDetailsResponseReset),
		call(onGetBranchEODFixedDepositDetails),
		call(onGetBranchEODFixedDepositDetailsResponseReset),
		call(onGetBranchEODYetToCollectArrearDetails),
		call(onGetBranchEODYetToCollectArrearDetailsResponseReset),
		call(onGetDemandCollectionTotalDetails),
		call(onGetDemandCollectionTotalDetailsResponseReset),
		call(onGetAdvanceCollectionTotalDetails),
		call(onGetAdvanceCollectionTotalDetailsResponseReset),
		call(onGetArrearCollectionTotalDetails),
		call(onGetArrearCollectionTotalDetailsResponseReset),
		call(onGetWalletCollectionTotalDetails),
		call(onGetWalletCollectionTotalDetailsResponseReset),
		call(onGetPrecloseCollectionTotalDetails),
		call(onGetPrecloseCollectionTotalDetailsResponseReset),
		call(onGetLoanAdvanceUsedTotalDetails),
		call(onGetLoanAdvanceUsedTotalDetailsResponseReset),
		call(onGetCloseLoanListDetails),
		call(onGetCloseLoanListDetailsResponseReset),
		call(onGetBranchEODDetails),
		call(onGetBranchEODDetailsResponseReset),
		call(onGetPreviousDayBankAccountDayBookReportDetails),
		call(onGetPreviousDayBankAccountDayBookReportResponseReset),
		call(onGetBankAccountDayBookReportDetails),
		call(onGetBankAccountDayBookReportResponseReset),
		call(onGetPreviousDayCashAccountDayBookReportDetails),
		call(onGetPreviousDayCashAccountDayBookReportResponseReset),
		call(onGetCashAccountDayBookReportDetails),
		call(onGetCashAccountDayBookReportResponseReset),
		call(onGetPrecloseCollectionDetails),
		call(onGetPrecloseCollectionDetailsResponseReset),
		call(onGetAdvanceCollectionDetails),
		call(onGetAdvanceCollectionDetailsResponseReset),
		call(onGetArrearCollectionDetails),
		call(onGetArrearCollectionDetailsResponseReset),
		call(onGetVoucherApprovedDetails),
		call(onGetVoucherApprovedDetailsResponseReset),
		call(onGetSubmitEODDetails),
		call(onGetSubmitEODDetailsResponseReset),
		call(onGetRevertEODDetails),
		call(onGetRevertEODDetailsResponseReset),
	]);
}
