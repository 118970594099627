/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';


export const fetchMandatoryMemberAccessType = (fetchData) => {
	const data = {
		MemberMandatoryAccessToken: fetchData,
	};
	const url = `${API_URL}MandatoryMemberAccessTypeToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateMandatoryMemberAccessType = (updateData) => {
	const data = {
		MemberMandatoryUpdateToken: updateData,
	};
	const url = `${API_URL}UpdateMandatoryMemberToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


