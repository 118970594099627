/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import BranchDCBReportActionTypes from './branchDCBReport.types';

/** ***************** GET BRANCH DCB REPORT START *********************** */
export const fetchPostBranchDCBReportStart = (getData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_START,
	payload: getData,
});

export const getBranchDCBReportDetailsSuccess = (successData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getBranchDCBReportDetailsFailure = (failureData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchDCBReportDetailsResponseResetStart = () => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_RESPONSE_RESET,
});

export const getBranchDCBReportDetailsResponseChanged = () => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH DCB REPORT END *********************** */

/** ***************** FETCH BRANCH DCB REPORT EXCEL DETAILS START *********************** */
export const fetchBranchDCBReportExcelStart = (addData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchBranchDCBReportExcelSuccess = (successData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchBranchDCBReportExcelFailure = (failureData) => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchBranchDCBReportResponseExcelResetStart = () => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_RESET,
});

export const fetchBranchDCBReportResponseExcelResetChanged = () => ({
	type: BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCH DCB REPORT EXCEL DETAILS END *********************** */
