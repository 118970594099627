/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import RequestReportActionTypes from './requestReport.types';

import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchRequestReportDetailsResponse: null,
	fetchRequestReportDetailsArr: [],
	addRequestReportDetailsArr: null,
	addRequestReportDetailsResponse: null,
	deleteRequestReportStatus: null,
	deleteRequestReportResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_SUCCESS:
			return {
				...state,
				fetchRequestReportDetailsResponse: action.payload,
				fetchRequestReportDetailsArr: docodeResponse(
					action.payload,
					'FetchRequestedReportKey',
				),
			};

		case RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_FAILURE:
			return { ...state, fetchRequestReportDetailsResponse: action.payload };

		case RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchRequestReportDetailsResponse: null };

		case RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_SUCCESS:
			return {
				...state,
				addRequestReportDetailsArr: action.payload,
				addRequestReportDetailsResponse: docodeResponse(
					action.payload,
					'AddRequestedReportKey',
				),
			};

		case RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_FAILURE:
			return { ...state, addRequestReportDetailsResponse: 'Failure' };

		case RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addRequestReportDetailsResponse: null };

		case RequestReportActionTypes.DELETE_REQUEST_REPORT_SUCCESS:
			return {
				...state,
				deleteRequestReportStatus: 'SUCCESS',
				deleteRequestReportResponse: docodeResponse(
					action.payload,
					'DeleteRequestedReportKey',
				),
			};

		case RequestReportActionTypes.DELETE_REQUEST_REPORT_FAILURE:
			return { ...state, deleteRequestReportStatus: 'Failure' };

		case RequestReportActionTypes.DELETE_REQUEST_REPORT_CHANGED:
			return { ...state, deleteRequestReportStatus: null };

		default:
			return state;
	}
};
