/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import ChangeGroupOficerActionTypes from './changeGroupOfficer.types';

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getBranchOfficerDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_START,
	payload: getData,
});

export const getBranchOfficerDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const getBranchOfficerDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const getBranchOfficerDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_RESPONSE_RESET,
});

export const getBranchOfficerDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON CHANGE BRANCH DETAILS START *********************** */
export const getChangeBranchOfficerDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_START,
	payload: getData,
});

export const getChangeBranchOfficerDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const getChangeBranchOfficerDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const getChangeBranchOfficerDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_RESET,
});

export const getChangeBranchOfficerDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON CHANGE BRANCH DETAILS END *********************** */

/** ***************** GET CENTER BASED ON OFFICER DETAILS START *********************** */
export const getOfficerCenterDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_START,
	payload: getData,
});

export const getOfficerCenterDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerCenterDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerCenterDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_RESPONSE_RESET,
});

export const getOfficerCenterDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET CENTER BASED ON OFFICER DETAILS END *********************** */

/** ***************** GET LOAN GROUP BASED ON CENTER DETAILS START *********************** */
export const getLoanGroupBasedOnCenter = (getData) => ({
	type: ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_START,
	payload: getData,
});

export const getLoanGroupDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const getLoanGroupDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const getLoanGroupDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_RESPONSE_RESET,
});

export const getLoanGroupDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** GET CENTER BASED ON OFFICER DETAILS END *********************** */

/** ***************** UPDATE CHANGE GROUP OFFICER DETAILS START *********************** */
export const updateChangeGroupOfficerDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_START,
	payload: getData,
});

export const updateChangeGroupOfficerDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const updateChangeGroupOfficerDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const updateChangeGroupOfficerDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_RESET,
});

export const updateChangeGroupOfficerDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_CHANGED,
});
/** ***************** UPDATE CHANGE GROUP OFFICER END *********************** */

/** ***************** UPDATE CHANGE PROPOSED OFFICER DETAILS START *********************** */
export const updateChangeProposedOfficerDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_START,
	payload: getData,
});

export const updateChangeProposedOfficerDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const updateChangeProposedOfficerDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const updateChangeProposedOfficerDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_RESET,
});

export const updateChangeProposedOfficerDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_CHANGED,
});
/** ***************** UPDATE CHANGE PROPOSED OFFICER END *********************** */

/** ***************** UPDATE CHANGE CENTER LOAN OFFICER DETAILS START *********************** */
export const updateChangeCenterLoanOfficerDetailsStart = (getData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_START,
	payload: getData,
});

export const updateChangeCenterLoanOfficerDetailsSuccess = (successData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_SUCCESS,
	payload: successData,
});

export const updateChangeCenterLoanOfficerDetailsFailure = (failureData) => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_FAILURE,
	payload: failureData,
});

export const updateChangeCenterLoanOfficerDetailsResponseResetStart = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_RESET,
});

export const updateChangeCenterLoanOfficerDetailsResponseChanged = () => ({
	type: ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_CHANGED,
});
/** ***************** UPDATE CHANGE CENTER LOAN OFFICER END *********************** */