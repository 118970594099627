import { takeLatest, put, all, call } from 'redux-saga/effects';
import NocPhotosActionType from './noc-photos.types';
import {
	updateNocPhotosSuccess,
	updateNocPhotosFailure,
	updateNocPhotosResponseChanged,
} from './noc-photos.action';
import { encodeJson } from '../../../enode-decode';
import { nocPhotosUpdate } from './noc-photos.service';

// Start Noc Photos Add
export function* updateNocPhotosId({ payload }) {
	try {
		const key = 'updateNocPhotosID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberNocImg1: payload.memberNocImage1,
			MemberNocImg2: payload.memberNocImage2,
			MemberNocImg3: payload.memberNocImage3,
			MemberNocDetails1: payload.memberNocDetails1,
			MemberNocDetails2: payload.memberNocDetails2,
			MemberNocDetails3: payload.memberNocDetails3,
			OfficerID: payload.userID,
		};
		const nocPhotosData = encodeJson(jsonData, key);
		const responseData = yield call(nocPhotosUpdate, nocPhotosData);
		if (responseData.data.statusCode === '100') {
			yield put(updateNocPhotosFailure(responseData.data));
		} else {
			yield put(updateNocPhotosSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateNocPhotosFailure(error));
	}
}

export function* updateNocPhotosResponseClear() {
	yield put(updateNocPhotosResponseChanged());
}

export function* onNocPhotosUpdateStart() {
	yield takeLatest(NocPhotosActionType.UPDATE_NOC_PHOTOS_START, updateNocPhotosId);
}

export function* onNocPhotosUpdateResponseRest() {
	yield takeLatest(
		NocPhotosActionType.UPDATE_NOC_PHOTOS_RESPONSE_REST_START,
		updateNocPhotosResponseClear,
	);
}
// End Noc Photos Add

export function* nocPhotosSaga() {
	yield all([call(onNocPhotosUpdateStart), call(onNocPhotosUpdateResponseRest)]);
}
