/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AuditingToolActionTypes from './auditing-tool.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAuditingToolListResponse: null,
	getAuditingToolListArr: [],

	getAuditingToolDetailsStatus: null,
	getAuditingToolDetailsArr: [],

	getQuestionnaireResponse: null,
	getQuestionnaireArr: [],

	getTrainingProposalMembersStatus: null,
	getTrainingProposalMembersArr: [],

	getTrainingProposalIndividualStatus: null,
	getTrainingProposalIndividualArr: [],

	addTrainingProposalStatus: null,
	addTrainingProposalArr: [],

	updateTrainingProposalStatus: null,
	updateTrainingProposalArr: [],

	deleteTrainingProposalStatus: null,
	deleteTrainingProposalArr: [],

	getAllSuperviserListStatus: null,
	getAllSuperviserListArr: [],

	approveTrainingProposalStatus: null,
	approveTrainingProposalArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_SUCCESS:
			return {
				...state,
				getAuditingToolListResponse: action.payload,
				getAuditingToolListArr: docodeResponse(
					action.payload,
					'GetTrainingProposalListKey',
				),
			};

		case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_FAILURE:
			return { ...state, getAuditingToolListResponse: action.payload };

		case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED:
			return { ...state, getAuditingToolListResponse: null };

		case AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_SUCCESS:
			return {
				...state,
				getAuditingToolDetailsStatus: 'SUCCESS',
				getAuditingToolDetailsArr: docodeResponse(
					action.payload,
					'GetTrainingProposalDetailsKey',
				),
			};

		case AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_FAILURE:
			return { ...state, getAuditingToolDetailsStatus: 'FAILURE' };

		case AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_CHANGED:
			return { ...state, getAuditingToolDetailsStatus: null };

		case AuditingToolActionTypes.GET_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				getQuestionnaireResponse: action.payload,
				getQuestionnaireArr: docodeResponse(action.payload, 'GetAllQuestionnaireListKey'),
			};

		case AuditingToolActionTypes.GET_QUESTIONNAIRE_FAILURE:
			return { ...state, getQuestionnaireResponse: action.payload };

		case AuditingToolActionTypes.GET_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, getQuestionnaireResponse: null };

		// TRAINING PROPOSAL MEMBERS SECTION
		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_SUCCESS:
			return {
				...state,
				getTrainingProposalMembersArr: docodeResponse(
					action.payload,
					'GetAllTrainingMembersKey',
				),
				getTrainingProposalMembersStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_FAILURE:
			return { ...state, getTrainingProposalMembersStatus: 'FAILUER' };

		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_CHANGED:
			return { ...state, getTrainingProposalMembersStatus: null };

		// TRAINING PROPOSAL SECTION
		case AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_SUCCESS:
			return {
				...state,
				addTrainingProposalArr: docodeResponse(
					action.payload,
					'NewGroupTrainingProposalKey',
				),
				addTrainingProposalStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_FAILURE:
			return { ...state, addTrainingProposalStatus: 'FAILUER' };

		case AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_CHANGED:
			return { ...state, addTrainingProposalStatus: null };

		case AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_SUCCESS:
			return {
				...state,
				updateTrainingProposalArr: docodeResponse(
					action.payload,
					'UpdateGroupTrainingProposalKey',
				),
				updateTrainingProposalStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_FAILURE:
			return { ...state, updateTrainingProposalStatus: 'FAILUER' };

		case AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_CHANGED:
			return { ...state, updateTrainingProposalStatus: null };

		case AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_SUCCESS:
			return {
				...state,
				deleteTrainingProposalArr: docodeResponse(
					action.payload,
					'DeleteGroupTrainingProposalKey',
				),
				deleteTrainingProposalStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_FAILURE:
			return { ...state, deleteTrainingProposalStatus: 'FAILUER' };

		case AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_CHANGED:
			return { ...state, deleteTrainingProposalStatus: null };

		// GET ALL SUPERVISER LIST SECTION
		case AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_SUCCESS:
			return {
				...state,
				getAllSuperviserListArr: docodeResponse(action.payload, 'GetAllSuperviserListKey'),
				getAllSuperviserListStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_FAILURE:
			return { ...state, getAllSuperviserListStatus: 'FAILUER' };

		case AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_CHANGED:
			return { ...state, getAllSuperviserListStatus: null };

		// REJECT TRAINING PROPOSAL SECTION
		case AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_SUCCESS:
			return {
				...state,
				approveTrainingProposalArr: docodeResponse(
					action.payload,
					'UpdateTraningProposalStatusKey',
				),
				approveTrainingProposalStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_FAILURE:
			return { ...state, approveTrainingProposalStatus: 'FAILUER' };

		case AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_CHANGED:
			return { ...state, approveTrainingProposalStatus: null };

		// TRAINING PROPOSAL INDIVIDUAL SECTION
		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_SUCCESS:
			return {
				...state,
				getTrainingProposalIndividualArr: docodeResponse(
					action.payload,
					'GetAllTrainingIndividualKey',
				),
				getTrainingProposalIndividualStatus: 'SUCCESS',
			};

		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_FAILURE:
			return { ...state, getTrainingProposalIndividualStatus: 'FAILUER' };

		case AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_CHANGED:
			return { ...state, getTrainingProposalIndividualStatus: null };
		default:
			return state;
	}
};
