import { takeLatest, put, all, call } from 'redux-saga/effects';
import PancardDetailsActionType from './pancard-details.types';
import {
	updatePancardSuccess,
	updatePancardFailure,
	updatePancardResponseChanged,
} from './pancard-details.action';
import { encodeJson } from '../../../enode-decode';
import { pancardUpdateDetails } from './pancard-details.service';

// Start Pancard Details Add
export function* updatePancardIdDetails({ payload }) {
	try {
		const key = 'updatePancardID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberPancardImg: payload.memberPanCardImg,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const pancardData = encodeJson(jsonData, key);
		const responseData = yield call(pancardUpdateDetails, pancardData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePancardFailure(responseData.data));
		} else {
			yield put(updatePancardSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePancardFailure(error));
	}
}

export function* updatePancardDetailsResponseClear() {
	yield put(updatePancardResponseChanged());
}

export function* onPancardDetailsUpdateStart() {
	yield takeLatest(PancardDetailsActionType.UPDATE_PANCARD_START, updatePancardIdDetails);
}

export function* onPancardDetailsUpdateResponseRest() {
	yield takeLatest(
		PancardDetailsActionType.UPDATE_PANCARD_RESPONSE_REST_START,
		updatePancardDetailsResponseClear,
	);
}
// End Pancard Details Add

export function* pancardDetailsSaga() {
	yield all([call(onPancardDetailsUpdateStart), call(onPancardDetailsUpdateResponseRest)]);
}
