/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PreCloseReportActionTypes from './preclose-loan.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getPrecloseReportListResponse: null,
	getPrecloseReportListStatus: null,

	getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],

	getPrecloseExcelListResponse: null,
	getPrecloseExcelListArr: [],
	getPrecloseExcelListStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_SUCCESS:
			return {
				...state,
				getPreCloseReportListResponse: docodeResponse(
					action.payload,
					'GetMemberPreCloseKey',
				),
				getPrecloseReportListStatus: 'Success',
			};

		case PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_FAILURE:
			return { ...state, getPrecloseReportListStatus: 'Failure' };

		case PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getPrecloseReportListStatus: null };

		case PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getOfficerBranchListResponse: action.payload,
				getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
			};

		case PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
			return { ...state, getOfficerBranchListResponse: action.payload };

		case PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getOfficerBranchListResponse: null };

		case PreCloseReportActionTypes.GET_PRECLOSE_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getPrecloseExcelListResponse: action.payload,
				getPrecloseExcelListArr: docodeResponse(action.payload, 'PrecloseReportExcelKey'),
				getPrecloseExcelListStatus: 'Success',
			};

		case PreCloseReportActionTypes.GET_PRECLOSE_EXCEL_LIST_FAILURE:
			return { ...state, getPrecloseExcelListStatus: action.payload };

		case PreCloseReportActionTypes.GET_PRECLOSE_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getPrecloseExcelListStatus: null };
		default:
			return state;
	}
};
