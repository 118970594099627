/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import memberDetailsReportTypes from './member-details-report.types';
import {
	postAllMemberDetailReport,
	MemberDetailsExcelListGet,
	MemberExperianExcelListGet,
	HighMarkExcelListGet,
	CommonExcelListGet,
} from './member-details-report.service';
import {
	fetchMemberDetailsReportSuccess,
	fetchMemberDetailsReportFailure,
	fetchMemberDetailsReportResponseResetChanged,
	fetchMemberDetailsReportExcelSuccess,
	fetchMemberDetailsReportExcelFailure,
	fetchMemberDetailsReportResponseExcelResetChanged,
	fetchMemberExperianReportExcelSuccess,
	fetchMemberExperianReportExcelFailure,
	fetchMemberExperianReportResponseExcelResetChanged,
	fetchHighMarkReportExcelSuccess,
	fetchHighMarkReportExcelFailure,
	fetchHighMarkReportResponseExcelResetChanged,
	fetchCommonReportExcelSuccess,
	fetchCommonReportExcelFailure,
	fetchCommonReportResponseExcelResetChanged,
} from './member-details-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH MEMBER DETAILS REPORT DETAILS  ******************** */
export function* fetchMemberDetailsReportReportDetails({ payload }) {
	try {
		const key = 'PostAllMemberDetailReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			TillDate: payload.tillDate,
			UserLevel: payload.userLevel,
			SearchByLoanModal: payload.loanFilter,
			SearchBy: payload.loanDateFilter,
			BranchID: payload.branchID,
			UserID: payload.userID,
			SearchType: payload.dateFilter,
			LoanStatus: payload.loanStatusFilter,
			OrderListBy: payload.wiseFilter,
			LoanFunder: payload.loanFunder,
			Limit: payload.limit,
			IsThriftExist: payload.isThrift,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(postAllMemberDetailReport, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(fetchMemberDetailsReportFailure(responseData.data));
		} else {
			yield put(fetchMemberDetailsReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberDetailsReportFailure(error));
	}
}

export function* resetFetchMemberDetailsReportResponse() {
	yield put(fetchMemberDetailsReportResponseResetChanged());
}

export function* onFetchMemberDetailsReportDetails() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_START,
		fetchMemberDetailsReportReportDetails,
	);
}

export function* onFetchMemberDetailsReportResponseReset() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_RESET_START,
		resetFetchMemberDetailsReportResponse,
	);
}
/** *********************  END FETCH MEMBER DETAILS REPORT DETAILS ******************** */

/** *********************  START GET MEMBER DETAILS EXCEL REPORT  ******************** */
export function* getMemberDetailsExcelList({ payload }) {
	try {
		const key = 'MemberDetailsExcelKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			OrderListBy: payload.wiseFilter,
			SearchBy: payload.loanDateFilter,
			TillDate: payload.tillDate,
			SearchType: payload.dateFilter,
			LoanStatus: payload.loanStatusFilter,
			LoanFunder: payload.loanFunder,
			ProvisionType: payload.ProvisionType,
			IsThriftExist: payload.isThrift,
			GeneralCompanyID: payload.generalCompanyID,
			CountLimit: payload.limit,
			IsMoratoriumExist: payload.isMoratorium,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(MemberDetailsExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberDetailsReportExcelFailure(responseData.data));
		} else {
			yield put(fetchMemberDetailsReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberDetailsReportExcelFailure(error));
	}
}

export function* resetGetMemberDetailsExcelListResponse() {
	yield put(fetchMemberDetailsReportResponseExcelResetChanged());
}

export function* onGetMemberDetailsExcelListDetails() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_START,
		getMemberDetailsExcelList,
	);
}

export function* onGetMemberDetailsExcelListResponseReset() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_RESET_START,
		resetGetMemberDetailsExcelListResponse,
	);
}
/** *********************  END GET MEMBER DETAILS EXCEL REPORT ******************** */

/** *********************  START GET MEMBER EXPERIAN EXCEL REPORT  ******************** */
export function* getMemberExperianExcelList({ payload }) {
	try {
		const key = 'MemberExperianExcelKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			GroupListBy: payload.groupFilter,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			OrderListBy: payload.wiseFilter,
			SearchBy: payload.loanDateFilter,
			TillDate: payload.tillDate,
			SearchType: payload.dateFilter,
			LoanStatus: payload.loanStatusFilter,
			SearchByLoanModal: payload.loanFilter,
			LoanFunder: payload.loanFunder,
			ProvisionType: payload.ProvisionType,
			IsThriftExist: payload.isThrift,
			GeneralCompanyID: payload.generalCompanyID,
			CountLimit: payload.limit,
			IsMoratoriumExist: payload.isMoratorium,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(MemberExperianExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberExperianReportExcelFailure(responseData.data));
		} else {
			yield put(fetchMemberExperianReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberExperianReportExcelFailure(error));
	}
}

export function* resetGetMemberExperianExcelListResponse() {
	yield put(fetchMemberExperianReportResponseExcelResetChanged());
}

export function* onGetMemberExperianExcelListDetails() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_START,
		getMemberExperianExcelList,
	);
}

export function* onGetMemberExperianExcelListResponseReset() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_RESET_START,
		resetGetMemberExperianExcelListResponse,
	);
}
/** *********************  END GET MEMBER EXPERIAN EXCEL REPORT ******************** */

/** *********************  START GET HIGH MARK EXCEL REPORT  ******************** */
export function* getHighMarkExcelList({ payload }) {
	try {
		const key = 'HighmarkExcelKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			GroupListBy: payload.groupFilter,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			OrderListBy: payload.wiseFilter,
			SearchBy: payload.loanDateFilter,
			TillDate: payload.tillDate,
			DateType: payload.dateFilter,
			LoanStatus: payload.loanStatusFilter,
			SearchByLoanModal: payload.loanFilter,
			LoanFunder: payload.loanFunder,
			ProvisionType: payload.ProvisionType,
			IsThriftExist: payload.isThrift,
			GeneralCompanyID: payload.generalCompanyID,
			CountLimit: payload.limit,
			IsMoratoriumExist: payload.isMoratorium,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(HighMarkExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchHighMarkReportExcelFailure(responseData.data));
		} else {
			yield put(fetchHighMarkReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchHighMarkReportExcelFailure(error));
	}
}

export function* resetGetHighMarkExcelListResponse() {
	yield put(fetchHighMarkReportResponseExcelResetChanged());
}

export function* onGetHighMarkExcelListDetails() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_START,
		getHighMarkExcelList,
	);
}

export function* onGetHighMarkExcelListResponseReset() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_RESET_START,
		resetGetHighMarkExcelListResponse,
	);
}
/** *********************  END GET HIGH MARK EXCEL REPORT ******************** */

/** *********************  START GET COMMON EXCEL REPORT  ******************** */
export function* getCommonExcelList({ payload }) {
	try {
		const key = 'CommonMemberExportKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			GroupListBy: payload.groupFilter,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			OrderListBy: payload.wiseFilter,
			SearchBy: payload.loanDateFilter,
			TillDate: payload.tillDate,
			SearchType: payload.dateFilter,
			LoanStatus: payload.loanStatusFilter,
			SearchByLoanModal: payload.loanFilter,
			LoanFunder: payload.loanFunder,
			ProvisionType: payload.ProvisionType,
			IsThriftExist: payload.isThrift,
			GeneralCompanyID: payload.generalCompanyID,
			CountLimit: payload.limit,
			IsMoratoriumExist: payload.isMoratorium,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(CommonExcelListGet, encodeData, payload.requestReportPage);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCommonReportExcelFailure(responseData.data));
		} else {
			yield put(fetchCommonReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCommonReportExcelFailure(error));
	}
}

export function* resetGetCommonExcelListResponse() {
	yield put(fetchCommonReportResponseExcelResetChanged());
}

export function* onGetCommonExcelListDetails() {
	yield takeLatest(memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_START, getCommonExcelList);
}

export function* onGetCommonExcelListResponseReset() {
	yield takeLatest(
		memberDetailsReportTypes.FETCH_COMMON_REPORT_RESPONSE_EXCEL_RESET_START,
		resetGetCommonExcelListResponse,
	);
}
/** *********************  END GET COMMON EXCEL REPORT ******************** */

export function* memberDetailsReportSaga() {
	yield all([
		call(onFetchMemberDetailsReportDetails),
		call(onFetchMemberDetailsReportResponseReset),
		call(onGetMemberDetailsExcelListDetails),
		call(onGetMemberDetailsExcelListResponseReset),
		call(onGetMemberExperianExcelListDetails),
		call(onGetMemberExperianExcelListResponseReset),
		call(onGetHighMarkExcelListDetails),
		call(onGetHighMarkExcelListResponseReset),
		call(onGetCommonExcelListDetails),
		call(onGetCommonExcelListResponseReset),
	]);
}
