/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const changeMeetingListGet = (listData) => {
	const data = {
		GetChangeMeetingReportToken: listData,
	};
	const url = `${API_URL}PostAllChangeMeeting`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const officerBranchListGet = (listData) => {
	const data = {
		GetOfficerListBranchToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedonBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const changeMeetingListExportGet = (postData) => {
	const params = new URLSearchParams();
	params.append('changeMeetingListGetExportToken', postData);
	const url = `${API_URL}Report/Excel/ChangeMeetingReportExcelWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};