/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import BasicDetailsActionType from './basic-details.types';
import {
	basicDetailsAddSuccess,
	basicDetailsAddFailure,
	basicDetailsAddUserResponseChanged,
	skipMemberDetailsResponseChanged,
	skipMemberDetailsFailure,
	skipMemberDetailsSuccess,
	verifyKYCResponseChanged,
	verifyKYCFailure,
	verifyKYCSuccess,
	memberCompletionResponseChanged,
	memberCompletionFailure,
	memberCompletionSuccess,
	verifyAadharOtpResponseChanged,
	verifyAadharOtpFailure,
	verifyAadharOtpSuccess,
	verifyValidateAadharOtpResponseChanged,
	verifyValidateAadharOtpFailure,
	verifyValidateAadharOtpSuccess,
	fetchVerificationDetailsResponseChanged,
	fetchVerificationDetailsFailure,
	fetchVerificationDetailsSuccess,
} from './basic-details.action';
import { encodeJson } from '../../../enode-decode';
import {
	basicMemberDetailsAdd,
	memberCompletionAPI,
	skipMemberDetailsAPI,
	verifyKYCAPI,
	verifyAadharOtpAPI,
	verifyValidateAadharOtpAPI,
	fetchKycVerificationAPI,
} from './basic-details.service';

// Start Basic Details Add
export function* AddBasicDetails({ payload }) {
	try {
		const key = 'AddMemberBasicDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberName: payload.memberName,
			MemberDateOfBirth: payload.memberDateOfBirth,
			MemberGender: payload.memberGender,
			MemberAadharNumber: payload.memberAadharNumber,
			AadharJSON: payload.aadharQRjson,
			MemberPanNumber: payload.memberPanNumber,
			Pincode: payload.pincode,
			DoorNo: payload.doorNo,
			AddressStreet: payload.addressStreet,
			City: payload.city,
			District: payload.district,
			State: payload.state,
			CurrentPincode: payload.currentPincode,
			CurrentDoorNo: payload.currentDoorNo,
			CurrentAddressStreet: payload.currentAddressStreet,
			CurrentState: payload.currentState,
			CurrentDistrict: payload.currentDistrict,
			CurrentCity: payload.currentCity,
			Country: payload.country,
			MemberPhone: payload.memberPhone,
			OfficerID: payload.userID,
			IsAadharScanned: payload.isAadharScanned,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(basicMemberDetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(basicDetailsAddFailure(responseData.data));
		} else {
			yield put(basicDetailsAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(basicDetailsAddFailure(error));
	}
}

export function* AddBasicDetailsResponseClear() {
	yield put(basicDetailsAddUserResponseChanged());
}

export function* onBasicDetailsAddStart() {
	yield takeLatest(BasicDetailsActionType.BASIC_DETAILS_ADD_START, AddBasicDetails);
}

export function* onBasicDetailsAddResponseRest() {
	yield takeLatest(
		BasicDetailsActionType.BASIC_DETAILS_ADD_RESPONSE_REST_START,
		AddBasicDetailsResponseClear,
	);
}
// End Basic Details Add

// Start Skip Details
export function* toSkipMemberDetails({ payload }) {
	try {
		const key = 'MemberDataSkipKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			PageStageVal: payload.PageStageVal,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(skipMemberDetailsAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(skipMemberDetailsFailure(responseData.data));
		} else {
			yield put(skipMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(skipMemberDetailsFailure(error));
	}
}

export function* SkipMemberDetaislResponseClear() {
	yield put(skipMemberDetailsResponseChanged());
}

export function* onSkipMemberDetailsStart() {
	yield takeLatest(BasicDetailsActionType.SKIP_MEMBER_DETAILS_START, toSkipMemberDetails);
}

export function* onSkipMemberDetailsRest() {
	yield takeLatest(
		BasicDetailsActionType.SKIP_MEMBER_DETAILS_RESPONSE_RESET_START,
		SkipMemberDetaislResponseClear,
	);
}
// End Skip Details

// Start Member Completion
export function* toUpdateMemberCompletion({ payload }) {
	try {
		const key = 'MemberCompletionKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(memberCompletionAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(memberCompletionFailure(responseData.data));
		} else {
			yield put(memberCompletionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberCompletionFailure(error));
	}
}

export function* MemberCompletionResponseClear() {
	yield put(memberCompletionResponseChanged());
}

export function* onMemberCompletionStart() {
	yield takeLatest(BasicDetailsActionType.MEMBER_COMPLETION_START, toUpdateMemberCompletion);
}

export function* onMemberCompletionRest() {
	yield takeLatest(
		BasicDetailsActionType.MEMBER_COMPLETION_RESPONSE_RESET_START,
		MemberCompletionResponseClear,
	);
}
// End Member Completion

// Start verify KYC Details
export function* toVerifyKYCDetails({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(verifyKYCAPI, verifyData, payload.serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(verifyKYCFailure(responseData.data));
		} else {
			yield put(verifyKYCSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyKYCFailure(error));
	}
}

export function* VerifyKYCDetailsResponseClear() {
	yield put(verifyKYCResponseChanged());
}

export function* onVerifyKYCDetailsStart() {
	yield takeLatest(BasicDetailsActionType.VERIFY_KYC_DETAILS_START, toVerifyKYCDetails);
}

export function* onVerifyKYCDetailsRest() {
	yield takeLatest(
		BasicDetailsActionType.VERIFY_KYC_DETAILS_RESPONSE_RESET_START,
		VerifyKYCDetailsResponseClear,
	);
}
// End Verify KYC Details

// Start verify Aadhar Otp
export function* toVerifyAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(verifyAadharOtpAPI, verifyData, payload.serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(verifyAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyAadharOtpFailure(error));
	}
}

export function* VerifyAadharOtpResponseClear() {
	yield put(verifyAadharOtpResponseChanged());
}

export function* onVerifyAadharOtpStart() {
	yield takeLatest(BasicDetailsActionType.VERIFY_AADHAR_OTP_START, toVerifyAadharOtp);
}

export function* onVerifyAadharOtpRest() {
	yield takeLatest(
		BasicDetailsActionType.VERIFY_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyAadharOtpResponseClear,
	);
}
// End Verify Aadhar Otp

// Start verify validate Aadhar Otp
export function* toVerifyValidateAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			RandNum: payload.randNum,
			DOB: payload.DOB,
			Otp: payload.otp,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyValidateAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyValidateAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyValidateAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyValidateAadharOtpFailure(error));
	}
}

export function* VerifyValidateAadharOtpResponseClear() {
	yield put(verifyValidateAadharOtpResponseChanged());
}

export function* onVerifyValidateAadharOtpStart() {
	yield takeLatest(
		BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_START,
		toVerifyValidateAadharOtp,
	);
}

export function* onVerifyValidateAadharOtpRest() {
	yield takeLatest(
		BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyValidateAadharOtpResponseClear,
	);
}
// End Verify validate Aadhar Otp

// Start fetch Verification Details
export function* toVerifyDetailsFetch({ payload }) {
	try {
		const key = 'AppVerifyKYCFetchKey';
		const jsonData = {
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(fetchKycVerificationAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchVerificationDetailsFailure(responseData.data));
		} else {
			yield put(fetchVerificationDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchVerificationDetailsFailure(error));
	}
}

export function* VerifyDetailsFetchRespClear() {
	yield put(fetchVerificationDetailsResponseChanged());
}

export function* onVerifyDetailsFetchStart() {
	yield takeLatest(BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_START, toVerifyDetailsFetch);
}

export function* onVerifyDetailsFetchReset() {
	yield takeLatest(
		BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_RESPONSE_RESET_START,
		VerifyDetailsFetchRespClear,
	);
}
// End fetch Verification Details

export function* basicDetailsSaga() {
	yield all([
		call(onBasicDetailsAddStart),
		call(onBasicDetailsAddResponseRest),
		call(onSkipMemberDetailsStart),
		call(onSkipMemberDetailsRest),
		call(onVerifyKYCDetailsStart),
		call(onVerifyKYCDetailsRest),
		call(onMemberCompletionStart),
		call(onMemberCompletionRest),
		call(onVerifyAadharOtpStart),
		call(onVerifyAadharOtpRest),
		call(onVerifyValidateAadharOtpStart),
		call(onVerifyValidateAadharOtpRest),
		call(onVerifyDetailsFetchStart),
		call(onVerifyDetailsFetchReset),
	]);
}
