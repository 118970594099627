/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import GroupActionTypes from './group.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addGroupDetailsResponse: null,
	getGroupListResponse: null,
	getGroupListArr: [],

	fetchAllGroupResponse: null,
	fetchAllGroupArr: [],

	fetchAllApprovedMemberResponse: null,
	fetchAllApprovedMemberArr: [],

	allOfficerResponse: null,
	allOfficerArr: [],

	allGroupDetailsResponse: null,
	allGroupDetailsArr: [],

	branchOfficerResponse: null,
	branchOfficerArr: [],

	updateExistGroupResponse: null,
	removeMemberFromGroupResponse: null,
	removeGroupResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GroupActionTypes.ADD_GROUP_DETAILS_SUCCESS:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_FAILURE:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, addGroupDetailsResponse: null };

		case GroupActionTypes.GET_GROUP_LIST_SUCCESS:
			return {
				...state,
				getGroupListResponse: action.payload,
				getGroupListArr: docodeResponse(action.payload, 'GetAllGroupListKey'),
			};

		case GroupActionTypes.GET_GROUP_LIST_FAILURE:
			return { ...state, getGroupListResponse: action.payload, getGroupListArr: [] };

		case GroupActionTypes.GET_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, getGroupListResponse: null };

		case GroupActionTypes.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				fetchAllGroupResponse: action.payload,
				fetchAllGroupArr: docodeResponse(action.payload, 'FetchAllGroupListKey'),
			};

		case GroupActionTypes.FETCH_ALL_GROUP_FAILURE:
			return { ...state, fetchAllGroupResponse: action.payload, fetchAllGroupArr: [] };

		case GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED:
			return { ...state, fetchAllGroupResponse: null };

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_SUCCESS:
			return {
				...state,
				fetchAllApprovedMemberResponse: action.payload,
				fetchAllApprovedMemberArr: docodeResponse(
					action.payload,
					'FetchApprovedMembersKey',
				),
			};

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_FAILURE:
			return {
				...state,
				fetchAllApprovedMemberResponse: action.payload,
				fetchAllApprovedMemberArr: [],
			};

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED:
			return { ...state, fetchAllApprovedMemberResponse: null };

		case GroupActionTypes.GET_ALL_OFFICER_SUCCESS:
			return {
				...state,
				allOfficerResponse: action.payload,
				allOfficerArr: docodeResponse(action.payload, 'GetAllOfficerKey'),
			};

		case GroupActionTypes.GET_ALL_OFFICER_FAILURE:
			return { ...state, allOfficerResponse: action.payload, allOfficerArr: [] };

		case GroupActionTypes.GET_ALL_OFFICER_RESPONSE_CHANGED:
			return { ...state, allOfficerResponse: null };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_SUCCESS:
			return {
				...state,
				allGroupDetailsResponse: action.payload,
				allGroupDetailsArr: docodeResponse(action.payload, 'GetAllGroupDetailsKey'),
			};

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_FAILURE:
			return { ...state, allGroupDetailsResponse: action.payload, allGroupDetailsArr: [] };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, allGroupDetailsResponse: null };

		case GroupActionTypes.GET_BRANCH_OFFICER_SUCCESS:
			return {
				...state,
				branchOfficerResponse: action.payload,
				branchOfficerArr: docodeResponse(action.payload, 'CENTEROFFICERLISTBASEDONCOMPANY'),
			};

		case GroupActionTypes.GET_BRANCH_OFFICER_FAILURE:
			return { ...state, branchOfficerResponse: action.payload, branchOfficerArr: [] };

		case GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_CHANGED:
			return { ...state, branchOfficerResponse: null };

		case GroupActionTypes.UPDATE_EXIST_GROUP_SUCCESS:
			return { ...state, updateExistGroupResponse: action.payload };

		case GroupActionTypes.UPDATE_EXIST_GROUP_FAILURE:
			return { ...state, updateExistGroupResponse: action.payload };

		case GroupActionTypes.UPDATE_EXIST_GROUP_RESPONSE_CHANGED:
			return { ...state, updateExistGroupResponse: null };

		case GroupActionTypes.REMOVE_MEMBER_FROM_SUCCESS:
			return { ...state, removeMemberFromGroupResponse: action.payload };

		case GroupActionTypes.REMOVE_MEMBER_FROM_FAILURE:
			return { ...state, removeMemberFromGroupResponse: action.payload };

		case GroupActionTypes.REMOVE_MEMBER_FROM_RESPONSE_CHANGED:
			return { ...state, removeMemberFromGroupResponse: null };

		case GroupActionTypes.REMOVE_GROUP_SUCCESS:
			return { ...state, removeGroupResponse: action.payload };

		case GroupActionTypes.REMOVE_GROUP_FAILURE:
			return { ...state, removeGroupResponse: action.payload };

		case GroupActionTypes.REMOVE_GROUP_RESPONSE_CHANGED:
			return { ...state, removeGroupResponse: null };

		default:
			return state;
	}
};
