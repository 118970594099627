const MemberMandatoryAccessActionTypes = {

	FETCH_MEMBER_MANDATORY_ACCESS_START: 'fetch_member_mandatory_access_Start',
	FETCH_MEMBER_MANDATORY_ACCESS_SUCCESS: 'fetch_member_mandatory_access_Success',
	FETCH_MEMBER_MANDATORY_ACCESS_FAILURE: 'fetch_member_mandatory_access_Failure',
	FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET: 'fetch_member_mandatory_access_ResponseReset',
	FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED: 'fetch_member_mandatory_access_ResponseChanged',

	UPDATE_MEMBER_MANDATORY_ACCESS_START: 'update_member_mandatory_access_Start',
	UPDATE_MEMBER_MANDATORY_ACCESS_SUCCESS: 'update_member_mandatory_access_Success',
	UPDATE_MEMBER_MANDATORY_ACCESS_FAILURE: 'update_member_mandatory_access_Failure',
	UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET: 'update_member_mandatory_access_ResponseReset',
	UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED: 'update_member_mandatory_access_ResponseChanged',
};
export default MemberMandatoryAccessActionTypes;
