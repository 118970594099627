/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import arrearCollectionActionTypes from './arrearCollection.types';
import { fetchArrearLoanList, arrearCollectionRevertAPI, arrearCollectionAPI } from './arrearCollection.service';

import {
	fetchArrearMemberLoanInfoSuccess,
	fetchArrearMemberLoanInfoFailure,
	fetchArrearMemberLoanInfoResponseChanged,
	updateArrearCollectionRevertResponseChanged,
	updateArrearCollectionRevertSuccess,
	updateArrearCollectionRevertFailure,
	updateArrearCollectionChanged,
	updateArrearCollectionSuccess,
	updateArrearCollectionFailure,
} from './arrearCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START MEMBER LOAN LIST DETAILS  ******************** */
export function* getArrearMemberLoanList({ payload }) {
	try {
		const key = 'FetchArrearMemberListKey';
		const jsonData = {
			GroupOfficerID: payload.officerID,
			DatabaseName: payload.databaseName,
			RepayDate: payload.arrearCollectionDate,
			FromDate: payload.arrearCollectionDate,
			ToDate: payload.arrearCollectionDate,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			LocationID: payload.centerID,
			GroupID: payload.groupID,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchArrearLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchArrearMemberLoanInfoFailure(responseData.data));
		} else {
			yield put(fetchArrearMemberLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchArrearMemberLoanInfoFailure(error));
	}
}

export function* resetGetArrearMemberLoanListResponse() {
	yield put(fetchArrearMemberLoanInfoResponseChanged());
}

export function* onGetArrearMemberLoanListDetails() {
	yield takeLatest(
		arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_START,
		getArrearMemberLoanList,
	);
}

export function* onGetArrearMemberLoanListResponseReset() {
	yield takeLatest(
		arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_RESPONSE_RESET,
		resetGetArrearMemberLoanListResponse,
	);
}
/** *********************  END GET MEMBER LOAN LIST  ******************** */


/** *********************  START ARREAR COLLECTION REVERT  ******************** */
export function* updateArrearCollectionList({ payload }) {
	try {
		const key = 'UpdateCommonLoanPaidDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			UserID: payload.userID,
			UserName: payload.userName,
			twoThousand: payload.twoThousand,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			TotalDenomination: payload.totalDenominationAmount,
			// receiptNumber: localCredential.ReceiptNumber,
			// chequeNumber: localCredential.ChequeNumber,
			CollectionLoanArr: JSON.stringify(payload.loanArrearMemberInfo).replace(/"/g, "'"),
			paymentMethod: payload.paymentMethod,
			collectionPage: 'ArrearCollection',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(arrearCollectionAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateArrearCollectionFailure(responseData.data));
		} else {
			yield put(updateArrearCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateArrearCollectionFailure(error));
	}
}

export function* resetArrearCollectionResponse() {
	yield put(updateArrearCollectionChanged());
}

export function* onArrearCollection() {
	yield takeLatest(
		arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_START,
		updateArrearCollectionList,
	);
}

export function* onArrearCollectionResponseReset() {
	yield takeLatest(
		arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_RESET,
		resetArrearCollectionResponse,
	);
}
/** *********************  END ARREAR COLLECTION UPDATE  ******************** */

/** *********************  START ARREAR COLLECTION REVERT  ******************** */
export function* revertArrearCollectionList({ payload }) {
	try {
		const key = 'RevertArrearForTodayCollectionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			CollectionDate: payload.collectionDate,
			ToDate: payload.collectionDate,
			CenterID: payload.centerID,
			RepayDate: payload.collectionDate,
			GroupID: payload.groupID,
			UserID: payload.userID,
			UserName: payload.userName,
			LoanIndexGroupMemberIDs: payload.loanIndexGroupMemberIDs,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(arrearCollectionRevertAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateArrearCollectionRevertFailure(responseData.data));
		} else {
			yield put(updateArrearCollectionRevertSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateArrearCollectionRevertFailure(error));
	}
}

export function* resetArrearCollectionRevertResponse() {
	yield put(updateArrearCollectionRevertResponseChanged());
}

export function* onArrearCollectionRevert() {
	yield takeLatest(
		arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_START,
		revertArrearCollectionList,
	);
}

export function* onArrearCollectionRevertResponseReset() {
	yield takeLatest(
		arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_RESET,
		resetArrearCollectionRevertResponse,
	);
}
/** *********************  END ARREAR COLLECTION UPDATE  ******************** */

export function* arrearCollectionSaga() {
	yield all([
		call(onGetArrearMemberLoanListDetails),
		call(onGetArrearMemberLoanListResponseReset),
		call(onArrearCollection),
		call(onArrearCollectionResponseReset),
		call(onArrearCollectionRevert),
		call(onArrearCollectionRevertResponseReset),
	]);
}
