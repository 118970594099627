const AddressExtraProofActionType = {
	UPDATE_ADDRESS_EXTRA_PROOF_START: 'update_address_extra_proof_start',
	UPDATE_ADDRESS_EXTRA_PROOF_SUCCESS: 'update_address_extra_proof_success',
	UPDATE_ADDRESS_EXTRA_PROOF_FAILURE: 'update_address_extra_proof_failure',
	UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_REST_START:
		'update_address_extra_proof_response_reset_start',
	UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_CHANGED: 'update_address_extra_proof_response_changed',
};

export default AddressExtraProofActionType;
