const NomineeDetailsActionType = {
	NOMINEE_DETAILS_ADD_CHANGED: 'nominee_details_add_changed',

	NOMINEE_DETAILS_ADD_START: 'nominee_details_add_start',
	NOMINEE_DETAILS_ADD_SUCCESS: 'nominee_details_add_success',
	NOMINEE_DETAILS_ADD_FAILURE: 'nominee_details_add_failure',
	NOMINEE_DETAILS_ADD_RESPONSE_REST_START: 'nominee_details_add_response_reset_start',
	NOMINEE_DETAILS_ADD_RESPONSE_CHANGED: 'nominee_details_add_response_changed',

	NOMINEE_DETAILS_ADDRESS_LIST_START: 'nominee_details_address_list_start',
	NOMINEE_DETAILS_ADDRESS_LIST_SUCCESS: 'nominee_details_address_list_success',
	NOMINEE_DETAILS_ADDRESS_LIST_FAILURE: 'nominee_details_address_list_failure',
	NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_REST_START:
		'nominee_details_address_list_response_reset_start',
	NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_CHANGED: 'nominee_details_address_list_response_changed',

	VERIFY_NOMINEE_AADHAR_OTP_START: 'verify_nominee_aadhar_otp_start',
	VERIFY_NOMINEE_AADHAR_OTP_SUCCESS: 'verify_nominee_aadhar_otp_success',
	VERIFY_NOMINEE_AADHAR_OTP_FAILURE: 'verify_nominee_aadhar_otp_failure',
	VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_nominee_aadhar_otp_response_reset_start',
	VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED: 'verify_nominee_aadhar_otp_response_changed',

	VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_START: 'verify_validate_nominee_aadhar_otp_start',
	VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_SUCCESS: 'verify_validate_nominee_aadhar_otp_success',
	VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_FAILURE: 'verify_validate_nominee_aadhar_otp_failure',
	VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_validate_nominee_aadhar_otp_response_reset_start',
	VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_validate_nominee_aadhar_otp_response_changed',

	FETCH_NOMINEE_BASED_RELATIONSHIP_START: 'fetch_nominee_based_relationship_start',
	FETCH_NOMINEE_BASED_RELATIONSHIP_SUCCESS: 'fetch_nominee_based_relationship_success',
	FETCH_NOMINEE_BASED_RELATIONSHIP_FAILURE: 'fetch_nominee_based_relationship_failure',
	FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_RESET_START:
		'fetch_nominee_based_relationship_response_reset_start',
	FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_CHANGED:
		'fetch_nominee_based_relationship_response_changed',
};

export default NomineeDetailsActionType;
