/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import ReconciliationForQRTypes from './reconciliationForQR.types';

/** ***************** GET QR List START *********************** */
export const getReconciliationForQRStart = (getData) => ({
	type: ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_START,
	payload: getData,
});

export const getReconciliationForQRSuccess = (successData) => ({
	type: ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_SUCCESS,
	payload: successData,
});

export const getReconciliationForQRFailure = (failureData) => ({
	type: ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_FAILURE,
	payload: failureData,
});

export const getReconciliationForQRReset = () => ({
	type: ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_RESET,
});

export const getReconciliationForQRChanged = () => ({
	type: ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_CHANGED,
});
/** ***************** GET QR List END *********************** */

/** ***************** Update QR List START *********************** */
export const updateReconciliationForQRStart = (getData) => ({
	type: ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_START,
	payload: getData,
});

export const updateReconciliationForQRSuccess = (successData) => ({
	type: ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_SUCCESS,
	payload: successData,
});

export const updateReconciliationForQRFailure = (failureData) => ({
	type: ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_FAILURE,
	payload: failureData,
});

export const updateReconciliationForQRReset = () => ({
	type: ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_RESET,
});

export const updateReconciliationForQRChanged = () => ({
	type: ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_CHANGED,
});
/** ***************** Update QR List END *********************** */
