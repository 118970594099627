import DashboardTypes from './Dashboard.types';

/** ***************** GET DASHBOARD DETAILS START *********************** */
export const getDashboardDetailsStart = (getData) => ({
	type: DashboardTypes.GET_DASHBOARD_DETAILS_START,
	payload: getData,
});

export const getDashboardDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_DASHBOARD_DETAILS_SUCCESS,
	payload: successData,
});

export const getDashboardDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_DASHBOARD_DETAILS_FAILURE,
	payload: failureData,
});

export const getDashboardDetailsResponseResetStart = () => ({
	type: DashboardTypes.GET_DASHBOARD_DETAILS_RESPONSE_RESET,
});

export const getDashboardDetailsResponseChanged = () => ({
	type: DashboardTypes.GET_DASHBOARD_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET DASHBOARD DETAILS END *********************** */

/** ***************** GET LOAN DETAILS START *********************** */
export const getLoanDetailsStart = (getData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_START,
	payload: getData,
});

export const getLoanDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_SUCCESS,
	payload: successData,
});

export const getLoanDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_FAILURE,
	payload: failureData,
});

export const getLoanDetailsResponseResetStart = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_RESPONSE_RESET,
});

export const getLoanDetailsResponseChanged = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET LOAN DETAILS END *********************** */

/** ***************** GET LOAN INDEX DETAILS START *********************** */
export const getLoanIndexDetailsStart = (getData) => ({
	type: DashboardTypes.GET_LOAN_INDEX_DETAILS_START,
	payload: getData,
});

export const getLoanIndexDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_LOAN_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const getLoanIndexDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_LOAN_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const getLoanIndexDetailsResponseResetStart = () => ({
	type: DashboardTypes.GET_LOAN_INDEX_DETAILS_RESPONSE_RESET,
});

export const getLoanIndexDetailsResponseChanged = () => ({
	type: DashboardTypes.GET_LOAN_INDEX_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET LOAN INDEX DETAILS END *********************** */

/** ***************** COMPANY EOD STATUS START *********************** */
export const companyEodStatusStart = (getData) => ({
	type: DashboardTypes.COMPANY_EOD_STATUS_START,
	payload: getData,
});

export const companyEodStatusSuccess = (successData) => ({
	type: DashboardTypes.COMPANY_EOD_STATUS_SUCCESS,
	payload: successData,
});

export const companyEodStatusFailure = (failureData) => ({
	type: DashboardTypes.COMPANY_EOD_STATUS_FAILURE,
	payload: failureData,
});

export const companyEodStatusResponseResetStart = () => ({
	type: DashboardTypes.COMPANY_EOD_STATUS_RESPONSE_RESET,
});

export const companyEodStatusResponseChanged = () => ({
	type: DashboardTypes.COMPANY_EOD_STATUS_RESPONSE_CHANGED,
});
/** ***************** COMPANY EOD STATUS END *********************** */

/** ***************** GET WROKFLOW DASHBOARD DETAILS START *********************** */
export const getWorkflowDashboardDetailsStart = (getData) => ({
	type: DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_START,
	payload: getData,
});

export const getWorkflowDashboardDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_SUCCESS,
	payload: successData,
});

export const getWorkflowDashboardDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_FAILURE,
	payload: failureData,
});

export const getWorkflowDashboardDetailsResponseResetStart = () => ({
	type: DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_RESET,
});

export const getWorkflowDashboardDetailsResponseChanged = () => ({
	type: DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET WROKFLOW DASHBOARD DETAILS END *********************** */

/** ***************** GET TODAY GOLG PRICE START *********************** */
export const getTodayGoldPriceStart = (getData) => ({
	type: DashboardTypes.GET_TODAY_GOLD_PRICE_START,
	payload: getData,
});

export const getTodayGoldPriceSuccess = (successData) => ({
	type: DashboardTypes.GETTODAY_GOLD_PRICE_SUCCESS,
	payload: successData,
});

export const getTodayGoldPriceFailure = (failureData) => ({
	type: DashboardTypes.GET_TODAY_GOLD_PRICE_FAILURE,
	payload: failureData,
});

export const getTodayGoldPriceResponseResetStart = () => ({
	type: DashboardTypes.GET_TODAY_GOLD_PRICE_RESPONSE_RESET,
});

export const getTodayGoldPriceResponseChanged = () => ({
	type: DashboardTypes.GET_TODAY_GOLD_PRICE_RESPONSE_CHANGED,
});
/** ***************** GET TODAY GOLG PRICE END *********************** */

/** ***************** GET LOAN DETAILS EXPOTR START *********************** */
export const getLoanDetailsExportStart = (getData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_START,
	payload: getData,
});

export const getLoanDetailsExportSuccess = (successData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_SUCCESS,
	payload: successData,
});

export const getLoanDetailsExportFailure = (failureData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_FAILURE,
	payload: failureData,
});

export const getLoanDetailsExportResponseResetStart = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_RESPONSE_RESET,
});

export const getLoanDetailsExportResponseChanged = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_RESPONSE_CHANGED,
});

export const getLoanDetailsExportJsonStart = (getData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_START,
	payload: getData,
});

export const getLoanDetailsExportJsonSuccess = (successData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_SUCCESS,
	payload: successData,
});

export const getLoanDetailsExportJsonFailure = (failureData) => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_FAILURE,
	payload: failureData,
});

export const getLoanDetailsExportJsonReset = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_RESET,
});

export const getLoanDetailsExportJsonChanged = () => ({
	type: DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_CHANGED,
});
/** ***************** GET LOAN DETAILS EXPOTR END *********************** */

/** ***************** UPDATE TODAY GOLG PRICE START *********************** */
export const updateTodayGoldPriceStart = (getData) => ({
	type: DashboardTypes.UPDATE_TODAY_GOLD_PRICE_START,
	payload: getData,
});

export const updateTodayGoldPriceSuccess = (successData) => ({
	type: DashboardTypes.UPDATE_TODAY_GOLD_PRICE_SUCCESS,
	payload: successData,
});

export const updateTodayGoldPriceFailure = (failureData) => ({
	type: DashboardTypes.UPDATE_TODAY_GOLD_PRICE_FAILURE,
	payload: failureData,
});

export const updateTodayGoldPriceResponseResetStart = () => ({
	type: DashboardTypes.UPDATE_TODAY_GOLD_PRICE_RESPONSE_RESET,
});

export const updateTodayGoldPriceResponseChanged = () => ({
	type: DashboardTypes.UPDATE_TODAY_GOLD_PRICE_RESPONSE_CHANGED,
});
/** ***************** UPDATE TODAY GOLG PRICE END *********************** */

/** ***************** EOD Status PDF START *********************** */
export const eodStatusPdfStart = (getData) => ({
	type: DashboardTypes.EOD_STATUS_PDF_START,
	payload: getData,
});

export const eodStatusPdfSuccess = (successData) => ({
	type: DashboardTypes.EOD_STATUS_PDF_SUCCESS,
	payload: successData,
});

export const eodStatusPdfFailure = (failureData) => ({
	type: DashboardTypes.EOD_STATUS_PDF_FAILURE,
	payload: failureData,
});

export const eodStatusPdfResponseResetStart = () => ({
	type: DashboardTypes.EOD_STATUS_PDF_RESPONSE_RESET,
});

export const eodStatusPdfResponseChanged = () => ({
	type: DashboardTypes.EOD_STATUS_PDF_RESPONSE_CHANGED,
});
/** *****************  EOD Status PDF END *********************** */


/** ***************** REJECT MEMBER START *********************** */
export const rejectMemberStart = (getData) => ({
	type: DashboardTypes.REJECT_MEMBER_LIST_START,
	payload: getData,
});

export const rejectMemberSuccess = (successData) => ({
	type: DashboardTypes.REJECT_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const rejectMemberFailure = (failureData) => ({
	type: DashboardTypes.REJECT_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const rejectMemberReset = () => ({
	type: DashboardTypes.REJECT_MEMBER_LIST_RESET,
});

export const rejectMemberChanged = () => ({
	type: DashboardTypes.REJECT_MEMBER_LIST_CHANGED,
});
/** *****************  REJECT MEMBER END *********************** */


/** ***************** DASHBOARD PARTICULAR DETAILS START *********************** */
export const getDashboardParticularDetailsStart = (getData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_START,
	payload: getData,
});

export const getDashboardParticularDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_SUCCESS,
	payload: successData,
});

export const getDashboardParticularDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_FAILURE,
	payload: failureData,
});

export const getDashboardParticularDetailsReset = () => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_RESET,
});

export const getDashboardParticularDetailsChanged = () => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_CHANGED,
});
/** *****************  DASHBOARD PARTICULAR DETAILS END *********************** */
/** ***************** DASHBOARD PARTICULAR DEMAND DETAILS START *********************** */
export const getDashboardParticularDemandDetailsStart = (getData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_START,
	payload: getData,
});

export const getDashboardParticularDemandDetailsSuccess = (successData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_SUCCESS,
	payload: successData,
});

export const getDashboardParticularDemandDetailsFailure = (failureData) => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_FAILURE,
	payload: failureData,
});

export const getDashboardParticularDemandDetailsReset = () => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_RESET,
});

export const getDashboardParticularDemandDetailsChanged = () => ({
	type: DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_CHANGED,
});
/** *****************  DASHBOARD PARTICULAR DEMAND DETAILS END *********************** */


/** ***************** GET DASHBOARD COLLECTION OFFICER START *********************** */
export const getDashboardStaffCollectionStart = (getData) => ({
	type: DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_START,
	payload: getData,
});

export const getDashboardStaffCollectionSuccess = (successData) => ({
	type: DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_SUCCESS,
	payload: successData,
});

export const getDashboardStaffCollectionFailure = (failureData) => ({
	type: DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_FAILURE,
	payload: failureData,
});

export const getDashboardStaffCollectionReset = () => ({
	type: DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_RESET,
});

export const getDashboardStaffCollectionChanged = () => ({
	type: DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_CHANGED,
});
/** *****************  GET DASHBOARD COLLECTION OFFICER END *********************** */


/** ***************** GET STAFF LOCATION START *********************** */
export const getStaffLocationStart = (getData) => ({
	type: DashboardTypes.GET_STAFF_LOCATION_START,
	payload: getData,
});

export const getStaffLocationSuccess = (successData) => ({
	type: DashboardTypes.GET_STAFF_LOCATION_SUCCESS,
	payload: successData,
});

export const getStaffLocationFailure = (failureData) => ({
	type: DashboardTypes.GET_STAFF_LOCATION_FAILURE,
	payload: failureData,
});

export const getStaffLocationReset = () => ({
	type: DashboardTypes.GET_STAFF_LOCATION_RESET,
});

export const getStaffLocationChanged = () => ({
	type: DashboardTypes.GET_STAFF_LOCATION_CHANGED,
});
/** *****************  GET STAFF LOCATION END *********************** */


/** ***************** REJECT NOTES MEMBER START *********************** */
export const rejectNotesMemberStart = (getData) => ({
	type: DashboardTypes.REJECT_NOTES_MEMBER_LIST_START,
	payload: getData,
});

export const rejectNotesMemberSuccess = (successData) => ({
	type: DashboardTypes.REJECT_NOTES_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const rejectNotesMemberFailure = (failureData) => ({
	type: DashboardTypes.REJECT_NOTES_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const rejectNotesMemberReset = () => ({
	type: DashboardTypes.REJECT_NOTES_MEMBER_LIST_RESET,
});

export const rejectNotesMemberChanged = () => ({
	type: DashboardTypes.REJECT_NOTES_MEMBER_LIST_CHANGED,
});
/** *****************  REJECT NOTES MEMBER END *********************** */


// DEMAND SECTION
/** ***************** GET PARTICULAR DATE DEMAND START *********************** */
export const getParticularDateDemandStart = (getData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_START,
	payload: getData,
});

export const getParticularDateDemandSuccess = (successData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_SUCCESS,
	payload: successData,
});

export const getParticularDateDemandFailure = (failureData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_FAILURE,
	payload: failureData,
});

export const getParticularDateDemandReset = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_RESET,
});

export const getParticularDateDemandChanged = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_CHANGED,
});
/** *****************  GET PARTICULAR DATE DEMAND END *********************** */

/** ***************** GET PARTICULAR DATE DEMAND INFO START *********************** */
export const getParticularDateDemandInfoStart = (getData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_START,
	payload: getData,
});

export const getParticularDateDemandInfoSuccess = (successData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_SUCCESS,
	payload: successData,
});

export const getParticularDateDemandInfoFailure = (failureData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_FAILURE,
	payload: failureData,
});

export const getParticularDateDemandInfoReset = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_RESET,
});

export const getParticularDateDemandInfoChanged = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_CHANGED,
});
/** *****************  GET PARTICULAR DATE DEMAND INFO END *********************** */


/** ***************** GET PARTICULAR DATE MEMBER ABSENT START *********************** */
export const getParticularDateMemberAbsentStart = (getData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_START,
	payload: getData,
});

export const getParticularDateMemberAbsentSuccess = (successData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_SUCCESS,
	payload: successData,
});

export const getParticularDateMemberAbsentFailure = (failureData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_FAILURE,
	payload: failureData,
});

export const getParticularDateMemberAbsentReset = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_RESET,
});

export const getParticularDateMemberAbsentChanged = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_CHANGED,
});
/** *****************  GET PARTICULAR DATE MEMBER ABSENT END *********************** */

// COLLECTION SECTION

/** ***************** GET PARTICULAR DATE COLLECTION START *********************** */
export const getParticularDateCollectionStart = (getData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_START,
	payload: getData,
});

export const getParticularDateCollectionSuccess = (successData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_SUCCESS,
	payload: successData,
});

export const getParticularDateCollectionFailure = (failureData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_FAILURE,
	payload: failureData,
});

export const getParticularDateCollectionReset = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_RESET,
});

export const getParticularDateCollectionChanged = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_CHANGED,
});
/** *****************  GET PARTICULAR DATE COLLECTION END *********************** */


/** ***************** GET PARTICULAR DATE COLLECTION INFO START *********************** */
export const getParticularDateCollectionInfoStart = (getData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_START,
	payload: getData,
});

export const getParticularDateCollectionInfoSuccess = (successData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_SUCCESS,
	payload: successData,
});

export const getParticularDateCollectionInfoFailure = (failureData) => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_FAILURE,
	payload: failureData,
});

export const getParticularDateCollectionInfoReset = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_RESET,
});

export const getParticularDateCollectionInfoChanged = () => ({
	type: DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_CHANGED,
});
/** *****************  GET PARTICULAR DATE COLLECTION INFO END *********************** */

/** ***************** GET WEEK COLLECTION START *********************** */
export const getWeekCollectionStart = (getData) => ({
	type: DashboardTypes.GET_WEEK_COLLECTION_START,
	payload: getData,
});

export const getWeekCollectionSuccess = (successData) => ({
	type: DashboardTypes.GET_WEEK_COLLECTION_SUCCESS,
	payload: successData,
});

export const getWeekCollectionFailure = (failureData) => ({
	type: DashboardTypes.GET_WEEK_COLLECTION_FAILURE,
	payload: failureData,
});

export const getWeekCollectionReset = () => ({
	type: DashboardTypes.GET_WEEK_COLLECTION_RESET,
});

export const getWeekCollectionChanged = () => ({
	type: DashboardTypes.GET_WEEK_COLLECTION_CHANGED,
});
/** *****************  GET WEEK COLLECTION END *********************** */

/** ***************** GET WEEK YET TO COLLECTION START *********************** */
export const getWeekYTCollectionStart = (getData) => ({
	type: DashboardTypes.GET_WEEK_YT_COLLECTION_START,
	payload: getData,
});

export const getWeekYTCollectionSuccess = (successData) => ({
	type: DashboardTypes.GET_WEEK_YT_COLLECTION_SUCCESS,
	payload: successData,
});

export const getWeekYTCollectionFailure = (failureData) => ({
	type: DashboardTypes.GET_WEEK_YT_COLLECTION_FAILURE,
	payload: failureData,
});

export const getWeekYTCollectionReset = () => ({
	type: DashboardTypes.GET_WEEK_YT_COLLECTION_RESET,
});

export const getWeekYTCollectionChanged = () => ({
	type: DashboardTypes.GET_WEEK_YT_COLLECTION_CHANGED,
});
/** *****************  GET WEEK YET TO COLLECTION END *********************** */

/** ***************** GET SMS USED LIST START *********************** */
export const getSMSUsedListStart = (getData) => ({
	type: DashboardTypes.SMS_USED_LIST_START,
	payload: getData,
});

export const getSMSUsedListSuccess = (successData) => ({
	type: DashboardTypes.SMS_USED_LIST_SUCCESS,
	payload: successData,
});

export const getSMSUsedListFailure = (failureData) => ({
	type: DashboardTypes.SMS_USED_LIST_FAILURE,
	payload: failureData,
});

export const getSMSUsedListReset = () => ({
	type: DashboardTypes.SMS_USED_LIST_RESET,
});

export const getSMSUsedListChanged = () => ({
	type: DashboardTypes.SMS_USED_LIST_CHANGED,
});
/** *****************  GET SMS USED LIST END *********************** */


/** ***************** GET KYC USED LIST START *********************** */
export const getKYCUsedListStart = (getData) => ({
	type: DashboardTypes.KYC_USED_LIST_START,
	payload: getData,
});

export const getKYCUsedListSuccess = (successData) => ({
	type: DashboardTypes.KYC_USED_LIST_SUCCESS,
	payload: successData,
});

export const getKYCUsedListFailure = (failureData) => ({
	type: DashboardTypes.KYC_USED_LIST_FAILURE,
	payload: failureData,
});

export const getKYCUsedListReset = () => ({
	type: DashboardTypes.KYC_USED_LIST_RESET,
});

export const getKYCUsedListChanged = () => ({
	type: DashboardTypes.KYC_USED_LIST_CHANGED,
});
/** *****************  GET KYC USED LIST END *********************** */

/** ***************** GET ARREAR COLLECTION LIST START *********************** */
export const getArrearCollectionListStart = (getData) => ({
	type: DashboardTypes.ARREAR_COLLECTION_LIST_START,
	payload: getData,
});

export const getArrearCollectionListSuccess = (successData) => ({
	type: DashboardTypes.ARREAR_COLLECTION_LIST_SUCCESS,
	payload: successData,
});

export const getArrearCollectionListFailure = (failureData) => ({
	type: DashboardTypes.ARREAR_COLLECTION_LIST_FAILURE,
	payload: failureData,
});

export const getArrearCollectionListReset = () => ({
	type: DashboardTypes.ARREAR_COLLECTION_LIST_RESET,
});

export const getArrearCollectionListChanged = () => ({
	type: DashboardTypes.ARREAR_COLLECTION_LIST_CHANGED,
});
/** *****************  GET ARREAR COLLECTION LIST END *********************** */

// EXPORT SECTION
// DEMAND COLLECTION EXPORT
/** ***************** DEMAND COLLECTION EXPORT START *********************** */
export const getDemandCollectionExportStart = (getData) => ({
	type: DashboardTypes.DEMAND_COLLECTION_EXPORT_START,
	payload: getData,
});

export const getDemandCollectionExportSuccess = (successData) => ({
	type: DashboardTypes.DEMAND_COLLECTION_EXPORT_SUCCESS,
	payload: successData,
});

export const getDemandCollectionExportFailure = (failureData) => ({
	type: DashboardTypes.DEMAND_COLLECTION_EXPORT_FAILURE,
	payload: failureData,
});

export const getDemandCollectionExportReset = () => ({
	type: DashboardTypes.DEMAND_COLLECTION_EXPORT_RESET,
});

export const getDemandCollectionExportChanged = () => ({
	type: DashboardTypes.DEMAND_COLLECTION_EXPORT_CHANGED,
});
/** *****************  DEMAND COLLECTION EXPORT END *********************** */
/** ***************** DASHBOARD EOD EXPORT START *********************** */
export const getDashboardEodExportStart = (getData) => ({
	type: DashboardTypes.DASHBOARD_EOD_EXPORT_START,
	payload: getData,
});

export const getDashboardEodExportSuccess = (successData) => ({
	type: DashboardTypes.DASHBOARD_EOD_EXPORT_SUCCESS,
	payload: successData,
});

export const getDashboardEodExportFailure = (failureData) => ({
	type: DashboardTypes.DASHBOARD_EOD_EXPORT_FAILURE,
	payload: failureData,
});

export const getDashboardEodExportReset = () => ({
	type: DashboardTypes.DASHBOARD_EOD_EXPORT_RESET,
});

export const getDashboardEodExportChanged = () => ({
	type: DashboardTypes.DASHBOARD_EOD_EXPORT_CHANGED,
});
/** *****************  DASHBOARD EOD EXPORT END *********************** */

/** ***************** DASHBOARD STAFF MAP COLLECTION START *********************** */
export const getDashboardStaffMapCollectionStart = (getData) => ({
	type: DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_START,
	payload: getData,
});

export const getDashboardStaffMapCollectionSuccess = (successData) => ({
	type: DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_SUCCESS,
	payload: successData,
});

export const getDashboardStaffMapCollectionFailure = (failureData) => ({
	type: DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_FAILURE,
	payload: failureData,
});

export const getDashboardStaffMapCollectionReset = () => ({
	type: DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_RESET,
});

export const getDashboardStaffMapCollectionChanged = () => ({
	type: DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_CHANGED,
});
/** *****************  DASHBOARD STAFF MAP COLLECTION END *********************** */

/** ***************** DASHBOARD COLLECTION INFO START *********************** */
export const getDashboardCollectionNoteInfoStart = (getData) => ({
	type: DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_START,
	payload: getData,
});

export const getDashboardCollectionNoteInfoSuccess = (successData) => ({
	type: DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_SUCCESS,
	payload: successData,
});

export const getDashboardCollectionNoteInfoFailure = (failureData) => ({
	type: DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_FAILURE,
	payload: failureData,
});

export const getDashboardCollectionNoteInfoReset = () => ({
	type: DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_RESET,
});

export const getDashboardCollectionNoteInfoChanged = () => ({
	type: DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_CHANGED,
});
/** *****************  DASHBOARD COLLECTION INFO END *********************** */
