const CloseLoanActionTypes = {
	GET_CLOSE_LOAN_LIST_START: 'get_close_loan_start',
	GET_CLOSE_LOAN_LIST_SUCCESS: 'get_close_loan_success',
	GET_CLOSE_LOAN_LIST_FAILURE: 'get_close_loan_failure',
	GET_CLOSE_LOAN_LIST_RESPONSE_RESET: 'get_close_loan_response_reset',
	GET_CLOSE_LOAN_LIST_RESPONSE_CHANGED: 'get_close_loan_response_changed',

	UPDATE_CLOSE_LOAN_LIST_START: 'update_close_loan_start',
	UPDATE_CLOSE_LOAN_LIST_SUCCESS: 'update_close_loan_success',
	UPDATE_CLOSE_LOAN_LIST_FAILURE: 'update_close_loan_failure',
	UPDATE_CLOSE_LOAN_LIST_RESPONSE_RESET: 'update_close_loan_response_reset',
	UPDATE_CLOSE_LOAN_LIST_RESPONSE_CHANGED: 'update_close_loan_response_changed',

	REVERT_CLOSE_LOAN_LIST_START: 'REVERT_CLOSE_LOAN_LIST_START',
	REVERT_CLOSE_LOAN_LIST_SUCCESS: 'REVERT_CLOSE_LOAN_LIST_SUCCESS',
	REVERT_CLOSE_LOAN_LIST_FAILURE: 'REVERT_CLOSE_LOAN_LIST_FAILURE',
	REVERT_CLOSE_LOAN_LIST_RESPONSE_RESET: 'REVERT_CLOSE_LOAN_LIST_RESPONSE_RESET',
	REVERT_CLOSE_LOAN_LIST_RESPONSE_CHANGED: 'REVERT_CLOSE_LOAN_LIST_RESPONSE_CHANGED',
};
export default CloseLoanActionTypes;
