/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import RangeLoanProposalActionTypes from './range-loan-propsal.types';

/** ***************** GET RANGE LOAN PROPOSAL LIST START *********************** */
export const getRangeLoanPropsalListStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_START,
	payload: getData,
});

export const getRangeLoanPropsalListSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_SUCCESS,
	payload: successData,
});

export const getRangeLoanPropsalListFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_FAILURE,
	payload: failureData,
});

export const getRangeLoanPropsalListResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
});

export const getRangeLoanPropsalListResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED,
});

/** ***************** GET RANGE LOAN PROPOSAL LIST END *********************** */

/** ***************** GET RANGE PROPOSAL CENTER LIST START *********************** */
export const getRangePropsalCenterListStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_START,
	payload: getData,
});

export const getRangePropsalCenterListSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_SUCCESS,
	payload: successData,
});

export const getRangePropsalCenterListFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_FAILURE,
	payload: failureData,
});

export const getRangePropsalCenterListResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_RESET,
});

export const getRangePropsalCenterListResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_CHANGED,
});

/** ***************** GET RANGE PROPOSAL CENTER LIST END *********************** */

/** ***************** GET CENTER MEMBER LIST START *********************** */
export const getCenterMemberListStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_START,
	payload: getData,
});

export const getCenterMemberListSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const getCenterMemberListFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const getCenterMemberListResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_RESPONSE_RESET,
});

export const getCenterMemberListResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_RESPONSE_CHANGED,
});

/** ***************** GET CENTER MEMBER LIST END *********************** */

/** ***************** GET RANGE PRODUCT LIST START *********************** */
export const getRangeProductListStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_START,
	payload: getData,
});

export const getRangeProductListSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_SUCCESS,
	payload: successData,
});

export const getRangeProductListFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_FAILURE,
	payload: failureData,
});

export const getRangeProductListResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_RESPONSE_RESET,
});

export const getRangeProductListResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_RESPONSE_CHANGED,
});

/** ***************** GET RANGE PRODUCT LIST END *********************** */

/** ***************** ADD RANGE LOAN PROPOSAL START *********************** */
export const addRangeLoanProposalStart = (getData) => ({
	type: RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const addRangeLoanProposalSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addRangeLoanProposalFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addRangeLoanProposalResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const addRangeLoanProposalResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** ADD RANGE LOAN PROPOSAL END *********************** */

/** ***************** UPDATE RANGE LOAN PROPOSAL START *********************** */
export const updateRangeLoanProposalStart = (getData) => ({
	type: RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const updateRangeLoanProposalSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const updateRangeLoanProposalFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const updateRangeLoanProposalResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const updateRangeLoanProposalResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** UPDATE RANGE LOAN PROPOSAL END *********************** */

/** ***************** GET CENTER MEMBER LEAD LIST START *********************** */
export const getCenterMemberLeadListStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_START,
	payload: getData,
});

export const getCenterMemberLeadListSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_SUCCESS,
	payload: successData,
});

export const getCenterMemberLeadListFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_FAILURE,
	payload: failureData,
});

export const getCenterMemberLeadListResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_RESET,
});

export const getCenterMemberLeadListResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_CHANGED,
});

/** ***************** GET CENTER MEMBER LEAD LIST END *********************** */

/** ***************** GET PARTICULAR LOAN INDEX DETAILS START *********************** */
export const getParticularLoanIndexDetailsStart = (getData) => ({
	type: RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_START,
	payload: getData,
});

export const getParticularLoanIndexDetailsSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const getParticularLoanIndexDetailsFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const getParticularLoanIndexDetailsResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
});

export const getParticularLoanIndexDetailsResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** GET PARTICULAR LOAN INDEX DETAILS END *********************** */

/** ***************** APPROVE LOAN INDEX START *********************** */
export const approveRangeLoanIndexStart = (getData) => ({
	type: RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const approveRangeLoanIndexSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const approveRangeLoanIndexFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const approveRangeLoanIndexResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const approveRangeLoanIndexResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** APPROVE LOAN INDEX END *********************** */

/** ***************** REVERT APPROVED LOAN INDEX START *********************** */
export const revertApproveRangeLoanIndexStart = (getData) => ({
	type: RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const revertApproveRangeLoanIndexSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const revertApproveRangeLoanIndexFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const revertApproveRangeLoanIndexResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const revertApproveRangeLoanIndexResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** REVERT APPROVED LOAN INDEX END *********************** */

/** ***************** REJECT LOAN INDEX START *********************** */
export const rejectRangeLoanIndexStart = (getData) => ({
	type: RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const rejectRangeLoanIndexSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const rejectRangeLoanIndexFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const rejectRangeLoanIndexResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const rejectRangeLoanIndexResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** REJECT LOAN INDEX END *********************** */

/** ***************** DELETE RANGE LOAN PROPOSAL START *********************** */
export const deleteRangeLoanProposalStart = (getData) => ({
	type: RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const deleteRangeLoanProposalSuccess = (successData) => ({
	type: RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const deleteRangeLoanProposalFailure = (failureData) => ({
	type: RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const deleteRangeLoanProposalResponseResetStart = () => ({
	type: RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const deleteRangeLoanProposalResponseChanged = () => ({
	type: RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** DELETE RANGE LOAN PROPOSAL END *********************** */
