/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const creditCheckMemberAdd = (verifyData) => {
	const data = {
		CreateCreditCheckMemberToken: verifyData,
	};
	const url = `${API_URL}addCreditCheckMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getCreditCheck = (memberData) => {
	const data = {
		CheckMemberHighMarkToken: memberData,
	};
	const url = `${API_URL}callHighMark`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEquifaxCheck = (memberData) => {
	const data = {
		AppUploadEquifaxToken: memberData,
	};
	const url = `${API_URL}UploadEquifaxDetailsApp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const callVerifyMemberAPI = (memberData) => {
	const data = {
		CheckMemberDetailsToken: memberData,
	};
	const url = `${API_URL}CheckMemberDetailsExistApp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const otpVerificationCodeSend = (verifyData) => {
	const data = {
		HigmarkSendOtpVerify: verifyData,
	};
	const url = `${API_URL}sendVerificationOtp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyCreditCheckAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyValidateCreditCheckAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharValidateOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const callVerifyMemberDetailsAPI = (memberData) => {
	const data = {
		RecheckMemberCreditToken: memberData,
	};
	const url = `${API_URL}crifRecheckMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
