/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import RequestReportActionTypes from './requestReport.types';

import {
	requestReportDetailsFetch,
	requestReportDetailsAdd,
	requestReportDetailsDelete,
} from './requestReport.service';

import {
	fetchRequestReportDetailsSuccess,
	fetchRequestReportDetailsFailure,
	fetchRequestReportDetailsResponseChanged,
	addRequestReportDetailsSuccess,
	addRequestReportDetailsFailure,
	addRequestReportDetailsResponseChanged,
	deleteRequestReportSuccess,
	deleteRequestReportFailure,
	deleteRequestReportChanged,
} from './requestReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH REQUEST REPORT DETAILS  ******************** */
export function* fetchRequestReportDetails({ payload }) {
	try {
		const key = 'FetchRequestedReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			Limit: payload.Limit,
			RequestedBy: payload.instanceStaffID,
			ToDate: payload.toDate,
			BranchID: '0',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(requestReportDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchRequestReportDetailsFailure(responseData.data));
		} else {
			yield put(fetchRequestReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchRequestReportDetailsFailure(error));
	}
}

export function* resetFetchRequestReportDetailsResponse() {
	yield put(fetchRequestReportDetailsResponseChanged());
}

export function* onFetchRequestReportDetails() {
	yield takeLatest(
		RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_START,
		fetchRequestReportDetails,
	);
}

export function* onFetchRequestReportDetailsResponseReset() {
	yield takeLatest(
		RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_RESPONSE_RESET,
		resetFetchRequestReportDetailsResponse,
	);
}
/** *********************  END FETCH REQUEST REPORT DETAILS ******************** */

/** *********************  ADD FETCH REQUEST REPORT DETAILS  ******************** */
export function* addRequestReportDetails({ payload }) {
	try {
		const key = 'AddRequestedReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			RequestedBy: payload.requestedBy,
			RequestReportPage: payload.requestReportPage,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			MemberID: payload.memberID,
			DateType: payload.DateType,
			CollectionMode: payload.CollectionMode,
			FunderID: payload.funderID,
			IsBusiness: payload.isBusiness,
			SearchLog: payload.filterData,
			ReportMode: payload.ReportMode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(requestReportDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addRequestReportDetailsFailure(responseData.data));
		} else {
			yield put(addRequestReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addRequestReportDetailsFailure(error));
	}
}

export function* resetAddRequestReportDetailsResponse() {
	yield put(addRequestReportDetailsResponseChanged());
}

export function* onAddRequestReportDetails() {
	yield takeLatest(
		RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_START,
		addRequestReportDetails,
	);
}

export function* onAddRequestReportDetailsResponseReset() {
	yield takeLatest(
		RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_RESPONSE_RESET,
		resetAddRequestReportDetailsResponse,
	);
}
/** *********************  END FETCH REQUEST REPORT DETAILS ******************** */

/** *********************  ADD FETCH REQUEST REPORT DETAILS  ******************** */
export function* deleteRequestReportCallStart({ payload }) {
	try {
		const key = 'DeleteRequestedReportKey';
		const jsonData = {
			JobHandlerID: payload.jobHandlerID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(requestReportDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteRequestReportFailure(responseData.data));
		} else {
			yield put(deleteRequestReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteRequestReportFailure(error));
	}
}

export function* deleteRequestReportCallReset() {
	yield put(deleteRequestReportChanged());
}

export function* onDeleteRequestReport() {
	yield takeLatest(
		RequestReportActionTypes.DELETE_REQUEST_REPORT_START,
		deleteRequestReportCallStart,
	);
}

export function* onDeleteRequestReportResponseReset() {
	yield takeLatest(
		RequestReportActionTypes.DELETE_REQUEST_REPORT_RESET,
		deleteRequestReportCallReset,
	);
}
/** *********************  END FETCH REQUEST REPORT DETAILS ******************** */

export function* requestReportSaga() {
	yield all([
		call(onFetchRequestReportDetails),
		call(onFetchRequestReportDetailsResponseReset),
		call(onAddRequestReportDetails),
		call(onAddRequestReportDetailsResponseReset),
		call(onDeleteRequestReport),
		call(onDeleteRequestReportResponseReset),
	]);
}
