/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchActionTypes from './branch.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addBranchDetailsResponse: null,
	updateBranchDetailsResponse: null,
	getBranchListResponse: null,
	getBranchListArr: [],
	fetchBranchDetailsResponse: null,
	fetchBranchDetailsArr: [],
	deleteBranchDetailsResponse: null,
	fetchSubBranchNextUniqueIDResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchActionTypes.ADD_BRANCH_DETAILS_SUCCESS:
			return { ...state, addBranchDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_DETAILS_FAILURE:
			return { ...state, addBranchDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBranchDetailsResponse: null };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_SUCCESS:
			return { ...state, updateBranchDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_FAILURE:
			return { ...state, updateBranchDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBranchDetailsResponse: null };

		case BranchActionTypes.GET_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getBranchListResponse: action.payload,
				getBranchListArr: docodeResponse(action.payload, 'GetBranchListKey'),
			};

		case BranchActionTypes.GET_BRANCH_LIST_FAILURE:
			return { ...state, getBranchListResponse: action.payload };

		case BranchActionTypes.GET_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchListResponse: null };

		case BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS:
			return {
				...state,
				fetchBranchDetailsResponse: action.payload,
				fetchBranchDetailsArr: docodeResponse(action.payload, 'FetchBranchDetailsKey'),
			};

		case BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE:
			return { ...state, fetchBranchDetailsResponse: action.payload };

		case BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchBranchDetailsResponse: null, fetchBranchDetailsArr: [] };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_SUCCESS:
			return { ...state, deleteBranchDetailsResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_FAILURE:
			return { ...state, deleteBranchDetailsResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteBranchDetailsResponse: null };

		case BranchActionTypes.FETCH_SUB_BRANCH_NEXT_UID_SUCCESS:
			return {
				...state,
				fetchSubBranchNextUniqueIDResponse: action.payload,
			};

		case BranchActionTypes.FETCH_SUB_BRANCH_NEXT_UID_FAILURE:
			return { ...state, fetchSubBranchNextUniqueIDResponse: action.payload };

		case BranchActionTypes.FETCH_SUB_BRANCH_NEXT_UID_RESPONSE_CHANGED:
			return {
				...state,
				fetchSubBranchNextUniqueIDResponse: null,
			};

		default:
			return state;
	}
};
