/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import MemberMandatoryAccessActionTypes from './memberMandatoryAccess.types';

/** ***************** GET Member Mandatory Access DETAILS START *********************** */
export const fetchMemberMandatoryAccessStart = (getData) => ({
	type: MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_START,
	payload: getData,
});

export const fetchMemberMandatoryAccessSuccess = (successData) => ({
	type: MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_SUCCESS,
	payload: successData,
});

export const fetchMemberMandatoryAccessFailure = (failureData) => ({
	type: MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_FAILURE,
	payload: failureData,
});

export const fetchMemberMandatoryAccessResponseReset = () => ({
	type: MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET,
});

export const fetchMemberMandatoryAccessResponseChanged = () => ({
	type: MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED,
});

/** ***************** GET Member Mandatory Access DETAILS END *********************** */


/** ***************** UPDATE Member Mandatory Access DETAILS START *********************** */
export const updateMemberMandatoryAccessStart = (getData) => ({
	type: MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_START,
	payload: getData,
});

export const updateMemberMandatoryAccessSuccess = (successData) => ({
	type: MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_SUCCESS,
	payload: successData,
});

export const updateMemberMandatoryAccessFailure = (failureData) => ({
	type: MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_FAILURE,
	payload: failureData,
});

export const updateMemberMandatoryAccessResponseReset = () => ({
	type: MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET,
});

export const updateMemberMandatoryAccessResponseChanged = () => ({
	type: MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_CHANGED,
});

/** ***************** UPDATE Member Mandatory Access DETAILS END *********************** */
