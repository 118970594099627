const AddMemberActionType = {
	UPDATE_STAGE_COMPLETED_CODE: 'update_stage_completed_code',

	GET_MEMBER_DETAILS_START: 'get_member_details_start',
	GET_MEMBER_DETAILS_SUCCESS: 'get_member_details_success',
	GET_MEMBER_DETAILS_FAILURE: 'get_member_details_failure',
	GET_MEMBER_DETAILS_RESPONSE_REST_START: 'get_member_details_response_reset_start',
	GET_MEMBER_DETAILS_RESPONSE_CHANGED: 'get_member_details_response_changed',

	FETCH_PARTICULAR_MEMBER_DETAILS_START: 'fetch_particular_member_details_start',
	FETCH_PARTICULAR_MEMBER_DETAILS_SUCCESS: 'fetch_particular_member_details_success',
	FETCH_PARTICULAR_MEMBER_DETAILS_FAILURE: 'fetch_particular_member_details_failure',
	FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_REST_START:
		'fetch_particular_member_details_response_reset_start',
	FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_CHANGED:
		'fetch_particular_member_details_response_changed',

	GET_MEMBER_MANDATORY_FIELDS_START: 'get_member_mandatory_fields_start',
	GET_MEMBER_MANDATORY_FIELDS_SUCCESS: 'get_member_mandatory_fields_success',
	GET_MEMBER_MANDATORY_FIELDS_FAILURE: 'get_member_mandatory_fields_failure',
	GET_MEMBER_MANDATORY_FIELDS_RESPONSE_REST_START:
		'get_member_mandatory_fields_response_reset_start',
	GET_MEMBER_MANDATORY_FIELDS_RESPONSE_CHANGED: 'get_member_mandatory_fields_response_changed',

	GET_ADD_MEMBER_NAV_LIST_START: 'get_add_member_nav_list_start',
	GET_ADD_MEMBER_NAV_LIST_SUCCESS: 'get_add_member_nav_list_success',
	GET_ADD_MEMBER_NAV_LIST_FAILURE: 'get_add_member_nav_list_failure',
	GET_ADD_MEMBER_NAV_LIST_RESET_START: 'get_add_member_nav_list_reset_start',
	GET_ADD_MEMBER_NAV_LIST_CHANGED: 'get_add_member_nav_list_changed',

	CHECK_ALL_MANDATORY_FIELDs_START: 'check_all_mandatory_fields_start',
	CHECK_ALL_MANDATORY_FIELDs_SUCCESS: 'check_all_mandatory_fields_success',
	CHECK_ALL_MANDATORY_FIELDs_FAILURE: 'check_all_mandatory_fields_failure',
	CHECK_ALL_MANDATORY_FIELDs_RESET_START: 'check_all_mandatory_fields_reset_start',
	CHECK_ALL_MANDATORY_FIELDs_CHANGED: 'check_all_mandatory_fields_changed',

	FETCH_SCREEN_MANDATORY_STATUS_START: 'fetch_screen_mandatory_status_start',
	FETCH_SCREEN_MANDATORY_STATUS_SUCCESS: 'fetch_screen_mandatory_status_success',
	FETCH_SCREEN_MANDATORY_STATUS_FAILURE: 'fetch_screen_mandatory_status_failure',
	FETCH_SCREEN_MANDATORY_STATUS_RESET_START: 'fetch_screen_mandatory_status_reset_start',
	FETCH_SCREEN_MANDATORY_STATUS_CHANGED: 'fetch_screen_mandatory_status_changed',

	UPDATE_ADD_MEMBER_NAV_LIST_ARRAY: 'update_add_member_nav_list_array',

	REJECT_MEMBER_DETAILS_START: 'reject_member_details_start',
	REJECT_MEMBER_DETAILS_SUCCESS: 'reject_member_details_success',
	REJECT_MEMBER_DETAILS_FAILURE: 'reject_member_details_failure',
	REJECT_MEMBER_DETAILS_RESPONSE_REST_START: 'reject_member_details_response_reset_start',
	REJECT_MEMBER_DETAILS_RESPONSE_CHANGED: 'reject_member_details_response_changed',

	FETCH_ADD_MEMBER_LIST_START: 'fetch_add_member_list_start',
	FETCH_ADD_MEMBER_LIST_SUCCESS: 'fetch_add_member_list_success',
	FETCH_ADD_MEMBER_LIST_FAILURE: 'fetch_add_member_list_failure',
	FETCH_ADD_MEMBER_LIST_RESPONSE_REST_START: 'fetch_add_member_list_response_reset_start',
	FETCH_ADD_MEMBER_LIST_RESPONSE_CHANGED: 'fetch_add_member_list_response_changed',

	EXPORT_MEMBER_LIST_START: 'export_member_list_start',
	EXPORT_MEMBER_LIST_SUCCESS: 'export_member_list_success',
	EXPORT_MEMBER_LIST_FAILURE: 'export_member_list_failure',
	EXPORT_MEMBER_LIST_RESPONSE_REST_START: 'export_member_list_response_reset_start',
	EXPORT_MEMBER_LIST_RESPONSE_CHANGED: 'export_member_list_response_changed',

	DELETE_MEMBER_START: 'delete_member_start',
	DELETE_MEMBER_SUCCESS: 'delete_member_success',
	DELETE_MEMBER_FAILURE: 'delete_member_failure',
	DELETE_MEMBER_RESPONSE_RESET_START: 'delete_member_response_reset_start',
	DELETE_MEMBER_RESPONSE_CHANGED: 'delete_member_response_changed',

	UPDATE_MEMBER_ACCESS_TYPE: 'update_member_access_type',
};
export default AddMemberActionType;
