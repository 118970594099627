/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DailyCollectionReportActionTypes from './dailyCollectionReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getDailyCollectionReportListResponse: null,
	getDailyCollectionReportListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DailyCollectionReportActionTypes.GET_DAILY_COLLECTION_REPORT_LIST_SUCCESS:
			return {
				...state,
				getDailyCollectionReportListResponse: action.payload,
				getDailyCollectionReportListArr: docodeResponse(
					action.payload,
					'DailyCollectionReportKey',
				),
			};

		case DailyCollectionReportActionTypes.GET_DAILY_COLLECTION_REPORT_LIST_FAILURE:
			return { ...state, getDailyCollectionReportListResponse: action.payload };

		case DailyCollectionReportActionTypes.GET_DAILY_COLLECTION_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getDailyCollectionReportListResponse: null };
		default:
			return state;
	}
};
