/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LoanActionTypes from './product-loan-proposal.types';

/** ***************** GET PRODUCT LOAN PROPOSAL START ***************** */
export const getProductLoanProposalStart = (getData) => ({
	type: LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_START,
	payload: getData,
});

export const getProductLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const getProductLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const getProductLoanProposalResponseReset = () => ({
	type: LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const getProductLoanProposalListResponseChanged = () => ({
	type: LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LOAN PROPOSAL END ************ */

/** ***************** GET PRODUCT LOAN PROPOSAL START ***************** */
export const getParticularProductLoanProposalStart = (getData) => ({
	type: LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_START,
	payload: getData,
});

export const getParticularProductLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const getParticularProductLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const getParticularProductLoanProposalResponseReset = () => ({
	type: LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const getParticularProductLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LOAN PROPOSAL END ************ */

/** ***************** GET PRODUCT LOAN PROPOSAL START ***************** */
export const getWholeGroupMemberStart = (getData) => ({
	type: LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_START,
	payload: getData,
});

export const getWholeGroupMemberSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_SUCCESS,
	payload: successData,
});

export const getWholeGroupMemberFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_FAILURE,
	payload: failureData,
});

export const getWholeGroupMemberResponseReset = () => ({
	type: LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_RESPONSE_RESET,
});

export const getWholeGroupMemberResponseChanged = () => ({
	type: LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LOAN PROPOSAL END ************ */

/** ***************** ADD PRODUCT DETAILS START *********************** */
export const addProductLoanProposalStart = (addData) => ({
	type: LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_START,
	payload: addData,
});

export const addProductLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addProductLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addProductLoanProposalResponseResetStart = () => ({
	type: LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const addProductLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT DETAILS END *********************** */
