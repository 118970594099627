import axios from 'axios';
import { API_URL } from '../../../const';

export const memberDetailsGetService = (verifyData) => {
	const data = {
		MemberProfileDetailsToken: verifyData,
	};

	const url = `${API_URL}fetchMemberDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchMemberData = (fetchData, serviceName) => {
	const data = {
		FetchMemberDetailsToken: fetchData,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberMandatoryGetService = (fetchData) => {
	const data = {
		MemberMandatoryDetailsToken: fetchData,
	};
	const url = `${API_URL}getMandatoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAddMemberNavAPI = (mandData) => {
	const data = {
		AddMemberNavigationToken: mandData,
	};
	const url = `${API_URL}AddMemberNavigation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkMAndatoryDataAPI = (credentials) => {
	const data = {
		AddMemberMandatoryToken: credentials,
	};
	const url = `${API_URL}checkMandatoryData`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkMandatoryScreenAPI = (credentials) => {
	const data = {
		AddMemberMandatoryToken: credentials,
	};
	const url = `${API_URL}checkMandatoryScreen`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsRejectService = (verifyData) => {
	const data = {
		MemberProfileRejectToken: verifyData,
	};

	const url = `${API_URL}rejectMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addMemberListFetchService = (Data, serviceName) => {
	const data = {
		AddMemberToken: Data,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAllMemberExcel = (postData) => {
	const params = new URLSearchParams();
	params.append('AllMemberExportReportToken', postData);
	const url = `${API_URL}Report/Excel/AllMembersReportExport.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const deleteMemberService = (Data) => {
	const data = {
		AddMemberToken: Data,
	};
	const url = `${API_URL}DeleteMemberToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
