/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import HouseDetailsActionType from './house-details.types';

const INITIAL_STATE = {
	houseResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HouseDetailsActionType.HOUSE_DETAILS_CREATE_SUCCESS:
			return { ...state, houseResponse: action.payload };

		case HouseDetailsActionType.HOUSE_DETAILS_CREATE_FAILURE:
			return { ...state, houseResponse: action.payload };

		case HouseDetailsActionType.HOUSE_DETAILS_CREATE_RESPONSE_CHANGED:
			return { ...state, houseResponse: null };

		default:
			return state;
	}
};
