const BankDetailsActionType = {
	BANK_DETAILS_CREATE_START: 'bank_details_create_start',
	BANK_DETAILS_CREATE_SUCCESS: 'bank_details_create_success',
	BANK_DETAILS_CREATE_FAILURE: 'bank_details_create_failure',
	BANK_DETAILS_CREATE_RESPONSE_REST_START: 'bank_details_create_response_reset_start',
	BANK_DETAILS_CREATE_RESPONSE_CHANGED: 'bank_details_create_response_changed',

	BANK_IFSC_CODE_RESPONSE_START: 'bank_ifsc_code_response_start',
	BANK_IFSC_CODE_RESPONSE_SUCCESS: 'bank_ifsc_code_response_success',
	BANK_IFSC_CODE_RESPONSE_FAILURE: 'bank_ifsc_code_response_failure',
	BANK_IFSC_CODE_RESPONSE_CHANGED: 'bank_ifsc_code_response_changed',
	BANK_IFSC_CODE_RESPONSE_RESET: 'bank_ifsc_code_response_reset',
};

export default BankDetailsActionType;
