/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const basicMemberDetailsAdd = (verifyData) => {
	const data = {
		CreateMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}addBasicDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const skipMemberDetailsAPI = (verifyData) => {
	const data = {
		SkipMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}skipMemberDetials`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberCompletionAPI = (verifyData) => {
	const data = {
		MemberCompletionToken: verifyData,
	};
	const url = `${API_URL}updateMemberCompletion`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyKYCAPI = (credentials, serviceName) => {
	const data = {
		DocumentVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyValidateAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharValidateOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchKycVerificationAPI = (credentials) => {
	const data = {
		VerifyKYCFetchToken: credentials,
	};
	const url = `${API_URL}fetchKycVerification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
