/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { docodeResponse } from '../../../common/common.utils';
import AddMemberActionType from './add-member.types';

const INITIAL_STATE = {
	getExistsMemberDetailsResponse: null,
	getExistsMemberDetailsArr: [],
	fetchMemberDataResp: null,
	fetchMemberDataArr: [],
	memberMandatoryResp: null,
	memberMandatoryArr: [],
	addMemberNavList: [],
	addMemberNavListResp: null,
	checkMandatoryFieldsData: [],
	checkMandatoryFieldsResp: null,
	checkMandatoryScreenStatus: [],
	checkMandatoryScreenStatusResp: null,
	memberAccessType: 'MFI Loan',
	fetchAddMemberListResp: null,
	fetchAddMemberListArr: [],
	exportMemberListResp: null,
	exportMemberListArr: [],
	deleteMemberResp: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AddMemberActionType.GET_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				getExistsMemberDetailsResponse: action.payload,
				getExistsMemberDetailsArr: docodeResponse(action.payload, 'MemberProfileDetails'),
			};

		case AddMemberActionType.GET_MEMBER_DETAILS_FAILURE:
			return { ...state, getExistsMemberDetailsResponse: action.payload };

		case AddMemberActionType.GET_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, getExistsMemberDetailsResponse: null };

		case AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberDataResp: action.payload,
				fetchMemberDataArr: docodeResponse(action.payload, 'FetchMemberDataKey'),
			};

		case AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_FAILURE:
			return { ...state, fetchMemberDataResp: action.payload };

		case AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberDataResp: null, fetchMemberDataArr: [] };

		case AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_SUCCESS:
			return {
				...state,
				memberMandatoryResp: action.payload,
				memberMandatoryArr: docodeResponse(action.payload, 'MemberMandatoryDetailskey'),
			};

		case AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_FAILURE:
			return { ...state, memberMandatoryResp: action.payload };

		case AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_RESPONSE_CHANGED:
			return { ...state, memberMandatoryResp: null };

		case AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_SUCCESS:
			return {
				...state,
				addMemberNavListResp: action.payload,
				addMemberNavList: docodeResponse(action.payload, 'AddMemberNavigationKey'),
			};

		case AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_FAILURE:
			return { ...state, addMemberNavListResp: action.payload };

		case AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_CHANGED:
			return { ...state, addMemberNavListResp: null };

		case AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_SUCCESS:
			return {
				...state,
				checkMandatoryFieldsResp: action.payload,
				checkMandatoryFieldsData: docodeResponse(action.payload, 'MemberMandatoryCheckKey'),
			};

		case AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_FAILURE:
			return { ...state, checkMandatoryFieldsResp: action.payload };

		case AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_CHANGED:
			return { ...state, checkMandatoryFieldsResp: null, checkMandatoryFieldsData: [] };

		case AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_SUCCESS:
			return {
				...state,
				checkMandatoryScreenStatusResp: action.payload,
				checkMandatoryScreenStatus: docodeResponse(
					action.payload,
					'MemberMandatoryCheckKey',
				),
			};

		case AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_FAILURE:
			return { ...state, checkMandatoryScreenStatusResp: action.payload };

		case AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_CHANGED:
			return { ...state, checkMandatoryScreenStatusResp: null };

		case AddMemberActionType.REJECT_MEMBER_DETAILS_SUCCESS:
			return { ...state, rejectMemberDetailsResponse: action.payload };

		case AddMemberActionType.REJECT_MEMBER_DETAILS_FAILURE:
			return { ...state, rejectMemberDetailsResponse: action.payload };

		case AddMemberActionType.REJECT_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, rejectMemberDetailsResponse: null };

		case AddMemberActionType.UPDATE_MEMBER_ACCESS_TYPE:
			return { ...state, memberAccessType: action.payload };

		case AddMemberActionType.FETCH_ADD_MEMBER_LIST_SUCCESS:
			return {
				...state,
				fetchAddMemberListResp: action.payload,
				fetchAddMemberListArr: docodeResponse(action.payload, 'MemberDetailsKey'),
			};

		case AddMemberActionType.FETCH_ADD_MEMBER_LIST_FAILURE:
			return { ...state, fetchAddMemberListResp: action.payload };

		case AddMemberActionType.FETCH_ADD_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAddMemberListResp: null };

		case AddMemberActionType.EXPORT_MEMBER_LIST_SUCCESS:
			return {
				...state,
				exportMemberListResp: action.payload,
				exportMemberListArr: docodeResponse(action.payload, 'AllMemberReportExcelKey'),
			};

		case AddMemberActionType.EXPORT_MEMBER_LIST_FAILURE:
			return { ...state, exportMemberListResp: action.payload };

		case AddMemberActionType.EXPORT_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, exportMemberListResp: null };

		case AddMemberActionType.DELETE_MEMBER_SUCCESS:
			return {
				...state,
				deleteMemberResp: action.payload,
			};

		case AddMemberActionType.DELETE_MEMBER_FAILURE:
			return { ...state, deleteMemberResp: action.payload };

		case AddMemberActionType.DELETE_MEMBER_RESPONSE_CHANGED:
			return { ...state, deleteMemberResp: null };

		default:
			return state;
	}
};
