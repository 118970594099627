// eslint-disable-next-line import/no-unresolved
import PersonalDetailsActionType from './personal-details.types';

// START PERSONAL DETAILS
export const personalDetailsStart = (signatureUploadCredentials) => ({
	type: PersonalDetailsActionType.PERSONAL_DETAILS_START,
	payload: signatureUploadCredentials,
});

export const personalDetailsSuccess = (signatureUploadSuccess) => ({
	type: PersonalDetailsActionType.PERSONAL_DETAILS_SUCCESS,
	payload: signatureUploadSuccess,
});

export const personalDetailsFailure = (signatureUploadFailure) => ({
	type: PersonalDetailsActionType.PERSONAL_DETAILS_FAILURE,
	payload: signatureUploadFailure,
});

export const personalDetailsResponseResetStart = () => ({
	type: PersonalDetailsActionType.PERSONAL_DETAILS_RESPONSE_REST_START,
});

export const personalDetailsUserResponseChanged = () => ({
	type: PersonalDetailsActionType.PERSONAL_DETAILS_RESPONSE_CHANGED,
});
// END PERSONAL DETAILS
