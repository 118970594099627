/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchMemberRejectionReport = (fetchData) => {
	const data = {
		MemberRejectionReportToken: fetchData,
	};
	const url = `${API_URL}FetchMemberRejectionReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json'}});
};
export const fetchMemberRejectionReasoncount = (fetchData) => {
	const data = {
		MemberRejectionReasonToken: fetchData,
	};
	const url = `${API_URL}FetchMemberRejectionReasonCount`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json'}});
};
export const exportMemberRejectionExcel = (postData) => {
	const params = new URLSearchParams();
	params.append('ExportRejectionMembersListToken', postData);
	const url = `${API_URL}Report/Excel/MemberRejectionReportExportWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};


