const PortfolioReportActionTypes = {

    GET_PORTFOLIO_REPORT_LIST_START: 'get_portfolio_report_list_start',
	GET_PORTFOLIO_REPORT_LIST_SUCCESS: 'get_portfolio_report_list_success',
	GET_PORTFOLIO_REPORT_LIST_FAILURE: 'get_portfolio_report_list_failure',
	GET_PORTFOLIO_REPORT_LIST_RESPONSE_RESET: 'get_portfolio_report_list_response_reset',
	GET_PORTFOLIO_REPORT_LIST_RESPONSE_CHANGED: 'get_portfolio_report_list_response_changed',

	FETCH_PORTFOLIO_EXCEL_START: 'FETCH_PORTFOLIO_EXCEL_START',
	FETCH_PORTFOLIO_EXCEL_SUCCESS: 'FETCH_PORTFOLIO_EXCEL_SUCCESS',
	FETCH_PORTFOLIO_EXCEL_FAILURE: 'FETCH_PORTFOLIO_EXCEL_FAILURE',
	FETCH_PORTFOLIO_EXCEL_RESET: 'FETCH_PORTFOLIO_EXCEL_RESET',
	FETCH_PORTFOLIO_EXCEL_CHANGED: 'FETCH_PORTFOLIO_EXCEL_CHANGED',

}
export default PortfolioReportActionTypes