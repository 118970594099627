/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import PreCloseLoanActionTypes from './preCloseLoan.types';

import {
	PreCloseLoanMemberListGet,
	PreCloseLoanMemberListUpdate,
	PreCloseLoanMemberListRevert,
	BranchEODDetails,
} from './preCloseLoan.service';

import {
	getPreCloseLoanDetailsSuccess,
	getPreCloseLoanDetailsFailure,
	getPreCloseLoanDetailsChanged,
	updatePreCloseLoanDetailsSuccess,
	updatePreCloseLoanDetailsFailure,
	updatePreCloseLoanDetailsChanged,
	revertPreCloseLoanDetailsSuccess,
	revertPreCloseLoanDetailsFailure,
	revertPreCloseLoanDetailsChanged,
	getBranchEODSuccess,
	getBranchEODFailure,
	getBranchEODChanged,
} from './preCloseLoan.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET Close Loan MEMBER REPORT DETAILS  ******************** */
export function* getcloseLoanMemberList({ payload }) {
	try {
		const key = 'GetGroupMemberDetailsForPreCloseDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			PrecloseDate: payload.preCloseDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PreCloseLoanMemberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPreCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(getPreCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPreCloseLoanDetailsFailure(error));
	}
}

export function* resetGetPreCloseLoanResponse() {
	yield put(getPreCloseLoanDetailsChanged());
}

export function* onGetPreCloseLoanDetails() {
	yield takeLatest(PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_START, getcloseLoanMemberList);
}

export function* onGetPreCloseLoanResponseReset() {
	yield takeLatest(
		PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_RESET,
		resetGetPreCloseLoanResponse,
	);
}
/** *********************  END GET PreCloseLoan MEMBER REPORT LIST ******************** */

/** *********************  START UPDATE Close Loan MEMBER REPORT DETAILS  ******************** */
export function* updatecloseLoanMemberList({ payload }) {
	try {
		const key = 'UpdateGroupMemberDetailsForPreCloseKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserLevel: payload.userLevel,
			InfogCompanyID: payload.infogID,
			BranchID: payload.branchID,
			CenterID: payload.CenterID,
			CenterName: payload.centerName,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			CreatedFor: payload.userID,
			Preclosedate: payload.preclosedate,
			PreCloseDateUI: payload.preCloseDateUI,
			PrecloseCollectAll: payload.precloseCollectAll,
			PreCloseList: JSON.stringify(payload.preCloseList).replace(/"/g, "'"),
			AccountArr: payload.accountArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PreCloseLoanMemberListUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePreCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(updatePreCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePreCloseLoanDetailsFailure(error));
	}
}

export function* resetUpdatePreCloseLoanResponse() {
	yield put(updatePreCloseLoanDetailsChanged());
}

export function* onUpdatePreCloseLoanDetails() {
	yield takeLatest(
		PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_START,
		updatecloseLoanMemberList,
	);
}

export function* onUpdatePreCloseLoanResponseReset() {
	yield takeLatest(
		PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_RESET,
		resetUpdatePreCloseLoanResponse,
	);
}
/** *********************  END UPDATE PreCloseLoan MEMBER REPORT LIST ******************** */
/** *********************  START REVERT Close Loan MEMBER REPORT DETAILS  ******************** */
export function* revertcloseLoanMemberList({ payload }) {
	try {
		const key = 'RevertGroupMemberDetailsForPreCloseKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserLevel: payload.userLevel,
			InfogID: payload.infogCompanyID,
			BranchID: payload.branchID,
			CenterID: payload.CenterID,
			CenterName: payload.centerName,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			CreatedFor: payload.userID,
			PrecloseDate: payload.preCloseDate,

			LoanIndexID: payload.revertData.LoanIndexID,
			LoanIndexGroupMemberID: payload.revertData.LoanIndexGroupMemberID,
			MemberID: payload.revertData.MemberID,
			BranchCashAccountID: payload.revertData.BranchCashAccountID,
			BranchBankAccountID: payload.revertData.BranchBankAccountID,
			FunderID: payload.revertData.FunderID,
			FunderPrincipalAccountID: payload.revertData.FunderPrincipalAccountID,
			FunderInterestAccountID: payload.revertData.FunderInterestAccountID,
			PreclosePrincipal: payload.revertData.TotalPreClosePrincipal,
			PrecloseInterest: payload.revertData.TotalPreCloseInterest,
			// PrecloseDate: payload.revertData.PreCloseDate,
			memberSavingAccID: payload.revertData.memberSavingAccID,
			positiveAmountDiff: payload.revertData.positiveAmountDiff,
			lastInsertTransactionID: payload.revertData.lastInsertTransactionID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PreCloseLoanMemberListRevert, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(revertPreCloseLoanDetailsFailure(responseData.data));
		} else {
			yield put(revertPreCloseLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertPreCloseLoanDetailsFailure(error));
	}
}

export function* resetRevertPreCloseLoanResponse() {
	yield put(revertPreCloseLoanDetailsChanged());
}

export function* onRevertPreCloseLoanDetails() {
	yield takeLatest(
		PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_START,
		revertcloseLoanMemberList,
	);
}

export function* onRevertPreCloseLoanResponseReset() {
	yield takeLatest(
		PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_RESET,
		resetRevertPreCloseLoanResponse,
	);
}
/** *********************  END REVERT PreCloseLoan MEMBER REPORT LIST ******************** */

/** *********************  START GET Close Loan MEMBER REPORT DETAILS  ******************** */
export function* getBranchEODDetailsStart({ payload }) {
	try {
		const key = 'GetBranchEODExistPreCloseDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			InputDate: payload.collectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchEODDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchEODFailure(responseData.data));
		} else {
			yield put(getBranchEODSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchEODFailure(error));
	}
}

export function* resetBranchEODDetails() {
	yield put(getBranchEODChanged());
}

export function* onGetBranchEODDetails() {
	yield takeLatest(PreCloseLoanActionTypes.GET_BRANCH_EOD_START, getBranchEODDetailsStart);
}

export function* onGetBranchEODResponseReset() {
	yield takeLatest(PreCloseLoanActionTypes.GET_BRANCH_EOD_RESET, resetBranchEODDetails);
}
/** *********************  END GET PreCloseLoan MEMBER REPORT LIST ******************** */

export function* PreCloseLoanMemberSaga() {
	yield all([
		call(onGetPreCloseLoanDetails),
		call(onGetPreCloseLoanResponseReset),
		call(onUpdatePreCloseLoanDetails),
		call(onUpdatePreCloseLoanResponseReset),
		call(onRevertPreCloseLoanDetails),
		call(onRevertPreCloseLoanResponseReset),
		call(onGetBranchEODDetails),
		call(onGetBranchEODResponseReset),
	]);
}
