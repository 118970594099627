const FunderActionTypes = {
	ADD_FUNDER_DETAILS_START: 'add_funder_details_start',
	ADD_FUNDER_DETAILS_SUCCESS: 'add_funder_details_success',
	ADD_FUNDER_DETAILS_FAILURE: 'add_funder_details_failure',
	ADD_FUNDER_DETAILS_RESPONSE_RESET: 'add_funder_details_response_reset',
	ADD_FUNDER_DETAILS_RESPONSE_CHANGED: 'add_funder_details_response_changed',

	GET_FUNDER_LIST_START: 'get_funder_funder_start',
	GET_FUNDER_LIST_SUCCESS: 'get_funder_funder_success',
	GET_FUNDER_LIST_FAILURE: 'get_funder_funder_failure',
	GET_FUNDER_LIST_RESPONSE_RESET: 'get_funder_funder_response_reset',
	GET_FUNDER_LIST_RESPONSE_CHANGED: 'get_funder_funder_response_changed',

	UPDATE_FUNDER_DETAILS_START: 'update_funder_details_start',
	UPDATE_FUNDER_DETAILS_SUCCESS: 'update_funder_details_success',
	UPDATE_FUNDER_DETAILS_FAILURE: 'update_funder_details_failure',
	UPDATE_FUNDER_DETAILS_RESPONSE_RESET: 'update_funder_details_response_reset',
	UPDATE_FUNDER_DETAILS_RESPONSE_CHANGED: 'update_funder_details_response_changed',

	FETCH_FUNDER_DETAILS_START: 'fetch_funder_details_start',
	FETCH_FUNDER_DETAILS_SUCCESS: 'fetch_funder_details_success',
	FETCH_FUNDER_DETAILS_FAILURE: 'fetch_funder_details_failure',
	FETCH_FUNDER_DETAILS_RESPONSE_RESET: 'fetch_funder_details_response_reset',
	FETCH_FUNDER_DETAILS_RESPONSE_CHANGED: 'fetch_funder_details_response_changed',

	DELETE_FUNDER_DETAILS_START: 'delete_funder_details_start',
	DELETE_FUNDER_DETAILS_SUCCESS: 'delete_funder_details_success',
	DELETE_FUNDER_DETAILS_FAILURE: 'delete_funder_details_failure',
	DELETE_FUNDER_DETAILS_RESPONSE_RESET: 'delete_funder_details_response_reset',
	DELETE_FUNDER_DETAILS_RESPONSE_CHANGED: 'delete_funder_details_response_changed',

	UPDATE_ISPUBLISH_FUNDER_START: 'update_isPublish_Funder_start',
	UPDATE_ISPUBLISH_FUNDER_SUCCESS: 'update_isPublish_Funder_success',
	UPDATE_ISPUBLISH_FUNDER_FAILURE: 'update_isPublish_Funder_failure',
	UPDATE_ISPUBLISH_FUNDER_RESPONSE_RESET: 'update_isPublish_Funder_response_reset',
	UPDATE_ISPUBLISH_FUNDER_RESPONSE_CHANGED: 'update_isPublish_Funder_response_changed',
};
export default FunderActionTypes;
