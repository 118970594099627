/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';

export const signInSuccess = (user) => ({
	type: loginActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = (error) => ({
	type: loginActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

export const signInStart = (userCredentials) => ({
	type: loginActionTypes.SIGN_IN_START,
	payload: userCredentials,
});

export const signInReset = () => ({
	type: loginActionTypes.SIGN_IN_RESET,
});

export const signInChanged = () => ({
	type: loginActionTypes.SIGN_IN_CHANGED,
	// type: loginActionTypes.SIGN_IN_LOGOUT,
});

export const forgotPasswordSuccess = (user) => ({
	type: loginActionTypes.FORGOT_PASSWORD_SUCCESS,
	payload: user,
});

export const forgotPasswordFailure = (error) => ({
	type: loginActionTypes.FORGOT_PASSWORD_FAILURE,
	payload: error,
});

export const forgotPasswordStart = (userCredentials) => ({
	type: loginActionTypes.FORGOT_PASSWORD_START,
	payload: userCredentials,
});

export const forgotPasswordReset = () => ({
	type: loginActionTypes.FORGOT_PASSWORD_RESET,
});

export const forgotPasswordChanged = () => ({
	type: loginActionTypes.FORGOT_PASSWORD_CHANGED,
});

export const logOut = () => ({
	type: loginActionTypes.SIGN_IN_LOGOUT,
});

// USER DETAILS SECTION

export const getUserDetailsSuccess = (user) => ({
	type: loginActionTypes.GET_USER_DETAILS_SUCCESS,
	payload: user,
});

export const getUserDetailsFailure = (error) => ({
	type: loginActionTypes.GET_USER_DETAILS_FAILURE,
	payload: error,
});

export const getUserDetailsStart = (userCredentials) => ({
	type: loginActionTypes.GET_USER_DETAILS_START,
	payload: userCredentials,
});

export const getUserDetailsReset = () => ({
	type: loginActionTypes.GET_USER_DETAILS_RESET,
});

export const getUserDetailsChanged = () => ({
	type: loginActionTypes.GET_USER_DETAILS_CHANGED,
});

export const updateUserPasswordSuccess = (user) => ({
	type: loginActionTypes.UPDATE_USER_PASSWORD_SUCCESS,
	payload: user,
});

export const updateUserPasswordFailure = (error) => ({
	type: loginActionTypes.UPDATE_USER_PASSWORD_FAILURE,
	payload: error,
});

export const updateUserPasswordStart = (userCredentials) => ({
	type: loginActionTypes.UPDATE_USER_PASSWORD_START,
	payload: userCredentials,
});

export const updateUserPasswordReset = () => ({
	type: loginActionTypes.UPDATE_USER_PASSWORD_RESET,
});

export const updateUserPasswordChanged = () => ({
	type: loginActionTypes.UPDATE_USER_PASSWORD_CHANGED,
});

export const addPersistDataStart = (loginData) => ({
	type: loginActionTypes.ADD_PERSIST_LOGIN_DATA_START,
	payload: loginData,
});

export const addExistVersionDetails = (versionData) => ({
	type: loginActionTypes.ADD_EXIST_VERSION_DETAILS,
	payload: versionData,
});
