/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import PassportActionType from './passport-details.types';

const INITIAL_STATE = {
	updatePassportResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PassportActionType.UPDATE_PASSPORT_SUCCESS:
			return { ...state, updatePassportResponse: action.payload };

		case PassportActionType.UPDATE_PASSPORT_FAILURE:
			return { ...state, updatePassportResponse: action.payload };

		case PassportActionType.UPDATE_PASSPORT_RESPONSE_CHANGED:
			return { ...state, updatePassportResponse: null };

		default:
			return state;
	}
};
