/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const insuranceCompanyDetailsAdd = (addData) => {
	const data = {
		AddInsuranceCompanyDetailsToken: addData,
	};
	const url = `${API_URL}addInsuranceCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insuranceCompanyDetailsUpdate = (updateData) => {
	const data = {
		UpdateInsuranceCompanyDetailsToken: updateData,
	};
	const url = `${API_URL}updateInsuranceCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insuranceCompanyListGet = (listData) => {
	const data = {
		GetInsuranceCompanyListToken: listData,
	};
	const url = `${API_URL}getInsuranceCompanyList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insuranceCompanyDetailsFetch = (fetchData) => {
	const data = {
		FetchInsuranceCompanyDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchInsuranceCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insuranceCompanyDetailsDelete = (deleteData) => {
	const data = {
		DeleteInsuranceCompanyDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteInsuranceCompanyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allInsuranceCompanyFetch = (fetchBranchesData) => {
	const data = {
		FetchAllBranchKeyToken: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllBranches`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
