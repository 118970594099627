/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
export const getCurrencyList = (currencyType) => {
	const currencyObj = [
		{
			Currency: 'INR',
			Symbol: '₹',
		},
		{
			Currency: 'USD',
			Symbol: '$',
		},
		{
			Currency: 'EUR',
			Symbol: '€',
		},
		{
			Currency: 'CRC',
			Symbol: '₡',
		},
		{
			Currency: 'GBP',
			Symbol: '£',
		},
		{
			Currency: 'ILS',
			Symbol: '₪',
		},
		{
			Currency: 'JPY',
			Symbol: '¥',
		},
		{
			Currency: 'KRW',
			Symbol: '₩',
		},
		{
			Currency: 'NGN',
			Symbol: '₦',
		},
		{
			Currency: 'PHP',
			Symbol: '₱',
		},
		{
			Currency: 'PLN',
			Symbol: 'zł',
		},
		{
			Currency: 'PYG',
			Symbol: '₲',
		},
		{
			Currency: 'THB',
			Symbol: '฿',
		},
		{
			Currency: 'UAH',
			Symbol: '₴',
		},
		{
			Currency: 'VND',
			Symbol: '₫',
		},
	];
	const result = currencyObj.find((e) => e.Currency === currencyType);
	return result.Symbol;
};

export const UserAccessDataUpdate = (newArray, originalArray) => {
	const modifiedArray = originalArray;
	if (modifiedArray && newArray) {
		if (modifiedArray?.data && newArray?.data) {
			if (modifiedArray.data.length > 0 && newArray.data.length > 0) {
				const commonKeys = Object.keys(newArray.data[0]).filter((key) =>
					modifiedArray.data[0].hasOwnProperty(key),
				);

				if (commonKeys.length > 0) {
					commonKeys.forEach((key) => {
						modifiedArray.data[0][key] = newArray.data[0][key];
					});
				}
			}
		}
	}
	return modifiedArray;
};
