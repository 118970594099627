/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchGroupMember = (fetchData) => {
	const data = {
		GetGroupMemberListToken: fetchData,
	};
	const url = `${API_URL}FetchGroupMemberList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchWalletReport = (fetchData) => {
	const data = {
		GetWalletCollectionReportToken: fetchData,
	};
	const url = `${API_URL}GetWalletCollectionReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
