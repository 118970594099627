/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CenterActionTypes from './center.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addCenterDetailsResponse: null,
	updateCenterDetailsResponse: null,
	getCenterListResponse: null,
	getCenterListArr: [],
	fetchCenterDetailsResponse: null,
	fetchCenterDetailsArr: [],
	deleteCenterDetailsResponse: null,
	fetchCenterUniqueIDResponse: null,
	fetchCenterUniqueIDArr: [],
	exportCenterListResp: null,
	exportCenterListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CenterActionTypes.ADD_CENTER_DETAILS_SUCCESS:
			return { ...state, addCenterDetailsResponse: action.payload };

		case CenterActionTypes.ADD_CENTER_DETAILS_FAILURE:
			return { ...state, addCenterDetailsResponse: action.payload };

		case CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addCenterDetailsResponse: null };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_SUCCESS:
			return { ...state, updateCenterDetailsResponse: action.payload };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_FAILURE:
			return { ...state, updateCenterDetailsResponse: action.payload };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateCenterDetailsResponse: null };

		case CenterActionTypes.GET_CENTER_LIST_SUCCESS:
			return {
				...state,
				getCenterListResponse: action.payload,
				getCenterListArr: docodeResponse(action.payload, 'GetCenterListKey'),
			};

		case CenterActionTypes.GET_CENTER_LIST_FAILURE:
			return { ...state, getCenterListResponse: action.payload };

		case CenterActionTypes.GET_CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, getCenterListResponse: null };

		case CenterActionTypes.FETCH_CENTER_DETAILS_SUCCESS:
			return {
				...state,
				fetchCenterDetailsResponse: action.payload,
				fetchCenterDetailsArr: docodeResponse(action.payload, 'FetchCenterDetailsKey'),
			};

		case CenterActionTypes.FETCH_CENTER_DETAILS_FAILURE:
			return { ...state, fetchCenterDetailsResponse: action.payload };

		case CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchCenterDetailsResponse: null, fetchCenterDetailsArr: [] };

		case CenterActionTypes.DELETE_CENTER_DETAILS_SUCCESS:
			return { ...state, deleteCenterDetailsResponse: action.payload };

		case CenterActionTypes.DELETE_CENTER_DETAILS_FAILURE:
			return { ...state, deleteCenterDetailsResponse: action.payload };

		case CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteCenterDetailsResponse: null };

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_SUCCESS:
			return {
				...state,
				fetchCenterUniqueIDResponse: action.payload,
				fetchCenterUniqueIDArr: docodeResponse(action.payload, 'FetchCenterUniqueIdKey'),
			};

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_FAILURE:
			return { ...state, fetchCenterUniqueIDResponse: action.payload };

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED:
			return { ...state, fetchCenterUniqueIDResponse: null };

		case CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_SUCCESS:
			return {
				...state,
				getAllCenterListExportResponse: action.payload,
			};

		case CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_FAILURE:
			return { ...state, getAllCenterListExportResponse: action.payload };

		case CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_RESPONSE_CHANGED:
			return { ...state, getAllCenterListExportResponse: null };

		default:
			return state;
	}
};
