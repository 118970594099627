import ProfileTypes from './profile.types';

/** ***************** GET PROFILE DETAILS START *********************** */
export const getProfileDetailsStart = (getData) => ({
	type: ProfileTypes.GET_PROFILE_DETAILS_START,
	payload: getData,
});

export const getProfileDetailsSuccess = (successData) => ({
	type: ProfileTypes.GET_PROFILE_DETAILS_SUCCESS,
	payload: successData,
});

export const getProfileDetailsFailure = (failureData) => ({
	type: ProfileTypes.GET_PROFILE_DETAILS_FAILURE,
	payload: failureData,
});

export const getProfileDetailsResponseResetStart = () => ({
	type: ProfileTypes.GET_PROFILE_DETAILS_RESPONSE_RESET,
});

export const getProfileDetailsResponseChanged = () => ({
	type: ProfileTypes.GET_PROFILE_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET PROFILE DETAILS END *********************** */

/** ***************** UPDATE PROFILE DETAILS START *********************** */
export const updateProfileDetailsStart = (updateData) => ({
	type: ProfileTypes.UPDATE_PROFILE_DETAILS_START,
	payload: updateData,
});

export const updateProfileDetailsSuccess = (successData) => ({
	type: ProfileTypes.UPDATE_PROFILE_DETAILS_SUCCESS,
	payload: successData,
});

export const updateProfileDetailsFailure = (failureData) => ({
	type: ProfileTypes.UPDATE_PROFILE_DETAILS_FAILURE,
	payload: failureData,
});

export const updateProfileDetailsResponseResetStart = () => ({
	type: ProfileTypes.UPDATE_PROFILE_DETAILS_RESPONSE_RESET,
});

export const updateProfileDetailsResponseChanged = () => ({
	type: ProfileTypes.UPDATE_PROFILE_DETAILS_RESPONSE_CHANGED,
});
/** ***************** UPDATE PROFILE DETAILS END *********************** */

/** ***************** UPDATE PASSWORD START *********************** */
export const updatePasswordStart = (updatePasswordData) => ({
	type: ProfileTypes.UPDATE_PASSWORD_START,
	payload: updatePasswordData,
});

export const updatePasswordSuccess = (successData) => ({
	type: ProfileTypes.UPDATE_PASSWORD_SUCCESS,
	payload: successData,
});

export const updatePasswordFailure = (failureData) => ({
	type: ProfileTypes.UPDATE_PASSWORD_FAILURE,
	payload: failureData,
});

export const updatePasswordResponseResetStart = () => ({
	type: ProfileTypes.UPDATE_PASSWORD_RESPONSE_RESET,
});

export const updatePasswordResponseChanged = () => ({
	type: ProfileTypes.UPDATE_PASSWORD_RESPONSE_CHANGED,
});
/** ***************** UPDATE PASSWORD END *********************** */

/** ***************** GENERATE SECRETE AUTHKEYFUN START *********************** */

export const generateSecreteAuthKeyStart = (updateData) => ({
	type: ProfileTypes.GENERATE_SECRETE_AUTHKEY_START,
	payload: updateData,
});

export const generateSecreteAuthKeySuccess = (successData) => ({
	type: ProfileTypes.GENERATE_SECRETE_AUTHKEY_SUCCESS,
	payload: successData,
});

export const generateSecreteAuthKeyFailure = (failureData) => ({
	type: ProfileTypes.GENERATE_SECRETE_AUTHKEY_FAILURE,
	payload: failureData,
});

export const generateSecreteAuthKeyResponseResetStart = () => ({
	type: ProfileTypes.GENERATE_SECRETE_AUTHKEY_RESPONSE_RESET,
});

export const generateSecreteAuthKeyResponseChanged = () => ({
	type: ProfileTypes.GENERATE_SECRETE_AUTHKEY_RESPONSE_CHANGED,
});
/** ***************** GENERATE SECRETE AUTHKEYFUN END *********************** */
