/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';
import PortFlioAtRiskTypes from './portflioAtRiskReport.types';
import {
	PortflioAtRiskDetailsGet,
	PortflioAtRiskDetailsExportGet,
	UserDetailsGet,
	LoanInfoBasedOnMemberGet,
} from './portflioAtRiskReport.service';
import {
	getportflioAtRiskSuccess,
	getportflioAtRiskFailure,
	getportflioAtRiskResponseChanged,
	getportflioAtRiskExportSuccess,
	getportflioAtRiskExportFailure,
	getportflioAtRiskExportResponseChanged,
	getUserDetailsSuccess,
	getUserDetailsFailure,
	getUserDetailsResponseChanged,
	getLoanInfoBasedOnMemberFailure,
	getLoanInfoBasedOnMemberSuccess,
} from './portflioAtRiskReport.action';
import { encodeJson } from '../../enode-decode';
/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getPortflioAtRisk({ payload }) {
	// debugger;
	try {
		const key = 'GetPortfolioAtRiskReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			TillDate: payload.TillDate,
			fromDays: payload.fromDays,
			toDays: payload.toDays,
			UserRole: payload.userRole,
			FieldOfficer: payload.officerID,
			SearchValue: payload.SearchValue,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PortflioAtRiskDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getportflioAtRiskFailure(responseData.data));
		} else {
			yield put(getportflioAtRiskSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getportflioAtRiskFailure(error));
	}
}

export function* resetGetPortfolioReportListResponse() {
	yield put(getportflioAtRiskResponseChanged());
}

export function* onGetPortflioAtRiskListDetails() {
	yield takeLatest(PortFlioAtRiskTypes.PORTFLIO_AT_RISK_START, getPortflioAtRisk);
}

export function* onGetPortflioAtRiskListResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.PORTFLIO_AT_RISK_RESPONSE_RESET,
		resetGetPortfolioReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getPortflioAtRiskExport({ payload }) {
	// debugger;
	try {
		const key = 'GetPortfolioAtRiskReportExportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			TillDate: payload.TillDate,
			fromDays: payload.fromDays,
			toDays: payload.toDays,
			UserRole: payload.userRole,
			FieldOfficer: payload.officerID,
			SearchValue: payload.SearchValue,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PortflioAtRiskDetailsExportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getportflioAtRiskExportFailure(responseData.data));
		} else {
			yield put(getportflioAtRiskExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getportflioAtRiskExportFailure(error));
	}
}

export function* resetGetPortfolioReportListExportResponse() {
	yield put(getportflioAtRiskExportResponseChanged());
}

export function* onGetPortflioAtRiskListExportDetails() {
	yield takeLatest(PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_START, getPortflioAtRiskExport);
}

export function* onGetPortflioAtRiskListExportResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_RESPONSE_RESET,
		resetGetPortfolioReportListExportResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */


export function* userDetailspar({ payload }) {
	// debugger;
	try {
		const key = 'userDetailsparKey';
		const jsonData = {
			staffID: payload.userID,
			userLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(UserDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getUserDetailsFailure(responseData.data));
		} else {
			yield put(getUserDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getUserDetailsFailure(error));
	}
}

export function* resetGetUserDetailsResponse() {
	yield put(getUserDetailsResponseChanged());
}

export function* onGetUserDetailsListDetails() {
	yield takeLatest(PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_START, userDetailspar);
}

export function* onGetUserDetailsListResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_RESET,
		resetGetUserDetailsResponse,
	);
}

export function* LoanInfoBasedOnMemberDetails({ payload }) {
	// debugger;
	try {
		const key = 'LOANINFORBASEDONMEMBERBOTHCLOSEDTOKEN';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			TillDate: payload.TillDate,
			fromDays: payload.fromDays,
			toDays: payload.toDays,
			UserRole: payload.userRole,
			FieldOfficer: payload.officerID,
			SearchValue: payload.SearchValue,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanInfoBasedOnMemberGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanInfoBasedOnMemberFailure(responseData.data));
		} else {
			yield put(getLoanInfoBasedOnMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanInfoBasedOnMemberFailure(error));
	}
}

export function* resetGetLoanInfoBasedOnMemberResponse() {
	yield put(getUserDetailsResponseChanged());
}

export function* onGetLoanInfoBasedOnMemberDetails() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_START,
		LoanInfoBasedOnMemberDetails,
	);
}

export function* onGetLoanInfoBasedOnMemberResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_RESET,
		resetGetLoanInfoBasedOnMemberResponse,
	);
}
export function* portfolioAtRiskReportSaga() {
	yield all([
		call(onGetPortflioAtRiskListDetails),
		call(onGetPortflioAtRiskListResponseReset),
		call(onGetPortflioAtRiskListExportDetails),
		call(onGetPortflioAtRiskListExportResponseReset),
		call(onGetUserDetailsListDetails),
		call(onGetUserDetailsListResponseReset),
		call(onGetLoanInfoBasedOnMemberDetails),
		call(onGetLoanInfoBasedOnMemberResponseReset),
	]);
}
