/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import ResidentialDetailsActionType from './residential-details.types';
import { decodeApiResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	residentialDetailsResponse: null,
	citiesListResponse: null,
	citiesListArray: [],
	stateListResponse: null,
	stateListArray: [],
	addressDetails: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_SUCCESS:
			return { ...state, residentialDetailsResponse: action.payload };

		case ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_FAILURE:
			return { ...state, residentialDetailsResponse: action.payload };

		case ResidentialDetailsActionType.RESIDENTIAL_DETAILS_ADD_RESPONSE_CHANGED:
			return { ...state, residentialDetailsResponse: null };

		case ResidentialDetailsActionType.FETCH_STATE_LIST_SUCCESS:
			return {
				...state,
				stateListResponse: action.payload,
				stateListArray: decodeApiResponse(action.payload, 'FetchStateDataKey'),
			};

		case ResidentialDetailsActionType.FETCH_STATE_LIST_FAILURE:
			return { ...state, stateListResponse: action.payload };

		case ResidentialDetailsActionType.FETCH_STATE_LIST_RESPONSE_CHANGED:
			return { ...state, stateListResponse: null, stateListArray: [] };

		case ResidentialDetailsActionType.FETCH_CITIES_LIST_SUCCESS:
			return {
				...state,
				citiesListResponse: action.payload,
				citiesListArray: decodeApiResponse(action.payload, 'FetchCitiesDataKey'),
			};

		case ResidentialDetailsActionType.FETCH_CITIES_LIST_FAILURE:
			return { ...state, citiesListResponse: action.payload };

		case ResidentialDetailsActionType.FETCH_CITIES_LIST_RESPONSE_CHANGED:
			return { ...state, citiesListResponse: null, citiesListArray: [] };

		case ResidentialDetailsActionType.UPDATE_SELECTED_ADDRESS_DATA:
			return { ...state, addressDetails: action.payload };

		default:
			return state;
	}
};
