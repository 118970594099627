/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import InsuranceCompanyActionTypes from './insurance-company.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addInsuranceCompanyDetailsResponse: null,
	updateInsuranceCompanyDetailsResponse: null,
	getInsuranceCompanyListResponse: null,
	getInsuranceCompanyListArr: [],
	fetchInsuranceCompanyDetailsResponse: null,
	fetchInsuranceCompanyDetailsArr: [],
	deleteInsuranceCompanyDetailsResponse: null,
	fetchAllInsuranceCompanysResponse: null,
	fetchAllInsuranceCompanysArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_SUCCESS:
			return { ...state, addInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_FAILURE:
			return { ...state, addInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED:
			return { ...state, addInsuranceCompanyDetailsResponse: null };

		case InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_SUCCESS:
			return { ...state, updateInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_FAILURE:
			return { ...state, updateInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateInsuranceCompanyDetailsResponse: null };

		case InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_SUCCESS:
			return {
				...state,
				getInsuranceCompanyListResponse: action.payload,
				getInsuranceCompanyListArr: docodeResponse(
					action.payload,
					'GetInsuranceCompanyListKey',
				),
			};

		case InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_FAILURE:
			return {
				...state,
				getInsuranceCompanyListResponse: action.payload,
				getInsuranceCompanyListArr: [],
			};

		case InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_RESPONSE_CHANGED:
			return { ...state, getInsuranceCompanyListResponse: null };

		case InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_SUCCESS:
			return {
				...state,
				fetchInsuranceCompanyDetailsResponse: action.payload,
				fetchInsuranceCompanyDetailsArr: docodeResponse(
					action.payload,
					'FetchInsuranceCompanyDetailsKey',
				),
			};

		case InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_FAILURE:
			return { ...state, fetchInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchInsuranceCompanyDetailsResponse: null,
				fetchInsuranceCompanyDetailsArr: [],
			};

		case InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_SUCCESS:
			return { ...state, deleteInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_FAILURE:
			return { ...state, deleteInsuranceCompanyDetailsResponse: action.payload };

		case InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteInsuranceCompanyDetailsResponse: null };

		case InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				fetchAllInsuranceCompanysResponse: action.payload,
				fetchAllInsuranceCompanysArr: docodeResponse(action.payload, 'GetBranchListKey'),
			};

		case InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_FAILURE:
			return { ...state, fetchAllInsuranceCompanysResponse: action.payload };

		case InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_RESPONSE_CHANGED:
			return { ...state, fetchAllInsuranceCompanysResponse: null };

		default:
			return state;
	}
};
