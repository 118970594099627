/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChangeMeetingActionTypes from './change-meeting.types';

import {
	changeMeetingListGet,
	officerBranchListGet,
	changeMeetingListExportGet
} from './change-meeting.service';

import {
	getChangeMeetingDetailsSuccess,
	getChangeMeetingDetailsFailure,
	getChangeMeetingDetailsResponseChanged,
	getChangeMeetingDetailsExportSuccess,
	getChangeMeetingDetailsExportFailure,
	getChangeMeetingDetailsExportResponseChanged,
	getOfficerBranchDetailsSuccess,
	getOfficerBranchDetailsFailure,
	getOfficerBranchDetailsResponseChanged,
} from './change-meeting.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getChangeMeetingList({ payload }) {
	try {
		const key = 'GetChangeMeetingReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(changeMeetingListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getChangeMeetingDetailsFailure(responseData.data));
		} else {
			yield put(getChangeMeetingDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getChangeMeetingDetailsFailure(error));
	}
}

export function* resetGetChangeMeetingListResponse() {
	yield put(getChangeMeetingDetailsResponseChanged());
}

export function* onGetChangeMeetingListDetails() {
	yield takeLatest(ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_START, getChangeMeetingList);
}

export function* onGetChangeMeetingListResponseReset() {
	yield takeLatest(
		ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_RESPONSE_RESET,
		resetGetChangeMeetingListResponse,
	);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getChangeMeetingExportList({ payload }) {
	try {
		const key = 'GetChangeMeetingExportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			RequestID: payload.requestID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(changeMeetingListExportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getChangeMeetingDetailsExportFailure(responseData.data));
		} else {
			yield put(getChangeMeetingDetailsExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getChangeMeetingDetailsExportFailure(error));
	}
}

export function* resetGetChangeMeetingExportListResponse() {
	yield put(getChangeMeetingDetailsExportResponseChanged());
}

export function* onGetChangeMeetingListExportDetails() {
	yield takeLatest(
		ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_EXPORT_START,
		getChangeMeetingExportList,
	);
}

export function* onGetChangeMeetingListExportResponseReset() {
	yield takeLatest(
		ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_EXPORT_RESPONSE_RESET,
		resetGetChangeMeetingExportListResponse,
	);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(
		ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
		resetGetChangeMeetingListResponse,
	);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

export function* reportChangeMeetingSaga() {
	yield all([
		call(onGetChangeMeetingListDetails),
		call(onGetChangeMeetingListResponseReset),
		call(onGetChangeMeetingListExportDetails),
		call(onGetChangeMeetingListExportResponseReset),
		call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
