/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import arrearCollectionActionTypes from './arrearCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getArrearMemberLoanInfoResponse: null,
	getArrearMemberLoanInfoArr: [],
	updatedArrearCollectionStatus: null,
	updatedArrearCollectionResponse: null,
	updatedArrearCollectionRevertResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_SUCCESS:
			return {
				...state,
				getArrearMemberLoanInfoResponse: action.payload,
				getArrearMemberLoanInfoArr: docodeResponse(
					action.payload,
					'FetchArrearMemberListKey',
				),
			};

		case arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_FAILURE:
			return { ...state, getArrearMemberLoanInfoResponse: action.payload };

		case arrearCollectionActionTypes.GET_ARREAR_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getArrearMemberLoanInfoResponse: null };

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_SUCCESS:
			return {
				...state,
				updatedArrearCollectionStatus: 'SUCCESS',
				updatedArrearCollectionResponse: docodeResponse(
					action.payload,
					'UpdateCommonLoanPaidDetailsKey',
				),
			};

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_FAILURE:
			return { ...state, updatedArrearCollectionStatus: 'FAILURE' };

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_CHANGED:
			return {
				...state,
				updatedArrearCollectionStatus: null,
				updatedArrearCollectionResponse: null,
			};

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_SUCCESS:
			return {
				...state,
				updatedArrearCollectionRevertResponse: action.payload,
			};

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_FAILURE:
			return { ...state, updatedArrearCollectionRevertResponse: action.payload };

		case arrearCollectionActionTypes.UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_CHANGED:
			return { ...state, updatedArrearCollectionRevertResponse: null };

		default:
			return state;
	}
};
