/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import MemberRejectionReportActionTypes from './memberRejectionReport.types';

/** ***************** GET MEMBER REJECTION REPORT DETAILS START *********************** */
export const fetchMemberRejectionReportStart = (getData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_START,
	payload: getData,
});

export const fetchMemberRejectionReportSuccess = (successData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_SUCCESS,
	payload: successData,
});

export const fetchMemberRejectionReportFailure = (failureData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_FAILURE,
	payload: failureData,
});

export const fetchMemberRejectionReportResponseReset = () => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_RESPONSE_RESET,
});

export const fetchMemberRejectionReportResponseChanged = () => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED,
});

export const exportMemberRejectionReportStart = (getData) => ({
	type: MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_START,
	payload: getData,
});

export const exportMemberRejectionReportSuccess = (successData) => ({
	type: MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_SUCCESS,
	payload: successData,
});

export const exportMemberRejectionReportFailure = (failureData) => ({
	type: MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_FAILURE,
	payload: failureData,
});

export const exportMemberRejectionReportResponseReset = () => ({
	type: MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_RESET,
});

export const exportMemberRejectionReportResponseChanged = () => ({
	type: MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED,
});

/** ***************** GET MEMBER REJECTION REPORT DETAILS END *********************** */

/** ***************** GET MEMBER REJECTION REPORT REASON COUNT START *********************** */
export const fetchMemberRejectionReasoncountStart = (getData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_START,
	payload: getData,
});

export const fetchMemberRejectionReasoncountSuccess = (successData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_SUCCESS,
	payload: successData,
});

export const fetchMemberRejectionReasoncountFailure = (failureData) => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_FAILURE,
	payload: failureData,
});

export const fetchMemberRejectionReasoncountResponseReset = () => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_RESET,
});

export const fetchMemberRejectionReasoncountResponseChanged = () => ({
	type: MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_CHANGED,
});

/** ***************** GET MEMBER REJECTION REPORT REASON COUNT START *********************** */
