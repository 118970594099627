/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import memberCollectionActionTypes from './memberCollection.types';
import {
	fetchSearchMemberLoanList,
	fetchMemberLoanInfo,
	memberCollectionUpdateAPI,
	memberDeathUpdateAPI,
	interestWaiverUpdateAPI,
	memberCollectionRevertAPI,
	memberCollectionFetchAPI,
	memberWalletFetchAPI,
	memberQrFetchAPI,
	memberRepaymentFetchAPI,
	memberWalletRevertAPI,
} from './memberCollection.service';

import {
	getSearchMemberLoanListSuccess,
	getSearchMemberLoanListFailure,
	getSearchMemberLoanListResponseChanged,
	getMemberLoanInfoSuccess,
	getMemberLoanInfoFailure,
	getMemberLoanInfoResponseChanged,
	updateMemberCollectionResponseChanged,
	updateMemberCollectionSuccess,
	updateMemberCollectionFailure,
	updateMemberDeathResponseChanged,
	updateMemberDeathSuccess,
	updateMemberDeathFailure,
	updateInterestWaiverResponseChanged,
	updateInterestWaiverSuccess,
	updateInterestWaiverFailure,
	revertMemberCollectionResponseChanged,
	revertMemberCollectionSuccess,
	revertMemberCollectionFailure,
	fetchMemberCollectionResponseChanged,
	fetchMemberCollectionSuccess,
	fetchMemberCollectionFailure,
	fetchMemberWalletResponseChanged,
	fetchMemberWalletSuccess,
	fetchMemberWalletFailure,
	fetchMemberQrResponseChanged,
	fetchMemberQrSuccess,
	fetchMemberQrFailure,
	fetchMemberRepaymentResponseChanged,
	fetchMemberRepaymentSuccess,
	fetchMemberRepaymentFailure,
	revertMemberWalletFailure,
	revertMemberWalletResponseChanged,
	revertMemberWalletSuccess,
} from './memberCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START SEARCH MEMBER LOAN LIST DETAILS  ******************** */
export function* getSearchMemberLoanList({ payload }) {
	try {
		const key = 'searchMemberLoanListKey';
		const jsonData = {
			searchMemberValue: payload.searchMemberValue,
			searchMemberType: payload.searchMemberType,
			MemberCompanyID: payload.MemberCompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			DatabaseName: payload.DatabaseName,
			ProvisionType: payload.ProvisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchMemberLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSearchMemberLoanListFailure(responseData.data));
		} else {
			yield put(getSearchMemberLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSearchMemberLoanListFailure(error));
	}
}

export function* resetGetSearchMemberLoanListResponse() {
	yield put(getSearchMemberLoanListResponseChanged());
}

export function* onGetSearchMemberLoanListDetails() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_START,
		getSearchMemberLoanList,
	);
}

export function* onGetSearchMemberLoanListResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET,
		resetGetSearchMemberLoanListResponse,
	);
}
/** *********************  END GET SEARCH MEMBER LOAN LIST  ******************** */

/** *********************  START MEMBER LOAN INFO DETAILS  ******************** */
export function* getMemberLoanInfo({ payload }) {
	try {
		const key = 'LoanInfoMemberLoanListKey';
		const jsonData = {
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberLoanInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberLoanInfoFailure(responseData.data));
		} else {
			yield put(getMemberLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberLoanInfoFailure(error));
	}
}

export function* resetGetMemberLoanInfoResponse() {
	yield put(getMemberLoanInfoResponseChanged());
}

export function* onGetMemberLoanInfoDetails() {
	yield takeLatest(memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_START, getMemberLoanInfo);
}

export function* onGetMemberLoanInfoResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_RESET,
		resetGetMemberLoanInfoResponse,
	);
}
/** *********************  END GET MEMBER LOAN INFO  ******************** */

/** *********************  START MEMBER COLLECTION UPDATE  ******************** */
export function* updateMemberCollectionList({ payload }) {
	try {
		const key = 'UpdateMemberCollectionDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			totalDenominationAmount: payload.totalDenominationAmount,
			BranchID: payload.BranchID,
			LocationID: payload.CenterID,
			GroupID: payload.GroupID,
			Member: payload.memberID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			RecivedAmount: payload.Amount,
			memberCollectionDate: payload.repayDate,
			ReceiptNumber: payload.receiptNumber,
			ChequeNumber: payload.chequeNumber,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberCollectionUpdateAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberCollectionFailure(responseData.data));
		} else {
			yield put(updateMemberCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberCollectionFailure(error));
	}
}

export function* resetMemberCollectionUpdateResponse() {
	yield put(updateMemberCollectionResponseChanged());
}

export function* onMemberCollectionUpdate() {
	yield takeLatest(
		memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_START,
		updateMemberCollectionList,
	);
}

export function* onMemberCollectionUpdateResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_RESPONSE_RESET,
		resetMemberCollectionUpdateResponse,
	);
}

export function* updateMemberDeathList({ payload }) {
	try {
		const key = 'UpdateMemberDeathDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			MemberID: payload.MemberID,
			DeathDate: payload.DeathDate,
			DeathPlace: payload.DeathPlace,
			DeathReason: payload.DeathReason,
			IsDeadDemandRequired: payload.IsDeadDemandRequired,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDeathUpdateAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberDeathFailure(responseData.data));
		} else {
			yield put(updateMemberDeathSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberDeathFailure(error));
	}
}

export function* resetMemberDeathUpdateResponse() {
	yield put(updateMemberDeathResponseChanged());
}

export function* onMemberDeathUpdate() {
	yield takeLatest(memberCollectionActionTypes.UPDATE_MEMBER_DEATH_START, updateMemberDeathList);
}

export function* onMemberDeathUpdateResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.UPDATE_MEMBER_DEATH_RESPONSE_RESET,
		resetMemberDeathUpdateResponse,
	);
}

export function* updateInterestWaiverList({ payload }) {
	try {
		const key = 'UpdateInterestWaiverDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			MemberID: payload.MemberID,
			InterestWaiverAmount: payload.InterestWaiverAmount,
			InterestWaiverReason: payload.InterestWaiverReason,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(interestWaiverUpdateAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateInterestWaiverFailure(responseData.data));
		} else {
			yield put(updateInterestWaiverSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateInterestWaiverFailure(error));
	}
}

export function* resetInterestWaiverUpdateResponse() {
	yield put(updateInterestWaiverResponseChanged());
}

export function* onInterestWaiverUpdate() {
	yield takeLatest(
		memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_START,
		updateInterestWaiverList,
	);
}

export function* onInterestWaiverUpdateResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_RESPONSE_RESET,
		resetInterestWaiverUpdateResponse,
	);
}

export function* revertMemberCollectionList({ payload }) {
	try {
		const key = 'RevertMemberCollectionDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			ArrearCollectionID: payload.arrearCollectionID,
			DueID: payload.loanPaidID,
			serviceName: payload.serviceName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(
			memberCollectionRevertAPI,
			encodeData,
			jsonData.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(revertMemberCollectionFailure(responseData.data));
		} else {
			yield put(revertMemberCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertMemberCollectionFailure(error));
	}
}

export function* resetMemberCollectionRevertResponse() {
	yield put(revertMemberCollectionResponseChanged());
}

export function* onMemberCollectionRevert() {
	yield takeLatest(
		memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_START,
		revertMemberCollectionList,
	);
}

export function* onMemberCollectionRevertResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_RESPONSE_RESET,
		resetMemberCollectionRevertResponse,
	);
}

export function* fetchMemberCollectionList({ payload }) {
	try {
		const key = 'FetchMemberCollectionDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			Member: payload.memberID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			RevertAmount: payload.Amount,
			DueID: payload.loanPaidID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberCollectionFetchAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberCollectionFailure(responseData.data));
		} else {
			yield put(fetchMemberCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberCollectionFailure(error));
	}
}

export function* resetMemberCollectionFetchResponse() {
	yield put(fetchMemberCollectionResponseChanged());
}

export function* onMemberCollectionDetailsFetch() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_COLLECTION_START,
		fetchMemberCollectionList,
	);
}

export function* onMemberCollectionDetailsFetchResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_COLLECTION_RESPONSE_RESET,
		resetMemberCollectionFetchResponse,
	);
}

export function* fetchMemberWalletList({ payload }) {
	try {
		const key = 'FetchMemberWalletDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberWalletFetchAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberWalletFailure(responseData.data));
		} else {
			yield put(fetchMemberWalletSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberWalletFailure(error));
	}
}

export function* resetMemberWalletFetchResponse() {
	yield put(fetchMemberWalletResponseChanged());
}

export function* onMemberWalletDetailsFetch() {
	yield takeLatest(memberCollectionActionTypes.GET_MEMBER_WALLET_START, fetchMemberWalletList);
}

export function* onMemberWalletDetailsFetchResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_WALLET_RESPONSE_RESET,
		resetMemberWalletFetchResponse,
	);
}

export function* fetchMemberQrList({ payload }) {
	try {
		const key = 'FetchMemberQrDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberQrFetchAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberQrFailure(responseData.data));
		} else {
			yield put(fetchMemberQrSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberQrFailure(error));
	}
}

export function* resetMemberQrFetchResponse() {
	yield put(fetchMemberQrResponseChanged());
}

export function* onMemberQrDetailsFetch() {
	yield takeLatest(memberCollectionActionTypes.GET_MEMBER_QR_START, fetchMemberQrList);
}

export function* onMemberQrDetailsFetchResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_QR_RESPONSE_RESET,
		resetMemberQrFetchResponse,
	);
}

export function* fetchMemberRepaymentList({ payload }) {
	try {
		const key = 'FetchMemberRepaymentDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.companyID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberRepaymentFetchAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberRepaymentFailure(responseData.data));
		} else {
			yield put(fetchMemberRepaymentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberRepaymentFailure(error));
	}
}

export function* resetMemberRepaymentFetchResponse() {
	yield put(fetchMemberRepaymentResponseChanged());
}

export function* onMemberRepaymentDetailsFetch() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_REPAYMENT_START,
		fetchMemberRepaymentList,
	);
}

export function* onMemberRepaymentDetailsFetchResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_REPAYMENT_RESPONSE_RESET,
		resetMemberRepaymentFetchResponse,
	);
}

export function* revertMemberWalletList({ payload }) {
	try {
		const key = 'RevertMemberWalletDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberWalletRevertAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(revertMemberWalletFailure(responseData.data));
		} else {
			yield put(revertMemberWalletSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertMemberWalletFailure(error));
	}
}

export function* resetMemberWalletRevertResponse() {
	yield put(revertMemberWalletResponseChanged());
}

export function* onMemberWalletDetailsRevert() {
	yield takeLatest(
		memberCollectionActionTypes.DELETE_MEMBER_WALLET_START,
		revertMemberWalletList,
	);
}

export function* onMemberWalletDetailsRevertResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.DELETE_MEMBER_WALLET_RESPONSE_RESET,
		resetMemberWalletRevertResponse,
	);
}

export function* memberCollectionSaga() {
	yield all([
		call(onGetSearchMemberLoanListDetails),
		call(onGetSearchMemberLoanListResponseReset),
		call(onGetMemberLoanInfoDetails),
		call(onGetMemberLoanInfoResponseReset),
		call(onMemberCollectionUpdate),
		call(onMemberCollectionUpdateResponseReset),
		call(onMemberDeathUpdate),
		call(onMemberDeathUpdateResponseReset),
		call(onInterestWaiverUpdate),
		call(onInterestWaiverUpdateResponseReset),
		call(onMemberCollectionRevert),
		call(onMemberCollectionRevertResponseReset),
		call(onMemberCollectionDetailsFetch),
		call(onMemberCollectionDetailsFetchResponseReset),
		call(onMemberWalletDetailsFetch),
		call(onMemberWalletDetailsFetchResponseReset),
		call(onMemberQrDetailsFetch),
		call(onMemberQrDetailsFetchResponseReset),
		call(onMemberRepaymentDetailsFetch),
		call(onMemberRepaymentDetailsFetchResponseReset),
		call(onMemberWalletDetailsRevert),
		call(onMemberWalletDetailsRevertResponseReset),
	]);
}
