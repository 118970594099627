/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import AuditingToolActionTypes from './auditing-tool.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getAuditingToolDetailsStart = (getData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_START,
	payload: getData,
});

export const getAuditingToolDetailsSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_SUCCESS,
	payload: successData,
});

export const getAuditingToolDetailsFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_FAILURE,
	payload: failureData,
});

export const getAuditingToolDetailsResponseResetStart = () => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_RESET,
});

export const getAuditingToolDetailsResponseChanged = () => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */

/** ***************** FETCH AUDITING TOOL START *********************** */
export const fetchAuditingToolDetailsStart = (getData) => ({
	type: AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_START,
	payload: getData,
});

export const fetchAuditingToolDetailsSuccess = (successData) => ({
	type: AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_SUCCESS,
	payload: successData,
});

export const fetchAuditingToolDetailsFailure = (failureData) => ({
	type: AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_FAILURE,
	payload: failureData,
});

export const fetchAuditingToolDetailsReset = () => ({
	type: AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_RESET,
});

export const fetchAuditingToolDetailsChanged = () => ({
	type: AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_CHANGED,
});
/** *****************  FETCH AUDITING TOOL END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getQuestionnaireStart = (getData) => ({
	type: AuditingToolActionTypes.GET_QUESTIONNAIRE_START,
	payload: getData,
});

export const getQuestionnaireSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const getQuestionnaireFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const getQuestionnaireResponseResetStart = () => ({
	type: AuditingToolActionTypes.GET_QUESTIONNAIRE_RESPONSE_RESET,
});

export const getQuestionnaireResponseChanged = () => ({
	type: AuditingToolActionTypes.GET_QUESTIONNAIRE_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

/** ***************** GET TRAINING PROPOSAL MEMBERS START *********************** */
export const getTrainingProposalMembersStart = (getData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_START,
	payload: getData,
});

export const getTrainingProposalMembersSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_SUCCESS,
	payload: successData,
});

export const getTrainingProposalMembersFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_FAILURE,
	payload: failureData,
});

export const getTrainingProposalMembersReset = () => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_RESET,
});

export const getTrainingProposalMembersChanged = () => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_CHANGED,
});
/** ***************** GET TRAINING PROPOSAL MEMBERS END *********************** */


/** ***************** ADD TRAINING PROPOSAL START *********************** */
export const addTrainingProposalStart = (addData) => ({
	type: AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_START,
	payload: addData,
});

export const addTrainingProposalSuccess = (successData) => ({
	type: AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addTrainingProposalFailure = (failureData) => ({
	type: AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addTrainingProposalReset = () => ({
	type: AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_RESET,
});

export const addTrainingProposalChanged = () => ({
	type: AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_CHANGED,
});
/** ***************** ADD TRAINING PROPOSAL END *********************** */

/** ***************** UPDATE TRAINING PROPOSAL START *********************** */
export const updateTrainingProposalStart = (updateData) => ({
	type: AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_START,
	payload: updateData,
});

export const updateTrainingProposalSuccess = (successData) => ({
	type: AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_SUCCESS,
	payload: successData,
});

export const updateTrainingProposalFailure = (failureData) => ({
	type: AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_FAILURE,
	payload: failureData,
});

export const updateTrainingProposalReset = () => ({
	type: AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_RESET,
});

export const updateTrainingProposalChanged = () => ({
	type: AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_CHANGED,
});
/** ***************** UPDATE TRAINING PROPOSAL END *********************** */


/** ***************** DELETE TRAINING PROPOSAL START *********************** */
export const deleteTrainingProposalStart = (deleteData) => ({
	type: AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_START,
	payload: deleteData,
});

export const deleteTrainingProposalSuccess = (successData) => ({
	type: AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_SUCCESS,
	payload: successData,
});

export const deleteTrainingProposalFailure = (failureData) => ({
	type: AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_FAILURE,
	payload: failureData,
});

export const deleteTrainingProposalReset = () => ({
	type: AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_RESET,
});

export const deleteTrainingProposalChanged = () => ({
	type: AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_CHANGED,
});
/** ***************** DELETE TRAINING PROPOSAL END *********************** */


/** ***************** GET SUPERVISER LIST START *********************** */
export const getAllSuperviserListStart = (addData) => ({
	type: AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_START,
	payload: addData,
});

export const getAllSuperviserListSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_SUCCESS,
	payload: successData,
});

export const getAllSuperviserListFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_FAILURE,
	payload: failureData,
});

export const getAllSuperviserListReset = () => ({
	type: AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_RESET,
});

export const getAllSuperviserListChanged = () => ({
	type: AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_CHANGED,
});
/** ***************** GET SUPERVISER LIST END *********************** */


/** ***************** APPROVE TRAINING PROPOSAL START *********************** */
export const approveTrainingProposalStart = (rejectData) => ({
	type: AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_START,
	payload: rejectData,
});

export const approveTrainingProposalSuccess = (successData) => ({
	type: AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_SUCCESS,
	payload: successData,
});

export const approveTrainingProposalFailure = (failureData) => ({
	type: AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_FAILURE,
	payload: failureData,
});

export const approveTrainingProposalReset = () => ({
	type: AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_RESET,
});

export const approveTrainingProposalChanged = () => ({
	type: AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_CHANGED,
});
/** ***************** APPROVE TRAINING PROPOSAL END *********************** */


/** ***************** GET TRAINING PROPOSAL INDIVIDUAL START *********************** */
export const getTrainingProposalIndividualStart = (getData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_START,
	payload: getData,
});

export const getTrainingProposalIndividualSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_SUCCESS,
	payload: successData,
});

export const getTrainingProposalIndividualFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_FAILURE,
	payload: failureData,
});

export const getTrainingProposalIndividualReset = () => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_RESET,
});

export const getTrainingProposalIndividualChanged = () => ({
	type: AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_CHANGED,
});
/** ***************** GET TRAINING PROPOSAL INDIVIDUAL END *********************** */