/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import LeadReportActionTypes from './leadReport.types';

import { LeadListGetAPI, OfficerListGetAPI } from './leadReport.service';

import {
	fetchLeadListSuccess,
	fetchLeadListFailure,
	fetchLeadListResponseChanged,
	fetchOfficerListSuccess,
	fetchOfficerListFailure,
	fetchOfficerListResponseChanged,
} from './leadReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET LEAD DETAILS  ******************** */
export function* getLeadListList({ payload }) {
	try {
		const key = 'GetLeadDetailsListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.OfficerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LeadListGetAPI, encodeData);
		if (responseData.data.status === '100') {
			yield put(fetchLeadListFailure(responseData.data));
		} else {
			yield put(fetchLeadListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLeadListFailure(error));
	}
}

export function* resetGetLeadListResponse() {
	yield put(fetchLeadListResponseChanged());
}

export function* onGetLeadListDetails() {
	yield takeLatest(LeadReportActionTypes.FETCH_LEAD_LIST_START, getLeadListList);
}

export function* onGetLeadListResponseReset() {
	yield takeLatest(LeadReportActionTypes.FETCH_LEAD_LIST_RESET_START, resetGetLeadListResponse);
}
/** *********************  END GET LEAD LIST ******************** */

/** *********************  START GET OFFICER DETAILS  ******************** */
export function* getOfficerListList({ payload }) {
	try {
		const key = 'GetOfficerDetailsListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(OfficerListGetAPI, encodeData);
		if (responseData.data.status === '100') {
			yield put(fetchOfficerListFailure(responseData.data));
		} else {
			yield put(fetchOfficerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchOfficerListFailure(error));
	}
}

export function* resetGetOfficerListResponse() {
	yield put(fetchOfficerListResponseChanged());
}

export function* onGetOfficerListDetails() {
	yield takeLatest(LeadReportActionTypes.FETCH_OFFICER_LIST_START, getOfficerListList);
}

export function* onGetOfficerListResponseReset() {
	yield takeLatest(
		LeadReportActionTypes.FETCH_OFFICER_LIST_RESET_START,
		resetGetOfficerListResponse,
	);
}
/** *********************  END GET OFFICER LIST ******************** */

export function* LeadReportSaga() {
	yield all([
		call(onGetLeadListDetails),
		call(onGetLeadListResponseReset),
		call(onGetOfficerListDetails),
		call(onGetOfficerListResponseReset),
	]);
}
