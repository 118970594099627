/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import RationCardDetailsActionType from './ration-card-details.types';

const INITIAL_STATE = {
	updateRationCardResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RationCardDetailsActionType.UPDATE_RATION_CARD_SUCCESS:
			return { ...state, updateRationCardResponse: action.payload };

		case RationCardDetailsActionType.UPDATE_RATION_CARD_FAILURE:
			return { ...state, updateRationCardResponse: action.payload };

		case RationCardDetailsActionType.UPDATE_RATION_CARD_RESPONSE_CHANGED:
			return { ...state, updateRationCardResponse: null };

		default:
			return state;
	}
};
