/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const deadmemberDetailsListGet = (listData) => {
	const data = {
		GetDeadMemberReportToken: listData,
	};
	const url = `${API_URL}PostAllDeadMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deadmemberDetailsListExport = (listData) => {
	const params = new URLSearchParams();
	params.append('GetDeadMemberToken', listData);
	const url = `${API_URL}Report/Excel/DeadMemberReportExportWithToken.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};