/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import RangeLoanProposalActionTypes from './range-loan-propsal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getRangeProposalResponse: null,
	getRangeProposalListArr: [],
	getRangeProposalCenterListResponse: null,
	getRangeProposalCenterListArr: [],
	getCenterMemberListResponse: null,
	getCenterMemberListArr: [],
	getRangeProductListResponse: null,
	getRangeProductListArr: [],
	addRangeLoanProposalResponse: null,
	addRangeLoanProposalArr: [],
	updateRangeLoanProposalResponse: null,
	updateRangeLoanProposalArr: [],
	getCenterMemberLeadListResponse: null,
	getCenterMemberLeadListArr: [],
	getParticularLoanIndexDetailsResponse: null,
	getParticularLoanIndexDetailsArr: [],
	approveRangeLoanIndexResponse: null,
	revertApprovedRangeLoanIndexResponse: null,
	rejectRangeLoanIndexResponse: null,
	deleteRangeLoanProposalResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_SUCCESS:
			return {
				...state,
				getRangeProposalResponse: action.payload,
				getRangeProposalListArr: docodeResponse(
					action.payload,
					'GetAllBranchLoanIndexDetailsKey',
				),
			};

		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_FAILURE:
			return {
				...state,
				getRangeProposalResponse: action.payload,
				getRangeProposalListArr: [],
			};

		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
			return { ...state, getRangeProposalResponse: null };

		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_SUCCESS:
			return {
				...state,
				getRangeProposalCenterListResponse: action.payload,
				getRangeProposalCenterListArr: docodeResponse(
					action.payload,
					'GetRangeLoanProposalCenterListKey',
				),
			};

		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_FAILURE:
			return { ...state, getRangeProposalCenterListResponse: action.payload };

		case RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, getRangeProposalCenterListResponse: null };

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getCenterMemberListResponse: action.payload,
				getCenterMemberListArr: docodeResponse(
					action.payload,
					'GetMemberDetailsForCenterLoanKey',
				),
			};

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_FAILURE:
			return { ...state, getCenterMemberListResponse: action.payload };

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, getCenterMemberListResponse: null };

		case RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getRangeProductListResponse: action.payload,
				getRangeProductListArr: docodeResponse(
					action.payload,
					'GetRangeLoanProposalProductListKey',
				),
			};

		case RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_FAILURE:
			return { ...state, getRangeProductListResponse: action.payload };

		case RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getRangeProductListResponse: null };

		case RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				addRangeLoanProposalResponse: action.payload,
				addRangeLoanProposalArr: docodeResponse(action.payload, 'AddRangeLoanProposaltKey'),
			};

		case RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, addRangeLoanProposalResponse: action.payload };

		case RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addRangeLoanProposalResponse: null };

		case RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				updateRangeLoanProposalResponse: action.payload,
				updateRangeLoanProposalArr: docodeResponse(
					action.payload,
					'UpdateRangeLoanProposaltKey',
				),
			};

		case RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, updateRangeLoanProposalResponse: action.payload };

		case RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, updateRangeLoanProposalResponse: null };

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_SUCCESS:
			return {
				...state,
				getCenterMemberLeadListResponse: action.payload,
				getCenterMemberLeadListArr: docodeResponse(action.payload, 'GetMemberLeadListKey'),
			};

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_FAILURE:
			return { ...state, getCenterMemberLeadListResponse: action.payload };

		case RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_CHANGED:
			return { ...state, getCenterMemberLeadListResponse: null };

		case RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				getParticularLoanIndexDetailsResponse: action.payload,
				getParticularLoanIndexDetailsArr: docodeResponse(
					action.payload,
					'GetParticularRangeLoanIndexKey',
				),
			};

		case RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE:
			return { ...state, getParticularLoanIndexDetailsResponse: action.payload };

		case RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, getParticularLoanIndexDetailsResponse: null };

		case RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				approveRangeLoanIndexResponse: action.payload,
			};

		case RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, approveRangeLoanIndexResponse: action.payload };

		case RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, approveRangeLoanIndexResponse: null };

		case RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				revertApprovedRangeLoanIndexResponse: action.payload,
			};

		case RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, revertApprovedRangeLoanIndexResponse: action.payload };

		case RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, revertApprovedRangeLoanIndexResponse: null };

		case RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				rejectRangeLoanIndexResponse: action.payload,
			};

		case RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, rejectRangeLoanIndexResponse: action.payload };

		case RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, rejectRangeLoanIndexResponse: null };

		case RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				deleteRangeLoanProposalResponse: action.payload,
			};

		case RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_FAILURE:
			return { ...state, deleteRangeLoanProposalResponse: action.payload };

		case RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, deleteRangeLoanProposalResponse: null };

		default:
			return state;
	}
};
