/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanActionTypes from './product-loan-proposal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getProductProposalResponse: null,
	getProductProposalListArr: [],
	getParticularProductProposalResponse: null,
	getParticularProductProposalListArr: [],
	getWholeGroupMemberResponse: null,
	getWholeGroupMemberListArr: [],
	addProductLoanProposalResponse: null,
	addProductLoanProposalArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				getProductProposalResponse: action.payload,
				getProductProposalListArr: docodeResponse(
					action.payload,
					'GetProductLoanProposalKey',
				),
			};

		case LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_FAILURE:
			return { ...state, getProductProposalResponse: action.payload };

		case LoanActionTypes.FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, getProductProposalResponse: null };

		case LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				getParticularProductProposalResponse: action.payload,
				getParticularProductProposalArr: docodeResponse(
					action.payload,
					'GetParticularProductLoanProposalKey',
				),
			};

		case LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_FAILURE:
			return { ...state, getParticularProductProposalResponse: action.payload };

		case LoanActionTypes.FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, getParticularProductProposalResponse: null };

		case LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_SUCCESS:
			return {
				...state,
				getWholeGroupMemberResponse: action.payload,
				getWholeGroupMemberArr: docodeResponse(
					action.payload,
					'FetchWholeGroupMemberDetailsKey',
				),
			};

		case LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_FAILURE:
			return { ...state, getWholeGroupMemberResponse: action.payload };

		case LoanActionTypes.FETCH_WHOLE_GROUP_MEMBER_RESPONSE_CHANGED:
			return { ...state, getWholeGroupMemberResponse: null };

		case LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				addProductLoanProposalResponse: action.payload,
				addProductLoanProposalArr: docodeResponse(action.payload, 'AddLoanIndexTokenKey'),
			};

		case LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_FAILURE:
			return { ...state, addProductLoanProposalResponse: action.payload };

		case LoanActionTypes.ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addProductLoanProposalResponse: null };

		default:
			return state;
	}
};
