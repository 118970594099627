const MemberActionTypes = {
	ADD_MEMBER_DETAILS_START: 'add_member_details_start',
	ADD_MEMBER_DETAILS_SUCCESS: 'add_member_details_success',
	ADD_MEMBER_DETAILS_FAILURE: 'add_member_details_failure',
	ADD_MEMBER_DETAILS_RESPONSE_RESET: 'add_member_details_response_reset',
	ADD_MEMBER_DETAILS_RESPONSE_CHANGED: 'add_member_details_response_changed',

	UPDATE_MEMBER_DETAILS_START: 'update_member_details_start',
	UPDATE_MEMBER_DETAILS_SUCCESS: 'update_member_details_success',
	UPDATE_MEMBER_DETAILS_FAILURE: 'update_member_details_failure',
	UPDATE_MEMBER_DETAILS_RESPONSE_RESET: 'update_member_details_response_reset',
	UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED: 'update_member_details_response_changed',

	GET_MEMBER_LIST_START: 'get_member_member_start',
	GET_MEMBER_LIST_SUCCESS: 'get_member_member_success',
	GET_MEMBER_LIST_FAILURE: 'get_member_member_failure',
	GET_MEMBER_LIST_RESPONSE_RESET: 'get_member_member_response_reset',
	GET_MEMBER_LIST_RESPONSE_CHANGED: 'get_member_member_response_changed',

	FETCH_MEMBER_DETAILS_START: 'fetch_member_details_start',
	FETCH_MEMBER_DETAILS_SUCCESS: 'fetch_member_details_success',
	FETCH_MEMBER_DETAILS_FAILURE: 'fetch_member_details_failure',
	FETCH_MEMBER_DETAILS_RESPONSE_RESET: 'fetch_member_details_response_reset',
	FETCH_MEMBER_DETAILS_RESPONSE_CHANGED: 'fetch_member_details_response_changed',

	DELETE_MEMBER_DETAILS_START: 'delete_member_details_start',
	DELETE_MEMBER_DETAILS_SUCCESS: 'delete_member_details_success',
	DELETE_MEMBER_DETAILS_FAILURE: 'delete_member_details_failure',
	DELETE_MEMBER_DETAILS_RESPONSE_RESET: 'delete_member_details_response_reset',
	DELETE_MEMBER_DETAILS_RESPONSE_CHANGED: 'delete_member_details_response_changed',

	// CO-APPLICANT HIGH MARK DETAILS

	FETCH_COAPPLICANT_HIGHMARK_DETAILS_START: 'fetch_coapplicant_highmark_details_start',
	FETCH_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS: 'fetch_coapplicant_highmark_details_success',
	FETCH_COAPPLICANT_HIGHMARK_DETAILS_FAILURE: 'fetch_coapplicant_highmark_details_failure',
	FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET:
		'fetch_coapplicant_highmark_details_response_reset',
	FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED:
		'fetch_coapplicant_highmark_details_response_changed',

	// VERIFY DETAILS

	VERIFY_DOCUMENT_DETAILS_START: 'verify_document_details_start',
	VERIFY_DOCUMENT_DETAILS_SUCCESS: 'verify_document_details_success',
	VERIFY_DOCUMENT_DETAILS_FAILURE: 'verify_document_details_failure',
	VERIFY_DOCUMENT_DETAILS_RESPONSE_RESET: 'verify_document_details_response_reset',
	VERIFY_DOCUMENT_DETAILS_RESPONSE_CHANGED: 'verify_document_details_response_changed',

	// CHECK HIGH MARK DETAILS

	CHECK_HIGHMARK_DETAILS_START: 'check_highmark_details_start',
	CHECK_HIGHMARK_DETAILS_SUCCESS: 'check_highmark_details_success',
	CHECK_HIGHMARK_DETAILS_FAILURE: 'check_highmark_details_failure',
	CHECK_HIGHMARK_DETAILS_RESPONSE_RESET: 'check_highmark_details_response_reset',
	CHECK_HIGHMARK_DETAILS_RESPONSE_CHANGED: 'check_highmark_details_response_changed',

	// CHECK EQUIFAX DETAILS

	CHECK_EQUIFAX_DETAILS_START: 'check_equifax_details_start',
	CHECK_EQUIFAX_DETAILS_SUCCESS: 'check_equifax_details_success',
	CHECK_EQUIFAX_DETAILS_FAILURE: 'check_equifax_details_failure',
	CHECK_EQUIFAX_DETAILS_RESPONSE_RESET: 'check_equifax_details_response_reset',
	CHECK_EQUIFAX_DETAILS_RESPONSE_CHANGED: 'check_equifax_details_response_changed',
	// CHECK CO-APPLICANT HIGH MARK DETAILS

	CHECK_COAPPLICANT_HIGHMARK_DETAILS_START: 'check_coapplicant_highmark_details_start',
	CHECK_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS: 'check_coapplicant_highmark_details_success',
	CHECK_COAPPLICANT_HIGHMARK_DETAILS_FAILURE: 'check_coapplicant_highmark_details_failure',
	CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET:
		'check_coapplicant_highmark_details_response_reset',
	CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED:
		'check_coapplicant_highmark_details_response_changed',

	// UPDATE MEMBER LOAN

	UPDATE_NO_LOAN_MEMBER_START: 'update_no_loan_member_start',
	UPDATE_NO_LOAN_MEMBER_SUCCESS: 'update_no_loan_member_success',
	UPDATE_NO_LOAN_MEMBER_FAILURE: 'update_no_loan_member_failure',
	UPDATE_NO_LOAN_MEMBER_RESPONSE_RESET: 'update_no_loan_member_response_reset',
	UPDATE_NO_LOAN_MEMBER_RESPONSE_CHANGED: 'update_no_loan_member_response_changed',

	// CHECK MEMBER LOAN
	CHECK_TOPUP_LOAN_MEMBER_START: 'check_topup_loan_member_start',
	CHECK_TOPUP_LOAN_MEMBER_SUCCESS: 'check_topup_loan_member_success',
	CHECK_TOPUP_LOAN_MEMBER_FAILURE: 'check_topup_loan_member_failure',
	CHECK_TOPUP_LOAN_MEMBER_RESPONSE_RESET: 'check_topup_loan_member_response_reset',
	CHECK_TOPUP_LOAN_MEMBER_RESPONSE_CHANGED: 'check_topup_loan_member_response_changed',

	// REJECT MEMBER

	REJECT_MEMBER_START: 'reject_member_start',
	REJECT_MEMBER_SUCCESS: 'reject_member_success',
	REJECT_MEMBER_FAILURE: 'reject_member_failure',
	REJECT_MEMBER_RESPONSE_RESET: 'reject_member_response_reset',
	REJECT_MEMBER_RESPONSE_CHANGED: 'reject_member_response_changed',

	// RECHECK MEMBER

	RECHECK_MEMBER_START: 'recheck_member_start',
	RECHECK_MEMBER_SUCCESS: 'recheck_member_success',
	RECHECK_MEMBER_FAILURE: 'recheck_member_failure',
	RECHECK_MEMBER_RESPONSE_RESET: 'recheck_member_response_reset',
	RECHECK_MEMBER_RESPONSE_CHANGED: 'recheck_member_response_changed',

	// RECHECK MEMBER

	APPROVE_MEMBER_START: 'approve_member_start',
	APPROVE_MEMBER_SUCCESS: 'approve_member_success',
	APPROVE_MEMBER_FAILURE: 'approve_member_failure',
	APPROVE_MEMBER_RESPONSE_RESET: 'approve_member_response_reset',
	APPROVE_MEMBER_RESPONSE_CHANGED: 'approve_member_response_changed',

	// PAYSPRINT LOG FETCH

	FETCH_PAYSPRINT_DETAILS_START: 'fetch_paysprint_details_start',
	FETCH_PAYSPRINT_DETAILS_SUCCESS: 'fetch_paysprint_details_success',
	FETCH_PAYSPRINT_DETAILS_FAILURE: 'fetch_paysprint_details_failure',
	FETCH_PAYSPRINT_DETAILS_RESPONSE_RESET: 'fetch_paysprint_details_response_reset',
	FETCH_PAYSPRINT_DETAILS_RESPONSE_CHANGED: 'fetch_paysprint_details_response_changed',

	// PAYSPRINT LOG FETCH

	FETCH_MEMBER_AUDIT_DETAILS_START: 'fetch_member_audit_details_start',
	FETCH_MEMBER_AUDIT_DETAILS_SUCCESS: 'fetch_member_audit_details_success',
	FETCH_MEMBER_AUDIT_DETAILS_FAILURE: 'fetch_member_audit_details_failure',
	FETCH_MEMBER_AUDIT_DETAILS_RESPONSE_RESET: 'fetch_member_audit_details_response_reset',
	FETCH_MEMBER_AUDIT_DETAILS_RESPONSE_CHANGED: 'fetch_member_audit_details_response_changed',

	// PAYSPRINT LOG FETCH

	FETCH_MEMBER_VERIFY_DETAILS_START: 'fetch_member_verify_details_start',
	FETCH_MEMBER_VERIFY_DETAILS_SUCCESS: 'fetch_member_verify_details_success',
	FETCH_MEMBER_VERIFY_DETAILS_FAILURE: 'fetch_member_verify_details_failure',
	FETCH_MEMBER_VERIFY_DETAILS_RESPONSE_RESET: 'fetch_member_verify_details_response_reset',
	FETCH_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED: 'fetch_member_verify_details_response_changed',

	UPDATE_MEMBER_VERIFY_DETAILS_START: 'update_member_verify_details_start',
	UPDATE_MEMBER_VERIFY_DETAILS_SUCCESS: 'update_member_verify_details_success',
	UPDATE_MEMBER_VERIFY_DETAILS_FAILURE: 'update_member_verify_details_failure',
	UPDATE_MEMBER_VERIFY_DETAILS_RESPONSE_RESET: 'update_member_verify_details_response_reset',
	UPDATE_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED: 'update_member_verify_details_response_changed',

	UPDATE_MEMBER_KYC_DETAILS_START: 'update_member_kyc_details_start',
	UPDATE_MEMBER_KYC_DETAILS_SUCCESS: 'update_member_kyc_details_success',
	UPDATE_MEMBER_KYC_DETAILS_FAILURE: 'update_member_kyc_details_failure',
	UPDATE_MEMBER_KYC_DETAILS_RESPONSE_RESET: 'update_member_kyc_details_response_reset',
	UPDATE_MEMBER_KYC_DETAILS_RESPONSE_CHANGED: 'update_member_kyc_details_response_changed',

	UPDATE_MEMBER_TRANSFER_DETAILS_START: 'update_member_transfer_details_start',
	UPDATE_MEMBER_TRANSFER_DETAILS_SUCCESS: 'update_member_transfer_details_success',
	UPDATE_MEMBER_TRANSFER_DETAILS_FAILURE: 'update_member_transfer_details_failure',
	UPDATE_MEMBER_TRANSFER_DETAILS_RESPONSE_RESET: 'update_member_transfer_details_response_reset',
	UPDATE_MEMBER_TRANSFER_DETAILS_RESPONSE_CHANGED:
		'update_member_transfer_details_response_changed',

	// SEND OTP

	SEND_OTP_START: 'send_otp_start',
	SEND_OTP_SUCCESS: 'send_otp_success',
	SEND_OTP_FAILURE: 'send_otp_failure',
	SEND_OTP_RESPONSE_RESET: 'send_otp_response_reset',
	SEND_OTP_RESPONSE_CHANGED: 'send_otp_response_changed',

	// ADD MEMBER DETAILS
	ADD_MEMBER_DETAILS: 'add_member_detials',
	ADD_MEMBER_TAB: 'add_member_tab',
	UPDATE_MEMBER_TAB: 'update_member_tab',
	RESET_MEMBER_DATA: 'reset_member_data',
};
export default MemberActionTypes;
