/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ActiveMemberActionTypes from './active-member.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getActiveMemberListResponse: null,
	getActiveMemberListStatus: null,

	getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],

	getActiveMemberExcelListResponse: null,
	getActiveMemberExcelListArr: [],
	getActiveMemberExcelListStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getActiveMemberListResponse: docodeResponse(
					action.payload,
					'GetActiveMemberReportKey',
				),
				getActiveMemberListStatus: 'Success',
			};

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_FAILURE:
			return { ...state, getActiveMemberListStatus: action.payload };

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, getActiveMemberListStatus: null };

		case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getOfficerBranchListResponse: action.payload,
				getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
			};

		case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
			return { ...state, getOfficerBranchListResponse: action.payload };

		case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getOfficerBranchListResponse: null };

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getActiveMemberExcelListResponse: action.payload,
				getActiveMemberExcelListArr: docodeResponse(action.payload, 'FutureDemandExcelKey'),
				getActiveMemberExcelListStatus: 'Success',
			};

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_FAILURE:
			return { ...state, getActiveMemberExcelListStatus: action.payload };

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getActiveMemberExcelListStatus: null };

		default:
			return state;
	}
};
