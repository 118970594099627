import axios from 'axios';
import { API_URL } from '../../../const';

export const coApplicantUpdate = (updateCoApplicantData) => {
	const data = {
		CoApplicantToken: updateCoApplicantData,
	};
	const url = `${API_URL}updateCoApplicant`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const coApplicantAdd = (updateCoApplicantData) => {
	const data = {
		CoApplicantToken: updateCoApplicantData,
	};
	const url = `${API_URL}AddCoBorrower`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const coApplicantFetch = (updateCoApplicantData) => {
	const data = {
		FetchMemberDetailsToken: updateCoApplicantData,
	};
	const url = `${API_URL}fetchParticularCoApplicant`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const coApplicantDelete = (CoApplicantData) => {
	const data = {
		DeleteCoApplicantToken: CoApplicantData,
	};
	const url = `${API_URL}deleteParticularCoApplicant`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const coApplicantCrifAPI = (CoApplicantData) => {
	const data = {
		CheckCoAppliantHighMarkToken: CoApplicantData,
	};
	const url = `${API_URL}callCoApplicantHighMark`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyCoApplicantAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyValidateCoApplicantAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharValidateOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
