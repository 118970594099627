/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import HousePhotosActionType from './house-photos.types';

const INITIAL_STATE = {
	updateHousePhotosResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HousePhotosActionType.UPDATE_HOUSE_PHOTOS_SUCCESS:
			return { ...state, updateHousePhotosResponse: action.payload };

		case HousePhotosActionType.UPDATE_HOUSE_PHOTOS_FAILURE:
			return { ...state, updateHousePhotosResponse: action.payload };

		case HousePhotosActionType.UPDATE_HOUSE_PHOTOS_RESPONSE_CHANGED:
			return { ...state, updateHousePhotosResponse: null };

		default:
			return state;
	}
};
