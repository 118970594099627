/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import Spinner from '../../components/bootstrap/Spinner';

const ButtonSpinner = () => {
	return <Spinner isSmall inButton isGrow />;
};

export default ButtonSpinner;
