
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ArrearFollowupActionTypes from './arrear-followup-report.types';

import { ArrearFollowupListGet } from './arrear-followup-report.service';

import {
	getArrearFollowupDetailsSuccess,
	getArrearFollowupDetailsFailure,
	getArrearFollowupDetailsResponseChanged,
	getArrearFollowupDetailsResponseArrayChanged,
} from './arrear-followup-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET ARREAR FOLLOWUP REPORT DETAILS  ******************** */
export function* getArrearFollowupList({ payload }) {
	try {
		const key = 'GetArrearFollowUpReportKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			Month: payload.Month,
			Year: payload.Year,
			ViewBy: payload.ViewBy,
			IsThriftExist: payload.IsThriftExist,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ArrearFollowupListGet, encodeData, payload.ViewType);
		if (responseData.data.statusCode === '100') {
			yield put(getArrearFollowupDetailsFailure(responseData.data));
		} else {
			yield put(getArrearFollowupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getArrearFollowupDetailsFailure(error));
	}
}

export function* resetGetArrearFollowupListResponse() {
	yield put(getArrearFollowupDetailsResponseChanged());
}

export function* resetGetArrearFollowupListResponseArray() {
	yield put(getArrearFollowupDetailsResponseArrayChanged());
}

export function* onGetArrearFollowupListDetails() {
	yield takeLatest(
		ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_START,
		getArrearFollowupList,
	);
}

export function* onGetArrearFollowupListResponseReset() {
	yield takeLatest(
		ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_RESET,
		resetGetArrearFollowupListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT REPORT LIST ******************** */


export function* reportArrearFollowupSaga() {
	yield all([
		call(onGetArrearFollowupListDetails),
		call(onGetArrearFollowupListResponseReset),
	]);
}
