/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import WorkFlowManagementActionTypes from './workflowManagement.types';

/** ***************** GET ALL_ROLE DETAILS START *********************** */
export const getAllRoleDetailsStart = (getData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_START,
	payload: getData,
});

export const getAllRoleDetailsSuccess = (successData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_SUCCESS,
	payload: successData,
});

export const getAllRoleDetailsFailure = (failureData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_FAILURE,
	payload: failureData,
});

export const getAllRoleDetailsResponseResetStart = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_RESPONSE_RESET,
});

export const getAllRoleDetailsResponseChanged = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_RESPONSE_CHANGED,
});

/** ***************** GET ALL_ROLE DETAILS END *********************** */

/** ***************** GET QUESTIONNAIRE DETAILS START *********************** */
export const getAllQuestionnaireDetailsStart = (getData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_START,
	payload: getData,
});

export const getAllQuestionnaireDetailsSuccess = (successData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_SUCCESS,
	payload: successData,
});

export const getAllQuestionnaireDetailsFailure = (failureData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_FAILURE,
	payload: failureData,
});

export const getAllQuestionnaireDetailsResponseResetStart = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_RESPONSE_RESET,
});

export const getAllQuestionnaireDetailsResponseChanged = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_RESPONSE_CHANGED,
});

/** ***************** GET QUESTIONNAIRE DETAILS END *********************** */

/** ***************** ADD CREDIT APPRAISAL DETAILS START *********************** */
export const addCreditAppraisalDetailsStart = (addData) => ({
	type: WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_START,
	payload: addData,
});

export const addCreditAppraisalDetailsSuccess = (successData) => ({
	type: WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_SUCCESS,
	payload: successData,
});

export const addCreditAppraisalDetailsFailure = (failureData) => ({
	type: WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_FAILURE,
	payload: failureData,
});

export const addCreditAppraisalDetailsResponseResetStart = () => ({
	type: WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET,
});

export const addCreditAppraisalDetailsResponseChanged = () => ({
	type: WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD CREDIT APPRAISAL DETAILS END *********************** */

/** ***************** GET ALL_CREDIT_APPRAISAL DETAILS START *********************** */
export const getAllCreditAppraisalDetailsStart = (getData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_START,
	payload: getData,
});

export const getAllCreditAppraisalDetailsSuccess = (successData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_SUCCESS,
	payload: successData,
});

export const getAllCreditAppraisalDetailsFailure = (failureData) => ({
	type: WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_FAILURE,
	payload: failureData,
});

export const getAllCreditAppraisalDetailsResponseResetStart = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_RESET,
});

export const getAllCreditAppraisalDetailsResponseChanged = () => ({
	type: WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_CHANGED,
});

/** ***************** GET ALL_CREDIT_APPRAISAL DETAILS END *********************** */

/** ***************** DELETE CREDIT APPRAISAL DETAILS START *********************** */
export const deleteCreditAppraisalDetailsStart = (addData) => ({
	type: WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_START,
	payload: addData,
});

export const deleteCreditAppraisalDetailsSuccess = (successData) => ({
	type: WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteCreditAppraisalDetailsFailure = (failureData) => ({
	type: WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteCreditAppraisalDetailsResponseResetStart = () => ({
	type: WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET,
});

export const deleteCreditAppraisalDetailsResponseChanged = () => ({
	type: WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE CREDIT APPRAISAL DETAILS END *********************** */
