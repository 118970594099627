/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */
/** *** COllection Approval Page All the actions declared over here **** */
/** *** Version : 2.0.1 **** */
/** *** Author : Sathish **** */
/** *** LastEdit : Sathish **** */
/** *** Last Added : Collection Approve - Allocate for EOD fetch API **** */
/** *** Version : 2.0.2 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Common Collection Update Action added **** */
/** *** Version : 2.0.3 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Check Branch EOD Action added **** */

import collectionActionTypes from './collection.types';

/** ***************** GET BRANCH OFFICER CENTERS LIST START *********************** */
export const fetchOfficerListCentersStart = (getData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_START,
	payload: getData,
});

export const getOfficerListCentersSuccess = (successData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_SUCCESS,
	payload: successData,
});

export const getOfficerListCentersFailure = (failureData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_FAILURE,
	payload: failureData,
});

export const fetchOfficerListCentersResponseResetStart = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_RESET,
});

export const getOfficerListCentersResponseChanged = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER CENTERS LIST END *********************** */

/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST START *********************** */
export const fetchOfficerListArrearCentersStart = (getData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_START,
	payload: getData,
});

export const getOfficerListArrearCentersSuccess = (successData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS,
	payload: successData,
});

export const getOfficerListArrearCentersFailure = (failureData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE,
	payload: failureData,
});

export const fetchOfficerListArrearCentersResponseResetStart = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET,
});

export const getOfficerListArrearCentersResponseChanged = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST END *********************** */

/** ***************** GET BRANCH COLLECTION USERS LIST START *********************** */
export const fetchBranchCollectionUsersListStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
	payload: getData,
});

export const getBranchCollectionUsersListSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS,
	payload: successData,
});

export const getBranchCollectionUsersListFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchCollectionUsersListResponseResetStart = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
});

export const getBranchCollectionUsersListResponseChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST END *********************** */

/** ***************** GET CENTER LIST OFFICER COLLECTIONS START *********************** */
export const fetchCenterListOfficerCollectionsStart = (getData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_START,
	payload: getData,
});

export const getCenterListOfficerCollectionsSuccess = (successData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS,
	payload: successData,
});

export const getCenterListOfficerCollectionsFailure = (failureData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE,
	payload: failureData,
});

export const fetchCenterListOfficerCollectionsResponseResetStart = () => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET,
});

export const getCenterListOfficerCollectionsResponseChanged = () => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED,
});
/** ***************** GET CENTER LIST OFFICER COLLECTIONS END *********************** */

/** ***************** GET BRANCH TODAY COLLECTION START *********************** */
export const fetchBranchTodayCollectionDetailsStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_START,
	payload: getData,
});

export const getBranchTodayCollectionDetailsSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchTodayCollectionDetailsFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchBranchTodayCollectionDetailsReset = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_RESET,
});

export const getBranchTodayCollectionDetailsChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_CHANGED,
});
/** ***************** GET BRANCH TODAY COLLECTION END *********************** */

/** ***************** GET BRANCH TODAY DEMAND MOVE TO ARREAR START *********************** */
export const fetchBranchTodayDemandMoveToArrearStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_START,
	payload: getData,
});

export const getBranchTodayDemandMoveToArrearSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_SUCCESS,
	payload: successData,
});

export const getBranchTodayDemandMoveToArrearFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchTodayDemandMoveToArrearReset = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_RESET,
});

export const getBranchTodayDemandMoveToArrearChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_CHANGED,
});
/** ***************** GET BRANCH TODAY DEMAND MOVE TO ARREAR  END *********************** */

/** ***************** GET BRANCH CLOSED LOAN LIST START *********************** */
export const fetchBranchClosedLoanListStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_START,
	payload: getData,
});

export const getBranchClosedLoanListSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getBranchClosedLoanListFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchClosedLoanListReset = () => ({
	type: collectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_RESET,
});

export const getBranchClosedLoanListChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_CHANGED,
});
/** ***************** GET BRANCH CLOSED LOAN LIST END *********************** */

/** ***************** GET CENTER COLLECTION DENOMINATION START *********************** */
export const fetchCenterCollectionDenominationStart = (getData) => ({
	type: collectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_START,
	payload: getData,
});

export const getCenterCollectionDenominationSuccess = (successData) => ({
	type: collectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_SUCCESS,
	payload: successData,
});

export const getCenterCollectionDenominationFailure = (failureData) => ({
	type: collectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchCenterCollectionDenominationReset = () => ({
	type: collectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_RESET,
});

export const getCenterCollectionDenominationChanged = () => ({
	type: collectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_CHANGED,
});
/** ***************** GET CENTER COLLECTION DENOMINATION END *********************** */

/** ***************** GET CENTER COLLECTION DENOMINATION START *********************** */
export const fetchAttendanceOfficerCollectionStart = (getData) => ({
	type: collectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_START,
	payload: getData,
});

export const getAttendanceOfficerCollectionSuccess = (successData) => ({
	type: collectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_SUCCESS,
	payload: successData,
});

export const getAttendanceOfficerCollectionFailure = (failureData) => ({
	type: collectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_FAILURE,
	payload: failureData,
});

export const fetchAttendanceOfficerCollectionReset = () => ({
	type: collectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_RESET,
});

export const getAttendanceOfficerCollectionChanged = () => ({
	type: collectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_CHANGED,
});
/** ***************** GET CENTER COLLECTION DENOMINATION END *********************** */

/** ***************** GET BRANCH COLLECTION FUNDER ALLOCATE EOD START *********************** */
export const fetchBranchCollectionFunderAllocateEODStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_START,
	payload: getData,
});

export const getBranchCollectionFunderAllocateEODSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_SUCCESS,
	payload: successData,
});

export const getBranchCollectionFunderAllocateEODFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_FAILURE,
	payload: failureData,
});

export const fetchBranchCollectionFunderAllocateEODReset = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_RESET,
});

export const getBranchCollectionFunderAllocateEODChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED,
});

export const getBranchCollectionFunderAllocateEODChangedData = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED_DATA,
});
/** ***************** GET BRANCH COLLECTION FUNDER ALLOCATE EOD END *********************** */

/** ***************** GET BRANCH TODAY COLLECTIONS YET TO APPROVE START *********************** */
export const fetchBranchTodayCollectionsYetToApproveStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_START,
	payload: getData,
});

export const getBranchTodayCollectionsYetToApproveSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_SUCCESS,
	payload: successData,
});

export const getBranchTodayCollectionsYetToApproveFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_FAILURE,
	payload: failureData,
});

export const fetchBranchTodayCollectionsYetToApproveReset = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_RESET,
});

export const getBranchTodayCollectionsYetToApproveChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED,
});

export const getBranchTodayCollectionsYetToApproveChangedData = () => ({
	type: collectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED_DATA,
});
/** ***************** GET BRANCH TODAY COLLECTIONS YET TO APPROVE END *********************** */

/** ***************** UPDATE LOAN PAID PER MEMBER COLLECTION APPROVE START *********************** */
export const updateLoanPaidPerMemberCollectionApproveStart = (getData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_START,
	payload: getData,
});

export const updateLoanPaidPerMemberCollectionApproveSuccess = (successData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_SUCCESS,
	payload: successData,
});

export const updateLoanPaidPerMemberCollectionApproveFailure = (failureData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_FAILURE,
	payload: failureData,
});

export const updateLoanPaidPerMemberCollectionApproveResponseReset = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_RESPONSE_RESET,
});

export const updateLoanPaidPerMemberCollectionApproveResponseChanged = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_CHANGED,
});
/** ***************** UPDATE LOAN PAID PER MEMBER COLLECTION APPROVE END *********************** */

/** ***************** UPDATE LOAN PAID OVERALL COLLECTION APPROVE START *********************** */
export const updateLoanPaidOverallCollectionApproveStart = (getData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_START,
	payload: getData,
});

export const updateLoanPaidOverallCollectionApproveSuccess = (successData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_SUCCESS,
	payload: successData,
});

export const updateLoanPaidOverallCollectionApproveFailure = (failureData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_FAILURE,
	payload: failureData,
});

export const updateLoanPaidOverallCollectionApproveResponseReset = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_RESPONSE_RESET,
});

export const updateLoanPaidOverallCollectionApproveResponseChanged = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_CHANGED,
});
/** ***************** UPDATE LOAN PAID OVERALL COLLECTION APPROVE END *********************** */

/** ***************** UPDATE LOAN PAID COMMON COLLECTION START *********************** */
export const commonCollectionUpdateStart = (getData) => ({
	type: collectionActionTypes.COMMON_COLLECTION_UPDATE_START,
	payload: getData,
});

export const commonCollectionUpdateSuccess = (successData) => ({
	type: collectionActionTypes.COMMON_COLLECTION_UPDATE_SUCCESS,
	payload: successData,
});

export const commonCollectionUpdateFailure = (failureData) => ({
	type: collectionActionTypes.COMMON_COLLECTION_UPDATE_FAILURE,
	payload: failureData,
});

export const commonCollectionUpdateResponseReset = () => ({
	type: collectionActionTypes.COMMON_COLLECTION_UPDATE_RESPONSE_RESET,
});

export const commonCollectionUpdateResponseChanged = () => ({
	type: collectionActionTypes.COMMON_COLLECTION_UPDATE_CHANGED,
});
/** ***************** UPDATE LOAN PAID COMMON COLLECTION END *********************** */

/** ***************** CHECK EOD EXIST START *********************** */
export const checkBranchEODExistStart = (getData) => ({
	type: collectionActionTypes.CHECK_BRANCH_EOD_EXIST_START,
	payload: getData,
});

export const checkBranchEODExistSuccess = (successData) => ({
	type: collectionActionTypes.CHECK_BRANCH_EOD_EXIST_SUCCESS,
	payload: successData,
});

export const checkBranchEODExistFailure = (failureData) => ({
	type: collectionActionTypes.CHECK_BRANCH_EOD_EXIST_FAILURE,
	payload: failureData,
});

export const checkBranchEODExistResponseReset = () => ({
	type: collectionActionTypes.CHECK_BRANCH_EOD_EXIST_RESPONSE_RESET,
});

export const checkBranchEODExistResponseChanged = () => ({
	type: collectionActionTypes.CHECK_BRANCH_EOD_EXIST_CHANGED,
});
/** ***************** CHECK EOD EXIST END *********************** */

/** ***************** UPDATE LOAN PAID OVERALL MOVE TO ARREAR START *********************** */
export const updateLoanPaidOverAllMoveToArrearStart = (getData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_START,
	payload: getData,
});

export const updateLoanPaidOverAllMoveToArrearSuccess = (successData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_SUCCESS,
	payload: successData,
});

export const updateLoanPaidOverAllMoveToArrearFailure = (failureData) => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_FAILURE,
	payload: failureData,
});

export const updateLoanPaidOverAllMoveToArrearResponseReset = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_RESPONSE_RESET,
});

export const updateLoanPaidOverAllMoveToArrearResponseChanged = () => ({
	type: collectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_CHANGED,
});
/** ***************** UPDATE LOAN PAID OVERALL MOVE TO ARREAR END *********************** */

/** ***************** UPDATE LOAN PAID OVERALL ACCRUED INTEREST ALLOCATE FOR EOD START *********************** */
export const updateOverallAccruedInterestAllocateForEODStart = (getData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_START,
	payload: getData,
});

export const updateOverallAccruedInterestAllocateForEODSuccess = (successData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_SUCCESS,
	payload: successData,
});

export const updateOverallAccruedInterestAllocateForEODFailure = (failureData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_FAILURE,
	payload: failureData,
});

export const updateOverallAccruedInterestAllocateForEODResponseReset = () => ({
	type: collectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_RESPONSE_RESET,
});

export const updateOverallAccruedInterestAllocateForEODResponseChanged = () => ({
	type: collectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_CHANGED,
});
/** ***************** UPDATE LOAN PAID OVERALL ACCRUED INTEREST ALLOCATE FOR EOD END *********************** */

/** ***************** UPDATE LOAN PAID OVERALL ALLOCATE FOR EOD START *********************** */
export const updateOverallAllocateForEODStart = (getData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_START,
	payload: getData,
});

export const updateOverallAllocateForEODSuccess = (successData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_SUCCESS,
	payload: successData,
});

export const updateOverallAllocateForEODFailure = (failureData) => ({
	type: collectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_FAILURE,
	payload: failureData,
});

export const updateOverallAllocateForEODResponseReset = () => ({
	type: collectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_RESPONSE_RESET,
});

export const updateOverallAllocateForEODResponseChanged = () => ({
	type: collectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_CHANGED,
});
/** ***************** UPDATE LOAN PAID OVERALL ALLOCATE FOR EOD END *********************** */
