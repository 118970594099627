/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import FamilyActionType from './family-details.types';

export const familyMemberCountDataStart = (saveData) => ({
	type: FamilyActionType.FAMILY_MEMBER_COUNT_CHANGED,
	payload: saveData,
});

export const addFamilyMemberStart = (saveData) => ({
	type: FamilyActionType.ADD_FAMILY_MEMBER_START,
	payload: saveData,
});

export const removeFamilyMemberStart = (saveData) => ({
	type: FamilyActionType.REMOVE_FAMILY_MEMBER_START,
	payload: saveData,
});

// START FAMILY INCOME
export const familyIncomeStart = (familyIncomeAddCredentials) => ({
	type: FamilyActionType.FAMILY_MEMBER_INCOME_START,
	payload: familyIncomeAddCredentials,
});

export const familyIncomeSuccess = (familyIncomeAddSuccess) => ({
	type: FamilyActionType.FAMILY_MEMBER_INCOME_SUCCESS,
	payload: familyIncomeAddSuccess,
});

export const familyIncomeFailure = (familyIncomeAddFailure) => ({
	type: FamilyActionType.FAMILY_MEMBER_INCOME_FAILURE,
	payload: familyIncomeAddFailure,
});

export const familyIncomeResponseResetStart = () => ({
	type: FamilyActionType.FAMILY_MEMBER_INCOME_RESPONSE_REST_START,
});

export const familyIncomeUserResponseChanged = () => ({
	type: FamilyActionType.FAMILY_MEMBER_INCOME_RESPONSE_CHANGED,
});
// END FAMILY INCOME

export const setFamilyArrStart = (familyArr) => ({
	type: FamilyActionType.SET_FAMILY_MEMBER_ARR_START,
	payload: familyArr,
});

// START FAMILY FETCH
export const fetchFamilyMemberStart = (familyCredentials) => ({
	type: FamilyActionType.FETCH_FAMILY_MEMBERS_START,
	payload: familyCredentials,
});

export const fetchFamilyMemberSuccess = (familySuccess) => ({
	type: FamilyActionType.FETCH_FAMILY_MEMBERS_SUCCESS,
	payload: familySuccess,
});

export const fetchFamilyMemberFailure = (familyFailure) => ({
	type: FamilyActionType.FETCH_FAMILY_MEMBERS_FAILURE,
	payload: familyFailure,
});

export const fetchFamilyMemberResponseResetStart = () => ({
	type: FamilyActionType.FETCH_FAMILY_MEMBERS_RESPONSE_REST_START,
});

export const fetchFamilyMemberUserResponseChanged = () => ({
	type: FamilyActionType.FETCH_FAMILY_MEMBERS_RESPONSE_CHANGED,
});
// END FAMILY FETCH

// START FAMILY ADD
export const familyMemberAddStart = (familyCredentials) => ({
	type: FamilyActionType.ADD_FAMILY_MEMBERS_START,
	payload: familyCredentials,
});

export const familyMemberAddSuccess = (familySuccess) => ({
	type: FamilyActionType.ADD_FAMILY_MEMBERS_SUCCESS,
	payload: familySuccess,
});

export const familyMemberAddFailure = (familyFailure) => ({
	type: FamilyActionType.ADD_FAMILY_MEMBERS_FAILURE,
	payload: familyFailure,
});

export const familyMemberAddResponseResetStart = () => ({
	type: FamilyActionType.ADD_FAMILY_MEMBERS_RESPONSE_REST_START,
});

export const familyMemberAddResponseChanged = () => ({
	type: FamilyActionType.ADD_FAMILY_MEMBERS_RESPONSE_CHANGED,
});
// END FAMILY ADD

// START FETCH PARTICULAR FAMILY MEMBER
export const fetchParticularFamMemberStart = (familyCredentials) => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_START,
	payload: familyCredentials,
});

export const fetchParticularFamSuccess = (familySuccess) => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_SUCCESS,
	payload: familySuccess,
});

export const fetchParticularFamFailure = (familyFailure) => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_FAILURE,
	payload: familyFailure,
});

export const fetchParticularFamResponseResetStart = () => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_REST_START,
});

export const fetchParticularFamObjResetStart = () => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_OBJ_RESET,
});

export const fetchParticularFamResponseChanged = () => ({
	type: FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_CHANGED,
});
// END FETCH PARTICULAR FAMILY MEMBER

// START DELETE PARTICULAR FAMILY MEMBER
export const deleteFamilyMemberStart = (familyCredentials) => ({
	type: FamilyActionType.DELETE_FAMILY_MEMBERS_START,
	payload: familyCredentials,
});

export const deleteFamilySuccess = (familySuccess) => ({
	type: FamilyActionType.DELETE_FAMILY_MEMBERS_SUCCESS,
	payload: familySuccess,
});

export const deleteFamilyFailure = (familyFailure) => ({
	type: FamilyActionType.DELETE_FAMILY_MEMBERS_FAILURE,
	payload: familyFailure,
});

export const deleteFamilyResponseResetStart = () => ({
	type: FamilyActionType.DELETE_FAMILY_MEMBERS_RESPONSE_REST_START,
});

export const deleteFamilyResponseChanged = () => ({
	type: FamilyActionType.DELETE_FAMILY_MEMBERS_RESPONSE_CHANGED,
});
// END DELETE PARTICULAR FAMILY MEMBER

// TO VERIFY AADHAR OTP DETAILS
export const verifyFamilyAadharOtpStart = (DetailsCredentials) => ({
	type: FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyFamilyAadharOtpSuccess = (DetailsSuccess) => ({
	type: FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyFamilyAadharOtpFailure = (DetailsFailure) => ({
	type: FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyFamilyAadharOtpResponseResetStart = () => ({
	type: FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyFamilyAadharOtpResponseChanged = () => ({
	type: FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR VALIDATE OTP DETAILS
export const verifyValidateFamilyAadharOtpStart = (DetailsCredentials) => ({
	type: FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyValidateFamilyAadharOtpSuccess = (DetailsSuccess) => ({
	type: FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyValidateFamilyAadharOtpFailure = (DetailsFailure) => ({
	type: FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyValidateFamilyAadharOtpResponseResetStart = () => ({
	type: FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyValidateFamilyAadharOtpResponseChanged = () => ({
	type: FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_CHANGED,
});
