/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProductActionTypes from './product.types';
import {
	productDetailsAdd,
	productDetailsUpdate,
	productListGet,
	productDetailsFetch,
	customProductDetailsFetch,
	productDetailsDelete,
	getMasterAccountList,
	getAllInstrumentList,
	filterProductListGet,
} from './product.service';

import {
	addProductDetailsSuccess,
	addProductDetailsFailure,
	addProductDetailsResponseChanged,
	updateProductDetailsSuccess,
	updateProductDetailsFailure,
	updateProductDetailsResponseChanged,
	getProductListSuccess,
	getProductListFailure,
	getProductListResponseChanged,
	fetchProductDetailsSuccess,
	fetchProductDetailsFailure,
	fetchProductDetailsResponseChanged,
	fetchCustomProductDetailsSuccess,
	fetchCustomProductDetailsFailure,
	fetchCustomProductDetailsResponseChanged,
	deleteProductDetailsSuccess,
	deleteProductDetailsFailure,
	deleteProductDetailsResponseChanged,
	getMasterAccountListSuccess,
	getMasterAccountListFailure,
	getMasterAccountListResponseChanged,
	getAllInstrumnetListSuccess,
	getAllInstrumnetListFailure,
	getAllInstrumnetListResponseChanged,
	fetchFilterProductListSuccess,
	fetchFilterProductListFailure,
	fetchFilterProductListResponseChanged,
} from './product.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PRODUCT DETAILS  ******************** */
export function* createProduct({ payload }) {
	try {
		const key = 'AddProductDetailKey';
		const loanschedulelistJson = JSON.stringify(payload.loanschedulelist).replace(/"/g, "'");
		const getGoldInterestListJson = JSON.stringify(payload.getGoldInterestListArr).replace(
			/"/g,
			"'",
		);
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			ProductName: payload.productName,
			NoOfHolidays: payload.noOfHolidays,
			ProductAmount: payload.productAmount,
			ProductROI: payload.productROI,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			minProductAmount: payload.minProductAmount,
			maxProductAmount: payload.maxProductAmount,
			minNumberOfInstalment: payload.minNumberOfInstalment,
			maxNumberOfInstalment: payload.maxNumberOfInstalment,
			minProductInterest: payload.minProductInterest,
			maxProductInterest: payload.maxProductInterest,
			PrincipalType: payload.principalType,
			InterestPayType: payload.interestPayType,
			InsuranceCompany: payload.insuranceCompany,
			WhichWeek: payload.whichWeek,
			IsPayAsYouGo: payload.isPayAsYouGo ? 1 : 0,
			IsPercentagePenalty: payload.isPercentagePenalty ? 1 : 0,
			IsDaywisePenalty: payload.isDayWisePenalty ? 1 : 0,
			DayPenalty: payload.dayPenalty,
			goldProductFixedInterest: payload.fixedInterest ? 1 : 0,
			IsAdjustInterestBasedOnDiffDays: payload.isAdjustInterestBasedOnDiffDays ? 1 : 0,
			IsAdjustToUpperRoundOffValue: payload.isRoundOffToUpperValue ? 1 : 0,
			IsProcessingFeeAutoDetect: payload.isProcessingFeeAutoDetect ? 1 : 0,
			EffectiveDate: payload.effectiveDate,
			InActiveDate: payload.inActiveDate,
			IsPreCloseCharge: payload.isPreCloseCharge ? 1 : 0,
			preClosePercentage: payload.preCloseChargePercentage,
			NeedDifferentialPayout: payload.needDifferentialPayout ? 1 : 0,
			IsCollectInterestOnDisbusement: payload.isCollectInterestOnDisbusement ? 1 : 0,
			IncludeSaturday: payload.isIncludeSaturday ? 1 : 0,
			IncludeSunday: payload.isIncludeSunday ? 1 : 0,
			InstalmentType: payload.productInstallmentType,
			IsCorporateProduct: payload.isCorporateProduct ? 1 : 0,
			PercentageOnSellingPrice: payload.percentageOnSellingPrice,
			GoldMinimumInterestDays: payload.minimumInterestDays,
			ordinaryDate: payload.ordinaryDays,
			ordinaryAmount: payload.ordinaryAmount,
			registerDate: payload.registerDays,
			registerAmount: payload.registerAmount,
			auctionDate: payload.auctionDays,
			auctionAmount: payload.auctionAmount,
			ProductDescription: payload.productDescription,
			MemberAmount: payload.memberAmount,
			ProductPrincipal: payload.productPrincipal,
			ProductInterest: payload.interestPercentage,
			NoOfYears: payload.noOfYear,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.productInterestType,
			HolidayType: payload.holidayType,
			ProductCreatedBy: payload.userID,
			UniqueProductID: payload.uniqueProductID,
			DocumentCharges: payload.docCharges,
			ProcessingFees: payload.processingFee,
			ProcessingFeesPercentage: payload.processingFeePercentage,
			CGST: payload.cgst,
			CGSTPercentage: payload.cgstPercentage,
			IGST: payload.igst,
			IGSTPercentage: payload.igstPercentage,
			SGST: payload.sgst,
			SGSTPercentage: payload.sgstPercentage,
			ProductType: payload.productType,
			IsPenalty: payload.isPenalty ? 1 : 0,
			PenaltyPercentage: payload.PenaltyPercentage,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insuranceFee,
			InsurenseFeePercentage: payload.insuranceFeePercentage,
			Noofstartday: payload.noOfDaysToStartDisbursement,
			IsFirstWeekMonthly: payload.isFirstWeekMonthly ? 1 : 0,
			IsInstalmentBasedCollection: payload.isInstallmentBasedCollection ? 1 : 0,
			AssetType: payload.assetType,
			NoofDueInterval: payload.noofDueInterval,
			IsSameDueDate: payload.isSameDateAsDue ? 1 : 0,
			IsInterestBasedOnStartDate: payload.intersetBasedOnStartDate ? 1 : 0,
			IsFixedEMI: payload.isFixedEMI ? 1 : 0,
			FixedEMIAmount: payload.FixedEMIAmount,
			IsBrokenPeriodNeeded: payload.isBrokenPeriodNeeded ? 1 : 0,
			BrokenPeriodDays: payload.brokenPeriodDays,
			goldProductInterestArr: getGoldInterestListJson,
			interestType:
				payload.variedInterest && payload.getGoldInterestListArr.length > 0 ? 1 : 0,
			PrincipalAmountLedger: payload.principalAmountLedger,
			InterestAmountLedger: payload.interestAmountLedger,
			LoanAmountLedger: payload.loanAmountLedger,
			PenaltyAmountLedger: payload.penaltyAmountLedger,
			BMAdvanceAmountLedger: payload.bmAdvanceAmountLedger,
			WriteOffLedger: payload.writeOfftAmountLedger,
			InterestWriteOffLedger: payload.interestWriteOffAmountLedger,
			InterestAccuralLedger: payload.interestAccuralLedger,
			DisbursementType: payload.disbursementType,
			ChequeToBeCollected: payload.chequeToBeCollected,
			InsuranceProductLedger: payload.insuranceProductLedger,
			NoOfCheque: payload.noOfCheque,
			Membersettlement: payload.memberSettlement,
			MemberAmountLedger: payload.memberAmountLedger,
			DocumentChargesLedger: payload.documentChargesLedger,
			ProcessingFeeLedger: payload.processingFeeLedger,
			IGSTAmountLedger: payload.igstAmountLedger,
			CGSTAmountLedger: payload.cgstAmountLedger,
			SGSTAmountLedger: payload.sgstAmountLedger,
			VerificationFeeLedger: payload.verificationFeeLedger,
			HPInsuranceProductLedger: payload.hpInsuranceProductLedger,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			LoanDataforDiminishing: loanschedulelistJson,
			IsGoldProduct: payload.isGoldProduct,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductDetailsFailure(responseData.data));
		} else {
			yield put(addProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductDetailsFailure(error));
	}
}

export function* resetAddProductResponse() {
	yield put(addProductDetailsResponseChanged());
}

export function* onCreateProductDetails() {
	yield takeLatest(ProductActionTypes.ADD_PRODUCT_DETAILS_START, createProduct);
}

export function* onCreateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_RESET,
		resetAddProductResponse,
	);
}
/** *********************  END ADD PRODUCT DETAILS ******************** */

/** *********************  START UPDATE PRODUCT DETAILS  ******************** */
export function* productUpdate({ payload }) {
	try {
		const key = 'UpdateProductDetailsKey';
		let loanschedulelistJson = '';
		// eslint-disable-next-line eqeqeq
		if (payload.loanschedulelist != undefined){
			loanschedulelistJson = JSON.stringify(payload.loanschedulelist).replace(/"/g, "'");
		}
		let getGoldInterestListJson = '';
		// eslint-disable-next-line eqeqeq
		if (payload.getGoldInterestListArr != undefined){
			getGoldInterestListJson = JSON.stringify(payload.getGoldInterestListArr).replace(
				/"/g,
				"'",
			);
		}
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			ProductName: payload.productName,
			NoOfHolidays: payload.noOfHolidays,
			ProductAmount: payload.productAmount,
			ProductROI: payload.productROI,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			minProductAmount: payload.minProductAmount,
			maxProductAmount: payload.maxProductAmount,
			minNumberOfInstalment: payload.minNumberOfInstalment,
			maxNumberOfInstalment: payload.maxNumberOfInstalment,
			minProductInterest: payload.minProductInterest,
			maxProductInterest: payload.maxProductInterest,
			PrincipalType: payload.principalType,
			InterestPayType: payload.interestPayType,
			InsuranceCompany: payload.insuranceCompany,
			WhichWeek: payload.whichWeek,
			IsPayAsYouGo: payload.isPayAsYouGo ? 1 : 0,
			IsPercentagePenalty: payload.isPercentagePenalty ? 1 : 0,
			IsDaywisePenalty: payload.isDayWisePenalty ? 1 : 0,
			DayPenalty: payload.dayPenalty,
			goldProductFixedInterest: payload.fixedInterest ? 1 : 0,
			IsAdjustInterestBasedOnDiffDays: payload.isAdjustInterestBasedOnDiffDays ? 1 : 0,
			IsAdjustToUpperRoundOffValue: payload.isRoundOffToUpperValue ? 1 : 0,
			IsProcessingFeeAutoDetect: payload.isProcessingFeeAutoDetect ? 1 : 0,
			EffectiveDate: payload.effectiveDate,
			InActiveDate: payload.inActiveDate,
			IsPreCloseCharge: payload.isPreCloseCharge ? 1 : 0,
			preClosePercentage: payload.preCloseChargePercentage,
			NeedDifferentialPayout: payload.needDifferentialPayout ? 1 : 0,
			IsCollectInterestOnDisbusement: payload.isCollectInterestOnDisbusement ? 1 : 0,
			IncludeSaturday: payload.isIncludeSaturday ? 1 : 0,
			IncludeSunday: payload.isIncludeSunday ? 1 : 0,
			InstalmentType: payload.productInstallmentType,
			IsCorporateProduct: payload.isCorporateProduct ? 1 : 0,
			PercentageOnSellingPrice: payload.percentageOnSellingPrice,
			GoldMinimumInterestDays: payload.minimumInterestDays,
			ordinaryDate: payload.ordinaryDays,
			ordinaryAmount: payload.ordinaryAmount,
			registerDate: payload.registerDays,
			registerAmount: payload.registerAmount,
			auctionDate: payload.auctionDays,
			auctionAmount: payload.auctionAmount,
			ProductDescription: payload.productDescription,
			MemberAmount: payload.memberAmount,
			ProductPrincipal: payload.productPrincipal,
			ProductInterest: payload.interestPercentage,
			NoOfYears: payload.noOfYear,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.productInterestType,
			HolidayType: payload.holidayType,
			ProductCreatedBy: payload.userID,
			UniqueProductID: payload.uniqueProductID,
			DocumentCharges: payload.docCharges,
			ProcessingFees: payload.processingFee,
			ProcessingFeesPercentage: payload.processingFeePercentage,
			CGST: payload.cgst,
			CGSTPercentage: payload.cgstPercentage,
			IGST: payload.igst,
			IGSTPercentage: payload.igstPercentage,
			SGST: payload.sgst,
			SGSTPercentage: payload.sgstPercentage,
			ProductType: payload.productType,
			IsPenalty: payload.isPenalty ? 1 : 0,
			PenaltyPercentage: payload.PenaltyPercentage,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insuranceFee,
			InsurenseFeePercentage: payload.insuranceFeePercentage,
			Noofstartday: payload.noOfDaysToStartDisbursement,
			IsFirstWeekMonthly: payload.isFirstWeekMonthly ? 1 : 0,
			IsInstalmentBasedCollection: payload.isInstallmentBasedCollection ? 1 : 0,
			AssetType: payload.assetType,
			NoofDueInterval: payload.noofDueInterval,
			IsSameDueDate: payload.isSameDateAsDue ? 1 : 0,
			IsInterestBasedOnStartDate: payload.intersetBasedOnStartDate ? 1 : 0,
			IsFixedEMI: payload.isFixedEMI ? 1 : 0,
			FixedEMIAmount: payload.FixedEMIAmount,
			IsBrokenPeriodNeeded: payload.isBrokenPeriodNeeded ? 1 : 0,
			BrokenPeriodDays: payload.brokenPeriodDays,
			goldProductInterestArr: getGoldInterestListJson,
			interestType:
				payload.variedInterest && payload.getGoldInterestListArr.length > 0 ? 1 : 0,
			PrincipalAmountLedger: payload.principalAmountLedger,
			InterestAmountLedger: payload.interestAmountLedger,
			LoanAmountLedger: payload.loanAmountLedger,
			PenaltyAmountLedger: payload.penaltyAmountLedger,
			BMAdvanceAmountLedger: payload.bmAdvanceAmountLedger,
			WriteOffLedger: payload.writeOfftAmountLedger,
			InterestWriteOffLedger: payload.interestWriteOffAmountLedger,
			InterestAccuralLedger: payload.interestAccuralLedger,
			DisbursementType: payload.disbursementType,
			ChequeToBeCollected: payload.chequeToBeCollected,
			InsuranceProductLedger: payload.insuranceProductLedger,
			NoOfCheque: payload.noOfCheque,
			Membersettlement: payload.memberSettlement,
			MemberAmountLedger: payload.memberAmountLedger,
			DocumentChargesLedger: payload.documentChargesLedger,
			ProcessingFeeLedger: payload.processingFeeLedger,
			IGSTAmountLedger: payload.igstAmountLedger,
			CGSTAmountLedger: payload.cgstAmountLedger,
			SGSTAmountLedger: payload.sgstAmountLedger,
			VerificationFeeLedger: payload.verificationFeeLedger,
			HPInsuranceProductLedger: payload.hpInsuranceProductLedger,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			LoanDataforDiminishing: loanschedulelistJson,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductDetailsFailure(responseData.data));
		} else {
			yield put(updateProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductDetailsFailure(error));
	}
}

export function* resetUpdateProductDetailsResponse() {
	yield put(updateProductDetailsResponseChanged());
}

export function* onUpdateProductDetails() {
	yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_DETAILS_START, productUpdate);
}

export function* onUpdateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetUpdateProductDetailsResponse,
	);
}
/** *********************  END UPDATE PRODUCT DETAILS ******************** */

/** *********************  START GET PRODUCT LIST  ******************** */
export function* productDetailsListGet({ payload }) {
	try {
		const key = 'GetProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductListFailure(responseData.data));
		} else {
			yield put(getProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductListFailure(error));
	}
}

export function* resetGetProductListResponse() {
	yield put(getProductListResponseChanged());
}

export function* onGetProductListDetails() {
	yield takeLatest(ProductActionTypes.GET_PRODUCT_LIST_START, productDetailsListGet);
}

export function* onGetProductListResponseReset() {
	yield takeLatest(
		ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
		resetGetProductListResponse,
	);
}
/** *********************  END GET PRODUCT LIST ******************** */

/** *********************  START FETCH PRODUCT DETAILS  ******************** */
export function* fetchProductData({ payload }) {
	try {
		const key = 'FetchProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductDetailsFailure(error));
	}
}

/** *********************  START FETCH CUSTOM PRODUCT DETAILS  ******************** */
export function* fetchCustomProductData({ payload }) {
	try {
		const key = 'GetCustomProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(customProductDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCustomProductDetailsFailure(responseData.data));
		} else {
			yield put(fetchCustomProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCustomProductDetailsFailure(error));
	}
}

export function* resetFetchProductDetailsResponse() {
	yield put(fetchProductDetailsResponseChanged());
}

export function* resetFetchCustomProductDetailsResponse() {
	yield put(fetchCustomProductDetailsResponseChanged());
}

export function* onFetchProductDetails() {
	yield takeLatest(ProductActionTypes.FETCH_PRODUCT_DETAILS_START, fetchProductData);
}

export function* onFetchCustomProductDetails() {
	yield takeLatest(ProductActionTypes.FETCH_CUSTOM_PRODUCT_DETAILS_START, fetchCustomProductData);
}

export function* onFetchCustomProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_CUSTOM_PRODUCT_DETAILS_RESPONSE_RESET,
		resetFetchCustomProductDetailsResponse,
	);
}

export function* onFetchProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET,
		resetFetchProductDetailsResponse,
	);
}
/** *********************  END FETCH PRODUCT DETAILS ******************** */

/** *********************  START DELETE PRODUCT DETAILS  ******************** */
export function* productDelete({ payload }) {
	try {
		const key = 'DeleteProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			IsView: payload.isView,
			ProductName: payload.productName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteProductDetailsFailure(responseData.data));
		} else {
			yield put(deleteProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteProductDetailsFailure(error));
	}
}

export function* resetDeleteProductDetailsResponse() {
	yield put(deleteProductDetailsResponseChanged());
}

export function* onDeleteProductDetail() {
	yield takeLatest(ProductActionTypes.DELETE_PRODUCT_DETAILS_START, productDelete);
}

export function* onDeleteProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetDeleteProductDetailsResponse,
	);
}
/** *********************  END DELETE PRODUCT DETAILS ******************** */

/** *********************  START GET MASTER ACCOUNT LIST  ******************** */
export function* masterAccountListGet({ payload }) {
	try {
		const key = 'GetMasterAccountListKey';
		const jsonData = {
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getMasterAccountList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMasterAccountListFailure(responseData.data));
		} else {
			yield put(getMasterAccountListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMasterAccountListFailure(error));
	}
}

export function* resetGetMasterAccountListResponse() {
	yield put(getMasterAccountListResponseChanged());
}

export function* onGetMasterAccountListDetails() {
	yield takeLatest(ProductActionTypes.GET_MASTER_ACCOUNT_LIST_START, masterAccountListGet);
}

export function* onGetMasterAccountListResponseReset() {
	yield takeLatest(
		ProductActionTypes.GET_MASTER_ACCOUNT_LIST_RESPONSE_RESET,
		resetGetMasterAccountListResponse,
	);
}
/** *********************  END GET MASTER ACCOUNT LIST ******************** */

/** *********************  START GET ALL INSTRUMENT LIST  ******************** */
export function* allInstrumentListGet({ payload }) {
	try {
		const key = 'GetAllInstrumentListKey';
		const jsonData = {
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAllInstrumentList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllInstrumnetListFailure(responseData.data));
		} else {
			yield put(getAllInstrumnetListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllInstrumnetListFailure(error));
	}
}

export function* resetGetAllInstrumentListResponse() {
	yield put(getAllInstrumnetListResponseChanged());
}

export function* onGetAllInstrumnetListDetails() {
	yield takeLatest(ProductActionTypes.GET_ALL_INSTRUMENT_LIST_START, allInstrumentListGet);
}

export function* onGetAllInstrumnetListResponseReset() {
	yield takeLatest(
		ProductActionTypes.GET_ALL_INSTRUMENT_LIST_RESPONSE_RESET,
		resetGetAllInstrumentListResponse,
	);
}
/** *********************  END GET ALL INSTRUMENT LIST ******************** */

/** *********************  START FETCH FILTER PRODUCT LIST  ******************** */
export function* fetchFilterProductListGet({ payload }) {
	try {
		const key = 'GetFilterProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			ProductType: payload.productType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(filterProductListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFilterProductListFailure(responseData.data));
		} else {
			yield put(fetchFilterProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFilterProductListFailure(error));
	}
}

export function* resetFetchFilterProductListResponse() {
	yield put(fetchFilterProductListResponseChanged());
}

export function* onFetchFilterProductListDetails() {
	yield takeLatest(ProductActionTypes.FETCH_FILTER_PRODUCT_LIST_START, fetchFilterProductListGet);
}

export function* onFetchFilterProductListResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_FILTER_PRODUCT_LIST_RESPONSE_RESET,
		resetFetchFilterProductListResponse,
	);
}
/** *********************  END FETCH FILTER PRODUCT LIST ******************** */

export function* productMasterSaga() {
	yield all([
		call(onCreateProductDetails),
		call(onCreateProductResponseReset),
		call(onUpdateProductDetails),
		call(onUpdateProductResponseReset),
		call(onGetProductListDetails),
		call(onGetProductListResponseReset),
		call(onFetchProductDetails),
		call(onFetchProductResponseReset),
		call(onFetchCustomProductDetails),
		call(onFetchCustomProductResponseReset),
		call(onDeleteProductDetail),
		call(onDeleteProductResponseReset),
		call(onGetMasterAccountListDetails),
		call(onGetMasterAccountListResponseReset),
		call(onGetAllInstrumnetListDetails),
		call(onGetAllInstrumnetListResponseReset),
		call(onFetchFilterProductListDetails),
		call(onFetchFilterProductListResponseReset),
	]);
}
