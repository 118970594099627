/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanCardReportActionTypes from './loanCardReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getLoanCardReportResponse: null,
	getLoanCardReportArr: [],
	getMemberLoanCardStatus: null,
	getMemberLoanCardArr: [],
	getParticularLoanCardStatus: null,
	getParticularLoanCardArr: [],
	fetchLoanCardExcelStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_SUCCESS:
			return {
				...state,
				getLoanCardReportResponse: action.payload,
				getLoanCardReportArr: docodeResponse(action.payload, 'FetchLoanCardReportKey'),
			};

		case LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_FAILURE:
			return { ...state, getLoanCardReportResponse: action.payload };

		case LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_RESPONSE_CHANGED:
			return { ...state, getLoanCardReportResponse: null };

		case LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_SUCCESS:
			return {
				...state,
				getMemberLoanCardStatus: 'SUCCESS',
				getMemberLoanCardArr: docodeResponse(
					action.payload,
					'GetLoanDetailsForParticularMemberKey',
				),
			};

		case LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_FAILURE:
			return { ...state, getMemberLoanCardStatus: 'FAILURE' };

		case LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_RESPONSE_CHANGED:
			return { ...state, getMemberLoanCardArr: null, getMemberLoanCardStatus: null };

		case LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_SUCCESS:
			return {
				...state,
				getParticularLoanCardStatus: 'SUCCESS',
				getParticularLoanCardArr: docodeResponse(
					action.payload,
					'GetLoanCardDetailsBasedOnMemebersKey',
				),
			};

		case LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_FAILURE:
			return { ...state, getParticularLoanCardStatus: 'FAILURE' };

		case LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_RESPONSE_CHANGED:
			return { ...state, getParticularLoanCardStatus: null };

		case LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_SUCCESS:
			return {
				...state,
				fetchLoanCardExcelStatus: 'SUCCESS'
			};

		case LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_FAILURE:
			return { ...state, fetchLoanCardExcelStatus: 'FAILURE' };

		case LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_CHANGED:
			return { ...state, fetchLoanCardExcelStatus: null };
		default:
			return state;
	}
};
