const MemberRejectionReportActionTypes = {
	FETCH_MEMBER_REJECTION_REPORT_START: 'fetch_member_rejection_report_Start',
	FETCH_MEMBER_REJECTION_REPORT_SUCCESS: 'fetch_member_rejection_report_Success',
	FETCH_MEMBER_REJECTION_REPORT_FAILURE: 'fetch_member_rejection_report_Failure',
	FETCH_MEMBER_REJECTION_REPORT_RESPONSE_RESET: 'fetch_member_rejection_report_ResponseReset',
	FETCH_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED: 'fetch_member_rejection_report_ResponseChanged',

	EXPORT_MEMBER_REJECTION_REPORT_START: 'export_member_rejection_report_Start',
	EXPORT_MEMBER_REJECTION_REPORT_SUCCESS: 'export_member_rejection_report_Success',
	EXPORT_MEMBER_REJECTION_REPORT_FAILURE: 'export_member_rejection_report_Failure',
	EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_RESET: 'export_member_rejection_report_ResponseReset',
	EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED:
		'export_member_rejection_report_ResponseChanged',

	FETCH_MEMBER_REJECTION_REASON_COUNT_START: 'fetch_member_rejection_reason_count_Start',
	FETCH_MEMBER_REJECTION_REASON_COUNT_SUCCESS: 'fetch_member_rejection_reason_count_Success',
	FETCH_MEMBER_REJECTION_REASON_COUNT_FAILURE: 'fetch_member_rejection_reason_count_Failure',
	FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_RESET: 'fetch_member_rejection_reason_count_ResponseReset',
	FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_CHANGED: 'fetch_member_rejection_reason_count_ResponseChanged',
};
export default MemberRejectionReportActionTypes;
