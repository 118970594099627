/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import AuditingToolActionTypes from './auditing-tool.types';

import {
	auditingToolListGet,
	auditingToolGet,
	questionnaireAllGet,
	trainingProposalMembersList,
	trainingProposalIndividualList,
	addtrainingProposalList,
	updatetrainingProposalList,
	deleteTrainingProposalList,
	getAllSuperviserList,
	approveTrainingProposalList,
} from './auditing-tool.service';

import {
	getAuditingToolDetailsSuccess,
	getAuditingToolDetailsFailure,
	getAuditingToolDetailsResponseChanged,
	fetchAuditingToolDetailsSuccess,
	fetchAuditingToolDetailsFailure,
	fetchAuditingToolDetailsChanged,
	getQuestionnaireSuccess,
	getQuestionnaireFailure,
	getQuestionnaireResponseChanged,
	getTrainingProposalMembersSuccess,
	getTrainingProposalMembersFailure,
	getTrainingProposalMembersChanged,
	getTrainingProposalIndividualSuccess,
	getTrainingProposalIndividualFailure,
	getTrainingProposalIndividualChanged,
	addTrainingProposalSuccess,
	addTrainingProposalFailure,
	addTrainingProposalChanged,
	updateTrainingProposalSuccess,
	updateTrainingProposalFailure,
	updateTrainingProposalChanged,
	deleteTrainingProposalSuccess,
	deleteTrainingProposalFailure,
	deleteTrainingProposalChanged,
	getAllSuperviserListSuccess,
	getAllSuperviserListFailure,
	getAllSuperviserListChanged,
	approveTrainingProposalSuccess,
	approveTrainingProposalFailure,
	approveTrainingProposalChanged,
} from './auditing-tool.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getAuditingToolList({ payload }) {
	try {
		const key = 'GetTrainingProposalListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			Limit: payload.limit,
			QuestionID: payload.questionnaireID,
			SearchData: payload.searchData,
			Status: payload.status,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(auditingToolListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAuditingToolDetailsFailure(responseData.data));
		} else {
			yield put(getAuditingToolDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAuditingToolDetailsFailure(error));
	}
}

export function* resetGetAuditingToolListResponse() {
	yield put(getAuditingToolDetailsResponseChanged());
}

export function* onGetAuditingToolListDetails() {
	yield takeLatest(AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_START, getAuditingToolList);
}

export function* onGetAuditingToolListResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_RESET,
		resetGetAuditingToolListResponse,
	);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START FETCH AUDITING TOOL DETAILS  ******************** */
export function* getAuditingToolDetails({ payload }) {
	try {
		const key = 'GetTrainingProposalDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			GroupTrainingProposalID: payload.tempTrainingID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(auditingToolGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAuditingToolDetailsFailure(responseData.data));
		} else {
			yield put(fetchAuditingToolDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAuditingToolDetailsFailure(error));
	}
}

export function* resetGetAuditingToolDetailsResponse() {
	yield put(fetchAuditingToolDetailsChanged());
}

export function* onGetAuditingToolDetailsStart() {
	yield takeLatest(
		AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_START,
		getAuditingToolDetails,
	);
}

export function* onGetAuditingToolDetailsResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.FETCH_AUDITING_TOOL_LIST_RESET,
		resetGetAuditingToolDetailsResponse,
	);
}
/** *********************  END FETCH AUDITING TOOL DETAILS ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getQuestionnaire({ payload }) {
	try {
		const key = 'GetAllQuestionnaireListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireAllGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getQuestionnaireFailure(responseData.data));
		} else {
			yield put(getQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getQuestionnaireFailure(error));
	}
}

export function* resetGetQuestionnaireResponse() {
	yield put(getQuestionnaireResponseChanged());
}

export function* onGetQuestionnaireDetails() {
	yield takeLatest(AuditingToolActionTypes.GET_QUESTIONNAIRE_START, getQuestionnaire);
}

export function* onGetQuestionnaireResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.GET_QUESTIONNAIRE_RESPONSE_RESET,
		resetGetQuestionnaireResponse,
	);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

/** *********************  START TRAINING PROPOSAL MEMBERS  ******************** */
export function* getTrainingProposalMembersStart({ payload }) {
	try {
		const key = 'GetAllTrainingMembersKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			IsTrainingStarted: payload.isTrainingStarted,
			SearchMemberData: payload.memberSearch,
			TrainingProposalID: payload.isTempTrainingID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(trainingProposalMembersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getTrainingProposalMembersFailure(responseData.data));
		} else {
			yield put(getTrainingProposalMembersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTrainingProposalMembersFailure(error));
	}
}

export function* resetTrainingProposalMembers() {
	yield put(getTrainingProposalMembersChanged());
}

export function* onGetTrainingProposalMembersDetails() {
	yield takeLatest(
		AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_START,
		getTrainingProposalMembersStart,
	);
}

export function* onGetTrainingProposalMembersResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.GET_TRAINING_PROPOSAL_MEMBERS_RESET,
		resetTrainingProposalMembers,
	);
}
/** *********************  END TRAINING PROPOSAL MEMBERS ******************** */

/** *********************  START TRAINING PROPOSAL INDIVIDUAL  ******************** */
export function* getTrainingProposalIndividualStart({ payload }) {
	try {
		const key = 'GetAllTrainingIndividualKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			GroupTrainingProposalID: payload.groupTrainingID,
			GroupTrainingDateProposalID: payload.groupDateTrainingID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(trainingProposalIndividualList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getTrainingProposalIndividualFailure(responseData.data));
		} else {
			yield put(getTrainingProposalIndividualSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTrainingProposalIndividualFailure(error));
	}
}

export function* resetTrainingProposalIndividual() {
	yield put(getTrainingProposalIndividualChanged());
}

export function* onGetTrainingProposalIndividualDetails() {
	yield takeLatest(
		AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_START,
		getTrainingProposalIndividualStart,
	);
}

export function* onGetTrainingProposalIndividualResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.GET_TRAINING_PROPOSAL_INDIVIDUAL_RESET,
		resetTrainingProposalIndividual,
	);
}
/** *********************  END TRAINING PROPOSAL MEMBERS ******************** */

/** *********************  START TRAINING PROPOSAL  ******************** */
export function* addTrainingProposalsStart({ payload }) {
	try {
		const key = 'NewGroupTrainingProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			TrainingType: payload.isCenterWise ? 'Center' : 'Group',
			ProposedDate: payload.proposalDate,
			ProposedBy: payload.userID,
			MeetupLat: payload.meetupLatitude,
			MeetupLong: payload.meetupLongitude,
			MeetupAddress: payload.meetupLocation,
			TrainingDateProposal: JSON.stringify(payload.dateProposalArr).replace(/"/g, "'"),
			TrainingMembersArr: JSON.stringify(payload.memberArr).replace(/"/g, "'"),
			ProvisionType: payload.provisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addtrainingProposalList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addTrainingProposalFailure(responseData.data));
		} else {
			yield put(addTrainingProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addTrainingProposalFailure(error));
	}
}

export function* resetTrainingProposalResponse() {
	yield put(addTrainingProposalChanged());
}

export function* onAddTrainingProposalDetails() {
	yield takeLatest(
		AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_START,
		addTrainingProposalsStart,
	);
}

export function* onAddTrainingProposalResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.ADD_TRAINING_PROPOSAL_RESET,
		resetTrainingProposalResponse,
	);
}
/** *********************  END TRAINING PROPOSAL ******************** */

/** *********************  START TRAINING PROPOSAL  ******************** */
export function* updateTrainingProposalsStart({ payload }) {
	try {
		const key = 'UpdateGroupTrainingProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			GroupTrainingProposalID: payload.isTempTrainingID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			TrainingType: payload.isCenterWise ? 'Center' : 'Group',
			ProposedDate: payload.proposalDate,
			ProposedBy: payload.userID,
			MeetupLat: payload.meetupLatitude,
			MeetupLong: payload.meetupLongitude,
			MeetupAddress: payload.meetupLocation,
			TrainingDateProposal: JSON.stringify(payload.dateProposalArr).replace(/"/g, "'"),
			TrainingMembersArr: JSON.stringify(payload.memberArr).replace(/"/g, "'"),
			ProvisionType: payload.provisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updatetrainingProposalList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateTrainingProposalFailure(responseData.data));
		} else {
			yield put(updateTrainingProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateTrainingProposalFailure(error));
	}
}

export function* resetUpdateTrainingProposalResponse() {
	yield put(updateTrainingProposalChanged());
}

export function* onUpdateTrainingProposalDetails() {
	yield takeLatest(
		AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_START,
		updateTrainingProposalsStart,
	);
}

export function* onUpdateTrainingProposalResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.UPDATE_TRAINING_PROPOSAL_RESET,
		resetUpdateTrainingProposalResponse,
	);
}
/** *********************  END TRAINING PROPOSAL ******************** */

/** *********************  START TRAINING PROPOSAL  ******************** */
export function* deleteTrainingProposalsStart({ payload }) {
	try {
		const key = 'DeleteGroupTrainingProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			GroupTrainingProposalID: payload.TempTrainingID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteTrainingProposalList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteTrainingProposalFailure(responseData.data));
		} else {
			yield put(deleteTrainingProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteTrainingProposalFailure(error));
	}
}

export function* resetDeleteTrainingProposalResponse() {
	yield put(deleteTrainingProposalChanged());
}

export function* onDeleteTrainingProposalDetails() {
	yield takeLatest(
		AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_START,
		deleteTrainingProposalsStart,
	);
}

export function* onDeleteTrainingProposalResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.DELETE_TRAINING_PROPOSAL_RESET,
		resetDeleteTrainingProposalResponse,
	);
}
/** *********************  END TRAINING PROPOSAL ******************** */

/** *********************  START GET ALL SUPERVISER LIST  ******************** */
export function* getAllSuperviserListsStart({ payload }) {
	try {
		const key = 'GetAllSuperviserListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAllSuperviserList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllSuperviserListFailure(responseData.data));
		} else {
			yield put(getAllSuperviserListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllSuperviserListFailure(error));
	}
}

export function* resetGetAllSuperviserListResponse() {
	yield put(getAllSuperviserListChanged());
}

export function* onGetAllSuperviserListDetails() {
	yield takeLatest(
		AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_START,
		getAllSuperviserListsStart,
	);
}

export function* onGetAllSuperviserListResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.GET_ALL_SUPERVISER_LIST_RESET,
		resetGetAllSuperviserListResponse,
	);
}
/** *********************  END GET ALL SUPERVISER LIST ******************** */

/** *********************  START APPROVE TRAINING PROPOSAL  ******************** */
export function* approveTrainingProposalsStart({ payload }) {
	try {
		const key = 'UpdateTraningProposalStatusKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			GroupTrainingProposalID: payload.tempTrainingID,
			ApprovedStatus: payload.rejectModalValue === '' ? 'Approved' : 'Rejected',
			RejectedReason: encodeURIComponent(payload.rejectModalValue.replace(/'/g, '′')),
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(approveTrainingProposalList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(approveTrainingProposalFailure(responseData.data));
		} else {
			yield put(approveTrainingProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveTrainingProposalFailure(error));
	}
}

export function* resetRejectTrainingProposalResponse() {
	yield put(approveTrainingProposalChanged());
}

export function* onRejectTrainingProposalDetails() {
	yield takeLatest(
		AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_START,
		approveTrainingProposalsStart,
	);
}

export function* onRejectTrainingProposalResponseReset() {
	yield takeLatest(
		AuditingToolActionTypes.APPROVE_TRAINING_PROPOSAL_RESET,
		resetRejectTrainingProposalResponse,
	);
}
/** *********************  END APPROVE TRAINING PROPOSAL ******************** */

export function* reportAuditingToolSaga() {
	yield all([
		call(onGetAuditingToolListDetails),
		call(onGetAuditingToolListResponseReset),
		call(onGetAuditingToolDetailsStart),
		call(onGetAuditingToolDetailsResponseReset),
		call(onGetQuestionnaireDetails),
		call(onGetQuestionnaireResponseReset),
		call(onGetTrainingProposalMembersDetails),
		call(onGetTrainingProposalMembersResponseReset),
		call(onGetTrainingProposalIndividualDetails),
		call(onGetTrainingProposalIndividualResponseReset),
		call(onAddTrainingProposalDetails),
		call(onAddTrainingProposalResponseReset),
		call(onUpdateTrainingProposalDetails),
		call(onUpdateTrainingProposalResponseReset),
		call(onDeleteTrainingProposalDetails),
		call(onDeleteTrainingProposalResponseReset),
		call(onGetAllSuperviserListDetails),
		call(onGetAllSuperviserListResponseReset),
		call(onRejectTrainingProposalDetails),
		call(onRejectTrainingProposalResponseReset),
	]);
}
