/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import RuleEngineActionTypes from './ruleEngine.types';

/** ***************** GET RULE ENGINE LIST START *********************** */
export const getRuleEngineListStart = (getData) => ({
	type: RuleEngineActionTypes.GET_RULE_ENGINE_LIST_START,
	payload: getData,
});

export const getRuleEngineListSuccess = (successData) => ({
	type: RuleEngineActionTypes.GET_RULE_ENGINE_LIST_SUCCESS,
	payload: successData,
});

export const getRuleEngineListFailure = (failureData) => ({
	type: RuleEngineActionTypes.GET_RULE_ENGINE_LIST_FAILURE,
	payload: failureData,
});

export const getRuleEngineListReset = () => ({
	type: RuleEngineActionTypes.GET_RULE_ENGINE_LIST_RESET,
});

export const getRuleEngineListChanged = () => ({
	type: RuleEngineActionTypes.GET_RULE_ENGINE_LIST_CHANGED,
});

/** ***************** GET RULE ENGINE LIST END *********************** */


/** ***************** ADD RULE ENGINE LIST START *********************** */
export const addRuleEngineListStart = (addData) => ({
	type: RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_START,
	payload: addData,
});

export const addRuleEngineListSuccess = (successData) => ({
	type: RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_SUCCESS,
	payload: successData,
});

export const addRuleEngineListFailure = (failureData) => ({
	type: RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_FAILURE,
	payload: failureData,
});

export const addRuleEngineListReset = () => ({
	type: RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_RESET,
});

export const addRuleEngineListChanged = () => ({
	type: RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_CHANGED,
});

/** ***************** ADD RULE ENGINE LIST END *********************** */

/** ***************** DELETE RULE ENGINE LIST START *********************** */
export const deleteRuleEngineListStart = (deleteData) => ({
	type: RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_START,
	payload: deleteData,
});

export const deleteRuleEngineListSuccess = (successData) => ({
	type: RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_SUCCESS,
	payload: successData,
});

export const deleteRuleEngineListFailure = (failureData) => ({
	type: RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_FAILURE,
	payload: failureData,
});

export const deleteRuleEngineListReset = () => ({
	type: RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_RESET,
});

export const deleteRuleEngineListChanged = () => ({
	type: RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_CHANGED,
});

/** ***************** DELETE RULE ENGINE LIST END *********************** */