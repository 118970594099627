/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ClosedLoanDetailsActionTypes from './closed-loan-details.types';

import {
	closedloanDetailsListGet,
	officerBranchListGet,
	closedloanDetailsListExport
} from './closed-loan-details.service';

import {
	getClosedLoanDetailsDetailsSuccess,
	getClosedLoanDetailsDetailsFailure,
	getClosedLoanDetailsDetailsResponseChanged,
	getClosedLoanDetailsExportSuccess,
	getClosedLoanDetailsExportFailure,
	getClosedLoanDetailsExportResponseChanged,
	getOfficerBranchDetailsSuccess,
	getOfficerBranchDetailsFailure,
	getOfficerBranchDetailsResponseChanged,
} from './closed-loan-details.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getClosedLoanDetailsList({ payload }) {
	try {
		const key = 'GetMemberClosedLoanDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
			UserRole: payload.userRole,
			DateFilter: 'FromToDate',
		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(closedloanDetailsListGet, encodeData);
		if (responseData.data.status === '102') {
			yield put(getClosedLoanDetailsDetailsFailure(responseData.data));
		} else {
			yield put(getClosedLoanDetailsDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getClosedLoanDetailsDetailsFailure(error));
	}
}

export function* resetGetClosedLoanDetailsListResponse() {
	yield put(getClosedLoanDetailsDetailsResponseChanged());
}

export function* onGetClosedLoanDetailsListDetails() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_START,
		getClosedLoanDetailsList,
	);
}

export function* onGetClosedLoanDetailsListResponseReset() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_RESET,
		resetGetClosedLoanDetailsListResponse,
	);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getClosedLoanDetailsExport({ payload }) {
	try {
		const key = 'ClosedLoanReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
			UserRole: payload.userRole,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
			ProvisionType: payload.ProvisionType,
			isCustomerIDExist: payload.isCustomerIDExist,
			DateFilter: 'FromToDate',
		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(closedloanDetailsListExport, encodeData);
		if (responseData.data.status === '102') {
			yield put(getClosedLoanDetailsExportFailure(responseData.data));
		} else {
			yield put(getClosedLoanDetailsExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getClosedLoanDetailsExportFailure(error));
	}
}

export function* resetGetClosedLoanDetailsExportResponse() {
	yield put(getClosedLoanDetailsExportResponseChanged());
}

export function* onGetClosedLoanDetailsExportDetails() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_EXPORT_START,
		getClosedLoanDetailsExport,
	);
}

export function* onGetClosedLoanDetailsExportResponseReset() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_EXPORT_RESPONSE_RESET,
		resetGetClosedLoanDetailsExportResponse,
	);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_START,
		getOfficerBranchList,
	);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(
		ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
		resetGetClosedLoanDetailsListResponse,
	);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

export function* reportClosedLoanSaga() {
	yield all([
		call(onGetClosedLoanDetailsListDetails),
		call(onGetClosedLoanDetailsListResponseReset),
		call(onGetClosedLoanDetailsExportDetails),
		call(onGetClosedLoanDetailsExportResponseReset),
		call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
