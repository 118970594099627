const PortFlioAtRiskTypes = {
	PORTFLIO_AT_RISK_START: 'PORTFLIO_AT_RISK_START',
	PORTFLIO_AT_RISK_SUCCESS: 'PORTFLIO_AT_RISK_SUCCESS',
	PORTFLIO_AT_RISK_FAILURE: 'PORTFLIO_AT_RISK_FAILURE',
	PORTFLIO_AT_RISK_RESPONSE_RESET: 'PORTFLIO_AT_RISK_RESPONSE_RESET',
	PORTFLIO_AT_RISK_RESPONSE_CHANGED: 'PORTFLIO_AT_RISK_RESPONSE_CHANGED',

	PORTFLIO_AT_RISK_EXPORT_START: 'PORTFLIO_AT_RISK_EXPORT_START',
	PORTFLIO_AT_RISK_EXPORT_SUCCESS: 'PORTFLIO_AT_RISK_EXPORT_SUCCESS',
	PORTFLIO_AT_RISK_EXPORT_FAILURE: 'PORTFLIO_AT_RISK_EXPORT_FAILURE',
	PORTFLIO_AT_RISK_EXPORT_RESPONSE_RESET: 'PORTFLIO_AT_RISK_EXPORT_RESPONSE_RESET',
	PORTFLIO_AT_RISK_EXPORT_RESPONSE_CHANGED: 'PORTFLIO_AT_RISK_EXPORT_RESPONSE_CHANGED',

	GET_USER_DETAILS_PAR_START: 'GET_USER_DETAILS_PAR_START',
	GET_USER_DETAILS_PAR_SUCCESS: 'GET_USER_DETAILS_PAR_SUCCESS',
	GET_USER_DETAILS_PAR_FAILURE: 'GET_USER_DETAILS_PAR_FAILURE',
	GET_USER_DETAILS_PAR_RESPONSE_RESET: 'GET_USER_DETAILS_PAR_RESPONSE_RESET',
	GET_USER_DETAILS_PAR_RESPONSE_CHANGED: 'GET_USER_DETAILS_PAR_RESPONSE_CHANGED',

	GET_LOAN_INFO_BASEDON_MEMBER_START: 'GET_LOAN_INFO_BASEDON_MEMBER_START',
	GET_LOAN_INFO_BASEDON_MEMBER_SUCCESS: 'GET_LOAN_INFO_BASEDON_MEMBER_SUCCESS',
	GET_LOAN_INFO_BASEDON_MEMBER_FAILURE: 'GET_LOAN_INFO_BASEDON_MEMBER_FAILURE',
	GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_RESET: 'GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_RESET',
	GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_CHANGED: 'GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_CHANGED',
};
export default PortFlioAtRiskTypes;
