/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import SignatureActionType from './signature.types';
import {
	uploadSignatureSuccess,
	uploadSignatureFailure,
	uploadSignatureUserResponseChanged,
} from './signature.action';
import { encodeJson } from '../../../enode-decode';
import { signatureUpload } from './signature.service';

// Start Upload Signature Photo
export function* UploadSignature({ payload }) {
	try {
		const key = 'UploadMemberSignature';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberSignature: payload.memberSignature,
			MemberSignatureImg: payload.memberSignatureImg,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(signatureUpload, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(uploadSignatureFailure(responseData.data));
		} else {
			yield put(uploadSignatureSuccess(responseData.data));
		}
	} catch (error) {
		yield put(uploadSignatureFailure(error));
	}
}

export function* UploadSignatureResponseClear() {
	yield put(uploadSignatureUserResponseChanged());
}

export function* onUploadSignatureStart() {
	yield takeLatest(SignatureActionType.UPLOAD_SIGNATURE_START, UploadSignature);
}

export function* onUploadSignatureResponseReset() {
	yield takeLatest(
		SignatureActionType.UPLOAD_SIGNATURE_RESPONSE_REST_START,
		UploadSignatureResponseClear,
	);
}
// End Upload Signature Photo

export function* signatureSaga() {
	yield all([call(onUploadSignatureStart), call(onUploadSignatureResponseReset)]);
}
