const CenterActionTypes = {
	ADD_CENTER_DETAILS_START: 'add_center_details_start',
	ADD_CENTER_DETAILS_SUCCESS: 'add_center_details_success',
	ADD_CENTER_DETAILS_FAILURE: 'add_center_details_failure',
	ADD_CENTER_DETAILS_RESPONSE_RESET: 'add_center_details_response_reset',
	ADD_CENTER_DETAILS_RESPONSE_CHANGED: 'add_center_details_response_changed',

	UPDATE_CENTER_DETAILS_START: 'update_center_details_start',
	UPDATE_CENTER_DETAILS_SUCCESS: 'update_center_details_success',
	UPDATE_CENTER_DETAILS_FAILURE: 'update_center_details_failure',
	UPDATE_CENTER_DETAILS_RESPONSE_RESET: 'update_center_details_response_reset',
	UPDATE_CENTER_DETAILS_RESPONSE_CHANGED: 'update_center_details_response_changed',

	GET_CENTER_LIST_START: 'get_center_list_start',
	GET_CENTER_LIST_SUCCESS: 'get_center_list_success',
	GET_CENTER_LIST_FAILURE: 'get_center_list_failure',
	GET_CENTER_LIST_RESPONSE_RESET: 'get_center_list_response_reset',
	GET_CENTER_LIST_RESPONSE_CHANGED: 'get_center_list_response_changed',

	FETCH_CENTER_DETAILS_START: 'fetch_center_details_start',
	FETCH_CENTER_DETAILS_SUCCESS: 'fetch_center_details_success',
	FETCH_CENTER_DETAILS_FAILURE: 'fetch_center_details_failure',
	FETCH_CENTER_DETAILS_RESPONSE_RESET: 'fetch_center_details_response_reset',
	FETCH_CENTER_DETAILS_RESPONSE_CHANGED: 'fetch_center_details_response_changed',

	DELETE_CENTER_DETAILS_START: 'delete_center_details_start',
	DELETE_CENTER_DETAILS_SUCCESS: 'delete_center_details_success',
	DELETE_CENTER_DETAILS_FAILURE: 'delete_center_details_failure',
	DELETE_CENTER_DETAILS_RESPONSE_RESET: 'delete_center_details_response_reset',
	DELETE_CENTER_DETAILS_RESPONSE_CHANGED: 'delete_center_details_response_changed',

	FETCH_CENTER_UNIQUE_ID_START: 'fetch_center_unique_id_start',
	FETCH_CENTER_UNIQUE_ID_SUCCESS: 'fetch_center_unique_id_success',
	FETCH_CENTER_UNIQUE_ID_FAILURE: 'fetch_center_unique_id_failure',
	FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET: 'fetch_center_unique_id_response_reset',
	FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED: 'fetch_center_unique_id_response_changed',

	GET_ALL_CENTER_LIST_EXPORT_START: 'get_all_center_export_start',
	GET_ALL_CENTER_LIST_EXPORT_SUCCESS: 'get_all_center_export_success',
	GET_ALL_CENTER_LIST_EXPORT_FAILURE: 'get_all_center_export_failure',
	GET_ALL_CENTER_LIST_EXPORT_RESPONSE_RESET: 'get_all_center_export_response_reset',
	GET_ALL_CENTER_LIST_EXPORT_RESPONSE_CHANGED: 'get_all_center_export_response_changed',
};
export default CenterActionTypes;
