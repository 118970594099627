/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import { componentsMenu, demoPages, layoutMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import { signInReset, getUserDetailsReset } from '../../redux/login/login.actions';
// import { userLoginDatas } from '../../redux/login/login.selector';

import Loader from '../../assets/Loader.json';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';

import { deleteLocalStore } from '../../indexedDb/db';
import { getLoginObjDetails, selectGetUserDetailsStatus } from '../../redux/login/login.selector';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [menuList, setMenuList] = useState(demoPages);

	const loginData = useSelector(getLoginObjDetails);
	const userDetailsStatus = useSelector(selectGetUserDetailsStatus);

	const { databaseName, UserAccessData, userLevel } = loginData;

	// const loginData = useSelector(userLoginDatas);

	const logOutScreen = () => {
		setShowLogoutModal(true);

		// Remove local store
		deleteLocalStore().then(() => {
			dispatch(signInReset());
			setShowLogoutModal(false);
			navigate(`${menuList.login.path}`);
		});
	};

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Loader,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const toCheckAccess = (menuName, isMainMenu, mainMenuName) => {
		let hasAccess = false;
		if (isMainMenu === 1) {
			if (menuName === 'Loan Proposal') {
				const operationList = UserAccessData.filter((val) => val.menuName === 'Operations');

				if (operationList && operationList[0]?.submenu) {
					const proposalAccessObj = operationList[0].submenu.find(
						(dataItem) =>
							dataItem.Isshow === '1' &&
							(dataItem.subMenuName === 'Center Wise Loan' ||
								dataItem.subMenuName === 'Group Wise Loan' ||
								dataItem.subMenuName === 'Group Wise TopUp Loan' ||
								dataItem.subMenuName === 'Product Loan' ||
								dataItem.subMenuName === 'Custom Product Loan' ||
								dataItem.subMenuName === 'Individual Loan' ||
								dataItem.subMenuName === 'Range Product Loan' ||
								dataItem.subMenuName === 'Gold Loan'),
					);

					if (proposalAccessObj) {
						hasAccess = true;
					}
				}
			} else if (menuName === 'Loan Disbursement') {
				const operationList = UserAccessData.filter((val) => val.menuName === 'Operations');

				if (operationList && operationList[0]?.submenu) {
					const disbursementAccessObj = operationList[0].submenu.find(
						(dataItem) =>
							dataItem.Isshow === '1' &&
							dataItem.subMenuName === 'Loan Index Disbursement',
					);

					if (disbursementAccessObj) {
						hasAccess = true;
					}
				}
			} else {
				const userAccessObj = UserAccessData.find((val) => val.menuName === menuName);

				if (userAccessObj) {
					if (userAccessObj.SubMenuCount > 0) {
						hasAccess = true;
					}
				}
			}
		} else if (isMainMenu === 0) {
			if (mainMenuName === 'Loan Proposal' || mainMenuName === 'Loan Disbursement') {
				const operationList = UserAccessData.filter((val) => val.menuName === 'Operations');

				if (operationList && operationList[0]?.submenu) {
					const proposalAccessObj = operationList[0].submenu.find(
						(dataItem) => dataItem.Isshow === '1' && dataItem.subMenuName === menuName,
					);

					if (proposalAccessObj) {
						hasAccess = true;
					}
				}
			} else {
				const mainMenuList = UserAccessData.filter((val) => val.menuName === mainMenuName);

				if (mainMenuList && mainMenuList[0]?.submenu) {
					const proposalAccessObj = mainMenuList[0].submenu.find(
						(dataItem) => dataItem.Isshow === '1' && dataItem.subMenuName === menuName,
					);

					if (proposalAccessObj) {
						hasAccess = true;
					}
				}
			}
		}

		return hasAccess;
	};

	const refreshPage = () => {
		window.location.reload();
	};

	useEffect(() => {
		if (databaseName && databaseName === 'SMCF') {
			const updatedMenu = { ...menuList };
			for (const key in updatedMenu) {
				if (updatedMenu.hasOwnProperty(key)) {
					updatedMenu[key].isView = false;
					if (updatedMenu[key].text === 'Reports') {
						updatedMenu[key].isView = true;
					}

					if (updatedMenu[key].subMenu) {
						for (const key1 in updatedMenu[key].subMenu) {
							updatedMenu[key].subMenu[key1].isView = false;
							if (updatedMenu[key].subMenu[key1].text === 'Lead Report') {
								updatedMenu[key].subMenu[key1].isView = true;
							}
						}
					}
				}
			}
			setMenuList(updatedMenu);
		}

		if (userLevel !== 'level100' && databaseName !== 'SMCF') {
			const updatedMenu = { ...menuList };
			for (const mainMenu in updatedMenu) {
				if (updatedMenu.hasOwnProperty(mainMenu)) {
					updatedMenu[mainMenu].isView = toCheckAccess(
						updatedMenu[mainMenu].text,
						1,
						updatedMenu[mainMenu].text,
					);

					if (updatedMenu[mainMenu].subMenu) {
						for (const subMenu in updatedMenu[mainMenu].subMenu) {
							updatedMenu[mainMenu].subMenu[subMenu].isView = toCheckAccess(
								updatedMenu[mainMenu].subMenu[subMenu].text,
								0,
								updatedMenu[mainMenu].text,
							);
						}
					}
				}
			}
			setMenuList(updatedMenu);
		}
	}, []);

	useEffect(() => {
		if (userDetailsStatus && userLevel !== 'level100' && databaseName !== 'SMCF') {
			// Refresh Latest Data
			const updatedMenu = { ...menuList };
			for (const mainMenu in updatedMenu) {
				if (updatedMenu.hasOwnProperty(mainMenu)) {
					updatedMenu[mainMenu].isView = toCheckAccess(
						updatedMenu[mainMenu].text,
						1,
						updatedMenu[mainMenu].text,
					);

					if (updatedMenu[mainMenu].subMenu) {
						for (const subMenu in updatedMenu[mainMenu].subMenu) {
							updatedMenu[mainMenu].subMenu[subMenu].isView = toCheckAccess(
								updatedMenu[mainMenu].subMenu[subMenu].text,
								0,
								updatedMenu[mainMenu].text,
							);
						}
					}
				}
			}
			setMenuList(updatedMenu);
			dispatch(getUserDetailsReset());
		}
	}, [userDetailsStatus]);

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					{/* <Navigation menu={dashboardMenu} id='aside-dashboard' /> */}
					{!doc && (
						<>
							<NavigationLine />
							<Navigation menu={menuList} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
						</>
					)}

					{doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p
									className={classNames('h4', {
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									})}>
									{t('Everything is ready!')}
								</p>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button>
							</CardBody>
						</Card>
					)}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={logOutScreen}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>Logout</span>
									</span>
								</span>
							</div>
							{/* Dark Mode */}
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => setDarkModeStatus(!darkModeStatus)}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											className='navigation-icon'
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											aria-label='Toggle fullscreen'
											data-tour='dark-mode'
										/>
										<span className='navigation-text'>DarkMode</span>
									</span>
								</span>
							</div> */}

							{/*	Full Screen */}
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => setFullScreenStatus(!fullScreenStatus)}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											className='navigation-icon'
											icon={
												fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'
											}
											aria-label='Toggle dark mode'
										/>
										<span className='navigation-text'>Full Screen</span>
									</span>
								</span>
							</div> */}
						</div>
					</nav>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}

			{showLogoutModal ? (
				<Modal
					setIsOpen={() => {}}
					isOpen={showLogoutModal}
					isCentered
					isAnimation
					isStaticBackdrop
					size='sm'>
					<ModalBody>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								height: '45vh',
								flexDirection: 'column',
							}}>
							<Lottie options={defaultOptions} height={200} width={200} />
							<h3 style={{ textAlign: 'center' }}>
								Please wait, you are signing off...
							</h3>
						</div>
					</ModalBody>
				</Modal>
			) : null}
		</>
	);
};

export default Aside;
