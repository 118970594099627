import { takeLatest, put, all, call } from 'redux-saga/effects';
import ExtraInformationActionType from './extra-information.types';
import {
	extraInformationAddSuccess,
	extraInformationAddFailure,
	extraInformationAddUserResponseChanged,
} from './extra-information.action';
import { encodeJson } from '../../../enode-decode';
import { extraMemberInformationAdd } from './extra-information.service';

// Start Extra Information Add
export function* ExtraInformationDetails({ payload }) {
	try {
		const key = 'ExtraInformationDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			BloodGroup: payload.bloodGroup,
			Disablity: payload.disablity,
			NameDisablity: payload.nameDisablity,
			Identification: payload.identification,
			EducationQualification: payload.educationQualification,
			Religion: payload.religion,
			Caste: payload.caste,
			MailID: payload.mailID,
			// CityType: payload.cityType,
			PhoneType: payload.phoneType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(extraMemberInformationAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(extraInformationAddFailure(responseData.data));
		} else {
			yield put(extraInformationAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(extraInformationAddFailure(error));
	}
}

export function* ExtraInformationDetailsResponseClear() {
	yield put(extraInformationAddUserResponseChanged());
}

export function* onExtraInformationAddStart() {
	yield takeLatest(
		ExtraInformationActionType.EXTRA_INFORMATION_ADD_START,
		ExtraInformationDetails,
	);
}

export function* onExtraInformationAddResponseRest() {
	yield takeLatest(
		ExtraInformationActionType.EXTRA_INFORMATION_ADD_RESPONSE_REST_START,
		ExtraInformationDetailsResponseClear,
	);
}
// End Extra Information Add

export function* extraInformationSaga() {
	yield all([call(onExtraInformationAddStart), call(onExtraInformationAddResponseRest)]);
}
