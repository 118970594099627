/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import InsuranceReportTypes from './insurance-report.types';
import {
	getInsuranceReportDetails,
	getInsuranceReportExportDetails,
} from './insurance-report.service';
import {
	fetchInsuranceReportSuccess,
	fetchInsuranceReportFailure,
	fetchInsuranceReportResponseResetChanged,
	fetchInsuranceReportExportSuccess,
	fetchInsuranceReportExportFailure,
	fetchInsuranceReportExportResponseResetChanged,
} from './insurance-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH INSURANCE DETAILS  ******************** */
export function* fetchInsuranceReportReportDetails({ payload }) {
	try {
		const key = 'GetInsuranceReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			BranchID: payload.branchID,
			ToDate: payload.ToDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getInsuranceReportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(fetchInsuranceReportFailure(responseData.data));
		} else {
			yield put(fetchInsuranceReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchInsuranceReportFailure(error));
	}
}

export function* resetInsuranceReportResponse() {
	yield put(fetchInsuranceReportResponseResetChanged());
}

export function* onFetchInsuranceReportDetails() {
	yield takeLatest(
		InsuranceReportTypes.FETCH_INSURANCE_REPORT_START,
		fetchInsuranceReportReportDetails,
	);
}

export function* onFetchInsuranceReportResponseReset() {
	yield takeLatest(
		InsuranceReportTypes.FETCH_INSURANCE_REPORT_RESPONSE_RESET_START,
		resetInsuranceReportResponse,
	);
}
/** *********************  END FETCH INSURANCE DETAILS ******************** */

/** *********************  START FETCH INSURANCE DETAILS  ******************** */
export function* fetchInsuranceReportExportDetails({ payload }) {
	try {
		const key = 'OnBoardingMembersKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			AreaID: payload.branchID,
			ToDate: payload.ToDate,
			LoginUserID: payload.userID,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getInsuranceReportExportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(fetchInsuranceReportExportFailure(responseData.data));
		} else {
			yield put(fetchInsuranceReportExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchInsuranceReportExportFailure(error));
	}
}

export function* resetInsuranceReportExportResponse() {
	yield put(fetchInsuranceReportExportResponseResetChanged());
}

export function* onFetchInsuranceReportExportDetails() {
	yield takeLatest(
		InsuranceReportTypes.FETCH_INSURANCE_REPORT_EXPORT_START,
		fetchInsuranceReportExportDetails,
	);
}

export function* onFetchInsuranceReportExportResponseReset() {
	yield takeLatest(
		InsuranceReportTypes.FETCH_INSURANCE_REPORT_EXPORT_RESPONSE_RESET_START,
		resetInsuranceReportExportResponse,
	);
}
/** *********************  END FETCH INSURANCE DETAILS ******************** */

export function* insuranceReportSaga() {
	yield all([
		call(onFetchInsuranceReportDetails),
		call(onFetchInsuranceReportResponseReset),
		call(onFetchInsuranceReportExportDetails),
		call(onFetchInsuranceReportExportResponseReset),
	]);
}
