import { takeLatest, put, all, call } from 'redux-saga/effects';
import HousePhotosActionType from './house-photos.types';
import {
	updateHousePhotosSuccess,
	updateHousePhotosFailure,
	updateHousePhotosResponseChanged,
} from './house-photos.action';
import { encodeJson } from '../../../enode-decode';
import { housePhotosUpdate } from './house-photos.service';

// Start House Photos Add
export function* updateHousePhotosId({ payload }) {
	try {
		const key = 'updateHousePhotosID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberHouseImg1: payload.memberHouseImg1,
			MemberHouseImg2: payload.memberHouseImg2,
			MemberHouseImg3: payload.memberHouseImg3,
			MemberHouseImg4: payload.memberHouseImg4,
			MemberHouseImg5: payload.memberHouseImg5,
			HouseVerifyLatitude: payload.houseVerifyLatitude,
			HouseVerifyLongitude: payload.houseVerifyLogitude,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const housePhotosData = encodeJson(jsonData, key);
		const responseData = yield call(housePhotosUpdate, housePhotosData);
		if (responseData.data.statusCode === '100') {
			yield put(updateHousePhotosFailure(responseData.data));
		} else {
			yield put(updateHousePhotosSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateHousePhotosFailure(error));
	}
}

export function* updateHousePhotosResponseClear() {
	yield put(updateHousePhotosResponseChanged());
}

export function* onHousePhotosUpdateStart() {
	yield takeLatest(HousePhotosActionType.UPDATE_HOUSE_PHOTOS_START, updateHousePhotosId);
}

export function* onHousePhotosUpdateResponseRest() {
	yield takeLatest(
		HousePhotosActionType.UPDATE_HOUSE_PHOTOS_RESPONSE_REST_START,
		updateHousePhotosResponseClear,
	);
}
// End House Photos Add

export function* housePhotosSaga() {
	yield all([call(onHousePhotosUpdateStart), call(onHousePhotosUpdateResponseRest)]);
}
