/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import PurposeOfLoanActionTypes from './purposeofloan.types';
import {
	purposeofLoanDetailsAdd,
	purposeofLoanDetailsUpdate,
	purposeofLoanListGet,
	purposeofLoanDetailsFetch,
	purposeofLoanDetailsDelete,
	purposeOfLoanIsPublishUpdate,
	// PURPOSE OF LOAN CATEGORY
	getPurposeOfLoanCategoryList,
	addPurposeOfLoanCategory,
	fetchAllPurposeOfLoanType,
} from './purposeofloan.service';

import {
	addPurposeOfLoanDetailsSuccess,
	addPurposeOfLoanDetailsFailure,
	addPurposeOfLoanDetailsResponseChanged,
	updatePurposeOfLoanDetailsSuccess,
	updatePurposeOfLoanDetailsFailure,
	updatePurposeOfLoanDetailsResponseChanged,
	getPurposeOfLoanListSuccess,
	getPurposeOfLoanListFailure,
	getPurposeOfLoanListResponseChanged,
	fetchPurposeOfLoanDetailsSuccess,
	fetchPurposeOfLoanDetailsFailure,
	fetchPurposeOfLoanDetailsResponseChanged,
	deletePurposeOfLoanDetailsSuccess,
	deletePurposeOfLoanDetailsFailure,
	deletePurposeOfLoanDetailsResponseChanged,
	updatePurposeOfLoanPublishStatusSuccess,
	updatePurposeOfLoanPublishStatusFailure,
	updatePurposeOfLoanPublishStatusResponseChanged,

	// PURPOSE OF LOAN CATEGORY
	getPurposeOfLoanCategorySuccess,
	getPurposeOfLoanCategoryFailure,
	getPurposeOfLoanCategoryChanged,
	addPurposeOfLoanCategorySuccess,
	addPurposeOfLoanCategoryFailure,
	addPurposeOfLoanCategoryChanged,
	fetchPurposeOfLoanTypeListSuccess,
	fetchPurposeOfLoanTypeListFailure,
	fetchPurposeOfLoanTypeListChanged,
} from './purposeofloan.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PURPOSE OF LOAN DETAILS  ******************** */
export function* createPurposeOfLoan({ payload }) {
	try {
		const key = 'AddPurposeOfLoanDetailKey';
		const jsonData = {
			PurposeOfLoanName: payload.purposeOfLoanName,
			PurposeOfLoanCategoryID: payload.purposeOfLoanCategoryID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(purposeofLoanDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addPurposeOfLoanDetailsFailure(responseData.data));
		} else {
			yield put(addPurposeOfLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addPurposeOfLoanDetailsFailure(error));
	}
}

export function* resetAddPurposeOfLoanResponse() {
	yield put(addPurposeOfLoanDetailsResponseChanged());
}

export function* onCreatePurposeOfLoanDetails() {
	yield takeLatest(
		PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_START,
		createPurposeOfLoan,
	);
}

export function* onCreatePurposeOfLoanResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
		resetAddPurposeOfLoanResponse,
	);
}
/** *********************  END ADD PURPOSE OF LOAN DETAILS ******************** */

/** *********************  START UPDATE PURPOSE OF LOAN DETAILS  ******************** */
export function* purposeofLoanUpdate({ payload }) {
	try {
		const key = 'UpdatePurposeOfLoanDetailsKey';
		const jsonData = {
			PurposeOfLoanID: payload.purposeOfLoanID,
			PurposeOfLoanName: payload.purposeOfLoanName,
			PurposeOfLoanCategoryID: payload.purposeOfLoanCategoryID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(purposeofLoanDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePurposeOfLoanDetailsFailure(responseData.data));
		} else {
			yield put(updatePurposeOfLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePurposeOfLoanDetailsFailure(error));
	}
}

export function* resetUpdatePurposeOfLoanDetailsResponse() {
	yield put(updatePurposeOfLoanDetailsResponseChanged());
}

export function* onUpdatePurposeOfLoanDetails() {
	yield takeLatest(
		PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_START,
		purposeofLoanUpdate,
	);
}

export function* onUpdatePurposeOfLoanResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
		resetUpdatePurposeOfLoanDetailsResponse,
	);
}
/** *********************  END UPDATE PURPOSE OF LOAN DETAILS ******************** */

/** *********************  START GET PURPOSE OF LOAN LIST  ******************** */
export function* purposeofLoanDetailsListGet({ payload }) {
	try {
		const key = 'GetPurposeOfLoanListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(purposeofLoanListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPurposeOfLoanListFailure(responseData.data));
		} else {
			yield put(getPurposeOfLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPurposeOfLoanListFailure(error));
	}
}

export function* resetGetPurposeOfLoanListResponse() {
	yield put(getPurposeOfLoanListResponseChanged());
}

export function* onGetPurposeOfLoanListDetails() {
	yield takeLatest(
		PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_START,
		purposeofLoanDetailsListGet,
	);
}

export function* onGetPurposeOfLoanListResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET,
		resetGetPurposeOfLoanListResponse,
	);
}
/** *********************  END GET PURPOSE OF LOAN LIST ******************** */

/** *********************  START FETCH PURPOSE OF LOAN DETAILS  ******************** */
export function* fetchPurposeOfLoanData({ payload }) {
	try {
		const key = 'FetchPurposeOfLoanDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			PurposeOfLoanID: payload.purposeOfLoanID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(purposeofLoanDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPurposeOfLoanDetailsFailure(responseData.data));
		} else {
			yield put(fetchPurposeOfLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPurposeOfLoanDetailsFailure(error));
	}
}

export function* resetFetchPurposeOfLoanDetailsResponse() {
	yield put(fetchPurposeOfLoanDetailsResponseChanged());
}

export function* onFetchPurposeOfLoanDetails() {
	yield takeLatest(
		PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_START,
		fetchPurposeOfLoanData,
	);
}

export function* onFetchPurposeOfLoanResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
		resetFetchPurposeOfLoanDetailsResponse,
	);
}
/** *********************  END FETCH PURPOSE OF LOAN DETAILS ******************** */

/** *********************  START DELETE PURPOSE OF LOAN DETAILS  ******************** */
export function* purposeofLoanDelete({ payload }) {
	try {
		const key = 'DeletePurposeOfLoanDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
			PurposeOfLoanName: payload.PurposeOfLoanName,
			DatabaseName: payload.databaseName,
			UserName: payload.userName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			IsActive: '0',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(purposeofLoanDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deletePurposeOfLoanDetailsFailure(responseData.data));
		} else {
			yield put(deletePurposeOfLoanDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deletePurposeOfLoanDetailsFailure(error));
	}
}

export function* resetDeletePurposeOfLoanDetailsResponse() {
	yield put(deletePurposeOfLoanDetailsResponseChanged());
}

export function* onDeletePurposeOfLoanDetail() {
	yield takeLatest(
		PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_START,
		purposeofLoanDelete,
	);
}

export function* onDeletePurposeOfLoanResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
		resetDeletePurposeOfLoanDetailsResponse,
	);
}
/** *********************  END DELETE PURPOSE OF LOAN DETAILS ******************** */

/** *********************  START UPDATE PURPOSE OF LOAN PUBLISH STATUS  ******************** */
export function* purposeofLoanPublishStatusUpdate({ payload }) {
	try {
		const key = 'UpdatePurposeOfLoanPublishStatusKey';
		const jsonData = {
			PurposeOfLoanID: payload.purposeOfLoanID,
			PurposeOfLoanName: payload.purposeOfLoanName,
			IsPublish: payload.isPublish,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(purposeOfLoanIsPublishUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePurposeOfLoanPublishStatusFailure(responseData.data));
		} else {
			yield put(updatePurposeOfLoanPublishStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePurposeOfLoanPublishStatusFailure(error));
	}
}

export function* resetUpdatePurposeOfLoanPublishStatusResponse() {
	yield put(updatePurposeOfLoanPublishStatusResponseChanged());
}

export function* onUpdatePurposeOfLoanPublishStatus() {
	yield takeLatest(
		PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_START,
		purposeofLoanPublishStatusUpdate,
	);
}

export function* onUpdatePurposeOfLoanPublishStatusResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_RESPONSE_RESET,
		resetUpdatePurposeOfLoanPublishStatusResponse,
	);
}
/** *********************  END UPDATE PURPOSE OF LOAN PUBLISH STATUS ******************** */

// PURPOSE OF LOAN CATEGORY
/** *************** GET PURPOSE OF LOAN CATEGORY START ***************** */

export function* purposeOfLoanCategoryListStart({ payload }) {
	try {
		const key = 'PURPOSEOFLOANCATEGORYLISTKEY';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getPurposeOfLoanCategoryList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPurposeOfLoanCategoryFailure(responseData.data));
		} else {
			yield put(getPurposeOfLoanCategorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPurposeOfLoanCategoryFailure(error));
	}
}
export function* purposeOfLoanCategoryListReset() {
	yield put(getPurposeOfLoanCategoryChanged());
}

export function* onPurposeOfLoanCategoryGetStart() {
	yield takeLatest(
		PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_START,
		purposeOfLoanCategoryListStart,
	);
}

export function* onPurposeOfLoanCategoryGetReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_RESET,
		purposeOfLoanCategoryListReset,
	);
}

/** *************** GET PURPOSE OF LOAN CATEGORY END  ***************** */

/** *************** ADD PURPOSE OF LOAN CATEGORY START ***************** */
export function* purposeOfLoanCategoryAddStart({ payload }) {
	try {
		const key = 'PURPOSEOFLOANCATEGORYCREATEKEY';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			PurposeOfLoanCategoryName: payload.purposeOfLoanCategoryName,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addPurposeOfLoanCategory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addPurposeOfLoanCategoryFailure(responseData.data));
		} else {
			yield put(addPurposeOfLoanCategorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(addPurposeOfLoanCategoryFailure(error));
	}
}
export function* purposeOfLoanCategoryAddReset() {
	yield put(addPurposeOfLoanCategoryChanged());
}

export function* onPurposeOfLoanCategoryAddStart() {
	yield takeLatest(
		PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_START,
		purposeOfLoanCategoryAddStart,
	);
}

export function* onPurposeOfLoanCategoryAddReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_RESET,
		purposeOfLoanCategoryAddReset,
	);
}

/** *************** ADD PURPOSE OF LOAN CATEGORY END  ***************** */

/** *************** FETCH PURPOSE OF LOAN TYPE LIST START ***************** */
export function* fetchPurposeOfLoanTypeListStart({ payload }) {
	try {
		const key = 'FetchAllPurposeOfLoanTypesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllPurposeOfLoanType, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPurposeOfLoanTypeListFailure(responseData.data));
		} else {
			yield put(fetchPurposeOfLoanTypeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPurposeOfLoanTypeListFailure(error));
	}
}
export function* fetchPurposeOfLoanTypeListReset() {
	yield put(fetchPurposeOfLoanTypeListChanged());
}

export function* onPurposeOfLoanTypeListDetails() {
	yield takeLatest(
		PurposeOfLoanActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START,
		fetchPurposeOfLoanTypeListStart,
	);
}

export function* onPurposeOfLoanTypeListResponseReset() {
	yield takeLatest(
		PurposeOfLoanActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESET,
		fetchPurposeOfLoanTypeListReset,
	);
}
/** *************** FETCH PURPOSE OF LOAN TYPE LIST END  ***************** */

export function* purposeofLoanMasterSaga() {
	yield all([
		call(onCreatePurposeOfLoanDetails),
		call(onCreatePurposeOfLoanResponseReset),
		call(onUpdatePurposeOfLoanDetails),
		call(onUpdatePurposeOfLoanResponseReset),
		call(onGetPurposeOfLoanListDetails),
		call(onGetPurposeOfLoanListResponseReset),
		call(onFetchPurposeOfLoanDetails),
		call(onFetchPurposeOfLoanResponseReset),
		call(onDeletePurposeOfLoanDetail),
		call(onDeletePurposeOfLoanResponseReset),
		call(onUpdatePurposeOfLoanPublishStatus),
		call(onUpdatePurposeOfLoanPublishStatusResponseReset),
		call(onPurposeOfLoanCategoryGetStart),
		call(onPurposeOfLoanCategoryGetReset),
		call(onPurposeOfLoanCategoryAddStart),
		call(onPurposeOfLoanCategoryAddReset),
		call(onPurposeOfLoanTypeListDetails),
		call(onPurposeOfLoanTypeListResponseReset),
	]);
}
