/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const auditingToolListGet = (listData) => {
	const data = {
		GetTrainingProposalListToken: listData,
	};
	const url = `${API_URL}GetTrainingProposalListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const auditingToolGet = (listData) => {
	const data = {
		GetTrainingProposalDetailsToken: listData,
	};
	const url = `${API_URL}GetTrainingDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const questionnaireAllGet = (listData) => {
	const data = {
		GetAllQuestionnaireListToken: listData,
	};
	const url = `${API_URL}GetAllquestionnaireListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// TRAINING PROPOSAL MEMBERS SECTION
export const trainingProposalMembersList = (listData) => {
	const data = {
		GetAllTrainingMembersToken: listData,
	};
	const url = `${API_URL}GetTrainingProposalMembersWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// TRAINING PROPOSAL INDIVIDUAL SECTION
export const trainingProposalIndividualList = (listData) => {
	const data = {
		GetAllTrainingIndividualToken: listData,
	};
	const url = `${API_URL}GetTrainingProposalIndividualWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// ADD TRAINING PROPOSAL SECTION
export const addtrainingProposalList = (listData) => {
	const data = {
		NewGroupTrainingProposalToken: listData,
	};
	const url = `${API_URL}CreateGroupTrainingProposalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// UPDATE TRAINING PROPOSAL SECTION
export const updatetrainingProposalList = (listData) => {
	const data = {
		UpdateGroupTrainingProposalToken: listData,
	};
	const url = `${API_URL}UpdateGroupTrainingProposalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// DELETE TRAINING PROPOSAL SECTION
export const deleteTrainingProposalList = (listData) => {
	const data = {
		DeleteGroupTrainingProposalToken: listData,
	};
	const url = `${API_URL}DeleteGroupTrainingProposalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// GET ALL SUPERVISER LIST SECTION
export const getAllSuperviserList = (listData) => {
	const data = {
		GetAllSuperviserListToken: listData,
	};
	const url = `${API_URL}GetAllSuperviserListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// APPROVE TRAINING PROPOSAL SECTION
export const approveTrainingProposalList = (listData) => {
	const data = {
		UpdateTraningProposalStatusToken: listData,
	};
	const url = `${API_URL}approveTrainingProposalList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};