/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/** *** Collection Approval Page All the Service declared over here **** */
/** *** Version : 2.0.1 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Common Collection Update Service added **** */
/** *** Version : 2.0.2 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Branch EOD Check Service added **** */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchOfficerListCenters = (listData) => {
	const data = {
		CenterListBasedForOfficerToken: listData,
	};
	const url = `${API_URL}GetCenterListForOfficerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchOfficerListArrearCenters = (listData) => {
	const data = {
		ArrearCenterListBasedForOfficerToken: listData,
	};
	const url = `${API_URL}GetArrearCenterListForOfficerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchCollectionUsersList = (listData) => {
	const data = {
		BranchCollectionUsersToken: listData,
	};
	const url = `${API_URL}GetBranchCollectionUsersWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCenterListOfficerCollections = (listData) => {
	const data = {
		centerListOfficerCollectionsToken: listData,
	};
	const url = `${API_URL}GetCenterListOfficerCollectionWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchTodayCollectionDetails = (listData) => {
	const data = {
		FetchBranchTodayCollectionDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchTodayCollectionMembersForApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchTodayDemandMoveToArrearList = (listData) => {
	const data = {
		FetchBranchTodayDemandMoveToArrearListToken: listData,
	};
	const url = `${API_URL}GetBranchTodayDemandMoveToArrear`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchClosedLoanList = (listData) => {
	const data = {
		CloseLoanListFetchToken: listData,
	};
	const url = `${API_URL}FetchCloseLoanListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCenterCollectionDenominationDetails = (listData) => {
	const data = {
		FetchCenterCollectionDenominationToken: listData,
	};
	const url = `${API_URL}GetCenterCollectionDenominationToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAttendanceOfficerCollectionDetails = (listData) => {
	const data = {
		FetchAttendanceBasedOfficerCollectionToken: listData,
	};
	const url = `${API_URL}AttendanceBasedOfficerCollection`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchCollectionFunderAllocateEODDetails = (listData) => {
	const data = {
		BranchCollectionsAllocateForEodToken: listData,
	};
	const url = `${API_URL}GetBranchCollectionsFunderAllocateForEodToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchTodayCollectionsYetToApprove = (listData) => {
	const data = {
		BranchTodayCollectionMembersYetToApproveToken: listData,
	};
	const url = `${API_URL}GetBranchTodayCollectionMembersForYetToApproveToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanPaidPerMemberCollectionApprove = (listData) => {
	const data = {
		LoanPaidPerMemberCollectionApproveDataToken: listData,
	};
	const url = `${API_URL}UpdateLoanPaidPerMemberCollectionApproveToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanPaidAllCollectionApprove = (listData) => {
	const data = {
		UpdateLoanPaidCollectionApproveToken: listData,
	};
	const url = `${API_URL}UpdateLoanPaidCollectionApproveToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const commonCollectionUpdate = (listData) => {
	const data = {
		UpdateCommonLoanPaidDetailsToken: listData,
	};
	const url = `${API_URL}UpdateCommonLoanPaid`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkBranchEODExist = (listData) => {
	const data = {
		checkBranchEODExistDataToken: listData,
	};
	const url = `${API_URL}CheckBranchEODExistToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanPaidMoveToArrear = (listData) => {
	const data = {
		loanAllMemberMoveToArrearToken: listData,
	};
	const url = `${API_URL}SetAllMemberMoveToArrearToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOverallAccruedInterestAllocateForEODCall = (listData) => {
	const data = {
		UpdateAccruedInterestAllocateForEODToken: listData,
	};
	const url = `${API_URL}UpdateAccruedInterestAllocateForEodFinanceToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOverallAllocateForEODCall = (listData) => {
	const data = {
		BranchCollectionsAllocateForEODFinanceToken: listData,
	};
	const url = `${API_URL}GetBranchCollectionsAllocateForEodFinanceToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
