/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import trainingActionTypes from './training.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	trainingProposalBranchResponse: null,
	trainingProposalBranchArr: [],
	trainingProposalBranchExcelListResponse: null,
	trainingProposalBranchExcelListArr: [],
};

const trainingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case trainingActionTypes.TRAINING_PROPOSAL_BRANCH_SUCCESS:
			return {
				...state,
				trainingProposalBranchResponse: action.payload,
				trainingProposalBranchArr: docodeResponse(
					action.payload,
					'TRAININGDETAILSFORBRANCH',
				),
			};

		case trainingActionTypes.TRAINING_PROPOSAL_BRANCH_FAILURE:
			return {
				...state,
				trainingProposalBranchResponse: action.payload,
			};

		case trainingActionTypes.TRAINING_PROPOSAL_BRANCH_RESPONSE_CHANGED:
			return {
				...state,
				trainingProposalBranchResponse: null,
			};

		case trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_SUCCESS:
			return {
				...state,
				trainingProposalBranchExcelListResponse: action.payload,
				trainingProposalBranchExcelListArr: docodeResponse(
					action.payload,
					'AuditingToolReportExcelKey',
				),
			};

		case trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_FAILURE:
			return { ...state, trainingProposalBranchExcelListResponse: action.payload };

		case trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_CHANGED:
			return { ...state, trainingProposalBranchExcelListResponse: null };

		default:
			return state;
	}
};

export default trainingReducer;
