/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import FunderActionTypes from './funder.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addFunderDetailsResponse: null,
	getFunderListResponse: null,
	getFunderListArr: [],
	fetchFunderDetailsResponse: null,
	fetchFunderDetailsArr: [],
	deleteFunderDetailsResponse: null,
	updateIsPublishFunder: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FunderActionTypes.ADD_FUNDER_DETAILS_SUCCESS:
			return { ...state, addFunderDetailsResponse: action.payload };

		case FunderActionTypes.ADD_FUNDER_DETAILS_FAILURE:
			return { ...state, addFunderDetailsResponse: action.payload };

		case FunderActionTypes.ADD_FUNDER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addFunderDetailsResponse: null };

		case FunderActionTypes.UPDATE_FUNDER_DETAILS_SUCCESS:
			return { ...state, updateFunderDetailsResponse: action.payload };

		case FunderActionTypes.UPDATE_FUNDER_DETAILS_FAILURE:
			return { ...state, updateFunderDetailsResponse: action.payload };

		case FunderActionTypes.UPDATE_FUNDER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateFunderDetailsResponse: null };

		case FunderActionTypes.GET_FUNDER_LIST_SUCCESS:
			return {
				...state,
				getFunderListResponse: action.payload,
				getFunderListArr: docodeResponse(action.payload, 'GetFunderListKey'),
			};

		case FunderActionTypes.GET_FUNDER_LIST_FAILURE:
			return { ...state, getFunderListResponse: action.payload, getFunderListArr: [] };

		case FunderActionTypes.GET_FUNDER_LIST_RESPONSE_CHANGED:
			return { ...state, getFunderListResponse: null };

		case FunderActionTypes.FETCH_FUNDER_DETAILS_SUCCESS:
			return {
				...state,
				fetchFunderDetailsResponse: action.payload,
				fetchFunderDetailsArr: docodeResponse(action.payload, 'FetchFunderDetailsKey'),
			};

		case FunderActionTypes.FETCH_FUNDER_DETAILS_FAILURE:
			return { ...state, fetchFunderDetailsResponse: action.payload };

		case FunderActionTypes.FETCH_FUNDER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchFunderDetailsResponse: null, fetchFunderDetailsArr: [] };

		case FunderActionTypes.DELETE_FUNDER_DETAILS_SUCCESS:
			return { ...state, deleteFunderDetailsResponse: action.payload };

		case FunderActionTypes.DELETE_FUNDER_DETAILS_FAILURE:
			return { ...state, deleteFunderDetailsResponse: action.payload };

		case FunderActionTypes.DELETE_FUNDER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteFunderDetailsResponse: null };

		case FunderActionTypes.UPDATE_ISPUBLISH_FUNDER_SUCCESS:
			return { ...state, updateIsPublishFunder: action.payload };

		case FunderActionTypes.UPDATE_ISPUBLISH_FUNDER_FAILURE:
			return { ...state, updateIsPublishFunder: action.payload };

		case FunderActionTypes.UPDATE_ISPUBLISH_FUNDER_RESPONSE_CHANGED:
			return { ...state, updateIsPublishFunder: null };
		default:
			return state;
	}
};
