/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { decodeApiResponse } from '../../../common/common.utils';
import FamilyActionType from './family-details.types';

const INITIAL_STATE = {
	familyMemberCountData: null,
	familyMemberIncomeResponse: null,
	familyMemberListArr: [],
	fetchFamilyMemberArr: [],
	fetchFamilyMemberResp: null,
	addFamilyMemberResp: null,
	fetchParticularFamilyMemberArr: null,
	fetchParticularFamilyMemberResp: null,
	deleteFamilyMemberResp: null,
	verifyFamilyAadharOtpResp: null,
	verifyFamilyAadharOtpResult: [],
	verifyValidateFamilyAadharOtpResp: null,
	verifyValidateFamilyAadharOtpResult: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FamilyActionType.FAMILY_MEMBER_INCOME_SUCCESS:
			return { ...state, familyMemberIncomeResponse: action.payload };

		case FamilyActionType.FAMILY_MEMBER_INCOME_FAILURE:
			return { ...state, familyMemberIncomeResponse: action.payload };

		case FamilyActionType.FAMILY_MEMBER_INCOME_RESPONSE_CHANGED:
			return { ...state, familyMemberIncomeResponse: null };

		case FamilyActionType.SET_FAMILY_MEMBER_ARR_START:
			return { ...state, familyMemberListArr: action.payload };

		case FamilyActionType.FETCH_FAMILY_MEMBERS_SUCCESS:
			return {
				...state,
				fetchFamilyMemberResp: action.payload,
				fetchFamilyMemberArr: decodeApiResponse(action.payload, 'FetchMemberDataKey'),
			};

		case FamilyActionType.FETCH_FAMILY_MEMBERS_FAILURE:
			return { ...state, fetchFamilyMemberResp: action.payload };

		case FamilyActionType.FETCH_FAMILY_MEMBERS_RESPONSE_CHANGED:
			return { ...state, fetchFamilyMemberResp: null };

		case FamilyActionType.ADD_FAMILY_MEMBERS_SUCCESS:
			return { ...state, addFamilyMemberResp: action.payload };

		case FamilyActionType.ADD_FAMILY_MEMBERS_FAILURE:
			return { ...state, addFamilyMemberResp: action.payload };

		case FamilyActionType.ADD_FAMILY_MEMBERS_RESPONSE_CHANGED:
			return { ...state, addFamilyMemberResp: null };

		case FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_SUCCESS:
			return {
				...state,
				fetchParticularFamilyMemberResp: action.payload,
				fetchParticularFamilyMemberArr: decodeApiResponse(
					action.payload,
					'FetchMemberDataKey',
				),
			};

		case FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_FAILURE:
			return { ...state, fetchParticularFamilyMemberResp: action.payload };

		case FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_CHANGED:
			return { ...state, fetchParticularFamilyMemberResp: action.payload };

		case FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_OBJ_RESET:
			return { ...state, fetchParticularFamilyMemberArr: null };

		case FamilyActionType.DELETE_FAMILY_MEMBERS_SUCCESS:
			return { ...state, deleteFamilyMemberResp: action.payload };

		case FamilyActionType.DELETE_FAMILY_MEMBERS_FAILURE:
			return { ...state, deleteFamilyMemberResp: action.payload };

		case FamilyActionType.DELETE_FAMILY_MEMBERS_RESPONSE_CHANGED:
			return { ...state, deleteFamilyMemberResp: null };

		case FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyFamilyAadharOtpResp: action.payload,
				verifyFamilyAadharOtpResult: decodeApiResponse(action.payload, 'AppVerifyKYCKey'),
			};

		case FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_FAILURE:
			return { ...state, verifyFamilyAadharOtpResp: action.payload };

		case FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyFamilyAadharOtpResp: null };

		case FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyValidateFamilyAadharOtpResp: action.payload,
				verifyValidateFamilyAadharOtpResult: decodeApiResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_FAILURE:
			return { ...state, verifyValidateFamilyAadharOtpResp: action.payload };

		case FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyValidateFamilyAadharOtpResp: null };

		default:
			return state;
	}
};
