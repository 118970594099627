/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import CreditBureauReportActionTypes from './creditBureauReport.types';

import {
	creditBureauReportListGet,
	creditBureauHMReportListGet,
	creditBureauExcelListGet,
} from './creditBureauReport.service';

import {
	getCreditBureauReportDetailsSuccess,
	getCreditBureauReportDetailsFailure,
	getCreditBureauReportDetailsResponseChanged,
	fetchCreditBureauExcelSuccess,
	fetchCreditBureauExcelFailure,
	fetchCreditBureauExcelResponseChanged,
} from './creditBureauReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET BRANCH CBI REPORT DETAILS  ******************** */
export function* getBranchCBIReportList({ payload }) {
	try {
		const key = 'CreditCheckDetailsReportKey';
		const jsonData = {
			memberCreatedBy: payload.userID,
			CompanyID: payload.CompanyID,
			SearchType: payload.SearchType,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			Limit: payload.Limit,
		};
		let getCbiService = '';
		if (payload.IsHighMarkCheck === '1') {
			getCbiService = creditBureauHMReportListGet;
		} else {
			getCbiService = creditBureauReportListGet;
		}
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getCbiService, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCreditBureauReportDetailsFailure(responseData.data));
		} else {
			yield put(getCreditBureauReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCreditBureauReportDetailsFailure(error));
	}
}

export function* resetgetBranchCBIReportListResponse() {
	yield put(getCreditBureauReportDetailsResponseChanged());
}

export function* onGetBranchCBIReportListDetails() {
	yield takeLatest(
		CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_START,
		getBranchCBIReportList,
	);
}

export function* onGetBranchCBIReportListResponseReset() {
	yield takeLatest(
		CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_RESET,
		resetgetBranchCBIReportListResponse,
	);
}
/** *********************  END GET BRANCH CBI REPORT DETAILS ******************** */

/** *********************  START GET LOAN DISBURSEMENT EXCEL REPORT DETAILS  ******************** */
export function* getCreditBureauExcelList({ payload }) {
	try {
		const key = 'CreditBureauReportExcelKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.userName,
			LocationID: payload.CenterID,
			BranchID: payload.BranchID,
			GroupID: payload.groupID,
			FunderID: payload.FunderID,
			LoanType: payload.LoanType,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserLevel: payload.UserLevel,
			RequestID: payload.RequestID,
			generalCompanyID: payload.generalCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(creditBureauExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCreditBureauExcelFailure(responseData.data));
		} else {
			yield put(fetchCreditBureauExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCreditBureauExcelFailure(error));
	}
}

export function* resetGetCreditBureauExcelListResponse() {
	yield put(fetchCreditBureauExcelResponseChanged());
}

export function* onGetCreditBureauExcelListDetails() {
	yield takeLatest(
		CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_START,
		getCreditBureauExcelList,
	);
}

export function* onGetCreditBureauExcelListResponseReset() {
	yield takeLatest(
		CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_RESET,
		resetGetCreditBureauExcelListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT EXCEL REPORT LIST ******************** */

export function* branchCBIReportSaga() {
	yield all([
		call(onGetBranchCBIReportListDetails),
		call(onGetBranchCBIReportListResponseReset),
		call(onGetCreditBureauExcelListDetails),
		call(onGetCreditBureauExcelListResponseReset),
	]);
}
