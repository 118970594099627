import { takeLatest, put, all, call } from 'redux-saga/effects';
import PassbookChequeDetailsActionType from './passbook-cheque-details.types';
import {
	updatePassbookChequeSuccess,
	updatePassbookChequeFailure,
	updatePassbookChequeResponseChanged,
} from './passbook-cheque-details.action';
import { encodeJson } from '../../../enode-decode';
import { passbookChequeUpdateDetails } from './passbook-cheque-details.service';

// Start Passbook Cheque Details Add
export function* updatePassbookChequeIdDetails({ payload }) {
	try {
		const key = 'updatePassbookChequeID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberPassbookChequeImg: payload.memberPassbookChequeImg,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const passbookChequeData = encodeJson(jsonData, key);
		const responseData = yield call(passbookChequeUpdateDetails, passbookChequeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePassbookChequeFailure(responseData.data));
		} else {
			yield put(updatePassbookChequeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePassbookChequeFailure(error));
	}
}

export function* updatePassbookChequeDetailsResponseClear() {
	yield put(updatePassbookChequeResponseChanged());
}

export function* onPassbookChequeDetailsUpdateStart() {
	yield takeLatest(
		PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_START,
		updatePassbookChequeIdDetails,
	);
}

export function* onPassbookChequeDetailsUpdateResponseRest() {
	yield takeLatest(
		PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_RESPONSE_REST_START,
		updatePassbookChequeDetailsResponseClear,
	);
}
// End Passbook Cheque Details Add

export function* passbookChequeDetailsSaga() {
	yield all([
		call(onPassbookChequeDetailsUpdateStart),
		call(onPassbookChequeDetailsUpdateResponseRest),
	]);
}
