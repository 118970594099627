/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import PersonalDetailsActionType from './personal-details.types';
import {
	personalDetailsSuccess,
	personalDetailsFailure,
	personalDetailsUserResponseChanged,
} from './personal-details.action';
import { encodeJson } from '../../../enode-decode';
import { personalDetailsMember } from './personal-details.service';

// Start Member Personal Details
export function* PersonalDetails({ payload }) {
	try {
		const key = 'PersonalFamilyDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberDOB: payload.memberDOB,
			MemberAge: payload.memberAge,
			MaritalStatus: payload.maritalStatus,
			FatherName: payload.fatherName,
			MotherName: payload.motherName,
			SpouseName: payload.spouseName,
			SpouseDOB: payload.spouseDOB,
			AlternatePhoneNumber: payload.alternatePhoneNumber,
			IDProofType: payload.selectedIdProof,
			IDProofNumber: payload.idProofNumber,
			AddressProofType: payload.addressProofType,
			AddressProofNumber: payload.addressProofNumber,
			AddressProofName: payload.addressProofName,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(personalDetailsMember, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(personalDetailsFailure(responseData.data));
		} else {
			yield put(personalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(personalDetailsFailure(error));
	}
}

export function* PersonalDetailsResponseClear() {
	yield put(personalDetailsUserResponseChanged());
}

export function* onPersonalDetailsStart() {
	yield takeLatest(PersonalDetailsActionType.PERSONAL_DETAILS_START, PersonalDetails);
}

export function* onPersonalDetailsResponseReset() {
	yield takeLatest(
		PersonalDetailsActionType.PERSONAL_DETAILS_RESPONSE_REST_START,
		PersonalDetailsResponseClear,
	);
}
// End Member Personal Details

export function* personalDetailsSaga() {
	yield all([call(onPersonalDetailsStart), call(onPersonalDetailsResponseReset)]);
}
