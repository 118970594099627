/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import CreditBureauReportActionTypes from './creditBureauReport.types';

/** ***************** GET Credit Bureau Report START *********************** */
export const fetchPostCreditBureauReportStart = (getData) => ({
	type: CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_START,
	payload: getData,
});

export const getCreditBureauReportDetailsSuccess = (successData) => ({
	type: CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getCreditBureauReportDetailsFailure = (failureData) => ({
	type: CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const fetchCreditBureauReportDetailsResponseResetStart = () => ({
	type: CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_RESET,
});

export const getCreditBureauReportDetailsResponseChanged = () => ({
	type: CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET Credit Bureau Report END *********************** */

/** ***************** GET Credit Bureau Report Export START *********************** */
export const fetchCreditBureauExcelStart = (getData) => ({
	type: CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_START,
	payload: getData,
});

export const fetchCreditBureauExcelSuccess = (successData) => ({
	type: CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchCreditBureauExcelFailure = (failureData) => ({
	type: CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchCreditBureauExcelResponseResetStart = () => ({
	type: CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_CHANGED,
});

export const fetchCreditBureauExcelResponseChanged = () => ({
	type: CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_RESET,
});
/** ***************** GET Credit Bureau Report Export END *********************** */
