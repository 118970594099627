import VoterIdDetailsActionType from './voter-id-details.types';

// update VoterId
export const updateVoterIdStart = (voterIdCredentials) => ({
	type: VoterIdDetailsActionType.UPDATE_VOTER_ID_START,
	payload: voterIdCredentials,
});

export const updateVoterIdSuccess = (voterIdSuccess) => ({
	type: VoterIdDetailsActionType.UPDATE_VOTER_ID_SUCCESS,
	payload: voterIdSuccess,
});

export const updateVoterIdFailure = (voterIdFailure) => ({
	type: VoterIdDetailsActionType.UPDATE_VOTER_ID_FAILURE,
	payload: voterIdFailure,
});

export const updateVoterIdResponseResetStart = () => ({
	type: VoterIdDetailsActionType.UPDATE_VOTER_ID_RESPONSE_REST_START,
});

export const updateVoterIdResponseChanged = () => ({
	type: VoterIdDetailsActionType.UPDATE_VOTER_ID_RESPONSE_CHANGED,
});
