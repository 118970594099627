// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-param-last */
import ProfileTypes from './profile.types';
import { decodeBase64Response, docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	profileDetailsFetchResponse: null,
	getprofileDetailsArr: [],
	profileDetailsUpdateResponse: null,
	updatePasswordResponse: null,
	generateSecreteAuthKeyResponse: null,
	generateSecreteAuthKeyArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProfileTypes.GET_PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				profileDetailsFetchResponse: action.payload,
				getprofileDetailsArr: decodeBase64Response(
					action.payload,
					'FetchProfileDetailsKey',
				),
			};

		case ProfileTypes.GET_PROFILE_DETAILS_FAILURE:
			return {
				...state,
				profileDetailsFetchResponse: action.payload,
			};

		case ProfileTypes.GET_PROFILE_DETAILS_RESPONSE_CHANGED:
			return { ...state, profileDetailsFetchResponse: null };

		// PROFILE DETAILS FETCH END

		case ProfileTypes.UPDATE_PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				profileDetailsUpdateResponse: action.payload,
			};

		case ProfileTypes.UPDATE_PROFILE_DETAILS_FAILURE:
			return { ...state, profileDetailsUpdateResponse: action.payload };

		case ProfileTypes.UPDATE_PROFILE_DETAILS_RESPONSE_CHANGED:
			return { ...state, profileDetailsUpdateResponse: null };

		// PROFILE DETAILS UPDATE END

		case ProfileTypes.UPDATE_PASSWORD_SUCCESS:
			return {
				...state,
				updatePasswordResponse: action.payload,
			};

		case ProfileTypes.UPDATE_PASSWORD_FAILURE:
			return { ...state, updatePasswordResponse: action.payload };

		case ProfileTypes.UPDATE_PASSWORD_RESPONSE_CHANGED:
			return { ...state, updatePasswordResponse: null };

		// UPDATE PASSWORD END

		case ProfileTypes.GENERATE_SECRETE_AUTHKEY_SUCCESS:
			return {
				...state,
				generateSecreteAuthKeyResponse: action.payload,
				generateSecreteAuthKeyArr: docodeResponse(action.payload, 'GenerateSecreteAuthKey'),
			};

		case ProfileTypes.GENERATE_SECRETE_AUTHKEY_FAILURE:
			return { ...state, generateSecreteAuthKeyResponse: action.payload };

		case ProfileTypes.GENERATE_SECRETE_AUTHKEY_RESPONSE_CHANGED:
			return { ...state, generateSecreteAuthKeyResponse: null };

		// GENERATE SECRETE AUTHKEY END

		default:
			return state;
	}
};
