// eslint-disable-next-line import/no-unresolved
import HousePhotosActionType from './house-photos.types';

// update House Photos
export const updateHousePhotosStart = (housePhotosCredentials) => ({
	type: HousePhotosActionType.UPDATE_HOUSE_PHOTOS_START,
	payload: housePhotosCredentials,
});

export const updateHousePhotosSuccess = (housePhotosSuccess) => ({
	type: HousePhotosActionType.UPDATE_HOUSE_PHOTOS_SUCCESS,
	payload: housePhotosSuccess,
});

export const updateHousePhotosFailure = (housePhotosFailure) => ({
	type: HousePhotosActionType.UPDATE_HOUSE_PHOTOS_FAILURE,
	payload: housePhotosFailure,
});

export const updateHousePhotosResponseResetStart = () => ({
	type: HousePhotosActionType.UPDATE_HOUSE_PHOTOS_RESPONSE_REST_START,
});

export const updateHousePhotosResponseChanged = () => ({
	type: HousePhotosActionType.UPDATE_HOUSE_PHOTOS_RESPONSE_CHANGED,
});
