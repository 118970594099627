/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import WorkFlowManagementActionTypes from './workflowManagement.types';
import {
	AllRoleListGet,
	AllQuestionnaireListGet,
	creditAppraisalDetailsAdd,
	AllCreditAppraisalListGet,
	creditAppraisalDetailsDelete,
} from './workflowManagement.service';

import {
	getAllRoleDetailsSuccess,
	getAllRoleDetailsFailure,
	getAllRoleDetailsResponseChanged,
	getAllQuestionnaireDetailsSuccess,
	getAllQuestionnaireDetailsFailure,
	getAllQuestionnaireDetailsResponseChanged,
	addCreditAppraisalDetailsSuccess,
	addCreditAppraisalDetailsFailure,
	addCreditAppraisalDetailsResponseChanged,
	getAllCreditAppraisalDetailsSuccess,
	getAllCreditAppraisalDetailsFailure,
	getAllCreditAppraisalDetailsResponseChanged,
	deleteCreditAppraisalDetailsSuccess,
	deleteCreditAppraisalDetailsFailure,
	deleteCreditAppraisalDetailsResponseChanged,
} from './workflowManagement.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START GET ROLE LIST  ******************** */
export function* AllRoleDetailsListGet({ payload }) {
	try {
		const key = 'FetchAllRoleListForCreditAppraisalListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllRoleListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllRoleDetailsFailure(responseData.data));
		} else {
			yield put(getAllRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllRoleDetailsFailure(error));
	}
}

export function* resetGetAllRoleListResponse() {
	yield put(getAllRoleDetailsResponseChanged());
}

export function* onGetAllRoleListDetails() {
	yield takeLatest(WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_START, AllRoleDetailsListGet);
}

export function* onGetAllRoleListResponseReset() {
	yield takeLatest(
		WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_RESPONSE_RESET,
		resetGetAllRoleListResponse,
	);
}
/** *********************  END GET ROLE LIST ******************** */

/** *********************  START GET ROLE LIST  ******************** */
export function* AllQuestionnaireDetailsListGet({ payload }) {
	try {
		const key = 'GetAllQuestionnaireListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllQuestionnaireListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllQuestionnaireDetailsFailure(responseData.data));
		} else {
			yield put(getAllQuestionnaireDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllQuestionnaireDetailsFailure(error));
	}
}

export function* resetGetAllQuestionnaireListResponse() {
	yield put(getAllQuestionnaireDetailsResponseChanged());
}

export function* onGetAllQuestionnaireListDetails() {
	yield takeLatest(
		WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_START,
		AllQuestionnaireDetailsListGet,
	);
}

export function* onGetAllQuestionnaireListResponseReset() {
	yield takeLatest(
		WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_RESPONSE_RESET,
		resetGetAllQuestionnaireListResponse,
	);
}
/** *********************  END GET ROLE LIST ******************** */

/** *********************  START ADD CENTER DETAILS  ******************** */
export function* createCreditAppraisal({ payload }) {
	try {
		const key = 'AddCreditAppraisalKey';
		const jsonData = {
			AppraisalType: payload.AppraisalType,
			ScreenName: payload.ScreenName,
			Questionairre: payload.Questionairre,
			Weightage: payload.Weightage,
			FirstAppraisal: payload.FirstAppraisal,
			SecondAppraisal: payload.SecondAppraisal,
			ThirdAppraisal: payload.ThirdAppraisal,
			FourthAppraisal: payload.FourthAppraisal,
			FifthAppraisal: payload.FifthAppraisal,
			NoOfApproval: payload.NoOfApproval,
			AreaID: payload.AreaID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(creditAppraisalDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addCreditAppraisalDetailsFailure(responseData.data));
		} else {
			yield put(addCreditAppraisalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCreditAppraisalDetailsFailure(error));
	}
}

export function* resetAddCreditAppraisalResponse() {
	yield put(addCreditAppraisalDetailsResponseChanged());
}

export function* onCreateCreditAppraisalDetails() {
	yield takeLatest(
		WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_START,
		createCreditAppraisal,
	);
}

export function* onCreateCreditAppraisalResponseReset() {
	yield takeLatest(
		WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET,
		resetAddCreditAppraisalResponse,
	);
}
/** *********************  END ADD CENTER DETAILS ******************** */

/** *********************  START GET ROLE LIST  ******************** */
export function* AllCreditAppraisalDetailsListGet({ payload }) {
	try {
		const key = 'GetAllCreditAppraisalListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllCreditAppraisalListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllCreditAppraisalDetailsFailure(responseData.data));
		} else {
			yield put(getAllCreditAppraisalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllCreditAppraisalDetailsFailure(error));
	}
}

export function* resetGetAllCreditAppraisalListResponse() {
	yield put(getAllCreditAppraisalDetailsResponseChanged());
}

export function* onGetAllCreditAppraisalListDetails() {
	yield takeLatest(
		WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_START,
		AllCreditAppraisalDetailsListGet,
	);
}

export function* onGetAllCreditAppraisalListResponseReset() {
	yield takeLatest(
		WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_RESET,
		resetGetAllCreditAppraisalListResponse,
	);
}
/** *********************  END GET ROLE LIST ******************** */

/** *********************  START DELETE CENTER DETAILS  ******************** */
export function* deleteCreditAppraisal({ payload }) {
	try {
		const key = 'DeleteCreditAppraisalKey';
		const jsonData = {
			AppraisalID: payload.AppraisalID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(creditAppraisalDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteCreditAppraisalDetailsFailure(responseData.data));
		} else {
			yield put(deleteCreditAppraisalDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteCreditAppraisalDetailsFailure(error));
	}
}

export function* resetDeleteCreditAppraisalResponse() {
	yield put(deleteCreditAppraisalDetailsResponseChanged());
}

export function* onDeleteCreditAppraisalDetails() {
	yield takeLatest(
		WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_START,
		deleteCreditAppraisal,
	);
}

export function* onDeleteCreditAppraisalResponseReset() {
	yield takeLatest(
		WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET,
		resetDeleteCreditAppraisalResponse,
	);
}
/** *********************  END DELETE CENTER DETAILS ******************** */

export function* workflowManagementSaga() {
	yield all([
		call(onGetAllRoleListDetails),
		call(onGetAllRoleListResponseReset),
		call(onGetAllQuestionnaireListDetails),
		call(onGetAllQuestionnaireListResponseReset),
		call(onCreateCreditAppraisalDetails),
		call(onCreateCreditAppraisalResponseReset),
		call(onGetAllCreditAppraisalListDetails),
		call(onGetAllCreditAppraisalListResponseReset),
		call(onDeleteCreditAppraisalDetails),
		call(onDeleteCreditAppraisalResponseReset),
	]);
}
