/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import loginActionTypes from './login.types';
import {
	UserLogin,
	forgotPasswordUpdate,
	getUserDetailsCall,
	updateUserPasswordCall,
} from './login.services';
import {
	signInSuccess,
	signInFailure,
	logOut,
	signInChanged,
	forgotPasswordSuccess,
	forgotPasswordFailure,
	forgotPasswordChanged,
	getUserDetailsSuccess,
	getUserDetailsFailure,
	getUserDetailsChanged,
	updateUserPasswordSuccess,
	updateUserPasswordFailure,
	updateUserPasswordChanged,
} from './login.actions';
import { encodeJson } from '../enode-decode';
import generalTypesAction from '../genera.types';

export function* singInWithPhoneNumber({ payload: { values } }) {
	try {
		const jsonData = {
			UserName: values.phoneNumber,
			UserPassword: values.password,
			UserTokenID: '',
		};
		const loginData = encodeJson(jsonData, generalTypesAction.LOGIN_KEY);
		const responseData = yield call(UserLogin, loginData);
		if (responseData.data.statusCode === '500') {
			yield put(signInFailure(responseData.data));
		} else {
			yield put(signInSuccess(responseData.data));
		}
	} catch (error) {
		yield put(signInFailure(error));
	}
}
export function* makeUserLogout() {
	yield put(logOut());
}

export function* makeUserLoginReset() {
	yield put(signInChanged());
}

export function* userLogout() {
	yield takeLatest(loginActionTypes.SIGN_IN_LOGOUT, makeUserLogout);
}

export function* onEmailSignInReset() {
	yield takeLatest(loginActionTypes.SIGN_IN_RESET, makeUserLoginReset);
}

export function* onEmailSignInStart() {
	yield takeLatest(loginActionTypes.SIGN_IN_START, singInWithPhoneNumber);
}

/** *********************  START Forgot Password DETAILS  ******************** */
export function* forgotPasswordDetails({ payload }) {
	try {
		const key = 'FORGOTPASSWORDKEY';
		const jsonData = {
			UserName: payload.MobileNumber,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(forgotPasswordUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(forgotPasswordFailure(responseData.data));
		} else {
			yield put(forgotPasswordSuccess(responseData.data));
		}
	} catch (error) {
		yield put(forgotPasswordFailure(error));
	}
}

export function* resetForgotPasswordResponse() {
	yield put(forgotPasswordChanged());
}

export function* onForgotPasswordDetails() {
	yield takeLatest(loginActionTypes.FORGOT_PASSWORD_START, forgotPasswordDetails);
}

export function* onForgotPasswordDetailsResponseReset() {
	yield takeLatest(loginActionTypes.FORGOT_PASSWORD_RESET, resetForgotPasswordResponse);
}
/** *********************  END ADD Forgotpassword DETAILS ******************** */

/** *********************  START USER DETAILS  ******************** */
export function* getUserDetailsDetails({ payload }) {
	try {
		const key = 'GETUSERDETAILSKEY';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getUserDetailsCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getUserDetailsFailure(responseData.data));
		} else {
			yield put(getUserDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getUserDetailsFailure(error));
	}
}

export function* resetGetUserDetailsResponse() {
	yield put(getUserDetailsChanged());
}

export function* onGetUserDetails() {
	yield takeLatest(loginActionTypes.GET_USER_DETAILS_START, getUserDetailsDetails);
}

export function* onGetUserDetailsResponseReset() {
	yield takeLatest(loginActionTypes.GET_USER_DETAILS_RESET, resetGetUserDetailsResponse);
}
/** *********************  END USER DETAILS ******************** */

/** *********************  START USER PASSWORD DETAILS  ******************** */
export function* updateUserPasswordDetails({ payload }) {
	try {
		const key = 'UPDATEUSERPASSWORDKEY';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserPhoneNumber: payload.UserPhoneNumber,
			GeneralUserID: payload.userID,
			ConfirmPassword: payload.confirmPassword,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateUserPasswordCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateUserPasswordFailure(responseData.data));
		} else {
			yield put(updateUserPasswordSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateUserPasswordFailure(error));
	}
}

export function* resetUpdateUserPasswordResponse() {
	yield put(updateUserPasswordChanged());
}

export function* onUpdateUserPassword() {
	yield takeLatest(loginActionTypes.UPDATE_USER_PASSWORD_START, updateUserPasswordDetails);
}

export function* onUpdateUserPasswordResponseReset() {
	yield takeLatest(loginActionTypes.UPDATE_USER_PASSWORD_RESET, resetUpdateUserPasswordResponse);
}
/** *********************  END USER PASSWORD DETAILS ******************** */

export function* loginSaga() {
	yield all([
		call(onEmailSignInStart),
		call(userLogout),
		call(onEmailSignInReset),
		call(onForgotPasswordDetails),
		call(onForgotPasswordDetailsResponseReset),
		call(onGetUserDetails),
		call(onGetUserDetailsResponseReset),
		call(onUpdateUserPassword),
		call(onUpdateUserPasswordResponseReset),
	]);
}
