/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import NomineeDetailsActionType from './basic-nominee-details.types';
import {
	nomineeDetailsAddSuccess,
	nomineeDetailsAddFailure,
	nomineeDetailsAddUserResponseChanged,
	nomineeAddressListSuccess,
	nomineeAddressListFailure,
	nomineeAddressListUserResponseChanged,
	verifyNomineeAadharOtpResponseChanged,
	verifyNomineeAadharOtpFailure,
	verifyNomineeAadharOtpSuccess,
	verifyValidateNomineeAadharOtpResponseChanged,
	verifyValidateNomineeAadharOtpFailure,
	verifyValidateNomineeAadharOtpSuccess,
	fetchNomineeRelationshipFailure,
	fetchNomineeRelationshipSuccess,
	fetchNomineeRelationshipResponseChanged,
} from './basic-nominee-details.action';
import { encodeJson } from '../../../enode-decode';
import {
	nomineeDetailsAdd,
	getNomineeAddressList,
	verifyNomineeAadharOtpAPI,
	verifyValidateNomineeAadharOtpAPI,
	getFetchNomineeRelationship,
} from './basic-nominee-details.service';

// Start Nominee Details Add
export function* AddNomineeDetails({ payload }) {
	try {
		const key = 'AddMemberNomineeDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			NomineeRelationship: payload.nomineeRelationship,
			NomineeName: payload.nomineeName,
			NomineeDOB: payload.nomineeDOB,
			Gender: payload.gender,
			NomineeFatherName: payload.nomineeFatherName,
			NomineeAadharNumber: payload.nomineeAadharNumber,
			NomineeIDProofType: payload.nomineeIDProofType,
			NomineeIDProofNumber: payload.nomineeIDProofNumber,
			NomineePhoneNumber: payload.nomineePhoneNumber,
			NomineePinCode: payload.pincode,
			NomineeDoorNo: payload.doorNo,
			MemberNomineeAddressStreet: payload.addressStreet,
			NomineeCountry: payload.country,
			NomineeState: payload.state,
			NomineeDistrict: payload.district,
			NomineeCity: payload.city,
			OfficerID: payload.userID,
			IsAadharScanned: payload.isAadharScanned,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(nomineeDetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(nomineeDetailsAddFailure(responseData.data));
		} else {
			yield put(nomineeDetailsAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(nomineeDetailsAddFailure(error));
	}
}

export function* AddNomineeDetailsResponseClear() {
	yield put(nomineeDetailsAddUserResponseChanged());
}

export function* onNomineeDetailsAddStart() {
	yield takeLatest(NomineeDetailsActionType.NOMINEE_DETAILS_ADD_START, AddNomineeDetails);
}

export function* onNomineeDetailsAddResponseRest() {
	yield takeLatest(
		NomineeDetailsActionType.NOMINEE_DETAILS_ADD_RESPONSE_REST_START,
		AddNomineeDetailsResponseClear,
	);
}
// End Nominee Details Add

// Start Nominee Address List
export function* AddressListNominee({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getNomineeAddressList, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(nomineeAddressListFailure(responseData.data));
		} else {
			yield put(nomineeAddressListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(nomineeAddressListFailure(error));
	}
}

export function* AddressListNomineeResponseClear() {
	yield put(nomineeAddressListUserResponseChanged());
}

export function* onNomineeAddressListStart() {
	yield takeLatest(
		NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_START,
		AddressListNominee,
	);
}

export function* onNomineeAddressListResponseRest() {
	yield takeLatest(
		NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_REST_START,
		AddressListNomineeResponseClear,
	);
}
// End Nominee Address List

// Start verify Aadhar Otp
export function* toVerifyNomineeAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(verifyNomineeAadharOtpAPI, verifyData, payload.serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(verifyNomineeAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyNomineeAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyNomineeAadharOtpFailure(error));
	}
}

export function* VerifyNomineeAadharOtpResponseClear() {
	yield put(verifyNomineeAadharOtpResponseChanged());
}

export function* onVerifyNomineeAadharOtpStart() {
	yield takeLatest(
		NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_START,
		toVerifyNomineeAadharOtp,
	);
}

export function* onVerifyNomineeAadharOtpRest() {
	yield takeLatest(
		NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyNomineeAadharOtpResponseClear,
	);
}
// End Verify Aadhar Otp

// Start verify validate Aadhar Otp
export function* toVerifyValidateNomineeAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			RandNum: payload.randNum,
			Otp: payload.otp,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyValidateNomineeAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyValidateNomineeAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyValidateNomineeAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyValidateNomineeAadharOtpFailure(error));
	}
}

export function* VerifyValidateNomineeAadharOtpResponseClear() {
	yield put(verifyValidateNomineeAadharOtpResponseChanged());
}

export function* onVerifyValidateNomineeAadharOtpStart() {
	yield takeLatest(
		NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_START,
		toVerifyValidateNomineeAadharOtp,
	);
}

export function* onVerifyValidateNomineeAadharOtpRest() {
	yield takeLatest(
		NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyValidateNomineeAadharOtpResponseClear,
	);
}
// End Verify validate Aadhar Otp

// Start Fetch Nominee Relationship List
export function* fetchNomineeRelationshipList({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Relationship: payload.coApplicantRelationship,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getFetchNomineeRelationship, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchNomineeRelationshipFailure(responseData.data));
		} else {
			yield put(fetchNomineeRelationshipSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchNomineeRelationshipFailure(error));
	}
}
export function* fetchNomineeRelationshipResponseClear() {
	yield put(fetchNomineeRelationshipResponseChanged());
}
export function* onNomineeRelationshipListStart() {
	yield takeLatest(
		NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_START,
		fetchNomineeRelationshipList,
	);
}
export function* onNomineeRelationshipListResponseRest() {
	yield takeLatest(
		NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_RESET_START,
		fetchNomineeRelationshipResponseClear,
	);
}
// End Fetch Nominee Relationship List

export function* nomineeDetailsSaga() {
	yield all([
		call(onNomineeDetailsAddStart),
		call(onNomineeDetailsAddResponseRest),
		call(onNomineeAddressListStart),
		call(onNomineeAddressListResponseRest),
		call(onVerifyNomineeAadharOtpStart),
		call(onVerifyNomineeAadharOtpRest),
		call(onVerifyValidateNomineeAadharOtpStart),
		call(onVerifyValidateNomineeAadharOtpRest),
		call(onNomineeRelationshipListStart),
		call(onNomineeRelationshipListResponseRest),
	]);
}
