/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import highmarkRulesTypes from './highmarkRules.types';

/** ***************** ADD HIGHMARK RULES START *********************** */
export const addHighmarkRulesStart = (addData) => ({
	type: highmarkRulesTypes.ADD_HIGHMARK_RULES_START,
	payload: addData,
});

export const addHighmarkRulesSuccess = (successData) => ({
	type: highmarkRulesTypes.ADD_HIGHMARK_RULES_SUCCESS,
	payload: successData,
});

export const addHighmarkRulesFailure = (failureData) => ({
	type: highmarkRulesTypes.ADD_HIGHMARK_RULES_FAILURE,
	payload: failureData,
});

export const addHighmarkRulesResponseResetStart = () => ({
	type: highmarkRulesTypes.ADD_HIGHMARK_RULES_RESPONSE_RESET,
});

export const addHighmarkRulesResponseChanged = () => ({
	type: highmarkRulesTypes.ADD_HIGHMARK_RULES_RESPONSE_CHANGED,
});

/** ***************** ADD HIGHMARK RULES END *********************** */

/** ***************** FETCH HIGHMARK RULES START *********************** */
export const fetchHighmarkRulesStart = (fetchData) => ({
	type: highmarkRulesTypes.FETCH_HIGHMARK_RULES_START,
	payload: fetchData,
});

export const fetchHighmarkRulesSuccess = (successData) => ({
	type: highmarkRulesTypes.FETCH_HIGHMARK_RULES_SUCCESS,
	payload: successData,
});

export const fetchHighmarkRulesFailure = (failureData) => ({
	type: highmarkRulesTypes.FETCH_HIGHMARK_RULES_FAILURE,
	payload: failureData,
});

export const fetchHighmarkRulesResponseResetStart = () => ({
	type: highmarkRulesTypes.FETCH_HIGHMARK_RULES_RESPONSE_RESET,
});

export const fetchHighmarkRulesResponseChanged = () => ({
	type: highmarkRulesTypes.FETCH_HIGHMARK_RULES_RESPONSE_CHANGED,
});
