/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from "redux-saga/effects";

import MemberMandatoryAccessActionTypes from "./memberMandatoryAccess.types";

import {
  fetchMandatoryMemberAccessType,
  updateMandatoryMemberAccessType,
} from "./memberMandatoryAccess.service";

import {
  fetchMemberMandatoryAccessSuccess,
  fetchMemberMandatoryAccessFailure,
  fetchMemberMandatoryAccessResponseChanged,
  updateMemberMandatoryAccessSuccess,
  updateMemberMandatoryAccessFailure,
  updateMemberMandatoryAccessResponseChanged,
} from "./memberMandatoryAccess.action";
import { encodeJson } from "../../enode-decode";

/** *********************  START FETCH MemberMandatory DETAILS LIST  ******************** */
export function* fetchMemberMandatoryAccessList({ payload }) {
  try {
    const key = "MemberMandatoryAccessKey";
    const jsonData = {
      CompanyID: payload.companyID,
      MandatoryMode: payload.mandatoryMode,
      DatabaseName: payload.databaseName,
    };
    const encodeData = encodeJson(jsonData, key);
    const responseData = yield call(fetchMandatoryMemberAccessType, encodeData);
    if (responseData.data.statusCode === "100") {
      yield put(fetchMemberMandatoryAccessFailure(responseData.data));
    } else {
      yield put(fetchMemberMandatoryAccessSuccess(responseData.data));
    }
  } catch (error) {
    yield put(fetchMemberMandatoryAccessFailure(error));
  }
}

export function* resetFetchListMemberMandatoryAccessResponse() {
  yield put(fetchMemberMandatoryAccessResponseChanged());
}

export function* onFetchListMemberMandatoryAccess() {
  yield takeLatest(
    MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_START,
    fetchMemberMandatoryAccessList
  );
}

export function* onFetchListMemberMandatoryAccessResponseReset() {
  yield takeLatest(
    MemberMandatoryAccessActionTypes.FETCH_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET,
    resetFetchListMemberMandatoryAccessResponse
  );
}

/** *********************  END FETCH MemberMandatory DETAILS LIST ******************** */

/** *********************  START UPDATE MemberMandatory DETAILS LIST  ******************** */
export function* updateMemberMandatoryAccessList({ payload }) {
  try {
    const key = "MemberMandatoryUpdateKey";
    const jsonData = {
      companyID: payload.companyID,
      DatabaseName: payload.databaseName,
	    UserID: payload.userID,
	    MandatoryMode: payload.mandatoryMode,
      aadharNumber: payload.AadharNumber,
      aadharPhoto: payload.AadharPhoto,
      panCard: payload.PanCard,
      IDProof1: payload.iDProof1,
      IDProof1Image: payload.iDProof1Image,
      IDProof2: payload.iDProof2,
      IDProof2Image: payload.iDProof2Image,
      Gender: payload.gender,
      Photo: payload.photo,
      Signature: payload.signature,
      Name: payload.name,
      DOB: payload.dOB,
      Age: payload.age,
      Phone: payload.phone,
      AltPhone: payload.altPhone,
      MarritialStatus: payload.marritialStatus,
      FatherName: payload.fatherName,
      MotherName: payload.motherName,
      SpouseName: payload.spouseName,
      SpouseDOB: payload.spouseDOB,
      DoorNo: payload.doorNo,
      StreetAddress: payload.streetAddress,
      Country: payload.country,
      State: payload.state,
      City: payload.city,
      PinCode: payload.pinCode,
      District: payload.district,
      Village: payload.village,
      CurrentDoorNo: payload.currentDoorNo,
      CurrentAddress: payload.currentAddress,
      CurrentAddressSince: payload.currentAddressSince,
      CurrentAddressCountry: payload.currentAddressCountry,
      CurrentAddressState: payload.currentAddressState,
      CurrentAddressCity: payload.currentAddressCity,
      CurrentAddressPin: payload.currentAddressPin,
      CurrentAddressDistrict: payload.currentAddressDistrict,
      CurrentAddressLocalArea: payload.currentAddressLocalArea,
      Citizen: payload.citizen,
      FamilyType: payload.familyType,
      FamilyCount: payload.familyCount,
      FamilyMemberName: payload.familyMemberName,
      FamilyMemberAadhar: payload.familyMemberAadhar,
      FamilyMemberGender: payload.familyMemberGender,
      FamilyMemberAge: payload.familyMemberAge,
      FamilyMemberRelationship: payload.familyMemberRelationship,
      FamilyMemberEducation: payload.familyMemberEducation,
      FamilyMemberOccupation: payload.familyMemberOccupation,
      FamilyTotalIncome: payload.familyTotalIncome,
      FamilyMonthlyIncode: payload.familyMonthlyIncode,
      FamilyMonthlyExpense: payload.familyMonthlyExpense,
      FamilyMontlyExtraSpend: payload.familyMontlyExtraSpend,
      DOJ: payload.dOJ,
      EconomicActivity: payload.economicActivity,
      EconomicActivityType: payload.economicActivityType,
      Center: payload.center,
      ApplicationAmount: payload.applicationAmount,
      AnnualIncome: payload.annualIncome,
      MonthlyIncome: payload.monthlyIncome,
      otherIncome: payload.OtherIncome,
      preferredLanguage: payload.PreferredLanguage,
      Disability: payload.disability,
      BloodGroup: payload.bloodGroup,
      Identification: payload.identification,
      EducationQualification: payload.educationQualification,
      Religion: payload.religion,
      Caste: payload.caste,
      EmailID: payload.emailID,
      RuralUrban: payload.ruralUrban,
      LandValue: payload.landValue,
      NomineeRelationship: payload.nomineeRelationship,
      NomineeName: payload.nomineeName,
      NomineeGender: payload.nomineeGender,
      NomineeFatherName: payload.nomineeFatherName,
      NomineeDOB: payload.nomineeDOB,
      NomineeAge: payload.nomineeAge,
      NomineeAadhar: payload.nomineeAadhar,
      NomineeAadharPhoto: payload.nomineeAadharPhoto,
      NomineeIDProff: payload.nomineeIDProff,
      NomineeIDProffImage: payload.nomineeIDProffImage,
      NomineePhoneNumber: payload.nomineePhoneNumber,
      NomineeCurrentDoorNo: payload.nomineeCurrentDoorNo,
      NomineeCurrentAddress: payload.nomineeCurrentAddress,
      NomineePhoto: payload.nomineePhoto,
      HouseType: payload.houseType,
      DurationOfStay: payload.durationOfStay,
      NoOfRoom: payload.noOfRoom,
      FacilityType: payload.facilityType,
      AgricultureLand: payload.agricultureLand,
      LiveStock: payload.liveStock,
      AssetType: payload.assetType,
      BankAccNumber: payload.bankAccNumber,
      ReBankAccNUmber: payload.reBankAccNUmber,
      AccHolderName: payload.accHolderName,
      IFSCCode: payload.ifscCode,
      BankName: payload.bankName,
      BankBranchName: payload.bankBranchName,
      BankTransaction: payload.bankTransaction,
      RBIStatus: payload.rbiStatus,
      AccountType: payload.accountType,
      NeedMemberAgeLimit: payload.needMemberAgeLimit,
      NeedNomineeAgeLimit: payload.needNomineeAgeLimit,
      NeedSpouseAgeLimit: payload.needSpouseAgeLimit,
      NeedFamilyAgeLimit: payload.needFamilyAgeLimit,
      NeedCoApplicantAgeLimit: payload.needCoApplicantAgeLimit,
      MemberMinAge: payload.memberMinAge,
      MemberMaxAge: payload.memberMaxAge,
      NomineeMinAge: payload.nomineeMinAge,
      NomineeMaxAge: payload.nomineeMaxAge,
      SpouseMinAge: payload.spouseMinAge,
      SpouseMaxAge: payload.spouseMaxAge,
      FamilyMinAge: payload.familyMinAge,
      FamilyMaxAge: payload.familyMaxAge,
      CoApplicantMinAge: payload.coApplicantMinAge,
      CoApplicantMaxAge: payload.coApplicantMaxAge,
      CoApplicantName: payload.coApplicantName,
      CoApplicantDOB: payload.coApplicantDOB,
      CoApplicantRelationship: payload.coApplicantRelationship,
      CoApplicantOccupation: payload.coApplicantOccupation,
      CoApplicantPhoneNumber: payload.coApplicantPhoneNumber,
      CoApplicantMonthlyIncome: payload.coApplicantMonthlyIncome,
      CoApplicantAadharNumber: payload.coApplicantAadharNumber,
      CoApplicantIDProof: payload.coApplicantIDProof,
      CoApplicantIDProofNumber: payload.coApplicantIDProofNumber,
      CoApplicantAddressProof: payload.coApplicantAddressProof,
      CoApplicantAddressProofNumber: payload.coApplicantAddressProofNumber,
      CoApplicantAadharFront: payload.coApplicantAadharFront,
      CoApplicantAadharBack: payload.coApplicantAadharBack,
      CoApplicantIDProofFront: payload.coApplicantIDProofFront,
      CoApplicantIDProofBack: payload.coApplicantIDProofBack,
      CoApplicantAddressProofFront: payload.coApplicantAddressProofFront,
      CoApplicantAddressProofBack: payload.coApplicantAddressProofBack,
      CoApplicantPhoto: payload.coApplicantPhoto,
      CoApplicantTogetherPhoto: payload.coApplicantTogetherPhoto,
      CoApplicantAgreement: payload.coApplicantAgreement,
      aadharBackPhoto: payload.AadharBackPhoto,
      IDProof1BackImage: payload.idProof1BackImage,
      IDProof2BackImage: payload.idProof2BackImage,
      NomineeAadharBackPhoto: payload.nomineeAadharBackPhoto,
      NomineeIDProffBackImage: payload.nomineeIDProffBackImage,
      HouseImage1: payload.houseImage1,
      HouseImage2: payload.houseImage2,
      HouseImage3: payload.houseImage3,
      HouseImage4: payload.houseImage4,
      HouseImage5: payload.houseImage5,
      PassbookImage: payload.passbookImage,
      coApplicantDoorNumber: payload.CoApplicantDoorNumber,
      coApplicantStreet: payload.CoApplicantStreet,
      coApplicantCity: payload.CoApplicantCity,
      coApplicantDistrict: payload.CoApplicantDistrict,
      coApplicantState: payload.CoApplicantState,
      coApplicantCountry: payload.CoApplicantCountry,
      coApplicantPincode: payload.CoApplicantPincode,
      coApplicantMaritalStatus: payload.CoApplicantMaritalStatus,
      coApplicantRelationShipWithMember:
        payload.CoApplicantRelationShipWithMember,
      coApplicantGender: payload.CoApplicantGender,
      coApplicantLoanAppliedAmount: payload.CoApplicantLoanAppliedAmount,
      NOCDocument1: payload.nocDocument1,
      NOCDocument2: payload.nocDocument2,
      NOCDocument3: payload.nocDocument3,
      NeedCenterOnCrif: payload.needCenterOnCrif,
      NeedFamilyMemberChildrenCount: payload.needFamilyMemberChildrenCount,
      NeedPhoneAvailability: payload.needPhoneAvailability,
      ProductType: payload.productType,
    };
    const encodeData = encodeJson(jsonData, key);
	debugger;
    const responseData = yield call(
      updateMandatoryMemberAccessType,
      encodeData
    );
    if (responseData.data.statusCode === "100") {
      yield put(updateMemberMandatoryAccessFailure(responseData.data));
    } else {
      yield put(updateMemberMandatoryAccessSuccess(responseData.data));
    }
  } catch (error) {
    yield put(updateMemberMandatoryAccessFailure(error));
  }
}

export function* resetUpdateMemberMandatoryAccessResponse() {
  yield put(updateMemberMandatoryAccessResponseChanged());
}

export function* onUpdateMemberMandatoryAccess() {
  yield takeLatest(
    MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_START,
    updateMemberMandatoryAccessList
  );
}

export function* onUpdateMemberMandatoryAccessResponseReset() {
  yield takeLatest(
    MemberMandatoryAccessActionTypes.UPDATE_MEMBER_MANDATORY_ACCESS_RESPONSE_RESET,
    resetUpdateMemberMandatoryAccessResponse
  );
}

/** *********************  END UPDATE MemberMandatory DETAILS LIST ******************** */

export function* memberMandatoryAccessMasterSaga() {
  yield all([
    call(onFetchListMemberMandatoryAccess),
    call(onFetchListMemberMandatoryAccessResponseReset),
    call(onUpdateMemberMandatoryAccess),
    call(onUpdateMemberMandatoryAccessResponseReset),
  ]);
}
