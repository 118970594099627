const AuditingToolActionTypes = {

    GET_AUDITING_TOOL_LIST_START: 'get_auditing_tool_start',
	GET_AUDITING_TOOL_LIST_SUCCESS: 'get_auditing_tool_success',
	GET_AUDITING_TOOL_LIST_FAILURE: 'get_auditing_tool_failure',
	GET_AUDITING_TOOL_LIST_RESPONSE_RESET: 'get_auditing_tool_response_reset',
	GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED: 'get_auditing_tool_response_changed',

	FETCH_AUDITING_TOOL_LIST_START: 'FETCH_AUDITING_TOOL_LIST_START',
	FETCH_AUDITING_TOOL_LIST_SUCCESS: 'FETCH_AUDITING_TOOL_LIST_SUCCESS',
	FETCH_AUDITING_TOOL_LIST_FAILURE: 'FETCH_AUDITING_TOOL_LIST_FAILURE',
	FETCH_AUDITING_TOOL_LIST_RESET: 'FETCH_AUDITING_TOOL_LIST_RESET',
	FETCH_AUDITING_TOOL_LIST_CHANGED: 'FETCH_AUDITING_TOOL_LIST_CHANGED',

	GET_QUESTIONNAIRE_START: 'GET_QUESTIONNAIRE_START',
	GET_QUESTIONNAIRE_SUCCESS: 'GET_QUESTIONNAIRE_SUCCESS',
	GET_QUESTIONNAIRE_FAILURE: 'GET_QUESTIONNAIRE_FAILURE',
	GET_QUESTIONNAIRE_RESPONSE_RESET: 'GET_QUESTIONNAIRE_RESPONSE_RESET',
	GET_QUESTIONNAIRE_RESPONSE_CHANGED: 'GET_QUESTIONNAIRE_RESPONSE_CHANGED',

	GET_TRAINING_PROPOSAL_MEMBERS_START: 'GET_TRAINING_PROPOSAL_MEMBERS_START',
	GET_TRAINING_PROPOSAL_MEMBERS_SUCCESS: 'GET_TRAINING_PROPOSAL_MEMBERS_SUCCESS',
	GET_TRAINING_PROPOSAL_MEMBERS_FAILURE: 'GET_TRAINING_PROPOSAL_MEMBERS_FAILURE',
	GET_TRAINING_PROPOSAL_MEMBERS_RESET: 'GET_TRAINING_PROPOSAL_MEMBERS_RESET',
	GET_TRAINING_PROPOSAL_MEMBERS_CHANGED: 'GET_TRAINING_PROPOSAL_MEMBERS_CHANGED',

	ADD_TRAINING_PROPOSAL_START: 'ADD_TRAINING_PROPOSAL_START',
	ADD_TRAINING_PROPOSAL_SUCCESS: 'ADD_TRAINING_PROPOSAL_SUCCESS',
	ADD_TRAINING_PROPOSAL_FAILURE: 'ADD_TRAINING_PROPOSAL_FAILURE',
	ADD_TRAINING_PROPOSAL_RESET: 'ADD_TRAINING_PROPOSAL_RESET',
	ADD_TRAINING_PROPOSAL_CHANGED: 'ADD_TRAINING_PROPOSAL_CHANGED',

	UPDATE_TRAINING_PROPOSAL_START: 'UPDATE_TRAINING_PROPOSAL_START',
	UPDATE_TRAINING_PROPOSAL_SUCCESS: 'UPDATE_TRAINING_PROPOSAL_SUCCESS',
	UPDATE_TRAINING_PROPOSAL_FAILURE: 'UPDATE_TRAINING_PROPOSAL_FAILURE',
	UPDATE_TRAINING_PROPOSAL_RESET: 'UPDATE_TRAINING_PROPOSAL_RESET',
	UPDATE_TRAINING_PROPOSAL_CHANGED: 'UPDATE_TRAINING_PROPOSAL_CHANGED',

	DELETE_TRAINING_PROPOSAL_START: 'DELETE_TRAINING_PROPOSAL_START',
	DELETE_TRAINING_PROPOSAL_SUCCESS: 'DELETE_TRAINING_PROPOSAL_SUCCESS',
	DELETE_TRAINING_PROPOSAL_FAILURE: 'DELETE_TRAINING_PROPOSAL_FAILURE',
	DELETE_TRAINING_PROPOSAL_RESET: 'DELETE_TRAINING_PROPOSAL_RESET',
	DELETE_TRAINING_PROPOSAL_CHANGED: 'DELETE_TRAINING_PROPOSAL_CHANGED',
	
	GET_ALL_SUPERVISER_LIST_START: 'GET_ALL_SUPERVISER_LIST_START',
	GET_ALL_SUPERVISER_LIST_SUCCESS: 'GET_ALL_SUPERVISER_LIST_SUCCESS',
	GET_ALL_SUPERVISER_LIST_FAILURE: 'GET_ALL_SUPERVISER_LIST_FAILURE',
	GET_ALL_SUPERVISER_LIST_RESET: 'GET_ALL_SUPERVISER_LIST_RESET',
	GET_ALL_SUPERVISER_LIST_CHANGED: 'GET_ALL_SUPERVISER_LIST_CHANGED',

	APPROVE_TRAINING_PROPOSAL_START: 'APPROVE_TRAINING_PROPOSAL_START',
	APPROVE_TRAINING_PROPOSAL_SUCCESS: 'APPROVE_TRAINING_PROPOSAL_SUCCESS',
	APPROVE_TRAINING_PROPOSAL_FAILURE: 'APPROVE_TRAINING_PROPOSAL_FAILURE',
	APPROVE_TRAINING_PROPOSAL_RESET: 'APPROVE_TRAINING_PROPOSAL_RESET',
	APPROVE_TRAINING_PROPOSAL_CHANGED: 'APPROVE_TRAINING_PROPOSAL_CHANGED',

	GET_TRAINING_PROPOSAL_INDIVIDUAL_START: 'GET_TRAINING_PROPOSAL_INDIVIDUAL_START',
	GET_TRAINING_PROPOSAL_INDIVIDUAL_SUCCESS: 'GET_TRAINING_PROPOSAL_INDIVIDUAL_SUCCESS',
	GET_TRAINING_PROPOSAL_INDIVIDUAL_FAILURE: 'GET_TRAINING_PROPOSAL_INDIVIDUAL_FAILURE',
	GET_TRAINING_PROPOSAL_INDIVIDUAL_RESET: 'GET_TRAINING_PROPOSAL_INDIVIDUAL_RESET',
	GET_TRAINING_PROPOSAL_INDIVIDUAL_CHANGED: 'GET_TRAINING_PROPOSAL_INDIVIDUAL_CHANGED',
    
}
export default AuditingToolActionTypes;