/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import SignatureActionType from './signature.types';

const INITIAL_STATE = {
	uploadSignature: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SignatureActionType.UPLOAD_SIGNATURE_SUCCESS:
			return { ...state, uploadSignature: action.payload };

		case SignatureActionType.UPLOAD_SIGNATURE_FAILURE:
			return { ...state, uploadSignature: action.payload };

		case SignatureActionType.UPLOAD_SIGNATURE_RESPONSE_CHANGED:
			return { ...state, uploadSignature: null, uploadSignatureStatus: null };

		default:
			return state;
	}
};
