/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import AssignUserRightsTypes from './assignUserRights.types';

/** ***************** CREATE USER ASSIGN CONTROL RIGHTS PAGE START *********************** */
export const createUserControlRightsPageStart = (addData) => ({
	type: AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START,
	payload: addData,
});

export const createUserControlRightsPageSuccess = (successData) => ({
	type: AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS,
	payload: successData,
});

export const createUserControlRightsPageFailure = (failureData) => ({
	type: AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE,
	payload: failureData,
});

export const createUserControlRightsPageResponseResetStart = () => ({
	type: AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET,
});

export const createUserControlRightsPageResponseChanged = () => ({
	type: AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED,
});
/** ***************** CREATE USER ASSIGN CONTROL RIGHTS PAGE END *********************** */

/** ***************** UPDATE USER ASSIGN CONTROL RIGHTS PAGE START *********************** */
export const updateUserControlRightsPageStart = (addData) => ({
	type: AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START,
	payload: addData,
});

export const updateUserControlRightsPageSuccess = (successData) => ({
	type: AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS,
	payload: successData,
});

export const updateUserControlRightsPageFailure = (failureData) => ({
	type: AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE,
	payload: failureData,
});

export const updateUserControlRightsPageResponseResetStart = () => ({
	type: AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET,
});

export const updateUserControlRightsPageResponseChanged = () => ({
	type: AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED,
});
/** ***************** UPDATE USER ASSIGN CONTROL RIGHTS PAGE END *********************** */

/** ***************** SAVE USER ASSIGN CONTROL RIGHTS FOR ALL PAGE START *********************** */
export const saveUserControlRightsForAllPageStart = (addData) => ({
	type: AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_START,
	payload: addData,
});

export const saveUserControlRightsForAllPageSuccess = (successData) => ({
	type: AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_SUCCESS,
	payload: successData,
});

export const saveUserControlRightsForAllPageFailure = (failureData) => ({
	type: AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_FAILURE,
	payload: failureData,
});

export const saveUserControlRightsForAllPageResponseResetStart = () => ({
	type: AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_RESET,
});

export const saveUserControlRightsForAllPageResponseChanged = () => ({
	type: AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_CHANGED,
});
/** ***************** SAVE USER ASSIGN CONTROL RIGHTS FOR ALL PAGE END *********************** */

/** ***************** FETCH LIST USER CONTROL DETAILS START *********************** */
export const fetchUserControlDetailsListStart = (fetchAssignUserRights) => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_START,
	payload: fetchAssignUserRights,
});

export const fetchUserControlDetailsListSuccess = (successData) => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchUserControlDetailsListFailure = (failureData) => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchUserControlDetailsListResponseResetStart = () => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_RESET,
});

export const fetchUserControlDetailsListResponseChanged = () => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_CHANGED,
});

export const fetchUserControlDetailsListArrResetStart = () => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_ARR_RESET,
});

export const fetchUserControlDetailsListArrChanged = () => ({
	type: AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_ARR_CHANGED,
});
/** ***************** FETCH LIST USER CONTROL DETAILS END *********************** */
