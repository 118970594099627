const InsuranceReportTypes = {
	FETCH_INSURANCE_REPORT_START: 'fetch_insurance_report_start',
	FETCH_INSURANCE_REPORT_SUCCESS: 'fetch_insurance_report_success',
	FETCH_INSURANCE_REPORT_FAILURE: 'fetch_insurance_report_failure',
	FETCH_INSURANCE_REPORT_RESPONSE_RESET_START: 'fetch_insurance_report_response_reset_start',
	FETCH_INSURANCE_REPORT_RESPONSE_CHANGED: 'fetch_insurance_report_response_reset_changed',

	FETCH_INSURANCE_REPORT_EXPORT_START: 'fetch_insurance_report_export_start',
	FETCH_INSURANCE_REPORT_EXPORT_SUCCESS: 'fetch_insurance_report_export_success',
	FETCH_INSURANCE_REPORT_EXPORT_FAILURE: 'fetch_insurance_Report_export_failure',
	FETCH_INSURANCE_REPORT_EXPORT_RESPONSE_RESET_START: 'fetch_insurance_report_export_response_reset_start',
	FETCH_INSURANCE_REPORT_EXPORT_RESPONSE_CHANGED: 'fetch_insurance_report_export_response_reset_changed',
};
export default InsuranceReportTypes;
