const WorkFlowManagementActionTypes = {
	GET_ALL_ROLE_LIST_START: 'get_all_role_list_start',
	GET_ALL_ROLE_LIST_SUCCESS: 'get_all_role_list_success',
	GET_ALL_ROLE_LIST_FAILURE: 'get_all_role_list_failure',
	GET_ALL_ROLE_LIST_RESPONSE_RESET: 'get_all_role_list_response_reset',
	GET_ALL_ROLE_LIST_RESPONSE_CHANGED: 'get_all_role_list_response_changed',

	GET_ALL_QUESTIONNAIRE_LIST_START: 'get_all_questionnaire_list_start',
	GET_ALL_QUESTIONNAIRE_LIST_SUCCESS: 'get_all_questionnaire_list_success',
	GET_ALL_QUESTIONNAIRE_LIST_FAILURE: 'get_all_questionnaire_list_failure',
	GET_ALL_QUESTIONNAIRE_LIST_RESPONSE_RESET: 'get_all_questionnaire_list_response_reset',
	GET_ALL_QUESTIONNAIRE_LIST_RESPONSE_CHANGED: 'get_all_questionnaire_list_response_changed',

	ADD_CREDIT_APPRAISAL_DETAILS_START: 'add_credit_appraisal_details_start',
	ADD_CREDIT_APPRAISAL_DETAILS_SUCCESS: 'add_credit_appraisal_details_success',
	ADD_CREDIT_APPRAISAL_DETAILS_FAILURE: 'add_credit_appraisal_details_failure',
	ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET: 'add_credit_appraisal_details_response_reset',
	ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED: 'add_credit_appraisal_details_response_changed',

	GET_ALL_CREDIT_APPRAISAL_LIST_START: 'get_all_credit_appraisal_list_start',
	GET_ALL_CREDIT_APPRAISAL_LIST_SUCCESS: 'get_all_credit_appraisal_list_success',
	GET_ALL_CREDIT_APPRAISAL_LIST_FAILURE: 'get_all_credit_appraisal_list_failure',
	GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_RESET: 'get_all_credit_appraisal_list_response_reset',
	GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_CHANGED:
		'get_all_credit_appraisal_list_response_changed',

	DELETE_CREDIT_APPRAISAL_DETAILS_START: 'delete_credit_appraisal_details_start',
	DELETE_CREDIT_APPRAISAL_DETAILS_SUCCESS: 'delete_credit_appraisal_details_success',
	DELETE_CREDIT_APPRAISAL_DETAILS_FAILURE: 'delete_credit_appraisal_details_failure',
	DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_RESET:
		'delete_credit_appraisal_details_response_reset',
	DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED:
		'delete_credit_appraisal_details_response_changed',
};
export default WorkFlowManagementActionTypes;
