/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import StaffActionTypes from './staff.types';
import {
	staffDetailsAdd,
	staffDetailsUpdate,
	staffListGet,
	staffDetailsFetch,
	staffDetailsDelete,
	allStaffFetch,
	staffPublishStatusUpdate,
	fetchAllStaffExcel,
	staffIsPrintStatusUpdate,
	allStaffListExportGet,
} from './staff.service';

import {
	addStaffDetailsSuccess,
	addStaffDetailsFailure,
	addStaffDetailsResponseChanged,
	updateStaffDetailsSuccess,
	updateStaffDetailsFailure,
	updateStaffDetailsResponseChanged,
	getStaffListSuccess,
	getStaffListFailure,
	getStaffListResponseChanged,
	fetchStaffDetailsSuccess,
	fetchStaffDetailsFailure,
	fetchStaffDetailsResponseChanged,
	deleteStaffDetailsSuccess,
	deleteStaffDetailsFailure,
	deleteStaffDetailsResponseChanged,
	updateIsPublishStatusSuccess,
	updateIsPublishStatusFailure,
	updateIsPublishStatusResponseChanged,
	fetchAllStaffSuccess,
	fetchAllStaffFailure,
	fetchAllStaffResponseChanged,
	exportStaffListSuccess,
	exportStaffListFailure,
	exportStaffListResponseChanged,
	updateIsPrintStaffSuccess,
	updateIsPrintStaffFailure,
	updateIsPrintStaffResponseChanged,
	getAllStaffDetailsExportSuccess,
	getAllStaffDetailsExportFailure,
	getAllStaffDetailsExportResponseChanged,
} from './staff.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD STAFF DETAILS  ******************** */
export function* createStaff({ payload }) {
	try {
		const key = 'AddStaffDetailKey';
		const stafflistJson = JSON.stringify(payload.staffDetailArr).replace(/"/g, "'");
		const jsonData = {
			UserPhoto: payload.staffImage,
			UniqueID: payload.staffUniqueID,
			UserName: payload.staffName,
			UserPhoneNumber: payload.staffPhoneNumber,
			UserEmailID: payload.staffEmailID,
			UserPassword: payload.staffPassword,
			DOB: payload.staffDateOfBirth,
			DateOfJoining: payload.staffDateOfJoining,
			Sex: payload.staffGender,
			Designation: payload.staffDesignation,
			UserAddress: payload.staffAddress,
			Office: payload.staffOfficeField,
			EmergencyContactNameOne: payload.emergenceContactName1,
			EmergencyNumberOne: payload.emergenceContactPhone1,
			EmergencyRelationShipOne: payload.relationshipContact1,
			EmergencyContactNameTwo: payload.emergenceContactName2,
			EmergencyNumberTwo: payload.emergenceContactPhone2,
			EmergencyRelationShipTwo: payload.relationshipContact2,
			AlternativePhoneNumber: payload.staffAlternativePhone,
			RoleID: payload.staffRoleID,
			BranchID: payload.staffBranchID,
			isConsumerReportStaff: payload.isGenerateConsumerReport ? 1 : 0,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
			UserLocation: stafflistJson,
			InvestorDetails: '',
			EmployeeCode: '',
			AccountArr: '',
			UserCity: payload.branchCityID,
			UserState: payload.branchStateID,
			UserEducation: payload.staffEducation,
			UserLevel: payload.SelectedLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addStaffDetailsFailure(responseData.data));
		} else {
			yield put(addStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addStaffDetailsFailure(error));
	}
}

export function* resetAddStaffResponse() {
	yield put(addStaffDetailsResponseChanged());
}

export function* onCreateStaffDetails() {
	yield takeLatest(StaffActionTypes.ADD_STAFF_DETAILS_START, createStaff);
}

export function* onCreateStaffResponseReset() {
	yield takeLatest(StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_RESET, resetAddStaffResponse);
}
/** *********************  END ADD STAFF DETAILS ******************** */

/** *********************  START UPDATE STAFF DETAILS  ******************** */
export function* staffUpdate({ payload }) {
	try {
		const key = 'UpdateStaffDetailsKey';
		const stafflistJson = JSON.stringify(payload.staffDetailArr).replace(/"/g, "'");
		const jsonData = {
			UserPhoto: payload.staffImage,
			UniqueID: payload.staffUniqueID,
			UserName: payload.staffName,
			UserPhoneNumber: payload.staffPhoneNumber,
			UserEmailID: payload.staffEmailID,
			UserPassword: payload.staffPassword,
			DOB: payload.staffDateOfBirth,
			DateOfJoining: payload.staffDateOfJoining,
			Sex: payload.staffGender,
			Designation: payload.staffDesignation,
			UserAddress: payload.staffAddress,
			Office: payload.staffOfficeField,
			emergencyContactNameOne: payload.emergenceContactName1,
			emergencyNumberOne: payload.emergenceContactPhone1,
			emergencyRelationShipOne: payload.relationshipContact1,
			emergencyContactNameTwo: payload.emergenceContactName2,
			emergencyNumberTwo: payload.emergenceContactPhone2,
			emergencyRelationShipTwo: payload.relationshipContact2,
			alternativePhoneNumber: payload.staffAlternativePhone,
			RoleID: payload.staffRoleID,
			BranchID: payload.staffBranchID,
			isConsumerReportStaff: payload.isGenerateConsumerReport ? 1 : 0,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
			UserLocation: stafflistJson,
			InvestorDetails: '',
			EmployeeCode: '',
			AccountArr: '',
			UserCity: payload.branchCityID,
			UserState: payload.branchStateID,
			UserEducation: payload.staffEducation,
			UserLevel: payload.SelectedLevel,
			UserID: payload.staffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffDetailsFailure(error));
	}
}

export function* resetUpdateStaffDetailsResponse() {
	yield put(updateStaffDetailsResponseChanged());
}

export function* onUpdateStaffDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_DETAILS_START, staffUpdate);
}

export function* onUpdateStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_RESET,
		resetUpdateStaffDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF DETAILS ******************** */

/** *********************  START GET STAFF LIST  ******************** */
export function* staffDetailsListGet({ payload }) {
	try {
		const key = 'GetStaffBasedOnCompanyKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRoleName: payload.userRole,
			Level: payload.userLevel,
			BranchID: payload.userAccessBranchID,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
			staffLevel: payload.RoleID,
			staffRoleName: payload.staffRoleName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffListFailure(responseData.data));
		} else {
			yield put(getStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffListFailure(error));
	}
}

export function* resetGetStaffListResponse() {
	yield put(getStaffListResponseChanged());
}

export function* onGetStaffListDetails() {
	yield takeLatest(StaffActionTypes.GET_STAFF_LIST_START, staffDetailsListGet);
}

export function* onGetStaffListResponseReset() {
	yield takeLatest(StaffActionTypes.GET_STAFF_LIST_RESPONSE_RESET, resetGetStaffListResponse);
}
/** *********************  END GET STAFF LIST ******************** */

/** *********************  START FETCH STAFF DETAILS  ******************** */
export function* fetchStaffData({ payload }) {
	try {
		const key = 'FetchStaffDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			StaffID: payload.staffID,
			DatabaseName: payload.databaseName,
			Level: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStaffDetailsFailure(responseData.data));
		} else {
			yield put(fetchStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStaffDetailsFailure(error));
	}
}

export function* resetFetchStaffDetailsResponse() {
	yield put(fetchStaffDetailsResponseChanged());
}

export function* onFetchStaffDetails() {
	yield takeLatest(StaffActionTypes.FETCH_STAFF_DETAILS_START, fetchStaffData);
}

export function* onFetchStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_RESET,
		resetFetchStaffDetailsResponse,
	);
}
/** *********************  END FETCH STAFF DETAILS ******************** */

/** *********************  START DELETE STAFF DETAILS  ******************** */
export function* staffDelete({ payload }) {
	try {
		const key = 'DeleteStaffDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			StaffID: payload.staffID,
			UserName: payload.staffName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserAccessName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteStaffDetailsFailure(responseData.data));
		} else {
			yield put(deleteStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteStaffDetailsFailure(error));
	}
}

export function* resetDeleteStaffDetailsResponse() {
	yield put(deleteStaffDetailsResponseChanged());
}

export function* onDeleteStaffDetail() {
	yield takeLatest(StaffActionTypes.DELETE_STAFF_DETAILS_START, staffDelete);
}

export function* onDeleteStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_RESET,
		resetDeleteStaffDetailsResponse,
	);
}
/** *********************  END DELETE STAFF DETAILS ******************** */

/** *********************  START UPDATE STAFF IS PUBLISH STATUS DETAILS  ******************** */
export function* staffIsPublishUpdate({ payload }) {
	try {
		const key = 'UpdateStaffPublisKey';
		const jsonData = {
			StaffID: payload.staffID,
			IsPublish: payload.isPublish,
			DatabaseName: payload.databaseName,
			UserType: 'Staff',
			UserName: payload.userName,
			CreatedBy: payload.userID,
			CompanyID: payload.companyID,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffPublishStatusUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateIsPublishStatusFailure(responseData.data));
		} else {
			yield put(updateIsPublishStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateIsPublishStatusFailure(error));
	}
}

export function* resetUpdatePublishStatusResponse() {
	yield put(updateIsPublishStatusResponseChanged());
}

export function* onUpdatePublishStatus() {
	yield takeLatest(StaffActionTypes.UPDATE_ISPUBLISH_STATUS_START, staffIsPublishUpdate);
}

export function* onUpdatePublishStatusResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET,
		resetUpdatePublishStatusResponse,
	);
}
/** *********************  END UPDATE STAFF IS PUBLISH STATUS DETAILS ******************** */

/** *********************  START FETCH ALL STAFFS LIST  ******************** */
export function* fetchAllStaffsList({ payload }) {
	try {
		const key = 'FetchAllStaffKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserStaff: payload.userStaff,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allStaffFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllStaffFailure(responseData.data));
		} else {
			yield put(fetchAllStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllStaffFailure(error));
	}
}

export function* resetFetchAllStaffsResponse() {
	yield put(fetchAllStaffResponseChanged());
}

export function* onFetchAllStaffsDetails() {
	yield takeLatest(StaffActionTypes.FETCH_ALL_STAFF_START, fetchAllStaffsList);
}

export function* onFetchAllStaffsResponseReset() {
	yield takeLatest(StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_RESET, resetFetchAllStaffsResponse);
}
/** *********************  END FETCH ALL STAFFS LIST ******************** */

export function* exportStaffListReport({ payload }) {
	try {
		const key = 'AllStaffReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllStaffExcel, memberData);
		if (responseData.status === '500') {
			yield put(exportStaffListFailure(responseData.data));
		} else {
			yield put(exportStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(exportStaffListFailure(error));
	}
}

export function* resetReportExportStaffList() {
	yield put(exportStaffListResponseChanged());
}

export function* onExportReportStaffListStart() {
	yield takeLatest(StaffActionTypes.EXPORT_STAFF_LIST_START, exportStaffListReport);
}

export function* onResetReportExportStaffList() {
	yield takeLatest(
		StaffActionTypes.EXPORT_STAFF_LIST_RESPONSE_REST_START,
		resetReportExportStaffList,
	);
}

/** *********************  START UPDATE STAFF IS PUBLISH STATUS DETAILS  ******************** */
export function* staffIsPrintUpdate({ payload }) {
	try {
		const key = 'UpdateStaffIsPrintKey';
		const jsonData = {
			StaffID: payload.UserID,
			IsPrintReceiptAsManager: payload.isPrintReceiptAsManager,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			LoginUserID: payload.userID,
			LoginUserName: payload.userName,
			StaffBranchID: payload.staffBranchID,
			staffLevel: payload.StaffLevel,
			BranchName: payload.BranchName,
			// Level: payload.BranchName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffIsPrintStatusUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateIsPrintStaffFailure(responseData.data));
		} else {
			yield put(updateIsPrintStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateIsPrintStaffFailure(error));
	}
}

export function* resetUpdateIsPrintStaffResponse() {
	yield put(updateIsPrintStaffResponseChanged());
}

export function* onUpdateIsPrintStaff() {
	yield takeLatest(StaffActionTypes.UPDATE_ISPRINT_STAFF_START, staffIsPrintUpdate);
}

export function* onUpdateIsPrintStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_ISPRINT_STAFF_RESPONSE_RESET,
		resetUpdateIsPrintStaffResponse,
	);
}
/** *********************  END UPDATE STAFF IS PRINT STATUS DETAILS ******************** */

/** *********************  START GET All STAFF DETAILS  ******************** */
export function* getAllStaffExportList({ payload }) {
	try {
		const key = 'ExportAllStaffKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
			DateFilter: payload.datefilter,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allStaffListExportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllStaffDetailsExportFailure(responseData.data));
		} else {
			yield put(getAllStaffDetailsExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllStaffDetailsExportFailure(error));
	}
}
export function* resetGetAllStaffExportListResponse() {
	yield put(getAllStaffDetailsExportResponseChanged());
}
export function* onGetAllStaffListExportDetails() {
	yield takeLatest(StaffActionTypes.GET_ALL_STAFF_LIST_EXPORT_START, getAllStaffExportList);
}
export function* onGetAllStaffListExportResponseReset() {
	yield takeLatest(
		StaffActionTypes.GET_ALL_STAFF_LIST_EXPORT_RESPONSE_RESET,
		resetGetAllStaffExportListResponse,
	);
}
/** *********************  END GET All STAFF LIST ******************** */

export function* staffMasterSaga() {
	yield all([
		call(onCreateStaffDetails),
		call(onCreateStaffResponseReset),
		call(onUpdateStaffDetails),
		call(onUpdateStaffResponseReset),
		call(onGetStaffListDetails),
		call(onGetStaffListResponseReset),
		call(onFetchStaffDetails),
		call(onFetchStaffResponseReset),
		call(onDeleteStaffDetail),
		call(onDeleteStaffResponseReset),
		call(onUpdatePublishStatus),
		call(onUpdatePublishStatusResponseReset),
		call(onFetchAllStaffsDetails),
		call(onFetchAllStaffsResponseReset),
		call(onExportReportStaffListStart),
		call(onResetReportExportStaffList),
		call(onUpdateIsPrintStaff),
		call(onUpdateIsPrintStaffResponseReset),
		call(onGetAllStaffListExportDetails),
		call(onGetAllStaffListExportResponseReset),
	]);
}
