/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanDisbursementActionTypes from './loan-index-disbursement.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getParticularLoanIndexDetailsResponse: null,
	deleteParticularLoanIndexDetailsResponse: null,
	getParticularLoanIndexDetailsArr: [],
	getFinanceAccountsListResponse: null,
	getFinanceAccountsListArr: [],
	getHolidayListResponse: null,
	getHolidayListArr: [],
	getLoanScheduleListResponse: null,
	getLoanScheduleListArr: [],
	updateLoanIndexFunderResponse: null,
	updateLoanIndexMeettimeResponse: null,
	createLoanIndexDisbursementResponse: null,
	createTransactionDisbursementResponse: null,
	createTransactionAdditionalChargesResponse: null,
	updateLoanIndexPOLResponse: null,
	getNextTransactionSerialNoResponse: null,
	getNextTransactionSerialNoArr: [],
	getProductAccountDetailsResponse: null,
	getProductAccountDetailsArr: [],
	getVoucherCountBasedOnCompanyResponse: null,
	getVoucherCountBasedOnCompanyArr: [],
	getInterBranchContraResponse: null,
	getLoandisbursementPDFResponse: null,
	getInterBranchContraArr: [],
	getAllProductAdditionalAccountResponse: null,
	getAllProductAdditionalAccountArr: [],
	getAllRangeProductResponse: null,
	getAllRangeProductArr: [],
	createTopupLoanIndextResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				getParticularLoanIndexDetailsResponse: action.payload,
				getParticularLoanIndexDetailsArr: docodeResponse(
					action.payload,
					'FetchParticularLoanIndexDetails',
				),
			};

		case LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE:
			return { ...state, getParticularLoanIndexDetailsResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, getParticularLoanIndexDetailsResponse: null };

		case LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				deleteParticularLoanIndexDetailsResponse: action.payload,
			};

		case LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE:
			return { ...state, deleteParticularLoanIndexDetailsResponse: action.payload };

		case LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteParticularLoanIndexDetailsResponse: null };

		case LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_SUCCESS:
			return {
				...state,
				getFinanceAccountsListResponse: action.payload,
				getFinanceAccountsListArr: docodeResponse(
					action.payload,
					'FinanceAccountsBasedonTypeKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_FAILURE:
			return { ...state, getFinanceAccountsListResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_RESPONSE_CHANGED:
			return { ...state, getFinanceAccountsListResponse: null };

		case LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_SUCCESS:
			return {
				...state,
				getHolidayListResponse: action.payload,
				getHolidayListArr: docodeResponse(action.payload, 'GetHolidayDataKey'),
			};

		case LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_FAILURE:
			return { ...state, getHolidayListResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_RESPONSE_CHANGED:
			return { ...state, getHolidayListResponse: null };

		case LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_SUCCESS:
			return {
				...state,
				getLoanScheduleListResponse: action.payload,
				getLoanScheduleListArr: docodeResponse(
					action.payload,
					'getLoanInfoBasedOnMembersBothclosedKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_FAILURE:
			return { ...state, getLoanScheduleListResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_RESPONSE_CHANGED:
			return { ...state, getLoanScheduleListResponse: null };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_SUCCESS:
			return {
				...state,
				updateLoanIndexFunderResponse: action.payload,
			};

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_FAILURE:
			return { ...state, updateLoanIndexFunderResponse: action.payload };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_RESPONSE_CHANGED:
			return { ...state, updateLoanIndexFunderResponse: null };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_SUCCESS:
			return {
				...state,
				updateLoanIndexMeettimeResponse: action.payload,
			};

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_FAILURE:
			return { ...state, updateLoanIndexMeettimeResponse: action.payload };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_RESPONSE_CHANGED:
			return { ...state, updateLoanIndexMeettimeResponse: null };

		case LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_SUCCESS:
			return {
				...state,
				createLoanIndexDisbursementResponse: action.payload,
			};

		case LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_FAILURE:
			return { ...state, createLoanIndexDisbursementResponse: action.payload };

		case LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_CHANGED:
			return { ...state, createLoanIndexDisbursementResponse: null };

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_SUCCESS:
			return {
				...state,
				createTransactionDisbursementResponse: action.payload,
			};

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_FAILURE:
			return { ...state, createTransactionDisbursementResponse: action.payload };

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_CHANGED:
			return { ...state, createTransactionDisbursementResponse: null };

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_SUCCESS:
			return {
				...state,
				createTransactionAdditionalChargesResponse: action.payload,
			};

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_FAILURE:
			return { ...state, createTransactionAdditionalChargesResponse: action.payload };

		case LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_CHANGED:
			return { ...state, createTransactionAdditionalChargesResponse: null };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_SUCCESS:
			return {
				...state,
				updateLoanIndexPOLResponse: action.payload,
			};

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_FAILURE:
			return { ...state, updateLoanIndexPOLResponse: action.payload };

		case LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_RESPONSE_CHANGED:
			return { ...state, updateLoanIndexPOLResponse: null };
		case LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_SUCCESS:
			return {
				...state,
				getNextTransactionSerialNoResponse: action.payload,
				getNextTransactionSerialNoArr: docodeResponse(
					action.payload,
					'nextTransactionSerialNumberKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_FAILURE:
			return { ...state, getNextTransactionSerialNoResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_CHANGED:
			return { ...state, getNextTransactionSerialNoResponse: null };

		case LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_SUCCESS:
			return {
				...state,
				getProductAccountDetailsResponse: action.payload,
				getProductAccountDetailsArr: docodeResponse(
					action.payload,
					'productAccountDetailKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_FAILURE:
			return { ...state, getProductAccountDetailsResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_CHANGED:
			return { ...state, getProductAccountDetailsResponse: null };

		case LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_SUCCESS:
			return {
				...state,
				getVoucherCountBasedOnCompanyResponse: action.payload,
				getVoucherCountBasedOnCompanyArr: docodeResponse(
					action.payload,
					'getVoucherCountBasedOncompanyKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_FAILURE:
			return { ...state, getVoucherCountBasedOnCompanyResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_CHANGED:
			return { ...state, getVoucherCountBasedOnCompanyResponse: null };

		case LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_SUCCESS:
			return {
				...state,
				getInterBranchContraResponse: action.payload,
				getInterBranchContraArr: docodeResponse(
					action.payload,
					'getInterBranchContraAccountKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_FAILURE:
			return { ...state, getInterBranchContraResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_RESPONSE_CHANGED:
			return { ...state, getInterBranchContraResponse: null };

		case LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_SUCCESS:
			return {
				...state,
				getAllProductAdditionalAccountResponse: action.payload,
				getAllProductAdditionalAccountArr: docodeResponse(
					action.payload,
					'getAllProductAdditionalAccountListKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_FAILURE:
			return { ...state, getAllProductAdditionalAccountResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_CHANGED:
			return { ...state, getAllProductAdditionalAccountResponse: null };

		case LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_SUCCESS:
			return {
				...state,
				getAllRangeProductResponse: action.payload,
				getAllRangeProductArr: docodeResponse(
					action.payload,
					'getFilterRangeProductListKey',
				),
			};

		case LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_FAILURE:
			return { ...state, getAllRangeProductResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_RESPONSE_CHANGED:
			return { ...state, getAllRangeProductResponse: null };

		case LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_SUCCESS:
			return {
				...state,
				createTopupLoanIndextResponse: action.payload,
			};

		case LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_FAILURE:
			return { ...state, createTopupLoanIndextResponse: action.payload };

		case LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_RESPONSE_CHANGED:
			return { ...state, createTopupLoanIndextResponse: null };

		case LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_SUCCESS:
			return {
				...state,
				getLoandisbursementPDFResponse: action.payload,
			};

		case LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_FAILURE:
			return { ...state, getLoandisbursementPDFResponse: action.payload };

		case LoanDisbursementActionTypes.FETCH_ILOAN_DISBURSEMENT_PDF_RESPONSE_CHANGED:
			return { ...state, getLoandisbursementPDFResponse: null };
		default:
			return state;
	}
};
