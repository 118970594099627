/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import CreditCheckActionType from './credit-check.types';
import {
	creditCheckMemberAddSuccess,
	creditCheckMemberAddFailure,
	creditCheckMemberAddUserResponseChanged,
	creditCheckCallSuccess,
	creditCheckCallFailure,
	creditCheckCallUserResponseChanged,
	verifyMemberUserResponseChanged,
	verifyMemberFailure,
	verifyMemberSuccess,
	sendVerificationOtpSuccess,
	sendVerificationOtpFailure,
	sendVerificationOtpResponseChanged,
	verifyCreditCheckAadharOtpResponseChanged,
	verifyCreditCheckAadharOtpFailure,
	verifyCreditCheckAadharOtpSuccess,
	verifyValidateCreditCheckAadharOtpResponseChanged,
	verifyValidateCreditCheckAadharOtpFailure,
	verifyValidateCreditCheckAadharOtpSuccess,
	recheckMemberDetailsSuccess,
	recheckMemberDetailsFailure,
	recheckMemberDetailsResponseChanged,
	equifaxCheckCallUserResponseChanged,
	equifaxCheckCallSuccess,
	equifaxCheckCallFailure,
} from './credit-check.action';
import { encodeJson } from '../../../enode-decode';
import {
	callVerifyMemberAPI,
	creditCheckMemberAdd,
	getCreditCheck,
	getEquifaxCheck,
	otpVerificationCodeSend,
	verifyCreditCheckAadharOtpAPI,
	verifyValidateCreditCheckAadharOtpAPI,
	callVerifyMemberDetailsAPI,
} from './credit-check.service';

// Start Credit Check Member Add
export function* AddCreditCheckMember({ payload }) {
	try {
		const key = 'AddMemberCreditCheckMember';
		const jsonData = {
			MemberCompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberCreatedBy: payload.userID,
			MemberID: payload.memberID,
			MemberName: payload.memberName,
			MemberDateOfBirth: payload.memberDateOfBirth,
			MemberGender: payload.memberGender,
			MemberPhoneNumber: payload.memberPhoneNumber,
			MemberAadharNumber: payload.memberAadharNumber,
			IdProofType: payload.idProofType,
			IdProofNumber: payload.idProofNumber,
			AppliedAmount: payload.appliedAmount,
			MemberFatherName: payload.memberFatherName,
			MemberPincode: payload.pincode,
			MemberDoorNo: payload.doorNo,
			MemberAddressStreet: payload.addressStreet,
			MemberCountry: payload.country,
			MemberState: payload.state,
			MemberDistrict: payload.district,
			MemberCity: payload.city,
			CurrentPincode: payload.currentPincode,
			CurrentDoorNo: payload.currentDoorNo,
			CurrentAddressStreet: payload.currentAddressStreet,
			CurrentCountry: payload.currentCountry,
			CurrentState: payload.currentState,
			CurrentDistrict: payload.currentDistrict,
			CurrentCity: payload.currentCity,
			IsAadharScanned: payload.isAadharScanned,
			LocationId: payload.centerSelectValue,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(creditCheckMemberAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(creditCheckMemberAddFailure(responseData.data));
		} else {
			yield put(creditCheckMemberAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(creditCheckMemberAddFailure(error));
	}
}

export function* AddCreditCheckMemberResponseClear() {
	yield put(creditCheckMemberAddUserResponseChanged());
}

export function* onCreditCheckMemberAddStart() {
	yield takeLatest(CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_START, AddCreditCheckMember);
}

export function* onCreditCheckMemberAddResponseRest() {
	yield takeLatest(
		CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_RESPONSE_REST_START,
		AddCreditCheckMemberResponseClear,
	);
}
// End Credit Check Member Add

// Start Credit Check Call
export function* CallCreditCheck({ payload }) {
	try {
		const key = 'CallHighMarkDetailsKey';
		const jsonData = {
			MemberID: payload.MemberID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			IsConsumer: payload.isConsumerReport,
			AppliedAmount: payload.appliedAmount,
			GeneralCompanyID: payload.generalCompanyID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getCreditCheck, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(creditCheckCallFailure(responseData.data));
		} else {
			yield put(creditCheckCallSuccess(responseData.data));
		}
	} catch (error) {
		yield put(creditCheckCallFailure(error));
	}
}

export function* CallCreditCheckResponseClear() {
	yield put(creditCheckCallUserResponseChanged());
}

export function* onCreditCheckCallStart() {
	yield takeLatest(CreditCheckActionType.CREDIT_CHECK_CALL_START, CallCreditCheck);
}

export function* onCreditCheckCallResponseRest() {
	yield takeLatest(
		CreditCheckActionType.CREDIT_CHECK_CALL_RESPONSE_REST_START,
		CallCreditCheckResponseClear,
	);
}
// End Credit Check Call

// Start Equifax Check Call
export function* CallEquifaxCheck({ payload }) {
	try {
		const key = 'AppCheckEquifaxKey';
		const jsonData = {
			MemberID: payload.MemberID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			IsConsumerReport: payload.isConsumerReport,
			AppliedAmount: payload.appliedAmount,
			GeneralCompanyID: payload.generalCompanyID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(getEquifaxCheck, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(equifaxCheckCallFailure(responseData.data));
		} else {
			yield put(equifaxCheckCallSuccess(responseData.data));
		}
	} catch (error) {
		yield put(equifaxCheckCallFailure(error));
	}
}

export function* CallEquifaxCheckResponseClear() {
	yield put(equifaxCheckCallUserResponseChanged());
}

export function* onEquifaxCheckCallStart() {
	yield takeLatest(CreditCheckActionType.EQUIFAX_CHECK_CALL_START, CallEquifaxCheck);
}

export function* onEquifaxCheckCallResponseRest() {
	yield takeLatest(
		CreditCheckActionType.EQUIFAX_CHECK_CALL_RESPONSE_REST_START,
		CallEquifaxCheckResponseClear,
	);
}
// End Equifax Check Call

// Start Verify Member Call
export function* CallVerifyMember({ payload }) {
	try {
		const key = 'CheckMemberDataKey';
		const jsonData = {
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			MemberCompanyID: payload.companyID,
			AadharNumber: payload.memberAadharNumber,
			PhoneNumber: payload.memberPhoneNumber,
			IDProofNumber: payload.idProofNumber,
			SearchIDProofType: payload.idProofType,
			SearchAddressProofType: '',
			AddressProofNumber: '',
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(callVerifyMemberAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(verifyMemberFailure(responseData.data));
		} else {
			yield put(verifyMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyMemberFailure(error));
	}
}

export function* VerifyMemberResponseClear() {
	yield put(verifyMemberUserResponseChanged());
}

export function* onVerifyMemberStart() {
	yield takeLatest(CreditCheckActionType.VERIFY_MEMBER_START, CallVerifyMember);
}

export function* onVerifyMemberResponseRest() {
	yield takeLatest(
		CreditCheckActionType.VERIFY_MEMBER_RESPONSE_RESET_START,
		VerifyMemberResponseClear,
	);
}
// End Verify Member Call

// Start Send Verification Otp
export function* verificationOtpSend({ payload }) {
	try {
		const key = 'PhoneNumberVerification';
		const jsonData = {
			DatabaseName: payload.databaseName,
			MemberCompanyID: payload.companyID,
			MemberPhoneNumber: payload.memberPhoneNumber,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(otpVerificationCodeSend, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(sendVerificationOtpFailure(responseData.data));
		} else {
			yield put(sendVerificationOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(sendVerificationOtpFailure(error));
	}
}

export function* sendVerificationOtpResponseClear() {
	yield put(sendVerificationOtpResponseChanged());
}

export function* onSendVerificationOtpStart() {
	yield takeLatest(CreditCheckActionType.SEND_VERIFICATION_OTP_START, verificationOtpSend);
}

export function* onSendVerificationOtpResponseRest() {
	yield takeLatest(
		CreditCheckActionType.SEND_VERIFICATION_OTP_RESPONSE_RESET_START,
		sendVerificationOtpResponseClear,
	);
}
// End Send Verification Otp

// Start verify Aadhar Otp
export function* toVerifyCreditCheckAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyCreditCheckAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyCreditCheckAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyCreditCheckAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyCreditCheckAadharOtpFailure(error));
	}
}

export function* VerifyCreditCheckAadharOtpResponseClear() {
	yield put(verifyCreditCheckAadharOtpResponseChanged());
}

export function* onVerifyCreditCheckAadharOtpStart() {
	yield takeLatest(
		CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_START,
		toVerifyCreditCheckAadharOtp,
	);
}

export function* onVerifyCreditCheckAadharOtpRest() {
	yield takeLatest(
		CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyCreditCheckAadharOtpResponseClear,
	);
}
// End Verify Aadhar Otp

// Start verify validate Aadhar Otp
export function* toVerifyValidateCreditCheckAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			RandNum: payload.randNum,
			Otp: payload.otp,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyValidateCreditCheckAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyValidateCreditCheckAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyValidateCreditCheckAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyValidateCreditCheckAadharOtpFailure(error));
	}
}

export function* VerifyValidateCreditCheckAadharOtpResponseClear() {
	yield put(verifyValidateCreditCheckAadharOtpResponseChanged());
}

export function* onVerifyValidateCreditCheckAadharOtpStart() {
	yield takeLatest(
		CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_START,
		toVerifyValidateCreditCheckAadharOtp,
	);
}

export function* onVerifyValidateCreditCheckAadharOtpRest() {
	yield takeLatest(
		CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyValidateCreditCheckAadharOtpResponseClear,
	);
}
// End Verify validate Aadhar Otp

// START MEMBER DETAILS RECHECK
export function* toCheckMemberDetails({ payload }) {
	try {
		const key = 'MemberCreditCheckDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(callVerifyMemberDetailsAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(recheckMemberDetailsFailure(responseData.data));
		} else {
			yield put(recheckMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(recheckMemberDetailsFailure(error));
	}
}

export function* CheckMemberDetailsResponseClear() {
	yield put(recheckMemberDetailsResponseChanged());
}

export function* onCheckMemberDetailsStart() {
	yield takeLatest(
		CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_START,
		toCheckMemberDetails,
	);
}

export function* onCheckMemberDetailsReset() {
	yield takeLatest(
		CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_RESET_START,
		CheckMemberDetailsResponseClear,
	);
}
// END MEMBER DETAILS RECHECK

export function* creditCheckDetailsSaga() {
	yield all([
		call(onCreditCheckMemberAddStart),
		call(onCreditCheckMemberAddResponseRest),
		call(onCreditCheckCallStart),
		call(onCreditCheckCallResponseRest),
		call(onVerifyMemberStart),
		call(onVerifyMemberResponseRest),
		call(onSendVerificationOtpStart),
		call(onSendVerificationOtpResponseRest),
		call(onVerifyCreditCheckAadharOtpStart),
		call(onVerifyCreditCheckAadharOtpRest),
		call(onVerifyValidateCreditCheckAadharOtpStart),
		call(onVerifyValidateCreditCheckAadharOtpRest),
		call(onCheckMemberDetailsStart),
		call(onCheckMemberDetailsReset),
		call(onEquifaxCheckCallStart),
		call(onEquifaxCheckCallResponseRest),
	]);
}
