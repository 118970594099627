/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PortfolioReportActionTypes from './portfolioReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getPortfolioReportStatus: null,
	getPortfolioReportArr: null,
	fetchPortfolioExcelStatus: null,
	fetchPortfolioExcelArr: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_SUCCESS:
			return {
				...state,
				getPortfolioReportStatus: 'SUCCESS',
				getPortfolioReportArr: docodeResponse(action.payload, 'PostportfolioReportKey'),
			};

		case PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_FAILURE:
			return { ...state, getPortfolioReportStatus: 'FAILURE' };

		case PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getPortfolioReportStatus: null };

		case PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_SUCCESS:
			return {
				...state,
				fetchPortfolioExcelStatus: 'SUCCESS'
			};

		case PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_FAILURE:
			return { ...state, fetchPortfolioExcelStatus: 'FAILURE' };

		case PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_CHANGED:
			return { ...state, fetchPortfolioExcelStatus: null };
		default:
			return state;
	}
};
