/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import GeneralActionTypes from './general.types';
import { docodeResponse, decodeBase64Response } from '../common/common.utils';

const INITIAL_STATE = {
	isDetailsEdit: false,
	isOffcanvasOpen: false,

	getCountryListResponse: [],
	getCountryListToken: null,

	getStateListResponse: [],
	getStateListToken: null,

	getCityListResponse: [],
	getCityListToken: null,

	getDistrictListResponse: [],

	getIndustryListResponse: null,
	getAllIndustryListArr: [],

	getSubIndustryListResponse: null,
	getSubIndustryListArr: [],

	fetchAllBranchesResponse: null,
	fetchAllBranchesArr: [],

	fetchAllPurposeOfLoanListResponse: null,
	fetchAllPurposeOfLoanListArr: [],

	fetchAllPurposeOfLoanTypeResponse: null,
	fetchAllPurposeOfLoanTypeArr: [],

	fetchAllBusinessResponse: null,
	fetchAllBusinessArr: [],

	fetchAllProductTypesResponse: null,
	fetchAllProductTypesArr: [],

	fetchAllProductListResponse: null,
	fetchAllProductListArr: [],

	fetchAllBranchOfficerResponse: null,
	fetchAllBranchOfficerArr: [],

	fetchAllCenterResponse: null,
	fetchAllCenterArr: [],

	fetchAllInverstorResponse: null,
	fetchAllInverstorArr: [],

	fetchallGroupResponse: null,
	fetchallGroupArr: [],

	fetchCenterGroupResponse: null,
	fetchCenterGroupArr: [],

	fetchGroupMemberResponse: null,
	fetchGroupMemberArr: [],

	getPinCodeListResponse: [],

	fetchBranchAccountListResponse: null,
	fetchBranchAccountListArr: [],

	fetchGroupLoanIndexListResponse: null,
	fetchGroupLoanIndexListArr: [],

	fetchSearchLoanIndexListResponse: null,
	fetchSearchLoanIndexListArr: [],

	fetchVersionResponse: null,
	fetchVersionArr: [],

	getLocationListBasedLevelResponse: null,
	getLocationListBasedLevelArray: [],

	checkGoogleAuthenticationResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GeneralActionTypes.IS_EDIT_DETAILS_START:
			return { ...state, isDetailsEdit: action.payload };

		case GeneralActionTypes.IS_OPEN_OFFCANVAS_START:
			return { ...state, isOffcanvasOpen: action.payload };

		case GeneralActionTypes.GET_COUNTRY_LIST_SUCCESS:
			return {
				...state,
				getCountryListResponse: docodeResponse(action.payload, 'GetCountryListKey'),
				getCountryListToken: action.payload,
			};

		case GeneralActionTypes.GET_COUNTRY_LIST_FAILURE:
			return { ...state, getCountryListResponse: [] };

		case GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_CHANGED:
			return { ...state, getCountryListResponse: [], getCountryListToken: null };

		case GeneralActionTypes.GET_STATE_LIST_SUCCESS:
			return {
				...state,
				getStateListResponse: decodeBase64Response(
					action.payload,
					'CountryStateListGetKey',
				),
				getStateListToken: action.payload,
			};

		case GeneralActionTypes.GET_STATE_LIST_FAILURE:
			return { ...state, getStateListResponse: [] };

		case GeneralActionTypes.GET_STATE_LIST_RESPONSE_CHANGED:
			return { ...state, getStateListResponse: [], getStateListToken: null };

		case GeneralActionTypes.GET_CITY_LIST_SUCCESS:
			return {
				...state,
				getCityListResponse: decodeBase64Response(action.payload, 'StateCityListGetKey'),
				getCityListToken: action.payload,
			};

		case GeneralActionTypes.GET_CITY_LIST_FAILURE:
			return { ...state, getCityListResponse: [] };

		case GeneralActionTypes.GET_CITY_LIST_RESPONSE_CHANGED:
			return { ...state, getCityListResponse: [], getCityListToken: null };

		case GeneralActionTypes.GET_DISTRICT_LIST_SUCCESS:
			return {
				...state,
				getDistrictListResponse: docodeResponse(action.payload, 'DistrictCityListGetKey'),
			};

		case GeneralActionTypes.GET_PINCODE_LIST_SUCCESS:
			return {
				...state,
				getPinCodeListResponse: action.payload.PostOffice,
			};
		case GeneralActionTypes.GET_PINCODE_LIST_FAILURE:
			return { ...state, getPinCodeListResponse: [] };

		case GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_CHANGED:
			return { ...state, getPinCodeListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_FAILURE:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_CHANGED:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.GET_INDUSTRY_LIST_SUCCESS:
			return {
				...state,
				getIndustryListResponse: action.payload,
				getAllIndustryListArr: docodeResponse(action.payload, 'GetAllIndustryListKey'),
			};

		case GeneralActionTypes.GET_INDUSTRY_LIST_FAILURE:
			return { ...state, getIndustryListResponse: action.payload };

		case GeneralActionTypes.GET_INDUSTRY_LIST_RESPONSE_CHANGED:
			return { ...state, getIndustryListResponse: null };

		case GeneralActionTypes.GET_SUB_INDUSTRY_LIST_SUCCESS:
			return {
				...state,
				getSubIndustryListResponse: action.payload,
				getSubIndustryListArr: docodeResponse(action.payload, 'GetSubIndustryListKey'),
			};

		case GeneralActionTypes.GET_SUB_INDUSTRY_LIST_FAILURE:
			return { ...state, getSubIndustryListResponse: action.payload };

		case GeneralActionTypes.GET_SUB_INDUSTRY_LIST_RESPONSE_CHANGED:
			return { ...state, getSubIndustryListResponse: null };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_SUCCESS:
			return {
				...state,
				fetchAllBranchesResponse: action.payload,
				fetchAllBranchesArr: docodeResponse(action.payload, 'FetchAllBranchKey'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCHES_FAILURE:
			return { ...state, fetchAllBranchesResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchesResponse: null };

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_SUCCESS:
			return {
				...state,
				fetchAllPurposeOfLoanListResponse: action.payload,
				fetchAllPurposeOfLoanListArr: docodeResponse(
					action.payload,
					'FetchAllPurposeOfLoansKey',
				),
			};

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_FAILURE:
			return { ...state, fetchAllPurposeOfLoanListResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAllPurposeOfLoanListResponse: null };

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_SUCCESS:
			return {
				...state,
				fetchAllPurposeOfLoanTypeResponse: action.payload,
				fetchAllPurposeOfLoanTypeArr: docodeResponse(
					action.payload,
					'FetchAllPurposeOfLoanTypesKey',
				),
			};

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_FAILURE:
			return { ...state, fetchAllPurposeOfLoanTypeResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_CHANGED:
			return { ...state, fetchAllPurposeOfLoanTypeResponse: null };

		case GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_SUCCESS:
			return {
				...state,
				fetchAllBusinessResponse: action.payload,
				fetchAllBusinessArr: docodeResponse(action.payload, 'GetAllBusinessListKey'),
			};

		case GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_FAILURE:
			return { ...state, fetchAllBusinessResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAllBusinessResponse: null };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_SUCCESS:
			return {
				...state,
				fetchAllProductTypesResponse: action.payload,
				fetchAllProductTypesArr: docodeResponse(action.payload, 'FetchAllProductTypesKey'),
			};

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_FAILURE:
			return { ...state, fetchAllProductTypesResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_CHANGED:
			return { ...state, fetchAllProductTypesResponse: null };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_SUCCESS:
			return {
				...state,
				fetchAllProductListResponse: action.payload,
				fetchAllProductListArr: docodeResponse(action.payload, 'FetchAllProductList'),
			};

		case GeneralActionTypes.FETCH_ALL_PRODUCT_FAILURE:
			return { ...state, fetchAllProductListResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED:
			return { ...state, fetchAllProductListResponse: null };

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_SUCCESS:
			return {
				...state,
				fetchAllBranchOfficerResponse: action.payload,
				fetchAllBranchOfficerArr: docodeResponse(action.payload, 'GetAllBranchOfficerList'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_FAILURE:
			return { ...state, fetchAllBranchOfficerResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchOfficerResponse: null };

		case GeneralActionTypes.FETCH_ALL_CENTERS_SUCCESS:
			return {
				...state,
				fetchAllCenterResponse: action.payload,
				fetchAllCenterArr: docodeResponse(action.payload, 'GetAllCentersList'),
			};

		case GeneralActionTypes.FETCH_ALL_CENTERS_FAILURE:
			return { ...state, fetchAllCenterResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_CHANGED:
			return { ...state, fetchAllCenterResponse: null };

		case GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_SUCCESS:
			return {
				...state,
				fetchAllInverstorResponse: action.payload,
				fetchAllInverstorArr: docodeResponse(action.payload, 'GetAllInvestorListKey'),
			};

		case GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_FAILURE:
			return { ...state, fetchAllInverstorResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAllInverstorResponse: null };

		case GeneralActionTypes.RESET_ALL_REDUCER_STATE_START:
			return INITIAL_STATE;

		case GeneralActionTypes.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				fetchallGroupResponse: action.payload,
				fetchallGroupArr: docodeResponse(action.payload, 'GetAllGroupsListkey'),
			};

		case GeneralActionTypes.FETCH_ALL_GROUP_FAILURE:
			return { ...state, fetchallGroupResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED:
			return { ...state, fetchallGroupResponse: null };

		case GeneralActionTypes.FETCH_CENTER_GROUPS_SUCCESS:
			return {
				...state,
				fetchCenterGroupResponse: action.payload,
				fetchCenterGroupArr: docodeResponse(action.payload, 'FetchAllGroupListKey'),
			};

		case GeneralActionTypes.FETCH_CENTER_GROUPS_FAILURE:
			return { ...state, fetchCenterGroupResponse: action.payload };

		case GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_CHANGED:
			return { ...state, fetchCenterGroupResponse: null };

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_SUCCESS:
			return {
				...state,
				fetchGroupMemberResponse: action.payload,
				fetchGroupMemberArr: docodeResponse(action.payload, 'FetchGroupMembersListKey'),
			};

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_FAILURE:
			return { ...state, fetchGroupMemberResponse: action.payload };

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_RESPONSE_CHANGED:
			return { ...state, fetchGroupMemberResponse: null };

		case GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_SUCCESS:
			return {
				...state,
				fetchBranchAccountListResponse: action.payload,
				fetchBranchAccountListArr: docodeResponse(
					action.payload,
					'FetchBranchAccountDetailsKey',
				),
			};

		case GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_FAILURE:
			return { ...state, fetchBranchAccountListResponse: action.payload };

		case GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_CHANGED:
			return { ...state, fetchBranchAccountListResponse: null };

		// LOAN INDEX SECTION
		case GeneralActionTypes.FETCH_LOAN_INDEX_BASED_GROUP_SUCCESS:
			return {
				...state,
				fetchGroupLoanIndexListResponse: action.payload,
				fetchGroupLoanIndexListArr: docodeResponse(
					action.payload,
					'FetchAllLoanIndexListKey',
				),
			};

		case GeneralActionTypes.FETCH_LOAN_INDEX_BASED_GROUP_FAILURE:
			return { ...state, fetchGroupLoanIndexListResponse: action.payload };

		case GeneralActionTypes.FETCH_LOAN_INDEX_BASED_GROUP_RESPONSE_CHANGED:
			return { ...state, fetchGroupLoanIndexListResponse: null };

		// LOAN INDEX SECTION
		case GeneralActionTypes.FETCH_SEARCH_LOAN_INDEX_SUCCESS:
			return {
				...state,
				fetchSearchLoanIndexListResponse: action.payload,
				fetchSearchLoanIndexListArr: docodeResponse(
					action.payload,
					'SearchLoanIndexDetailsTokenKey',
				),
			};

		case GeneralActionTypes.FETCH_SEARCH_LOAN_INDEX_FAILURE:
			return { ...state, fetchSearchLoanIndexListResponse: action.payload };

		case GeneralActionTypes.FETCH_SEARCH_LOAN_INDEX_RESPONSE_CHANGED:
			return { ...state, fetchSearchLoanIndexListResponse: null };

		case GeneralActionTypes.FETCH_PARTICULAR_LOAN_INDEX_SUCCESS:
			return {
				...state,
				fetchParticularLoanIndexListResponse: action.payload,
				fetchParticularLoanIndexListArr: docodeResponse(
					action.payload,
					'FetchParticularLoanIndexKey',
				),
			};

		case GeneralActionTypes.FETCH_PARTICULAR_LOAN_INDEX_FAILURE:
			return { ...state, fetchParticularLoanIndexListResponse: action.payload };

		case GeneralActionTypes.FETCH_PARTICULAR_LOAN_INDEX_RESPONSE_CHANGED:
			return { ...state, fetchParticularLoanIndexListResponse: null };

		case GeneralActionTypes.FETCH_VERSION_SUCCESS:
			return {
				...state,
				fetchVersionResponse: action.payload,
				fetchVersionArr: docodeResponse(action.payload, 'GetVersionDataKey'),
			};

		case GeneralActionTypes.FETCH_VERSION_FAILURE:
			return { ...state, fetchVersionResponse: action.payload };

		case GeneralActionTypes.FETCH_VERSION_RESPONSE_CHANGED:
			return { ...state, fetchVersionResponse: null };

		case GeneralActionTypes.UPDATE_VERSION_SUCCESS:
			return {
				...state,
				updateVersionResponse: action.payload,
				updateVersionArr: docodeResponse(action.payload, 'UpdateVersionDataKey'),
			};

		case GeneralActionTypes.UPDATE_VERSION_FAILURE:
			return { ...state, updateVersionResponse: action.payload };

		case GeneralActionTypes.UPDATE_VERSION_RESPONSE_CHANGED:
			return { ...state, updateVersionResponse: null };

		case GeneralActionTypes.FETCH_PINCODE_DETAILS_SUCCESS:
			return {
				...state,
				fetchPincodeDetailsResponse: action.payload,
				fetchPincodeDetailsArr: docodeResponse(action.payload, 'FetchPincodeListKey'),
			};

		case GeneralActionTypes.FETCH_PINCODE_DETAILS_FAILURE:
			return { ...state, fetchPincodeDetailsResponse: action.payload };

		case GeneralActionTypes.FETCH_PINCODE_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchPincodeDetailsResponse: null };

		// Get Location List based on Level
		case GeneralActionTypes.GET_LOCATION_LIST_BASED_LEVEL_SUCCESS:
			return {
				...state,
				getLocationListBasedLevelResponse: action.payload,
				getLocationListBasedLevelArray: docodeResponse(
					action.payload,
					'AllLocationBasedLevelKey',
				),
			};

		case GeneralActionTypes.GET_LOCATION_LIST_BASED_LEVEL_FAILURE:
			return { ...state, getLocationListBasedLevelResponse: action.payload };

		case GeneralActionTypes.GET_LOCATION_LIST_BASED_LEVEL_RESPONSE_CHANGED:
			return { ...state, getLocationListBasedLevelResponse: null };

		case GeneralActionTypes.CHECK_GOOGLE_AUTHENTICATION_SUCCESS:
			return {
				...state,
				checkGoogleAuthenticationResponse: action.payload,
			};

		case GeneralActionTypes.CHECK_GOOGLE_AUTHENTICATION_FAILURE:
			return { ...state, checkGoogleAuthenticationResponse: action.payload };

		case GeneralActionTypes.CHECK_GOOGLE_AUTHENTICATION_RESPONSE_CHANGED:
			return { ...state, checkGoogleAuthenticationResponse: null };

		default:
			return state;
	}
};
