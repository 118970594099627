/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const AllRoleListGet = (listData) => {
	const data = {
		FetchAllRoleListForCreditAppraisalListToken: listData,
	};
	const url = `${API_URL}GetAllRoleListForCreditAppraisalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const AllQuestionnaireListGet = (listData) => {
	const data = {
		GetAllQuestionnaireListToken: listData,
	};
	const url = `${API_URL}GetAllquestionnaireListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const creditAppraisalDetailsAdd = (addData) => {
	const data = {
		AddCreditAppraisalToken: addData,
	};
	const url = `${API_URL}AddCreditAppraisalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const AllCreditAppraisalListGet = (listData) => {
	const data = {
		GetAllCreditAppraisalListToken: listData,
	};
	const url = `${API_URL}GetAllCreditAppraisalListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const creditAppraisalDetailsDelete = (deleteData) => {
	const data = {
		DeleteCreditAppraisalToken: deleteData,
	};
	const url = `${API_URL}DeleteCreditAppraisalWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};