/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import memberCollectionActionTypes from './memberCollection.types';

/** ***************** GET SEARCH MEMBER LOAN LIST START *********************** */
export const fetchSearchMemberLoanListStart = (getData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_START,
	payload: getData,
});

export const getSearchMemberLoanListSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getSearchMemberLoanListFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchMemberLoanListResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET,
});

export const getSearchMemberLoanListResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET SEARCH MEMBER LOAN LIST END *********************** */

/** ***************** GET MEMBER LOAN INFO START *********************** */
export const fetchMemberLoanInfoStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_START,
	payload: getData,
});

export const getMemberLoanInfoSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_SUCCESS,
	payload: successData,
});

export const getMemberLoanInfoFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_FAILURE,
	payload: failureData,
});

export const fetchMemberLoanInfoResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_RESET,
});

export const getMemberLoanInfoResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN INFO END *********************** */

/** ***************** UPDATE MEMBER COLLECTION START *********************** */
export const updateMemberCollectionStart = (getData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_START,
	payload: getData,
});

export const updateMemberCollectionSuccess = (successData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_SUCCESS,
	payload: successData,
});

export const updateMemberCollectionFailure = (failureData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_FAILURE,
	payload: failureData,
});

export const updateMemberCollectionResponseResetStart = () => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_RESPONSE_RESET,
});

export const updateMemberCollectionResponseChanged = () => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** UPDATE MEMBER COLLECTION END *********************** */

/** ***************** UPDATE MEMBER COLLECTION START *********************** */
export const updateMemberDeathStart = (getData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_DEATH_START,
	payload: getData,
});

export const updateMemberDeathSuccess = (successData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_DEATH_SUCCESS,
	payload: successData,
});

export const updateMemberDeathFailure = (failureData) => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_DEATH_FAILURE,
	payload: failureData,
});

export const updateMemberDeathResponseResetStart = () => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_DEATH_RESPONSE_RESET,
});

export const updateMemberDeathResponseChanged = () => ({
	type: memberCollectionActionTypes.UPDATE_MEMBER_DEATH_RESPONSE_CHANGED,
});
/** ***************** UPDATE MEMBER COLLECTION END *********************** */

/** ***************** UPDATE MEMBER COLLECTION START *********************** */
export const updateInterestWaiverStart = (getData) => ({
	type: memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_START,
	payload: getData,
});

export const updateInterestWaiverSuccess = (successData) => ({
	type: memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_SUCCESS,
	payload: successData,
});

export const updateInterestWaiverFailure = (failureData) => ({
	type: memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_FAILURE,
	payload: failureData,
});

export const updateInterestWaiverResponseResetStart = () => ({
	type: memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_RESPONSE_RESET,
});

export const updateInterestWaiverResponseChanged = () => ({
	type: memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_RESPONSE_CHANGED,
});
/** ***************** UPDATE MEMBER COLLECTION END *********************** */

/** ***************** FETCH MEMBER COLLECTION START *********************** */
export const fetchMemberCollectionStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_COLLECTION_START,
	payload: getData,
});

export const fetchMemberCollectionSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_COLLECTION_SUCCESS,
	payload: successData,
});

export const fetchMemberCollectionFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_COLLECTION_FAILURE,
	payload: failureData,
});

export const fetchMemberCollectionResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_COLLECTION_RESPONSE_RESET,
});

export const fetchMemberCollectionResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER COLLECTION END *********************** */

/** ***************** FETCH MEMBER COLLECTION START *********************** */
export const fetchMemberWalletStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_WALLET_START,
	payload: getData,
});

export const fetchMemberWalletSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_WALLET_SUCCESS,
	payload: successData,
});

export const fetchMemberWalletFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_WALLET_FAILURE,
	payload: failureData,
});

export const fetchMemberWalletResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_WALLET_RESPONSE_RESET,
});

export const fetchMemberWalletResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_WALLET_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER COLLECTION END *********************** */

/** ***************** FETCH MEMBER COLLECTION START *********************** */
export const fetchMemberQrStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_QR_START,
	payload: getData,
});

export const fetchMemberQrSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_QR_SUCCESS,
	payload: successData,
});

export const fetchMemberQrFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_QR_FAILURE,
	payload: failureData,
});

export const fetchMemberQrResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_QR_RESPONSE_RESET,
});

export const fetchMemberQrResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_QR_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER COLLECTION END *********************** */

/** ***************** FETCH MEMBER COLLECTION START *********************** */
export const fetchMemberRepaymentStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_REPAYMENT_START,
	payload: getData,
});

export const fetchMemberRepaymentSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_REPAYMENT_SUCCESS,
	payload: successData,
});

export const fetchMemberRepaymentFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_REPAYMENT_FAILURE,
	payload: failureData,
});

export const fetchMemberRepaymentResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_REPAYMENT_RESPONSE_RESET,
});

export const fetchMemberRepaymentResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_REPAYMENT_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER COLLECTION END *********************** */

/** ***************** FETCH MEMBER COLLECTION START *********************** */
export const revertMemberWalletStart = (getData) => ({
	type: memberCollectionActionTypes.DELETE_MEMBER_WALLET_START,
	payload: getData,
});

export const revertMemberWalletSuccess = (successData) => ({
	type: memberCollectionActionTypes.DELETE_MEMBER_WALLET_SUCCESS,
	payload: successData,
});

export const revertMemberWalletFailure = (failureData) => ({
	type: memberCollectionActionTypes.DELETE_MEMBER_WALLET_FAILURE,
	payload: failureData,
});

export const revertMemberWalletResponseResetStart = () => ({
	type: memberCollectionActionTypes.DELETE_MEMBER_WALLET_RESPONSE_RESET,
});

export const revertMemberWalletResponseChanged = () => ({
	type: memberCollectionActionTypes.DELETE_MEMBER_WALLET_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER COLLECTION END *********************** */

/** ***************** UPDATE MEMBER COLLECTION START *********************** */
export const revertMemberCollectionStart = (getData) => ({
	type: memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_START,
	payload: getData,
});

export const revertMemberCollectionSuccess = (successData) => ({
	type: memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_SUCCESS,
	payload: successData,
});

export const revertMemberCollectionFailure = (failureData) => ({
	type: memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_FAILURE,
	payload: failureData,
});

export const revertMemberCollectionResponseResetStart = () => ({
	type: memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_RESPONSE_RESET,
});

export const revertMemberCollectionResponseChanged = () => ({
	type: memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** UPDATE MEMBER COLLECTION END *********************** */
