/* *** Loan Disbursement Saga ******** */
/* *** Version : 2.0.1 *************** */
/* *** Author : Vivin **************** */
/* *** Last Edit : Vivin ************* */
/* *** Last Added : Newy added *** */

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanDisbursementActionTypes from './loan-index-disbursement.types';
import {
	fetchParticularLoanIndexDetails,
	deleteParticularLoanIndexDetails,
	fetchHolidayList,
	fetchLoanScheduleList,
	updateLoanIndexFunder,
	updateLoanIndexMeettime,
	updateLoanIndexPOL,
	createLoanIndexDisbursement,
	createTransactionDisbursement,
	createTransactionAdditionalCharges,
	fetchFinanceAccountsList,
	fetchNextTransactionSerialNo,
	fetchProductAccountDetails,
	fetchVoucherCountBasedOnCompany,
	fetchInterBranchContra,
	fetchAllProductAdditionalAccount,
	fetchAllRangeProduct,
	createTopupLoanIndex,
	fetchLoanDisbursementPDF,
} from './loan-index-disbursement.service';

import {
	fetchParticularLoanIndexDetailsSuccess,
	fetchParticularLoanIndexDetailsFailure,
	fetchParticularLoanIndexDetailsResponseChanged,
	deleteParticularLoanIndexDetailsSuccess,
	deleteParticularLoanIndexDetailsFailure,
	deleteParticularLoanIndexDetailsResponseChanged,
	fetchFinanceAccountsListSuccess,
	fetchFinanceAccountsListFailure,
	fetchFinanceAccountsListResponseChanged,
	fetchHolidayListSuccess,
	fetchHolidayListFailure,
	fetchHolidayListResponseChanged,
	fetchLoanScheduleListSuccess,
	fetchLoanScheduleListFailure,
	fetchLoanScheduleListResponseChanged,
	updateLoanIndexFunderSuccess,
	updateLoanIndexFunderFailure,
	updateLoanIndexFunderResponseChanged,
	updateLoanIndexMeettimeSuccess,
	updateLoanIndexMeettimeFailure,
	updateLoanIndexMeettimeResponseChanged,
	updateLoanIndexPOLSuccess,
	updateLoanIndexPOLFailure,
	updateLoanIndexPOLResponseChanged,
	createLoanIndexDisbursementSuccess,
	createLoanIndexDisbursementFailure,
	createLoanIndexDisbursementResponseChanged,
	createTransactionDisbursementSuccess,
	createTransactionDisbursementFailure,
	createTransactionDisbursementResponseChanged,
	createTransactionAdditionalChargesSuccess,
	createTransactionAdditionalChargesFailure,
	createTransactionAdditionalChargesResponseChanged,
	fetchNextTransactionSerialNoSuccess,
	fetchNextTransactionSerialNoFailure,
	fetchNextTransactionSerialNoResponseChanged,
	fetchProductAccountDetailsSuccess,
	fetchProductAccountDetailsFailure,
	fetchProductAccountDetailsResponseChanged,
	fetchVoucherCountBasedOnCompanySuccess,
	fetchVoucherCountBasedOnCompanyFailure,
	fetchVoucherCountBasedOnCompanyResponseChanged,
	fetchInterBranchContraSuccess,
	fetchInterBranchContraFailure,
	fetchInterBranchContraResponseChanged,
	fetchAllProductAdditionalAccountSuccess,
	fetchAllProductAdditionalAccountFailure,
	fetchAllProductAdditionalAccountResponseChanged,
	fetchAllRangeProductSuccess,
	fetchAllRangeProductFailure,
	fetchAllRangeProductResponseChanged,
	createTopupLoanIndexSuccess,
	createTopupLoanIndexFailure,
	createTopupLoanIndexResponseChanged,
	fetchLoanDisbursementPDFSuccess,
	fetchLoanDisbursementPDFFailure,
	fetchLoanDisbursementPDFResponseChanged,
} from './loan-index-disbursement.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START FETCH LOAN INDEX DETAILS  ******************** */
export function* particularLoanIndexDetails({ payload }) {
	try {
		const key = 'FetchParticularLoanIndexDetails';
		const jsonData = {
			loanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchParticularLoanIndexDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchParticularLoanIndexDetailsFailure(responseData.data));
		} else {
			yield put(fetchParticularLoanIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchParticularLoanIndexDetailsFailure(error));
	}
}

export function* resetParticularLoanIndexDetailsResponse() {
	yield put(fetchParticularLoanIndexDetailsResponseChanged());
}

export function* onFetchParticularLoanIndexDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_START,
		particularLoanIndexDetails,
	);
}

export function* onParticularLoanIndexDetailsResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
		resetParticularLoanIndexDetailsResponse,
	);
}

export function* onDeleteParticularLoanIndexDetails({ payload }) {
	try {
		const key = 'DeleteParticularMemberInLoanIndexKey';
		const jsonData = {
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
			LoanIndexGroupMemberID: payload.ligmID,
			FromDisbursement: '1',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteParticularLoanIndexDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteParticularLoanIndexDetailsFailure(responseData.data));
		} else {
			yield put(deleteParticularLoanIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteParticularLoanIndexDetailsFailure(error));
	}
}

export function* resetDeleteParticularLoanIndexDetailsResponse() {
	yield put(deleteParticularLoanIndexDetailsResponseChanged());
}

export function* onDeleteParticularLoanDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_START,
		onDeleteParticularLoanIndexDetails,
	);
}

export function* onDeleteParticularLoanIndexDetailsResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
		resetDeleteParticularLoanIndexDetailsResponse,
	);
}

export function* financeAccountsListDetails({ payload }) {
	try {
		const key = 'FinanceAccountsBasedonTypeKey';
		const jsonData = {
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			DisbursementCashType: 'Cash',
			InfogID: payload.infogCompanyID,
			TransactionDate: payload.transactionDate,
			TransactionType: payload.TransactionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchFinanceAccountsList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFinanceAccountsListFailure(responseData.data));
		} else {
			yield put(fetchFinanceAccountsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFinanceAccountsListFailure(error));
	}
}

export function* resetFinanceAccountsListResponse() {
	yield put(fetchFinanceAccountsListResponseChanged());
}

export function* onFetchFinanceAccountsList() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_START,
		financeAccountsListDetails,
	);
}

export function* onFinanceAccountsListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_RESPONSE_RESET,
		resetFinanceAccountsListResponse,
	);
}

export function* holidayListDetails({ payload }) {
	try {
		const key = 'GetHolidayDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchHolidayList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchHolidayListFailure(responseData.data));
		} else {
			yield put(fetchHolidayListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchHolidayListFailure(error));
	}
}

export function* resetHolidayListResponse() {
	yield put(fetchHolidayListResponseChanged());
}

export function* onFetchHolidayList() {
	yield takeLatest(LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_START, holidayListDetails);
}

export function* onHolidayListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_RESPONSE_RESET,
		resetHolidayListResponse,
	);
}

export function* loanScheduleListDetails({ payload }) {
	try {
		const key = 'getLoanInfoBasedOnMembersBothclosedKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.loanIndexID,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchLoanScheduleList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanScheduleListFailure(responseData.data));
		} else {
			yield put(fetchLoanScheduleListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanScheduleListFailure(error));
	}
}

export function* resetLoanScheduleListResponse() {
	yield put(fetchLoanScheduleListResponseChanged());
}

export function* onFetchLoanScheduleList() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_START,
		loanScheduleListDetails,
	);
}

export function* onLoanScheduleListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_RESPONSE_RESET,
		resetLoanScheduleListResponse,
	);
}

export function* updateLoanIndexFunderDetails({ payload }) {
	try {
		const key = 'updateLoanIndexInvestorKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			NewInvestorID: payload.funderID,
			LoanIndexGroupMemberID: payload.loanIndexGroupMemberID,
			ExistsFunderName: payload.ExistsFunderName,
			FunderName: payload.FunderName,
			UserName: payload.userName,
			CreatedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			LoanIndexID: payload.LoanIndexID,
			BranchAccountID: payload.BranchAccountID,
			NewFunderPrincipalAccountID: payload.NewFunderPrincipalAccountID,
			InvestedAmount: payload.InvestedAmount,
			ExistFunderPrincipalAccountID: payload.ExistFunderPrincipalAccountID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanIndexFunder, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanIndexFunderFailure(responseData.data));
		} else {
			yield put(updateLoanIndexFunderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanIndexFunderFailure(error));
	}
}

export function* resetUpdateLoanIndexFunderResponse() {
	yield put(updateLoanIndexFunderResponseChanged());
}

export function* onUpdateLoanIndexFunder() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_START,
		updateLoanIndexFunderDetails,
	);
}

export function* onUpdateLoanIndexFunderResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_RESPONSE_RESET,
		resetUpdateLoanIndexFunderResponse,
	);
}

export function* updateLoanIndexMeettimeDetails({ payload }) {
	try {
		const key = 'updateLoanDisbursmentdateKey';
		const jsonData = {
			LoanindexID: payload.loanIndexID,
			MeetupTime: payload.meetupTime,
			LocationID: payload.centerID,
			DatabaseName: payload.databaseName,
			MeetupTimebeforechange: payload.previousMeetupTime,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			LoanStatus: payload.loanStatus,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanIndexMeettime, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanIndexMeettimeFailure(responseData.data));
		} else {
			yield put(updateLoanIndexMeettimeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanIndexMeettimeFailure(error));
	}
}

export function* resetUpdateLoanIndexMeettimeResponse() {
	yield put(updateLoanIndexMeettimeResponseChanged());
}

export function* onUpdateLoanIndexMeettime() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_START,
		updateLoanIndexMeettimeDetails,
	);
}

export function* onUpdateLoanIndexMeettimeResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_RESPONSE_RESET,
		resetUpdateLoanIndexMeettimeResponse,
	);
}

export function* updateLoanIndexPOLDetails({ payload }) {
	try {
		const key = 'updatePurposeLoanInvestorKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			NewInvestorID: payload.MemberPurposeOfLoanID,
			LoanIndexGroupMemberID: payload.loanIndexGroupMemberID,
			ExistsLoanName: payload.ExistsLoanName,
			LoanName: payload.LoanName,
			UserName: payload.userName,
			CreatedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanIndexPOL, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanIndexPOLFailure(responseData.data));
		} else {
			yield put(updateLoanIndexPOLSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanIndexPOLFailure(error));
	}
}

export function* resetUpdateLoanIndexPOLResponse() {
	yield put(updateLoanIndexPOLResponseChanged());
}

export function* onUpdateLoanIndexPOL() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_START,
		updateLoanIndexPOLDetails,
	);
}

export function* onUpdateLoanIndexPOLResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_RESPONSE_RESET,
		resetUpdateLoanIndexPOLResponse,
	);
}

export function* createLoanIndexDisbursementDetails({ payload }) {
	try {
		const key = 'createOnGoingProductLoanIndexKey';
		const LoanDisbursementArray = JSON.stringify(payload.LoanDisbursementArray).replace(/"/g, "'", );
		const ProductInvestors = JSON.stringify(payload.ProductInvestors).replace(/"/g, "'");
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Loandisbursementdate: payload.Loandisbursementdate,
			MeetupTime: payload.MeetupTime,
			LoanIndexStatus: payload.LoanIndexStatus,
			TransactionType: payload.TransactionType,
			LoanIndex: payload.LoanIndex,
			IsTopUpLoan: payload.IsTopUpLoan,
			CreatedBy: payload.userID,
			LoanDisbursementArray,
			MemberThrift: payload.MemberThrift,
			ProductInvestors,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createLoanIndexDisbursement, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createLoanIndexDisbursementFailure(responseData.data));
		} else {
			yield put(createLoanIndexDisbursementSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createLoanIndexDisbursementFailure(error));
	}
}

export function* resetCreateLoanIndexDisbursementResponse() {
	yield put(createLoanIndexDisbursementResponseChanged());
}

export function* onCreateLoanIndexDisbursement() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_START,
		createLoanIndexDisbursementDetails,
	);
}

export function* onCreateLoanIndexDisbursementResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_RESET,
		resetCreateLoanIndexDisbursementResponse,
	);
}

export function* createTransactionDisbursementDetails({ payload }) {
	try {
		const key = 'createDisbursementTranscationEntryKey';
		const TranscationArr = JSON.stringify(payload.TranscationArr).replace(/"/g, "'", );
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InfogCompanyID: payload.infogCompanyID,
			AccountID: payload.account,
			EntryDate: payload.entryDate,
			TransactionType: payload.TransactionType,
			TotalAmount: payload.TotalAmount,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			TranscationArr,
			StaffCode: payload.staffCode,
			CreatedFor: payload.CreatedFor,
			BranchName: payload.BranchName,
			IsLoanCollection: '0',
			UserLevel: payload.userLevel,
			LoanAdvanceAcc: payload.LoanAdvanceAcc,
			ProvisionType: payload.ProvisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createTransactionDisbursement, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createTransactionDisbursementFailure(responseData.data));
		} else {
			yield put(createTransactionDisbursementSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createTransactionDisbursementFailure(error));
	}
}

export function* resetCreateTransactionDisbursementResponse() {
	yield put(createTransactionDisbursementResponseChanged());
}

export function* onCreateTransactionDisbursement() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_START,
		createTransactionDisbursementDetails,
	);
}

export function* onCreateTransactionDisbursementResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_RESET,
		resetCreateTransactionDisbursementResponse,
	);
}

export function* createTransactionAdditionalChargesDetails({ payload }) {
	try {
		const key = 'createAdditionalAccountTranscationEntryKey';
		const TranscationArr = JSON.stringify(payload.TranscationArr).replace(/"/g, "'", );
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InfogCompanyID: payload.infogCompanyID,
			AccountID: payload.Credit_AccountID,
			EntryDate: payload.disbursementDate,
			TransactionType: payload.transaction_Type,
			TotalAmount: payload.totalAmount,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			TranscationArr,
			StaffCode: payload.staffCode,
			CreatedFor: payload.branchID,
			BranchName: payload.branchName,
			IsLoanCollection: '0'
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createTransactionAdditionalCharges, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createTransactionAdditionalChargesFailure(responseData.data));
		} else {
			yield put(createTransactionAdditionalChargesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createTransactionAdditionalChargesFailure(error));
	}
}

export function* resetCreateTransactionAdditionalChargesResponse() {
	yield put(createTransactionAdditionalChargesResponseChanged());
}

export function* onCreateTransactionAdditionalCharges() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_START,
		createTransactionAdditionalChargesDetails,
	);
}

export function* onCreateTransactionAdditionalChargesResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_RESET,
		resetCreateTransactionAdditionalChargesResponse,
	);
}
/** *********************  END FETCH LOAN INDEX ******************** */

export function* nextTransactionSerialNoDetails({ payload }) {
	try {
		const key = 'nextTransactionSerialNumberKey';
		const jsonData = {
			AccountID: payload.account,
			DatabaseName: payload.databaseName,
			CompanyID: payload.infogCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchNextTransactionSerialNo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchNextTransactionSerialNoFailure(responseData.data));
		} else {
			yield put(fetchNextTransactionSerialNoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchNextTransactionSerialNoFailure(error));
	}
}

export function* resetNextTransactionSerialNoResponse() {
	yield put(fetchNextTransactionSerialNoResponseChanged());
}

export function* onFetchNextTransactionSerialNo() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_START,
		nextTransactionSerialNoDetails,
	);
}

export function* onNextTransactionSerialNoResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_RESET,
		resetNextTransactionSerialNoResponse,
	);
}

export function* productAccountDetailsDetails({ payload }) {
	try {
		const key = 'productAccountDetailKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			InfogID: payload.infogCompanyID,
			ProductID: payload.ProductID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchProductAccountDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductAccountDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductAccountDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductAccountDetailsFailure(error));
	}
}

export function* resetProductAccountDetailsResponse() {
	yield put(fetchProductAccountDetailsResponseChanged());
}

export function* onFetchProductAccountDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_START,
		productAccountDetailsDetails,
	);
}

export function* onProductAccountDetailsResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_RESET,
		resetProductAccountDetailsResponse,
	);
}

export function* voucherCountBasedOnCompanyDetails({ payload }) {
	try {
		const key = 'getVoucherCountBasedOncompanyKey';
		const jsonData = {
			TransactionType: payload.TransactionType,
			DatabaseName: payload.databaseName,
			CompanyID: payload.infogCompanyID,
			CreatedFor: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchVoucherCountBasedOnCompany, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchVoucherCountBasedOnCompanyFailure(responseData.data));
		} else {
			yield put(fetchVoucherCountBasedOnCompanySuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchVoucherCountBasedOnCompanyFailure(error));
	}
}

export function* resetVoucherCountBasedOnCompanyResponse() {
	yield put(fetchVoucherCountBasedOnCompanyResponseChanged());
}

export function* onFetchVoucherCountBasedOnCompany() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_START,
		voucherCountBasedOnCompanyDetails,
	);
}

export function* onVoucherCountBasedOnCompanyResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_RESET,
		resetVoucherCountBasedOnCompanyResponse,
	);
}

export function* interBranchContraDetails({ payload }) {
	try {
		const key = 'getInterBranchContraAccountKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			InfogID: payload.infogCompanyID,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchInterBranchContra, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchInterBranchContraFailure(responseData.data));
		} else {
			yield put(fetchInterBranchContraSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchInterBranchContraFailure(error));
	}
}

export function* resetInterBranchContraResponse() {
	yield put(fetchInterBranchContraResponseChanged());
}

export function* onFetchInterBranchContra() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_START,
		interBranchContraDetails,
	);
}

export function* onInterBranchContraResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_RESPONSE_RESET,
		resetInterBranchContraResponse,
	);
}

export function* allProductAdditionalAccountDetails({ payload }) {
	try {
		const key = 'getAllProductAdditionalAccountListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			InfogID: payload.infogCompanyID,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllProductAdditionalAccount, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductAdditionalAccountFailure(responseData.data));
		} else {
			yield put(fetchAllProductAdditionalAccountSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductAdditionalAccountFailure(error));
	}
}

export function* resetAllProductAdditionalAccountResponse() {
	yield put(fetchAllProductAdditionalAccountResponseChanged());
}

export function* onFetchAllProductAdditionalAccount() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_START,
		allProductAdditionalAccountDetails,
	);
}

export function* onAllProductAdditionalAccountResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_RESET,
		resetAllProductAdditionalAccountResponse,
	);
}

export function* allRangeProduct({ payload }) {
	try {
		const key = 'getFilterRangeProductListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			ProductType: payload.productType,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllRangeProduct, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllRangeProductFailure(responseData.data));
		} else {
			yield put(fetchAllRangeProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllRangeProductFailure(error));
	}
}

export function* resetAllRangeProductResponse() {
	yield put(fetchAllRangeProductResponseChanged());
}

export function* onFetchAllRangeProduct() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_START,
		allRangeProduct,
	);
}

export function* onAllRangeProductResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_RESPONSE_RESET,
		resetAllRangeProductResponse,
	);
}

export function* CreateTopupLoanIndex({ payload }) {
	try {
		const LoanDisbursementArray = JSON.stringify(payload.LoanDisbursementArray).replace(/"/g, "'", );
		const key = 'createTopupLoanIndexKey';
		const jsonData = {
			LoanIndexID: payload.loanIndexID,
			AreaID: payload.areaID,
			LocationID: payload.centerID,
			LoanDisbursementArray,
			TransactionType: payload.transactionType,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			Loandisbursementdate: payload.disbursementDate,
			TotalLoanAmount: payload.totalLoanAmount,
			MeetUpLocation: payload.meetupLocation,
			MemberMeetupLat: payload.meetuplat,
			MemberMeetupLong: payload.meetupLong,
			MeetUpTime: payload.meetupTime,
			GroupOfficerID: payload.officerID,
			IsPrecloseAndCreateTopupLoan: payload.isPrecloseAndCreateTopupLoan,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createTopupLoanIndex, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createTopupLoanIndexFailure(responseData.data));
		} else {
			yield put(createTopupLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createTopupLoanIndexFailure(error));
	}
}

export function* resetCreateTopupLoanIndexResponse() {
	yield put(createTopupLoanIndexResponseChanged());
}

export function* onCreateTopupLoanIndex() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_START,
		CreateTopupLoanIndex,
	);
}

export function* onCreateTopupLoanIndexResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_RESPONSE_RESET,
		resetCreateTopupLoanIndexResponse,
	);
}

export function* loanDisbursementPDFDetails({ payload }) {
	try {
		const key = 'LoanIndexDisbursementPDFKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			LoanIndexID: payload.LoanIndexID,
			CompanyID: payload.CompanyID,
			DisbursementDate: payload.DisbursementDate,
			MemberID: payload.memberID,
			LoanIndexGroupMemberID: payload.ligmID,
			EMIAmount: payload.lastEMI,
			IsDifferentEMI: payload.isDiffentEMI,
			RequestID: payload.RequestID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchLoanDisbursementPDF, encodeData, payload.pdfTitle);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanDisbursementPDFFailure(responseData.data));
		} else {
			yield put(fetchLoanDisbursementPDFSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanDisbursementPDFFailure(error));
	}
}

export function* resetLoanDisbursementPDFResponse() {
	yield put(fetchLoanDisbursementPDFResponseChanged());
}

export function* onFetchLoanDisbursementPDF() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_START,
		loanDisbursementPDFDetails,
	);
}

export function* onLoanDisbursementPDFResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_RESPONSE_RESET,
		resetLoanDisbursementPDFResponse,
	);
}
/** *********************  END FETCH LOAN INDEX ******************** */
export function* loanIndexDisbursementSaga() {
	yield all([
		call(onFetchParticularLoanIndexDetails),
		call(onParticularLoanIndexDetailsResponseReset),
		call(onDeleteParticularLoanDetails),
		call(onDeleteParticularLoanIndexDetailsResponseReset),
		call(onFetchFinanceAccountsList),
		call(onFinanceAccountsListResponseReset),
		call(onFetchHolidayList),
		call(onHolidayListResponseReset),
		call(onFetchLoanScheduleList),
		call(onLoanScheduleListResponseReset),
		call(onUpdateLoanIndexFunder),
		call(onUpdateLoanIndexFunderResponseReset),
		call(onUpdateLoanIndexMeettime),
		call(onUpdateLoanIndexMeettimeResponseReset),
		call(onUpdateLoanIndexPOL),
		call(onUpdateLoanIndexPOLResponseReset),
		call(onCreateLoanIndexDisbursement),
		call(onCreateLoanIndexDisbursementResponseReset),
		call(onCreateTransactionDisbursement),
		call(onCreateTransactionDisbursementResponseReset),
		call(onCreateTransactionAdditionalCharges),
		call(onCreateTransactionAdditionalChargesResponseReset),
		call(onFetchNextTransactionSerialNo),
		call(onNextTransactionSerialNoResponseReset),
		call(onFetchProductAccountDetails),
		call(onProductAccountDetailsResponseReset),
		call(onFetchVoucherCountBasedOnCompany),
		call(onVoucherCountBasedOnCompanyResponseReset),
		call(onFetchInterBranchContra),
		call(onInterBranchContraResponseReset),
		call(onFetchAllProductAdditionalAccount),
		call(onAllProductAdditionalAccountResponseReset),
		call(onFetchAllRangeProduct),
		call(onAllRangeProductResponseReset),
		call(onCreateTopupLoanIndex),
		call(onCreateTopupLoanIndexResponseReset),
		call(onFetchLoanDisbursementPDF),
		call(onLoanDisbursementPDFResponseReset),
	]);
}
