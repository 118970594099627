/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import DrivingLicenceActionType from './driving-licence-details.types';

const INITIAL_STATE = {
	updateDrivingLicenceResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_SUCCESS:
			return { ...state, updateDrivingLicenceResponse: action.payload };

		case DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_FAILURE:
			return { ...state, updateDrivingLicenceResponse: action.payload };

		case DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_RESPONSE_CHANGED:
			return { ...state, updateDrivingLicenceResponse: null };

		default:
			return state;
	}
};
