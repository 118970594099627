import ChangeMeetingDateActionTypes from './changeMeetingDate.types';

/** ***************** UPDATE BRANCH MEETING DATE START *********************** */
export const updateBranchMeetingDateStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_START,
	payload: getData,
});

export const updateBranchMeetingDateSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_SUCCESS,
	payload: successData,
});

export const updateBranchMeetingDateFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_FAILURE,
	payload: failureData,
});

export const updateBranchMeetingDateResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_RESPONSE_RESET,
});

export const updateBranchMeetingDateResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_RESPONSE_CHANGED,
});
/** ***************** UPDATE BRANCH MEETING DATE END *********************** */

/** ***************** GET BRANCH MEETING DATE DETAILS START *********************** */
export const getBranchMeetingDateDetailsStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_START,
	payload: getData,
});

export const getBranchMeetingDateDetailsSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchMeetingDateDetailsFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchMeetingDateDetailsResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_RESET,
});

export const getBranchMeetingDateDetailsResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH MEETING DATE DETAILS END *********************** */

/** ***************** GET LOAN CENTER GROUP DETAILS START *********************** */
export const getLoanCenterGroupDetailsStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_START,
	payload: getData,
});

export const getLoanCenterGroupDetailsSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const getLoanCenterGroupDetailsFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const getLoanCenterGroupDetailsResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET,
});

export const getLoanCenterGroupDetailsResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET LOAN CENTER GROUP DETAILS END *********************** */

/** ***************** GET PARTICULAR LOAN CENTER GROUP DETAILS START *********************** */
export const getParticularLoanCenterGroupDetailsStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_START,
	payload: getData,
});

export const getParticularLoanCenterGroupDetailsSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const getParticularLoanCenterGroupDetailsFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const getParticularLoanCenterGroupDetailsResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_RESET,
});

export const getParticularLoanCenterGroupDetailsResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET PARTICULAR LOAN CENTER GROUP DETAILS END *********************** */

/** ***************** UPDATE SINGLE BRANCH MEETING DATE START *********************** */
export const updateSingleBranchMeetingDateStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_START,
	payload: getData,
});

export const updateSingleBranchMeetingDateSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_SUCCESS,
	payload: successData,
});

export const updateSingleBranchMeetingDateFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_FAILURE,
	payload: failureData,
});

export const updateSingleBranchMeetingDateResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_RESET,
});

export const updateSingleBranchMeetingDateResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_CHANGED,
});
/** ***************** UPDATE SINGLE BRANCH MEETING DATE END *********************** */

/** ***************** UPDATE SINGLE CENTER MEETING DATE START *********************** */
export const updateSingleCenterMeetingDateStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_START,
	payload: getData,
});

export const updateSingleCenterMeetingDateSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_SUCCESS,
	payload: successData,
});

export const updateSingleCenterMeetingDateFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_FAILURE,
	payload: failureData,
});

export const updateSingleCenterMeetingDateResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_RESET,
});

export const updateSingleCenterMeetingDateResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_CHANGED,
});
/** ***************** UPDATE SINGLE CENTER MEETING DATE END *********************** */

/** ***************** UPDATE SINGLE CENTER MEETING DATE START *********************** */
export const updateSingleMemberMeetingDateStart = (getData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_START,
	payload: getData,
});

export const updateSingleMemberMeetingDateSuccess = (successData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_SUCCESS,
	payload: successData,
});

export const updateSingleMemberMeetingDateFailure = (failureData) => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_FAILURE,
	payload: failureData,
});

export const updateSingleMemberMeetingDateResponseResetStart = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_RESET,
});

export const updateSingleMemberMeetingDateResponseChanged = () => ({
	type: ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_CHANGED,
});
/** ***************** UPDATE SINGLE CENTER MEETING DATE END *********************** */
