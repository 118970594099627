/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import MemberActionTypes from './member.types';
import { docodeResponse, decodeBase64Response } from '../../common/common.utils';
import { arrageMemberTab, updateMemberTab } from './member.utils';

const INITIAL_STATE = {
	addMemberResponse: null,
	addMemberDetailsResponse: [],
	updateMemberDetailsResponse: null,
	getMemberListResponse: null,
	getMemberListArr: [],
	fetchMemberDetailsResponse: null,
	fetchMemberDetailsArr: [],
	deleteMemberDetailsResponse: null,
	fetchCoApplicantHighMarkDetailsResponse: null,
	fetchCoApplicantHighMarkDetailsArr: [],
	verifyDocumentDetailsResponse: null,
	checkHighMarkDetailsResponse: null,
	checkEquifaxDetailsResponse: null,
	checkCoApplicantHighmarkDetailsResponse: null,
	checkCoApplicantHighmarkDetailsArr: [],
	updateNoLoanResponse: null,
	checkTopupLoanResponse: null,
	updateRejectResponse: null,
	updateRecheckResponse: null,
	fetchPaySprintLogDetailsResponse: null,
	fetchPaySprintLogDetailsArr: [],
	fetchMemberAuditDetailsResponse: null,
	fetchMemberAuditDetailsArr: [],
	fetchMemberVerifyDetailsResponse: null,
	updateMemberVerifyDetailsResponse: null,
	updateMemberKYCDetailsResponse: null,
	updateMemberTransferDetailsResponse: null,
	fetchMemberVerifyDetailsArr: [],
	addMemberDetails: {
		loanType: 'MFI Loan',
		MemberID: '',
		StageCompleted: 0,
		isPhoneNumberVerified: false,
	},
	addMemberDetailsTab: [
		{
			tab: 'VERIFICATION',
			label: 'Verification',
			isShow: true,
			isCompleted: false,
			isDisable: false,
		},
		{
			tab: 'LOAN_TYPE',
			label: 'Loan Type',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'CREDIT_CHECK',
			label: 'Credit Check',
			isShow: false,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'BASIC_DETAILS',
			label: 'Basic Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'PERSONAL_DETAILS',
			label: 'Personal Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'FAMILY_DETAILS',
			label: 'Family Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'NOMINEE_DETAILS',
			label: 'Nominee Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'BANK_DETAILS',
			label: 'Bank Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'HOUSE_DETAILS',
			label: 'House Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'MEMBER_DOCUMENT',
			label: 'Member Documents',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'NOMINEE_DOCUMENT',
			label: 'Nominee Documents',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'HOUSE_IMAGE',
			label: 'House Images',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'EXTRA_INFORMATION',
			label: 'Extra Information',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'OFFICIAL_DETAILS',
			label: 'Official Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'NOC_DETAILS',
			label: 'NOC Document Details',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
		{
			tab: 'COAPPLICANT',
			label: 'Co Applicant',
			isShow: true,
			isCompleted: false,
			isDisable: true,
		},
	],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberActionTypes.ADD_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				addMemberResponse: action.payload,
				addMemberDetailsResponse: docodeResponse(action.payload, 'AddMemberPhoneNumber'),
			};

		case MemberActionTypes.ADD_MEMBER_DETAILS_FAILURE:
			return { ...state, addMemberResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_SUCCESS:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_FAILURE:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberDetailsResponse: null };

		case MemberActionTypes.GET_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getMemberListResponse: action.payload,
				getMemberListArr: docodeResponse(action.payload, 'GetMemberListKey'),
			};

		case MemberActionTypes.GET_MEMBER_LIST_FAILURE:
			return { ...state, getMemberListResponse: action.payload };

		case MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, getMemberListResponse: null };

		case MemberActionTypes.FETCH_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberDetailsResponse: action.payload,
				fetchMemberDetailsArr: docodeResponse(action.payload, 'FetchMemberDetailsKey'),
			};

		case MemberActionTypes.FETCH_MEMBER_DETAILS_FAILURE:
			return { ...state, fetchMemberDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberDetailsResponse: null, fetchMemberDetailsArr: [] };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_SUCCESS:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_FAILURE:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteMemberDetailsResponse: null };

		// CO-APPLICANT DETAILS

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				fetchCoApplicantHighMarkDetailsResponse: action.payload,
				fetchCoApplicantHighMarkDetailsArr: docodeResponse(
					action.payload,
					'FetchCoApplicantHighMarkDetailsKey',
				),
			};

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_FAILURE:
			return { ...state, fetchCoApplicantHighMarkDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchCoApplicantHighMarkDetailsResponse: null,
				fetchCoApplicantHighMarkDetailsArr: [],
			};

		// VERIFY DOCUMENT DETAILS

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_SUCCESS:
			return {
				...state,
				verifyDocumentDetailsResponse: action.payload,
			};

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_FAILURE:
			return { ...state, verifyDocumentDetailsResponse: action.payload };

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_RESPONSE_RESET:
			return {
				...state,
				verifyDocumentDetailsResponse: null,
			};

		// CHECK HIGHMARK DETAILS

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				checkHighMarkDetailsResponse: action.payload,
			};

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_FAILURE:
			return { ...state, checkHighMarkDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkHighMarkDetailsResponse: null,
			};
		// CHECK EQUIFAX DETAILS

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_SUCCESS:
			return {
				...state,
				checkEquifaxDetailsResponse: action.payload,
			};

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_FAILURE:
			return { ...state, checkEquifaxDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkEquifaxDetailsResponse: null,
			};
		// CHECK CO APPLICANT HIGHMARK DETAILS

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				checkCoApplicantHighmarkDetailsResponse: action.payload,
				checkCoApplicantHighmarkDetailsArr: docodeResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_FAILURE:
			return { ...state, checkCoApplicantHighmarkDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkCoApplicantHighmarkDetailsResponse: null,
				checkCoApplicantHighmarkDetailsArr: [],
			};

		// UPDATE NO LOAN MEMBER RESPONSE

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_SUCCESS:
			return { ...state, updateNoLoanResponse: action.payload };

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_FAILURE:
			return { ...state, updateNoLoanResponse: action.payload };

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateNoLoanResponse: null };

		// CHECK TOPUP LOAN MEMBER RESPONSE

		case MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_SUCCESS:
			return { ...state, checkTopupLoanResponse: action.payload };

		case MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_FAILURE:
			return { ...state, checkTopupLoanResponse: action.payload };

		case MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_RESPONSE_CHANGED:
			return { ...state, checkTopupLoanResponse: null };

		// UPDATE REJECT MEMBER RESPONSE

		case MemberActionTypes.REJECT_MEMBER_SUCCESS:
			return { ...state, updateRejectResponse: action.payload };

		case MemberActionTypes.REJECT_MEMBER_FAILURE:
			return { ...state, updateRejectResponse: action.payload };

		case MemberActionTypes.REJECT_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateRejectResponse: null };

		// UPDATE RECHECK MEMBER RESPONSE

		case MemberActionTypes.RECHECK_MEMBER_SUCCESS:
			return { ...state, updateRecheckResponse: action.payload };

		case MemberActionTypes.RECHECK_MEMBER_FAILURE:
			return { ...state, updateRecheckResponse: action.payload };

		case MemberActionTypes.RECHECK_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateRecheckResponse: null };

		// UPDATE RECHECK MEMBER RESPONSE

		case MemberActionTypes.APPROVE_MEMBER_SUCCESS:
			return { ...state, updateApproveResponse: action.payload };

		case MemberActionTypes.APPROVE_MEMBER_FAILURE:
			return { ...state, updateApproveResponse: action.payload };

		case MemberActionTypes.APPROVE_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateApproveResponse: null };

		// FETCH PAYSPRINT LOG

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_SUCCESS:
			return {
				...state,
				fetchPaySprintLogDetailsResponse: action.payload,
				fetchPaySprintLogDetailsArr: docodeResponse(
					action.payload,
					'FetchPaySprintLogDetailsKey',
				),
			};

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_FAILURE:
			return { ...state, fetchPaySprintLogDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchPaySprintLogDetailsResponse: null,
			};

		// FETCH PAYSPRINT LOG

		case MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberAuditDetailsResponse: action.payload,
				fetchMemberAuditDetailsArr: decodeBase64Response(
					action.payload,
					'MemberAuditFetchApproveKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_FAILURE:
			return { ...state, fetchMemberAuditDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchMemberAuditDetailsResponse: null,
			};

		case MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberVerifyDetailsResponse: action.payload,
				fetchMemberVerifyDetailsArr: docodeResponse(
					action.payload,
					'MemberVerifyFetchApproveKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_FAILURE:
			return { ...state, fetchMemberVerifyDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchMemberVerifyDetailsResponse: null,
			};

		case MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_SUCCESS:
			return {
				...state,
				updateMemberVerifyDetailsResponse: action.payload,
			};

		case MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_FAILURE:
			return { ...state, updateMemberVerifyDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				updateMemberVerifyDetailsResponse: null,
			};

		case MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_SUCCESS:
			return {
				...state,
				updateMemberKYCDetailsResponse: action.payload,
			};

		case MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_FAILURE:
			return { ...state, updateMemberKYCDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				updateMemberKYCDetailsResponse: null,
			};

		case MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_SUCCESS:
			return {
				...state,
				updateMemberTransferDetailsResponse: action.payload,
			};

		case MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_FAILURE:
			return { ...state, updateMemberTransferDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				updateMemberTransferDetailsResponse: null,
			};
		case MemberActionTypes.ADD_MEMBER_DETAILS:
			return { ...state, addMemberDetails: action.payload };
		case MemberActionTypes.ADD_MEMBER_TAB:
			return {
				...state,
				addMemberDetailsTab: arrageMemberTab(state.addMemberDetailsTab, action.payload),
			};

		case MemberActionTypes.UPDATE_MEMBER_TAB:
			return {
				...state,
				addMemberDetailsTab: updateMemberTab(state.addMemberDetailsTab, action.payload),
			};
		case MemberActionTypes.RESET_MEMBER_DATA:
			return {
				...state,
				addMemberDetails: {
					loanType: 'MFI Loan',
					MemberID: '',
					StageCompleted: 0,
					isPhoneNumberVerified: false,
				},
				addMemberDetailsTab: updateMemberTab(state.addMemberDetailsTab, 'RESET'),
			};
		default:
			return state;
	}
};
