/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const rangeLoanProposalListFetch = (listData, serviceName) => {
	const data = {
		GetAllBranchLoanIndexDetailsToken: listData,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanProposalCenterListFetch = (listData) => {
	const data = {
		GetRangeLoanCenterListToken: listData,
	};
	const url = `${API_URL}getAllLocationListBasedLevelToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanCenterMemberListFetch = (listData) => {
	const data = {
		GetMemberDetailsForCenterLoanToken: listData,
	};
	const url = `${API_URL}GetMemberDetailsForCenterLoanWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanProductListFetch = (listData) => {
	const data = {
		GetRangeLoanRangeProductListToken: listData,
	};
	const url = `${API_URL}getAllRangeProductListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanProposalAddAPI = (listData) => {
	const data = {
		AddRangeLoanProposaltToken: listData,
	};
	const url = `${API_URL}AddRangeLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanProposalUpdateAPI = (listData) => {
	const data = {
		UpdateRangeLoanProposalToken: listData,
	};
	const url = `${API_URL}UpdateRangeLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanCenterMemberLeadListFetch = (listData) => {
	const data = {
		GetMemberLeadListToken: listData,
	};
	const url = `${API_URL}GetMemberLeadList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanIndexDetailsFetch = (listData) => {
	const data = {
		GetParticularRangeLoanIndexToken: listData,
	};
	const url = `${API_URL}GetParticularBranchLoanIndexInfoWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanIndexDetailsApproval = (listData) => {
	const data = {
		ApproveRangeLoanIndexToken: listData,
	};
	const url = `${API_URL}ApproveRangeLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanIndexDetailsApprovalRevert = (listData) => {
	const data = {
		RevertApprovedRangeLoanIndexToken: listData,
	};
	const url = `${API_URL}RevertApprovedRangeLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanIndexDetailsReject = (listData) => {
	const data = {
		RejectRangeLoanIndexToken: listData,
	};
	const url = `${API_URL}RejectRangeLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const rangeLoanProposalDeleteAPI = (listData) => {
	const data = {
		DeleteLoanIndexProposalToken: listData,
	};
	const url = `${API_URL}DeleteParticularLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
