const EndOfDayActionTypes = {
	GET_ALL_COLLECTION_DETAILS_START: 'get_all_collection_details_start',
	GET_ALL_COLLECTION_DETAILS_SUCCESS: 'get_all_collection_details_success',
	GET_ALL_COLLECTION_DETAILS_FAILURE: 'get_all_collection_details_failure',
	GET_ALL_COLLECTION_DETAILS_RESPONSE_RESET: 'get_all_collection_details_response_reset',
	GET_ALL_COLLECTION_DETAILS_RESPONSE_CHANGED: 'get_all_collection_details_response_changed',

	GET_BRANCH_EOD_WALLET_DETAILS_START: 'get_branch_eod_wallet_details_start',
	GET_BRANCH_EOD_WALLET_DETAILS_SUCCESS: 'get_branch_eod_wallet_details_success',
	GET_BRANCH_EOD_WALLET_DETAILS_FAILURE: 'get_branch_eod_wallet_details_failure',
	GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_RESET: 'get_branch_eod_wallet_details_response_reset',
	GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_CHANGED:
		'get_branch_eod_wallet_details_response_changed',

	GET_BRANCH_EOD_THRIFT_DETAILS_START: 'get_branch_eod_thrift_details_start',
	GET_BRANCH_EOD_THRIFT_DETAILS_SUCCESS: 'get_branch_eod_thrift_details_success',
	GET_BRANCH_EOD_THRIFT_DETAILS_FAILURE: 'get_branch_eod_thrift_details_failure',
	GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_RESET: 'get_branch_eod_thrift_details_response_reset',
	GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_CHANGED:
		'get_branch_eod_thrift_details_response_changed',

	GET_BRANCH_EOD_SAVINGS_DETAILS_START: 'get_branch_eod_thrift_details_start',
	GET_BRANCH_EOD_SAVINGS_DETAILS_SUCCESS: 'get_branch_eod_thrift_details_success',
	GET_BRANCH_EOD_SAVINGS_DETAILS_FAILURE: 'get_branch_eod_thrift_details_failure',
	GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_RESET: 'get_branch_eod_thrift_details_response_reset',
	GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_CHANGED:
		'get_branch_eod_savings_details_response_changed',

	GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_START: 'get_branch_eod_fixed_deposit_details_start',
	GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_SUCCESS: 'get_branch_eod_fixed_deposit_details_success',
	GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_FAILURE: 'get_branch_eod_fixed_deposit_details_failure',
	GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_RESET:
		'get_branch_eod_fixed_deposit_details_response_reset',
	GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_CHANGED: 'get_branch_eod_fixed_deposit_details_response_changed',

	GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_START: 'get_branch_eod_yettocollect_arrear_details_start',
	GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_SUCCESS: 'get_branch_eod_yettocollect_arrear_details_success',
	GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_FAILURE: 'get_branch_eod_yettocollect_arrear_details_failure',
	GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_RESPONSE_RESET:
		'get_branch_eod_yettocollect_arrear_details_response_reset',
	GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_RESPONSE_CHANGED: 'get_branch_eod_yettocollect_arrear_details_response_changed',

	GET_DEMAND_COLLECTION_TOTAL_DETAILS_START: 'get_demand_collection_total_details_start',
	GET_DEMAND_COLLECTION_TOTAL_DETAILS_SUCCESS: 'get_demand_collection_total_details_success',
	GET_DEMAND_COLLECTION_TOTAL_DETAILS_FAILURE: 'get_demand_collection_total_details_failure',
	GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET: 'get_demand_collection_total_details_response_reset',
	GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_demand_collection_total_details_response_changed',

	GET_ADVANCE_COLLECTION_TOTAL_DETAILS_START: 'get_advance_collection_total_details_start',
	GET_ADVANCE_COLLECTION_TOTAL_DETAILS_SUCCESS: 'get_advance_collection_total_details_success',
	GET_ADVANCE_COLLECTION_TOTAL_DETAILS_FAILURE: 'get_advance_collection_total_details_failure',
	GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET: 'get_advance_collection_total_details_response_reset',
	GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_advance_collection_total_details_response_changed',

	GET_ARREAR_COLLECTION_TOTAL_DETAILS_START: 'get_arrear_collection_total_details_start',
	GET_ARREAR_COLLECTION_TOTAL_DETAILS_SUCCESS: 'get_arrear_collection_total_details_success',
	GET_ARREAR_COLLECTION_TOTAL_DETAILS_FAILURE: 'get_arrear_collection_total_details_failure',
	GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET: 'get_arrear_collection_total_details_response_reset',
	GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_arrear_collection_total_details_response_changed',

	GET_WALLET_COLLECTION_TOTAL_DETAILS_START: 'get_wallet_collection_total_details_start',
	GET_WALLET_COLLECTION_TOTAL_DETAILS_SUCCESS: 'get_wallet_collection_total_details_success',
	GET_WALLET_COLLECTION_TOTAL_DETAILS_FAILURE: 'get_wallet_collection_total_details_failure',
	GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET: 'get_wallet_collection_total_details_response_reset',
	GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_wallet_collection_total_details_response_changed',

	GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_START: 'get_preclose_collection_total_details_start',
	GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_SUCCESS: 'get_preclose_collection_total_details_success',
	GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_FAILURE: 'get_preclose_collection_total_details_failure',
	GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET: 'get_preclose_collection_total_details_response_reset',
	GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_preclose_collection_total_details_response_changed',

	GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_START: 'get_loan_advance_used_total_details_start',
	GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_SUCCESS: 'get_loan_advance_used_total_details_success',
	GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_FAILURE: 'get_loan_advance_used_total_details_failure',
	GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_RESPONSE_RESET: 'get_loan_advance_used_total_details_response_reset',
	GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_RESPONSE_CHANGED: 'get_loan_advance_used_total_details_response_changed',

	GET_CLOSE_LOAN_LIST_DETAILS_START: 'get_close_loan_list_details_start',
	GET_CLOSE_LOAN_LIST_DETAILS_SUCCESS: 'get_close_loan_list_details_success',
	GET_CLOSE_LOAN_LIST_DETAILS_FAILURE: 'get_close_loan_list_details_failure',
	GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_RESET: 'get_close_loan_list_details_response_reset',
	GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_CHANGED: 'get_close_loan_list_details_response_changed',

	GET_BRANCH_EOD_DETAILS_START: 'get_branch_eod_details_start',
	GET_BRANCH_EOD_DETAILS_SUCCESS: 'get_branch_eod_details_success',
	GET_BRANCH_EOD_DETAILS_FAILURE: 'get_branch_eod_details_failure',
	GET_BRANCH_EOD_DETAILS_RESPONSE_RESET: 'get_branch_eod_details_response_reset',
	GET_BRANCH_EOD_DETAILS_RESPONSE_CHANGED: 'get_branch_eod_details_response_changed',

	GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_START: 'get_previous_day_bank_account_day_book_report_start',
	GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS: 'get_previous_day_bank_account_day_book_report_success',
	GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE: 'get_previous_day_bank_account_day_book_report_failure',
	GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET: 'get_previous_day_bank_account_day_book_report_response_reset',
	GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED: 'get_previous_day_bank_account_day_book_report_response_changed',

	GET_BANK_ACCOUNT_DAY_BOOK_REPORT_START: 'get_bank_account_day_book_report_start',
	GET_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS: 'get_bank_account_day_book_report_success',
	GET_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE: 'get_bank_account_day_book_report_failure',
	GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET: 'get_bank_account_day_book_report_response_reset',
	GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED: 'get_bank_account_day_book_report_response_changed',

	GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_START: 'get_previous_day_cash_account_day_book_report_start',
	GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS: 'get_previous_day_cash_account_day_book_report_success',
	GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE: 'get_previous_day_cash_account_day_book_report_failure',
	GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET: 'get_previous_day_cash_account_day_book_report_response_reset',
	GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED: 'get_previous_day_cash_account_day_book_report_response_changed',

	GET_CASH_ACCOUNT_DAY_BOOK_REPORT_START: 'get_cash_account_day_book_report_start',
	GET_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS: 'get_cash_account_day_book_report_success',
	GET_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE: 'get_cash_account_day_book_report_failure',
	GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET: 'get_cash_account_day_book_report_response_reset',
	GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED: 'get_cash_account_day_book_report_response_changed',

	GET_PRECLOSE_COLLECTION_DETAILS_START: 'get_preclose_collection_details_start',
	GET_PRECLOSE_COLLECTION_DETAILS_SUCCESS: 'get_preclose_collection_details_success',
	GET_PRECLOSE_COLLECTION_DETAILS_FAILURE: 'get_preclose_collection_details_failure',
	GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_RESET: 'get_preclose_collection_details_response_reset',
	GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_CHANGED: 'get_preclose_collection_details_response_changed',

	GET_ADVANCE_COLLECTION_DETAILS_START: 'get_advance_collection_details_start',
	GET_ADVANCE_COLLECTION_DETAILS_SUCCESS: 'get_advance_collection_details_success',
	GET_ADVANCE_COLLECTION_DETAILS_FAILURE: 'get_advance_collection_details_failure',
	GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_RESET: 'get_advance_collection_details_response_reset',
	GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_CHANGED: 'get_advance_collection_details_response_changed',

	GET_ARREAR_COLLECTION_DETAILS_START: 'get_arrear_collection_details_start',
	GET_ARREAR_COLLECTION_DETAILS_SUCCESS: 'get_arrear_collection_details_success',
	GET_ARREAR_COLLECTION_DETAILS_FAILURE: 'get_arrear_collection_details_failure',
	GET_ARREAR_COLLECTION_DETAILS_RESPONSE_RESET: 'get_arrear_collection_details_response_reset',
	GET_ARREAR_COLLECTION_DETAILS_RESPONSE_CHANGED: 'get_arrear_collection_details_response_changed',

	GET_VOUCHER_APPROVED_DETAILS_START: 'get_voucher_approved_details_start',
	GET_VOUCHER_APPROVED_DETAILS_SUCCESS: 'get_voucher_approved_details_success',
	GET_VOUCHER_APPROVED_DETAILS_FAILURE: 'get_voucher_approved_details_failure',
	GET_VOUCHER_APPROVED_DETAILS_RESPONSE_RESET: 'get_voucher_approved_details_response_reset',
	GET_VOUCHER_APPROVED_DETAILS_RESPONSE_CHANGED: 'get_voucher_approved_details_response_changed',

	GET_SUBMIT_EOD_DETAILS_START: 'get_eod_submit_details_start',
	GET_SUBMIT_EOD_DETAILS_SUCCESS: 'get_eod_submit_details_success',
	GET_SUBMIT_EOD_DETAILS_FAILURE: 'get_eod_submit_details_failure',
	GET_SUBMIT_EOD_DETAILS_RESPONSE_RESET: 'get_eod_submit_details_response_reset',
	GET_SUBMIT_EOD_DETAILS_RESPONSE_CHANGED: 'get_eod_submit_details_response_changed',

	GET_REVERT_EOD_DETAILS_START: 'get_eod_revert_details_start',
	GET_REVERT_EOD_DETAILS_SUCCESS: 'get_eod_revert_details_success',
	GET_REVERT_EOD_DETAILS_FAILURE: 'get_eod_revert_details_failure',
	GET_REVERT_EOD_DETAILS_RESPONSE_RESET: 'get_eod_revert_details_response_reset',
	GET_REVERT_EOD_DETAILS_RESPONSE_CHANGED: 'get_eod_revert_details_response_changed',
};
export default EndOfDayActionTypes;
