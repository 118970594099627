/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import FunderActionTypes from './funder.types';
import {
	funderDetailsAdd,
	funderDetailsUpdate,
	funderListGet,
	funderDetailsFetch,
	funderDetailsDelete,
	FunderPublishUpdate,
} from './funder.service';

import {
	addFunderDetailsSuccess,
	addFunderDetailsFailure,
	addFunderDetailsResponseChanged,
	updateFunderDetailsSuccess,
	updateFunderDetailsFailure,
	updateFunderDetailsResponseChanged,
	getFunderDetailsSuccess,
	getFunderDetailsFailure,
	getFunderDetailsResponseChanged,
	fetchFunderDetailsSuccess,
	fetchFunderDetailsFailure,
	fetchFunderDetailsResponseChanged,
	deleteFunderDetailsSuccess,
	deleteFunderDetailsFailure,
	deleteFunderDetailsResponseChanged,
	updateIsPublishFunderSuccess,
	updateIsPublishFunderFailure,
	updateIsPublishFunderResponseChanged,
} from './funder.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START ADD FUNDER DETAILS  ******************** */
export function* addFunderDetails({ payload }) {
	try {
		const key = 'AddFunderDetailsKey';
		const investorDetailJson = JSON.stringify(payload.investorDetailArr).replace(/"/g, "'");
		const accountDetailsJson = JSON.stringify(payload.accountDetailsArr).replace(/"/g, "'");
		const jsonData = {
			UserName: payload.funderName,
			UserPhoneNumber: payload.userPhoneNumber,
			BranchID: payload.branchID,
			UserEmailID: payload.userEmailID,
			UserPassword: payload.userPassword,
			UserAddress: payload.funderAddress,
			UserPhoto: payload.userPhoto,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			UserLocation: '',
			InvestorDetails: investorDetailJson,
			UniqueID: payload.funderUniqueID,
			RoleID: 'NULL',
			EmployeeCode: '',
			DOB: 'NULL',
			DateOfJoining: 'NULL',
			Designation: '',
			Sex: '',
			Office: '',
			ArreaArr: payload.branchArr,
			alternativePhoneNumber: '',
			emergencyContactNameOne: '',
			emergencyContactNameTwo: '',
			emergencyNumberOne: '',
			emergencyNumberTwo: '',
			emergencyRelationShipOne: '',
			CreatedBy: payload.userID,
			AccountArr: accountDetailsJson,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(funderDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addFunderDetailsFailure(responseData.data));
		} else {
			yield put(addFunderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addFunderDetailsFailure(error));
	}
}

export function* resetAddFunderResponse() {
	yield put(addFunderDetailsResponseChanged());
}

export function* onAddFunderDetails() {
	yield takeLatest(FunderActionTypes.ADD_FUNDER_DETAILS_START, addFunderDetails);
}

export function* onAddFunderDetailsResponseReset() {
	yield takeLatest(FunderActionTypes.ADD_FUNDER_DETAILS_RESPONSE_RESET, resetAddFunderResponse);
}

/** *********************  START UPDATE FUNDER DETAILS  ******************** */
export function* updateFunderDetails({ payload }) {
	try {
		const key = 'UpdateFunderDetailsKey';
		const jsonData = {
			FunderID: payload.funderID,
			UserName: payload.funderName,
			OldFunderName: payload.oldFunderName,
			UserPhoneNumber: payload.userPhoneNumber,
			UserEmailID: payload.userEmailID,
			UserPassword: payload.userPassword,
			UserAddress: payload.funderAddress,
			UserPhoto: payload.userPhoto,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			UserLocation: '',
			InvestorDetails: payload.investorDetailArr,
			UniqueID: payload.funderUniqueID,
			RoleID: 'NULL',
			EmployeeCode: '',
			DOB: 'NULL',
			DateOfJoining: 'NULL',
			Designation: '',
			Sex: 'NULL',
			Office: '',
			AreaArr: payload.branchArr,
			AlternativePhoneNumber: '',
			EmergencyNumberOne: '',
			EmergencyRelationShipOne: '',
			EmergencyContactNameOne: '',
			EmergencyNumberTwo: '',
			EmergencyRelationShipTwo: '',
			EmergencyContactNameTwo: '',
			CreatedBy: payload.userID,
			AccountArr: payload.accountDetailsArr,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(funderDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateFunderDetailsFailure(responseData.data));
		} else {
			yield put(updateFunderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateFunderDetailsFailure(error));
	}
}

export function* resetUpdateFunderDetailsResponse() {
	yield put(updateFunderDetailsResponseChanged());
}

export function* onUpdateFunderDetails() {
	yield takeLatest(FunderActionTypes.UPDATE_FUNDER_DETAILS_START, updateFunderDetails);
}

export function* onUpdateFunderResponseReset() {
	yield takeLatest(
		FunderActionTypes.UPDATE_FUNDER_DETAILS_RESPONSE_RESET,
		resetUpdateFunderDetailsResponse,
	);
}
/** *********************  END UPDATE FUNDER DETAILS ******************** */

/** *********************  START GET FUNDER DETAILS  ******************** */
export function* getFunderList({ payload }) {
	try {
		const key = 'GetFunderListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			BranchID: payload.userAccessBranchID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			Limit: payload.limit,
			inputSearch: payload.inputSearch,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(funderListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getFunderDetailsFailure(responseData.data));
		} else {
			yield put(getFunderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getFunderDetailsFailure(error));
	}
}

export function* resetGetFunderListResponse() {
	yield put(getFunderDetailsResponseChanged());
}

export function* onGetFunderListDetails() {
	yield takeLatest(FunderActionTypes.GET_FUNDER_LIST_START, getFunderList);
}

export function* onGetFunderListResponseReset() {
	yield takeLatest(FunderActionTypes.GET_FUNDER_LIST_RESPONSE_RESET, resetGetFunderListResponse);
}
/** *********************  END GET FUNDER LIST ******************** */

/** *********************  START FETCH FUNDER DETAILS  ******************** */
export function* fetchFunderDetails({ payload }) {
	try {
		const key = 'FetchFunderDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			FunderID: payload.funderID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(funderDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFunderDetailsFailure(responseData.data));
		} else {
			yield put(fetchFunderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFunderDetailsFailure(error));
	}
}

export function* resetFetchFunderDetailsResponse() {
	yield put(fetchFunderDetailsResponseChanged());
}

export function* onFetchFunderDetails() {
	yield takeLatest(FunderActionTypes.FETCH_FUNDER_DETAILS_START, fetchFunderDetails);
}

export function* onFetchFunderDetailsResponseReset() {
	yield takeLatest(
		FunderActionTypes.FETCH_FUNDER_DETAILS_RESPONSE_RESET,
		resetFetchFunderDetailsResponse,
	);
}
/** *********************  END FETCH FUNDER DETAILS ******************** */

/** *********************  START DELETE FUNDER DETAILS  ******************** */
export function* deleteFunder({ payload }) {
	try {
		const key = 'DeleteFunderDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			FunderID: payload.funderID,
			FunderName: payload.funderName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserID: payload.userID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(funderDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteFunderDetailsFailure(responseData.data));
		} else {
			yield put(deleteFunderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteFunderDetailsFailure(error));
	}
}

export function* resetDeleteFunderDetailsResponse() {
	yield put(deleteFunderDetailsResponseChanged());
}

export function* onDeleteFunderDetails() {
	yield takeLatest(FunderActionTypes.DELETE_FUNDER_DETAILS_START, deleteFunder);
}

export function* onDeleteFunderDetailsResponseReset() {
	yield takeLatest(
		FunderActionTypes.DELETE_FUNDER_DETAILS_RESPONSE_RESET,
		resetDeleteFunderDetailsResponse,
	);
}
/** *********************  END DELETE FUNDER DETAILS ******************** */

/** *********************  START UPDATE FUNDER IS PUBLISH STATUS DETAILS  ******************** */
export function* FunderIsPublishUpdate({ payload }) {
	try {
		const key = 'UpdateFunderPublisKey';
		const jsonData = {
			FunderID: payload.funderID,
			IsPublish: payload.isPublish,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(FunderPublishUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateIsPublishFunderFailure(responseData.data));
		} else {
			yield put(updateIsPublishFunderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateIsPublishFunderFailure(error));
	}
}
export function* resetUpdatePublishFunderResponse() {
	yield put(updateIsPublishFunderResponseChanged());
}

export function* onUpdatePublishFunder() {
	yield takeLatest(FunderActionTypes.UPDATE_ISPUBLISH_FUNDER_START, FunderIsPublishUpdate);
}

export function* onUpdatePublishFunderResponseReset() {
	yield takeLatest(
		FunderActionTypes.UPDATE_ISPUBLISH_FUNDER_RESPONSE_RESET,
		resetUpdatePublishFunderResponse,
	);
}

/** *********************  START UPDATE FUNDER IS PUBLISH STATUS DETAILS  ******************** */

export function* funderMasterSaga() {
	yield all([
		call(onAddFunderDetails),
		call(onAddFunderDetailsResponseReset),
		call(onUpdateFunderDetails),
		call(onUpdateFunderResponseReset),
		call(onGetFunderListDetails),
		call(onGetFunderListResponseReset),
		call(onFetchFunderDetails),
		call(onFetchFunderDetailsResponseReset),
		call(onDeleteFunderDetails),
		call(onDeleteFunderDetailsResponseReset),
		call(onUpdatePublishFunder),
		call(onUpdatePublishFunderResponseReset),
	]);
}
