// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProfileTypes from './profile.types';
import {
	profileDetailsGet,
	profileDetailsUpdate,
	passwordUpdate,
	secreteAuthKeyGenerate,
} from './profile.sarvices';
import {
	getProfileDetailsSuccess,
	getProfileDetailsFailure,
	getProfileDetailsResponseChanged,
	updateProfileDetailsSuccess,
	updateProfileDetailsFailure,
	updateProfileDetailsResponseChanged,
	updatePasswordSuccess,
	updatePasswordFailure,
	updatePasswordResponseChanged,
	generateSecreteAuthKeySuccess,
	generateSecreteAuthKeyFailure,
	generateSecreteAuthKeyResponseChanged,
} from './profile.actions';
import { encodeJson } from '../enode-decode';

/** *********************  START PROFILE DETAILS LIST  ******************** */
export function* getProfileDetails({ payload }) {
	try {
		const key = 'FetchProfileDetailsKey';
		const jsonData = {
			staffID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(profileDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProfileDetailsFailure(responseData.data));
		} else {
			yield put(getProfileDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProfileDetailsFailure(error));
	}
}

export function* resetProfileDetailsResponse() {
	yield put(getProfileDetailsResponseChanged());
}

export function* onGetProfileDetails() {
	yield takeLatest(ProfileTypes.GET_PROFILE_DETAILS_START, getProfileDetails);
}

export function* onGetProfileDetailsResponseReset() {
	yield takeLatest(ProfileTypes.GET_PROFILE_DETAILS_RESPONSE_RESET, resetProfileDetailsResponse);
}

/** *********************  END PROFILE DETAILS LIST  ******************** */

/** *********************  START PROFILE DETAILS UPDATE  ******************** */
export function* updateProfileDetails({ payload }) {
	try {
		const key = 'UpdateProfileDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			StaffID: payload.userID,
			AdminID: payload.userID,
			DatabaseName: payload.databaseName,
			CompanyName: payload.companyName,
			CompanyPhoneNumber: payload.companyPhoneNumber,
			AdminPhoneNumber: payload.UserPhoneNumber,
			NoOfGroupMembers: payload.noOfGroupMembers,
			MinNoOfGroupMembers: payload.minNoOfGroupMembers,
			MaxRuralIncome: payload.maxRuralIncome,
			MaxUrbanIncome: payload.maxUrbanIncome,
			CompanyEmergencyNumber: payload.companyEmergencyNumber,
			ProvisionType: payload.provisionType,
			CbDisbursementDayInterval: payload.cbDisbursementDayInterval,
			MaxDaysForDisbAfterCBCheck: payload.maxDaysForDisbAfterCBCheck,
			IsThrift: payload.isThrift ? 1 : 0,
			IsCollectionPhotoNeeded: payload.isCollectionPhotoNeeded ? 1 : 0,
			IsCollectionLocationNeeded: payload.isCollectionLocationNeeded ? 1 : 0,
			IsIndividualSavings: payload.isIndividualSavings ? 1 : 0,
			IsGroupSaving: payload.isGroupSaving ? 1 : 0,
			IsGroupLoan: payload.isGroupLoan ? 1 : 0,
			IsExtraChargesVouchers: payload.isExtraChargesVouchers ? 1 : 0,
			IsAuthenticationNeeded: payload.isAuthenticationNeeded ? 1 : 0,
			IsAutoEODNeededSat: payload.isAutoEODNeededSat ? 1 : 0,
			IsAutoEODNeeded: payload.isAutoEODNeeded ? 1 : 0,
			IsMoratoriumNeeded: payload.isMoratoriumNeeded ? 1 : 0,
			IsGroupAccountNeeded: payload.isGroupAccountNeeded ? 1 : 0,
			IsSubGroupNeeded: payload.isSubGroupNeeded ? 1 : 0,
			IsGoldLoanAvailed: payload.isGoldLoanAvailed ? 1 : 0,
			PercentageonSellingPrice: payload.percentageonSellingPrice ? 1 : 0,
			IsIndividualLoanNeeded: payload.isIndividualLoanNeeded ? 1 : 0,
			NeedJournalEntryAfterEOD: payload.needJournalEntryAfterEOD ? 1 : 0,
			AuditingToolneededforloan: payload.auditingToolneededforloan ? 1 : 0,
			IsNachCollectionNeeded: payload.isNachCollectionNeeded ? 1 : 0,
			SponsorBankCode: payload.sponsorBankCode,
			UtilityBankCode: payload.utilityBankCode,
			PrimaryAccountHolder: payload.primaryAccountHolder,
			IsExistingAadharCheck: payload.isExistingAadharCheck ? 1 : 0,
			IsExistingNomineeAadharCheck: payload.isExistingNomineeAadharCheck ? 1 : 0,
			IsSkipNomineeExistForCloseLoan: payload.isSkipNomineeExistForCloseLoan ? 1 : 0,
			IsOfficerAttendance: payload.isOfficerAttendance ? 1 : 0,
			IsWorkFlowManagementNeeded: payload.isWorkFlowManagementNeeded ? 1 : 0,
			IsDailyEODNeeded: payload.isDailyEODNeeded ? 1 : 0,
			IsExistingMobileCheck: payload.isExistingMobileCheck ? 1 : 0,
			IsNeedCoApplicantCBCheckAfterMember: payload.isNeedCoApplicantCBCheckAfterMember
				? 1
				: 0,
			IsTenureClosedArrearsinDemandCollectionReport:
				payload.isTenureClosedArrearsinDemandCollectionReport ? 1 : 0,
			IsActiveLoanArrearsinDCReport: payload.isActiveLoanArrearsinDCReport ? 1 : 0,
			IsFinanceApproveNeededforEOD: payload.isFinanceApproveNeededforEOD ? 1 : 0,
			IsPhoneNumberVerificationNeeded: payload.isPhoneNumberVerificationNeeded ? 1 : 0,
			IsFieldOfficercancheckCreditBureau: payload.isFieldOfficercancheckCreditBureau ? 1 : 0,
			IsBorrowerHighmarkMandatoryForApp: payload.isBorrowerHighmarkMandatoryForApp ? 1 : 0,
			IsHighmarkMandatoryForApp: payload.isHighmarkMandatoryForApp ? 1 : 0,
			IsProductConfigNeed: payload.isProductConfigNeed ? 1 : 0,
			IsSubAccountNeed: payload.isSubAccountNeed ? 1 : 0,
			IsTopupLoanNeeded: payload.isTopupLoanNeeded ? 1 : 0,
			IsKYCNeededinWeb: payload.isKYCNeededinWeb ? 1 : 0,
			IsTrainingMandatoryForLoan: payload.isTrainingMandatoryForLoan ? 1 : 0,
			IsEditActiveLoanMember: payload.editActiveLoanMemberDetails ? 1 : 0,
			IsHighmarkDownloadNeededForApp: payload.isHighmarkDownloadNeededForApp ? 1 : 0,
			IsKYCNeededinMobile: payload.isKYCNeededinMobile ? 1 : 0,
			IsNextFollowUpNeeded: payload.isNextFollowUpNeeded ? 1 : 0,
			IsAuditingImageNeeded: payload.isAuditingImageNeeded ? 1 : 0,
			IsWorkFlowNeeded: payload.IsWorkFlowNeeded ? 1 : 0,
			IsIndividualTrainingNeeded: payload.IsIndividualTrainingNeeded ? 1 : 0,
			RestrictLoanAlreadyExist: payload.IsRestrictLoanAlreadyExist ? 1 : 0,
			IsLevel1CanApproveHighmarkRejectedMember:
				payload.isLevel1CanApproveHighmarkRejectedMember ? 1 : 0,
			IsLevel2CanApproveHighmarkRejectedMember:
				payload.isLevel2CanApproveHighmarkRejectedMember ? 1 : 0,
			IsLevel3CanApproveHighmarkRejectedMember:
				payload.isLevel3CanApproveHighmarkRejectedMember ? 1 : 0,
			IsLevel4CanApproveHighmarkRejectedMember:
				payload.isLevel4CanApproveHighmarkRejectedMember ? 1 : 0,
			IsLevel6CanApproveHighmarkRejectedMember:
				payload.isLevel6CanApproveHighmarkRejectedMember ? 1 : 0,
			IsCollectionTypeNeeded: payload.isCollectionTypeNeeded ? 1 : 0,
			IsRejectionNeedForFieldOfficer: payload.isRejectionNeedForFieldOfficer ? 1 : 0,
			AfterEODCollectionNeededinMobile: payload.isAfterEODCollectionNeededForApp ? 1 : 0,
			IsShowActualDueDate: payload.isShowActualDueDate ? 1 : 0,
			IsAuditNotMandatoryForSecondLoan: payload.isAuditNotMandatoryForSecondLoan ? 1 : 0,
			IsonlyLeaderSMS: payload.isonlyLeaderSMS ? 1 : 0,
			IsallMemberNotifySMS: payload.isallMemberNotifySMS ? 1 : 0,
			IsduePaymentSMS: payload.isduePaymentSMS ? 1 : 0,
			IsonBoardingMemberSMS: payload.isonBoardingMemberSMS ? 1 : 0,
			IsoverdueOnEODSMS: payload.isoverdueOnEODSMS ? 1 : 0,
			IsonSavingsCollectionSMS: payload.isonSavingsCollectionSMS ? 1 : 0,
			UserPhoto: payload.userPhoto,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(profileDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProfileDetailsFailure(responseData.data));
		} else {
			yield put(updateProfileDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProfileDetailsFailure(error));
	}
}

export function* resetProfileUpdateDetailsResponse() {
	yield put(updateProfileDetailsResponseChanged());
}

export function* onUpdateProfileDetails() {
	yield takeLatest(ProfileTypes.UPDATE_PROFILE_DETAILS_START, updateProfileDetails);
}

export function* onUpdateProfileDetailsResponseReset() {
	yield takeLatest(
		ProfileTypes.UPDATE_PROFILE_DETAILS_RESPONSE_RESET,
		resetProfileUpdateDetailsResponse,
	);
}

/** *********************  END PROFILE DETAILS UPDATE  ******************** */

/** *********************  START UPDATE PASSWORD LIST  ******************** */
export function* updatePassword({ payload }) {
	try {
		const key = 'UpdatePasswordKey';
		const jsonData = {
			StaffID: payload.userID,
			UserName: payload.userName,
			CompanyID: payload.companyID,
			UserPhoneNumber: payload.userPhoneNumber,
			CurrentPassword: payload.currentPassword,
			ConfirmPassword: payload.confirmPassword,
			GeneralUserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(passwordUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePasswordFailure(responseData.data));
		} else {
			yield put(updatePasswordSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePasswordFailure(error));
	}
}

export function* resetUpdatePasswordResponse() {
	yield put(updatePasswordResponseChanged());
}

export function* onUpdatePassword() {
	yield takeLatest(ProfileTypes.UPDATE_PASSWORD_START, updatePassword);
}

export function* onUpdatePasswordResponseReset() {
	yield takeLatest(ProfileTypes.UPDATE_PASSWORD_RESPONSE_RESET, resetUpdatePasswordResponse);
}

/** *********************  END UPDATE PASSWORD  ******************** */

/** *********************  START GENERATE SECRETE AUTHKEY ******************** */
export function* generateSecreteAuthKeyQr({ payload }) {
	try {
		const key = 'GenerateSecreteAuthKey';
		const jsonData = {
			StaffID: payload.userID,
			CompanyID: payload.companyID,
			UserPhoneNumber: payload.userPhoneNumber,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(secreteAuthKeyGenerate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(generateSecreteAuthKeyFailure(responseData.data));
		} else {
			yield put(generateSecreteAuthKeySuccess(responseData.data));
		}
	} catch (error) {
		yield put(generateSecreteAuthKeyFailure(error));
	}
}

export function* resetGenerateSecreteAuthKeyResponse() {
	yield put(generateSecreteAuthKeyResponseChanged());
}

export function* onGenerateSecreteAuthKey() {
	yield takeLatest(ProfileTypes.GENERATE_SECRETE_AUTHKEY_START, generateSecreteAuthKeyQr);
}

export function* onGenerateSecreteAuthKeyResponseReset() {
	yield takeLatest(
		ProfileTypes.GENERATE_SECRETE_AUTHKEY_RESPONSE_RESET,
		resetGenerateSecreteAuthKeyResponse,
	);
}

/** *********************  END GENERATE SECRETE AUTHKEYFUN  ******************** */

export function* profileSaga() {
	yield all([
		call(onGetProfileDetails),
		call(onGetProfileDetailsResponseReset),
		call(onUpdateProfileDetails),
		call(onUpdateProfileDetailsResponseReset),
		call(onUpdatePassword),
		call(onUpdatePasswordResponseReset),
		call(onGenerateSecreteAuthKey),
		call(onGenerateSecreteAuthKeyResponseReset),
	]);
}
