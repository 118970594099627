/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const memberPhotoAdd = (verifyData) => {
	const data = {
		AddMemberPhotoToken: verifyData,
	};
	const url = `${API_URL}addMemberPhoto`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const imageProcessAPI = (verifyData) => {
	const data = {
		AddWaterMarkToken: verifyData,
	};
	const url = `${API_URL}addWaterMarkApp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
