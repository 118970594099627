/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanCardReportActionTypes from './loanCardReport.types';
import {
	loanCardReportGet,
	memberLoanCardReport,
	particualrLoanCardReport,
	LoanCardReportExcelListGet,
} from './loanCardReport.service';

import {
	getLoanCardReportSuccess,
	getLoanCardReportFailure,
	getLoanCardReportResponseChanged,
	getMemberLoanCardSuccess,
	getMemberLoanCardFailure,
	getMemberLoanCardResponseChanged,
	getParticularLoanCardSuccess,
	getParticularLoanCardFailure,
	getParticularLoanCardResponseChanged,
	fetchLoanCardExcelSuccess,
	fetchLoanCardExcelFailure,
	fetchLoanCardExcelChanged,
} from './loanCardReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getLoanCardReport({ payload }) {
	try {
		const key = 'FetchLoanCardReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			FunderID: '0',
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.loanIndexID,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanCardReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanCardReportFailure(responseData.data));
		} else {
			yield put(getLoanCardReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanCardReportFailure(error));
	}
}

export function* resetGetLoanCardReportListResponse() {
	yield put(getLoanCardReportResponseChanged());
}

export function* onGetLoanCardReportDetails() {
	yield takeLatest(LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_START, getLoanCardReport);
}

export function* onGetLoanCardReportResponseReset() {
	yield takeLatest(
		LoanCardReportActionTypes.GET_LOAN_CARD_REPORT_RESPONSE_RESET,
		resetGetLoanCardReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

/** *********************  START GET MEMBER LOAN CARD   ******************** */
export function* getMemberLoanCard({ payload }) {
	try {
		const key = 'GetLoanDetailsForParticularMemberKey';
		const jsonData = {
			CompanyID: payload.companyID,
			FunderID: '0',
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.loanIndexID,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberLoanCardReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberLoanCardFailure(responseData.data));
		} else {
			yield put(getMemberLoanCardSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberLoanCardFailure(error));
	}
}

export function* resetGetMemberLoanCardListResponse() {
	yield put(getMemberLoanCardResponseChanged());
}

export function* onGetMemberLoanCardDetails() {
	yield takeLatest(LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_START, getMemberLoanCard);
}

export function* onGetMemberLoanCardResponseReset() {
	yield takeLatest(
		LoanCardReportActionTypes.GET_MEMBER_LOAN_CARD_RESPONSE_RESET,
		resetGetMemberLoanCardListResponse,
	);
}
/** *********************  END GET MEMBER LOAN CARD ******************** */

/** *********************  START GET PARTICULAR LOAN CARD ******************** */
export function* getParticularLoanCard({ payload }) {
	try {
		const key = 'GetLoanCardDetailsBasedOnMemebersKey';
		const jsonData = {
			CompanyID: payload.companyID,
			FunderID: '0',
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.loanIndexID,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(particualrLoanCardReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularLoanCardFailure(responseData.data));
		} else {
			yield put(getParticularLoanCardSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularLoanCardFailure(error));
	}
}

export function* resetGetParticularLoanCardListResponse() {
	yield put(getParticularLoanCardResponseChanged());
}

export function* onGetParticularLoanCardDetails() {
	yield takeLatest(
		LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_START,
		getParticularLoanCard,
	);
}

export function* onGetParticularLoanCardResponseReset() {
	yield takeLatest(
		LoanCardReportActionTypes.GET_PARTICULAR_LOAN_CARD_RESPONSE_RESET,
		resetGetParticularLoanCardListResponse,
	);
}
/** *********************  END GET PARTICULAR LOAN CARD ******************** */

/** *********************  START LOAN CARD EXCEL ******************** */
export function* fecthLoanCardExcel({ payload }) {
	try {
		const key = 'LoanCardReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.loanIndexID,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanCardReportExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanCardExcelFailure(responseData.data));
		} else {
			yield put(fetchLoanCardExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanCardExcelFailure(error));
	}
}

export function* resetFetchLoanCardExcelResponse() {
	yield put(fetchLoanCardExcelChanged());
}

export function* onFecthLoanCardExcelDetails() {
	yield takeLatest(LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_START, fecthLoanCardExcel);
}

export function* onFecthLoanCardExcelResponseReset() {
	yield takeLatest(
		LoanCardReportActionTypes.FETCH_LOAN_CARD_EXCEL_RESET,
		resetFetchLoanCardExcelResponse,
	);
}
/** *********************  END LOAN CARD EXCEL ******************** */

export function* loanCardReportSaga() {
	yield all([
		call(onGetLoanCardReportDetails),
		call(onGetLoanCardReportResponseReset),
		call(onGetMemberLoanCardDetails),
		call(onGetMemberLoanCardResponseReset),
		call(onGetParticularLoanCardDetails),
		call(onGetParticularLoanCardResponseReset),
		call(onFecthLoanCardExcelDetails),
		call(onFecthLoanCardExcelResponseReset),
	]);
}
