// eslint-disable-next-line import/no-unresolved
import CurrentAddressActionType from './current-address.types';

export const currentAddressDataStart = (saveData) => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_CHANGED,
	payload: saveData,
});
// Otp Verify Mobile Number
export const currentAddressAddStart = (currentAddressCredentials) => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_START,
	payload: currentAddressCredentials,
});

export const currentAddressAddSuccess = (currentAddressSuccess) => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_SUCCESS,
	payload: currentAddressSuccess,
});

export const currentAddressAddFailure = (currentAddressFailure) => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_FAILURE,
	payload: currentAddressFailure,
});

export const currentAddressAddResponseResetStart = () => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_RESPONSE_REST_START,
});

export const currentAddressAddUserResponseChanged = () => ({
	type: CurrentAddressActionType.CURRENT_ADDRESS_ADD_RESPONSE_CHANGED,
});
