/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';
import PortfolioReportActionTypes from './portfolioReport.types';
import { portfolioReportDetailsGet, PortfolioExcelListGet } from './portfolioReport.service';
import {
	getPortfolioReportDetailsSuccess,
	getPortfolioReportDetailsFailure,
	getPortfolioReportDetailsResponseChanged,
	fetchPortfolioExcelSuccess,
	fetchPortfolioExcelFailure,
	fetchPortfolioExcelChanged,
} from './portfolioReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getPortfolioReportList({ payload }) {
	try {
		const key = 'PostportfolioReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.locationID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			SearchDateType: payload.dateTab === 'As On Date' ? 'tilldate' : 'fromDate',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(portfolioReportDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPortfolioReportDetailsFailure(responseData.data));
		} else {
			yield put(getPortfolioReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPortfolioReportDetailsFailure(error));
	}
}

export function* resetGetPortfolioReportListResponse() {
	yield put(getPortfolioReportDetailsResponseChanged());
}

export function* onGetPortfolioReportListDetails() {
	yield takeLatest(
		PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_START,
		getPortfolioReportList,
	);
}

export function* onGetPortfolioReportListResponseReset() {
	yield takeLatest(
		PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_RESPONSE_RESET,
		resetGetPortfolioReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */
/** *********************  START FETCH PORTFOLIO EXCEL  ******************** */
export function* fecthProtfolioExcel({ payload }) {
	try {
		const key = 'GetPortfolioReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.centerID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			SearchDateType: payload.datefilter === 'As On Date' ? 'TillDate' : 'FromToDate',
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PortfolioExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPortfolioExcelFailure(responseData.data));
		} else {
			yield put(fetchPortfolioExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPortfolioExcelFailure(error));
	}
}

export function* resetFetchPortfolioExcelResponse() {
	yield put(fetchPortfolioExcelChanged());
}

export function* onFecthPortfolioExcelDetails() {
	yield takeLatest(PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_START, fecthProtfolioExcel);
}

export function* onFecthPortfolioExcelResponseReset() {
	yield takeLatest(
		PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_RESET,
		resetFetchPortfolioExcelResponse,
	);
}
/** *********************  END FETCH PORTFOLIO EXCEL ******************** */

export function* portfolioReportSaga() {
	yield all([
		call(onGetPortfolioReportListDetails),
		call(onGetPortfolioReportListResponseReset),
		call(onFecthPortfolioExcelDetails),
		call(onFecthPortfolioExcelResponseReset),
	]);
}
