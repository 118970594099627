/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchDCBReportActionTypes from './branchDCBReport.types';

import { branchDCBReportListGet, branchDCBReportExcelListGet } from './branchDCBReport.service';

import {
	getBranchDCBReportDetailsSuccess,
	getBranchDCBReportDetailsFailure,
	getBranchDCBReportDetailsResponseChanged,
	fetchBranchDCBReportExcelSuccess,
	fetchBranchDCBReportExcelFailure,
	fetchBranchDCBReportResponseExcelResetChanged,
} from './branchDCBReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET BRANCH DCB REPORT DETAILS  ******************** */
export function* getBranchDCBReportList({ payload }) {
	try {
		const key = 'GetBranchDCBReportKey';
		const jsonData = {
			companyID: payload.CompanyID,
			branchID: payload.BranchID,
			centerID: payload.CenterID,
			GroupID: payload.GroupID,
			DatabaseName: payload.DatabaseName,
			fromDate: payload.FromDate,
			toDate: payload.ToDate,
			userID: payload.UserID,
			userlevel: payload.UserLevel,
			LoanFunder: payload.FunderID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDCBReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchDCBReportDetailsFailure(responseData.data));
		} else {
			yield put(getBranchDCBReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchDCBReportDetailsFailure(error));
	}
}

export function* resetgetBranchDCBReportListResponse() {
	yield put(getBranchDCBReportDetailsResponseChanged());
}

export function* onGetBranchDCBReportListDetails() {
	yield takeLatest(
		BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_START,
		getBranchDCBReportList,
	);
}

export function* onGetBranchDCBReportListResponseReset() {
	yield takeLatest(
		BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_RESPONSE_RESET,
		resetgetBranchDCBReportListResponse,
	);
}
/** *********************  END GET BRANCH DCB REPORT DETAILS ******************** */

/** *********************  START GET MEMBER DETAILS EXCEL REPORT  ******************** */
export function* getBranchDCBReportExcelList({ payload }) {
	try {
		const key = 'BranchDCBReportExport';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserID: payload.UserID,
			FunderID: payload.FunderID,
			GeneralCompanyID: payload.generalCompanyID,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDCBReportExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchDCBReportExcelFailure(responseData.data));
		} else {
			yield put(fetchBranchDCBReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchDCBReportExcelFailure(error));
	}
}

export function* resetGetBranchDCBReportExcelListResponse() {
	yield put(fetchBranchDCBReportResponseExcelResetChanged());
}

export function* onGetBranchDCBReportExcelListDetails() {
	yield takeLatest(
		BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_START,
		getBranchDCBReportExcelList,
	);
}

export function* onGetBranchDCBReportExcelListResponseReset() {
	yield takeLatest(
		BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_RESET,
		resetGetBranchDCBReportExcelListResponse,
	);
}
/** *********************  END GET MEMBER DETAILS EXCEL REPORT ******************** */

export function* branchDCBReportSaga() {
	yield all([
		call(onGetBranchDCBReportListDetails),
		call(onGetBranchDCBReportListResponseReset),
		call(onGetBranchDCBReportExcelListDetails),
		call(onGetBranchDCBReportExcelListResponseReset),
	]);
}
