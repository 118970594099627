/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import InsuranceReportTypes from './insurance-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchInsuranceDetailResponse: null,
	fetchInsuranceExportDetailResponse: null,
	fetchInsuranceDetailArr: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case InsuranceReportTypes.FETCH_INSURANCE_REPORT_SUCCESS:
			return {
				...state,
				fetchInsuranceDetailResponse: action.payload,
				fetchInsuranceDetailArr: docodeResponse(action.payload, 'GetInsuranceReportDetailsKey'),
			};

		case InsuranceReportTypes.FETCH_INSURANCE_REPORT_FAILURE:
			return { ...state, fetchInsuranceDetailResponse: action.payload };

		case InsuranceReportTypes.FETCH_INSURANCE_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchInsuranceDetailResponse: null };

			case InsuranceReportTypes.FETCH_INSURANCE_REPORT_EXPORT_SUCCESS:
				return {
					...state,
					fetchInsuranceExportDetailResponse: action.payload,
				};
	
			case InsuranceReportTypes.FETCH_INSURANCE_REPORT_EXPORT_FAILURE:
				return { ...state, fetchInsuranceExportDetailResponse: action.payload };
	
			case InsuranceReportTypes.FETCH_INSURANCE_REPORT_EXPORT_RESPONSE_CHANGED:
				return { ...state, fetchInsuranceExportDetailResponse: null };

		default:
			return state;
	}
};
