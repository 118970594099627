/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const createNewCenterWiseLoan = (addData) => {
	const data = {
		CenterWiseLoanProposalToken: addData,
	};
	const url = `${API_URL}createCenterWiseLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateCenterWiseLoan = (updateData) => {
	const data = {
		CenterWiseLoanProposalUpdateToken: updateData,
	};
	const url = `${API_URL}updateCenterWiseLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerWiseLoanProposalListFetch = (listData) => {
	const data = {
		GetCenterWiseLoanProposalListToken: listData,
	};
	const url = `${API_URL}getCenterWiseLoanProposalList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerWiseLoanProposalApprove = (approveData) => {
	const data = {
		CenterWiseLoanProposalApprovalToken: approveData,
	};
	const url = `${API_URL}centerWiseLoanProposalApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerWiseLoanProposalDetailsFetch = (fetchData) => {
	const data = {
		FetchCenterWiseLoanProposalDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchCenterWiseLoanProposalDetail`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerWiseLoanProposalDelete = (deleteData) => {
	const data = {
		CenterWiseLoanProposalDeleteToken: deleteData,
	};
	const url = `${API_URL}deleteCenterWiseLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchMembersListForCenterLoan = (deleteData) => {
	const data = {
		GetCenterWiseMembersListToken: deleteData,
	};
	const url = `${API_URL}GetMemberDetailsForCenterLoanToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
