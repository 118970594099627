import { takeLatest, put, all, call } from 'redux-saga/effects';
import PassportActionType from './passport-details.types';
import {
	updatePassportSuccess,
	updatePassportFailure,
	updatePassportResponseChanged,
} from './passport-details.action';
import { encodeJson } from '../../../enode-decode';
import { passportUpdate } from './passport-details.service';

// Start Voter Id  Add
export function* updatePassport({ payload }) {
	try {
		const key = 'updatePassportID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			PassportImg: payload.passportImg,
			ProofType: payload.proofType,
			DocumantType: payload.docType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const passportData = encodeJson(jsonData, key);
		const responseData = yield call(passportUpdate, passportData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePassportFailure(responseData.data));
		} else {
			yield put(updatePassportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePassportFailure(error));
	}
}

export function* updatePassportResponseClear() {
	yield put(updatePassportResponseChanged());
}

export function* onPassportUpdateStart() {
	yield takeLatest(PassportActionType.UPDATE_PASSPORT_START, updatePassport);
}

export function* onPassportUpdateResponseRest() {
	yield takeLatest(
		PassportActionType.UPDATE_PASSPORT_RESPONSE_REST_START,
		updatePassportResponseClear,
	);
}
// End Voter Id  Add

export function* passportSaga() {
	yield all([call(onPassportUpdateStart), call(onPassportUpdateResponseRest)]);
}
