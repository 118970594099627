/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import PhotoVerificationActionType from './photo-verification.types';
import {
	addMemberPhotoSuccess,
	addMemberPhotoFailure,
	addMemberPhotoUserResponseChanged,
	imageProcessResponseChanged,
	imageProcessSuccess,
	imageProcessFailure,
} from './photo-verification.action';
import { encodeJson } from '../../../enode-decode';
import { memberPhotoAdd, imageProcessAPI } from './photo-verification.service';

// Start Add Member Photo
export function* AddMemberPhoto({ payload }) {
	try {
		const key = 'AddMemberPhotoVerification';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberPhoto: payload.memberProfileImg,
			DocumantType: payload.docType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(memberPhotoAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberPhotoFailure(responseData.data));
		} else {
			yield put(addMemberPhotoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberPhotoFailure(error));
	}
}

export function* AddMemberPhotoResponseClear() {
	yield put(addMemberPhotoUserResponseChanged());
}

export function* onAddMemberPhotoStart() {
	yield takeLatest(PhotoVerificationActionType.ADD_MEMBER_PHOTO_START, AddMemberPhoto);
}

export function* onAddMemberPhotoResponseReset() {
	yield takeLatest(
		PhotoVerificationActionType.ADD_MEMBER_PHOTO_RESPONSE_REST_START,
		AddMemberPhotoResponseClear,
	);
}
// End Add Member Photo

// Start Image Process
export function* ImageProcess({ payload }) {
	try {
		const key = 'AddWaterMarkToken';
		const jsonData = {
			Base64: payload.base64,
			SavePath: payload.savePath,
			Type: payload.type,
			Latitude: payload.Latitude,
			Longitude: payload.Logitude,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(imageProcessAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(imageProcessFailure(responseData.data));
		} else {
			yield put(imageProcessSuccess(responseData.data));
		}
	} catch (error) {
		yield put(imageProcessFailure(error));
	}
}

export function* ImageProcessResponseClear() {
	yield put(imageProcessResponseChanged());
}

export function* onImageProcessStart() {
	yield takeLatest(PhotoVerificationActionType.IMAGE_PROCESS_START, ImageProcess);
}

export function* onImageProcessResponseReset() {
	yield takeLatest(
		PhotoVerificationActionType.IMAGE_PROCESS_RESPONSE_REST_START,
		ImageProcessResponseClear,
	);
}
// End Image Process

export function* addMemberPhotoSaga() {
	yield all([
		call(onAddMemberPhotoStart),
		call(onAddMemberPhotoResponseReset),
		call(onImageProcessStart),
		call(onImageProcessResponseReset),
	]);
}
