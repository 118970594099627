import DrivingLicenceActionType from './driving-licence-details.types';

// update DrivingLicence
export const updateDrivingLicenceStart = (DrivingLicenceCredentials) => ({
	type: DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_START,
	payload: DrivingLicenceCredentials,
});

export const updateDrivingLicenceSuccess = (DrivingLicenceSuccess) => ({
	type: DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_SUCCESS,
	payload: DrivingLicenceSuccess,
});

export const updateDrivingLicenceFailure = (DrivingLicenceFailure) => ({
	type: DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_FAILURE,
	payload: DrivingLicenceFailure,
});

export const updateDrivingLicenceResponseResetStart = () => ({
	type: DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_RESPONSE_REST_START,
});

export const updateDrivingLicenceResponseChanged = () => ({
	type: DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_RESPONSE_CHANGED,
});
