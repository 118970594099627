// eslint-disable-next-line import/no-unresolved
import AddMemberActionType from './add-member.types';

export const updateStageCompletedStart = (updatedVal) => ({
	type: AddMemberActionType.UPDATE_STAGE_COMPLETED_CODE,
	payload: updatedVal,
});

export const fetchExistsMemberDetailsStart = (bankCredentils) => ({
	type: AddMemberActionType.GET_MEMBER_DETAILS_START,
	payload: bankCredentils,
});

export const fetchExistsMemberDetailsSuccess = (bank) => ({
	type: AddMemberActionType.GET_MEMBER_DETAILS_SUCCESS,
	payload: bank,
});

export const fetchExistsMemberDetailsFailure = (error) => ({
	type: AddMemberActionType.GET_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const fetchExistsMemberDetailsResponseResetStart = () => ({
	type: AddMemberActionType.GET_MEMBER_DETAILS_RESPONSE_REST_START,
});

export const fetchExistsMemberDetailsResponseChanged = () => ({
	type: AddMemberActionType.GET_MEMBER_DETAILS_RESPONSE_CHANGED,
});

// Fetch
export const fetchMemberDataStart = (data) => ({
	type: AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_START,
	payload: data,
});

export const fetchMemberDataSuccess = (bank) => ({
	type: AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_SUCCESS,
	payload: bank,
});

export const fetchMemberDataFailure = (error) => ({
	type: AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const fetchMemberDataResponseResetStart = () => ({
	type: AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_REST_START,
});

export const fetchMemberDataResponseChanged = () => ({
	type: AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_CHANGED,
});

export const getMemberMandatoryStart = (data) => ({
	type: AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_START,
	payload: data,
});

export const getMemberMandatorySuccess = (bank) => ({
	type: AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_SUCCESS,
	payload: bank,
});

export const getMemberMandatoryFailure = (error) => ({
	type: AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_FAILURE,
	payload: error,
});

export const getMemberMandatoryResponseResetStart = () => ({
	type: AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_RESPONSE_REST_START,
});

export const getMemberMandatoryResponseChanged = () => ({
	type: AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_RESPONSE_CHANGED,
});

// ADD MEMBER NAVIGATION LIST
export const getAddMemberNavListStart = (data) => ({
	type: AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_START,
	payload: data,
});

export const getAddMemberNavListSuccess = (data) => ({
	type: AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_SUCCESS,
	payload: data,
});

export const getAddMemberNavListFailure = (error) => ({
	type: AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_FAILURE,
	payload: error,
});

export const getAddMemberNavListResetStart = () => ({
	type: AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_RESET_START,
});

export const getAddMemberNavListResponseChanged = () => ({
	type: AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_CHANGED,
});

export const updateAddMemNavArr = (updatedVal) => ({
	type: AddMemberActionType.UPDATE_ADD_MEMBER_NAV_LIST_ARRAY,
	payload: updatedVal,
});

// CHECK MEMBER MANDATORY DATA
export const checkMandatoryFieldsStart = (data) => ({
	type: AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_START,
	payload: data,
});

export const checkMandatoryFieldsSuccess = (data) => ({
	type: AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_SUCCESS,
	payload: data,
});

export const checkMandatoryFieldsFailure = (error) => ({
	type: AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_FAILURE,
	payload: error,
});

export const checkMandatoryFieldsResetStart = () => ({
	type: AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_RESET_START,
});

export const checkMandatoryFieldsRespChanged = () => ({
	type: AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_CHANGED,
});

// CHECK MEMBER MANDATORY SCREEN STATUS
export const checkMandatoryScreenStatusStart = (data) => ({
	type: AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_START,
	payload: data,
});

export const checkMandatoryScreenStatusSuccess = (data) => ({
	type: AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_SUCCESS,
	payload: data,
});

export const checkMandatoryScreenStatusFailure = (error) => ({
	type: AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_FAILURE,
	payload: error,
});

export const checkMandatoryScreenStatusResetStart = () => ({
	type: AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_RESET_START,
});

export const checkMandatoryScreenStatusRespChanged = () => ({
	type: AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_CHANGED,
});

// REJECT MEMBER
export const rejectMemberDashDetailsStart = (bankCredentils) => ({
	type: AddMemberActionType.REJECT_MEMBER_DETAILS_START,
	payload: bankCredentils,
});

export const rejectMemberDetailsSuccess = (bank) => ({
	type: AddMemberActionType.REJECT_MEMBER_DETAILS_SUCCESS,
	payload: bank,
});

export const rejectMemberDetailsFailure = (error) => ({
	type: AddMemberActionType.REJECT_MEMBER_DETAILS_FAILURE,
	payload: error,
});

export const rejectMemberDetailsResponseResetStart = () => ({
	type: AddMemberActionType.REJECT_MEMBER_DETAILS_RESPONSE_REST_START,
});

export const rejectMemberDetailsResponseChanged = () => ({
	type: AddMemberActionType.REJECT_MEMBER_DETAILS_RESPONSE_CHANGED,
});

export const updateMemberAccessType = (Credentils) => ({
	type: AddMemberActionType.UPDATE_MEMBER_ACCESS_TYPE,
	payload: Credentils,
});

// FETCH MEMBER LIST
export const fetchAddMemberListStart = (Credentils) => ({
	type: AddMemberActionType.FETCH_ADD_MEMBER_LIST_START,
	payload: Credentils,
});

export const fetchAddMemberListSuccess = (Credentils) => ({
	type: AddMemberActionType.FETCH_ADD_MEMBER_LIST_SUCCESS,
	payload: Credentils,
});

export const fetchAddMemberListFailure = (error) => ({
	type: AddMemberActionType.FETCH_ADD_MEMBER_LIST_FAILURE,
	payload: error,
});

export const fetchAddMemberListResponseResetStart = () => ({
	type: AddMemberActionType.FETCH_ADD_MEMBER_LIST_RESPONSE_REST_START,
});

export const fetchAddMemberListResponseChanged = () => ({
	type: AddMemberActionType.FETCH_ADD_MEMBER_LIST_RESPONSE_CHANGED,
});

// EXPORT MEMBER LIST
export const exportMemberListStart = (Credentils) => ({
	type: AddMemberActionType.EXPORT_MEMBER_LIST_START,
	payload: Credentils,
});

export const exportMemberListSuccess = (Credentils) => ({
	type: AddMemberActionType.EXPORT_MEMBER_LIST_SUCCESS,
	payload: Credentils,
});

export const exportMemberListFailure = (error) => ({
	type: AddMemberActionType.EXPORT_MEMBER_LIST_FAILURE,
	payload: error,
});

export const exportMemberListResponseResetStart = () => ({
	type: AddMemberActionType.EXPORT_MEMBER_LIST_RESPONSE_REST_START,
});

export const exportMemberListResponseChanged = () => ({
	type: AddMemberActionType.EXPORT_MEMBER_LIST_RESPONSE_CHANGED,
});

// DELETE MEMBER LIST
export const deleteMemberStart = (Credentils) => ({
	type: AddMemberActionType.DELETE_MEMBER_START,
	payload: Credentils,
});

export const deleteMemberSuccess = (Credentils) => ({
	type: AddMemberActionType.DELETE_MEMBER_SUCCESS,
	payload: Credentils,
});

export const deleteMemberFailure = (error) => ({
	type: AddMemberActionType.DELETE_MEMBER_FAILURE,
	payload: error,
});

export const deleteMemberResponseResetStart = () => ({
	type: AddMemberActionType.DELETE_MEMBER_RESPONSE_RESET_START,
});

export const deleteMemberResponseChanged = () => ({
	type: AddMemberActionType.DELETE_MEMBER_RESPONSE_CHANGED,
});
