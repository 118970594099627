const CurrentAddressActionType = {
	CURRENT_ADDRESS_ADD_CHANGED: 'current_address_add_changed',

	CURRENT_ADDRESS_ADD_START: 'current_address_add_start',
	CURRENT_ADDRESS_ADD_SUCCESS: 'current_address_add_success',
	CURRENT_ADDRESS_ADD_FAILURE: 'current_address_add_failure',
	CURRENT_ADDRESS_ADD_RESPONSE_REST_START: 'current_address_add_response_reset_start',
	CURRENT_ADDRESS_ADD_RESPONSE_CHANGED: 'current_address_add_response_changed',
};

export default CurrentAddressActionType;
