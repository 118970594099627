const StaffActionTypes = {
	ADD_STAFF_DETAILS_START: 'add_staff_details_start',
	ADD_STAFF_DETAILS_SUCCESS: 'add_staff_details_success',
	ADD_STAFF_DETAILS_FAILURE: 'add_staff_details_failure',
	ADD_STAFF_DETAILS_RESPONSE_RESET: 'add_staff_details_response_reset',
	ADD_STAFF_DETAILS_RESPONSE_CHANGED: 'add_staff_details_response_changed',

	UPDATE_STAFF_DETAILS_START: 'update_staff_details_start',
	UPDATE_STAFF_DETAILS_SUCCESS: 'update_staff_details_success',
	UPDATE_STAFF_DETAILS_FAILURE: 'update_staff_details_failure',
	UPDATE_STAFF_DETAILS_RESPONSE_RESET: 'update_staff_details_response_reset',
	UPDATE_STAFF_DETAILS_RESPONSE_CHANGED: 'update_staff_details_response_changed',

	GET_STAFF_LIST_START: 'get_staff_list_start',
	GET_STAFF_LIST_SUCCESS: 'get_staff_list_success',
	GET_STAFF_LIST_FAILURE: 'get_staff_list_failure',
	GET_STAFF_LIST_RESPONSE_RESET: 'get_staff_list_response_reset',
	GET_STAFF_LIST_RESPONSE_CHANGED: 'get_staff_list_response_changed',

	FETCH_STAFF_DETAILS_START: 'fetch_staff_details_start',
	FETCH_STAFF_DETAILS_SUCCESS: 'fetch_staff_details_success',
	FETCH_STAFF_DETAILS_FAILURE: 'fetch_staff_details_failure',
	FETCH_STAFF_DETAILS_RESPONSE_RESET: 'fetch_staff_details_response_reset',
	FETCH_STAFF_DETAILS_RESPONSE_CHANGED: 'fetch_staff_details_response_changed',

	DELETE_STAFF_DETAILS_START: 'delete_staff_details_start',
	DELETE_STAFF_DETAILS_SUCCESS: 'delete_staff_details_success',
	DELETE_STAFF_DETAILS_FAILURE: 'delete_staff_details_failure',
	DELETE_STAFF_DETAILS_RESPONSE_RESET: 'delete_staff_details_response_reset',
	DELETE_STAFF_DETAILS_RESPONSE_CHANGED: 'delete_staff_details_response_changed',

	UPDATE_ISPUBLISH_STATUS_START: 'update_isPublish_status_start',
	UPDATE_ISPUBLISH_STATUS_SUCCESS: 'update_isPublish_status_success',
	UPDATE_ISPUBLISH_STATUS_FAILURE: 'update_isPublish_status_failure',
	UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET: 'update_isPublish_status_response_reset',
	UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED: 'update_isPublish_status_response_changed',

	FETCH_ALL_STAFF_START: 'fetch_all_staff_start',
	FETCH_ALL_STAFF_SUCCESS: 'fetch_all_staff_success',
	FETCH_ALL_STAFF_FAILURE: 'fetch_all_staff_failure',
	FETCH_ALL_STAFF_RESPONSE_RESET: 'fetch_all_staff_response_reset',
	FETCH_ALL_STAFF_RESPONSE_CHANGED: 'fetch_all_staff_response_changed',

	EXPORT_STAFF_LIST_START: 'export_staff_list_start',
	EXPORT_STAFF_LIST_SUCCESS: 'export_staff_list_success',
	EXPORT_STAFF_LIST_FAILURE: 'export_staff_list_failure',
	EXPORT_STAFF_LIST_RESPONSE_REST_START: 'export_staff_list_response_reset_start',
	EXPORT_STAFF_LIST_RESPONSE_CHANGED: 'export_staff_list_response_changed',

	UPDATE_ISPRINT_STAFF_START: 'update_isprint_staff_start',
	UPDATE_ISPRINT_STAFF_SUCCESS: 'update_isprint_staff_success',
	UPDATE_ISPRINT_STAFF_FAILURE: 'update_isprint_staff_failure',
	UPDATE_ISPRINT_STAFF_RESPONSE_RESET: 'update_isprint_staff_response_reset',
	UPDATE_ISPRINT_STAFF_RESPONSE_CHANGED: 'update_isprint_staff_response_changed',

	GET_ALL_STAFF_LIST_EXPORT_START: 'get_all_staff_export_start',
	GET_ALL_STAFF_LIST_EXPORT_SUCCESS: 'get_all_staff_export_success',
	GET_ALL_STAFF_LIST_EXPORT_FAILURE: 'get_all_staff_export_failure',
	GET_ALL_STAFF_LIST_EXPORT_RESPONSE_RESET: 'get_all_staff_export_response_reset',
	GET_ALL_STAFF_LIST_EXPORT_RESPONSE_CHANGED: 'get_all_staff_export_response_changed',
};
export default StaffActionTypes;
