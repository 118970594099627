/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import PassbookChequeDetailsActionType from './passbook-cheque-details.types';

const INITIAL_STATE = {
	passbookChequeIDType: null,
	passbookChequeImage: null,
	updatePassbookChequeResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_SUCCESS:
			return { ...state, updatePassbookChequeResponse: action.payload };

		case PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_FAILURE:
			return { ...state, updatePassbookChequeResponse: action.payload };

		case PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_RESPONSE_CHANGED:
			return { ...state, updatePassbookChequeResponse: null };

		default:
			return state;
	}
};
