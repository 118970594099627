/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const productDetailsAdd = (addData) => {
	const data = {
		AddProductDetailsToken: addData,
	};
	const url = `${API_URL}addProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productDetailsUpdate = (updateData) => {
	const data = {
		UpdateProductDetailsToken: updateData,
	};
	const url = `${API_URL}updateProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productListGet = (listData) => {
	const data = {
		GetProductListToken: listData,
	};
	const url = `${API_URL}getProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productDetailsFetch = (fetchData) => {
	const data = {
		FetchProductDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const customProductDetailsFetch = (fetchData) => {
	const data = {
		GetCustomProductListToken: fetchData,
	};
	const url = `${API_URL}GetCustomProductListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productDetailsDelete = (deleteData) => {
	const data = {
		PublishProductDetailsToken: deleteData,
	};
	const url = `${API_URL}UpdateProductPublishStatusWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMasterAccountList = (listData) => {
	const data = {
		GetMasterAccountListData: listData,
	};
	const url = `${API_URL}GetMasterAccountList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllInstrumentList = (listData) => {
	const data = {
		GetAllInstrumentListData: listData,
	};
	const url = `${API_URL}GetAllInstrumentList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const filterProductListGet = (listData) => {
	const data = {
		GetFilterProductListToken: listData,
	};
	const url = `${API_URL}GetFilterProductListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
