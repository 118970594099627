/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DeadMemberDetailsActionTypes from './dead-member-report.types';

import {
	deadmemberDetailsListGet,
	deadmemberDetailsListExport,
} from './dead-member-report.service';

import {
	getDeadMemberDetailsDetailsSuccess,
	getDeadMemberDetailsDetailsFailure,
	getDeadMemberDetailsDetailsResponseChanged,
	getDeadMemberDetailsExportSuccess,
	getDeadMemberDetailsExportFailure,
	getDeadMemberDetailsExportResponseChanged,
} from './dead-member-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET DEAD MEMBER REPORT DETAILS  ******************** */
export function* getDeadMemberDetailsList({ payload }) {
	try {
		const key = 'GetDeadMemberReportKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deadmemberDetailsListGet, encodeData);
		if (responseData.data.status === '102') {
			yield put(getDeadMemberDetailsDetailsFailure(responseData.data));
		} else {
			yield put(getDeadMemberDetailsDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDeadMemberDetailsDetailsFailure(error));
	}
}

export function* resetGetDeadMemberDetailsListResponse() {
	yield put(getDeadMemberDetailsDetailsResponseChanged());
}

export function* onGetDeadMemberDetailsListDetails() {
	yield takeLatest(
		DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_START,
		getDeadMemberDetailsList,
	);
}

export function* onGetDeadMemberDetailsListResponseReset() {
	yield takeLatest(
		DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_RESET,
		resetGetDeadMemberDetailsListResponse,
	);
}
/** *********************  END GET DEAD MEMBER REPORT LIST ******************** */

/** *********************  START GET DEAD MEMBER EXPORT REPORT DETAILS  ******************** */
export function* getDeadMemberDetailsExport({ payload }) {
	try {
		const key = 'DeadMemberReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			UserLevel: payload.userLevel,
			UserID: payload.userID,
			UserRole: payload.userRole,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deadmemberDetailsListExport, encodeData);
		if (responseData.data.status === '102') {
			yield put(getDeadMemberDetailsExportFailure(responseData.data));
		} else {
			yield put(getDeadMemberDetailsExportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDeadMemberDetailsExportFailure(error));
	}
}

export function* resetGetDeadMemberDetailsExportResponse() {
	yield put(getDeadMemberDetailsExportResponseChanged());
}

export function* onGetDeadMemberDetailsExportDetails() {
	yield takeLatest(
		DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_START,
		getDeadMemberDetailsExport,
	);
}

export function* onGetDeadMemberDetailsExportResponseReset() {
	yield takeLatest(
		DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_RESET,
		resetGetDeadMemberDetailsExportResponse,
	);
}
/** *********************  END GET DEAD MEMBER EXPORT REPORT LIST ******************** */

export function* reportDeadMemberSaga() {
	yield all([
		call(onGetDeadMemberDetailsListDetails),
		call(onGetDeadMemberDetailsListResponseReset),
		call(onGetDeadMemberDetailsExportDetails),
		call(onGetDeadMemberDetailsExportResponseReset),
	]);
}
