const PhotoVerificationActionType = {
	ADD_MEMBER_PHOTO_START: 'add_member_photo_start',
	ADD_MEMBER_PHOTO_SUCCESS: 'add_member_photo_success',
	ADD_MEMBER_PHOTO_FAILURE: 'add_member_photo_failure',
	ADD_MEMBER_PHOTO_RESPONSE_REST_START: 'add_member_photo_response_reset_start',
	ADD_MEMBER_PHOTO_RESPONSE_CHANGED: 'add_member_photo_response_changed',

	IMAGE_PROCESS_START: 'image_process_start',
	IMAGE_PROCESS_SUCCESS: 'image_process_success',
	IMAGE_PROCESS_FAILURE: 'image_process_failure',
	IMAGE_PROCESS_RESPONSE_REST_START: 'image_process_response_reset_start',
	IMAGE_PROCESS_RESPONSE_CHANGED: 'image_process_response_changed',
};

export default PhotoVerificationActionType;
