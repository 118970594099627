/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import EndOfDayActionTypes from './endOfDay.types';

/** ***************** GET ALL COLLECTION DETAILS START *********************** */
export const getAllCollectionDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_START,
	payload: getData,
});

export const getAllCollectionDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const getAllCollectionDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const getAllCollectionDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_RESPONSE_RESET,
});

export const getAllCollectionDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL COLLECTION DETAILS END *********************** */

/** ***************** GET BRANCH EOD WALLET DETAILS START *********************** */
export const getBranchEODWalletDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_START,
	payload: getData,
});

export const getBranchEODWalletDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODWalletDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODWalletDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_RESET,
});

export const getBranchEODWalletDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH EOD WALLET DETAILS END *********************** */

/** ***************** GET BRANCH EOD THRIFT DETAILS START *********************** */
export const getBranchEODThriftDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_START,
	payload: getData,
});

export const getBranchEODThriftDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODThriftDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODThriftDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_RESET,
});

export const getBranchEODThriftDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH EOD THRIFT DETAILS END *********************** */

/** ***************** GET BRANCH EOD SAVINGS DETAILS START *********************** */
export const getBranchEODSavingsDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_START,
	payload: getData,
});

export const getBranchEODSavingsDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODSavingsDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODSavingsDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_RESET,
});

export const getBranchEODSavingsDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH EOD SAVINGS DETAILS END *********************** */

/** ***************** GET BRANCH EOD FIXED DEPOSIT DETAILS START *********************** */
export const getBranchEODFixedDepositDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_START,
	payload: getData,
});

export const getBranchEODFixedDepositDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODFixedDepositDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODFixedDepositDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_RESET,
});

export const getBranchEODFixedDepositDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH EOD FIXED DEPOSIT DETAILS END *********************** */

/** ***************** GET BRANCH EOD YETTOCOLLECT ARREAR DETAILS START *********************** */
export const getBranchEODYetToCollectArrearDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_START,
	payload: getData,
});

export const getBranchEODYetToCollectArrearDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODYetToCollectArrearDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODYetToCollectArrearDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_RESPONSE_RESET,
});

export const getBranchEODYetToCollectArrearDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_YETTOCOLLECT_ARREAR_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH EOD FIXED DEPOSIT DETAILS END *********************** */

/** ***************** GET DEMAND COLLECTION TOTAL DETAILS START *********************** */
export const getDemandCollectionTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getDemandCollectionTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getDemandCollectionTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getDemandCollectionTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getDemandCollectionTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET Demand COLLECTION TOTAL DETAILS END *********************** */

/** ***************** GET ADVANCE COLLECTION TOTAL DETAILS START *********************** */
export const getAdvanceCollectionTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getAdvanceCollectionTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getAdvanceCollectionTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getAdvanceCollectionTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getAdvanceCollectionTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ADVANCE COLLECTION TOTAL DETAILS END *********************** */

/** ***************** GET ARREAR COLLECTION TOTAL DETAILS START *********************** */
export const getArrearCollectionTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getArrearCollectionTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getArrearCollectionTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getArrearCollectionTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getArrearCollectionTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ARREAR COLLECTION TOTAL DETAILS END *********************** */

/** ***************** GET WALLET COLLECTION TOTAL DETAILS START *********************** */
export const getWalletCollectionTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getWalletCollectionTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getWalletCollectionTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getWalletCollectionTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getWalletCollectionTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET WALLET COLLECTION TOTAL DETAILS END *********************** */

/** ***************** GET PRECLOSE COLLECTION TOTAL DETAILS START *********************** */
export const getPrecloseCollectionTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getPrecloseCollectionTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getPrecloseCollectionTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getPrecloseCollectionTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getPrecloseCollectionTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET PRECLOSE COLLECTION TOTAL DETAILS END *********************** */

/** ***************** GET LOAN ADVANCE USED TOTAL DETAILS START *********************** */
export const getLoanAdvanceUsedTotalDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_START,
	payload: getData,
});

export const getLoanAdvanceUsedTotalDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_SUCCESS,
	payload: successData,
});

export const getLoanAdvanceUsedTotalDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_FAILURE,
	payload: failureData,
});

export const getLoanAdvanceUsedTotalDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_RESPONSE_RESET,
});

export const getLoanAdvanceUsedTotalDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET LOAN ADVANCE USED TOTAL DETAILS END *********************** */

/** ***************** GET CLOSE LOAN LIST DETAILS START *********************** */
export const getCloseLoanListDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_START,
	payload: getData,
});

export const getCloseLoanListDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_SUCCESS,
	payload: successData,
});

export const getCloseLoanListDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_FAILURE,
	payload: failureData,
});

export const getCloseLoanListDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_RESET,
});

export const getCloseLoanListDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET CLOSE_LOAN_LIST DETAILS END *********************** */

/** ***************** GET BRANCH EOD DETAILS START *********************** */
export const getBranchEODDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_START,
	payload: getData,
});

export const getBranchEODDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_SUCCESS,
	payload: successData,
});

export const getBranchEODDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_FAILURE,
	payload: failureData,
});

export const getBranchEODDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_RESPONSE_RESET,
});

export const getBranchEODDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET Branch EOD DETAILS END *********************** */

/** ***************** GET PREVIOUS DAY BANK ACCOUNT DAY BOOK REPORT START *********************** */
export const fetchPreviousDayBankAccountDayBookReportStart = (getData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_START,
	payload: getData,
});

export const getPreviousDayBankAccountDayBookReportSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS,
	payload: successData,
});

export const getPreviousDayBankAccountDayBookReportFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE,
	payload: failureData,
});

export const fetchPreviousDayBankAccountDayBookReportResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
});

export const getPreviousDayBankAccountDayBookReportResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET PREVIOUS DAY BANK ACCOUNT DAY BOOK REPORT END *********************** */

/** ***************** GET BANK ACCOUNT DAY BOOK REPORT START *********************** */
export const fetchBankAccountDayBookReportStart = (getData) => ({
	type: EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_START,
	payload: getData,
});

export const getBankAccountDayBookReportSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS,
	payload: successData,
});

export const getBankAccountDayBookReportFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE,
	payload: failureData,
});

export const fetchBankAccountDayBookReportResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
});

export const getBankAccountDayBookReportResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET BANK ACCOUNT DAY BOOK REPORT END *********************** */

/** ***************** GET PREVIOUS DAY CASH ACCOUNT DAY BOOK REPORT START *********************** */
export const fetchPreviousDayCashAccountDayBookReportStart = (getData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_START,
	payload: getData,
});

export const getPreviousDayCashAccountDayBookReportSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS,
	payload: successData,
});

export const getPreviousDayCashAccountDayBookReportFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE,
	payload: failureData,
});

export const fetchPreviousDayCashAccountDayBookReportResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
});

export const getPreviousDayCashAccountDayBookReportResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET PREVIOUS DAY CASH ACCOUNT DAY BOOK REPORT END *********************** */

/** ***************** GET CASH ACCOUNT DAY BOOK REPORT START *********************** */
export const fetchCashAccountDayBookReportStart = (getData) => ({
	type: EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_START,
	payload: getData,
});

export const getCashAccountDayBookReportSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS,
	payload: successData,
});

export const getCashAccountDayBookReportFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE,
	payload: failureData,
});

export const fetchCashAccountDayBookReportResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
});

export const getCashAccountDayBookReportResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET CASH ACCOUNT DAY BOOK REPORT END *********************** */

// /////// GET Details View Values ///////// 

/** ***************** GET PRECLOSE COLLECTION DETAILS START *********************** */
export const getPrecloseCollectionDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_START,
	payload: getData,
});

export const getPrecloseCollectionDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const getPrecloseCollectionDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const getPrecloseCollectionDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_RESET,
});

export const getPrecloseCollectionDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET PRECLOSE COLLECTION DETAILS END *********************** */

/** ***************** GET ADVANCE COLLECTION DETAILS START *********************** */
export const getAdvanceCollectionDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_START,
	payload: getData,
});

export const getAdvanceCollectionDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const getAdvanceCollectionDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const getAdvanceCollectionDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_RESET,
});

export const getAdvanceCollectionDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ADVANCE COLLECTION DETAILS END *********************** */


/** ***************** GET ARREAR COLLECTION DETAILS START *********************** */
export const getArrearCollectionDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_START,
	payload: getData,
});

export const getArrearCollectionDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const getArrearCollectionDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const getArrearCollectionDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_RESPONSE_RESET,
});

export const getArrearCollectionDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ARREAR COLLECTION DETAILS END *********************** */

// /////// End Detailed View Details //////// 

/** ***************** GET VOUCHER APPROVED DETAILS START *********************** */
export const getVoucherApprovedDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_START,
	payload: getData,
});

export const getVoucherApprovedDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_SUCCESS,
	payload: successData,
});

export const getVoucherApprovedDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_FAILURE,
	payload: failureData,
});

export const getVoucherApprovedDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_RESPONSE_RESET,
});

export const getVoucherApprovedDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET VOUCHER_APPROVED DETAILS END *********************** */

/** ***************** SUBMIT EOD DETAILS START *********************** */
export const getSubmitEODDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_START,
	payload: getData,
});

export const getSubmitEODDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_SUCCESS,
	payload: successData,
});

export const getSubmitEODDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_FAILURE,
	payload: failureData,
});

export const getSubmitEODDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_RESPONSE_RESET,
});

export const getSubmitEODDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET SUBMIT EOD DETAILS END *********************** */

/** ***************** REVERT EOD DETAILS START *********************** */
export const getRevertEODDetailsStart = (getData) => ({
	type: EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_START,
	payload: getData,
});

export const getRevertEODDetailsSuccess = (successData) => ({
	type: EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_SUCCESS,
	payload: successData,
});

export const getRevertEODDetailsFailure = (failureData) => ({
	type: EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_FAILURE,
	payload: failureData,
});

export const getRevertEODDetailsResponseResetStart = () => ({
	type: EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_RESPONSE_RESET,
});

export const getRevertEODDetailsResponseChanged = () => ({
	type: EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET REVERT EOD DETAILS END *********************** */