/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const branchDetailsAdd = (addData) => {
	const data = {
		AddBranchDetailsToken: addData,
	};
	const url = `${API_URL}addBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsUpdate = (updateData) => {
	const data = {
		UpdateBranchDetailsToken: updateData,
	};
	const url = `${API_URL}updateBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchListGet = (listData) => {
	const data = {
		GetBranchListToken: listData,
	};
	const url = `${API_URL}getBranchList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsFetch = (fetchData) => {
	const data = {
		FetchBranchToken: fetchData,
	};
	const url = `${API_URL}fetchBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsDelete = (deleteData) => {
	const data = {
		DeleteBranchDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const subBranchNextUuniqueIDFetch = (fetchData) => {
	const data = {
		FetchSubBranchToken: fetchData,
	};
	const url = `${API_URL}fetchSubBranchNextUniqueID`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};