const ArrearFollowupActionTypes = {

    GET_ARREAR_FOLLOWUP_LIST_START: 'get_arrear_followup_start',
	GET_ARREAR_FOLLOWUP_LIST_SUCCESS: 'get_arrear_followup_success',
	GET_ARREAR_FOLLOWUP_LIST_FAILURE: 'get_arrear_followup_failure',
	GET_ARREAR_FOLLOWUP_LIST_RESPONSE_RESET: 'get_arrear_followup_response_reset',
	GET_ARREAR_FOLLOWUP_LIST_RESPONSE_CHANGED: 'get_arrear_followup_response_changed',
	GET_ARREAR_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED: 'get_arrear_followup_response_array_changed',

}
export default ArrearFollowupActionTypes;