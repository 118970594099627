const ResidentialDetailsActionType = {
	RESIDENTIAL_DETAILS_ADD_START: 'residential_details_add_start',
	RESIDENTIAL_DETAILS_ADD_SUCCESS: 'residential_details_add_success',
	RESIDENTIAL_DETAILS_ADD_FAILURE: 'residential_details_add_failure',
	RESIDENTIAL_DETAILS_ADD_RESPONSE_REST_START: 'residential_details_add_response_reset_start',
	RESIDENTIAL_DETAILS_ADD_RESPONSE_CHANGED: 'residential_details_add_response_changed',

	FETCH_CITIES_LIST_START: 'fetch_cities_list_start',
	FETCH_CITIES_LIST_SUCCESS: 'fetch_cities_list_success',
	FETCH_CITIES_LIST_FAILURE: 'fetch_cities_list_failure',
	FETCH_CITIES_LIST_RESPONSE_REST_START: 'fetch_cities_list_response_reset_start',
	FETCH_CITIES_LIST_RESPONSE_CHANGED: 'fetch_cities_list_response_changed',

	FETCH_STATE_LIST_START: 'fetch_state_list_start',
	FETCH_STATE_LIST_SUCCESS: 'fetch_state_list_success',
	FETCH_STATE_LIST_FAILURE: 'fetch_state_list_failure',
	FETCH_STATE_LIST_RESPONSE_REST_START: 'fetch_state_list_response_reset_start',
	FETCH_STATE_LIST_RESPONSE_CHANGED: 'fetch_state_list_response_changed',

	UPDATE_SELECTED_ADDRESS_DATA: 'update_selected_address_data',
};

export default ResidentialDetailsActionType;
