// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-undef */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
import React from 'react';
// import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import JWT from 'expo-jwt';
import USERS from '../../common/data/userDummyData';
import { profileMenu } from '../../menu';
// import Dropdown, {
// 	DropdownItem,
// 	DropdownMenu,
// 	DropdownToggle,
// } from '../../components/bootstrap/Dropdown';
// import Button from '../../components/bootstrap/Button';
// import useDarkMode from '../../hooks/useDarkMode';
import { selectCurrentDecodedUser } from '../../redux/login/login.selector';
// import generalTypesAction from '../../redux/genera.types';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img
				srcSet={`https://api.infomicrofin.com/${srcSet}`}
				src={`https://api.infomicrofin.com/${src}`}
				alt='Avatar'
				width={128}
				height={128}
			/>
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const navigate = useNavigate();
	// const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const loginResponseStatus = useSelector(selectCurrentDecodedUser);

	// const onClickProfile = () => {
	// 	if (loginResponseStatus && loginResponseStatus?.data[0]?.databaseName === 'SMCF') {
	// 		// navigate(`${profileMenu.profile.path}`);
	// 	} else {
	// 		navigate(`${profileMenu.profile.path}`);
	// 	}
	// };

	return (
		// <Dropdown>
		// 	<DropdownToggle hasIcon={false}>
		// 		<div className='user'>
		// 			<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
		// 			<div className='user-info'>
		// 				<div className='user-name'>{`${USERS.JOHN.name} ${USERS.JOHN.surname}`}</div>
		// 				<div className='user-sub-title'>{USERS.JOHN.position}</div>
		// 			</div>
		// 		</div>
		// 	</DropdownToggle>
		// 	<DropdownMenu>
		// 		<DropdownItem>
		// 			<Button
		// 				icon='AccountBox'
		// 				onClick={() =>
		// 					navigate(
		// 						`../${demoPages.appointment.subMenu.employeeID.path}/${USERS.JOHN.id}`,
		// 					)
		// 				}>
		// 				Profile
		// 			</Button>
		// 		</DropdownItem>
		// 		<DropdownItem>
		// 			<Button
		// 				icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
		// 				onClick={() => setDarkModeStatus(!darkModeStatus)}
		// 				aria-label='Toggle fullscreen'>
		// 				{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
		// 			</Button>
		// 		</DropdownItem>
		// 		<DropdownItem isDivider />
		// 		<DropdownItem>
		// 			<Button icon='Logout' onClick={() => navigate(`../${demoPages.login.path}`)}>
		// 				Logout
		// 			</Button>
		// 		</DropdownItem>
		// 	</DropdownMenu>
		// </Dropdown>

		<div
			role='presentation'
			className='user'
			onClick={() => navigate(`${profileMenu.profile.path}`)}>
			{loginResponseStatus !== null &&
			loginResponseStatus !== undefined &&
			loginResponseStatus.data.length > 0 ? (
				<>
					{loginResponseStatus.data[0].UserPhoto &&
					loginResponseStatus.data[0].UserPhoto !== null ? (
						<UserAvatar
							srcSet={`${loginResponseStatus.data[0].UserPhoto}`}
							src={`${loginResponseStatus.data[0].UserPhoto}`}
						/>
					) : (
						<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					)}

					<div className='user-info'>
						<div className='user-name'>{`${loginResponseStatus.data[0].UserName}`}</div>
						<div className='user-sub-title'>
							{loginResponseStatus.data[0].UserPhoneNumber}
						</div>
					</div>
				</>
			) : (
				<>
					<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					<div className='user-info'>
						<div className='user-name'>{`${USERS.JOHN.name} ${USERS.JOHN.surname}`}</div>
						<div className='user-sub-title'>{USERS.JOHN.position}</div>
					</div>
				</>
			)}
		</div>
	);
};

export default User;
