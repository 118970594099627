import PancardDetailsActionType from './pancard-details.types';

// update Pancard
export const updatePancardStart = (pancardCredentials) => ({
	type: PancardDetailsActionType.UPDATE_PANCARD_START,
	payload: pancardCredentials,
});

export const updatePancardSuccess = (pancardSuccess) => ({
	type: PancardDetailsActionType.UPDATE_PANCARD_SUCCESS,
	payload: pancardSuccess,
});

export const updatePancardFailure = (pancardFailure) => ({
	type: PancardDetailsActionType.UPDATE_PANCARD_FAILURE,
	payload: pancardFailure,
});

export const updatePancardResponseResetStart = () => ({
	type: PancardDetailsActionType.UPDATE_PANCARD_RESPONSE_REST_START,
});

export const updatePancardResponseChanged = () => ({
	type: PancardDetailsActionType.UPDATE_PANCARD_RESPONSE_CHANGED,
});
