/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const familyMemberIncomeAdd = (verifyData) => {
	const data = {
		CreateFamilyIncomeToken: verifyData,
	};
	const url = `${API_URL}updateFamilyMemberIncome`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const familyMemberFetch = (verifyData) => {
	const data = {
		FetchMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}fetchFamilyIncomeAndDetails`;

	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const familyMemberAddDetails = (verifyData) => {
	const data = {
		CreateFamilyToken: verifyData,
	};
	const url = `${API_URL}addFamilyMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const familyMemberFetchParticular = (verifyData) => {
	const data = {
		FetchMemberDetailsToken: verifyData,
	};
	const url = `${API_URL}fetchParticularFamilyMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const DeleteMemberFamilyAPI = (verifyData) => {
	const data = {
		FamilyMemberDeleteToken: verifyData,
	};
	const url = `${API_URL}deleteParticularFamilyMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyFamilyAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyValidateFamilyAadharOtpAPI = (credentials, serviceName) => {
	const data = {
		AadharValidateOtpVerificationToken: credentials,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
