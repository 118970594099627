/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ProductActionTypes from './product.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addProductDetailsResponse: null,
	updateProductDetailsResponse: null,
	getProductListResponse: null,
	getProductListArr: [],
	fetchCustomProductDetailsResponse: null,
	fetchCustomProductDetailsArr: [],
	fetchProductDetailsResponse: null,
	fetchProductDetailsArr: [],
	deleteProductDetailsResponse: null,
	getAccountMasterListResponse: null,
	getAccountMasterListArr: [],
	getAllInstrumnetListResponse: null,
	getAllInstrumnetListArr: [],
	getFilterProductListResponse: null,
	getFilterProductListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProductActionTypes.ADD_PRODUCT_DETAILS_SUCCESS:
			return { ...state, addProductDetailsResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_DETAILS_FAILURE:
			return { ...state, addProductDetailsResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addProductDetailsResponse: null };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_FAILURE:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateProductDetailsResponse: null };

		case ProductActionTypes.GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getProductListResponse: action.payload,
				getProductListArr: docodeResponse(action.payload, 'GetProductListKey'),
			};

		case ProductActionTypes.GET_PRODUCT_LIST_FAILURE:
			return { ...state, getProductListResponse: action.payload, getProductListArr: [] };

		case ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getProductListResponse: null };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductDetailsResponse: action.payload,
				fetchProductDetailsArr: docodeResponse(action.payload, 'FetchProductDetailsKey'),
			};

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
			return {
				...state,
				fetchProductDetailsResponse: action.payload,
				fetchProductDetailsArr: [],
			};

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchProductDetailsResponse: null };

		case ProductActionTypes.FETCH_CUSTOM_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				fetchCustomProductDetailsResponse: action.payload,
				fetchCustomProductDetailsArr: docodeResponse(
					action.payload,
					'GetCustomProductListKey',
				),
			};

		case ProductActionTypes.FETCH_CUSTOM_PRODUCT_DETAILS_FAILURE:
			return {
				...state,
				fetchCustomProductDetailsResponse: action.payload,
				fetchCustomProductDetailsArr: [],
			};

		case ProductActionTypes.FETCH_CUSTOM_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchCustomProductDetailsResponse: null };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_FAILURE:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteProductDetailsResponse: null };

		case ProductActionTypes.GET_MASTER_ACCOUNT_LIST_SUCCESS:
			return {
				...state,
				getAccountMasterListResponse: action.payload,
				getAccountMasterListArr: docodeResponse(action.payload, 'GetMasterAccountListKey'),
			};

		case ProductActionTypes.GET_MASTER_ACCOUNT_LIST_FAILURE:
			return {
				...state,
				getAccountMasterListResponse: action.payload,
				getAccountMasterListArr: [],
			};

		case ProductActionTypes.GET_MASTER_ACCOUNT_LIST_RESPONSE_CHANGED:
			return { ...state, getAccountMasterListResponse: null };

		case ProductActionTypes.GET_ALL_INSTRUMENT_LIST_SUCCESS:
			return {
				...state,
				getAllInstrumnetListResponse: action.payload,
				getAllInstrumnetListArr: docodeResponse(action.payload, 'GetAllInstrumentListKey'),
			};

		case ProductActionTypes.GET_ALL_INSTRUMENT_LIST_FAILURE:
			return {
				...state,
				getAllInstrumnetListResponse: action.payload,
				getAllInstrumnetListArr: [],
			};

		case ProductActionTypes.GET_ALL_INSTRUMENT_LIST_RESPONSE_CHANGED:
			return { ...state, getAllInstrumnetListResponse: null };

		case ProductActionTypes.FETCH_FILTER_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getFilterProductListResponse: action.payload,
				getFilterProductListArr: docodeResponse(action.payload, 'GetFilterProductListKey'),
			};

		case ProductActionTypes.FETCH_FILTER_PRODUCT_LIST_FAILURE:
			return {
				...state,
				getFilterProductListResponse: action.payload,
				getProductListArr: [],
			};

		case ProductActionTypes.FETCH_FILTER_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getFilterProductListResponse: null };

		default:
			return state;
	}
};
