/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
/** *** Collection Approval Page All the Reducer declared over here **** */
/** *** Version : 2.0.1 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Common Collection Update Reducer added **** */
/** *** Version : 2.0.2 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Check Branch EOD Reducer added **** */

import CollectionActionTypes from './collection.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getOfficerListCentersResponse: null,
	getOfficerListCentersArr: [],
	getOfficerListArrearCentersResponse: null,
	getOfficerListArrearCentersArr: [],
	getBranchCollectionUsersListResponse: null,
	getBranchCollectionUsersListArr: [],
	getCenterListOfficerCollectionsResponse: null,
	getCenterListOfficerCollectionsArr: [],
	getBranchTodayCollectionDetailsStatus: null,
	getBranchTodayCollectionDetailsArr: [],
	getBranchTodayDemandMoveToArrearStatus: null,
	getBranchTodayDemandMoveToArrearArr: [],
	getBranchTodayClosedLoanListStatus: null,
	getBranchTodayClosedLoanListArr: [],
	getCenterCollectionDenominationDetailsStatus: null,
	getCenterCollectionDenominationDetailsArr: [],
	getAttendanceOfficerCollectionStatus: null,
	getAttendanceOfficerCollectionArr: [],
	getBranchCollectionFunderAllocateEODStatus: null,
	getBranchCollectionFunderAllocateEODArr: [],
	getBranchTodayCollectionYetToApproveStatus: null,
	getBranchTodayCollectionYetToApproveArr: [],
	updateLoanPaidPerMemberCollectionResponse: null,
	updateLoanPaidOverallCollectionResponse: null,
	commonCollectionUpdateResponse: null,
	updateLoanPaidOverAllMoveToArrearResponse: null,
	updateOverallAccruedInterestAllocateForEODResponse: null,
	updateOverallAllocateForEODResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_SUCCESS:
			return {
				...state,
				getOfficerListCentersResponse: action.payload,
				getOfficerListCentersArr: docodeResponse(action.payload, 'centerListForOfficerKey'),
			};

		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_FAILURE:
			return { ...state, getOfficerListCentersResponse: action.payload };

		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED:
			return { ...state, getOfficerListCentersResponse: null };

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS:
			return {
				...state,
				getOfficerListArrearCentersResponse: action.payload,
				getOfficerListArrearCentersArr: docodeResponse(
					action.payload,
					'arrearCenterListForOfficerKey',
				),
			};

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE:
			return { ...state, getOfficerListArrearCentersResponse: action.payload };

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED:
			return { ...state, getOfficerListArrearCentersResponse: null };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS:
			return {
				...state,
				getBranchCollectionUsersListResponse: action.payload,
				getBranchCollectionUsersListArr: docodeResponse(
					action.payload,
					'branchCollectionUsersListKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_FAILURE:
			return { ...state, getBranchCollectionUsersListResponse: action.payload };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchCollectionUsersListResponse: null };

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS:
			return {
				...state,
				getCenterListOfficerCollectionsResponse: action.payload,
				getCenterListOfficerCollectionsArr: docodeResponse(
					action.payload,
					'centerListOfficerCollectionsKey',
				),
			};

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE:
			return { ...state, getCenterListOfficerCollectionsResponse: action.payload };

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED:
			return { ...state, getCenterListOfficerCollectionsResponse: null };

		// BRANCH TODAY COLLECTION SECTION
		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				getBranchTodayCollectionDetailsStatus: 'SUCCESS',
				getBranchTodayCollectionDetailsArr: docodeResponse(
					action.payload,
					'FetchBranchTodayCollectionDetailsKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_FAILURE:
			return { ...state, getBranchTodayCollectionDetailsStatus: 'FAILURE' };

		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_CHANGED:
			return { ...state, getBranchTodayCollectionDetailsStatus: null };

		// BRANCH TODAY DEMAND MOVE TO ARREAR SECTION
		case CollectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_SUCCESS:
			return {
				...state,
				getBranchTodayDemandMoveToArrearStatus: action.payload,
				getBranchTodayDemandMoveToArrearArr: docodeResponse(
					action.payload,
					'FetchBranchTodayDemandMoveToArrearListKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_FAILURE:
			return { ...state, getBranchTodayDemandMoveToArrearStatus: 'FAILURE' };

		case CollectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_CHANGED:
			return { ...state, getBranchTodayDemandMoveToArrearStatus: null };

		// BRANCH TODAY CLOSED LOAN LIST FETCH
		case CollectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_SUCCESS:
			return {
				...state,
				getBranchTodayClosedLoanListStatus: action.payload,
				getBranchTodayClosedLoanListArr: docodeResponse(
					action.payload,
					'CloseLoanListFetchKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_FAILURE:
			return { ...state, getBranchTodayClosedLoanListStatus: 'FAILURE' };

		case CollectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_CHANGED:
			return { ...state, getBranchTodayClosedLoanListStatus: null };

		// CENTER DENOMINATION COLLECTION SECTION
		case CollectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_SUCCESS:
			return {
				...state,
				getCenterCollectionDenominationDetailsStatus: 'SUCCESS',
				getCenterCollectionDenominationDetailsArr: docodeResponse(
					action.payload,
					'FetchCenterCollectionDenominationKey',
				),
			};

		case CollectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_FAILURE:
			return { ...state, getCenterCollectionDenominationDetailsStatus: 'FAILURE' };

		case CollectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_CHANGED:
			return { ...state, getCenterCollectionDenominationDetailsStatus: null };
		// ATTENDANCE OFFICER COLLECTION SECTION
		case CollectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_SUCCESS:
			return {
				...state,
				getAttendanceOfficerCollectionStatus: 'SUCCESS',
				getAttendanceOfficerCollectionArr: docodeResponse(
					action.payload,
					'FetchAttendanceBasedOfficerCollectionKey',
				),
			};

		case CollectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_FAILURE:
			return { ...state, getAttendanceOfficerCollectionStatus: 'FAILURE' };

		case CollectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_CHANGED:
			return { ...state, getAttendanceOfficerCollectionStatus: null };
		// BRANCH COLLECTION FUNDER ALLOCATE EOD SECTION
		case CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_SUCCESS:
			return {
				...state,
				getBranchCollectionFunderAllocateEODStatus: 'SUCCESS',
				getBranchCollectionFunderAllocateEODArr: docodeResponse(
					action.payload,
					'BranchCollectionsAllocateForEodKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_FAILURE:
			return { ...state, getBranchCollectionFunderAllocateEODStatus: 'FAILURE' };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED:
			return { ...state, getBranchCollectionFunderAllocateEODStatus: null };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED_DATA:
			return { ...state, getBranchCollectionFunderAllocateEODArr: [] };

		// BRANCH TODAY COLLECTION SECTION
		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_SUCCESS:
			return {
				...state,
				getBranchTodayCollectionYetToApproveStatus: 'SUCCESS',
				getBranchTodayCollectionYetToApproveArr: docodeResponse(
					action.payload,
					'BranchTodayCollectionMembersYetToApproveKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_FAILURE:
			return { ...state, getBranchTodayCollectionYetToApproveStatus: 'FAILURE' };

		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED:
			return { ...state, getBranchTodayCollectionYetToApproveStatus: null };

		case CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED_DATA:
			return {
				...state,
				getBranchTodayCollectionYetToApproveArr: [],
			};

		case CollectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_SUCCESS:
			return {
				...state,
				updateLoanPaidPerMemberCollectionResponse: action.payload,
			};

		case CollectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_FAILURE:
			return { ...state, updateLoanPaidPerMemberCollectionResponse: action.payload };

		case CollectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_CHANGED:
			return { ...state, updateLoanPaidPerMemberCollectionResponse: null };

		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_SUCCESS:
			return {
				...state,
				updateLoanPaidOverallCollectionResponse: action.payload,
			};

		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_FAILURE:
			return { ...state, updateLoanPaidOverallCollectionResponse: action.payload };

		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_CHANGED:
			return { ...state, updateLoanPaidOverallCollectionResponse: null };

		case CollectionActionTypes.COMMON_COLLECTION_UPDATE_SUCCESS:
			return {
				...state,
				commonCollectionUpdateResponse: action.payload,
			};

		case CollectionActionTypes.COMMON_COLLECTION_UPDATE_FAILURE:
			return { ...state, commonCollectionUpdateResponse: action.payload };

		case CollectionActionTypes.COMMON_COLLECTION_UPDATE_CHANGED:
			return { ...state, commonCollectionUpdateResponse: null };
		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_SUCCESS:
			return {
				...state,
				updateLoanPaidOverAllMoveToArrearResponse: action.payload,
			};

		// BRANCH TODAY COLLECTION SECTION
		case CollectionActionTypes.CHECK_BRANCH_EOD_EXIST_SUCCESS:
			return {
				...state,
				checkBranchEODExistResponse: action.payload,
				checkBranchEODExistArr: docodeResponse(action.payload, 'checkBranchEODExistKey'),
			};

		case CollectionActionTypes.CHECK_BRANCH_EOD_EXIST_FAILURE:
			return { ...state, checkBranchEODExistResponse: action.payload };

		case CollectionActionTypes.CHECK_BRANCH_EOD_EXIST_CHANGED:
			return { ...state, checkBranchEODExistResponse: null };

		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_FAILURE:
			return { ...state, updateLoanPaidOverAllMoveToArrearResponse: action.payload };

		case CollectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_CHANGED:
			return { ...state, updateLoanPaidOverAllMoveToArrearResponse: null };

		case CollectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_SUCCESS:
			return {
				...state,
				updateOverallAccruedInterestAllocateForEODResponse: action.payload,
			};

		case CollectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_FAILURE:
			return { ...state, updateOverallAccruedInterestAllocateForEODResponse: action.payload };

		case CollectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_CHANGED:
			return { ...state, updateOverallAccruedInterestAllocateForEODResponse: null };

		case CollectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_SUCCESS:
			return {
				...state,
				updateOverallAllocateForEODResponse: action.payload,
			};

		case CollectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_FAILURE:
			return { ...state, updateOverallAllocateForEODResponse: action.payload };

		case CollectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_CHANGED:
			return { ...state, updateOverallAllocateForEODResponse: null };

		default:
			return state;
	}
};
