/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import QrReportActionTypes from './qr-report.types';

import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchQrReportResponse: null,
	fetchQrReportArr: [],
	fetchParticularQrReportResponse: null,
	fetchParticularQrReportArr: [],
	fetchQrReasoncountArr: [],
	exportQrReportResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case QrReportActionTypes.FETCH_QR_REPORT_SUCCESS:
			return {
				...state,
				fetchQrReportResponse: action.payload,
				fetchQrReportArr: docodeResponse(action.payload, 'QRPaymentReportKey'),
			};

		case QrReportActionTypes.FETCH_QR_REPORT_FAILURE:
			return { ...state, fetchQrReportResponse: action.payload };

		case QrReportActionTypes.FETCH_QR_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				fetchQrReportResponse: null,
			};

		case QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_SUCCESS:
			return {
				...state,
				fetchParticularQrReportResponse: action.payload,
				fetchParticularQrReportArr: docodeResponse(action.payload, 'ParticularQRPaymentReportKey'),
			};

		case QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_FAILURE:
			return { ...state, fetchParticularQrReportResponse: action.payload };

		case QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				fetchParticularQrReportResponse: null,
			};

		case QrReportActionTypes.EXPORT_QR_REPORT_SUCCESS:
			return {
				...state,
				exportQrReportResponse: action.payload,
			};

		case QrReportActionTypes.EXPORT_QR_REPORT_FAILURE:
			return { ...state, exportQrReportResponse: action.payload };

		case QrReportActionTypes.EXPORT_QR_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				exportQrReportResponse: null,
			};

		default:
			return state;
	}
};
