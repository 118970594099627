/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const passbookChequeUpdateDetails = (updatePassbookChequeData) => {
	const data = {
		PassbookChequeDetailsToken: updatePassbookChequeData,
	};
	const url = `${API_URL}updatePassbookChequeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
