/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ReconciliationForQRTypes from './reconciliationForQR.types';

import {
	ReconciliationForQRListGet,
	ReconciliationForQRListUpdate,
} from './reconciliationForQR.service';

import {
	getReconciliationForQRSuccess,
	getReconciliationForQRFailure,
	getReconciliationForQRChanged,
	updateReconciliationForQRSuccess,
	updateReconciliationForQRFailure,
	updateReconciliationForQRChanged,
} from './reconciliationForQR.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET QR RECONCILITATION DETAILS  ******************** */
export function* getReconciliationForQRList({ payload }) {
	try {
		const key = 'fetchQRPaymentForReconciliationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ReconciliationForQRListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getReconciliationForQRFailure(responseData.data));
		} else {
			yield put(getReconciliationForQRSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getReconciliationForQRFailure(error));
	}
}

export function* resetGetReconciliationForQRResponse() {
	yield put(getReconciliationForQRChanged());
}

export function* onGetReconciliationForQR() {
	yield takeLatest(
		ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_START,
		getReconciliationForQRList,
	);
}

export function* onGetReconciliationForQRResponseReset() {
	yield takeLatest(
		ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_RESET,
		resetGetReconciliationForQRResponse,
	);
}
/** *********************  END GET ReconciliationForQR MEMBER REPORT LIST ******************** */

/** *********************  START UPDATE QR RECONCILITATION DETAILS  ******************** */
export function* updateReconciliationForQRList({ payload }) {
	try {
		const key = 'updateQRPaymentForReconciliationKey';
		const toAccountArr = JSON.stringify(payload.toAccountArr).replace(/"/g, "'", );
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserName: payload.userName,
			FromAccount: payload.fromAccount,
			toAccountArr,
			paidDate: payload.paidDate,
			callbackLogID: payload.callbackLogID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ReconciliationForQRListUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateReconciliationForQRFailure(responseData.data));
		} else {
			yield put(updateReconciliationForQRSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateReconciliationForQRFailure(error));
	}
}

export function* resetUpdateReconciliationForQRResponse() {
	yield put(updateReconciliationForQRChanged());
}

export function* onUpdateReconciliationForQR() {
	yield takeLatest(
		ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_START,
		updateReconciliationForQRList,
	);
}

export function* onUpdateReconciliationForQRResponseReset() {
	yield takeLatest(
		ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_RESET,
		resetUpdateReconciliationForQRResponse,
	);
}
/** *********************  END UPDATE QR RECONCILITATION LIST ******************** */

export function* ReconciliationForQRMemberSaga() {
	yield all([
		call(onGetReconciliationForQR),
		call(onGetReconciliationForQRResponseReset),
		call(onUpdateReconciliationForQR),
		call(onUpdateReconciliationForQRResponseReset),
	]);
}
