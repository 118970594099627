/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import questionnaireTypes from './questionnaire.types';

import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addQuestionnaireResponse: null,
	getQuestionnaireResponse: null,
	getQuestionnaireListArr: [],
	deleteQuestionnaireResponse: null,
	deleteQuestionResponse: null,
	deleteQuestionChoiceResponse: null,
	fetchQuestionnaireArr: [],
	fetchQuestionnaireResponse: null,
	updateQuestionnaireStatus: null,
	updateQuestionnaireResponse: null,
	fetchModuleListStatus: null,
	fetchModuleListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case questionnaireTypes.ADD_QUESTIONNAIRE_SUCCESS:
			return { ...state, addQuestionnaireResponse: action.payload };

		case questionnaireTypes.ADD_QUESTIONNAIRE_FAILURE:
			return { ...state, addQuestionnaireResponse: action.payload };

		case questionnaireTypes.ADD_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, addQuestionnaireResponse: null };

		case questionnaireTypes.GET_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				getQuestionnaireResponse: action.payload,
				getQuestionnaireListArr: docodeResponse(
					action.payload,
					'GetAllQuestionnaireListkey',
				),
			};
		case questionnaireTypes.GET_QUESTIONNAIRE_FAILURE:
			return {
				...state,
				getQuestionnaireResponse: action.payload,
				getQuestionnaireListArr: [],
			};

		case questionnaireTypes.GET_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, getQuestionnaireResponse: null };

		case questionnaireTypes.FETCH_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				fetchQuestionnaireResponse: action.payload,
				fetchQuestionnaireArr: docodeResponse(action.payload, 'FetchQuestionnaireListkey'),
			};

		case questionnaireTypes.FETCH_QUESTIONNAIRE_FAILURE:
			return {
				...state,
				fetchQuestionnaireResponse: action.payload,
				fetchQuestionnaireArr: [],
			};

		case questionnaireTypes.FETCH_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, fetchQuestionnaireResponse: null };

		case questionnaireTypes.DELETE_QUESTIONNAIRE_SUCCESS:
			return { ...state, deleteQuestionnaireResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTIONNAIRE_FAILURE:
			return { ...state, deleteQuestionnaireResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, deleteQuestionnaireResponse: null };

		case questionnaireTypes.DELETE_QUESTION_SUCCESS:
			return { ...state, deleteQuestionResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTION_FAILURE:
			return { ...state, deleteQuestionResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTION_RESPONSE_CHANGED:
			return { ...state, deleteQuestionResponse: null };

		case questionnaireTypes.DELETE_QUESTION_CHOICE_SUCCESS:
			return { ...state, deleteQuestionChoiceResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTION_CHOICE_FAILURE:
			return { ...state, deleteQuestionChoiceResponse: action.payload };

		case questionnaireTypes.DELETE_QUESTION_CHOICE_RESPONSE_CHANGED:
			return { ...state, deleteQuestionChoiceResponse: null };

		case questionnaireTypes.UPDATE_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				updateQuestionnaireStatus: 'Success',
				updateQuestionnaireResponse: action.payload,
			};

		case questionnaireTypes.UPDATE_QUESTIONNAIRE_FAILURE:
			return { ...state, updateQuestionnaireStatus: 'Failure' };

		case questionnaireTypes.UPDATE_QUESTIONNAIRE_RESPONSE_CHANGED:
			return { ...state, updateQuestionnaireStatus: null, updateQuestionnaireResponse: null };

		case questionnaireTypes.FETCH_MODULE_LIST_SUCCESS:
			return {
				...state,
				fetchModuleListStatus: 'Success',
				fetchModuleListArr: docodeResponse(action.payload, 'ModuleListFetchKey'),
			};

		case questionnaireTypes.FETCH_MODULE_LIST_FAILURE:
			return { ...state, fetchModuleListStatus: 'Failure' };

		case questionnaireTypes.FETCH_MODULE_LIST_CHANGED:
			return { ...state, fetchModuleListStatus: null };

		default:
			return state;
	}
};
