/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { docodeResponse } from '../../../common/common.utils';
import OfficialDetailsActionType from './official-details.types';

const INITIAL_STATE = {
	officialDetailsResponse: null,
	economicActivityListResponse: null,
	economicActivityListData: [],
	centerListResponse: null,
	centerListData: [],
	productTypeListResp: null,
	productTypeListData: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_SUCCESS:
			return { ...state, officialDetailsResponse: action.payload };

		case OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_FAILURE:
			return { ...state, officialDetailsResponse: action.payload };

		case OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_RESPONSE_CHANGED:
			return { ...state, officialDetailsResponse: null };

		case OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_SUCCESS:
			return {
				...state,
				economicActivityListResponse: action.payload,
				economicActivityListData: docodeResponse(
					action.payload,
					'FetchAllEconomicActivityListKey',
				),
			};

		case OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_FAILURE:
			return { ...state, economicActivityListResponse: action.payload };

		case OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED:
			return { ...state, economicActivityListResponse: null };

		case OfficialDetailsActionType.CENTER_LIST_SUCCESS:
			return {
				...state,
				centerListResponse: action.payload,
				centerListData: docodeResponse(action.payload, 'CenterListStaffKey'),
			};

		case OfficialDetailsActionType.CENTER_LIST_FAILURE:
			return { ...state, centerListResponse: action.payload };

		case OfficialDetailsActionType.CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, centerListResponse: null };

		case OfficialDetailsActionType.PRODUCT_TYPE_LIST_SUCCESS:
			return {
				...state,
				productTypeListResp: action.payload,
				productTypeListData: docodeResponse(action.payload, 'FetchProductTypeDataKey'),
			};

		case OfficialDetailsActionType.PRODUCT_TYPE_LIST_FAILURE:
			return { ...state, productTypeListResp: action.payload };

		case OfficialDetailsActionType.PRODUCT_TYPE_LIST_RESPONSE_CHANGED:
			return { ...state, productTypeListResp: null };

		default:
			return state;
	}
};
