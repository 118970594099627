/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable default-param-last */

import LeadReportActionTypes from './leadReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getLeadListResponse: null,
	getLeadListArr: [],
	getOfficerListResponse: null,
	getOfficerListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LeadReportActionTypes.FETCH_LEAD_LIST_SUCCESS:
			return {
				...state,
				getLeadListArr: docodeResponse(action.payload, 'GetLeadDetailsListKey'),
				getLeadListResponse: 'Success',
			};

		case LeadReportActionTypes.FETCH_LEAD_LIST_FAILURE:
			return { ...state, getLeadListResponse: action.payload };

		case LeadReportActionTypes.FETCH_LEAD_LIST_CHANGED:
			return { ...state, getLeadListResponse: null };

		case LeadReportActionTypes.FETCH_OFFICER_LIST_SUCCESS:
			return {
				...state,
				getOfficerListArr: docodeResponse(action.payload, 'GetOfficerDetailsListKey'),
				getOfficerListResponse: 'Success',
			};

		case LeadReportActionTypes.FETCH_OFFICER_LIST_FAILURE:
			return { ...state, getOfficerListResponse: action.payload };

		case LeadReportActionTypes.FETCH_OFFICER_LIST_CHANGED:
			return { ...state, getOfficerListResponse: null };

		default:
			return state;
	}
};
