// eslint-disable-next-line import/no-unresolved
import NocPhotosActionType from './noc-photos.types';

// update Noc Photos
export const updateNocPhotosStart = (housePhotosCredentials) => ({
	type: NocPhotosActionType.UPDATE_NOC_PHOTOS_START,
	payload: housePhotosCredentials,
});

export const updateNocPhotosSuccess = (housePhotosSuccess) => ({
	type: NocPhotosActionType.UPDATE_NOC_PHOTOS_SUCCESS,
	payload: housePhotosSuccess,
});

export const updateNocPhotosFailure = (housePhotosFailure) => ({
	type: NocPhotosActionType.UPDATE_NOC_PHOTOS_FAILURE,
	payload: housePhotosFailure,
});

export const updateNocPhotosResponseResetStart = () => ({
	type: NocPhotosActionType.UPDATE_NOC_PHOTOS_RESPONSE_REST_START,
});

export const updateNocPhotosResponseChanged = () => ({
	type: NocPhotosActionType.UPDATE_NOC_PHOTOS_RESPONSE_CHANGED,
});
