/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import VerifyOtpActionType from './verify-otp.types';

const INITIAL_STATE = {
	verifyMemberOtpResponse: null,
	resenOtpResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_SUCCESS:
			return { ...state, verifyMemberOtpResponse: action.payload };

		case VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_FAILURE:
			return { ...state, verifyMemberOtpResponse: action.payload };

		case VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_RESPONSE_CHANGED:
			return { ...state, verifyMemberOtpResponse: null, verifyOtpStatus: null };

		case VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_SUCCESS:
			return { ...state, resenOtpResponse: action.payload };

		case VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_FAILURE:
			return { ...state, resenOtpResponse: action.payload };

		case VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_CHANGED:
			return { ...state, resenOtpResponse: null };

		default:
			return state;
	}
};
