/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import CenterActionTypes from './center.types';

/** ***************** ADD CENTER DETAILS START *********************** */
export const addCenterDetailsStart = (addData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_START,
	payload: addData,
});

export const addCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const addCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const addCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_RESET,
});

export const addCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD CENTER DETAILS END *********************** */

/** ***************** UPDATE CENTER DETAILS START *********************** */
export const updateCenterDetailsStart = (updateData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_START,
	payload: updateData,
});

export const updateCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_RESET,
});

export const updateCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE CENTER DETAILS END *********************** */

/** ***************** GET CENTER DETAILS START *********************** */
export const getCenterDetailsStart = (getData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_START,
	payload: getData,
});

export const getCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_SUCCESS,
	payload: successData,
});

export const getCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_FAILURE,
	payload: failureData,
});

export const getCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.GET_CENTER_LIST_RESPONSE_RESET,
});

export const getCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.GET_CENTER_LIST_RESPONSE_CHANGED,
});

/** ***************** GET CENTER DETAILS END *********************** */

/** ***************** FETCH CENTER DETAILS START *********************** */
export const fetchCenterDetailsStart = (fetchData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_START,
	payload: fetchData,
});

export const fetchCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_RESET,
});

export const fetchCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH CENTER DETAILS END *********************** */

/** ***************** DELETE CENTER DETAILS START *********************** */
export const deleteCenterDetailsStart = (deleteData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_START,
	payload: deleteData,
});

export const deleteCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_RESET,
});

export const deleteCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE CENTER DETAILS END *********************** */

/** ***************** FETCH CENTER UNIQUE ID START *********************** */
export const fetchCenterUniqueIDStart = (fetchCenters) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_START,
	payload: fetchCenters,
});

export const fetchCenterUniqueIDSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_SUCCESS,
	payload: successData,
});

export const fetchCenterUniqueIDFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_FAILURE,
	payload: failureData,
});

export const fetchCenterUniqueIDResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET,
});

export const fetchCenterUniqueIDResponseChanged = () => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED,
});
/** ***************** FETCH CENTER UNIQUE ID END *********************** */
/** ***************** EXPORT_ALL_CENTER DETAILS REPORT EXCEL DETAILS START *********************** */
export const getAllCenterDetailsExportStart = (getData) => ({
	type: CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_START,
	payload: getData,
});
export const getAllCenterDetailsExportSuccess = (successData) => ({
	type: CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_SUCCESS,
	payload: successData,
});
export const getAllCenterDetailsExportFailure = (failureData) => ({
	type: CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_FAILURE,
	payload: failureData,
});
export const getAllCenterDetailsExportResponseResetStart = () => ({
	type: CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_RESPONSE_RESET,
});
export const getAllCenterDetailsExportResponseChanged = () => ({
	type: CenterActionTypes.GET_ALL_CENTER_LIST_EXPORT_RESPONSE_CHANGED,
});

/** ***************** EXPORT_ALL_CENTER DETAILS REPORT EXCEL DETAILS END *********************** */