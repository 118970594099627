/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import PhotoVerificationActionType from './photo-verification.types';

const INITIAL_STATE = {
	addPhotoResponse: null,
	imageProcessResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PhotoVerificationActionType.ADD_MEMBER_PHOTO_SUCCESS:
			return { ...state, addPhotoResponse: action.payload };

		case PhotoVerificationActionType.ADD_MEMBER_PHOTO_FAILURE:
			return { ...state, addPhotoResponse: action.payload };

		case PhotoVerificationActionType.ADD_MEMBER_PHOTO_RESPONSE_CHANGED:
			return { ...state, addPhotoResponse: null };

		case PhotoVerificationActionType.IMAGE_PROCESS_SUCCESS:
			return { ...state, imageProcessResponse: action.payload };

		case PhotoVerificationActionType.IMAGE_PROCESS_FAILURE:
			return { ...state, imageProcessResponse: action.payload };

		case PhotoVerificationActionType.IMAGE_PROCESS_RESPONSE_CHANGED:
			return { ...state, imageProcessResponse: null };

		default:
			return state;
	}
};
