const RangeLoanProposalActionTypes = {
	GET_RANGE_LOAN_PROPOSAL_LIST_START: 'get_range_loan_proposal_list_start',
	GET_RANGE_LOAN_PROPOSAL_LIST_SUCCESS: 'get_range_loan_proposal_list_success',
	GET_RANGE_LOAN_PROPOSAL_LIST_FAILURE: 'get_range_loan_proposal_list_failure',
	GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_RESET: 'get_range_loan_proposal_list_response_reset',
	GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED: 'get_range_loan_proposal_list_response_changed',

	GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_START: 'get_range_loan_proposal_center_list_start',
	GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_SUCCESS: 'get_range_loan_proposal_center_list_success',
	GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_FAILURE: 'get_range_loan_proposal_center_list_failure',
	GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_RESET:
		'get_range_loan_proposal_center_list_response_reset',
	GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_CHANGED:
		'get_range_loan_proposal_center_list_response_changed',

	GET_CENTER_MEMBER_LIST_START: 'get_center_member_list_start',
	GET_CENTER_MEMBER_LIST_SUCCESS: 'get_center_member_list_success',
	GET_CENTER_MEMBER_LIST_FAILURE: 'get_center_member_list_failure',
	GET_CENTER_MEMBER_LIST_RESPONSE_RESET: 'get_center_member_list_response_reset',
	GET_CENTER_MEMBER_LIST_RESPONSE_CHANGED: 'get_center_member_list_response_changed',

	GET_RANGE_PRODUCT_LIST_START: 'get_range_product_list_start',
	GET_RANGE_PRODUCT_LIST_SUCCESS: 'get_range_product_list_success',
	GET_RANGE_PRODUCT_LIST_FAILURE: 'get_range_product_list_failure',
	GET_RANGE_PRODUCT_LIST_RESPONSE_RESET: 'get_range_product_list_response_reset',
	GET_RANGE_PRODUCT_LIST_RESPONSE_CHANGED: 'get_range_product_list_response_changed',

	ADD_RANGE_LOAN_PROPOSAL_START: 'add_range_loan_proposal_start',
	ADD_RANGE_LOAN_PROPOSAL_SUCCESS: 'add_range_loan_proposal_success',
	ADD_RANGE_LOAN_PROPOSAL_FAILURE: 'add_range_loan_proposal_failure',
	ADD_RANGE_LOAN_PROPOSAL_RESPONSE_RESET: 'add_range_loan_proposal_response_reset',
	ADD_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'add_range_loan_proposal_response_changed',

	UPDATE_RANGE_LOAN_PROPOSAL_START: 'update_range_loan_proposal_start',
	UPDATE_RANGE_LOAN_PROPOSAL_SUCCESS: 'update_range_loan_proposal_success',
	UPDATE_RANGE_LOAN_PROPOSAL_FAILURE: 'update_range_loan_proposal_failure',
	UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET: 'update_range_loan_proposal_response_reset',
	UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'update_range_loan_proposal_response_changed',

	GET_CENTER_MEMBER_LEAD_LIST_START: 'get_center_member_Lead_list_start',
	GET_CENTER_MEMBER_LEAD_LIST_SUCCESS: 'get_center_member_Lead_list_success',
	GET_CENTER_MEMBER_LEAD_LIST_FAILURE: 'get_center_member_Lead_list_failure',
	GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_RESET: 'get_center_member_Lead_list_response_reset',
	GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_CHANGED: 'get_center_member_Lead_list_response_changed',

	GET_PARTICULAR_LOAN_INDEX_DETAILS_START: 'get_particular_loan_index_details_start',
	GET_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS: 'get_particular_loan_index_details_success',
	GET_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE: 'get_particular_loan_index_details_failure',
	GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET:
		'get_particular_loan_index_details_response_reset',
	GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
		'get_particular_loan_index_details_response_changed',

	APPROVE_RANGE_LOAN_PROPOSAL_START: 'approve_range_loan_proposal_start',
	APPROVE_RANGE_LOAN_PROPOSAL_SUCCESS: 'approve_range_loan_proposal_success',
	APPROVE_RANGE_LOAN_PROPOSAL_FAILURE: 'approve_range_loan_proposal_failure',
	APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET: 'approve_range_loan_proposal_response_reset',
	APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'approve_range_loan_proposal_response_changed',

	REVERT_APPROVED_RANGE_LOAN_PROPOSAL_START: 'revert_approved_range_loan_proposal_start',
	REVERT_APPROVED_RANGE_LOAN_PROPOSAL_SUCCESS: 'revert_approved_range_loan_proposal_success',
	REVERT_APPROVED_RANGE_LOAN_PROPOSAL_FAILURE: 'revert_approved_range_loan_proposal_failure',
	REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_RESET:
		'revert_approved_range_loan_proposal_response_reset',
	REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'revert_approved_range_loan_proposal_response_changed',

	REJECT_RANGE_LOAN_PROPOSAL_START: 'reject_range_loan_proposal_start',
	REJECT_RANGE_LOAN_PROPOSAL_SUCCESS: 'reject_range_loan_proposal_success',
	REJECT_RANGE_LOAN_PROPOSAL_FAILURE: 'reject_range_loan_proposal_failure',
	REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_RESET: 'reject_range_loan_proposal_response_reset',
	REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'reject_range_loan_proposal_response_changed',

	DELETE_RANGE_LOAN_PROPOSAL_START: 'delete_range_loan_proposal_start',
	DELETE_RANGE_LOAN_PROPOSAL_SUCCESS: 'delete_range_loan_proposal_success',
	DELETE_RANGE_LOAN_PROPOSAL_FAILURE: 'delete_range_loan_proposal_failure',
	DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET: 'delete_range_loan_proposal_response_reset',
	DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'delete_range_loan_proposal_response_changed',
};
export default RangeLoanProposalActionTypes;
