import ExtraInformationActionType from './extra-information.types';

// Extra Information
export const extraInformationAddStart = (extraInformationCredentials) => ({
	type: ExtraInformationActionType.EXTRA_INFORMATION_ADD_START,
	payload: extraInformationCredentials,
});

export const extraInformationAddSuccess = (extraInformationSuccess) => ({
	type: ExtraInformationActionType.EXTRA_INFORMATION_ADD_SUCCESS,
	payload: extraInformationSuccess,
});

export const extraInformationAddFailure = (extraInformationFailure) => ({
	type: ExtraInformationActionType.EXTRA_INFORMATION_ADD_FAILURE,
	payload: extraInformationFailure,
});

export const extraInformationAddResponseResetStart = () => ({
	type: ExtraInformationActionType.EXTRA_INFORMATION_ADD_RESPONSE_REST_START,
});

export const extraInformationAddUserResponseChanged = () => ({
	type: ExtraInformationActionType.EXTRA_INFORMATION_ADD_RESPONSE_CHANGED,
});
