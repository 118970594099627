// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-param-last */
import DashboardTypes from './Dashboard.types';
import { docodeResponse, decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	dashboardDetailsFetchResponse: null,
	getDashboardDetailsArr: [],
	loanDetailsFetchResponse: null,
	getLoanDetailsArr: [],
	loanIndexDetailsFetchResponse: null,
	getLoanIndexDetailsArr: [],
	companyEodStatusResponse: null,
	getCompanyEodStatusArr: [],
	workflowDashboardDetailsFetchResponse: null,
	getWorkflowDashboardDetailsArr: [],
	todayGoldPriceResponse: null,
	getTodayGoldPriceArr: [],
	loanDetailsExportFetchResponse: null,
	getLoanDetailsExportArr: [],
	loanDetailsExportStatus: null,
	loanDetailsExportArr: [],
	updateTodayGoldPriceRep: null,
	getEODStatusPDFListResponse: null,
	getEODStatusPDFListArr: [],
	getEODStatusPDFListStatus: null,
	getRejectMemberListArr: [],
	getRejectMemberListStatus: null,
	getRejectNotesMemberListArr: [],
	getRejectNotesMemberListStatus: null,
	getDashboardParticularListArr: [],
	getDashboardParticularListStatus: null,
	getDashboardParticularDemandListArr: [],
	getDashboardParticularDemandListStatus: null,
	getDashboardStaffCollectionArr: [],
	getDashboardStaffCollectionStatus: null,
	getDashboardStaffLocationArr: [],
	getDashboardStaffLocationStatus: null,
	getParticularDateDemandArr: [],
	getParticularDateDemandStatus: null,
	getParticularDateCollectionArr: [],
	getParticularDateCollectionStatus: null,
	getParticularDateDemandInfoArr: [],
	getParticularDateDemandInfoStatus: null,
	getParticularDateMemberAbsentArr: [],
	getParticularDateMemberAbsentStatus: null,
	getWeekCollectionArr: [],
	getWeekCollectionResponse: null,
	getWeekCollectionStatus: null,
	getWeekYTCollectionArr: [],
	getWeekYTCollectionResponse: null,
	getWeekYTCollectionStatus: null,
	getSMSUsedListStatus: null,
	getSMSUsedListArr: [],
	getKYCUsedListStatus: null,
	getKYCUsedListArr: [],
	getArrearCollectionListStatus: null,
	getArrearCollectionListArr: [],
	getDashboardEodExportStatus: null,
	getDashboardEodExportArr: [],
	getDashboardStaffMapCollectionStatus: null,
	getDashboardStaffMapCollectionArr: [],
	getDashboardCollectionInfoStatus: null,
	getDashboardCollectionInfoArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DashboardTypes.GET_DASHBOARD_DETAILS_SUCCESS:
			return {
				...state,
				dashboardDetailsFetchResponse: action.payload,
				getDashboardDetailsArr: docodeResponse(action.payload, 'DASHBOARDCARDKEY'),
			};

		case DashboardTypes.GET_DASHBOARD_DETAILS_FAILURE:
			return {
				...state,
				dashboardDetailsFetchResponse: action.payload,
				getDashboardDetailsArr: [],
			};

		case DashboardTypes.GET_DASHBOARD_DETAILS_RESPONSE_CHANGED:
			return { ...state, dashboardDetailsFetchResponse: null };

		// DASHBOARD DETAILS FETCH END

		case DashboardTypes.GET_LOAN_DETAILS_SUCCESS:
			return {
				...state,
				loanDetailsFetchResponse: action.payload,
				getLoanDetailsArr: docodeResponse(action.payload, 'DASHBOARDLOANKEY'),
			};

		case DashboardTypes.GET_LOAN_DETAILS_FAILURE:
			return {
				...state,
				loanDetailsFetchResponse: action.payload,
				getLoanDetailsArr: [],
			};

		case DashboardTypes.GET_LOAN_DETAILS_RESPONSE_CHANGED:
			return { ...state, loanDetailsFetchResponse: null };

		// LOAN DETAILS FETCH END

		case DashboardTypes.GET_LOAN_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				loanIndexDetailsFetchResponse: action.payload,
				getLoanIndexDetailsArr: docodeResponse(
					action.payload,
					'DashboardLoanIndexDetailsKey',
				),
			};

		case DashboardTypes.GET_LOAN_INDEX_DETAILS_FAILURE:
			return {
				...state,
				loanIndexDetailsFetchResponse: action.payload,
				getLoanIndexDetailsArr: [],
			};

		case DashboardTypes.GET_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, loanIndexDetailsFetchResponse: null };

		// LOAN INDEX DETAILS FETCH END

		case DashboardTypes.COMPANY_EOD_STATUS_SUCCESS:
			return {
				...state,
				companyEodStatusResponse: action.payload,
				getCompanyEodStatusArr: docodeResponse(
					action.payload,
					'DashboardEODStatusDetailsKey',
				),
			};

		case DashboardTypes.COMPANY_EOD_STATUS_FAILURE:
			return {
				...state,
				companyEodStatusResponse: action.payload,
				getCompanyEodStatusArr: [],
			};

		case DashboardTypes.COMPANY_EOD_STATUS_RESPONSE_CHANGED:
			return { ...state, companyEodStatusResponse: null };

		// LOAN INDEX DETAILS FETCH END

		case DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_SUCCESS:
			return {
				...state,
				workflowDashboardDetailsFetchResponse: action.payload,
				getWorkflowDashboardDetailsArr: docodeResponse(
					action.payload,
					'GetWorkFlowDashboardDetailsKey',
				),
			};

		case DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_FAILURE:
			return {
				...state,
				workflowDashboardDetailsFetchResponse: action.payload,
				getWorkflowDashboardDetailsArr: [],
			};

		case DashboardTypes.GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_CHANGED:
			return { ...state, workflowDashboardDetailsFetchResponse: null };

		// WORKFLOW DASHBOARD DETAILS FETCH END

		case DashboardTypes.GETTODAY_GOLD_PRICE_SUCCESS:
			return {
				...state,
				todayGoldPriceResponse: action.payload,
				getTodayGoldPriceArr: docodeResponse(action.payload, 'GetTodayGoldPriceKey'),
			};

		case DashboardTypes.GET_TODAY_GOLD_PRICE_FAILURE:
			return {
				...state,
				todayGoldPriceResponse: action.payload,
				getTodayGoldPriceArr: [],
			};

		case DashboardTypes.GET_TODAY_GOLD_PRICE_RESPONSE_CHANGED:
			return { ...state, todayGoldPriceResponse: null };

		// TODAY GOLD PRICE END

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_SUCCESS:
			return {
				...state,
				loanDetailsExportFetchResponse: action.payload,
				getLoanDetailsExportArr: docodeResponse(action.payload, 'DashboardDayWiseExcelKey'),
			};

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_FAILURE:
			return {
				...state,
				loanDetailsExportFetchResponse: action.payload,
				getLoanDetailsExportArr: [],
			};

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_RESPONSE_CHANGED:
			return { ...state, loanDetailsExportFetchResponse: null };

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_SUCCESS:
			return {
				...state,
				loanDetailsExportStatus: 'SUCCESS',
				loanDetailsExportArr: docodeResponse(action.payload, 'DashboardLoanDetailsExportKey'),
			};

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_FAILURE:
			return {
				...state,
				loanDetailsExportStatus: 'FAILURE',
			};

		case DashboardTypes.GET_LOAN_DETAILS_EXPORT_JSON_CHANGED:
			return { ...state, loanDetailsExportStatus: null };

		// TODAY GOLD PRICE END

		case DashboardTypes.UPDATE_TODAY_GOLD_PRICE_SUCCESS:
			return {
				...state,
				updateTodayGoldPriceRep: action.payload,
			};

		case DashboardTypes.UPDATE_TODAY_GOLD_PRICE_FAILURE:
			return {
				...state,
				updateTodayGoldPriceRep: action.payload,
			};

		case DashboardTypes.UPDATE_TODAY_GOLD_PRICE_RESPONSE_CHANGED:
			return { ...state, updateTodayGoldPriceRep: null };

		// TODAY GOLD PRICE UPDATE END

		case DashboardTypes.GET_DEMAND_COLLECTION_PDF_LIST_SUCCESS:
			return {
				...state,
				getEODStatusPDFListResponse: action.payload,
				getEODStatusPDFListArr: docodeResponse(action.payload, 'EODStatusPDFKey'),
				getEODStatusPDFListStatus: 'Success',
			};

		case DashboardTypes.GET_DEMAND_COLLECTION_PDF_LIST_FAILURE:
			return { ...state, getEODStatusPDFListStatus: action.payload };

		case DashboardTypes.GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_CHANGED:
			return { ...state, getEODStatusPDFListStatus: null };

		// REJECT MEMBER LIST SECTION
		case DashboardTypes.REJECT_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getRejectMemberListStatus: 'SUCCESS',
				getRejectMemberListArr: docodeResponse(action.payload, 'RejectedMembersDetailsKey'),
			};

		case DashboardTypes.REJECT_MEMBER_LIST_FAILURE:
			return {
				...state,
				getRejectMemberListStatus: 'FAILURE',
			};

		case DashboardTypes.REJECT_MEMBER_LIST_CHANGED:
			return { ...state, getRejectMemberListStatus: null, getRejectMemberListArr: null };

		// REJECT NOTES MEMBER LIST SECTION
		case DashboardTypes.REJECT_NOTES_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getRejectNotesMemberListStatus: 'SUCCESS',
				getRejectNotesMemberListArr: docodeResponse(
					action.payload,
					'RejectedMembersDetailsKey',
				),
			};

		case DashboardTypes.REJECT_NOTES_MEMBER_LIST_FAILURE:
			return {
				...state,
				getRejectNotesMemberListStatus: 'FAILURE',
			};

		case DashboardTypes.REJECT_NOTES_MEMBER_LIST_CHANGED:
			return {
				...state,
				getRejectNotesMemberListStatus: null,
				getRejectNotesMemberListArr: null,
			};

		// DASHBOARD PARTICULAR DETAILS SECTION
		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_SUCCESS:
			return {
				...state,
				getDashboardParticularListStatus: 'SUCCESS',
				getDashboardParticularListArr: docodeResponse(
					action.payload,
					'DashboardCardBranchwiseKey',
				),
			};

		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_FAILURE:
			return {
				...state,
				getDashboardParticularListStatus: 'FAILURE',
			};

		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DETAILS_CHANGED:
			return { ...state, getDashboardParticularListStatus: null };

		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_SUCCESS:
			return {
				...state,
				getDashboardParticularDemandListStatus: 'SUCCESS',
				getDashboardParticularDemandListArr: docodeResponse(
					action.payload,
					'DashboardAmountBasedonlocationKey',
				),
			};

		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_FAILURE:
			return {
				...state,
				getDashboardParticularDemandListStatus: 'FAILURE',
			};

		case DashboardTypes.GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_CHANGED:
			return { ...state, getDashboardParticularDemandListStatus: null };

		// DASHBOARD STAFF COLLECTION LIST SECTION
		case DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_SUCCESS:
			return {
				...state,
				getDashboardStaffCollectionStatus: 'SUCCESS',
				getDashboardStaffCollectionArr: docodeResponse(
					action.payload,
					'StaffTotalcollectionKey',
				),
			};

		case DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_FAILURE:
			return {
				...state,
				getDashboardStaffCollectionStatus: 'FAILURE',
			};

		case DashboardTypes.GET_DASHBOARD_STAFF_COLLECTION_CHANGED:
			return { ...state, getDashboardStaffCollectionStatus: null };

		// DASHBOARD STAFF LOCATION SECTION
		case DashboardTypes.GET_STAFF_LOCATION_SUCCESS:
			return {
				...state,
				getDashboardStaffLocationStatus: 'SUCCESS',
				getDashboardStaffLocationArr: docodeResponse(
					action.payload,
					'GetCompanyBasedOnStaffLocationKey',
				),
			};

		case DashboardTypes.GET_STAFF_LOCATION_FAILURE:
			return {
				...state,
				getDashboardStaffLocationStatus: 'FAILURE',
			};

		case DashboardTypes.GET_STAFF_LOCATION_CHANGED:
			return { ...state, getDashboardStaffLocationStatus: null };

		// DEMAND SECTION

		// DASHBOARD PARTICULAR DATE DEMAND SECTION
		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_SUCCESS:
			return {
				...state,
				getParticularDateDemandStatus: 'SUCCESS',
				getParticularDateDemandArr: docodeResponse(
					action.payload,
					'ParticularDateGroupDemandInfoKey',
				),
			};

		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_FAILURE:
			return {
				...state,
				getParticularDateDemandStatus: 'FAILURE',
			};

		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_CHANGED:
			return {
				...state,
				getParticularDateDemandStatus: null,
				getParticularDateDemandArr: null,
			};

		// DASHBOARD PARTICULAR DATE DEMAND INFO SECTION
		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_SUCCESS:
			return {
				...state,
				getParticularDateDemandInfoStatus: 'SUCCESS',
				getParticularDateDemandInfoArr: docodeResponse(
					action.payload,
					'ParticularDateGroupMemberInfoKey',
				),
			};

		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_FAILURE:
			return {
				...state,
				getParticularDateDemandInfoStatus: 'FAILURE',
			};

		case DashboardTypes.GET_PARTICULAR_DATE_DEMAND_INFO_CHANGED:
			return {
				...state,
				getParticularDateDemandInfoStatus: null,
				getParticularDateDemandInfoArr: null,
			};

		// DASHBOARD PARTICULAR DATE MEMBER ABSENT SECTION
		case DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_SUCCESS:
			return {
				...state,
				getParticularDateMemberAbsentStatus: 'SUCCESS',
				getParticularDateMemberAbsentArr: docodeResponse(
					action.payload,
					'DashboardMemberAbsentDetailsKey',
				),
			};

		case DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_FAILURE:
			return {
				...state,
				getParticularDateMemberAbsentStatus: 'FAILURE',
			};

		case DashboardTypes.GET_PARTICULAR_DATE_MEMBER_ABSENT_CHANGED:
			return {
				...state,
				getParticularDateMemberAbsentStatus: null,
				getParticularDateMemberAbsentArr: null,
			};

		// COLLECTION SECTION
		// DASHBOARD PARTICULAR DATE COLLECTION SECTION
		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_SUCCESS:
			return {
				...state,
				getParticularDateCollectionStatus: 'SUCCESS',
				getParticularDateCollectionArr: docodeResponse(
					action.payload,
					'DashboardParticularDateGroupCollectionKey',
				),
			};

		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_FAILURE:
			return {
				...state,
				getParticularDateCollectionStatus: 'FAILURE',
			};

		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_CHANGED:
			return {
				...state,
				getParticularDateCollectionStatus: null,
				getParticularDateCollectionArr: null,
			};

		// DASHBOARD PARTICULAR DATE COLLECTION INFO SECTION
		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_SUCCESS:
			return {
				...state,
				getParticularDateCollectionInfoStatus: 'SUCCESS',
				getParticularDateCollectionInfoArr: docodeResponse(
					action.payload,
					'GetParticularDateGroupMemberCollectionKey',
				),
			};

		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_FAILURE:
			return {
				...state,
				getParticularDateCollectionInfoStatus: 'FAILURE',
			};

		case DashboardTypes.GET_PARTICULAR_DATE_COLLECTION_INFO_CHANGED:
			return {
				...state,
				getParticularDateCollectionInfoStatus: null,
				getParticularDateCollectionInfoArr: null,
			};

		case DashboardTypes.GET_WEEK_COLLECTION_SUCCESS:
			return {
				...state,
				getWeekCollectionStatus: 'SUCCESS',
				getWeekCollectionArr: docodeResponse(
					action.payload,
					'CollectionAmountBranchMemberWiseKey',
				),
				getWeekCollectionResponse: decodeApiResponse(
					action.payload.response,
					'CollectionAmountBranchMemberWiseKey',
				),
			};

		case DashboardTypes.GET_WEEK_COLLECTION_FAILURE:
			return {
				...state,
				getWeekCollectionStatus: 'FAILURE',
			};

		case DashboardTypes.GET_WEEK_COLLECTION_CHANGED:
			return {
				...state,
				getWeekCollectionStatus: null,
				getWeekCollectionArr: null,
			};

		case DashboardTypes.GET_WEEK_YT_COLLECTION_SUCCESS:
			return {
				...state,
				getWeekYTCollectionStatus: 'SUCCESS',
				getWeekYTCollectionArr: docodeResponse(
					action.payload,
					'GetParticularCollectionPendingGroupMemberKey',
				),
				getWeekYTCollectionResponse: decodeApiResponse(
					action.payload.response,
					'GetParticularCollectionPendingGroupMemberKey',
				),
			};

		case DashboardTypes.GET_WEEK_YT_COLLECTION_FAILURE:
			return {
				...state,
				getWeekYTCollectionStatus: 'FAILURE',
			};

		// SMS USED LIST SECTION

		case DashboardTypes.SMS_USED_LIST_SUCCESS:
			return {
				...state,
				getSMSUsedListStatus: 'SUCCESS',
				getSMSUsedListArr: docodeResponse(action.payload, 'DashboardSMSUsedListKey'),
			};

		case DashboardTypes.SMS_USED_LIST_FAILURE:
			return {
				...state,
				getSMSUsedListStatus: 'FAILURE',
			};

		case DashboardTypes.SMS_USED_LIST_CHANGED:
			return {
				...state,
				getSMSUsedListStatus: null,
			};

		// KYC USED LIST SECTION

		case DashboardTypes.KYC_USED_LIST_SUCCESS:
			return {
				...state,
				getKYCUsedListStatus: 'SUCCESS',
				getKYCUsedListArr: docodeResponse(action.payload, 'DashboardKYCUsedListKey'),
			};

		case DashboardTypes.KYC_USED_LIST_FAILURE:
			return {
				...state,
				getKYCUsedListStatus: 'FAILURE',
			};

		case DashboardTypes.KYC_USED_LIST_CHANGED:
			return {
				...state,
				getKYCUsedListStatus: null,
			};
		// ARREAR COLLECTION LIST SECTION

		case DashboardTypes.ARREAR_COLLECTION_LIST_SUCCESS:
			return {
				...state,
				getArrearCollectionListStatus: 'SUCCESS',
				getArrearCollectionListArr: docodeResponse(
					action.payload,
					'DashboardArrearCollectionListKey',
				),
			};

		case DashboardTypes.ARREAR_COLLECTION_LIST_FAILURE:
			return {
				...state,
				getArrearCollectionListStatus: 'FAILURE',
			};

		case DashboardTypes.ARREAR_COLLECTION_LIST_CHANGED:
			return {
				...state,
				getArrearCollectionListStatus: null,
			};

		case DashboardTypes.DASHBOARD_EOD_EXPORT_SUCCESS:
			return {
				...state,
				getDashboardEodExportStatus: 'SUCCESS',
				getDashboardEodExportArr: docodeResponse(
					action.payload,
					'DashboardEodStatusExportKey',
				),
			};

		case DashboardTypes.DASHBOARD_EOD_EXPORT_FAILURE:
			return {
				...state,
				getDashboardEodExportStatus: 'FAILURE',
			};

		case DashboardTypes.DASHBOARD_EOD_EXPORT_CHANGED:
			return {
				...state,
				getDashboardEodExportStatus: null,
			};

		// STAFF MAP COLLECTION SECTION
		case DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_SUCCESS:
			return {
				...state,
				getDashboardStaffMapCollectionStatus: 'SUCCESS',
				getDashboardStaffMapCollectionArr: docodeResponse(
					action.payload,
					'DashboardStaffMapCollectionKey',
				),
			};

		case DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_FAILURE:
			return {
				...state,
				getDashboardStaffMapCollectionStatus: 'FAILURE',
			};

		case DashboardTypes.DASHBOARD_STAFF_MAP_COLLECTION_CHANGED:
			return {
				...state,
				getDashboardStaffMapCollectionStatus: null,
			};
		// COLLECTION INFO
		case DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_SUCCESS:
			return {
				...state,
				getDashboardCollectionInfoStatus: 'SUCCESS',
				getDashboardCollectionInfoArr: docodeResponse(
					action.payload,
					'GetCollectionNotesInfoKey',
				),
			};

		case DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_FAILURE:
			return {
				...state,
				getDashboardCollectionInfoStatus: 'FAILURE',
			};

		case DashboardTypes.DASHBOARD_COLLECTION_NOTE_INFO_CHANGED:
			return {
				...state,
				getDashboardCollectionInfoStatus: null,
			};

		default:
			return state;
	}
};
