/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';


export const branchOfficerListGet = (listData) => {
	const data = {
		GetOfficerListBranchToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedonBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const officerCenterListGet = (listData) => {
	const data = {
		GetOfficerListCenterToken: listData,
	};
	const url = `${API_URL}GetCenterListBasedOnOfficerBranchToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanGroupListGet = (listData) => {
	const data = {
		GetLoanGroupListToken: listData,
	};
	const url = `${API_URL}getGroupBasedOnLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeGroupOfficerListUpdate = (listData) => {
	const data = {
		ChangeGroupOfficerDataToken: listData,
	};
	const url = `${API_URL}UpdateGroupOfficerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeProposedOfficerListUpdate = (listData) => {
	const data = {
		ChangeProposedOfficerDataToken: listData,
	};
	const url = `${API_URL}UpdateProposedOfficerForLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ChangeCenterLoanOfficerListUpdate = (listData) => {
	const data = {
		ChangeCenterLoansGroupOfficerToken: listData,
	};
	const url = `${API_URL}UpdateGroupOfficerForCenterListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

