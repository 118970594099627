/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const staffReportDetailsGet = (fetchData) => {
	const data = {
		FetchStaffReportDetailsToken: fetchData,
	};
	const url = `${API_URL}GetStaffReportwithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffReportExcel = (branchData) => {
	// const data = {
	// 	RepaymentCollectionExcel: branchData,
	// };
	// const url = `${API_URL}CreateJobHandler`;
	const API_URL_EXCEL = 'https://dev.api.squarenow.in/';
	const url = `${API_URL_EXCEL}Excel/RepaymentReportSimpleMemberWiseWithToken.php?CompanyID=${branchData.CompanyID}&DatabaseName=${branchData.DatabaseName}&FromDate=${branchData.FromDate}&ToDate=${branchData.ToDate}&BranchID=${branchData.BranchID}&CenterID=${branchData.CenterID}&RequestID=${branchData.RequestID}&CountLimit=${branchData.CountLimit}&UserID=${branchData.UserID}`;
	return axios.post(url, branchData, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
};

export const staffOfficerDetails = (fetchData) => {
	const data = {
		StaffOfficerToken: fetchData,
	};
	const url = `${API_URL}getListOfficerUserForBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
