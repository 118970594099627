/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const funderDetailsAdd = (addData) => {
	const data = {
		AddFunderDetailsToken: addData,
	};

	const url = `${API_URL}addFunderDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const funderDetailsUpdate = (updateData) => {
	const data = {
		UpdateFunderDetailsToken: updateData,
	};
	const url = `${API_URL}updateFunderDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const funderListGet = (listData) => {
	const data = {
		GetFunderListToken: listData,
	};
	const url = `${API_URL}getFunderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const funderDetailsFetch = (fetchData) => {
	const data = {
		FetchFunderDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchFunderDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const funderDetailsDelete = (deleteData) => {
	const data = {
		DeleteFunderDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteFunderDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const FunderPublishUpdate = (deleteData) => {
	const data = {
		UpdateFunderPublishToken: deleteData,
	};
	const url = `${API_URL}updatePublishStatusFunder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
