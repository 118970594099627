/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import highmarkRulesTypes from './highmarkRules.types';
import { highmarkRulesAdd, highmarkRulesFetch } from './highmarkRules.service';

import {
	addHighmarkRulesSuccess,
	addHighmarkRulesFailure,
	addHighmarkRulesResponseChanged,
	fetchHighmarkRulesSuccess,
	fetchHighmarkRulesFailure,
	fetchHighmarkRulesResponseChanged,
} from './highmarkRules.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD THRIFT DETAILS  ******************** */
export function* createThrift({ payload }) {
	try {
		const key = 'UpdateHighmarkRulesKey';
		const HighmarklistJson = JSON.stringify(payload.ruleArr).replace(/"/g, "'");
		const jsonData = {
			CompanyID: payload.companyID,
            DatabaseName: payload.databaseName,
			RuleArr: HighmarklistJson,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(highmarkRulesAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addHighmarkRulesFailure(responseData.data));
		} else {
			yield put(addHighmarkRulesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addHighmarkRulesFailure(error));
	}
}

export function* resetAddThriftResponse() {
	yield put(addHighmarkRulesResponseChanged());
}

export function* onCreateHighmarkRules() {
	yield takeLatest(highmarkRulesTypes.ADD_HIGHMARK_RULES_START, createThrift);
}

export function* onCreateThriftResponseReset() {
	yield takeLatest(highmarkRulesTypes.ADD_HIGHMARK_RULES_RESPONSE_RESET, resetAddThriftResponse);
}
/** *********************  END ADD THRIFT DETAILS ******************** */

/** *********************  START FETCH THRIFT DETAILS  ******************** */
export function* fetchThriftData({ payload }) {
	try {
		const key = 'FetchHighmarkRulesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(highmarkRulesFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchHighmarkRulesFailure(responseData.data));
		} else {
			yield put(fetchHighmarkRulesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchHighmarkRulesFailure(error));
	}
}

export function* resetFetchHighmarkRulesResponse() {
	yield put(fetchHighmarkRulesResponseChanged());
}

export function* onFetchHighmarkRules() {
	yield takeLatest(highmarkRulesTypes.FETCH_HIGHMARK_RULES_START, fetchThriftData);
}

export function* onFetchThriftResponseReset() {
	yield takeLatest(
		highmarkRulesTypes.FETCH_HIGHMARK_RULES_RESPONSE_RESET,
		resetFetchHighmarkRulesResponse,
	);
}
/** *********************  END FETCH THRIFT DETAILS ******************** */

export function* highmarkRulesSaga() {
	yield all([
		call(onCreateHighmarkRules),
		call(onCreateThriftResponseReset),
		call(onFetchHighmarkRules),
		call(onFetchThriftResponseReset),
	]);
}
