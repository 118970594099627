const memberCollectionActionTypes = {
	GET_SEARCH_MEMBER_LOAN_LIST_START: 'GET_SEARCH_MEMBER_LOAN_LIST_START',
	GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS: 'GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS',
	GET_SEARCH_MEMBER_LOAN_LIST_FAILURE: 'GET_SEARCH_MEMBER_LOAN_LIST_FAILURE',
	GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET: 'GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET',
	GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED: 'GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED',

	GET_MEMBER_LOAN_INFO_START: 'GET_MEMBER_LOAN_INFO_START',
	GET_MEMBER_LOAN_INFO_SUCCESS: 'GET_MEMBER_LOAN_INFO_SUCCESS',
	GET_MEMBER_LOAN_INFO_FAILURE: 'GET_MEMBER_LOAN_INFO_FAILURE',
	GET_MEMBER_LOAN_INFO_RESPONSE_RESET: 'GET_MEMBER_LOAN_INFO_RESPONSE_RESET',
	GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED: 'GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED',

	UPDATE_MEMBER_COLLECTION_START: 'UPDATE_MEMBER_COLLECTION_START',
	UPDATE_MEMBER_COLLECTION_SUCCESS: 'UPDATE_MEMBER_COLLECTION_SUCCESS',
	UPDATE_MEMBER_COLLECTION_FAILURE: 'UPDATE_MEMBER_COLLECTION_FAILURE',
	UPDATE_MEMBER_COLLECTION_RESPONSE_RESET: 'UPDATE_MEMBER_COLLECTION_RESPONSE_RESET',
	UPDATE_MEMBER_COLLECTION_RESPONSE_CHANGED: 'UPDATE_MEMBER_COLLECTION_RESPONSE_CHANGED',

	UPDATE_MEMBER_DEATH_START: 'UPDATE_MEMBER_DEATH_START',
	UPDATE_MEMBER_DEATH_SUCCESS: 'UPDATE_MEMBER_DEATH_SUCCESS',
	UPDATE_MEMBER_DEATH_FAILURE: 'UPDATE_MEMBER_DEATH_FAILURE',
	UPDATE_MEMBER_DEATH_RESPONSE_RESET: 'UPDATE_MEMBER_DEATH_RESPONSE_RESET',
	UPDATE_MEMBER_DEATH_RESPONSE_CHANGED: 'UPDATE_MEMBER_DEATH_RESPONSE_CHANGED',

	UPDATE_INTEREST_WAIVER_START: 'UPDATE_INTEREST_WAIVER_START',
	UPDATE_INTEREST_WAIVER_SUCCESS: 'UPDATE_INTEREST_WAIVER_SUCCESS',
	UPDATE_INTEREST_WAIVER_FAILURE: 'UPDATE_INTEREST_WAIVER_FAILURE',
	UPDATE_INTEREST_WAIVER_RESPONSE_RESET: 'UPDATE_INTEREST_WAIVER_RESPONSE_RESET',
	UPDATE_INTEREST_WAIVER_RESPONSE_CHANGED: 'UPDATE_INTEREST_WAIVER_RESPONSE_CHANGED',

	GET_MEMBER_COLLECTION_START: 'GET_MEMBER_COLLECTION_START',
	GET_MEMBER_COLLECTION_SUCCESS: 'GET_MEMBER_COLLECTION_SUCCESS',
	GET_MEMBER_COLLECTION_FAILURE: 'GET_MEMBER_COLLECTION_FAILURE',
	GET_MEMBER_COLLECTION_RESPONSE_RESET: 'GET_MEMBER_COLLECTION_RESPONSE_RESET',
	GET_MEMBER_COLLECTION_RESPONSE_CHANGED: 'GET_MEMBER_COLLECTION_RESPONSE_CHANGED',

	GET_MEMBER_WALLET_START: 'GET_MEMBER_WALLET_START',
	GET_MEMBER_WALLET_SUCCESS: 'GET_MEMBER_WALLET_SUCCESS',
	GET_MEMBER_WALLET_FAILURE: 'GET_MEMBER_WALLET_FAILURE',
	GET_MEMBER_WALLET_RESPONSE_RESET: 'GET_MEMBER_WALLET_RESPONSE_RESET',
	GET_MEMBER_WALLET_RESPONSE_CHANGED: 'GET_MEMBER_WALLET_RESPONSE_CHANGED',

	GET_MEMBER_QR_START: 'GET_MEMBER_QR_START',
	GET_MEMBER_QR_SUCCESS: 'GET_MEMBER_QR_SUCCESS',
	GET_MEMBER_QR_FAILURE: 'GET_MEMBER_QR_FAILURE',
	GET_MEMBER_QR_RESPONSE_RESET: 'GET_MEMBER_QR_RESPONSE_RESET',
	GET_MEMBER_QR_RESPONSE_CHANGED: 'GET_MEMBER_QR_RESPONSE_CHANGED',

	GET_MEMBER_REPAYMENT_START: 'GET_MEMBER_REPAYMENT_START',
	GET_MEMBER_REPAYMENT_SUCCESS: 'GET_MEMBER_REPAYMENT_SUCCESS',
	GET_MEMBER_REPAYMENT_FAILURE: 'GET_MEMBER_REPAYMENT_FAILURE',
	GET_MEMBER_REPAYMENT_RESPONSE_RESET: 'GET_MEMBER_REPAYMENT_RESPONSE_RESET',
	GET_MEMBER_REPAYMENT_RESPONSE_CHANGED: 'GET_MEMBER_REPAYMENT_RESPONSE_CHANGED',

	DELETE_MEMBER_WALLET_START: 'DELETE_MEMBER_WALLET_START',
	DELETE_MEMBER_WALLET_SUCCESS: 'DELETE_MEMBER_WALLET_SUCCESS',
	DELETE_MEMBER_WALLET_FAILURE: 'DELETE_MEMBER_WALLET_FAILURE',
	DELETE_MEMBER_WALLET_RESPONSE_RESET: 'DELETE_MEMBER_WALLET_RESPONSE_RESET',
	DELETE_MEMBER_WALLET_RESPONSE_CHANGED: 'DELETE_MEMBER_WALLET_RESPONSE_CHANGED',

	REVERT_MEMBER_COLLECTION_START: 'REVERT_MEMBER_COLLECTION_START',
	REVERT_MEMBER_COLLECTION_SUCCESS: 'REVERT_MEMBER_COLLECTION_SUCCESS',
	REVERT_MEMBER_COLLECTION_FAILURE: 'REVERT_MEMBER_COLLECTION_FAILURE',
	REVERT_MEMBER_COLLECTION_RESPONSE_RESET: 'REVERT_MEMBER_COLLECTION_RESPONSE_RESET',
	REVERT_MEMBER_COLLECTION_RESPONSE_CHANGED: 'REVERT_MEMBER_COLLECTION_RESPONSE_CHANGED',
};
export default memberCollectionActionTypes;
