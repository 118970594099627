// eslint-disable-next-line import/no-unresolved
import HouseDetailsActionType from './house-details.types';

export const houseCreateStart = (houseCredentils) => ({
	type: HouseDetailsActionType.HOUSE_DETAILS_CREATE_START,
	payload: houseCredentils,
});

export const houseCreateSuccess = (house) => ({
	type: HouseDetailsActionType.HOUSE_DETAILS_CREATE_SUCCESS,
	payload: house,
});

export const houseCreateFailure = (error) => ({
	type: HouseDetailsActionType.HOUSE_DETAILS_CREATE_FAILURE,
	payload: error,
});

export const houseResponseChanged = () => ({
	type: HouseDetailsActionType.HOUSE_DETAILS_CREATE_RESPONSE_CHANGED,
});

export const houseResponseReset = () => ({
	type: HouseDetailsActionType.HOUSE_DETAILS_CREATE_RESPONSE_REST_START,
});
