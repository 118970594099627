/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ThriftActionTypes from './thrift.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addThriftDetailsResponse: null,
	updateThriftDetailsResponse: null,
	getThriftListResponse: null,
	getThriftListArr: [],
	fetchThriftDetailsResponse: null,
	fetchThriftDetailsArr: [],
	deleteThriftDetailsResponse: null,
	fetchAllThriftListResponse: null,
	fetchAllThriftListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ThriftActionTypes.ADD_THRIFT_DETAILS_SUCCESS:
			return { ...state, addThriftDetailsResponse: action.payload };

		case ThriftActionTypes.ADD_THRIFT_DETAILS_FAILURE:
			return { ...state, addThriftDetailsResponse: action.payload };

		case ThriftActionTypes.ADD_THRIFT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addThriftDetailsResponse: null };

		case ThriftActionTypes.UPDATE_THRIFT_DETAILS_SUCCESS:
			return { ...state, updateThriftDetailsResponse: action.payload };

		case ThriftActionTypes.UPDATE_THRIFT_DETAILS_FAILURE:
			return { ...state, updateThriftDetailsResponse: action.payload };

		case ThriftActionTypes.UPDATE_THRIFT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateThriftDetailsResponse: null };

		case ThriftActionTypes.GET_THRIFT_LIST_SUCCESS:
			return {
				...state,
				getThriftListResponse: action.payload,
				getThriftListArr: docodeResponse(action.payload, 'GetThriftListKey'),
			};

		case ThriftActionTypes.GET_THRIFT_LIST_FAILURE:
			return { ...state, getThriftListResponse: action.payload };

		case ThriftActionTypes.GET_THRIFT_LIST_RESPONSE_CHANGED:
			return { ...state, getThriftListResponse: null };

		case ThriftActionTypes.FETCH_THRIFT_DETAILS_SUCCESS:
			return {
				...state,
				fetchThriftDetailsResponse: action.payload,
				fetchThriftDetailsArr: docodeResponse(action.payload, 'FetchThriftDetailsKey'),
			};

		case ThriftActionTypes.FETCH_THRIFT_DETAILS_FAILURE:
			return { ...state, fetchThriftDetailsResponse: action.payload, fetchThriftDetailsArr: [] };

		case ThriftActionTypes.FETCH_THRIFT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchThriftDetailsResponse: null };

		case ThriftActionTypes.DELETE_THRIFT_DETAILS_SUCCESS:
			return { ...state, deleteThriftDetailsResponse: action.payload };

		case ThriftActionTypes.DELETE_THRIFT_DETAILS_FAILURE:
			return { ...state, deleteThriftDetailsResponse: action.payload };

		case ThriftActionTypes.DELETE_THRIFT_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteThriftDetailsResponse: null };

		case ThriftActionTypes.FETCH_ALL_THRIFT_SUCCESS:
			return {
				...state,
				fetchAllThriftListResponse: action.payload,
				fetchAllThriftListArr: docodeResponse(action.payload, 'FetchAllThriftsKey'),
			};

		case ThriftActionTypes.FETCH_ALL_THRIFT_FAILURE:
			return { ...state, fetchAllThriftListResponse: action.payload };

		case ThriftActionTypes.FETCH_ALL_THRIFT_RESPONSE_CHANGED:
			return { ...state, fetchAllThriftListResponse: null };

		default:
			return state;
	}
};
