/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import JWT from 'expo-jwt';
import { decode } from 'js-base64';
import ReactS3 from 'react-s3';
import { decodeTokenJson } from '../enode-decode';

// Decode
export const getDecodedJsonData = (jsonData, key) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

export const decodeApiResponse = (jsonData, key) => {
	const decodedData = decodeTokenJson(jsonData, key);
	return decodedData;
};

export const docodeResponse = (response, key) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};

// Decode Token with Base64
export const decodeBase64Response = (reposneToken, decodeKey) => {
	let decodeResponse = decodeTokenJson(reposneToken.response, decodeKey);
	if (decodeResponse) {
		decodeResponse = decode(decodeResponse.data);
		decodeResponse = JSON.parse(decodeResponse);
	}
	return decodeResponse;
};

export const langData = [
	{
		key: 0,
		isSelected: false,
		label: 'English',
		value: 'ENGLISH',
	},
	{
		key: 1,
		isSelected: false,
		label: 'Tamil',
		value: 'TAMIL',
	},
	{
		key: 2,
		isSelected: false,
		label: 'Telugu',
		value: 'TELUGU',
	},
	{
		key: 3,
		isSelected: false,
		label: 'Kannada',
		value: 'KANNADA',
	},
	{
		key: 4,
		isSelected: false,
		label: 'Malayalam',
		value: 'MALAYALAM',
	},
	{
		key: 5,
		isSelected: false,
		label: 'Hindi',
		value: 'HINDI',
	},
];

export const dateDiffInDays = (date1, date2) => {
	// Parse the input dates into Date objects
	const d1 = new Date(date1);
	const d2 = new Date(date2);

	// Calculate the time difference in milliseconds
	const timeDiff = d2 - d1;

	// Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
	const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

	return daysDiff + 1;
};

export const getCurrentDate = () => {
	const newDate = new Date();
	const date = newDate.getDate();
	const month = newDate.getMonth() + 1;
	const year = newDate.getFullYear();
	const separator = '-';
	return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
};

// S3 IMAGE UPLOAD
export const imageUploadToS3 = async (result, path) => {
	// Create a new Blob with the same content and the desired name
	const newFile = new Blob([result], { type: 'image/png' });
	newFile.name = `${Date.now()}-S3.png`;

	const config = {
		dirName: `${path}`,
		bucketName: 'squarenow-production',
		region: 'ap-south-1',
		accessKeyId: 'AKIAXLKVNHKWZ5FRI25C',
		secretAccessKey: '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',
		successActionStatus: 201,
	};

	const imageUrl = ReactS3.uploadFile(newFile, config).then((response) => {
		if (response.location) {
			return response.location;
		}
	});
	return imageUrl;
};

// S3 PDF UPLOAD
export const pdfUploadToS3 = async (result, path) => {
	// Create a new Blob with the same content and the desired name
	const newFile = new Blob([result], { type: 'application/pdf' });
	newFile.name = `${Date.now()}-S3.pdf`;

	const config = {
		dirName: `${path}`,
		bucketName: 'squarenow-production',
		region: 'ap-south-1',
		accessKeyId: 'AKIAXLKVNHKWXKTDS3RW',
		secretAccessKey: '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',
		successActionStatus: 201,
	};

	const imageUrl = ReactS3.uploadFile(newFile, config).then((response) => {
		if (response.location) {
			return response.location;
		}
	});
	return imageUrl;
};

export const RejectReasonArray = [
	{
		ID: '1',
		Value: 'Information mismatch in documents submitted',
	},
	{
		ID: '2',
		Value: 'Low Credit Score',
	},
	{
		ID: '3',
		Value: 'Age more than norms',
	},
	{
		ID: '4',
		Value: 'Insufficient income',
	},
	{
		ID: '5',
		Value: 'Unstable employment',
	},
	{
		ID: '6',
		Value: 'Borrower  member of more than one SHG / JLG',
	},
	{
		ID: '7',
		Value: 'Geographical Limits not as per norms',
	},
	{
		ID: '8',
		Value: 'Caution/ Negative Profile (Ineligible Businesses and Activities)',
	},
	{
		ID: '9',
		Value: 'Living in a location where defaults are high',
	},
	{
		ID: '10',
		Value: 'Over indebtness',
	},
	{
		ID: '11',
		Value: 'Debt-to-income ratio is high (> 50%)',
	},
	{
		ID: '12',
		Value: 'Days past due (DPD) reported in Credit Bureau (CB) >30 days ',
	},
	{
		ID: '13',
		Value: 'PPI Score less than norms',
	},
	{
		ID: '14',
		Value: '> 6 live loans',
	},
	{
		ID: '15',
		Value: 'Income norms above RBI limit',
	},
	{
		ID: '16',
		Value: 'Group Default',
	},
	{
		ID: '17',
		Value: 'Writeoff loans reported in CB',
	},
	{
		ID: '18',
		Value: 'Client do not want loan now',
	},
	{
		ID: '19',
		Value: 'No nominee blood relative available',
	},
	{
		ID: '20',
		Value: 'Non recommendation from Group leader',
	},
	{
		ID: '21',
		Value: 'Overdue over 3000',
	},
	{
		ID: '22',
		Value: 'Black listed village or area',
	},
	{
		ID: '23',
		Value: 'Personal loan requirement',
	},
	{
		ID: '24',
		Value: 'very high loan request',
	},
	{
		ID: '25',
		Value: 'Multiple defaults in previous loans',
	},
	{
		ID: '26',
		Value: 'Cash flow is not adequate for repayment',
	},
	{
		ID: '27',
		Value: 'Irregular sources of income',
	},
];

export const groupListMapFun = (groupArr, groupID) => {
	const updatedGroupList = groupArr.find((item) => item.GroupID === groupID);
	return updatedGroupList;
};

export const MaskedNumber = (number, isTrue) => {
	let maskedNumber = number;
	if (number !== undefined && Number(isTrue) === 1) {
		number = number.replace(/\s/g, '');
		if (number.length === 12) {
			maskedNumber = number.toString().slice(0, -4).replace(/\d/g, 'x') + number.toString().slice(-4);
		}
	}
	return maskedNumber;
};