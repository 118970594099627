/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const questionnaireListGet = (listData) => {
	const data = {
		GetAllQuestionnaireListToken: listData,
	};
	const url = `${API_URL}GetQuestionnaireDetails`;
	return axios.post(url, data, { headers: { 'Content-type' : 'application/json'} });
};

export const questionnaireDelete = (deleteData) => {
	const data = {
		DeleteQuestionnaireToken: deleteData,
	};
	const url = `${API_URL}DeleteQuestionnaire`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
}

export const questionDelete = (deleteData) => {
	const data = {
		DeleteQuestionToken: deleteData,
	};
	const url = `${API_URL}DeleteQuestion`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
}

export const questionChoiceDelete = (deleteData) => {
	const data = {
		DeleteQuestionChoiceToken: deleteData,
	};
	const url = `${API_URL}DeleteQuestionChoice`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
}

export const questionnaireUpdate = (updateData) => {
	const data = {
		QuestionnaireUpdateToken: updateData,
	};
	const url = `${API_URL}Updatequestionnaire`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const questionnaireDetailsFetch = (listData) => {
	const data = {
		QuestionnaireFetchToken: listData,
	};
	const url = `${API_URL}FetchQuestionnaireData`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const questionnaireDetailsAdd = (addData) => {
	const data = {
		QuestionnaireCreateAddToken: addData,
	};
	const url = `${API_URL}CreateAddQuestionnaireData`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const moduleListFetch = (listData) => {
	const data = {
		ModuleListFetchToken: listData,
	};
	const url = `${API_URL}FetchModuleList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};