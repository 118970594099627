/* *** Loan Disbursement Service ******** */
/* *** Version : 2.0.1 *************** */
/* *** Author : Vivin **************** */
/* *** Last Edit : Vivin ************* */
/* *** Last Added : Newy added *** */

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchParticularLoanIndexDetails = (fetchData) => {
	const data = {
		getParticularLoanIndexDetailsDataToken: fetchData,
	};
	const url = `${API_URL}GetParticularLoanIndexDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteParticularLoanIndexDetails = (fetchData) => {
	const data = {
		DeleteParticularMemberInLoanIndexToken: fetchData,
	};
	const url = `${API_URL}DeleteParticularMemberInLoanIndexWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchFinanceAccountsList = (fetchData) => {
	const data = {
		FinanceAccountsBasedonTypeDataToken: fetchData,
	};
	const url = `${API_URL}FinanceAccountsBasedonTypeToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchHolidayList = (fetchData) => {
	const data = {
		GetHolidayDataToken: fetchData,
	};
	const url = `${API_URL}GetHolidayToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchLoanScheduleList = (fetchData) => {
	const data = {
		getLoanInfoBasedOnMembersBothclosedDataToken: fetchData,
	};
	const url = `${API_URL}GetLoanInfoBasedOnMembersBothclosedToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanIndexFunder = (fetchData) => {
	const data = {
		updateLoanIndexInvestorDataToken: fetchData,
	};
	const url = `${API_URL}UpdateLoanIndexInvestorToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanIndexMeettime = (fetchData) => {
	const data = {
		updateLoanDisbursmentdateDataToken: fetchData,
	};
	const url = `${API_URL}UpdateLoanDisbursmentdateToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateLoanIndexPOL = (fetchData) => {
	const data = {
		updatePurposeLoanInvestorDataToken: fetchData,
	};
	const url = `${API_URL}UpdatePurposeLoanInvestorToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createLoanIndexDisbursement = (fetchData) => {
	const data = {
		createOnGoingProductLoanIndexDataToken: fetchData,
	};
	const url = `${API_URL}CreateOnGoingProductLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createTransactionDisbursement = (fetchData) => {
	const data = {
		createDisbursementTranscationEntryDataToken: fetchData,
	};
	const url = `${API_URL}CreateDisbursementTranscationEntryToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createTransactionAdditionalCharges = (fetchData) => {
	const data = {
		createAdditionalAccountTranscationEntryDataToken: fetchData,
	};
	const url = `${API_URL}CreateAdditionalAccountTranscationEntryToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchNextTransactionSerialNo = (fetchData) => {
	const data = {
		nextTransactionSerialNumberToken: fetchData,
	};
	const url = `${API_URL}GetNextTransactionSerialNumber`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchProductAccountDetails = (fetchData) => {
	const data = {
		productAccountDetailDataToken: fetchData,
	};
	const url = `${API_URL}ProductAccountDetailToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchVoucherCountBasedOnCompany = (fetchData) => {
	const data = {
		getVoucherCountBasedOncompanyDataToken: fetchData,
	};
	const url = `${API_URL}GetVoucherCountBasedOncompanyToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchInterBranchContra = (fetchData) => {
	const data = {
		getInterBranchContraAccountDataToken: fetchData,
	};
	const url = `${API_URL}GetInterBranchContraAccountToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAllProductAdditionalAccount = (fetchData) => {
	const data = {
		getAllProductAdditionalAccountListDataToken: fetchData,
	};
	const url = `${API_URL}GetAllProductAdditionalAccountListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAllRangeProduct = (fetchData) => {
	const data = {
		getFilterRangeProductListDataToken: fetchData,
	};
	const url = `${API_URL}GetFilterRangeProductListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createTopupLoanIndex = (fetchData) => {
	const data = {
		createTopupLoanIndexDataToken: fetchData,
	};
	const url = `${API_URL}CreateTopupLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchLoanDisbursementPDF = (listData, pdfTitle) => {
	const params = new URLSearchParams();
	params.append('LoanIndexDisbursementPDfToken', listData);
	const url = `${API_URL}Report/Excel/${pdfTitle}.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
