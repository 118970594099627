/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import CenterWiseLoanProposalActionTypes from './centerWiseLoanProposal.types';

/** ***************** CREATE CENTER WISE LOAN PROPOSAL START *********************** */
export const addCenterWiseLoanPropsalStart = (addData) => ({
	type: CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_START,
	payload: addData,
});

export const addCenterWiseLoanPropsalSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addCenterWiseLoanPropsalFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addCenterWiseLoanPropsalResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const addCenterWiseLoanPropsalResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** CREATE CENTER WISE LOAN PROPOSAL END *********************** */

/** ***************** UPDATE CENTER WISE LOAN PROPOSAL START *********************** */
export const updateCenterWiseLoanProposalStart = (updateData) => ({
	type: CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_START,
	payload: updateData,
});

export const updateCenterWiseLoanProposalSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const updateCenterWiseLoanProposalFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const updateCenterWiseLoanProposalResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const updateCenterWiseLoanProposalResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** UPDATE CENTER WISE LOAN PROPOSAL END *********************** */

/** ***************** GET CENTER WISE LOAN PROPOSAL LIST START *********************** */
export const getCenterWiseLoanPropsalListStart = (getData) => ({
	type: CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_START,
	payload: getData,
});

export const getCenterWiseLoanPropsalListSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_SUCCESS,
	payload: successData,
});

export const getCenterWiseLoanPropsalListFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_FAILURE,
	payload: failureData,
});

export const getCenterWiseLoanPropsalListResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
});

export const getCenterWiseLoanPropsalListResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED,
});

/** ***************** GET CENTER WISE LOAN PROPOSAL LIST END *********************** */

/** ***************** APPROVE CENTER WISE LOAN PROPOSAL START *********************** */
export const approveCenterWiseProposalStart = (approveData) => ({
	type: CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_START,
	payload: approveData,
});

export const approveCenterWiseProposalSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_SUCCESS,
	payload: successData,
});

export const approveCenterWiseProposalFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_FAILURE,
	payload: failureData,
});

export const approveCenterWiseProposalResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_RESET_START,
});

export const approveCenterWiseProposalResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** APPROVE CENTER WISE LOAN PROPOSAL END *********************** */

/** ***************** FETCH CENTER WISE LOAN PROPOSAL START *********************** */
export const fetchCenterWiseLoanProposalStart = (fetchData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_START,
	payload: fetchData,
});

export const fetchCenterWiseLoanProposalSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const fetchCenterWiseLoanProposalFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const fetchCenterWiseLoanProposalResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const fetchCenterWiseLoanProposalResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** FETCH CENTER WISE LOAN PROPOSAL END *********************** */

/** ***************** DELETE CENTER WISE LOAN PROPOSAL START *********************** */
export const deleteCenterWiseLoanProposalStart = (deleteData) => ({
	type: CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_START,
	payload: deleteData,
});

export const deleteCenterWiseLoanProposalSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const deleteCenterWiseLoanProposalFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const deleteCenterWiseLoanProposalResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const deleteCenterWiseLoanProposalResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** DELETE CENTER WISE LOAN PROPOSAL END *********************** */

/** ***************** FETCH MEMBERS LIST FOR CENTER WISE LOAN START *********************** */
export const fetchMembersListForCenterWiseLoanStart = (fetchData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_START,
	payload: fetchData,
});

export const fetchMembersListForCenterWiseLoanSuccess = (successData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_SUCCESS,
	payload: successData,
});

export const fetchMembersListForCenterWiseLoanFailure = (failureData) => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_FAILURE,
	payload: failureData,
});

export const fetchMembersListForCenterWiseLoanResponseResetStart = () => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_RESET,
});

export const fetchMembersListForCenterWiseLoanResponseChanged = () => ({
	type: CenterWiseLoanProposalActionTypes.FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_CHANGED,
});

/** ***************** FETCH MEMBERS LIST FOR CENTER WISE LOAN END *********************** */
