const RuleEngineActionTypes = {
	GET_RULE_ENGINE_LIST_START: 'GET_RULE_ENGINE_LIST_START',
	GET_RULE_ENGINE_LIST_SUCCESS: 'GET_RULE_ENGINE_LIST_SUCCESS',
	GET_RULE_ENGINE_LIST_FAILURE: 'GET_RULE_ENGINE_LIST_FAILURE',
	GET_RULE_ENGINE_LIST_RESET: 'GET_RULE_ENGINE_LIST_RESET',
	GET_RULE_ENGINE_LIST_CHANGED: 'GET_RULE_ENGINE_LIST_CHANGED',

	ADD_RULE_ENGINE_LIST_START: 'ADD_RULE_ENGINE_LIST_START',
	ADD_RULE_ENGINE_LIST_SUCCESS: 'ADD_RULE_ENGINE_LIST_SUCCESS',
	ADD_RULE_ENGINE_LIST_FAILURE: 'ADD_RULE_ENGINE_LIST_FAILURE',
	ADD_RULE_ENGINE_LIST_RESET: 'ADD_RULE_ENGINE_LIST_RESET',
	ADD_RULE_ENGINE_LIST_CHANGED: 'ADD_RULE_ENGINE_LIST_CHANGED',

	DELETE_RULE_ENGINE_LIST_START: 'DELETE_RULE_ENGINE_LIST_START',
	DELETE_RULE_ENGINE_LIST_SUCCESS: 'DELETE_RULE_ENGINE_LIST_SUCCESS',
	DELETE_RULE_ENGINE_LIST_FAILURE: 'DELETE_RULE_ENGINE_LIST_FAILURE',
	DELETE_RULE_ENGINE_LIST_RESET: 'DELETE_RULE_ENGINE_LIST_RESET',
	DELETE_RULE_ENGINE_LIST_CHANGED: 'DELETE_RULE_ENGINE_LIST_CHANGED',
};
export default RuleEngineActionTypes;
