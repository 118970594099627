const AadharDetailsActionType = {
	AADHAR_FRONT_IMAGE_CHANGED: 'aadhar_front_image_changed',
	AADHAR_BACK_IMAGE_CHANGED: 'aadhar_back_image_changed',

	UPDATE_AADHAR_START: 'update_aadhar_start',
	UPDATE_AADHAR_SUCCESS: 'update_aadhar_success',
	UPDATE_AADHAR_FAILURE: 'update_aadhar_failure',
	UPDATE_AADHAR_RESPONSE_REST_START: 'update_aadhar_response_reset_start',
	UPDATE_AADHAR_RESPONSE_CHANGED: 'update_aadhar_response_changed',
};

export default AadharDetailsActionType;
