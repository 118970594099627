/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LoanDisbursementActionTypes from './loan-index-disbursement.types';

/** ***************** FETCH LOAN INDEX START *********************** */
export const fetchParticularLoanIndexDetailsStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_START,
	payload: addData,
});

export const fetchParticularLoanIndexDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchParticularLoanIndexDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchParticularLoanIndexDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
});

export const fetchParticularLoanIndexDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED,
});

export const deleteParticularLoanIndexDetailsStart = (addData) => ({
	type: LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_START,
	payload: addData,
});

export const deleteParticularLoanIndexDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteParticularLoanIndexDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteParticularLoanIndexDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
});

export const deleteParticularLoanIndexDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH LOAN INDEX END *********************** */

/** ***************** FETCH LOAN INDEX START *********************** */
export const fetchFinanceAccountsListStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_START,
	payload: addData,
});

export const fetchFinanceAccountsListSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_SUCCESS,
	payload: successData,
});

export const fetchFinanceAccountsListFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_FAILURE,
	payload: failureData,
});

export const fetchFinanceAccountsListResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_RESPONSE_RESET,
});

export const fetchFinanceAccountsListResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_FINANCE_ACCOUNTS_RESPONSE_CHANGED,
});

/** ***************** FETCH LOAN INDEX END *********************** */

/** ***************** FETCH LOAN INDEX START *********************** */
export const fetchHolidayListStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_START,
	payload: addData,
});

export const fetchHolidayListSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_SUCCESS,
	payload: successData,
});

export const fetchHolidayListFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_FAILURE,
	payload: failureData,
});

export const fetchHolidayListResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_RESPONSE_RESET,
});

export const fetchHolidayListResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_HOLIDAY_LIST_RESPONSE_CHANGED,
});

export const fetchLoanScheduleListStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_START,
	payload: addData,
});

export const fetchLoanScheduleListSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_SUCCESS,
	payload: successData,
});

export const fetchLoanScheduleListFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_FAILURE,
	payload: failureData,
});

export const fetchLoanScheduleListResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_RESPONSE_RESET,
});

export const fetchLoanScheduleListResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_SCHEDULE_LIST_RESPONSE_CHANGED,
});

export const updateLoanIndexFunderStart = (addData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_START,
	payload: addData,
});

export const updateLoanIndexFunderSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_SUCCESS,
	payload: successData,
});

export const updateLoanIndexFunderFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_FAILURE,
	payload: failureData,
});

export const updateLoanIndexFunderResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_RESPONSE_RESET,
});

export const updateLoanIndexFunderResponseChanged = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_FUNDER_RESPONSE_CHANGED,
});

export const updateLoanIndexMeettimeStart = (addData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_START,
	payload: addData,
});

export const updateLoanIndexMeettimeSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_SUCCESS,
	payload: successData,
});

export const updateLoanIndexMeettimeFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_FAILURE,
	payload: failureData,
});

export const updateLoanIndexMeettimeResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_RESPONSE_RESET,
});

export const updateLoanIndexMeettimeResponseChanged = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_MEETTIME_RESPONSE_CHANGED,
});

export const updateLoanIndexPOLStart = (addData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_START,
	payload: addData,
});

export const updateLoanIndexPOLSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_SUCCESS,
	payload: successData,
});

export const updateLoanIndexPOLFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_FAILURE,
	payload: failureData,
});

export const updateLoanIndexPOLResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_RESPONSE_RESET,
});

export const updateLoanIndexPOLResponseChanged = () => ({
	type: LoanDisbursementActionTypes.UPDATE_LOANINDEX_POL_RESPONSE_CHANGED,
});

export const createLoanIndexDisbursementStart = (addData) => ({
	type: LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_START,
	payload: addData,
});

export const createLoanIndexDisbursementSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_SUCCESS,
	payload: successData,
});

export const createLoanIndexDisbursementFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_FAILURE,
	payload: failureData,
});

export const createLoanIndexDisbursementResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_RESET,
});

export const createLoanIndexDisbursementResponseChanged = () => ({
	type: LoanDisbursementActionTypes.CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_CHANGED,
});

export const createTransactionDisbursementStart = (addData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_START,
	payload: addData,
});

export const createTransactionDisbursementSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_SUCCESS,
	payload: successData,
});

export const createTransactionDisbursementFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_FAILURE,
	payload: failureData,
});

export const createTransactionDisbursementResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_RESET,
});

export const createTransactionDisbursementResponseChanged = () => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_CHANGED,
});

export const createTransactionAdditionalChargesStart = (addData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_START,
	payload: addData,
});

export const createTransactionAdditionalChargesSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_SUCCESS,
	payload: successData,
});

export const createTransactionAdditionalChargesFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_FAILURE,
	payload: failureData,
});

export const createTransactionAdditionalChargesResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_RESET,
});

export const createTransactionAdditionalChargesResponseChanged = () => ({
	type: LoanDisbursementActionTypes.CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_CHANGED,
});

/** ***************** FETCH LOAN INDEX END *********************** */

/** ***************** FETCH LOAN INDEX START *********************** */
export const fetchNextTransactionSerialNoStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_START,
	payload: addData,
});

export const fetchNextTransactionSerialNoSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_SUCCESS,
	payload: successData,
});

export const fetchNextTransactionSerialNoFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_FAILURE,
	payload: failureData,
});

export const fetchNextTransactionSerialNoResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_RESET,
});

export const fetchNextTransactionSerialNoResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_CHANGED,
});

export const fetchProductAccountDetailsStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_START,
	payload: addData,
});

export const fetchProductAccountDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchProductAccountDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchProductAccountDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_RESET,
});

export const fetchProductAccountDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_CHANGED,
});

export const fetchVoucherCountBasedOnCompanyStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_START,
	payload: addData,
});

export const fetchVoucherCountBasedOnCompanySuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_SUCCESS,
	payload: successData,
});

export const fetchVoucherCountBasedOnCompanyFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_FAILURE,
	payload: failureData,
});

export const fetchVoucherCountBasedOnCompanyResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_RESET,
});

export const fetchVoucherCountBasedOnCompanyResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_CHANGED,
});

export const fetchInterBranchContraStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_START,
	payload: addData,
});

export const fetchInterBranchContraSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_SUCCESS,
	payload: successData,
});

export const fetchInterBranchContraFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_FAILURE,
	payload: failureData,
});

export const fetchInterBranchContraResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_RESPONSE_RESET,
});

export const fetchInterBranchContraResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_INTER_BRANCH_CONTRA_RESPONSE_CHANGED,
});

export const fetchLoanDisbursementPDFStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_START,
	payload: addData,
});

export const fetchLoanDisbursementPDFSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_SUCCESS,
	payload: successData,
});

export const fetchLoanDisbursementPDFFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_FAILURE,
	payload: failureData,
});

export const fetchLoanDisbursementPDFResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_RESPONSE_RESET,
});

export const fetchLoanDisbursementPDFResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_LOAN_DISBURSEMENT_PDF_RESPONSE_CHANGED,
});

export const fetchAllProductAdditionalAccountStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_START,
	payload: addData,
});

export const fetchAllProductAdditionalAccountSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_SUCCESS,
	payload: successData,
});

export const fetchAllProductAdditionalAccountFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_FAILURE,
	payload: failureData,
});

export const fetchAllProductAdditionalAccountResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_RESET,
});

export const fetchAllProductAdditionalAccountResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_CHANGED,
});

export const fetchAllRangeProductStart = (addData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_START,
	payload: addData,
});

export const fetchAllRangeProductSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_SUCCESS,
	payload: successData,
});

export const fetchAllRangeProductFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_FAILURE,
	payload: failureData,
});

export const fetchAllRangeProductResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_RESPONSE_RESET,
});

export const fetchAllRangeProductResponseChanged = () => ({
	type: LoanDisbursementActionTypes.FETCH_ALL_RANGE_PRODUCT_RESPONSE_CHANGED,
});

export const createTopupLoanIndexStart = (addData) => ({
	type: LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_START,
	payload: addData,
});

export const createTopupLoanIndexSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_SUCCESS,
	payload: successData,
});

export const createTopupLoanIndexFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_FAILURE,
	payload: failureData,
});

export const createTopupLoanIndexResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_RESPONSE_RESET,
});

export const createTopupLoanIndexResponseChanged = () => ({
	type: LoanDisbursementActionTypes.CREATE_TOPUP_LOAN_INDEX_RESPONSE_CHANGED,
});

/** ***************** FETCH LOAN INDEX END *********************** */
