/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import PreCloseLoanActionTypes from './preCloseLoan.types';

/** ***************** GET Close Loan START *********************** */
export const getPreCloseLoanDetailsStart = (getData) => ({
	type: PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const getPreCloseLoanDetailsSuccess = (successData) => ({
	type: PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getPreCloseLoanDetailsFailure = (failureData) => ({
	type: PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const getPreCloseLoanDetailsReset = () => ({
	type: PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_RESET,
});

export const getPreCloseLoanDetailsChanged = () => ({
	type: PreCloseLoanActionTypes.GET_PRE_CLOSE_LOAN_LIST_CHANGED,
});
/** ***************** GET Close Loan END *********************** */

/** ***************** UPDATE Close Loan START *********************** */
export const updatePreCloseLoanDetailsStart = (getData) => ({
	type: PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const updatePreCloseLoanDetailsSuccess = (successData) => ({
	type: PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const updatePreCloseLoanDetailsFailure = (failureData) => ({
	type: PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const updatePreCloseLoanDetailsReset = () => ({
	type: PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_RESET,
});

export const updatePreCloseLoanDetailsChanged = () => ({
	type: PreCloseLoanActionTypes.UPDATE_PRE_CLOSE_LOAN_LIST_CHANGED,
});
/** ***************** UPDATE Close Loan END *********************** */

/** ***************** GET Close Loan START *********************** */
export const getBranchEODStart = (getData) => ({
	type: PreCloseLoanActionTypes.GET_BRANCH_EOD_START,
	payload: getData,
});

export const getBranchEODSuccess = (successData) => ({
	type: PreCloseLoanActionTypes.GET_BRANCH_EOD_SUCCESS,
	payload: successData,
});

export const getBranchEODFailure = (failureData) => ({
	type: PreCloseLoanActionTypes.GET_BRANCH_EOD_FAILURE,
	payload: failureData,
});

export const getBranchEODReset = () => ({
	type: PreCloseLoanActionTypes.GET_BRANCH_EOD_RESET,
});

export const getBranchEODChanged = () => ({
	type: PreCloseLoanActionTypes.GET_BRANCH_EOD_CHANGED,
});
/** ***************** GET Close Loan END *********************** */


/** ***************** REVERT Close Loan START *********************** */
export const revertPreCloseLoanDetailsStart = (getData) => ({
	type: PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const revertPreCloseLoanDetailsSuccess = (successData) => ({
	type: PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const revertPreCloseLoanDetailsFailure = (failureData) => ({
	type: PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const revertPreCloseLoanDetailsReset = () => ({
	type: PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_RESET,
});

export const revertPreCloseLoanDetailsChanged = () => ({
	type: PreCloseLoanActionTypes.REVERT_PRE_CLOSE_LOAN_LIST_CHANGED,
});
/** ***************** REVERT Close Loan END *********************** */