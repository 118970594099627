const CoApplicantActionType = {
	ADD_CO_APPICANT_DETAILS_START: 'add_co_applicant_start',
	UPDATE_CO_APPICANT_DETAILS_START: 'update_co_applicant_details_start',
	REMOVE_CO_APPICANT_DETAILS_START: 'remove_co_applicant_start',
	UPDATE_CO_APPLICANT_ARR_START: 'update_co_appicant_arr_start',

	UPDATE_CO_APPLICANT_START: 'update_co_applicant_start',
	UPDATE_CO_APPLICANT_SUCCESS: 'update_co_applicant_success',
	UPDATE_CO_APPLICANT_FAILURE: 'update_co_applicant_failure',
	UPDATE_CO_APPLICANT_RESPONSE_REST_START: 'update_co_applicant_response_reset_start',
	UPDATE_CO_APPLICANT_RESPONSE_CHANGED: 'update_co_applicant_response_changed',

	ADD_COBORROWER_START: 'add_coborrower_start',
	ADD_COBORROWER_SUCCESS: 'add_coborrower_success',
	ADD_COBORROWER_FAILURE: 'add_coborrower_failure',
	ADD_COBORROWER_RESPONSE_RESET_START: 'add_coborrower_response_reset_start',
	ADD_COBORROWER_RESPONSE_CHANGED: 'add_coborrower_response_changed',

	FETCH_COBORROWER_START: 'fetch_coborrower_start',
	FETCH_COBORROWER_SUCCESS: 'fetch_coborrower_success',
	FETCH_COBORROWER_FAILURE: 'fetch_coborrower_failure',
	FETCH_COBORROWER_RESPONSE_RESET_START: 'fetch_coborrower_response_reset_start',
	FETCH_COBORROWER_RESPONSE_CHANGED: 'fetch_coborrower_response_changed',

	DELETE_CO_APPLICANT_START: 'delete_co_applicant_start',
	DELETE_CO_APPLICANT_SUCCESS: 'delete_co_applicant_success',
	DELETE_CO_APPLICANT_FAILURE: 'delete_co_applicant_failure',
	DELETE_CO_APPLICANT_RESPONSE_REST_START: 'delete_co_applicant_response_reset_start',
	DELETE_CO_APPLICANT_RESPONSE_CHANGED: 'delete_co_applicant_response_changed',

	COBORROWER_OBJECT_RESET: 'coborrower_object_reset',

	CHECK_COBORROWER_HIGHMARK_START: 'check_coborrower_highmark_start',
	CHECK_COBORROWER_HIGHMARK_SUCCESS: 'check_coborrower_highmark_success',
	CHECK_COBORROWER_HIGHMARK_FAILURE: 'check_coborrower_highmark_failure',
	CHECK_COBORROWER_HIGHMARK_RESPONSE_RESET_START:
		'check_coborrower_highmark_response_reset_start',
	CHECK_COBORROWER_HIGHMARK_RESPONSE_CHANGED: 'check_coborrower_highmark_response_changed',

	VERIFY_CO_APPLICANT_AADHAR_OTP_START: 'verify_co_applicant_aadhar_otp_start',
	VERIFY_CO_APPLICANT_AADHAR_OTP_SUCCESS: 'verify_co_applicant_aadhar_otp_success',
	VERIFY_CO_APPLICANT_AADHAR_OTP_FAILURE: 'verify_co_applicant_aadhar_otp_failure',
	VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_co_applicant_aadhar_otp_response_reset_start',
	VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_co_applicant_aadhar_otp_response_changed',

	VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_START: 'verify_validate_co_applicant_aadhar_otp_start',
	VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_SUCCESS:
		'verify_validate_co_applicant_aadhar_otp_success',
	VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_FAILURE:
		'verify_validate_co_applicant_aadhar_otp_failure',
	VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_validate_co_applicant_aadhar_otp_response_reset_start',
	VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_validate_co_applicant_aadhar_otp_response_changed',

	FETCH_DTEAILS_BASED_RELATIONSHIP_START: 'fetch_details_based_relationship_start',
	FETCH_DTEAILS_BASED_RELATIONSHIP_SUCCESS: 'fetch_details_based_relationship_success',
	FETCH_DTEAILS_BASED_RELATIONSHIP_FAILURE: 'fetch_details_based_relationship_failure',
	FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_RESET_START:
		'fetch_details_based_relationship_response_reset_start',
	FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_CHANGED:
		'fetch_details_based_relationship_response_changed',
};

export default CoApplicantActionType;
