/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanActionTypes from './custom-product-loan-proposal.types';

import { fetchAllCustomProductLoanProposalListGet } from './custom-product-loan-proposal.service';

import {
	getCustomProductLoanProposalFailure,
	getCustomProductLoanProposalSuccess,
	getCustomProductLoanProposalListResponseChanged,
} from './custom-product-loan-proposal.action';

import { encodeJson } from '../../../enode-decode';

/**   ******************* GET PRODUCT LOAN PROPOSAL *************** */
export function* customproductLoanProposalGet({ payload }) {
	try {
		const key = 'getCommonLoanIndexListKey';
		const jsonData = {
			BranchID: payload.branchID ? payload.branchID : 0,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			LoanIndexType: payload.loanIndexType,
			UserID: payload.userID,
			Level: payload.userLevel,
			UserRole: payload.userRole,
			LoginUserRoleID: payload.userRoleID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllCustomProductLoanProposalListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCustomProductLoanProposalFailure(responseData.data));
		} else {
			yield put(getCustomProductLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCustomProductLoanProposalFailure(error));
	}
}

export function* resetGetCustomProductLoanProposalResponse() {
	yield put(getCustomProductLoanProposalListResponseChanged());
}

export function* onGetCustomProductLoanProposal() {
	yield takeLatest(
		LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_START,
		customproductLoanProposalGet,
	);
}

export function* onGetCustomProductLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
		resetGetCustomProductLoanProposalResponse,
	);
}

export function* customproductLoanProposalSaga() {
	yield all([
		call(onGetCustomProductLoanProposal),
		call(onGetCustomProductLoanProposalResponseReset),
	]);
}
