const WalletReportActionTypes = {
	FETCH_GROUP_MEMBER_START: 'fetch_Group_Member_Start',
	FETCH_GROUP_MEMBER_SUCCESS: 'fetch_Group_Member_Success',
	FETCH_GROUP_MEMBER_FAILURE: 'fetch_Group_Member_Failure',
	FETCH_GROUP_MEMBER_RESPONSE_RESET: 'fetch_Group_Member_ResponseReset',
	FETCH_GROUP_MEMBER_RESPONSE_CHANGED: 'fetch_Group_Member_ResponseChanged',

	FETCH_WALLET_REPORT_START: 'fetch_wallet_report_Start',
	FETCH_WALLET_REPORT_SUCCESS: 'fetch_wallet_report_Success',
	FETCH_WALLET_REPORT_FAILURE: 'fetch_wallet_report_Failure',
	FETCH_WALLET_REPORT_RESPONSE_RESET: 'fetch_wallet_report_ResponseReset',
	FETCH_WALLET_REPORT_RESPONSE_CHANGED: 'fetch_wallet_report_ResponseChanged',
};
export default WalletReportActionTypes;
