/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import PersonalDetailsActionType from './personal-details.types';

const INITIAL_STATE = {
	personalDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PersonalDetailsActionType.PERSONAL_DETAILS_SUCCESS:
			return { ...state, personalDetailsResponse: action.payload };

		case PersonalDetailsActionType.PERSONAL_DETAILS_FAILURE:
			return { ...state, personalDetailsResponse: action.payload };

		case PersonalDetailsActionType.PERSONAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, personalDetailsResponse: null };

		default:
			return state;
	}
};
