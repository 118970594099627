/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import JWT from 'expo-jwt';
import swal from 'sweetalert';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import ButtonSpinner from '../../common/ButtonSpinner';
import Icon from '../../../components/icon/Icon';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	signInStart,
	signInReset,
	forgotPasswordStart,
	forgotPasswordReset,
} from '../../../redux/login/login.actions';
import { selectCurrentUser, selectForgotPasswordResp } from '../../../redux/login/login.selector';
import generalTypesAction from '../../../redux/genera.types';
import { dashboardMenu, demoPages } from '../../../menu';
import showNotification from '../../../components/extras/showNotification';
import { createLocalStore } from '../../../indexedDb/db';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome SquareNow</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [isSpinner, setIsSpinner] = useState(false);
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);
	const getForgotPasswordResp = useSelector(selectForgotPasswordResp);

	const navigate = useNavigate();
	useEffect(() => {
		if (getCurrentUserLoginDetails != null) {
			if (getCurrentUserLoginDetails.statusCode === '200') {
				const DecodeLoginData = JWT.decode(
					getCurrentUserLoginDetails.response,
					generalTypesAction.LOGIN_KEY,
				);

				if (DecodeLoginData.status === 'success') {
					if (DecodeLoginData.data[0].Company[0].ProvisionType === 'NBFC') {
						navigate(`${demoPages.masterPages.subMenu.business.path}`);
					} else if (DecodeLoginData.data[0].DatabaseName === 'SMCF') {
						navigate(`${demoPages.reportPages.subMenu.LeadReport.path}`);
					} else {
						navigate(`${dashboardMenu.dashboard.path}`);
					}

					// CREATE LOCAL INDEXED DB
					createLocalStore();
				}
			} else if (getCurrentUserLoginDetails.statusCode === '404') {
				swal('Cancelled', 'Wrong Mobile Number or Password.', 'error');

				dispatch(signInReset());
			}
			setIsSpinner(false);
		}

		if (getForgotPasswordResp != null) {
			if (getForgotPasswordResp.statusCode === '01') {
				swal('Success', 'Password Updated Successfully and sent to your Mail', 'success');
			} else if (getForgotPasswordResp.statusCode === '02') {
				swal('Cancelled', 'Please contact admin for login details.', 'error');
			} else if (getForgotPasswordResp.statusCode === '03') {
				swal('Cancelled', 'Phone Number does not exist.', 'error');
			} else {
				swal('Cancelled', 'Something went worng. Please try after sometime.', 'error');
			}
			dispatch(forgotPasswordReset());
		}
	}, [getCurrentUserLoginDetails, getForgotPasswordResp]);

	const formik = useFormik({
		initialValues: {
			phoneNumber: '',
			password: '',
			isView: false,
		},
		onSubmit: (values) => {
			if (values.phoneNumber !== '' && values.password !== '') {
				setIsSpinner(true);
				dispatch(signInStart({ values }));
			} else if (values.phoneNumber === '') {
				swal('Cancelled', 'Enter the Mobile Number', 'error');
			} else if (values.password === '') {
				swal('Cancelled', 'Enter the Password', 'error');
			}
		},
	});

	const ForgotPasswordCall = () => {
		if (formik.values.phoneNumber !== '') {
			const postData = { MobileNumber: formik.values.phoneNumber };
			dispatch(forgotPasswordStart(postData));
		} else {
			swal('Cancelled', 'Enter the Mobile Number', 'error');
		}
	};

	return (
		<PageWrapper title={isNewUser ? 'Sign Up' : 'Login'} className='bg-primary p-0 m-0'>
			<Page className='p-0 m-0 d-flex flex-row' container={null}>
				<div
					className='align-items-center justify-content-center'
					style={{ width: '50%', height: '100vh', alignContent: 'center' }}>
					<div className='text-center'>
						<Link to='/' className='text-light'>
							<img
								src={require('../../../assets/logos/SquareNow.png')}
								alt='Staff'
								className='roundPic'
							/>
						</Link>
					</div>

					<br />

					<div className='text-center text-light h5 fw-bold'>
						Powering Next Gen Financial Institution
					</div>

					<div className='text-center text-light h6 fw-bold'>
						Providing Best in class solution in NBFC, Microfinance & Digital Lending
						Solution
					</div>

					<div className='text-center'>
						<img
							src={require('../../../assets/img/cybersecurity.png')}
							alt='Staff'
							className='roundPic'
							width={350}
							height={210}
						/>
					</div>
				</div>

				<div style={{ width: '50%' }}>
					<div
						className='bg-white align-items-center justify-content-center'
						style={{
							height: '100vh',
							borderTopLeftRadius: '50px',
							borderBottomLeftRadius: '50px',
							alignContent: 'center',
						}}>
						<div className='text-center h1 fw-bold'>Welcome SquareNow</div>
						<div className='text-center h4 text-muted'>Sign in to continue!</div>
						<form
							className='d-flex flex-column align-items-center'
							style={{ justifyContent: 'center', height: '200px' }}
							onSubmit={formik.handleSubmit}>
							<div className='col-5 mt-4'>

								<InputGroup>
									<Button color='primary' icon='Phone Android' />
									<Input
										id='login-username'
										type='tel'
										autoComplete='tel'
										placeholder='Enter Mobile Number'
										name='phoneNumber'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur('ph')}
										value={(formik.values.phoneNumber || '').replace(
											/[^0-9]/g,
											'',
										)}
										isTouched={formik.touched.Department}
										maxLength='10'
									/>
								</InputGroup>
							</div>

							<div className='col-5 mt-4'>


								<InputGroup>
									<Button color="primary" icon="lock" />

									<Input
										type={formik.values.isView ? 'text' : 'password'}
										value={formik.values.password}
										onChange={formik.handleChange}
										isTouched={formik.touched.Department}
										name="password"
										placeholder="Enter Password"
									/>

									<Button
										color="primary"
										icon={formik.values.isView ? 'VisibilityOff' : 'Visibility'}
										onMouseDown={() => formik.setFieldValue('isView', true)} // Show password on mouse down
										onMouseUp={() => formik.setFieldValue('isView', false)} // Hide password on mouse up
										onMouseLeave={() => formik.setFieldValue('isView', false)} // Hide password when mouse leaves the button
									>
										{' '}
									</Button>
								</InputGroup>
							</div>
							<div className='col-5 mt-5 mb-3'>
								<Button
									color='primary'
									type='submit'
									className='w-100 py-3'
									icon={!isSpinner ? 'Save' : ''}
									isDisable={isSpinner}>
									{isSpinner ? <ButtonSpinner /> : ''}
									Sign in
								</Button>
							</div>
						</form>

						<div className='text-center'>
							<Button
								color='link'
								type='submit'
								className='w-100 py-3'
								onClick={() => ForgotPasswordCall()}>
								Forgot Password
							</Button>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
