/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DailyCollectionReportActionTypes from './dailyCollectionReport.types';

import { dailyCollectionReportListGet } from './dailyCollectionReport.service';

import {
	getDailyCollectionReportDetailsSuccess,
	getDailyCollectionReportDetailsFailure,
	getDailyCollectionReportDetailsResponseChanged,
} from './dailyCollectionReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET Daily COLLECTION REPORT DETAILS  ******************** */
export function* getDailyCollectionReportList({ payload }) {
	try {
		const key = 'DailyCollectionReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			userID: payload.userID,
			userlevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dailyCollectionReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDailyCollectionReportDetailsFailure(responseData.data));
		} else {
			yield put(getDailyCollectionReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDailyCollectionReportDetailsFailure(error));
	}
}

export function* resetgetDailyCollectionReportListResponse() {
	yield put(getDailyCollectionReportDetailsResponseChanged());
}

export function* onGetDailyCollectionReportListDetails() {
	yield takeLatest(
		DailyCollectionReportActionTypes.GET_DAILY_COLLECTION_REPORT_LIST_START,
		getDailyCollectionReportList,
	);
}

export function* onGetDailyCollectionReportListResponseReset() {
	yield takeLatest(
		DailyCollectionReportActionTypes.GET_DAILY_COLLECTION_REPORT_LIST_RESPONSE_RESET,
		resetgetDailyCollectionReportListResponse,
	);
}
/** *********************  END GET Daily COLLECTION REPORT DETAILS ******************** */

export function* dailyCollectionReportSaga() {
	yield all([
		call(onGetDailyCollectionReportListDetails),
		call(onGetDailyCollectionReportListResponseReset),
	]);
}
