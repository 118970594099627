/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AssignUserRightsTypes from './assignUserRights.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	createUserAssignControlRightsPageResponse: null,
	updateUserAssignControlRightsPageResponse: null,
	saveUserAssignControlRightsForAllPageResponse: null,
	fetchListUserControlDetailsResponse: null,
	fetchListUserControlDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS:
			return { ...state, createUserAssignControlRightsPageResponse: action.payload };

		case AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE:
			return { ...state, createUserAssignControlRightsPageResponse: action.payload };

		case AssignUserRightsTypes.CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED:
			return { ...state, createUserAssignControlRightsPageResponse: null };

		case AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS:
			return { ...state, updateUserAssignControlRightsPageResponse: action.payload };

		case AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE:
			return { ...state, updateUserAssignControlRightsPageResponse: action.payload };

		case AssignUserRightsTypes.UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED:
			return { ...state, updateUserAssignControlRightsPageResponse: null };

		case AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_SUCCESS:
			return { ...state, saveUserAssignControlRightsForAllPageResponse: action.payload };

		case AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_FAILURE:
			return { ...state, saveUserAssignControlRightsForAllPageResponse: action.payload };

		case AssignUserRightsTypes.SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_CHANGED:
			return { ...state, saveUserAssignControlRightsForAllPageResponse: null };

		case AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_SUCCESS:
			return {
				...state,
				fetchListUserControlDetailsResponse: action.payload,
				fetchListUserControlDetailsArr: docodeResponse(
					action.payload,
					'FetchUserControlDetailsKey',
				),
			};

		case AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_FAILURE:
			return { ...state, fetchListUserControlDetailsResponse: action.payload };

		case AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchListUserControlDetailsResponse: null };

		case AssignUserRightsTypes.FETCH_LIST_USER_CONTROL_DETAILS_ARR_CHANGED:
			return { ...state, fetchListUserControlDetailsArr: [] };

		default:
			return state;
	}
};
