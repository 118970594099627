// eslint-disable-next-line import/no-unresolved
import PassbookChequeDetailsActionType from './passbook-cheque-details.types';

// update PassbookCheque
export const updatePassbookChequeStart = (passbookChequeCredentials) => ({
	type: PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_START,
	payload: passbookChequeCredentials,
});

export const updatePassbookChequeSuccess = (passbookChequeSuccess) => ({
	type: PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_SUCCESS,
	payload: passbookChequeSuccess,
});

export const updatePassbookChequeFailure = (passbookChequeFailure) => ({
	type: PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_FAILURE,
	payload: passbookChequeFailure,
});

export const updatePassbookChequeResponseResetStart = () => ({
	type: PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_RESPONSE_REST_START,
});

export const updatePassbookChequeResponseChanged = () => ({
	type: PassbookChequeDetailsActionType.UPDATE_PASSBOOK_CHEQUE_RESPONSE_CHANGED,
});
