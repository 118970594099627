/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const UserLogin = (loginData) => {
	const data = {
		LoginCheckToken: loginData,
	};
	const url = `${API_URL}LoginUserToken`;
	// eslint-disable-next-line no-debugger
	// debugger;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const forgotPasswordUpdate = (addData) => {
	const data = {
		ForgotPasswordToken: addData,
	};
	const url = `${API_URL}ForgotPasswordDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// USER DETAILS SECTION
export const getUserDetailsCall = (encodeData) => {
	const data = {
		FetchUserDetailsToken: encodeData,
	};
	const url = `${API_URL}GetUserDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// UPDATE USER PASSWORD DETAILS SECTION
export const updateUserPasswordCall = (encodeData) => {
	const data = {
		UpdateUserPasswordToken: encodeData,
	};
	const url = `${API_URL}UpdatePassword`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
