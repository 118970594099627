/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchSearchMemberLoanList = (listData) => {
	const data = {
		SearchMemberLoanListDataToken: listData,
	};
	const url = `${API_URL}GetSearchForMemberLoanListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchMemberLoanInfo = (listData) => {
	const data = {
		LoanInfoBasedOnMemeberLoanDataToken: listData,
	};
	const url = `${API_URL}GetLoanInfoBasedOnMemeberLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberCollectionUpdateAPI = (listData) => {
	const data = {
		UpdateMemberListData: listData,
	};
	const url = `${API_URL}UpdateMemberLoanPaidForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDeathUpdateAPI = (listData) => {
	const data = {
		UpdateMemberDeathData: listData,
	};
	const url = `${API_URL}UpdateMemberDeathToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const interestWaiverUpdateAPI = (listData) => {
	const data = {
		UpdateInterestWaiverData: listData,
	};
	const url = `${API_URL}UpdateInterestWaiverToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberCollectionFetchAPI = (listData) => {
	const data = {
		FetchMemberCollectionData: listData,
	};
	const url = `${API_URL}FetchMemberLoanPaidForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberWalletFetchAPI = (listData) => {
	const data = {
		FetchMemberWalletData: listData,
	};
	const url = `${API_URL}FetchMemberWalletForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberQrFetchAPI = (listData) => {
	const data = {
		FetchMemberQrData: listData,
	};
	const url = `${API_URL}FetchMemberQrForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberRepaymentFetchAPI = (listData) => {
	const data = {
		FetchMemberRepaymentData: listData,
	};
	const url = `${API_URL}FetchMemberRepaymentForSelectedMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberWalletRevertAPI = (listData) => {
	const data = {
		RevertMemberWalletData: listData,
	};
	const url = `${API_URL}RevertMemberWalletForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberCollectionRevertAPI = (listData, serviceName) => {
	const data = {
		RevertMemberListData: listData,
	};
	const url = `${API_URL}${serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
