/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const branchDCBReportListGet = (listData) => {
	const data = {
		GetBranchDCBReportToken: listData,
	};
	const url = `${API_URL}GetBranchDCBReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDCBReportExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchBranchDCBReportToken', listData);
	const url = `${API_URL}Report/Excel/BranchDCBReportExportToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
