const ProfileTypes = {
	GET_PROFILE_DETAILS_START: 'get_profile_details_start',
	GET_PROFILE_DETAILS_SUCCESS: 'get_profile_details_success',
	GET_PROFILE_DETAILS_FAILURE: 'get_profile_details_failure',
	GET_PROFILE_DETAILS_RESPONSE_RESET: 'get_profile_details_response_reset',
	GET_PROFILE_DETAILS_RESPONSE_CHANGED: 'get_profile_details_response_changed',

	UPDATE_PROFILE_DETAILS_START: 'update_profile_details_start',
	UPDATE_PROFILE_DETAILS_SUCCESS: 'update_profile_details_success',
	UPDATE_PROFILE_DETAILS_FAILURE: 'update_profile_details_failure',
	UPDATE_PROFILE_DETAILS_RESPONSE_RESET: 'update_profile_details_response_reset',
	UPDATE_PROFILE_DETAILS_RESPONSE_CHANGED: 'update_profile_details_response_changed',

	UPDATE_PASSWORD_START: 'update_password_start',
	UPDATE_PASSWORD_SUCCESS: 'update_password_success',
	UPDATE_PASSWORD_FAILURE: 'update_password_failure',
	UPDATE_PASSWORD_RESPONSE_RESET: 'update_password_response_reset',
	UPDATE_PASSWORD_RESPONSE_CHANGED: 'update_password_response_changed',

	GENERATE_SECRETE_AUTHKEY_START: 'generate_secrete_authkey_start',
	GENERATE_SECRETE_AUTHKEY_SUCCESS: 'generate_secrete_authkey_success',
	GENERATE_SECRETE_AUTHKEY_FAILURE: 'generate_secrete_authkey_failure',
	GENERATE_SECRETE_AUTHKEY_RESPONSE_RESET: 'generate_secrete_authkey_response_reset',
	GENERATE_SECRETE_AUTHKEY_RESPONSE_CHANGED: 'generate_secrete_authkey_response_changed',
};

export default ProfileTypes;
