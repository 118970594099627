/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import memberCollectionActionTypes from './memberCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getSearchMemberLoanListStatus: null,
	getSearchMemberLoanListResponse: null,
	getSearchMemberLoanListArr: [],
	getMemberLoanInfoResponse: null,
	getMemberLoanInfoArr: [],
	updatedMemberCollectionResponse: null,
	fetchMemberCollectionResponse: null,
	fetchMemberCollectionArr: [],
	revertMemberCollectionResponse: null,
	fetchMemberWalletArr: [],
	fetchMemberWalletResponse: null,
	fetchMemberQrArr: [],
	fetchMemberQrResponse: null,
	fetchMemberRepaymentArr: [],
	fetchMemberRepaymentResponse: null,
	revertMemberWalletResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS:
			return {
				...state,
				getSearchMemberLoanListStatus: 'SUCCESS',
				getSearchMemberLoanListResponse: action.payload,
				getSearchMemberLoanListArr: docodeResponse(
					action.payload,
					'searchMemberLoanListKey',
				),
			};

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_FAILURE:
			return { ...state, getSearchMemberLoanListStatus: 'FAILURE', getSearchMemberLoanListResponse: action.payload };

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getSearchMemberLoanListStatus: null, getSearchMemberLoanListResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_SUCCESS:
			return {
				...state,
				getMemberLoanInfoResponse: action.payload,
				getMemberLoanInfoArr: docodeResponse(action.payload, 'LoanInfoMemberLoanListKey'),
			};

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_FAILURE:
			return { ...state, getMemberLoanInfoResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED:
			return { ...state, getMemberLoanInfoResponse: null };

		case memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_SUCCESS:
			return { ...state, updatedMemberCollectionResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_FAILURE:
			return { ...state, updatedMemberCollectionResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_MEMBER_COLLECTION_RESPONSE_CHANGED:
			return { ...state, updatedMemberCollectionResponse: null };

		case memberCollectionActionTypes.UPDATE_MEMBER_DEATH_SUCCESS:
			return { ...state, updatedMemberDeathResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_MEMBER_DEATH_FAILURE:
			return { ...state, updatedMemberDeathResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_MEMBER_DEATH_RESPONSE_CHANGED:
			return { ...state, updatedMemberDeathResponse: null };

		case memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_SUCCESS:
			return { ...state, updatedInterestWaiverResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_FAILURE:
			return { ...state, updatedInterestWaiverResponse: action.payload };

		case memberCollectionActionTypes.UPDATE_INTEREST_WAIVER_RESPONSE_CHANGED:
			return { ...state, updatedInterestWaiverResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_COLLECTION_SUCCESS:
			return {
				...state,
				fetchMemberCollectionResponse: action.payload,
				fetchMemberCollectionArr: docodeResponse(
					action.payload,
					'FetchMemberCollectionDataKey',
				),
			};

		case memberCollectionActionTypes.GET_MEMBER_COLLECTION_FAILURE:
			return { ...state, fetchMemberCollectionResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_COLLECTION_RESPONSE_CHANGED:
			return { ...state, fetchMemberCollectionResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_WALLET_SUCCESS:
			return {
				...state,
				fetchMemberWalletResponse: action.payload,
				fetchMemberWalletArr: docodeResponse(action.payload, 'FetchMemberWalletDataKey'),
			};

		case memberCollectionActionTypes.GET_MEMBER_WALLET_FAILURE:
			return { ...state, fetchMemberWalletResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_WALLET_RESPONSE_CHANGED:
			return { ...state, fetchMemberWalletResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_QR_SUCCESS:
			return {
				...state,
				fetchMemberQrResponse: action.payload,
				fetchMemberQrArr: docodeResponse(action.payload, 'FetchMemberQrDataKey'),
			};

		case memberCollectionActionTypes.GET_MEMBER_QR_FAILURE:
			return { ...state, fetchMemberQrResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_QR_RESPONSE_CHANGED:
			return { ...state, fetchMemberQrResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_REPAYMENT_SUCCESS:
			return {
				...state,
				fetchMemberRepaymentResponse: action.payload,
				fetchMemberRepaymentArr: docodeResponse(
					action.payload,
					'FetchMemberRepaymentDataKey',
				),
			};

		case memberCollectionActionTypes.GET_MEMBER_REPAYMENT_FAILURE:
			return { ...state, fetchMemberRepaymentResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_REPAYMENT_RESPONSE_CHANGED:
			return { ...state, fetchMemberRepaymentResponse: null };

		case memberCollectionActionTypes.DELETE_MEMBER_WALLET_SUCCESS:
			return { ...state, revertMemberWalletResponse: action.payload };

		case memberCollectionActionTypes.DELETE_MEMBER_WALLET_FAILURE:
			return { ...state, revertMemberWalletResponse: action.payload };

		case memberCollectionActionTypes.DELETE_MEMBER_WALLET_RESPONSE_CHANGED:
			return { ...state, revertMemberWalletResponse: null };

		case memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_SUCCESS:
			return { ...state, revertMemberCollectionResponse: action.payload };

		case memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_FAILURE:
			return { ...state, revertMemberCollectionResponse: action.payload };

		case memberCollectionActionTypes.REVERT_MEMBER_COLLECTION_RESPONSE_CHANGED:
			return { ...state, revertMemberCollectionResponse: null };

		default:
			return state;
	}
};
