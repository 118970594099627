import OfficialDetailsActionType from './official-details.types';

// Official Details
export const officialDetailsAddStart = (officialDetailsCredentials) => ({
	type: OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_START,
	payload: officialDetailsCredentials,
});

export const officialDetailsAddSuccess = (officialDetailsSuccess) => ({
	type: OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_SUCCESS,
	payload: officialDetailsSuccess,
});

export const officialDetailsAddFailure = (officialDetailsFailure) => ({
	type: OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_FAILURE,
	payload: officialDetailsFailure,
});

export const officialDetailsAddResponseResetStart = () => ({
	type: OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_RESPONSE_REST_START,
});

export const officialDetailsAddUserResponseChanged = () => ({
	type: OfficialDetailsActionType.OFFICIAL_DETAILS_ADD_RESPONSE_CHANGED,
});

// Economic Activity Details
export const economicActivityListStart = (economicActivityCredentials) => ({
	type: OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_START,
	payload: economicActivityCredentials,
});

export const economicActivityListSuccess = (economicActivitySuccess) => ({
	type: OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_SUCCESS,
	payload: economicActivitySuccess,
});

export const economicActivityListFailure = (economicActivityFailure) => ({
	type: OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_FAILURE,
	payload: economicActivityFailure,
});

export const economicActivityListResponseResetStart = () => ({
	type: OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_RESPONSE_REST_START,
});

export const economicActivityListUserResponseChanged = () => ({
	type: OfficialDetailsActionType.ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED,
});

// Center Details
export const centerListStart = (centerCredentials) => ({
	type: OfficialDetailsActionType.CENTER_LIST_START,
	payload: centerCredentials,
});

export const centerListSuccess = (centerSuccess) => ({
	type: OfficialDetailsActionType.CENTER_LIST_SUCCESS,
	payload: centerSuccess,
});

export const centerListFailure = (centerFailure) => ({
	type: OfficialDetailsActionType.CENTER_LIST_FAILURE,
	payload: centerFailure,
});

export const centerListResponseResetStart = () => ({
	type: OfficialDetailsActionType.CENTER_LIST_RESPONSE_REST_START,
});

export const centerListUserResponseChanged = () => ({
	type: OfficialDetailsActionType.CENTER_LIST_RESPONSE_CHANGED,
});

// Product Type List Details
export const productTypeListStart = (productTypeCredentials) => ({
	type: OfficialDetailsActionType.PRODUCT_TYPE_LIST_START,
	payload: productTypeCredentials,
});

export const productTypeListSuccess = (productTypeSuccess) => ({
	type: OfficialDetailsActionType.PRODUCT_TYPE_LIST_SUCCESS,
	payload: productTypeSuccess,
});

export const productTypeListFailure = (productTypeFailure) => ({
	type: OfficialDetailsActionType.PRODUCT_TYPE_LIST_FAILURE,
	payload: productTypeFailure,
});

export const productTypeListResetStart = () => ({
	type: OfficialDetailsActionType.PRODUCT_TYPE_LIST_RESPONSE_RESET_START,
});

export const productTypeListResponseChanged = () => ({
	type: OfficialDetailsActionType.PRODUCT_TYPE_LIST_RESPONSE_CHANGED,
});
