/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getAllCountriesList = (country) => {
	const data = {
		GetCountryListToken: country,
	};
	const url = `${API_URL}fetchCountryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllStateList = (state) => {
	const data = {
		CountryStateListGetToken: state,
	};
	const url = `${API_URL}fetchCountryStateList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllCityList = (city) => {
	const data = {
		StateCityListGetToken: city,
	};
	const url = `${API_URL}fetchStateCityList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllPincodeList = (postCodeData) => {
	const data = postCodeData;
	const url = `https://api.postalpincode.in/pincode/${data}`;
	return axios.get(url);
};

export const getDistrictList = (district) => {
	const data = {
		DistrictCityListGetToken: district,
	};
	const url = `${API_URL}fetchStateDistrictList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllIndustryList = (fetchIndustryData) => {
	const data = {
		FetchIndustryToken: fetchIndustryData,
	};
	const url = `${API_URL}fetchIndustryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getSubIndustryList = (subIndustryData) => {
	const data = {
		FetchSubIndustryListToken: subIndustryData,
	};
	const url = `${API_URL}fetchSubIndustryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBranchesFetch = (fetchBranchesData) => {
	const data = {
		FetchAllBranchKeyToken: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllBranches`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allPurposeOfLoanTypeFetch = (fetchPurposeOfLoanTypeData) => {
	const data = {
		FetchAllPurposeOfLoanTypeListData: fetchPurposeOfLoanTypeData,
	};
	const url = `${API_URL}fetchAllPurposeOfLoanTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allPurposeOfLoanListFetch = (fetchPurposeOfLoanTypeData) => {
	const data = {
		FetchAllPurposeOfLoanListToken: fetchPurposeOfLoanTypeData,
	};
	const url = `${API_URL}fetchAllPurposeOfLoanList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBusinessDetailsFetch = (fetchBusinessListData) => {
	const data = {
		GetAllBusinessListToken: fetchBusinessListData,
	};
	const url = `${API_URL}getAllBusinessList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allProductTypeFetch = (fetchBranchesData) => {
	const data = {
		FetchAllProductTypeListToken: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllProductTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allProductsFetch = (fetchAllProductData) => {
	const data = {
		FetchAllProductListToken: fetchAllProductData,
	};
	const url = `${API_URL}GetAllProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBranchOfficerFetch = (fetchBranchOfficerData) => {
	const data = {
		GetBranchAllOfficerListToken: fetchBranchOfficerData,
	};
	const url = `${API_URL}fetchAllBranchOfficer`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allCenterFetch = (fetchCenterData) => {
	const data = {
		GetAllCenterListToken: fetchCenterData,
	};
	const url = `${API_URL}fetchAllCenterList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allInvestorFetch = (fetchData) => {
	const data = {
		GetAllInvestorListToken: fetchData,
	};
	const url = `${API_URL}fetchInvestorList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchallGroup = (fetchData) => {
	const data = {
		GetAllGroupListToken: fetchData,
	};
	const url = `${API_URL}fetchAllGroupList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGroupCenterFetch = (fetchGroupCenterData) => {
	const data = {
		FetchAllGroupKeyToken: fetchGroupCenterData,
	};
	const url = `${API_URL}GetAllGroupOnLocation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGroupMembersFetch = (fetchGroupMemberData) => {
	const data = {
		GetGroupMemberListToken: fetchGroupMemberData,
	};
	const url = `${API_URL}GetGroupMemberListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchAccountListFetch = (fetchBranchAccountListData) => {
	const data = {
		BranchAccountPostDataToken: fetchBranchAccountListData,
	};
	const url = `${API_URL}GetCompanyFinanceAccountTypeListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGroupLoanIndexListAPI = (encodeData) => {
	const data = {
		GetAllLoanIndexListToken: encodeData,
	};
	const url = `${API_URL}GetLoanIndexListBasedOnGroup`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchSearchLoanIndexListAPI = (encodeData) => {
	const data = {
		SearchLoanIndexDetailsTokenDataToken: encodeData,
	};
	const url = `${API_URL}SearchLoanIndexDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchParticularLoanIndexListAPI = (encodeData) => {
	const data = {
		GetParticularLoanIndexToken: encodeData,
	};
	const url = `${API_URL}GetParticularLaonIndexInfoWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const VersionDetailsFetch = (posatData) => {
	const data = {
		GetVersionDataToken: posatData,
	};
	const url = `${API_URL}GetVersionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const VersionDetailsUpdate = (posatData) => {
	const data = {
		UpdateVersionDataToken: posatData,
	};
	const url = `${API_URL}UpdateVersionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const PincodeDetailsFetch = (posatData) => {
	const data = {
		FetchPincodeListToken: posatData,
	};
	const url = `${API_URL}FetchPincodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllLocationListBasedLevel = (encodeData) => {
	const data = {
		AllLocationBasedLevelToken: encodeData,
	};
	const url = `${API_URL}GetAllLocationListBasedLevel`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkAuthenticationKeyAPI = (encodeData) => {
	const data = {
		CheckUserAuthToken: encodeData,
	};
	const url = `${API_URL}CheckAuthentication`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
