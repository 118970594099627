const PreCloseLoanActionTypes = {
	GET_PRE_CLOSE_LOAN_LIST_START: 'GET_PRE_CLOSE_LOAN_LIST_START',
	GET_PRE_CLOSE_LOAN_LIST_SUCCESS: 'GET_PRE_CLOSE_LOAN_LIST_SUCCESS',
	GET_PRE_CLOSE_LOAN_LIST_FAILURE: 'GET_PRE_CLOSE_LOAN_LIST_FAILURE',
	GET_PRE_CLOSE_LOAN_LIST_RESET: 'GET_PRE_CLOSE_LOAN_LIST_RESET',
	GET_PRE_CLOSE_LOAN_LIST_CHANGED: 'GET_PRE_CLOSE_LOAN_LIST_CHANGED',

	UPDATE_PRE_CLOSE_LOAN_LIST_START: 'UPDATE_PRE_CLOSE_LOAN_LIST_START',
	UPDATE_PRE_CLOSE_LOAN_LIST_SUCCESS: 'UPDATE_PRE_CLOSE_LOAN_LIST_SUCCESS',
	UPDATE_PRE_CLOSE_LOAN_LIST_FAILURE: 'UPDATE_PRE_CLOSE_LOAN_LIST_FAILURE',
	UPDATE_PRE_CLOSE_LOAN_LIST_RESET: 'UPDATE_PRE_CLOSE_LOAN_LIST_RESET',
	UPDATE_PRE_CLOSE_LOAN_LIST_CHANGED: 'UPDATE_PRE_CLOSE_LOAN_LIST_CHANGED',

	GET_BRANCH_EOD_START: 'GET_BRANCH_EOD_START',
	GET_BRANCH_EOD_SUCCESS: 'GET_BRANCH_EOD_SUCCESS',
	GET_BRANCH_EOD_FAILURE: 'GET_BRANCH_EOD_FAILURE',
	GET_BRANCH_EOD_RESET: 'GET_BRANCH_EOD_RESET',
	GET_BRANCH_EOD_CHANGED: 'GET_BRANCH_EOD_CHANGED',

	REVERT_PRE_CLOSE_LOAN_LIST_START: 'REVERT_PRE_CLOSE_LOAN_LIST_START',
	REVERT_PRE_CLOSE_LOAN_LIST_SUCCESS: 'REVERT_PRE_CLOSE_LOAN_LIST_SUCCESS',
	REVERT_PRE_CLOSE_LOAN_LIST_FAILURE: 'REVERT_PRE_CLOSE_LOAN_LIST_FAILURE',
	REVERT_PRE_CLOSE_LOAN_LIST_RESET: 'REVERT_PRE_CLOSE_LOAN_LIST_RESET',
	REVERT_PRE_CLOSE_LOAN_LIST_CHANGED: 'REVERT_PRE_CLOSE_LOAN_LIST_CHANGED',
};
export default PreCloseLoanActionTypes;
