import { takeLatest, put, all, call } from 'redux-saga/effects';
import DrivingLicenceActionType from './driving-licence-details.types';
import {
	updateDrivingLicenceSuccess,
	updateDrivingLicenceFailure,
	updateDrivingLicenceResponseChanged,
} from './driving-licence-details.action';
import { encodeJson } from '../../../enode-decode';
import { drivingLicenceUpdate } from './driving-licence-details.service';

// Start  Driving Licence Add
export function* updateDrivingLicenceId({ payload }) {
	try {
		const key = 'updateDrivingLicenceID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			DrivingLicenceFrontImg: payload.drivingLicenceFrontImg,
			DrivingLicenceBackImg: payload.drivingLicenceBackImg,
			ProofType: payload.proofType,
			DocumantType: payload.docType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const rationCardData = encodeJson(jsonData, key);
		const responseData = yield call(drivingLicenceUpdate, rationCardData);
		if (responseData.data.statusCode === '100') {
			yield put(updateDrivingLicenceFailure(responseData.data));
		} else {
			yield put(updateDrivingLicenceSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateDrivingLicenceFailure(error));
	}
}

export function* updateDrivingLicenceResponseClear() {
	yield put(updateDrivingLicenceResponseChanged());
}

export function* onDrivingLicenceUpdateStart() {
	yield takeLatest(DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_START, updateDrivingLicenceId);
}

export function* onDrivingLicenceUpdateResponseRest() {
	yield takeLatest(
		DrivingLicenceActionType.UPDATE_DRIVING_LICENCE_RESPONSE_REST_START,
		updateDrivingLicenceResponseClear,
	);
}
// End  Driving Licence Add

export function* drivingLicenceSaga() {
	yield all([call(onDrivingLicenceUpdateStart), call(onDrivingLicenceUpdateResponseRest)]);
}
