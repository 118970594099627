const ChangeGroupOficerActionTypes = {
	GET_BRANCH_OFFICER_LIST_START: 'get_branch_officer_start',
	GET_BRANCH_OFFICER_LIST_SUCCESS: 'get_branch_officer_success',
	GET_BRANCH_OFFICER_LIST_FAILURE: 'get_branch_officer_failure',
	GET_BRANCH_OFFICER_LIST_RESPONSE_RESET: 'get_branch_officer_response_reset',
	GET_BRANCH_OFFICER_LIST_RESPONSE_CHANGED: 'get_branch_officer_response_changed',

	GET_CHANGE_BRANCH_OFFICER_LIST_START: 'get_change_branch_officer_start',
	GET_CHANGE_BRANCH_OFFICER_LIST_SUCCESS: 'get_change_branch_officer_success',
	GET_CHANGE_BRANCH_OFFICER_LIST_FAILURE: 'get_change_branch_officer_failure',
	GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_RESET: 'get_change_branch_officer_response_reset',
	GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_CHANGED: 'get_change_branch_officer_response_changed',

	GET_OFFICER_CENTER_LIST_START: 'get_officer_center_start',
	GET_OFFICER_CENTER_LIST_SUCCESS: 'get_officer_center_success',
	GET_OFFICER_CENTER_LIST_FAILURE: 'get_officer_center_failure',
	GET_OFFICER_CENTER_LIST_RESPONSE_RESET: 'get_officer_center_response_reset',
	GET_OFFICER_CENTER_LIST_RESPONSE_CHANGED: 'get_officer_center_response_changed',

	GET_LOAN_GROUP_LIST_START: 'get_loan_group_start',
	GET_LOAN_GROUP_LIST_SUCCESS: 'get_loan_group_success',
	GET_LOAN_GROUP_LIST_FAILURE: 'get_loan_group_failure',
	GET_LOAN_GROUP_LIST_RESPONSE_RESET: 'get_loan_group_response_reset',
	GET_LOAN_GROUP_LIST_RESPONSE_CHANGED: 'get_loan_group_response_changed',

	UPDATE_CHANGE_GROUP_OFFICER_LIST_START: 'update_change_group_officer_start',
	UPDATE_CHANGE_GROUP_OFFICER_LIST_SUCCESS: 'update_change_group_officer_success',
	UPDATE_CHANGE_GROUP_OFFICER_LIST_FAILURE: 'update_change_group_officer_failure',
	UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_RESET: 'update_change_group_officer_response_reset',
	UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_CHANGED:
		'update_change_group_officer_response_changed',

	UPDATE_CHANGE_PROPOSED_OFFICER_LIST_START: 'update_change_proposed_officer_start',
	UPDATE_CHANGE_PROPOSED_OFFICER_LIST_SUCCESS: 'update_change_proposed_officer_success',
	UPDATE_CHANGE_PROPOSED_OFFICER_LIST_FAILURE: 'update_change_proposed_officer_failure',
	UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_RESET: 'update_change_proposed_officer_response_reset',
	UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_CHANGED:
		'update_change_proposed_officer_response_changed',

	UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_START: 'update_change_center_loan_officer_start',
	UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_SUCCESS: 'update_change_center_loan_officer_success',
	UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_FAILURE: 'update_change_center_loan_officer_failure',
	UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_RESET: 'update_change_center_loan_officer_response_reset',
	UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_CHANGED:
		'update_change_center_loan_officer_response_changed',
};
export default ChangeGroupOficerActionTypes;
