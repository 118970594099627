/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import DeadMemberDetailsActionTypes from './dead-member-report.types';

/** ***************** GET DEAD MEMBER REPORT DETAILS START *********************** */
export const getDeadMemberDetailsDetailsStart = (getData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_START,
	payload: getData,
});

export const getDeadMemberDetailsDetailsSuccess = (successData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_SUCCESS,
	payload: successData,
});

export const getDeadMemberDetailsDetailsFailure = (failureData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_FAILURE,
	payload: failureData,
});

export const getDeadMemberDetailsDetailsResponseResetStart = () => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_RESET,
});

export const getDeadMemberDetailsDetailsResponseChanged = () => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET DEAD MEMBER REPORT DETAILS END *********************** */

/** ***************** GET DEAD MEMBER EXPORT REPORT DETAILS START *********************** */
export const getDeadMemberDetailsExportStart = (getData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_START,
	payload: getData,
});

export const getDeadMemberDetailsExportSuccess = (successData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_SUCCESS,
	payload: successData,
});

export const getDeadMemberDetailsExportFailure = (failureData) => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_FAILURE,
	payload: failureData,
});

export const getDeadMemberDetailsExportResponseResetStart = () => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_RESET,
});

export const getDeadMemberDetailsExportResponseChanged = () => ({
	type: DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_CHANGED,
});
/** ***************** GET DEAD MEMBER EXPORT REPORT DETAILS END *********************** */