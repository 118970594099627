/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import CreditCheckActionType from './credit-check.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	creditCheckMemberAddResponse: null,
	creditCheckMemberAddResponseList: [],
	creditCheckCallResponse: null,
	creditCheckCallArr: [],
	equifaxCheckCallResponse: null,
	equifaxCheckCallArr: [],
	verifyMemberResp: null,
	verifyMemberData: [],
	sendVerifyOtpResponse: null,
	verifyCreditCheckAadharOtpResp: null,
	verifyCreditCheckAadharOtpResult: [],
	verifyValidateCreditCheckAadharOtpResp: null,
	verifyValidateCreditCheckAadharOtpResult: [],
	recheckMemberDetailsCrifResp: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_SUCCESS:
			return {
				...state,
				creditCheckMemberAddResponse: action.payload,
				creditCheckMemberAddResponseList: docodeResponse(
					action.payload,
					'AddMemberCreditCheckMember',
				),
			};

		case CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_FAILURE:
			return { ...state, creditCheckMemberAddResponse: action.payload };

		case CreditCheckActionType.CREDIT_CHECK_MEMBER_ADD_RESPONSE_CHANGED:
			return { ...state, creditCheckMemberAddResponse: null };

		case CreditCheckActionType.CREDIT_CHECK_CALL_SUCCESS:
			return {
				...state,
				creditCheckCallResponse: action.payload,
				creditCheckCallArr: docodeResponse(action.payload, 'CallHighMarkDetailsKey'),
			};

		case CreditCheckActionType.CREDIT_CHECK_CALL_FAILURE:
			return { ...state, creditCheckCallResponse: action.payload };

		case CreditCheckActionType.CREDIT_CHECK_CALL_RESPONSE_CHANGED:
			return { ...state, creditCheckCallResponse: null };

		case CreditCheckActionType.EQUIFAX_CHECK_CALL_SUCCESS:
			return {
				...state,
				equifaxCheckCallResponse: action.payload,
				equifaxCheckCallArr: docodeResponse(action.payload, 'AppCheckEquifaxKey'),
			};

		case CreditCheckActionType.EQUIFAX_CHECK_CALL_FAILURE:
			return { ...state, equifaxCheckCallResponse: action.payload };

		case CreditCheckActionType.EQUIFAX_CHECK_CALL_RESPONSE_CHANGED:
			return { ...state, equifaxCheckCallResponse: null };

		case CreditCheckActionType.VERIFY_MEMBER_SUCCESS:
			return { ...state, verifyMemberResp: action.payload };

		case CreditCheckActionType.VERIFY_MEMBER_FAILURE:
			return { ...state, verifyMemberResp: action.payload };

		case CreditCheckActionType.VERIFY_MEMBER_RESPONSE_CHANGED:
			return { ...state, verifyMemberResp: null };

		case CreditCheckActionType.SEND_VERIFICATION_OTP_SUCCESS:
			return { ...state, sendVerifyOtpResponse: action.payload };

		case CreditCheckActionType.SEND_VERIFICATION_OTP_FAILURE:
			return { ...state, sendVerifyOtpResponse: action.payload };

		case CreditCheckActionType.SEND_VERIFICATION_OTP_RESPONSE_CHANGED:
			return { ...state, sendVerifyOtpResponse: null };

		case CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyCreditCheckAadharOtpResp: action.payload,
				verifyCreditCheckAadharOtpResult: docodeResponse(action.payload, 'AppVerifyKYCKey'),
			};

		case CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_FAILURE:
			return { ...state, verifyCreditCheckAadharOtpResp: action.payload };

		case CreditCheckActionType.VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyCreditCheckAadharOtpResp: null };

		case CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyValidateCreditCheckAadharOtpResp: action.payload,
				verifyValidateCreditCheckAadharOtpResult: docodeResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_FAILURE:
			return { ...state, verifyValidateCreditCheckAadharOtpResp: action.payload };

		case CreditCheckActionType.VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyValidateCreditCheckAadharOtpResp: null };

		case CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_SUCCESS:
			return { ...state, recheckMemberDetailsCrifResp: action.payload };

		case CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_FAILURE:
			return { ...state, recheckMemberDetailsCrifResp: action.payload };

		case CreditCheckActionType.RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_CHANGED:
			return { ...state, recheckMemberDetailsCrifResp: null };

		default:
			return state;
	}
};
