/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const loanCardReportGet = (listData) => {
	const data = {
		FetchLoanCardReportDataToken: listData,
	};
	const url = `${API_URL}FetchLoanCardReportToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// MEMBER LOAN DEATILS
export const memberLoanCardReport = (listData) => {
	const data = {
		GetLoanDetailsForParticularMember: listData,
	};
	const url = `${API_URL}GetParticularMemberLoanDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// MEMBER LOAN CARD DETAILS
export const particualrLoanCardReport = (listData) => {
	const data = {
		GetLoanCardDetailsBasedOnMemebersToken: listData,
	};
	const url = `${API_URL}GetLoanCardDetailsBasedOnMemebersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const LoanCardReportExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('LoanCardReportToken', listData);
	const url = `${API_URL}Report/Excel/LoanCardReportWithToken.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};