/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const DemandCollectionListGet = (listData, SearchByViewType) => {
	const data = {
		DemandCollectionReportToken: listData,
	};
	// const url = `${API_URL}PostDemandCollectionReport`;
	let url = `${API_URL}PostDemandCollectionSimpleReport`;
	if (SearchByViewType === 'simple') {
		url = `${API_URL}PostDemandCollectionSimpleReport`;
	} else if (SearchByViewType === 'detailed') {
		url = `${API_URL}PostDemandCollectionDetailedReport`;
	}

	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const DemandCollectionExcelListGet = (listData, SearchByViewType) => {
	const params = new URLSearchParams();
	let url = `${API_URL}Report/Excel/DemandCollectionSimpleReportExportWithToken.php`;
	if (SearchByViewType === 'simple') {
		params.append('FetchDemandCollectionSimpleListToken', listData);
		url = `${API_URL}Report/Excel/DemandCollectionSimpleReportExportWithToken.php`;
	} else if (SearchByViewType === 'detailed') {
		params.append('FetchDemandCollectionDetailedListToken', listData);
		url = `${API_URL}Report/Excel/DemandCollectionDetailedReportExportWithToken.php`;
	}

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const DemandCollectionPDFListGet = (listData, LocationID) => {
	const params = new URLSearchParams();
	let url = `${API_URL}Report/tcpdf/pdf/DemandCollectionSimpleAllPDFWithToken.php`;
	if (LocationID === 'All') {
		params.append('FetchDemandCollectionSimplePDfToken', listData);
		url = `${API_URL}Report/tcpdf/pdf/DemandCollectionSimpleAllPDFWithToken.php`;
	} else {
		params.append('FetchDemandCollectionSimpleAllPDfToken', listData);
		url = `${API_URL}Report/tcpdf/pdf/DemandCollectionSimplePDFWithToken.php`;
	}

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
