/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import demandCollectionActionTypes from './demandCollection.types';

/** ***************** GET MEMBER LOAN INFO START *********************** */
export const fetchDemandMemberLoanInfoStart = (getData) => ({
	type: demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_START,
	payload: getData,
});

export const fetchDemandMemberLoanInfoSuccess = (successData) => ({
	type: demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const fetchDemandMemberLoanInfoFailure = (failureData) => ({
	type: demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchDemandMemberLoanInfoResponseResetStart = () => ({
	type: demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_RESPONSE_RESET,
});

export const fetchDemandMemberLoanInfoResponseChanged = () => ({
	type: demandCollectionActionTypes.GET_DEMAND_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN INFO END *********************** */

/** ***************** UPDATE DEMAND COLLECTION START *********************** */
export const updateDemandCollectionRevertStart = (getData) => ({
	type: demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_START,
	payload: getData,
});

export const updateDemandCollectionRevertSuccess = (successData) => ({
	type: demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_SUCCESS,
	payload: successData,
});

export const updateDemandCollectionRevertFailure = (failureData) => ({
	type: demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_FAILURE,
	payload: failureData,
});

export const updateDemandCollectionRevertResponseResetStart = () => ({
	type: demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_RESET,
});

export const updateDemandCollectionRevertResponseChanged = () => ({
	type: demandCollectionActionTypes.UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_CHANGED,
});
/** ***************** UPDATE DEMAND COLLECTION END *********************** */
