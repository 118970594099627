/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CloseLoanActionTypes from './closeLoan.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getCloseLoanMemberListResponse: null,
	getCloseLoanMemberListArr: [],
	getCloseLoanMemberListStatus: null,
	updateCloseLoanMemberListStatus: null,
	updateCloseLoanMemberListRespose: [],
	revertCloseLoanMemberListStatus: null,
	revertCloseLoanMemberListRespose: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				getCloseLoanMemberListResponse: action.payload,
				getCloseLoanMemberListArr: docodeResponse(
					action.payload,
					'GetGroupMemberDetailsForCloseDetailsKey',
				),
				getCloseLoanMemberListStatus: 'SUCCESS',
			};

		case CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, getCloseLoanMemberListStatus: action.payload };

		case CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getCloseLoanMemberListStatus: null };

		case CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				updateCloseLoanMemberListStatus: 'SUCCESS',
				updateCloseLoanMemberListRespose: docodeResponse(
					action.payload,
					'UpdateGroupMemberDetailsForCloseKey',
				),
			};

		case CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, updateCloseLoanMemberListStatus: 'FAILURE' };

		case CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_RESPONSE_CHANGED:
			return {
				...state,
				updateCloseLoanMemberListStatus: null,
				updateCloseLoanMemberListRespose: [],
			};

		case CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_SUCCESS:
			return {
				...state,
				revertCloseLoanMemberListStatus: 'SUCCESS',
				revertCloseLoanMemberListRespose: docodeResponse(
					action.payload,
					'RevertGroupMemberDetailsForCloseKey',
				),
			};

		case CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_FAILURE:
			return { ...state, revertCloseLoanMemberListStatus: 'FAILURE' };

		case CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_RESPONSE_CHANGED:
			return {
				...state,
				revertCloseLoanMemberListStatus: null,
				revertCloseLoanMemberListRespose: [],
			};
		default:
			return state;
	}
};
