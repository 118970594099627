/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const createAssignUserRightsForPage = (payloadData) => {
	const data = {
		CreateAsignControMenuToken: payloadData,
	};
	const url = `${API_URL}CreateAsignControlMenuWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateAssignUserRightsForPage = (payloadData) => {
	const data = {
		UpdateAssignControlMenuToken: payloadData,
	};
	const url = `${API_URL}UpdateAssignControltoMenuWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const saveAssignUserRightsForAllPage = (payloadData) => {
	const data = {
		SaveAssignControForAllPageToken: payloadData,
	};
	const url = `${API_URL}SaveAssignControltoAllMenuListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchListUserControlDetails = (fetchListUserControlData) => {
	const data = {
		FetchUserControlDetailsToken: fetchListUserControlData,
	};
	const url = `${API_URL}GetAllUserControlDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
