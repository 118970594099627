/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { decodeApiResponse } from '../../../common/common.utils';
import CoApplicantActionType from './co-applicant.types';

const INITIAL_STATE = {
	coApplicantObj: null,
	coApplicantsListArr: [],
	updateCoApplicantResponse: null,
	addcoBorrowerResponse: null,
	fetchCoApplicantResp: null,
	fetchCoApplicantArr: null,
	deleteCoApplicantResp: null,
	coApplicantHighMarkResp: null,
	coApplicantHighMarkArr: [],
	verifyCoApplicantAadharOtpResp: null,
	verifyCoApplicantAadharOtpResult: [],
	verifyValidateCoApplicantAadharOtpResp: null,
	verifyValidateCoApplicantAadharOtpResult: [],
	fetchRelationshipResp: null,
	fetchRelationshipArr: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CoApplicantActionType.ADD_CO_APPICANT_DETAILS_START:
			return { ...state, coApplicantObj: action.payload };

		case CoApplicantActionType.UPDATE_CO_APPICANT_DETAILS_START:
			return { ...state, coApplicantObj: null };

		case CoApplicantActionType.UPDATE_CO_APPLICANT_SUCCESS:
			return { ...state, updateCoApplicantResponse: action.payload };

		case CoApplicantActionType.UPDATE_CO_APPLICANT_FAILURE:
			return { ...state, updateCoApplicantResponse: action.payload };

		case CoApplicantActionType.UPDATE_CO_APPLICANT_RESPONSE_CHANGED:
			return { ...state, updateCoApplicantResponse: null };

		case CoApplicantActionType.ADD_COBORROWER_SUCCESS:
			return { ...state, addcoBorrowerResponse: action.payload };

		case CoApplicantActionType.ADD_COBORROWER_FAILURE:
			return { ...state, addcoBorrowerResponse: action.payload };

		case CoApplicantActionType.ADD_COBORROWER_RESPONSE_CHANGED:
			return { ...state, addcoBorrowerResponse: null };

		case CoApplicantActionType.FETCH_COBORROWER_SUCCESS:
			return {
				...state,
				fetchCoApplicantResp: action.payload,
				fetchCoApplicantArr: decodeApiResponse(action.payload, 'FetchMemberDataKey'),
			};

		case CoApplicantActionType.FETCH_COBORROWER_FAILURE:
			return { ...state, fetchCoApplicantResp: action.payload };

		case CoApplicantActionType.FETCH_COBORROWER_RESPONSE_CHANGED:
			return { ...state, fetchCoApplicantResp: null };

		case CoApplicantActionType.DELETE_CO_APPLICANT_SUCCESS:
			return { ...state, deleteCoApplicantResp: action.payload };

		case CoApplicantActionType.DELETE_CO_APPLICANT_FAILURE:
			return { ...state, deleteCoApplicantResp: action.payload };

		case CoApplicantActionType.DELETE_CO_APPLICANT_RESPONSE_CHANGED:
			return { ...state, deleteCoApplicantResp: null };

		case CoApplicantActionType.COBORROWER_OBJECT_RESET:
			return { ...state, fetchCoApplicantArr: null };

		case CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_SUCCESS:
			return {
				...state,
				coApplicantHighMarkResp: action.payload,
				coApplicantHighMarkArr: decodeApiResponse(action.payload, 'CoBorrowerCrifCheckKey'),
			};

		case CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_FAILURE:
			return { ...state, coApplicantHighMarkResp: action.payload };

		case CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_RESPONSE_CHANGED:
			return { ...state, coApplicantHighMarkResp: null };

		case CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyCoApplicantAadharOtpResp: action.payload,
				verifyCoApplicantAadharOtpResult: decodeApiResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_FAILURE:
			return { ...state, verifyCoApplicantAadharOtpResp: action.payload };

		case CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyCoApplicantAadharOtpResp: null };

		case CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_SUCCESS:
			return {
				...state,
				verifyValidateCoApplicantAadharOtpResp: action.payload,
				verifyValidateCoApplicantAadharOtpResult: decodeApiResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_FAILURE:
			return { ...state, verifyValidateCoApplicantAadharOtpResp: action.payload };

		case CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyValidateCoApplicantAadharOtpResp: null };

		case CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_SUCCESS:
			return {
				...state,
				fetchRelationshipResp: action.payload,
				fetchRelationshipArr: decodeApiResponse(action.payload, 'FetchMemberDataKey'),
			};

		case CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_FAILURE:
			return { ...state, fetchRelationshipResp: action.payload };

		case CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_CHANGED:
			return { ...state, fetchRelationshipResp: null };

		default:
			return state;
	}
};
