const PurposeOfLoanActionTypes = {
	ADD_PURPOSE_OF_LOAN_DETAILS_START: 'add_purpose_of_loan_details_start',
	ADD_PURPOSE_OF_LOAN_DETAILS_SUCCESS: 'add_purpose_of_loan_details_success',
	ADD_PURPOSE_OF_LOAN_DETAILS_FAILURE: 'add_purpose_of_loan_details_failure',
	ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET: 'add_purpose_of_loan_details_response_reset',
	ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED: 'add_purpose_of_loan_details_response_changed',

	UPDATE_PURPOSE_OF_LOAN_DETAILS_START: 'update_purpose_of_loan_details_start',
	UPDATE_PURPOSE_OF_LOAN_DETAILS_SUCCESS: 'update_purpose_of_loan_details_success',
	UPDATE_PURPOSE_OF_LOAN_DETAILS_FAILURE: 'update_purpose_of_loan_details_failure',
	UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET: 'update_purpose_of_loan_details_response_reset',
	UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
		'update_purpose_of_loan_details_response_changed',

	GET_PURPOSE_OF_LOAN_LIST_START: 'get_purpose_of_loan_list_start',
	GET_PURPOSE_OF_LOAN_LIST_SUCCESS: 'get_purpose_of_loan_list_success',
	GET_PURPOSE_OF_LOAN_LIST_FAILURE: 'get_purpose_of_loan_list_failure',
	GET_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET: 'get_purpose_of_loan_list_response_reset',
	GET_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED: 'get_purpose_of_loan_list_response_changed',

	FETCH_PURPOSE_OF_LOAN_DETAILS_START: 'fetch_purpose_of_loan_details_start',
	FETCH_PURPOSE_OF_LOAN_DETAILS_SUCCESS: 'fetch_purpose_of_loan_details_success',
	FETCH_PURPOSE_OF_LOAN_DETAILS_FAILURE: 'fetch_purpose_of_loan_details_failure',
	FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET: 'fetch_purpose_of_loan_details_response_reset',
	FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
		'fetch_purpose_of_loan_details_response_changed',

	DELETE_PURPOSE_OF_LOAN_DETAILS_START: 'delete_purpose_of_loan_details_start',
	DELETE_PURPOSE_OF_LOAN_DETAILS_SUCCESS: 'delete_purpose_of_loan_details_success',
	DELETE_PURPOSE_OF_LOAN_DETAILS_FAILURE: 'delete_purpose_of_loan_details_failure',
	DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET: 'delete_purpose_of_loan_details_response_reset',
	DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
		'delete_purpose_of_loan_details_response_changed',

	UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_START: 'UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_START',
	UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_SUCCESS: 'UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_SUCCESS',
	UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_FAILURE: 'UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_FAILURE',
	UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_RESPONSE_RESET:
		'UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_RESPONSE_RESET',
	UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_RESPONSE_CHANGED:
		'UPDATE_PURPOSE_OF_LOAN_PUBLISH_STATUS_RESPONSE_CHANGED',

	// GET ALL PURPOSE OF LOAN CATEGORY DETAILS
	GET_PURPOSE_OF_LOAN_CATEGORY_START: 'GET_PURPOSE_OF_LOAN_CATEGORY_START',
	GET_PURPOSE_OF_LOAN_CATEGORY_SUCCESS: 'GET_PURPOSE_OF_LOAN_CATEGORY_SUCCESS',
	GET_PURPOSE_OF_LOAN_CATEGORY_FAILURE: 'GET_PURPOSE_OF_LOAN_CATEGORY_FAILURE',
	GET_PURPOSE_OF_LOAN_CATEGORY_RESET: 'GET_PURPOSE_OF_LOAN_CATEGORY_RESET',
	GET_PURPOSE_OF_LOAN_CATEGORY_CHANGED: 'GET_PURPOSE_OF_LOAN_CATEGORY_CHANGED',

	// ADD PURPOSE OF LOAN CATEGORY DETAILS
	ADD_PURPOSE_OF_LOAN_CATEGORY_START: 'ADD_PURPOSE_OF_LOAN_CATEGORY_START',
	ADD_PURPOSE_OF_LOAN_CATEGORY_SUCCESS: 'ADD_PURPOSE_OF_LOAN_CATEGORY_SUCCESS',
	ADD_PURPOSE_OF_LOAN_CATEGORY_FAILURE: 'ADD_PURPOSE_OF_LOAN_CATEGORY_FAILURE',
	ADD_PURPOSE_OF_LOAN_CATEGORY_RESET: 'ADD_PURPOSE_OF_LOAN_CATEGORY_RESET',
	ADD_PURPOSE_OF_LOAN_CATEGORY_CHANGED: 'ADD_PURPOSE_OF_LOAN_CATEGORY_CHANGED',

	// UPDATE PURPOSE OF LOAN CATEGORY DETAILS
	UPDATE_PURPOSE_OF_LOAN_CATEGORY_START: 'UPDATE_PURPOSE_OF_LOAN_CATEGORY_START',
	UPDATE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS: 'UPDATE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS',
	UPDATE_PURPOSE_OF_LOAN_CATEGORY_FAILURE: 'UPDATE_PURPOSE_OF_LOAN_CATEGORY_FAILURE',
	UPDATE_PURPOSE_OF_LOAN_CATEGORY_RESET: 'UPDATE_PURPOSE_OF_LOAN_CATEGORY_RESET',
	UPDATE_PURPOSE_OF_LOAN_CATEGORY_CHANGED: 'UPDATE_PURPOSE_OF_LOAN_CATEGORY_CHANGED',

	// DELETE PURPOSE OF LOAN CATEGORY DETAILS
	DELETE_PURPOSE_OF_LOAN_CATEGORY_START: 'DELETE_PURPOSE_OF_LOAN_CATEGORY_START',
	DELETE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS: 'DELETE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS',
	DELETE_PURPOSE_OF_LOAN_CATEGORY_FAILURE: 'DELETE_PURPOSE_OF_LOAN_CATEGORY_FAILURE',
	DELETE_PURPOSE_OF_LOAN_CATEGORY_RESET: 'DELETE_PURPOSE_OF_LOAN_CATEGORY_RESET',
	DELETE_PURPOSE_OF_LOAN_CATEGORY_CHANGED: 'DELETE_PURPOSE_OF_LOAN_CATEGORY_CHANGED',

	// FETCH ALL PURPOSE OF LOAN TYPE DETAILS
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START: 'FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_SUCCESS: 'FETCH_ALL_PURPOSE_OF_LOAN_TYPE_SUCCESS',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_FAILURE: 'FETCH_ALL_PURPOSE_OF_LOAN_TYPE_FAILURE',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESET: 'FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESET',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_CHANGED:
		'FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_CHANGED',
};

export default PurposeOfLoanActionTypes;
