/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import MemberActionTypes from './member.types';

/** ***************** ADD MEMBER DETAILS START *********************** */
export const addMemberDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_START,
	payload: addData,
});

export const addMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_RESET,
});

export const addMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER DETAILS END *********************** */

/** ***************** UPDATE MEMBER DETAILS START *********************** */
export const updateMemberDetailsStart = (updateData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_START,
	payload: updateData,
});

export const updateMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_RESET,
});

export const updateMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER DETAILS END *********************** */

/** ***************** GET MEMBER DETAILS START *********************** */
export const getMemberDetailsStart = (getData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_START,
	payload: getData,
});

export const getMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const getMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const getMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.GET_MEMBER_LIST_RESPONSE_RESET,
});

export const getMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CHANGED,
});

/** ***************** GET MEMBER DETAILS END *********************** */

/** ***************** FETCH MEMBER DETAILS START *********************** */
export const fetchMemberDetailsStart = (fetchData) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_START,
	payload: fetchData,
});

export const fetchMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_RESET,
});

export const fetchMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH MEMBER DETAILS END *********************** */

/** ***************** DELETE MEMBER DETAILS START *********************** */
export const deleteMemberDetailsStart = (deleteData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_START,
	payload: deleteData,
});

export const deleteMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_RESET,
});

export const deleteMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE MEMBER DETAILS END *********************** */

/** ***************** FETCH COAPPLICANT DETAILS START *********************** */
export const fetchCoApplicantHighMarkDetailsStart = (fetchData) => ({
	type: MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_START,
	payload: fetchData,
});

export const fetchCoApplicantHighMarkDetailsSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchCoApplicantHighMarkDetailsFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchCoApplicantHighMarkDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET,
});

export const fetchCoApplicantHighMarkDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH COAPPLICANT DETAILS END *********************** */

/** ***************** VERIFY DOCUMENT DETAILS START *********************** */
export const verifyDocumentDetailsStart = (fetchData) => ({
	type: MemberActionTypes.VERIFY_DOCUMENT_DETAILS_START,
	payload: fetchData,
});

export const verifyDocumentDetailsSuccess = (successData) => ({
	type: MemberActionTypes.VERIFY_DOCUMENT_DETAILS_SUCCESS,
	payload: successData,
});

export const verifyDocumentDetailsFailure = (failureData) => ({
	type: MemberActionTypes.VERIFY_DOCUMENT_DETAILS_FAILURE,
	payload: failureData,
});

export const verifyDocumentDetailsResponseResetStart = () => ({
	type: MemberActionTypes.VERIFY_DOCUMENT_DETAILS_RESPONSE_RESET,
});

export const verifyDocumentDetailsResponseChanged = () => ({
	type: MemberActionTypes.VERIFY_DOCUMENT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** VERIFY DOCUMENT DETAILS END *********************** */

/** ***************** CHECK HGIMARK DETAILS START *********************** */
export const checkHighmarkDetailsStart = (fetchData) => ({
	type: MemberActionTypes.CHECK_HIGHMARK_DETAILS_START,
	payload: fetchData,
});

export const checkHighmarkDetailsSuccess = (successData) => ({
	type: MemberActionTypes.CHECK_HIGHMARK_DETAILS_SUCCESS,
	payload: successData,
});

export const checkHighmarkDetailsFailure = (failureData) => ({
	type: MemberActionTypes.CHECK_HIGHMARK_DETAILS_FAILURE,
	payload: failureData,
});

export const checkHighmarkDetailsResponseResetStart = () => ({
	type: MemberActionTypes.CHECK_HIGHMARK_DETAILS_RESPONSE_RESET,
});

export const checkHighmarkDetailsResponseChanged = () => ({
	type: MemberActionTypes.CHECK_HIGHMARK_DETAILS_RESPONSE_CHANGED,
});

/** ***************** VERIFY HIGHMARK DETAILS END *********************** */
/** ***************** CHECK EQUIFAX DETAILS START *********************** */
export const checkEquifaxDetailsStart = (fetchData) => ({
	type: MemberActionTypes.CHECK_EQUIFAX_DETAILS_START,
	payload: fetchData,
});

export const checkEquifaxDetailsSuccess = (successData) => ({
	type: MemberActionTypes.CHECK_EQUIFAX_DETAILS_SUCCESS,
	payload: successData,
});

export const checkEquifaxDetailsFailure = (failureData) => ({
	type: MemberActionTypes.CHECK_EQUIFAX_DETAILS_FAILURE,
	payload: failureData,
});

export const checkEquifaxDetailsResponseResetStart = () => ({
	type: MemberActionTypes.CHECK_EQUIFAX_DETAILS_RESPONSE_RESET,
});

export const checkEquifaxDetailsResponseChanged = () => ({
	type: MemberActionTypes.CHECK_EQUIFAX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** VERIFY DOCUMENT DETAILS END *********************** */

/** ***************** CHECK CO-APPLICANT DOCUMENT DETAILS START *********************** */
export const checkCoApplicantHighmarkDetailsStart = (fetchData) => ({
	type: MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_START,
	payload: fetchData,
});

export const checkCoApplicantHighmarkDetailsSuccess = (successData) => ({
	type: MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS,
	payload: successData,
});

export const checkCoApplicantHighmarkDetailsFailure = (failureData) => ({
	type: MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_FAILURE,
	payload: failureData,
});

export const checkCoApplicantHighmarkDetailsResponseResetStart = () => ({
	type: MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET,
});

export const checkCoApplicantHighmarkDetailsResponseChanged = () => ({
	type: MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED,
});

/** ***************** VERIFY DOCUMENT DETAILS END *********************** */

/** ***************** UPDATE NO LOAN MEMBER START *********************** */
export const updateNoLoanMemberStart = (fetchData) => ({
	type: MemberActionTypes.UPDATE_NO_LOAN_MEMBER_START,
	payload: fetchData,
});

export const updateNoLoanMemberSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_NO_LOAN_MEMBER_SUCCESS,
	payload: successData,
});

export const updateNoLoanMemberFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_NO_LOAN_MEMBER_FAILURE,
	payload: failureData,
});

export const updateNoLoanMemberResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_NO_LOAN_MEMBER_RESPONSE_RESET,
});

export const updateNoLoanMemberResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_NO_LOAN_MEMBER_RESPONSE_CHANGED,
});

/** ***************** NO LOAN MEMBER  END *********************** */

/** ***************** UPDATE NO LOAN MEMBER START *********************** */
export const checkTopupLoanMemberStart = (fetchData) => ({
	type: MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_START,
	payload: fetchData,
});

export const checkTopupLoanMemberSuccess = (successData) => ({
	type: MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_SUCCESS,
	payload: successData,
});

export const checkTopupLoanMemberFailure = (failureData) => ({
	type: MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_FAILURE,
	payload: failureData,
});

export const checkTopupLoanMemberResponseResetStart = () => ({
	type: MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_RESPONSE_RESET,
});

export const checkTopupLoanMemberResponseChanged = () => ({
	type: MemberActionTypes.CHECK_TOPUP_LOAN_MEMBER_RESPONSE_CHANGED,
});

/** ***************** NO LOAN MEMBER  END *********************** */

/** ***************** UPDATE REJECT MEMBER START *********************** */
export const updateMemberRejectStart = (fetchData) => ({
	type: MemberActionTypes.REJECT_MEMBER_START,
	payload: fetchData,
});

export const updateMemberRejectSuccess = (successData) => ({
	type: MemberActionTypes.REJECT_MEMBER_SUCCESS,
	payload: successData,
});

export const updateMemberRejectFailure = (failureData) => ({
	type: MemberActionTypes.REJECT_MEMBER_FAILURE,
	payload: failureData,
});

export const updateMemberRejectResponseResetStart = () => ({
	type: MemberActionTypes.REJECT_MEMBER_RESPONSE_RESET,
});

export const updateMemberRejectResponseChanged = () => ({
	type: MemberActionTypes.REJECT_MEMBER_RESPONSE_CHANGED,
});

/** ***************** VERIFY DOCUMENT DETAILS END *********************** */

/** ***************** UPDATE REJECT MEMBER START *********************** */
export const updateMemberRecheckStart = (fetchData) => ({
	type: MemberActionTypes.RECHECK_MEMBER_START,
	payload: fetchData,
});

export const updateMemberRecheckSuccess = (successData) => ({
	type: MemberActionTypes.RECHECK_MEMBER_SUCCESS,
	payload: successData,
});

export const updateMemberRecheckFailure = (failureData) => ({
	type: MemberActionTypes.RECHECK_MEMBER_FAILURE,
	payload: failureData,
});

export const updateMemberRecheckResponseResetStart = () => ({
	type: MemberActionTypes.RECHECK_MEMBER_RESPONSE_RESET,
});

export const updateMemberRecheckResponseChanged = () => ({
	type: MemberActionTypes.RECHECK_MEMBER_RESPONSE_CHANGED,
});

/** ***************** VERIFY RECHECK DETAILS END *********************** */

/** ***************** UPDATE APPROVE MEMBER START *********************** */
export const updateMemberApproveStart = (fetchData) => ({
	type: MemberActionTypes.APPROVE_MEMBER_START,
	payload: fetchData,
});

export const updateMemberApproveSuccess = (successData) => ({
	type: MemberActionTypes.APPROVE_MEMBER_SUCCESS,
	payload: successData,
});

export const updateMemberApproveFailure = (failureData) => ({
	type: MemberActionTypes.APPROVE_MEMBER_FAILURE,
	payload: failureData,
});

export const updateMemberApproveResponseResetStart = () => ({
	type: MemberActionTypes.APPROVE_MEMBER_RESPONSE_RESET,
});

export const updateMemberApproveResponseChanged = () => ({
	type: MemberActionTypes.APPROVE_MEMBER_RESPONSE_CHANGED,
});

/** ***************** VERIFY APPROVE DETAILS END *********************** */

/** ***************** FETCH PAYSPRINT LOG DETAILS START *********************** */
export const fetchPaySprintLogDetailsStart = (fetchData) => ({
	type: MemberActionTypes.FETCH_PAYSPRINT_DETAILS_START,
	payload: fetchData,
});

export const fetchPaySprintLogDetailsSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_PAYSPRINT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchPaySprintLogDetailsFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_PAYSPRINT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchPaySprintLogDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_PAYSPRINT_DETAILS_RESPONSE_RESET,
});

export const fetchPaySprintLogDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_PAYSPRINT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PAYSPRINT LOG DETAILS END *********************** */

/** ***************** FETCH MEMBER AUDIT DETAILS START *********************** */
export const fetchMemberAuditDetailsStart = (fetchData) => ({
	type: MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_START,
	payload: fetchData,
});

export const fetchMemberAuditDetailsSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchMemberAuditDetailsFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchMemberAuditDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_RESPONSE_RESET,
});

export const fetchMemberAuditDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_AUDIT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PAYSPRINT LOG DETAILS END *********************** */

/** ***************** FETCH VERIFY  DETAILS START *********************** */
export const updateMemberVerifyDetailsStart = (fetchData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_START,
	payload: fetchData,
});

export const updateMemberVerifyDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberVerifyDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberVerifyDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_RESPONSE_RESET,
});

export const updateMemberVerifyDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED,
});

export const updateMemberKYCDetailsStart = (fetchData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_START,
	payload: fetchData,
});

export const updateMemberKYCDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberKYCDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberKYCDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_RESPONSE_RESET,
});

export const updateMemberKYCDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_KYC_DETAILS_RESPONSE_CHANGED,
});

export const updateMemberTransferDetailsStart = (fetchData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_START,
	payload: fetchData,
});

export const updateMemberTransferDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberTransferDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberTransferDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_RESPONSE_RESET,
});

export const updateMemberTransferDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_TRANSFER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH VERIFY DETAILS END *********************** */

/** ***************** FETCH MEMBER AUDIT DETAILS START *********************** */
export const fetchMemberVerifyDetailsStart = (fetchData) => ({
	type: MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_START,
	payload: fetchData,
});

export const fetchMemberVerifyDetailsSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchMemberVerifyDetailsFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchMemberVerifyDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_RESPONSE_RESET,
});

export const fetchMemberVerifyDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_VERIFY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH VERIFY DETAILS END *********************** */

/** ***************** SEND OTP START *********************** */
export const sendOTPStart = (fetchData) => ({
	type: MemberActionTypes.SEND_OTP_START,
	payload: fetchData,
});

export const sendOTPSuccess = (successData) => ({
	type: MemberActionTypes.SEND_OTP_SUCCESS,
	payload: successData,
});

export const sendOTPFailure = (failureData) => ({
	type: MemberActionTypes.SEND_OTP_FAILURE,
	payload: failureData,
});

export const sendOTPResponseResetStart = () => ({
	type: MemberActionTypes.SEND_OTP_RESPONSE_RESET,
});

export const sendOTPResponseChanged = () => ({
	type: MemberActionTypes.SEND_OTP_RESPONSE_CHANGED,
});

/** ***************** SEND OTP END *********************** */

export const addMemberDetails = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS,
	payload: addData,
});

export const changeAddMemberTab = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_TAB,
	payload: addData,
});

export const updateMemberTab = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_TAB,
	payload: addData,
});

export const resetMemberData = () => ({
	type: MemberActionTypes.RESET_MEMBER_DATA,
});
