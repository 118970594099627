import { takeLatest, put, all, call } from 'redux-saga/effects';
import RationCardDetailsActionType from './ration-card-details.types';
import {
	updateRationCardSuccess,
	updateRationCardFailure,
	updateRationCardResponseChanged,
} from './ration-card-details.action';
import { encodeJson } from '../../../enode-decode';
import { rationCardUpdateDetails } from './ration-card-details.service';

// Start Ration Card Details Add
export function* updateRationCardIdDetails({ payload }) {
	try {
		const key = 'updateRationCardID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			MemberRationCardFrontImg: payload.memberRationCardFrontImg,
			MemberRationCardBackImg: payload.memberRationCardBackImg,
			ProofType: payload.proofType,
			DocumantType: payload.docType,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const rationCardData = encodeJson(jsonData, key);
		const responseData = yield call(rationCardUpdateDetails, rationCardData);
		if (responseData.data.statusCode === '100') {
			yield put(updateRationCardFailure(responseData.data));
		} else {
			yield put(updateRationCardSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateRationCardFailure(error));
	}
}

export function* updateRationCardDetailsResponseClear() {
	yield put(updateRationCardResponseChanged());
}

export function* onRationCardDetailsUpdateStart() {
	yield takeLatest(
		RationCardDetailsActionType.UPDATE_RATION_CARD_START,
		updateRationCardIdDetails,
	);
}

export function* onRationCardDetailsUpdateResponseRest() {
	yield takeLatest(
		RationCardDetailsActionType.UPDATE_RATION_CARD_RESPONSE_REST_START,
		updateRationCardDetailsResponseClear,
	);
}
// End Ration Card Details Add

export function* rationCardDetailsSaga() {
	yield all([call(onRationCardDetailsUpdateStart), call(onRationCardDetailsUpdateResponseRest)]);
}
