const BasicDetailsActionType = {
	BASIC_DETAILS_ADD_START: 'basic_details_add_start',
	BASIC_DETAILS_ADD_SUCCESS: 'basic_details_add_success',
	BASIC_DETAILS_ADD_FAILURE: 'basic_details_add_failure',
	BASIC_DETAILS_ADD_RESPONSE_REST_START: 'basic_details_add_response_reset_start',
	BASIC_DETAILS_ADD_RESPONSE_CHANGED: 'basic_details_add_response_changed',

	SCAN_AADHAR_NUMBER: 'scan_aadhar_number',
	SCAN_AADHAR_NUMBER_RESET: 'scan_aadhar_number_reset',

	AADHAR_QR_CODE_SCAN_DATA: 'aadhar_qr_code_scan_data',

	SKIP_MEMBER_DETAILS_START: 'skip_member_details_start',
	SKIP_MEMBER_DETAILS_SUCCESS: 'skip_member_details_success',
	SKIP_MEMBER_DETAILS_FAILURE: 'skip_member_details_failure',
	SKIP_MEMBER_DETAILS_RESPONSE_RESET_START: 'skip_member_details_response_reset_start',
	SKIP_MEMBER_DETAILS_RESPONSE_CHANGED: 'skip_member_details_response_changed',

	VERIFY_KYC_DETAILS_START: 'verify_kyc_details_start',
	VERIFY_KYC_DETAILS_SUCCESS: 'verify_kyc_details_success',
	VERIFY_KYC_DETAILS_FAILURE: 'verify_kyc_details_failure',
	VERIFY_KYC_DETAILS_RESPONSE_RESET_START: 'verify_kyc_details_response_reset_start',
	VERIFY_KYC_DETAILS_RESPONSE_CHANGED: 'verify_kyc_details_response_changed',

	MEMBER_COMPLETION_START: 'member_completion_start',
	MEMBER_COMPLETION_SUCCESS: 'member_completion_success',
	MEMBER_COMPLETION_FAILURE: 'member_completion_failure',
	MEMBER_COMPLETION_RESPONSE_RESET_START: 'member_completion_response_reset_start',
	MEMBER_COMPLETION_RESPONSE_CHANGED: 'member_completion_response_changed',

	VERIFY_AADHAR_OTP_START: 'verify_aadhar_otp_start',
	VERIFY_AADHAR_OTP_SUCCESS: 'verify_aadhar_otp_success',
	VERIFY_AADHAR_OTP_FAILURE: 'verify_aadhar_otp_failure',
	VERIFY_AADHAR_OTP_RESPONSE_RESET_START: 'verify_aadhar_otp_response_reset_start',
	VERIFY_AADHAR_OTP_RESPONSE_CHANGED: 'verify_aadhar_otp_response_changed',

	VERIFY_VALIDATE_AADHAR_OTP_START: 'verify_validate_aadhar_otp_start',
	VERIFY_VALIDATE_AADHAR_OTP_SUCCESS: 'verify_validate_aadhar_otp_success',
	VERIFY_VALIDATE_AADHAR_OTP_FAILURE: 'verify_validate_aadhar_otp_failure',
	VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_validate_aadhar_otp_response_reset_start',
	VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_CHANGED: 'verify_validate_aadhar_otp_response_changed',

	FETCH_VERIFICATION_DETAILS_START: 'fetch_verification_data_start',
	FETCH_VERIFICATION_DETAILS_SUCCESS: 'fetch_verification_data_success',
	FETCH_VERIFICATION_DETAILS_FAILURE: 'fetch_verification_data_failure',
	FETCH_VERIFICATION_DETAILS_RESPONSE_RESET_START: 'fetch_verification_data_response_reset_start',
	FETCH_VERIFICATION_DETAILS_RESPONSE_CHANGED: 'fetch_verification_data_response_changed',

	KYC_RESPONSE_RESET: 'kyc_response_reset',
};

export default BasicDetailsActionType;
