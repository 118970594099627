/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchAllProductLoanProposalListGet = (listData) => {
	const data = {
		FetchAllProductLoanProposalListToken: listData,
	};
	const url = `${API_URL}GetAllProductLoanIndexDetailsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchParticularProductLoanProposalListGet = (listData) => {
	const data = {
		FetchParticularProductLoanProposalToken: listData,
	};
	const url = `${API_URL}GetParticularProductLoanIndexDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchWholeGroupMemberListGet = (listData) => {
	const data = {
		FetchWholeGroupMemberDetailsToken: listData,
	};
	const url = `${API_URL}GetWholeGroupMemberListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addProductLoanProposal = (listData) => {
	const data = {
		AddLoanIndexDetailsToken: listData,
	};
	const url = `${API_URL}AddLoanIndexToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
