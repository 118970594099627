/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable default-param-last */
import ChangeMeetingDateActionTypes from './changeMeetingDate.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	updateBranchMeetingDateResponse: null,
	updateSingleBranchMeetingDateResponse: null,
	updateSingleCenterMeetingDateResponse: null,
	getBranchMeetingDateDetailsResponse: null,
	getBranchMeetingDateDetailsArr: [],
	getLoanCenterGroupDetailsResponse: null,
	getLoanCenterGroupDetailsArr: [],
	getParticularLoanCenterGroupDetailsResponse: null,
	getParticularLoanCenterGroupDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_SUCCESS:
			return {
				...state,
				updateBranchMeetingDateResponse: action.payload,
			};

		case ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_FAILURE:
			return { ...state, updateBranchMeetingDateResponse: action.payload };

		case ChangeMeetingDateActionTypes.UPDATE_BRANCH_MEETING_DATE_RESPONSE_CHANGED:
			return { ...state, updateBranchMeetingDateResponse: null };

		case ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_SUCCESS:
			return {
				...state,
				getBranchMeetingDateDetailsResponse: action.payload,
				getBranchMeetingDateDetailsArr: docodeResponse(
					action.payload,
					'ChangeMeetingDateKey',
				),
			};

		case ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_FAILURE:
			return { ...state, getBranchMeetingDateDetailsResponse: action.payload };

		case ChangeMeetingDateActionTypes.GET_BRANCH_MEETING_DATE_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchMeetingDateDetailsResponse: null };

		case ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_SUCCESS:
			return {
				...state,
				getLoanCenterGroupDetailsResponse: action.payload,
				getLoanCenterGroupDetailsArr: docodeResponse(
					action.payload,
					'ChangeMeetingDateKey',
				),
			};

		case ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_FAILURE:
			return { ...state, getLoanCenterGroupDetailsResponse: action.payload };

		case ChangeMeetingDateActionTypes.GET_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, getLoanCenterGroupDetailsResponse: null };

		case ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_SUCCESS:
			return {
				...state,
				getParticularLoanCenterGroupDetailsResponse: action.payload,
				getParticularLoanCenterGroupDetailsArr: docodeResponse(
					action.payload,
					'ChangeMeetingDateKey',
				),
			};

		case ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_FAILURE:
			return { ...state, getParticularLoanCenterGroupDetailsResponse: action.payload };

		case ChangeMeetingDateActionTypes.GET_PARTICULAR_LOAN_CENTER_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, getParticularLoanCenterGroupDetailsResponse: null };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_SUCCESS:
			return {
				...state,
				updateSingleBranchMeetingDateResponse: action.payload,
			};

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_FAILURE:
			return { ...state, updateSingleBranchMeetingDateResponse: action.payload };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_BRANCH_MEETING_DATE_RESPONSE_CHANGED:
			return { ...state, updateSingleBranchMeetingDateResponse: null };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_SUCCESS:
			return {
				...state,
				updateSingleCenterMeetingDateResponse: action.payload,
			};

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_FAILURE:
			return { ...state, updateSingleCenterMeetingDateResponse: action.payload };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_CENTER_MEETING_DATE_RESPONSE_CHANGED:
			return { ...state, updateSingleCenterMeetingDateResponse: null };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_SUCCESS:
			return {
				...state,
				updateSingleMemberMeetingDateResponse: action.payload,
			};

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_FAILURE:
			return { ...state, updateSingleMemberMeetingDateResponse: action.payload };

		case ChangeMeetingDateActionTypes.UPDATE_SINGLE_MEMBER_MEETING_DATE_RESPONSE_CHANGED:
			return { ...state, updateSingleMemberMeetingDateResponse: null };

		default:
			return state;
	}
};
