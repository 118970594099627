/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ChangeGroupOficerActionTypes from './changeGroupOfficer.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getBranchOfficerListResponse: null,
	getBranchOfficerListArr: [],
	getChangeBranchOfficerListResponse: null,
	getChangeBranchOfficerListArr: [],
	getOfficerCenterListResponse: null,
	getOfficerCenterListArr: [],
	updateChangeGroupOfficerListRespose: null,
	updateChangeProposedOfficerListRespose: null,
	updateChangeCenterLoanOfficerListRespose: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_SUCCESS:
			return {
				...state,
				getBranchOfficerListResponse: action.payload,
				getBranchOfficerListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
			};

		case ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_FAILURE:
			return { ...state, getBranchOfficerListResponse: action.payload };

		case ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchOfficerListResponse: null };

		case ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_SUCCESS:
			return {
				...state,
				getChangeBranchOfficerListResponse: action.payload,
				getChangeBranchOfficerListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
			};

		case ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_FAILURE:
			return { ...state, getChangeBranchOfficerListResponse: action.payload };

		case ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_CHANGED:
			return { ...state, getChangeBranchOfficerListResponse: null };

		case ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_SUCCESS:
			return {
				...state,
				getOfficerCenterListResponse: action.payload,
				getOfficerCenterListArr: docodeResponse(action.payload, 'GetOfficerCenterListKey'),
			};

		case ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_FAILURE:
			return { ...state, getOfficerCenterListResponse: action.payload };

		case ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, getOfficerCenterListResponse: null };


		case ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_SUCCESS:
			return {
				...state,
				getLoanGroupListResponse: action.payload,
				getLoanGroupListArr: docodeResponse(action.payload, 'GetLoanGroupListKey'),
			};

		case ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_FAILURE:
			return { ...state, getLoanGroupListResponse: action.payload };

		case ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, getLoanGroupListResponse: null };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_SUCCESS:
			return { ...state, updateChangeGroupOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_FAILURE:
			return { ...state, updateChangeGroupOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_CHANGED:
			return { ...state, updateChangeGroupOfficerListRespose: null };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_SUCCESS:
			return { ...state, updateChangeProposedOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_FAILURE:
			return { ...state, updateChangeProposedOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_CHANGED:
			return { ...state, updateChangeProposedOfficerListRespose: null };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_SUCCESS:
			return { ...state, updateChangeCenterLoanOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_FAILURE:
			return { ...state, updateChangeCenterLoanOfficerListRespose: action.payload };

		case ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_CHANGED:
			return { ...state, updateChangeCenterLoanOfficerListRespose: null };

		default:
			return state;
	}
};
