const CreditBureauReportActionTypes = {
	GET_CREDIT_BUREAU_REPORT_LIST_START: 'GET_CREDIT_BUREAU_REPORT_LIST_START',
	GET_CREDIT_BUREAU_REPORT_LIST_SUCCESS: 'GET_CREDIT_BUREAU_REPORT_LIST_SUCCESS',
	GET_CREDIT_BUREAU_REPORT_LIST_FAILURE: 'GET_CREDIT_BUREAU_REPORT_LIST_FAILURE',
	GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_RESET: 'GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_RESET',
	GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_CHANGED:
		'GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_CHANGED',

	FETCH_CREDIT_BUREAU_EXCEL_START: 'FETCH_CREDIT_BUREAU_EXCEL_START',
	FETCH_CREDIT_BUREAU_EXCEL_SUCCESS: 'FETCH_CREDIT_BUREAU_EXCEL_SUCCESS',
	FETCH_CREDIT_BUREAU_EXCEL_FAILURE: 'FETCH_CREDIT_BUREAU_EXCEL_FAILURE',
	FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_CHANGED: 'FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_CHANGED',
	FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_RESET: 'FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_RESET',
};

export default CreditBureauReportActionTypes;
