/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const PreCloseLoanMemberListGet = (listData) => {
	const data = {
		GetGroupMemberDetailsForPreCloseDetailsToken: listData,
	};
	const url = `${API_URL}PostGroupMemberDetailsForPreClose`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const PreCloseLoanMemberListUpdate = (listData) => {
	const data = {
		UpdateGroupMemberDetailsForPreCloseToken: listData,
	};
	const url = `${API_URL}UpdateGroupMemberDetailsForPreClose`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const PreCloseLoanMemberListRevert = (listData) => {
	const data = {
		RevertGroupMemberDetailsForPreCloseToken: listData,
	};
	const url = `${API_URL}RevertGroupMemberDetailsForPreClose`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODDetails = (listData) => {
	const data = {
		GetBranchEODExistPreCloseDetailsToken: listData,
	};
	const url = `${API_URL}CheckBranchEODExistPreClose`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
