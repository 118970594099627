/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
/** *** Collection Approval Page All the Saga declared over here **** */
/** *** Version : 2.0.1 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Common Collection Update Saga added **** */
/** *** Version : 2.0.2 **** */
/** *** Author : Vivin **** */
/** *** LastEdit : Vivin **** */
/** *** Last Added : Branch EOD Check Saga added **** */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import CollectionActionTypes from './collection.types';
import {
	fetchOfficerListCenters,
	fetchOfficerListArrearCenters,
	fetchBranchCollectionUsersList,
	fetchCenterListOfficerCollections,
	fetchBranchTodayCollectionDetails,
	fetchBranchTodayDemandMoveToArrearList,
	fetchCenterCollectionDenominationDetails,
	fetchAttendanceOfficerCollectionDetails,
	fetchBranchCollectionFunderAllocateEODDetails,
	fetchBranchTodayCollectionsYetToApprove,
	updateLoanPaidPerMemberCollectionApprove,
	updateLoanPaidAllCollectionApprove,
	fetchBranchClosedLoanList,
	commonCollectionUpdate,
	checkBranchEODExist,
	updateLoanPaidMoveToArrear,
	updateOverallAccruedInterestAllocateForEODCall,
	updateOverallAllocateForEODCall,
} from './collection.service';

import {
	getOfficerListCentersSuccess,
	getOfficerListCentersFailure,
	getOfficerListCentersResponseChanged,
	getOfficerListArrearCentersSuccess,
	getOfficerListArrearCentersFailure,
	getOfficerListArrearCentersResponseChanged,
	getBranchCollectionUsersListSuccess,
	getBranchCollectionUsersListFailure,
	getBranchCollectionUsersListResponseChanged,
	getBranchTodayDemandMoveToArrearSuccess,
	getBranchTodayDemandMoveToArrearFailure,
	getBranchTodayDemandMoveToArrearChanged,
	getCenterListOfficerCollectionsSuccess,
	getCenterListOfficerCollectionsFailure,
	getCenterListOfficerCollectionsResponseChanged,
	getBranchTodayCollectionDetailsSuccess,
	getBranchTodayCollectionDetailsFailure,
	getBranchTodayCollectionDetailsChanged,
	getCenterCollectionDenominationSuccess,
	getCenterCollectionDenominationFailure,
	getCenterCollectionDenominationChanged,
	getAttendanceOfficerCollectionSuccess,
	getAttendanceOfficerCollectionFailure,
	getAttendanceOfficerCollectionChanged,
	getBranchCollectionFunderAllocateEODSuccess,
	getBranchCollectionFunderAllocateEODFailure,
	getBranchCollectionFunderAllocateEODChanged,
	getBranchCollectionFunderAllocateEODChangedData,
	getBranchTodayCollectionsYetToApproveSuccess,
	getBranchTodayCollectionsYetToApproveFailure,
	getBranchTodayCollectionsYetToApproveChanged,
	getBranchTodayCollectionsYetToApproveChangedData,
	updateLoanPaidPerMemberCollectionApproveSuccess,
	updateLoanPaidPerMemberCollectionApproveFailure,
	updateLoanPaidPerMemberCollectionApproveResponseChanged,
	updateLoanPaidOverallCollectionApproveSuccess,
	updateLoanPaidOverallCollectionApproveFailure,
	updateLoanPaidOverallCollectionApproveResponseChanged,
	commonCollectionUpdateSuccess,
	commonCollectionUpdateFailure,
	commonCollectionUpdateResponseChanged,
	checkBranchEODExistSuccess,
	checkBranchEODExistFailure,
	checkBranchEODExistResponseChanged,
	getBranchClosedLoanListSuccess,
	getBranchClosedLoanListFailure,
	getBranchClosedLoanListChanged,
	updateLoanPaidOverAllMoveToArrearSuccess,
	updateLoanPaidOverAllMoveToArrearFailure,
	updateLoanPaidOverAllMoveToArrearResponseChanged,
	updateOverallAccruedInterestAllocateForEODSuccess,
	updateOverallAccruedInterestAllocateForEODFailure,
	updateOverallAccruedInterestAllocateForEODResponseChanged,
	updateOverallAllocateForEODSuccess,
	updateOverallAllocateForEODFailure,
	updateOverallAllocateForEODResponseChanged,
} from './collection.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START OFFICER LIST CENTERS DETAILS  ******************** */
export function* getOfficerListCenters({ payload }) {
	try {
		const key = 'centerListForOfficerKey';
		const jsonData = {
			officerID: payload.officerID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			whenToPay: payload.whenToPay,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchOfficerListCenters, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerListCentersFailure(responseData.data));
		} else {
			yield put(getOfficerListCentersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerListCentersFailure(error));
	}
}

export function* resetGetOfficerListCentersResponse() {
	yield put(getOfficerListCentersResponseChanged());
}

export function* onGetOfficerListCentersDetails() {
	yield takeLatest(CollectionActionTypes.GET_OFFICER_LIST_CENTERS_START, getOfficerListCenters);
}

export function* onGetOfficerListCentersResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_RESET,
		resetGetOfficerListCentersResponse,
	);
}
/** *********************  END GET OFFICER LIST CENTERS  ******************** */

/** *********************  START OFFICER LIST ARREAR CENTERS DETAILS  ******************** */
export function* getOfficerListArrearCenters({ payload }) {
	try {
		const key = 'arrearCenterListForOfficerKey';
		const jsonData = {
			officerID: payload.officerID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchOfficerListArrearCenters, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerListArrearCentersFailure(responseData.data));
		} else {
			yield put(getOfficerListArrearCentersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerListArrearCentersFailure(error));
	}
}

export function* resetGetOfficerListArrearCentersResponse() {
	yield put(getOfficerListArrearCentersResponseChanged());
}

export function* onGetOfficerListArrearCentersDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_START,
		getOfficerListArrearCenters,
	);
}

export function* onGetOfficerListArrearCentersResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET,
		resetGetOfficerListArrearCentersResponse,
	);
}
/** *********************  END GET OFFICER LIST ARREAR CENTERS  ******************** */

/** *********************  START BRANCH COLLECTION USERS LIST DETAILS  ******************** */
export function* getBranchCollectionUsersList({ payload }) {
	try {
		const key = 'branchCollectionUsersListKey';
		let { collectionDate } = payload;
		if (payload.activeTabCollectionType === 'Close Loans') {
			collectionDate = '-';
		}
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			CollectionDate: collectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchCollectionUsersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchCollectionUsersListFailure(responseData.data));
		} else {
			yield put(getBranchCollectionUsersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchCollectionUsersListFailure(error));
	}
}

export function* resetGetBranchCollectionUsersListResponse() {
	yield put(getBranchCollectionUsersListResponseChanged());
}

export function* onGetBranchCollectionUsersListDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
		getBranchCollectionUsersList,
	);
}

export function* onGetBranchCollectionUsersListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
		resetGetBranchCollectionUsersListResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

/** *********************  START CENTER LIST FOR OFFICER COLLECTIONS DETAILS  ******************** */
export function* getCenterListOfficerCollections({ payload }) {
	try {
		const key = 'centerListOfficerCollectionsKey';
		const jsonData = {
			OfficerID: payload.officerID,
			AreaID: payload.branchID,
			DatabaseName: payload.databaseName,
			WhenToPay: payload.collectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterListOfficerCollections, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterListOfficerCollectionsFailure(responseData.data));
		} else {
			yield put(getCenterListOfficerCollectionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterListOfficerCollectionsFailure(error));
	}
}

export function* resetGetCenterListOfficerCollectionsResponse() {
	yield put(getCenterListOfficerCollectionsResponseChanged());
}

export function* onGetCenterListOfficerCollectionsDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_START,
		getCenterListOfficerCollections,
	);
}

export function* onGetCenterListOfficerCollectionsResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET,
		resetGetCenterListOfficerCollectionsResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

/** *********************  START CENTER LIST FOR OFFICER COLLECTIONS DETAILS  ******************** */
export function* getBranchTodayCollectionDetails({ payload }) {
	try {
		const key = 'FetchBranchTodayCollectionDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			RePayDate: payload.collectionDate,
			OfficerID: payload.officerID,
			InfogID: payload.infogCompanyID,
			IsProductConfigNeed: payload.IsProductConfigNeed,
			CollectionMode: payload.activeTabCollectionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchTodayCollectionDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchTodayCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getBranchTodayCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchTodayCollectionDetailsFailure(error));
	}
}

export function* resetGetBranchTodayCollectionDetailsResponse() {
	yield put(getBranchTodayCollectionDetailsChanged());
}

export function* onGetBranchTodayCollectionDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_START,
		getBranchTodayCollectionDetails,
	);
}

export function* onGetBranchTodayCollectionDetailsReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_DETAILS_RESET,
		resetGetBranchTodayCollectionDetailsResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

/** *********************  START BRANCH TODAY DEMAND MOVE TO ARREAR LIST  ******************** */
export function* getBranchTodayDemandMoveToArrearDetails({ payload }) {
	try {
		const key = 'FetchBranchTodayDemandMoveToArrearListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			RePayDate: payload.collectionDate,
			OfficerID: payload.officerID,
			InfogID: payload.infogCompanyID,
			IsProductConfigNeed: payload.IsProductConfigNeed,
			CollectionMode: payload.activeTabCollectionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchTodayDemandMoveToArrearList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchTodayDemandMoveToArrearFailure(responseData.data));
		} else {
			yield put(getBranchTodayDemandMoveToArrearSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchTodayDemandMoveToArrearFailure(error));
	}
}

export function* resetGetBranchTodayDemandMoveToArrearResponse() {
	yield put(getBranchTodayDemandMoveToArrearChanged());
}

export function* onGetBranchTodayDemandMoveToArrear() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_START,
		getBranchTodayDemandMoveToArrearDetails,
	);
}

export function* onGetBranchTodayDemandMoveToArrearReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_DEMAND_MOVETOARREAR_LIST_RESET,
		resetGetBranchTodayDemandMoveToArrearResponse,
	);
}
/** *********************  END GET BRANCH TODAY DEMAND MOVE TO ARREAR LIST  ******************** */

/** *********************  START BRANCH CLOSED LOAN LIST  ******************** */
export function* getBranchClosedLoanListDetails({ payload }) {
	try {
		const key = 'CloseLoanListFetchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			RePayDate: payload.collectionDate,
			OfficerID: payload.officerID,
			CollectionMode: payload.activeTabCollectionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchClosedLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchClosedLoanListFailure(responseData.data));
		} else {
			yield put(getBranchClosedLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchClosedLoanListFailure(error));
	}
}

export function* resetGetBranchClosedLoanListResponse() {
	yield put(getBranchClosedLoanListChanged());
}

export function* onGetBranchClosedLoanList() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_START,
		getBranchClosedLoanListDetails,
	);
}

export function* onGetBranchClosedLoanListReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_CLOSED_LOAN_LIST_RESET,
		resetGetBranchClosedLoanListResponse,
	);
}
/** *********************  END GET BRANCH CLOSED LOAN LIST  ******************** */

/** *********************  START CENTER COLLECTION DENOMINATION DETAILS  ******************** */
export function* getCenterCollectionDenomination({ payload }) {
	try {
		const key = 'FetchCenterCollectionDenominationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: 'All',
			DenominationDate: payload.collectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterCollectionDenominationDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterCollectionDenominationFailure(responseData.data));
		} else {
			yield put(getCenterCollectionDenominationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterCollectionDenominationFailure(error));
	}
}

export function* resetGetCenterCollectionDenominationResponse() {
	yield put(getCenterCollectionDenominationChanged());
}

export function* onGetCenterCollectionDenominationDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_START,
		getCenterCollectionDenomination,
	);
}

export function* onGetCenterCollectionDenominationDetailsReset() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_COLLECTION_DENOMINATION_DETAILS_RESET,
		resetGetCenterCollectionDenominationResponse,
	);
}
/** *********************  END CENTER COLLECTION DENOMINATION DETAILS  ******************** */

/** *********************  START CENTER COLLECTION DENOMINATION DETAILS  ******************** */
export function* getAttendanceOfficerCollection({ payload }) {
	try {
		const key = 'FetchAttendanceBasedOfficerCollectionKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: 'All',
			OfficerID: payload.officerID,
			RePayDate: payload.collectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAttendanceOfficerCollectionDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAttendanceOfficerCollectionFailure(responseData.data));
		} else {
			yield put(getAttendanceOfficerCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAttendanceOfficerCollectionFailure(error));
	}
}

export function* resetGetAttendanceOfficerCollectionResponse() {
	yield put(getAttendanceOfficerCollectionChanged());
}

export function* onGetAttendanceOfficerCollections() {
	yield takeLatest(
		CollectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_START,
		getAttendanceOfficerCollection,
	);
}

export function* onGetAttendanceOfficerCollectionsReset() {
	yield takeLatest(
		CollectionActionTypes.GET_ATTENDANCE_OFFICER_COLLECTION_RESET,
		resetGetAttendanceOfficerCollectionResponse,
	);
}
/** *********************  END CENTER COLLECTION DENOMINATION DETAILS  ******************** */

/** *********************  START BRANCH COLLECTION FUNDER ALLLOCATE EOD DETAILS  ******************** */
export function* getBranchCollectionFunderAllocateEOD({ payload }) {
	try {
		const key = 'BranchCollectionsAllocateForEodKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			InfogID: payload.infogCompanyID,
			OfficerID: payload.officerID,
			RePayDate: payload.collectionDate,
			IsThrift: payload.isThrift,
			IsProductConfigNeed: payload.IsProductConfigNeed,
			IsIndividualSavings: payload.isIndividualSavings,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchCollectionFunderAllocateEODDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchCollectionFunderAllocateEODFailure(responseData.data));
		} else {
			yield put(getBranchCollectionFunderAllocateEODSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchCollectionFunderAllocateEODFailure(error));
	}
}

export function* resetGetBranchCollectionFunderAllocateEODResponse() {
	yield put(getBranchCollectionFunderAllocateEODChanged());
}

export function* resetGetBranchCollectionFunderAllocateEODDataResponse() {
	yield put(getBranchCollectionFunderAllocateEODChangedData());
}

export function* onGetBranchCollectionFunderAllocateEOD() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_START,
		getBranchCollectionFunderAllocateEOD,
	);
}

export function* onGetBranchCollectionFunderAllocateEODReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_RESET,
		resetGetBranchCollectionFunderAllocateEODResponse,
	);
}

export function* onGetBranchCollectionFunderAllocateEODDataReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_FUNDER_ALLOCATE_EOD_CHANGED_DATA,
		resetGetBranchCollectionFunderAllocateEODDataResponse,
	);
}
/** *********************  END BRANCH COLLECTION FUNDER ALLLOCATE EOD DETAILS  ******************** */

/** *********************  START BRANCH TODAY COLLECTIONS YET TO APPROVE DETAILS  ******************** */
export function* getBranchTodayCollectionsYetToApprove({ payload }) {
	try {
		const key = 'BranchTodayCollectionMembersYetToApproveKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			BranchID: payload.branchID,
			InfogID: payload.infogCompanyID,
			RePayDate: payload.collectionDate,
			funderID: payload.funderID,
			productID: payload.productID,
			IsProductConfigNeed: payload.IsProductConfigNeed,
			CollectionMode: payload.CollectionMode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchTodayCollectionsYetToApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchTodayCollectionsYetToApproveFailure(responseData.data));
		} else {
			yield put(getBranchTodayCollectionsYetToApproveSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchTodayCollectionsYetToApproveFailure(error));
	}
}

export function* resetGetBranchTodayCollectionsYetToApproveResponse() {
	yield put(getBranchTodayCollectionsYetToApproveChanged());
}

export function* resetGetBranchTodayCollectionsYetToApproveDataResponse() {
	yield put(getBranchTodayCollectionsYetToApproveChangedData());
}

export function* onGetBranchTodayCollectionsYetToApprove() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_START,
		getBranchTodayCollectionsYetToApprove,
	);
}

export function* onGetBranchTodayCollectionsYetToApproveReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_RESET,
		resetGetBranchTodayCollectionsYetToApproveResponse,
	);
}

export function* onGetBranchTodayCollectionsYetToApproveDataChanged() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_TODAY_COLLECTION_YET_TO_APPROVE_CHANGED_DATA,
		resetGetBranchTodayCollectionsYetToApproveDataResponse,
	);
}
/** *********************  END BRANCH TODAY COLLECTIONS YET TO APPROVE DETAILS  ******************** */

/** *********************  START UPDATE LOAN PAID PER MEMBER COLLECTION APPROVE DETAILS  ******************** */
export function* updateLoanDuePerMemberCollectionApprove({ payload }) {
	try {
		const key = 'UpdateLoanPaidPerMemberCollectionApproveKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			UserID: payload.userID,
			UserName: payload.userName,
			UserLevel: payload.userLevel,
			BranchID: payload.BranchID,
			InfogID: payload.infogCompanyID,
			RePayDate: payload.RePayDate,
			LoanIndexID: payload.LoanIndexID,
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			MemberID: payload.MemberID,
			LoanPaidID: payload.LoanPaidID,
			FunderID: payload.FunderID,
			DemandCollPrincipal: payload.DemandCollPrincipal,
			DemandCollInterest: payload.DemandCollInterest,
			DemandCollTotal: payload.DemandCollTotal,
			AdvanceCollPrincipal: payload.AdvanceCollPrincipal,
			AdvanceCollInterest: payload.AdvanceCollInterest,
			AdvanceCollTotal: payload.AdvanceCollTotal,
			DueNo: payload.DueNo,
			CollectionMode: payload.CollectionMode,
			ApprovedBy: payload.ApprovedBy,
			ApprovedAmount: payload.ApprovedAmount,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanPaidPerMemberCollectionApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanPaidPerMemberCollectionApproveFailure(responseData.data));
		} else {
			yield put(updateLoanPaidPerMemberCollectionApproveSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanPaidPerMemberCollectionApproveFailure(error));
	}
}

export function* resetUpdateLoanPaidPerMemberCollectionApproveResponse() {
	yield put(updateLoanPaidPerMemberCollectionApproveResponseChanged());
}

export function* onUpdateLoanPaidPerMemberCollectionApprove() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_START,
		updateLoanDuePerMemberCollectionApprove,
	);
}

export function* onUpdateLoanPaidPerMemberCollectionApproveReset() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_PER_MEMBER_COLLECTION_APPROVE_RESPONSE_RESET,
		resetUpdateLoanPaidPerMemberCollectionApproveResponse,
	);
}
/** *********************  END UPDATE LOAN PAID PER MEMBER COLLECTION APPROVE  ******************** */

/** *********************  START UPDATE LOAN PAID OVER ALL COLLECTION APPROVE DETAILS  ******************** */
export function* updateLoanPaidOverallCollectionApprove({ payload }) {
	try {
		const key = 'UpdateLoanPaidCollectionApproveKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			LoanPaidList: payload.LoanPaidList,
			DatabaseName: payload.DatabaseName,
			ApprovedBy: payload.ApprovedBy,
			ApprovedAmount: payload.ApprovedAmount,
			UserName: payload.UserName,
			BranchID: payload.BranchID,
			LocationID: payload.LocationID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanPaidAllCollectionApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanPaidOverallCollectionApproveFailure(responseData.data));
		} else {
			yield put(updateLoanPaidOverallCollectionApproveSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanPaidOverallCollectionApproveFailure(error));
	}
}

export function* resetUpdateLoanPaidOverallCollectionApproveResponse() {
	yield put(updateLoanPaidOverallCollectionApproveResponseChanged());
}

export function* onUpdateLoanPaidOverallCollectionApprove() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_START,
		updateLoanPaidOverallCollectionApprove,
	);
}

export function* onUpdateLoanPaidOverallCollectionApproveReset() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_OVERALL_COLLECTION_APPROVE_RESPONSE_RESET,
		resetUpdateLoanPaidOverallCollectionApproveResponse,
	);
}
/** *********************  END UPDATE LOAN PAID OVER ALL COLLECTION APPROVE  ******************** */

/** *********************  START UPDATE LOAN PAID OVER ALL COLLECTION APPROVE DETAILS  ******************** */
export function* commonCollectionUpdateStart({ payload }) {
	try {
		const CollectionLoanArr = JSON.stringify(payload.collectionLoanArr).replace(/"/g, "'");
		const key = 'UpdateCommonLoanPaidDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CollectionLoanArr,
			CollectionDate: payload.CollectionDate,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserName: payload.userName,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			twoThousand: 0,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			TotalDenomination: payload.totalDenomination,
			IsAllCenter: payload.isAllCenter,
			IsGroup: payload.isGroup,
			collectionPage: payload.PaymentFrom,
			paymentMethod: payload.paymentMethod,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(commonCollectionUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(commonCollectionUpdateFailure(responseData.data));
		} else {
			yield put(commonCollectionUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(commonCollectionUpdateFailure(error));
	}
}

export function* resetcommonCollectionUpdateApproveResponse() {
	yield put(commonCollectionUpdateResponseChanged());
}

export function* oncommonCollectionUpdateApprove() {
	yield takeLatest(
		CollectionActionTypes.COMMON_COLLECTION_UPDATE_START,
		commonCollectionUpdateStart,
	);
}

export function* oncommonCollectionUpdateApproveReset() {
	yield takeLatest(
		CollectionActionTypes.COMMON_COLLECTION_UPDATE_RESPONSE_RESET,
		resetcommonCollectionUpdateApproveResponse,
	);
}
/** *********************  END UPDATE LOAN PAID OVER ALL COLLECTION APPROVE  ******************** */

/** *********************  CHECK BRANCH EOD EXIST  ******************** */
export function* checkBranchEODExistStart({ payload }) {
	try {
		const key = 'checkBranchEODExistKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			InputDate: payload.inputDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(checkBranchEODExist, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(checkBranchEODExistFailure(responseData.data));
		} else {
			yield put(checkBranchEODExistSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkBranchEODExistFailure(error));
	}
}

export function* resetcheckBranchEODExistApproveResponse() {
	yield put(checkBranchEODExistResponseChanged());
}

export function* oncheckBranchEODExistApprove() {
	yield takeLatest(CollectionActionTypes.CHECK_BRANCH_EOD_EXIST_START, checkBranchEODExistStart);
}

export function* oncheckBranchEODExistApproveReset() {
	yield takeLatest(
		CollectionActionTypes.CHECK_BRANCH_EOD_EXIST_RESPONSE_RESET,
		resetcheckBranchEODExistApproveResponse,
	);
}
/** *********************  CHECK BRANCH EOD EXIST  ******************** */

/** *********************  START UPDATE LOAN PAID OVER ALL MOVE TO ARREAR DETAILS  ******************** */
export function* updateLoanPaidOverAllMoveToArrear({ payload }) {
	try {
		const key = 'loanAllMemberMoveToArrearKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			LoanPaidList: payload.LoanPaidList,
			DatabaseName: payload.DatabaseName,
			UserID: payload.UserID,
			BranchName: payload.BranchName,
			CenterName: payload.CenterName,
			UserName: payload.UserName,
			BranchID: payload.BranchID,
			DemandAmount: payload.DemandAmount,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateLoanPaidMoveToArrear, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanPaidOverAllMoveToArrearFailure(responseData.data));
		} else {
			yield put(updateLoanPaidOverAllMoveToArrearSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanPaidOverAllMoveToArrearFailure(error));
	}
}

export function* resetUpdateLoanPaidOverAllMoveToArrearResponse() {
	yield put(updateLoanPaidOverAllMoveToArrearResponseChanged());
}

export function* onUpdateLoanPaidOverAllMoveToArrear() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_START,
		updateLoanPaidOverAllMoveToArrear,
	);
}

export function* onUpdateLoanPaidOverAllMoveToArrearReset() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_LOANPAID_OVERALL_MOVE_TO_ARREAR_RESPONSE_RESET,
		resetUpdateLoanPaidOverAllMoveToArrearResponse,
	);
}
/** *********************  END UPDATE LOAN PAID OVER ALL MOVE TO ARREAR  ******************** */

/** *********************  START UPDATE OVER ALL ACCRUED INTEREST ALLOCATE FOR EOD ******************** */
export function* updateOverallAccruedInterestAllocateForEOD({ payload }) {
	try {
		const key = 'UpdateAccruedInterestAllocateForEODKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			LoanAccruedInterestList: payload.LoanAccruedInterestList,
			DatabaseName: payload.DatabaseName,
			AccruedInterestAmount: payload.AccruedInterestAmount,
			CreatedBy: payload.CreatedBy,
			UserName: payload.UserName,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			InfogCompanyID: payload.InfogCompanyID,
			GetDueDate: payload.GetDueDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateOverallAccruedInterestAllocateForEODCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateOverallAccruedInterestAllocateForEODFailure(responseData.data));
		} else {
			yield put(updateOverallAccruedInterestAllocateForEODSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateOverallAccruedInterestAllocateForEODFailure(error));
	}
}

export function* resetUpdateOverallAccruedInterestAllocateForEODResponse() {
	yield put(updateOverallAccruedInterestAllocateForEODResponseChanged());
}

export function* onUpdateOverallAccruedInterestAllocateForEOD() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_START,
		updateOverallAccruedInterestAllocateForEOD,
	);
}

export function* onUpdateOverallAccruedInterestAllocateForEODReset() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_OVERALL_ACCRUED_INTEREST_ALLOCATE_FOR_EOD_RESPONSE_RESET,
		resetUpdateOverallAccruedInterestAllocateForEODResponse,
	);
}
/** *********************  END UPDATE OVER ALL ACCRUED INTEREST ALLOCATE FOR EOD  ******************** */

/** *********************  START UPDATE OVER ALL ALLOCATE FOR EOD ******************** */
export function* updateOverallAllocateForEOD({ payload }) {
	try {
		const key = 'BranchCollectionsAllocateForEODFinanceKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			BranchID: payload.BranchID,
			ProductID: payload.ProductID,
			FunderID: payload.FunderID,
			EodDate: payload.EodDate,
			IsProductConfigNeed: payload.IsProductConfigNeed,
			IsThrift: payload.IsThrift,
			BranchAccountID: payload.BranchAccountID,
			funderPrincipalAccountID: payload.funderPrincipalAccountID,
			funderInterestAccountID: payload.funderInterestAccountID,
			funderPrincipalAmount: payload.funderPrincipalAmount,
			funderInterestAmount: payload.funderInterestAmount,
			BranchBankAccountID: payload.BranchBankAccountID,
			funderBankPrincipalAmount: payload.funderBankPrincipalAmount,
			funderBankInterestAmount: payload.funderBankInterestAmount,
			narrationPrincipal: payload.narrationPrincipal,
			narrationInterest: payload.narrationInterest,
			InfogID: payload.InfogID,
			CreatedBy: payload.CreatedBy,
			CreatedFor: payload.CreatedFor,
			IsReceipts: payload.IsReceipts,
			IsPayments: payload.IsPayments,
			IsCash: payload.IsCash,
			IsBank: payload.IsBank,
			bankName: payload.bankName,
			chequeNo: payload.chequeNo,
			chequeDate: payload.chequeDate,
			partyBankName: payload.partyBankName,
			partyChequeNo: payload.partyChequeNo,
			partyChequeDate: payload.partyChequeDate,
			instrumentID: payload.instrumentID,
			WalletAccountID: payload.WalletAccountID,
			totalWalletAdvanceAmount: payload.totalWalletAdvanceAmount,
			ThriftAccountID: payload.ThriftAccountID,
			totalThriftAmount: payload.totalThriftAmount,
			WalletAdvanceListArr: payload.WalletAdvanceListArr,
			WalletPayListArr: payload.WalletPayListArr,
			ThriftUsedAmountListArr: payload.ThriftUsedAmountListArr,
			LogNotes: payload.LogNotes,
			PenaltyPaidAmount: payload.PenaltyPaidAmount,
			PenaltyAccountID: payload.PenaltyAccountID,
			IsTopupPrecloseExists: payload.IsTopupPrecloseExists,
			funderJournalPrincipalAmount: payload.funderJournalPrincipalAmount,
			funderJournalInterestAmount: payload.funderJournalInterestAmount,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateOverallAllocateForEODCall, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateOverallAllocateForEODFailure(responseData.data));
		} else {
			yield put(updateOverallAllocateForEODSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateOverallAllocateForEODFailure(error));
	}
}

export function* resetUpdateOverallAllocateForEODResponse() {
	yield put(updateOverallAllocateForEODResponseChanged());
}

export function* onUpdateOverallAllocateForEOD() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_START,
		updateOverallAllocateForEOD,
	);
}

export function* onUpdateOverallAllocateForEODReset() {
	yield takeLatest(
		CollectionActionTypes.UPDATE_OVERALL_ALLOCATE_FOR_EOD_RESPONSE_RESET,
		resetUpdateOverallAllocateForEODResponse,
	);
}
/** *********************  END UPDATE OVER ALL ALLOCATE FOR EOD  ******************** */

export function* collectionSaga() {
	yield all([
		call(onGetOfficerListCentersDetails),
		call(onGetOfficerListCentersResponseReset),
		call(onGetOfficerListArrearCentersDetails),
		call(onGetOfficerListArrearCentersResponseReset),
		call(onGetBranchCollectionUsersListDetails),
		call(onGetBranchCollectionUsersListResponseReset),
		call(onGetCenterListOfficerCollectionsDetails),
		call(onGetCenterListOfficerCollectionsResponseReset),
		call(onGetBranchTodayCollectionDetails),
		call(onGetBranchTodayCollectionDetailsReset),
		call(onGetBranchTodayDemandMoveToArrear),
		call(onGetBranchTodayDemandMoveToArrearReset),
		call(onGetCenterCollectionDenominationDetails),
		call(onGetCenterCollectionDenominationDetailsReset),
		call(onGetAttendanceOfficerCollections),
		call(onGetAttendanceOfficerCollectionsReset),
		call(onGetBranchCollectionFunderAllocateEOD),
		call(onGetBranchCollectionFunderAllocateEODReset),
		call(onGetBranchTodayCollectionsYetToApprove),
		call(onGetBranchTodayCollectionsYetToApproveReset),
		call(onUpdateLoanPaidPerMemberCollectionApprove),
		call(onUpdateLoanPaidPerMemberCollectionApproveReset),
		call(onUpdateLoanPaidOverallCollectionApprove),
		call(onUpdateLoanPaidOverallCollectionApproveReset),
		call(oncommonCollectionUpdateApprove),
		call(oncommonCollectionUpdateApproveReset),
		call(oncheckBranchEODExistApprove),
		call(oncheckBranchEODExistApproveReset),
		call(onGetBranchClosedLoanList),
		call(onGetBranchClosedLoanListReset),
		call(onUpdateLoanPaidOverAllMoveToArrear),
		call(onUpdateLoanPaidOverAllMoveToArrearReset),
		call(onUpdateOverallAccruedInterestAllocateForEOD),
		call(onUpdateOverallAccruedInterestAllocateForEODReset),
		call(onUpdateOverallAllocateForEOD),
		call(onUpdateOverallAllocateForEODReset),
	]);
}
