const BranchDCBReportActionTypes = {
	GET_BRANCH_DCB_REPORT_LIST_START: 'GET_BRANCH_DCB_REPORT_LIST_START',
	GET_BRANCH_DCB_REPORT_LIST_SUCCESS: 'GET_BRANCH_DCB_REPORT_LIST_SUCCESS',
	GET_BRANCH_DCB_REPORT_LIST_FAILURE: 'GET_BRANCH_DCB_REPORT_LIST_FAILURE',
	GET_BRANCH_DCB_REPORT_LIST_RESPONSE_RESET: 'GET_BRANCH_DCB_REPORT_LIST_RESPONSE_RESET',
	GET_BRANCH_DCB_REPORT_LIST_RESPONSE_CHANGED: 'GET_BRANCH_DCB_REPORT_LIST_RESPONSE_CHANGED',

	GET_BRANCH_DCB_REPORT_EXCEL_START: 'GET_BRANCH_DCB_REPORT_EXCEL_START',
	GET_BRANCH_DCB_REPORT_EXCEL_SUCCESS: 'GET_BRANCH_DCB_REPORT_EXCEL_SUCCESS',
	GET_BRANCH_DCB_REPORT_EXCEL_FAILURE: 'GET_BRANCH_DCB_REPORT_EXCEL_FAILURE',
	GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_RESET: 'GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_RESET',
	GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_CHANGED: 'GET_BRANCH_DCB_REPORT_EXCEL_RESPONSE_CHANGED',
};
export default BranchDCBReportActionTypes;
