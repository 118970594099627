import PhotoVerificationActionType from './photo-verification.types';

// Photo Verification
export const addMemberPhotoStart = (photoVerificationCredentials) => ({
	type: PhotoVerificationActionType.ADD_MEMBER_PHOTO_START,
	payload: photoVerificationCredentials,
});

export const addMemberPhotoSuccess = (photoVerificationSuccess) => ({
	type: PhotoVerificationActionType.ADD_MEMBER_PHOTO_SUCCESS,
	payload: photoVerificationSuccess,
});

export const addMemberPhotoFailure = (photoVerificationFailure) => ({
	type: PhotoVerificationActionType.ADD_MEMBER_PHOTO_FAILURE,
	payload: photoVerificationFailure,
});

export const addMemberPhotoResponseResetStart = () => ({
	type: PhotoVerificationActionType.ADD_MEMBER_PHOTO_RESPONSE_REST_START,
});

export const addMemberPhotoUserResponseChanged = () => ({
	type: PhotoVerificationActionType.ADD_MEMBER_PHOTO_RESPONSE_CHANGED,
});

// Image Process
export const imageProcessStart = (photoVerificationCredentials) => ({
	type: PhotoVerificationActionType.IMAGE_PROCESS_START,
	payload: photoVerificationCredentials,
});

export const imageProcessSuccess = (photoVerificationSuccess) => ({
	type: PhotoVerificationActionType.IMAGE_PROCESS_SUCCESS,
	payload: photoVerificationSuccess,
});

export const imageProcessFailure = (photoVerificationFailure) => ({
	type: PhotoVerificationActionType.IMAGE_PROCESS_FAILURE,
	payload: photoVerificationFailure,
});

export const imageProcessResponseResetStart = () => ({
	type: PhotoVerificationActionType.IMAGE_PROCESS_RESPONSE_REST_START,
});

export const imageProcessResponseChanged = () => ({
	type: PhotoVerificationActionType.IMAGE_PROCESS_RESPONSE_CHANGED,
});
