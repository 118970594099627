// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const dashboardDetailsGet = (listData) => {
	const data = {
		GetDashboardCardDetailsToken: listData,
	};
	const url = `${API_URL}GetDashboardCardDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanDetailsGet = (listData) => {
	const data = {
		GetDashboardLoanDetailsToken: listData,
	};
	const url = `${API_URL}GetDashboardLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanIndexDetailsGet = (listData) => {
	const data = {
		DashboardLoanIndexDetailsToken: listData,
	};
	const url = `${API_URL}GetDashboardLoanIndexDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const companyEodStatusGet = (listData) => {
	const data = {
		DashboardEODStatusDetailsToken: listData,
	};
	const url = `${API_URL}GetDashboardEndOfDayStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardWorkflowDetailsGet = (listData) => {
	const data = {
		GetWorkFlowDashboardDetailsToken: listData,
	};
	const url = `${API_URL}WorkflowDashboardDetailsGet`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const todayGoldPriceGet = (listData) => {
	const data = {
		GetTodayGoldPriceToken: listData,
	};
	const url = `${API_URL}TodayGoldPriceGet`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardDayWiseExcelApi = (listData) => {
	const params = new URLSearchParams();
	params.append('DashboardDayWiseExcelToken', listData);
	const url = `${API_URL}Report/Excel/DashboardDayWiseExcel.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const dashboardDayWiseExcelJsonApi = (listData) => {
	const data = {
		DashboardLoanDetailsExportToken: listData,
	};
	const url = `${API_URL}GetDashboardLoanDetailsExport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const todayGoldPriceUpdate = (listData) => {
	const data = {
		UpdateTodayGoldPriceToken: listData,
	};
	const url = `${API_URL}TodayGoldPriceUpdate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const pdfEodStatus = (listData) => {
	const params = new URLSearchParams();
	let url = `${API_URL}Report/tcpdf/pdf/EODStatusPDF.php`;

	params.append('EODStatusPDfToken', listData);
	url = `${API_URL}Report/tcpdf/pdf/EODStatusPDF.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const rejectMemberListCall = (listData) => {
	const data = {
		RejectedMembersDetailsToken: listData,
	};
	const url = `${API_URL}GetRejectedMembersDetailsInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardParticularListCall = (listData) => {
	const data = {
		DashboardCardBranchwiseToken: listData,
	};
	const url = `${API_URL}GetDashboardCardBranchwise`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardParticularDemandListCall = (listData) => {
	const data = {
		DashboardAmountBasedonlocationToken: listData,
	};
	const url = `${API_URL}GetDashboardAmountBasedonlocation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const dashboardStaffCollectionListCall = (listData) => {
	const data = {
		StaffTotalcollectionToken: listData,
	};
	const url = `${API_URL}GetDashboardStaffTotalcollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardStaffLocationListCall = (listData) => {
	const data = {
		GetCompanyBasedOnStaffLocationToken: listData,
	};
	const url = `${API_URL}GetCompanyBasedOnStaffLocation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// DEMAND SECTION
export const dashboardParticularDateDemandListCall = (listData) => {
	const data = {
		ParticularDateGroupDemandInfoToken: listData,
	};
	const url = `${API_URL}GetParticularDateGroupDemandInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardParticularDateDemandMemberListCall = (listData) => {
	const data = {
		ParticularDateGroupMemberInfoToken: listData,
	};
	const url = `${API_URL}GetParticularDateGroupMemberInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardParticularDateMemberAbsentListCall = (listData) => {
	const data = {
		DashboardMemberAbsentDetailsToken: listData,
	};
	const url = `${API_URL}GetMemberAbsentDueList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// COLLECTION SECTION
export const dashboardParticularDateCollectionListCall = (listData) => {
	const data = {
		DashboardParticularDateGroupCollectionToken: listData,
	};
	const url = `${API_URL}GetParticularDateGroupCollectionInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardParticularMemberCollectionListCall = (listData) => {
	const data = {
		GetParticularDateGroupMemberCollectionToken: listData,
	};
	const url = `${API_URL}GetParticularDateGroupMemberCollectionInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardWeekCollectionListCall = (listData) => {
	const data = {
		CollectionAmountBranchMemberWiseToken: listData,
	};
	const url = `${API_URL}GetDashboardCollectionAmountBranchMemberWise`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardWeekYTCollectionListCall = (listData) => {
	const data = {
		GetParticularCollectionPendingGroupMemberToken: listData,
	};
	const url = `${API_URL}GetParticularDateCollectionPendingGroupMemberInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const smsUsedListCall = (listData) => {
	const data = {
		DashboardSMSUsedListToken: listData,
	};
	const url = `${API_URL}DashboardSMSUsedListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const kycUsedListCall = (listData) => {
	const data = {
		DashboardKYCUsedListToken: listData,
	};
	const url = `${API_URL}DashboardKYCUsedListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const arrearCollectionListCall = (listData) => {
	const data = {
		DashboardArrearCollectionListToken: listData,
	};
	const url = `${API_URL}DashboardArrearCollectionListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardEodExportCall = (listData) => {
	const params = new URLSearchParams();
	params.append('DashboardEodStatusExportToken', listData);
	const url = `${API_URL}Report/Excel/DashboardEodStatusExport.php`;

	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
export const dashboardStaffMapCollectionCall = (listData) => {
	const data = {
		DashboardStaffMapCollectionToken: listData,
	};
	const url = `${API_URL}GetOfficerGroupLoanListByDay`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardCollectionNoteInfoCall = (listData) => {
	const data = {
		GetCollectionNotesInfoToken: listData,
	};
	const url = `${API_URL}GetCollectionNotesInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
