/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import AddressExtraProofActionType from './address-extra-proof.types';
import {
	updateAddressExtraProofSuccess,
	updateAddressExtraProofFailure,
	updateAddressExtraProofResponseChanged,
} from './address-extra-proof.action';
import { encodeJson } from '../../../enode-decode';
import { addressExtraProofUpdate } from './address-extra-proof.service';

// Start Address Extra Proof  Add
export function* updateAddressExtraProof({ payload }) {
	try {
		const key = 'updateAddressExtraProofID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			AddressExtraProofImg: payload.addressExtraProofImg,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const addressExtraProofData = encodeJson(jsonData, key);
		const responseData = yield call(addressExtraProofUpdate, addressExtraProofData);
		if (responseData.data.statusCode === '100') {
			yield put(updateAddressExtraProofFailure(responseData.data));
		} else {
			yield put(updateAddressExtraProofSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateAddressExtraProofFailure(error));
	}
}

export function* updateAddressExtraProofResponseClear() {
	yield put(updateAddressExtraProofResponseChanged());
}

export function* onAddressExtraProofUpdateStart() {
	yield takeLatest(
		AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_START,
		updateAddressExtraProof,
	);
}

export function* onAddressExtraProofUpdateResponseRest() {
	yield takeLatest(
		AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_REST_START,
		updateAddressExtraProofResponseClear,
	);
}
// End Address Extra Proof  Add

export function* addressExtraProofSaga() {
	yield all([call(onAddressExtraProofUpdateStart), call(onAddressExtraProofUpdateResponseRest)]);
}
