/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ChangeMeetingActionTypes from './change-meeting.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getChangeMeetingListResponse: null,
	getChangeMeetingListArr: [],

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_SUCCESS:
			return {
				...state,
				getChangeMeetingListResponse: action.payload,
				getChangeMeetingListArr: docodeResponse(action.payload, 'GetChangeMeetingReportKey'),
			};

		case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_FAILURE:
			return { ...state, getChangeMeetingListResponse: action.payload };

		case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_RESPONSE_CHANGED:
			return { ...state, getChangeMeetingListResponse: null };

            case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_EXPORT_SUCCESS:
                return {
                    ...state,
                    getChangeMeetingListExportResponse: action.payload,
                };
    
            case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_EXPORT_FAILURE:
                return { ...state, getChangeMeetingListExportResponse: action.payload };
    
            case ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_EXPORT_RESPONSE_CHANGED:
                return { ...state, getChangeMeetingListExportResponse: null };

        case ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };
        default:
            return state;
    }
}