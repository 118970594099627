/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */

// export const API_URL = 'https://api.squarenow.in/SquareNowWeb/';
export const API_URL = 'https://dev.api.squarenow.in/SquareNowWeb/';
// export const API_URL = 'http://localhost:8888/mfiapi/SquareNowWeb/';

// export const APP_API_URL = 'https://api.squarenow.in/SquareNowWeb/SquareNowApp/';
export const APP_API_URL = 'https://dev.api.squarenow.in/SquareNowWeb/SquareNowApp/';
// export const APP_API_URL = 'http://localhost:8888/mfiapi/SquareNowWeb/SquareNowApp/';

// export const EXPORT_API_URL = 'https://api.squarenow.in/';
export const EXPORT_API_URL = 'https://dev.api.squarenow.in/';
// export const EXPORT_API_URL = 'http://localhost:8888/mfiapi/';

export const S3_API_URL = 'https://s3-ap-south-1.amazonaws.com/squarenow-production/';
