/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import AadharDetailsActionType from './aadhar-details.types';

const INITIAL_STATE = {
	aadharFrontImage: '',
	aadharBackImage: '',
	updateAadharResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AadharDetailsActionType.AADHAR_FRONT_IMAGE_CHANGED:
			return { ...state, aadharFrontImage: action.payload };

		case AadharDetailsActionType.AADHAR_BACK_IMAGE_CHANGED:
			return { ...state, aadharBackImage: action.payload };

		case AadharDetailsActionType.UPDATE_AADHAR_SUCCESS:
			return { ...state, updateAadharResponse: action.payload };

		case AadharDetailsActionType.UPDATE_AADHAR_FAILURE:
			return { ...state, updateAadharResponse: action.payload };

		case AadharDetailsActionType.UPDATE_AADHAR_RESPONSE_CHANGED:
			return { ...state, updateAadharResponse: null };

		default:
			return state;
	}
};
