import PassportActionType from './passport-details.types';

// update PassportId
export const updatePassportStart = (passportIdCredentials) => ({
	type: PassportActionType.UPDATE_PASSPORT_START,
	payload: passportIdCredentials,
});

export const updatePassportSuccess = (passportIdSuccess) => ({
	type: PassportActionType.UPDATE_PASSPORT_SUCCESS,
	payload: passportIdSuccess,
});

export const updatePassportFailure = (passportIdFailure) => ({
	type: PassportActionType.UPDATE_PASSPORT_FAILURE,
	payload: passportIdFailure,
});

export const updatePassportResponseResetStart = () => ({
	type: PassportActionType.UPDATE_PASSPORT_RESPONSE_REST_START,
});

export const updatePassportResponseChanged = () => ({
	type: PassportActionType.UPDATE_PASSPORT_RESPONSE_CHANGED,
});
