/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchArrearLoanList = (listData) => {
	const data = {
		FetchArrearMemberListDataToken: listData,
	};
	const url = `${API_URL}GetDemandForArrearCollectionWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const arrearCollectionRevertAPI = (listData) => {
	const data = {
		RevertArrearForTodayCollectionToken: listData,
	};
	const url = `${API_URL}RevertArrearCollection`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const arrearCollectionAPI = (listData) => {
	const data = {
		UpdateCommonLoanPaidDetailsToken: listData,
	};
	const url = `${API_URL}UpdateCommonLoanPaid`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};