export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
		hide: true,
	},
	dashboardBooking: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
		hide: true,
	},
	crmDashboard: {
		id: 'crmDashboard',
		text: 'CRM Dashboard',
		path: 'crm/dashboard',
		icon: 'RecentActors',
		hide: true,
	},
	summary: {
		id: 'summary',
		text: 'Summary',
		path: 'summary',
		icon: 'sticky_note_2',
		subMenu: null,
		hide: true,
	},
};
export const superAdminPages = {
	Company: {
		id: 'company',
		text: 'Company',
		path: '/company',
		icon: 'Building',
	},
};

export const demoPages = {
	userControlAccess: {
		id: 'userControlAccess',
		text: 'User Control Access',
		path: '/userControlAccess',
		icon: 'Inboxes',
		isView: true,
		subMenu: {
			assignUserRights: {
				id: 'assignUserRights',
				text: 'Assign User Rights',
				path: '/userControlAccess/assignUserRights',
				icon: 'list',
				isView: true,
			},
			memberMandatoryAccess: {
				id: 'memberMandatoryAccess',
				text: 'Member Mandatory Access',
				path: '/userControlAccess/memberMandatoryAccess',
				icon: 'list',
				isView: true,
			},
			highmarkRules: {
				id: 'highmarkRules',
				text: 'Highmark Rules',
				path: '/userControlAccess/highmarkRules',
				icon: 'list',
				isView: true,
			},
			workflowmanagement: {
				id: 'workflowmanagement',
				text: 'Work Flow Management',
				path: '/userControlAccess/workflowmanagement',
				icon: 'list',
				isView: true,
			},
			ruleEngine: {
				id: 'ruleEngine',
				text: 'Rule Engine',
				path: '/userControlAccess/ruleEngine',
				icon: 'Engineering',
				isView: true,
			},
		},
	},
	masterPages: {
		id: 'master',
		text: 'Master',
		path: '/master',
		icon: 'Inboxes',
		isView: true,
		subMenu: {
			branch: {
				id: 'branch',
				text: 'Branch',
				path: '/master/branch',
				icon: 'AddBusiness',
				isView: true,
			},

			center: {
				id: 'center',
				text: 'Center',
				path: '/master/center',
				icon: 'Apartment',
				isView: true,
			},
			role: {
				id: 'role',
				text: 'Role',
				path: '/master/role',
				icon: 'GroupAdd',
				isView: true,
			},

			staff: {
				id: 'staff',
				text: 'Staff',
				path: '/master/staff',
				icon: 'SupervisedUserCircle',
				isView: true,
			},
			thrift: {
				id: 'thrift',
				text: 'Loan Advance',
				path: '/master/loan_advance',
				icon: 'MonetizationOn',
				isView: true,
			},
			member: {
				id: 'member',
				text: 'Member',
				path: '/master/member',
				icon: 'PeopleAlt',
				isView: true,
			},
			// memberApprove: {
			// 	id: 'memberapprove',
			// 	text: 'Member Approve',
			// 	path: '/master/member/member-approve',
			// 	icon: 'SupervisedUserCircle',
			// },
			group: {
				id: 'group',
				text: 'Group',
				path: '/master/group',
				icon: 'PeopleAlt',
				isView: true,
			},
			insurance_company: {
				id: 'insurance_company',
				text: 'Insurance Company',
				path: '/master/insurance_company',
				icon: 'AccountTree',
				isView: true,
			},

			fetchProductType: {
				id: 'fetchProductType',
				text: 'Product Type',
				path: '/master/fetchProductType',
				icon: 'DonutSmall',
				isView: true,
			},

			product: {
				id: 'product',
				text: 'Product',
				path: '/master/product',
				icon: 'Grain',
				isView: true,
			},
			funder: {
				id: 'funder',
				text: 'Funder',
				path: '/master/Funder',
				icon: 'OfflineBolt',
				isView: true,
			},
			economic_activity_type: {
				id: 'economic_activity_type',
				text: 'Economic Activity Type',
				path: '/master/economic_activity_type',
				icon: 'VolunteerActivism',
				isView: true,
			},

			economicActivity: {
				id: 'economicActivity',
				text: 'Economic Activity',
				path: '/master/economicActivity',
				icon: 'LocalActivity',
				isView: true,
			},
			purpose_of_loan: {
				id: 'purpose_of_loan',
				text: 'Purpose Of Loan',
				path: '/master/purpose_of_loan',
				icon: 'VolunteerActivism',
				isView: true,
			},
			questionnaire: {
				id: 'questionnaire',
				text: 'Questionnaire',
				path: '/master/questionnaire',
				icon: 'LocalActivity',
				isView: true,
			},

			// loan_application: {
			// 	id: 'loan_application',
			// 	text: 'Loan Application',
			// 	path: '/master/loanapplication',
			// 	icon: 'Apps',
			// 	isView: true,
			// },

			business: {
				id: 'business',
				text: 'Business',
				path: '/business',
				icon: 'Building',
				isView: true,
			},
			business_fund: {
				id: 'business_fund',
				text: 'Business_fund',
				path: '/master/business_fund',
				icon: 'MonetizationOn',
				isView: true,
			},
		},
	},
	operationPages: {
		id: 'operations',
		text: 'Operations',
		path: '/operations',
		icon: 'Inboxes',
		isView: true,
		subMenu: {
			auditTool: {
				id: 'auditTool',
				text: 'Auditing Tool',
				path: '/operations/auditTool',
				icon: 'FormatListNumbered',
				isView: true,
			},
			todayCollection: {
				id: 'todayCollection',
				text: 'Today Collection',
				path: '/operations/todayCollection',
				icon: 'Downloading',
				isView: true,
			},
			demandCollection: {
				id: 'demandCollection',
				text: 'Demand Collection',
				path: '/operations/demandCollection',
				icon: 'FormatListNumbered',
				isView: true,
			},
			memberCollection: {
				id: 'memberCollection',
				text: 'Member Collection',
				path: '/operations/memberCollection',
				icon: 'Person',
				isView: true,
			},
			advanceCollection: {
				id: 'advanceCollection',
				text: 'Advance Collection',
				path: '/operations/advanceCollection',
				icon: 'Group',
				isView: true,
			},
			arrearCollection: {
				id: 'arrearCollection',
				text: 'Arrear Collection',
				path: '/operations/arrearCollection',
				icon: 'Group',
				isView: true,
			},
			writeOffCollection: {
				id: 'writeOffCollection',
				text: 'Write-Off Collection',
				path: '/operations/writeOffCollection',
				icon: 'Group',
				isView: true,
			},
			collectionApprove: {
				id: 'collectionApprove',
				text: 'Collection Approve',
				path: '/operations/collectionApprove',
				icon: 'FormatListNumbered',
				isView: true,
			},
			preCloseLoan: {
				id: 'preCloseLoan',
				text: 'Pre-Close Loan',
				path: '/operations/preCloseLoan',
				icon: 'ListAlt',
				isView: true,
			},
			closeLoan: {
				id: 'closeLoan',
				text: 'Close Loan',
				path: '/operations/closeLoan',
				icon: 'ListAlt',
				isView: true,
			},
			endOfDay: {
				id: 'endOfDay',
				text: 'End Of Day',
				path: '/operations/endOfDay',
				icon: 'SyncAlt',
				isView: true,
			},
			changeGroupOfficer: {
				id: 'changeGroupOfficer',
				text: 'Change Group Officer',
				path: '/operations/changeGroupOfficer',
				icon: 'SyncAlt',
				isView: true,
			},
			changeMeetingDate: {
				id: 'ChangeMeetingDate',
				text: 'Change Meeting Date',
				path: '/operations/ChangeMeetingDate',
				icon: 'ListAlt',
				isView: true,
			},
		},
	},
	loanProposalPages: {
		id: 'loanProposalPages',
		text: 'Loan Proposal',
		path: '/loanProposal',
		icon: 'BorderColor',
		hide: true,
		isView: true,
		subMenu: {
			BusinessLoanProposal: {
				id: 'BusinessLoanProposal',
				text: 'Business Loan',
				path: '/loanProposalPages/BusinessLoanProposal',
				icon: 'Building',
				isView: true,
			},
			CenterWiseLoanProposal: {
				id: 'CenterWiseLoanProposal',
				text: 'Center Wise Loan',
				path: '/loanProposalPages/centerWiseLoanProposal',
				icon: 'Building',
				isView: true,
			},
			ProductLoanProposal: {
				id: 'ProductLoanProposal',
				text: 'Product Loan',
				path: '/loanProposalPages/ProductLoanProposal',
				icon: 'Building',
				isView: true,
			},
			CustomProductLoanProposal: {
				id: 'CustomProductLoanProposal',
				text: 'Custom Product Loan',
				path: '/loanProposalPages/CustomProductLoanProposal',
				icon: 'Building',
				isView: true,
			},
			GoldLoanProposal: {
				id: 'GoldLoanProposal',
				text: 'Gold Loan',
				path: '/loanProposalPages/GoldLoanProposal',
				icon: 'Building',
				isView: true,
			},
			RangeLoanProposal: {
				id: 'RangeLoanProposal',
				text: 'Range Product Loan',
				path: '/loanProposalPages/RangeLoanProposal',
				icon: 'Building',
				isView: true,
			},
		},
	},
	loanDisbursementPages: {
		id: 'loanDisbursementPages',
		text: 'Loan Disbursement',
		path: '/loanDisbursement',
		icon: 'BarChart',
		hide: true,
		isView: true,
		subMenu: {
			BusinessLoanDisbursement: {
				id: 'BusinessLoanDisbursement',
				text: 'Business Disbursement',
				path: '/loanDisbursmsntPages/BusinessLoanDisbursement',
				icon: 'Badge',
				isView: true,
			},
			LoanDisbursement: {
				id: 'LoanDisbursement',
				text: 'Loan Index Disbursement',
				path: '/loanDisbursmsntPages/LoanDisbursement',
				icon: 'Badge',
				isView: true,
			},
		},
	},
	reportPages: {
		id: 'reportPages',
		text: 'Reports',
		path: '/reports',
		icon: 'Inboxes',
		hide: true,
		isView: true,
		subMenu: {
			RequestReport: {
				id: 'RequestReport',
				text: 'Request Report',
				path: '/reportPages/RequestReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			LoanDisbursement: {
				id: 'LoanDisbursement',
				text: 'Loan Disbursement',
				path: '/reportPages/LoanDisbursement',
				icon: 'FormatListNumbered',
				isView: true,
			},
			DemandCollectionReport: {
				id: 'demandCollectionReport',
				text: 'Demand Collection',
				path: '/reportPages/DemandCollectionReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			FutureDemandReport: {
				id: 'futureDemandReport',
				text: 'Future Demand Report',
				path: '/reportPages/futureDemandReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			memberDetailsReport: {
				id: 'memberDetailsReport',
				text: 'Member Details Report',
				path: '/report/member-details-report',
				icon: 'FormatListNumbered',
				isView: true,
			},
			PortfolioReport: {
				id: 'portfolioReport',
				text: 'Portfolio Report',
				path: '/reportPages/portfolioReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			OverdueReport: {
				id: 'OverdueReport',
				text: 'Overdue Report',
				path: '/reportPages/OverdueReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			PortFolioAtRiskReport: {
				id: 'PortFolioAtRiskReport',
				text: 'Portfolio At Risk Report',
				path: '/reportPages/portFlioAtRiskReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			BranchDCBReport: {
				id: 'BranchDCBReport',
				text: 'Branch DCB Report',
				path: '/reportPages/BranchDCBReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			BranchProfile: {
				id: 'BranchProfile',
				text: 'Branch Profile',
				path: '/reportPages/BranchProfile',
				icon: 'FormatListNumbered',
				isView: true,
			},
			LoanCardReport: {
				id: 'LoanCardReport',
				text: 'Loan Card Report',
				path: '/reportPages/LoanCardReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			DemandFollowupReport: {
				id: 'demandFollowupReport',
				text: 'Demand Follow Up',
				path: '/reportPages/DemandFollowupReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			ArrearFollowupReport: {
				id: 'arrearFollowupReport',
				text: 'Arrear Follow Up',
				path: '/reportPages/ArrearFollowupReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// NextFollowupReport: {
			// 	id: 'nextFollowupReport',
			// 	text: 'Promise To Pay',
			// 	path: '/reportPages/NextFollowupReport',
			// 	icon: 'FormatListNumbered',
			// },
			DailyCollection: {
				id: 'DailyCollectionReport',
				text: 'Daily Collection Report',
				path: '/reportPages/DailyCollectionReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			RepaymentCollection: {
				id: 'RepaymentCollection',
				text: 'Repayment Collection',
				path: '/reportPages/RepaymentCollection',
				icon: 'FormatListNumbered',
				isView: true,
			},
			WalletCollectionReport: {
				id: 'WalletCollectionReport',
				text: 'Wallet Collection',
				path: '/reportPages/WalletReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			BranchCollection: {
				id: 'BranchCollection',
				text: 'Branch Collection',
				path: '/reportPages/BranchCollection',
				icon: 'FormatListNumbered',
				isView: true,
			},
			DayBookReport: {
				id: 'DayBookReport',
				text: 'Day Book',
				path: '/reportPages/DayBookReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// EodReport: {
			// 	id: 'EodReport',
			// 	text: 'EOD Report',
			// 	path: '/reportPages/EodReport',
			// 	icon: 'FormatListNumbered',
			// },
			PreCloseLoanReport: {
				id: 'preCloseLoanReport',
				text: 'Pre Close Report',
				path: '/reportPages/preCloseLoanReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			ClosedLoanDetails: {
				id: 'ClosedLoanDetails',
				text: 'Closed Loan Details',
				path: '/reportPages/ClosedLoanDetails',
				icon: 'FormatListNumbered',
				isView: true,
			},
			DeadMemberDetails: {
				id: 'DeadMemberDetails',
				text: 'Dead Member Details',
				path: '/reportPages/DeadMemberDetails',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// MemberAccountReport: {
			// 	id: 'MemberAccountReport',
			// 	text: 'Member Account',
			// 	path: '/reportPages/MemberAccountReport',
			// 	icon: 'FormatListNumbered',
			// },
			// SavingsFollowUpReport: {
			// 	id: 'SavingsFollowUpReport',
			// 	text: 'Savings Follow Up',
			// 	path: '/reportPages/SavingsFollowUpReport',
			// 	icon: 'FormatListNumbered',
			// },
			// AttendanceReport: {
			// 	id: 'AttendanceReport',
			// 	text: 'Attendance Report',
			// 	path: '/reportPages/AttendanceReport',
			// 	icon: 'FormatListNumbered',
			// },
			// MemberAttendanceReport: {
			// 	id: 'MemberAttendanceReport',
			// 	text: 'Member Attendance Report',
			// 	path: '/reportPages/MemberAttendanceReport',
			// 	icon: 'FormatListNumbered',
			// },
			GroupReport: {
				id: 'GroupReport',
				text: 'Group Report',
				path: '/reportPages/GroupReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// GroupOfficerReport: {
			// 	id: 'GroupOfficerReport',
			// 	text: 'Group Officer Report',
			// 	path: '/reportPages/GroupOfficerReport',
			// 	icon: 'FormatListNumbered',
			// },
			// ProgressReport: {
			// 	id: 'ProgressReport',
			// 	text: 'Progress Report',
			// 	path: '/reportPages/ProgressReport',
			// 	icon: 'FormatListNumbered',
			// },
			MonthlyProgress: {
				id: 'MonthlyProgress',
				text: 'Monthly Progress Report',
				path: '/reportPages/MonthlyProgress',
				icon: 'FormatListNumbered',
				isView: true,
			},
			ActiveMember: {
				id: 'ActiveMember',
				text: 'Active Member',
				path: '/reportPages/ActiveMember',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// InActiveMemberReport: {
			// 	id: 'InActiveMemberReport',
			// 	text: 'InActive Member',
			// 	path: '/reportPages/InActiveMemberReport',
			// 	icon: 'FormatListNumbered',
			// },
			// NpaReport: {
			// 	id: 'NpaReport',
			// 	text: 'NPA Report',
			// 	path: '/reportPages/NpaReport',
			// 	icon: 'FormatListNumbered',
			// },
			// AccruedInterestReport: {
			// 	id: 'AccruedInterestReport',
			// 	text: 'Accrued Interest Report',
			// 	path: '/reportPages/AccruedInterestReport',
			// 	icon: 'FormatListNumbered',
			// },
			ChangeMeetingReport: {
				id: 'ChangeMeetingReport',
				text: 'Change Meeting Report',
				path: '/reportPages/ChangeMeetingReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			CreditBureauReport: {
				id: 'CreditBureauReport',
				text: 'Credit Bureau Report',
				path: '/reportPages/CreditBureauReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			AuditingToolReport: {
				id: 'AuditingToolReport',
				text: 'Auditing Tool Report',
				path: '/reportPages/AuditingToolReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// PostAuditingToolReport: {
			// 	id: 'PostAuditingToolReport',
			// 	text: 'Post Auditing Tool Report',
			// 	path: '/reportPages/PostAuditingToolReport',
			// 	icon: 'FormatListNumbered',
			// },
			// SanctionReport: {
			// 	id: 'SanctionReport',
			// 	text: 'Sanction Report',
			// 	path: '/reportPages/SanctionReport',
			// 	icon: 'FormatListNumbered',
			// },
			// KYCSourcedReport: {
			// 	id: 'KYCSourcedReport',
			// 	text: 'KYC Sourced Report',
			// 	path: '/reportPages/KYCSourcedReport',
			// 	icon: 'FormatListNumbered',
			// },
			InsuranceReport: {
				id: 'InsuranceReport',
				text: 'Insurance Report',
				path: '/reportPages/insurance-report',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// MonthWiseInterestReport: {
			// 	id: 'MonthWiseInterestReport',
			// 	text: 'Month Wise Interest Report',
			// 	path: '/reportPages/MonthWiseInterestReport',
			// 	icon: 'FormatListNumbered',
			// },
			AuditTrailReport: {
				id: 'AuditTrailReport',
				text: 'Audit Trail Report',
				path: '/reportPages/AuditTrailReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
			// StaffReport: {
			// 	id: 'StaffReport',
			// 	text: 'Staff Report',
			// 	path: '/reportPages/StaffReport',
			// 	icon: 'FormatListNumbered',
			// },
			MemberRejectionReport: {
				id: 'MemberRejectionReport',
				text: 'Member Rejection Report',
				path: '/reportPages/memberRejectionReport',
				icon: 'FormatListNumbered',
				isView: true,
			},

			QrReport: {
				id: 'QrReport',
				text: 'QR Report',
				path: '/reportPages/qr-report',
				icon: 'FormatListNumbered',
				isView: true,
			},

			LeadReport: {
				id: 'LeadReport',
				text: 'Lead Report',
				path: '/reportPages/LeadReport',
				icon: 'FormatListNumbered',
				isView: true,
			},
		},
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		icon: 'Login',
		hide: true,
		isView: true,
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
		hide: true,
		isView: true,
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
		hide: true,
		isView: true,
	},
};

export const layoutMenu = {
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
		hide: true,
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: 'profileMenu/profile',
		icon: 'check_box_outline_blank ',
		// hide: true,
	},
};

export const componentsMenu = {
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap',
		icon: 'Extension',
	},
	content: {
		id: 'content',
		text: 'Content',
		path: 'content',
		icon: 'format_size',
		subMenu: {
			typography: {
				id: 'typography',
				text: 'Typography',
				path: 'content/typography',
				icon: 'text_fields',
			},
			images: {
				id: 'images',
				text: 'Images',
				path: 'content/images',
				icon: 'Image ',
			},
			tables: {
				id: 'tables',
				text: 'Tables',
				path: 'content/tables',
				icon: 'table_chart',
			},
			figures: {
				id: 'figures',
				text: 'Figures',
				path: 'content/figures',
				icon: 'Photo Library ',
			},
		},
	},
	forms: {
		id: 'forms',
		text: 'Forms',
		path: 'forms',
		icon: 'CheckBox',
		notification: 'success',
		subMenu: {
			formGroup: {
				id: 'formGroup',
				text: 'Form Group',
				path: 'forms/form-group',
				icon: 'Source',
			},
			formControl: {
				id: 'formControl',
				text: 'Form Controls',
				path: 'forms/form-controls',
				icon: 'Create',
			},
			select: {
				id: 'select',
				text: 'Select',
				path: 'forms/select',
				icon: 'Checklist',
			},
			checksAndRadio: {
				id: 'checksAndRadio',
				text: 'Checks & Radio',
				path: 'forms/checks-and-radio',
				icon: 'CheckBox',
			},
			range: {
				id: 'range',
				text: 'Range',
				path: 'forms/range',
				icon: 'HdrStrong',
			},
			inputGroup: {
				id: 'inputGroup',
				text: 'Input Group',
				path: 'forms/input-group',
				icon: 'PowerInput',
			},
			validation: {
				id: 'validation',
				text: 'Validation',
				path: 'forms/validation',
				icon: 'VerifiedUser',
			},
			wizard: {
				id: 'wizard',
				text: 'Wizard',
				path: 'forms/wizard',
				icon: 'LinearScale',
			},
		},
	},
	components: {
		id: 'components',
		text: 'Component',
		path: 'components',
		icon: 'Extension',
		notification: 'success',
		subMenu: {
			accordion: {
				id: 'accordion',
				text: 'Accordion',
				path: 'components/accordion',
				icon: 'ViewDay',
			},
			alert: {
				id: 'alert',
				text: 'Alert',
				path: 'components/alert',
				icon: 'Announcement',
			},
			badge: {
				id: 'badge',
				text: 'Badge',
				path: 'components/badge',
				icon: 'Vibration',
			},
			breadcrumb: {
				id: 'breadcrumb',
				text: 'Breadcrumb',
				path: 'components/breadcrumb',
				icon: 'AddRoad',
			},
			button: {
				id: 'button',
				text: 'Button',
				path: 'components/button',
				icon: 'SmartButton',
			},
			buttonGroup: {
				id: 'buttonGroup',
				text: 'Button Group',
				path: 'components/button-group',
				icon: 'Splitscreen',
			},
			card: {
				id: 'card',
				text: 'Card',
				path: 'components/card',
				icon: 'Crop32',
			},
			carousel: {
				id: 'carousel',
				text: 'Carousel',
				path: 'components/carousel',
				icon: 'RecentActors',
			},
			// Close
			collapse: {
				id: 'collapse',
				text: 'Collapse',
				path: 'components/collapse',
				icon: 'UnfoldLess',
			},
			dropdowns: {
				id: 'dropdowns',
				text: 'Dropdowns',
				path: 'components/dropdowns',
				icon: 'Inventory',
			},
			listGroup: {
				id: 'listGroup',
				text: 'List Group',
				path: 'components/list-group',
				icon: 'ListAlt',
			},
			modal: {
				id: 'modal',
				text: 'Modal',
				path: 'components/modal',
				icon: 'PictureInPicture',
			},
			navsTabs: {
				id: 'navsTabs',
				text: 'Navs & Tabs',
				path: 'components/navs-and-tabs',
				icon: 'PivotTableChart',
			},
			// Navbar
			offcanvas: {
				id: 'offcanvas',
				text: 'Offcanvas',
				path: 'components/offcanvas',
				icon: 'VerticalSplit',
			},
			pagination: {
				id: 'pagination',
				text: 'Pagination',
				path: 'components/pagination',
				icon: 'Money',
			},
			popovers: {
				id: 'popovers',
				text: 'Popovers',
				path: 'components/popovers',
				icon: 'Assistant',
			},
			progress: {
				id: 'progress',
				text: 'Progress',
				path: 'components/progress',
				icon: 'HourglassTop',
			},
			scrollspy: {
				id: 'scrollspy',
				text: 'Scrollspy',
				path: 'components/scrollspy',
				icon: 'KeyboardHide',
			},
			spinners: {
				id: 'spinners',
				text: 'Spinners',
				path: 'components/spinners',
				icon: 'RotateRight',
			},
			table: {
				id: 'table',
				text: 'Table',
				path: 'components/table',
				icon: 'TableChart',
			},
			toasts: {
				id: 'toasts',
				text: 'Toasts',
				path: 'components/toasts',
				icon: 'RotateRight',
			},
			tooltip: {
				id: 'tooltip',
				text: 'Tooltip',
				path: 'components/tooltip',
				icon: 'Assistant',
			},
		},
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: 'utilities',
		icon: 'Support',
		subMenu: {
			api: {
				id: 'api',
				text: 'API',
				path: 'utilities/api',
				icon: 'Api',
			},
			background: {
				id: 'background',
				text: 'Background',
				path: 'utilities/background',
				icon: 'FormatColorFill',
			},
			borders: {
				id: 'borders',
				text: 'Borders',
				path: 'utilities/borders',
				icon: 'BorderStyle',
			},
			colors: {
				id: 'colors',
				text: 'Colors',
				path: 'utilities/colors',
				icon: 'InvertColors',
			},
			display: {
				id: 'display',
				text: 'Display',
				path: 'utilities/display',
				icon: 'LaptopMac',
			},
			flex: {
				id: 'flex',
				text: 'Flex',
				path: 'utilities/flex',
				icon: 'SettingsOverscan',
			},
			float: {
				id: 'float',
				text: 'Float',
				path: 'utilities/float',
				icon: 'ViewArray',
			},
			interactions: {
				id: 'interactions',
				text: 'Interactions',
				path: 'utilities/interactions',
				icon: 'Mouse',
			},
			overflow: {
				id: 'overflow',
				text: 'Overflow',
				path: 'utilities/overflow',
				icon: 'TableRows',
			},
			position: {
				id: 'position',
				text: 'Position',
				path: 'utilities/position',
				icon: 'Adjust',
			},
			shadows: {
				id: 'shadows',
				text: 'Shadows',
				path: 'utilities/shadows',
				icon: 'ContentCopy',
			},
			sizing: {
				id: 'sizing',
				text: 'Sizing',
				path: 'utilities/sizing',
				icon: 'Straighten',
			},
			spacing: {
				id: 'spacing',
				text: 'Spacing',
				path: 'utilities/spacing',
				icon: 'SpaceBar',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'utilities/text',
				icon: 'TextFields',
			},
			verticalAlign: {
				id: 'vertical-align',
				text: 'Vertical Align',
				path: 'utilities/vertical-align',
				icon: 'VerticalAlignCenter',
			},
			visibility: {
				id: 'visibility',
				text: 'Visibility',
				path: 'utilities/visibility',
				icon: 'Visibility',
			},
		},
	},
	extra: {
		id: 'extra',
		text: 'Extra Library',
		icon: 'Extension',
	},
	icons: {
		id: 'icons',
		text: 'Icons',
		path: 'icons',
		icon: 'Grain',
		notification: 'success',
		subMenu: {
			icon: {
				id: 'icon',
				text: 'Icon',
				path: 'icons/icon',
				icon: 'Lightbulb',
			},
			material: {
				id: 'material',
				text: 'Material',
				path: 'icons/material',
				icon: 'Verified',
			},
			bootstrapIcon: {
				id: 'bootstrapIcon',
				text: 'Bootstrap Icon',
				path: 'icons/bootstrap-icon',
				icon: 'BootstrapFill',
			},
		},
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: 'charts',
		icon: 'AreaChart',
		notification: 'success',
		subMenu: {
			chartsUsage: {
				id: 'chartsUsage',
				text: 'General Usage',
				path: 'charts/general-usage',
				icon: 'Description',
			},
			chartsSparkline: {
				id: 'chartsSparkline',
				text: 'Sparkline',
				path: 'charts/sparkline',
				icon: 'AddChart',
			},
			chartsLine: {
				id: 'chartsLine',
				text: 'Line',
				path: 'charts/line',
				icon: 'ShowChart',
			},
			chartsArea: {
				id: 'chartsArea',
				text: 'Area',
				path: 'charts/area',
				icon: 'AreaChart',
			},
			chartsColumn: {
				id: 'chartsColumn',
				text: 'Column',
				path: 'charts/column',
				icon: 'BarChart',
			},
			chartsBar: {
				id: 'chartsBar',
				text: 'Bar',
				path: 'charts/bar',
				icon: 'StackedBarChart',
			},
			chartsMixed: {
				id: 'chartsMixed',
				text: 'Mixed',
				path: 'charts/mixed',
				icon: 'MultilineChart',
			},
			chartsTimeline: {
				id: 'chartsTimeline',
				text: 'Timeline',
				path: 'charts/timeline',
				icon: 'WaterfallChart',
			},
			chartsCandleStick: {
				id: 'chartsCandleStick',
				text: 'Candlestick',
				path: 'charts/candlestick',
				icon: 'Cake',
			},
			chartsBoxWhisker: {
				id: 'chartsBoxWhisker',
				text: 'Box Whisker',
				path: 'charts/box-whisker',
				icon: 'SportsMma',
			},
			chartsPieDonut: {
				id: 'chartsPieDonut',
				text: 'Pie & Donut',
				path: 'charts/pie-donut',
				icon: 'PieChart',
			},
			chartsRadar: {
				id: 'chartsRadar',
				text: 'Radar',
				path: 'charts/radar',
				icon: 'BrightnessLow',
			},
			chartsPolar: {
				id: 'chartsPolar',
				text: 'Polar',
				path: 'charts/polar',
				icon: 'TrackChanges',
			},
			chartsRadialBar: {
				id: 'chartsRadialBar',
				text: 'Radial Bar',
				path: 'charts/radial-bar',
				icon: 'DonutLarge',
			},
			chartsBubble: {
				id: 'chartsBubble',
				text: 'Bubble',
				path: 'charts/bubble',
				icon: 'BubbleChart',
			},
			chartsScatter: {
				id: 'chartsScatter',
				text: 'Scatter',
				path: 'charts/scatter',
				icon: 'ScatterPlot',
			},
			chartsHeatMap: {
				id: 'chartsHeatMap',
				text: 'Heat Map',
				path: 'charts/heat-map',
				icon: 'GridOn',
			},
			chartsTreeMap: {
				id: 'chartsTreeMap',
				text: 'Tree Map',
				path: 'charts/tree-map',
				icon: 'AccountTree',
			},
		},
	},
	notification: {
		id: 'notification',
		text: 'Notification',
		path: 'notifications',
		icon: 'NotificationsNone',
	},
	hooks: {
		id: 'hooks',
		text: 'Hooks',
		path: 'hooks',
		icon: 'Anchor',
	},
};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
