/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import trainingActionTypes from './training.types';
import {
	AllTrainingProposalDetailsForBranch,
	TrainingProposalDetailsForBranchExcelListGet,
} from './training.services';
import {
	trainingProposalBranchSuccess,
	trainingProposalBranchFailure,
	trainingProposalBranchChanged,
	getTrainingProposalExcelReportDetailsSuccess,
	getTrainingProposalExcelReportDetailsFailure,
	getTrainingProposalExcelReportDetailsResponseChanged,
} from './training.actions';
import { encodeJson } from '../../enode-decode';

export function* trainingProposalBranchWorker({ payload }) {
	try {
		const key = 'TRAININGDETAILSFORBRANCH';
		const jsonData = {
			CompanyID: payload.companyID,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			QuestionarieID: payload.QuestionnaireID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AllTrainingProposalDetailsForBranch, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(trainingProposalBranchFailure(responseData.data));
		} else {
			yield put(trainingProposalBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(trainingProposalBranchFailure(error));
	}
}

export function* resetAllTrainingProposalBranchReportResponse() {
	yield put(trainingProposalBranchChanged());
}

export function* onAllTrainingProposalBranchStart() {
	yield takeLatest(
		trainingActionTypes.TRAINING_PROPOSAL_BRANCH_START,
		trainingProposalBranchWorker,
	);
}

export function* onAllTrainingProposalBranchResponseReset() {
	yield takeLatest(
		trainingActionTypes.TRAINING_PROPOSAL_BRANCH_RESET,
		resetAllTrainingProposalBranchReportResponse,
	);
}

/** *********************  START GET AUDITING REPORT EXCEL DETAILS  ******************** */
export function* getAllTrainingProposalExcelList({ payload }) {
	try {
		const key = 'AuditingToolReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userName,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			QuestionID: payload.QuestionID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserLevel: payload.userLevel,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(TrainingProposalDetailsForBranchExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getTrainingProposalExcelReportDetailsFailure(responseData.data));
		} else {
			yield put(getTrainingProposalExcelReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTrainingProposalExcelReportDetailsFailure(error));
	}
}

export function* resetGetAllTrainingProposalExcelListResponse() {
	yield put(getTrainingProposalExcelReportDetailsResponseChanged());
}

export function* onGetAllTrainingProposalExcelListDetails() {
	yield takeLatest(
		trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_START,
		getAllTrainingProposalExcelList,
	);
}

export function* onGetAllTrainingProposalListResponseReset() {
	yield takeLatest(
		trainingActionTypes.TRAINING_PROPOSAL_EXCEL_REPORT_RESPONSE_RESET,
		resetGetAllTrainingProposalExcelListResponse,
	);
}
/** *********************  END GET AUDITING REPORT EXCEL DETAILS ******************** */

export function* reportTrainingToolSaga() {
	yield all([
		call(onAllTrainingProposalBranchStart),
		call(onAllTrainingProposalBranchResponseReset),
		call(onGetAllTrainingProposalExcelListDetails),
		call(onGetAllTrainingProposalListResponseReset),
	]);
}
