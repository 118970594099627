const highmarkRulesTypes = {
	ADD_HIGHMARK_RULES_START: 'add_highmark_rules_start',
	ADD_HIGHMARK_RULES_SUCCESS: 'add_highmark_rules_success',
	ADD_HIGHMARK_RULES_FAILURE: 'add_highmark_rules_failure',
	ADD_HIGHMARK_RULES_RESPONSE_RESET: 'add_highmark_rules_response_reset',
	ADD_HIGHMARK_RULES_RESPONSE_CHANGED: 'add_highmark_rules_response_changed',

	FETCH_HIGHMARK_RULES_START: 'fetch_highmark_rules_start',
	FETCH_HIGHMARK_RULES_SUCCESS: 'fetch_highmark_rules_success',
	FETCH_HIGHMARK_RULES_FAILURE: 'fetch_highmark_rules_failure',
	FETCH_HIGHMARK_RULES_RESPONSE_RESET: 'fetch_highmark_rules_response_reset',
	FETCH_HIGHMARK_RULES_RESPONSE_CHANGED: 'fetch_highmark_rules_response_changed',
};
export default highmarkRulesTypes;
