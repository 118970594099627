/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import RangeLoanProposalActionTypes from './range-loan-propsal.types';
import {
	rangeLoanProposalListFetch,
	rangeLoanProposalCenterListFetch,
	rangeLoanCenterMemberListFetch,
	rangeLoanProductListFetch,
	rangeLoanProposalAddAPI,
	rangeLoanProposalUpdateAPI,
	rangeLoanCenterMemberLeadListFetch,
	rangeLoanIndexDetailsFetch,
	rangeLoanIndexDetailsApproval,
	rangeLoanIndexDetailsReject,
	rangeLoanIndexDetailsApprovalRevert,
	rangeLoanProposalDeleteAPI,
} from './range-loan-proposal.service';

import {
	getRangeLoanPropsalListSuccess,
	getRangeLoanPropsalListFailure,
	getRangeLoanPropsalListResponseChanged,
	getRangePropsalCenterListFailure,
	getRangePropsalCenterListSuccess,
	getRangePropsalCenterListResponseChanged,
	getCenterMemberListSuccess,
	getCenterMemberListFailure,
	getCenterMemberListResponseChanged,
	getRangeProductListSuccess,
	getRangeProductListFailure,
	getRangeProductListResponseChanged,
	addRangeLoanProposalSuccess,
	addRangeLoanProposalFailure,
	addRangeLoanProposalResponseChanged,
	updateRangeLoanProposalSuccess,
	updateRangeLoanProposalFailure,
	updateRangeLoanProposalResponseChanged,
	getCenterMemberLeadListFailure,
	getCenterMemberLeadListSuccess,
	getCenterMemberLeadListResponseChanged,
	getParticularLoanIndexDetailsSuccess,
	getParticularLoanIndexDetailsFailure,
	getParticularLoanIndexDetailsResponseChanged,
	rejectRangeLoanIndexSuccess,
	rejectRangeLoanIndexFailure,
	rejectRangeLoanIndexResponseChanged,
	approveRangeLoanIndexSuccess,
	approveRangeLoanIndexFailure,
	approveRangeLoanIndexResponseChanged,
	revertApproveRangeLoanIndexSuccess,
	revertApproveRangeLoanIndexFailure,
	revertApproveRangeLoanIndexResponseChanged,
	deleteRangeLoanProposalSuccess,
	deleteRangeLoanProposalFailure,
	deleteRangeLoanProposalResponseChanged,
} from './range-loan-proposal.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START GET RANGE LOAN PROPOSAL  ******************** */
export function* rangeLoanProposalListGet({ payload }) {
	try {
		const key = 'GetAllBranchLoanIndexDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			LoanIndexType: payload.loanIndexType,
			BranchID: payload.branchID,
			UserID: payload.userID,
			SearchData: payload.inputSearch,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			UserRoleID: payload.userRoleID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(
			rangeLoanProposalListFetch,
			encodeData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(getRangeLoanPropsalListFailure(responseData.data));
		} else {
			yield put(getRangeLoanPropsalListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRangeLoanPropsalListFailure(error));
	}
}

export function* resetGetRangeLoanProposalListResponse() {
	yield put(getRangeLoanPropsalListResponseChanged());
}

export function* onGetRangeLoanProposal() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_START,
		rangeLoanProposalListGet,
	);
}

export function* onGetRangeLoanProposalListResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
		resetGetRangeLoanProposalListResponse,
	);
}
/** *********************  END GET RANGE LOAN PROPOSAL ******************** */

/** *********************  START GET RANGE LOAN PROPOSAL CENTER LIST  ******************** */
export function* rangeLoanProposalCenterListGet({ payload }) {
	try {
		const key = 'GetRangeLoanProposalCenterListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserRole: payload.userRole,
			BranchID: payload.branchID,
			IsNoLoanCenter: 0,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanProposalCenterListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getRangePropsalCenterListFailure(responseData.data));
		} else {
			yield put(getRangePropsalCenterListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRangePropsalCenterListFailure(error));
	}
}

export function* resetGetRangeLoanProposalCenterListResponse() {
	yield put(getRangePropsalCenterListResponseChanged());
}

export function* onGetRangeLoanProposalCenters() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_START,
		rangeLoanProposalCenterListGet,
	);
}

export function* onGetRangeLoanProposalCenterListResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_LOAN_PROPOSAL_CENTER_LIST_RESPONSE_RESET,
		resetGetRangeLoanProposalCenterListResponse,
	);
}
/** *********************  END GET RANGE LOAN PROPOSAL CENTER LIST ******************** */

/** *********************  START GET CENTER MEMBER LIST  ******************** */
export function* rangeLoanCenterMemberListGet({ payload }) {
	try {
		const key = 'GetMemberDetailsForCenterLoanKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			SearchData: payload.SearchData,
			Limit: payload.Limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanCenterMemberListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterMemberListFailure(responseData.data));
		} else {
			yield put(getCenterMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterMemberListFailure(error));
	}
}

export function* resetGetRangeLoanCenterMemberListResponse() {
	yield put(getCenterMemberListResponseChanged());
}

export function* onGetRangeLoanCenterMembers() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_START,
		rangeLoanCenterMemberListGet,
	);
}

export function* onGetRangeLoanCenterMemberListResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LIST_RESPONSE_RESET,
		resetGetRangeLoanCenterMemberListResponse,
	);
}
/** *********************  END GET CENTER MEMBER LIST ******************** */

/** *********************  START GET RANGE PRODUCT LIST  ******************** */
export function* rangeLoanProductListGet({ payload }) {
	try {
		const key = 'GetRangeLoanProposalProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			ProductType: payload.loanType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanProductListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getRangeProductListFailure(responseData.data));
		} else {
			yield put(getRangeProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRangeProductListFailure(error));
	}
}

export function* resetGetRangeLoanProductListResponse() {
	yield put(getRangeProductListResponseChanged());
}

export function* onGetRangeLoanProductList() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_START,
		rangeLoanProductListGet,
	);
}

export function* onGetRangeLoanProductListResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_RANGE_PRODUCT_LIST_RESPONSE_RESET,
		resetGetRangeLoanProductListResponse,
	);
}
/** *********************  END GET RANGE PRODUCT LIST ******************** */

/** *********************  START ADD RANGE LOAN PROPOSAL  ******************** */
export function* rangeLoanProposalAdd({ payload }) {
	try {
		const key = 'AddRangeLoanProposaltKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			AreaID: payload.AreaID,
			BranchID: payload.BranchID,
			LoanProposalDate: payload.LoanProposalDate,
			MeetUpLocation: payload.MeetUpLocation,
			MeetUpTime: payload.MeetUpTime,
			HOLoanID: payload.HOLoanID,
			MemberMeetupLat: payload.MemberMeetupLat,
			MemberMeetupLong: payload.MemberMeetupLong,
			CreatedUser: payload.userID,
			LoanIndexType: 'Range',
			LoanIndexGroupMember: payload.LoanIndexGroupMember,
			ProductInvestorDetails: payload.ProductInvestorDetails,
			FunderName: payload.FunderName,
			TotalLoanAmount: payload.TotalLoanAmount,
			LoanType: payload.LoanType,
			LoanOfficerID: payload.LoanOfficerID,
			memberLeadID: payload.memberLeadID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanProposalAddAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addRangeLoanProposalFailure(responseData.data));
		} else {
			yield put(addRangeLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addRangeLoanProposalFailure(error));
	}
}

export function* resetAddRangeLoanProposalResponse() {
	yield put(addRangeLoanProposalResponseChanged());
}

export function* onAddRangeLoanProposal() {
	yield takeLatest(
		RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_START,
		rangeLoanProposalAdd,
	);
}

export function* onAddRangeLoanProposalResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.ADD_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetAddRangeLoanProposalResponse,
	);
}
/** *********************  END ADD RANGE LOAN PROPOSAL ******************** */

/** *********************  START UPDATE RANGE LOAN PROPOSAL  ******************** */
export function* rangeLoanProposalUpdate({ payload }) {
	try {
		const key = 'UpdateRangeLoanProposaltKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			AreaID: payload.AreaID,
			BranchID: payload.BranchID,
			LoanProposalDate: payload.LoanProposalDate,
			MeetUpLocation: payload.MeetUpLocation,
			MeetUpTime: payload.MeetUpTime,
			HOLoanID: payload.HOLoanID,
			MemberMeetupLat: payload.MemberMeetupLat,
			MemberMeetupLong: payload.MemberMeetupLong,
			CreatedUser: payload.userID,
			LoanIndexType: 'Range',
			LoanIndexID: payload.LoanIndexID,
			LoanIndexGroupMember: payload.LoanIndexGroupMember,
			ProductInvestorDetails: payload.ProductInvestorDetails,
			FunderName: payload.FunderName,
			TotalLoanAmount: payload.TotalLoanAmount,
			LoanType: payload.LoanType,
			LoanOfficerID: payload.LoanOfficerID,
			memberLeadID: payload.memberLeadID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanProposalUpdateAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateRangeLoanProposalFailure(responseData.data));
		} else {
			yield put(updateRangeLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateRangeLoanProposalFailure(error));
	}
}

export function* resetUpdateRangeLoanProposalResponse() {
	yield put(updateRangeLoanProposalResponseChanged());
}

export function* onUpdateRangeLoanProposal() {
	yield takeLatest(
		RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_START,
		rangeLoanProposalUpdate,
	);
}

export function* onUpdateRangeLoanProposalResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.UPDATE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetUpdateRangeLoanProposalResponse,
	);
}
/** *********************  END UPDATE RANGE LOAN PROPOSAL ******************** */

/** *********************  START GET CENTER MEMBER LEAD LIST  ******************** */
export function* rangeLoanCenterMemberLeadListGet({ payload }) {
	try {
		const key = 'GetMemberLeadListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanCenterMemberLeadListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterMemberLeadListFailure(responseData.data));
		} else {
			yield put(getCenterMemberLeadListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterMemberLeadListFailure(error));
	}
}

export function* resetGetRangeLoanCenterMemberLeadListResponse() {
	yield put(getCenterMemberLeadListResponseChanged());
}

export function* onGetRangeLoanCenterMembersLead() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_START,
		rangeLoanCenterMemberLeadListGet,
	);
}

export function* onGetRangeLoanCenterMemberLeadListResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_CENTER_MEMBER_LEAD_LIST_RESPONSE_RESET,
		resetGetRangeLoanCenterMemberLeadListResponse,
	);
}
/** *********************  END GET CENTER MEMBER LEAD LIST ******************** */

/** *********************  START GET PARTICULAR LOAN INDEX DETAILS  ******************** */
export function* rangeLoanIndexDetailsGet({ payload }) {
	try {
		const key = 'GetParticularRangeLoanIndexKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanIndexDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getParticularLoanIndexDetailsFailure(responseData.data));
		} else {
			yield put(getParticularLoanIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getParticularLoanIndexDetailsFailure(error));
	}
}

export function* resetGetRangeLoanIndexDetailsResponse() {
	yield put(getParticularLoanIndexDetailsResponseChanged());
}

export function* onGetRangeLoanIndexDetails() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_START,
		rangeLoanIndexDetailsGet,
	);
}

export function* onGetRangeLoanIndexDetailsResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.GET_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET,
		resetGetRangeLoanIndexDetailsResponse,
	);
}
/** *********************  END GET PARTICULAR LOAN INDEX DETAILS ******************** */

/** *********************  START APPROVE RANGE LOAN INDEX  ******************** */
export function* rangeLoanIndexApprove({ payload }) {
	try {
		const key = 'ApproveRangeLoanIndexKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
			UserID: payload.userID,
			UserName: payload.userName,
			AppraisalLevel: payload.AppraisalLevel,
			AppraisalDoneBy: payload.AppraisalDoneBy,
			AppraisalDoneByUserName: payload.AppraisalDoneByUserName,
			AppraisalApproveSetupID: payload.AppraisalApproveSetupID,
			AppraisalApproveID: payload.AppraisalApproveID,
			IsAppraisalApproveNeeded: payload.IsAppraisalApproveNeeded,
			isAllAppraisalApproved: payload.isAllAppraisalApproved,
			NoOfAppraisalApproval: payload.NoOfAppraisalApproval,
			AppraisalApproveDoneCount: payload.AppraisalApproveDoneCount,
			AppraisalNotes: payload.AppraisalNotes,
			LoanApproveStatus: payload.LoanApproveStatus,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanIndexDetailsApproval, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(approveRangeLoanIndexFailure(responseData.data));
		} else {
			yield put(approveRangeLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveRangeLoanIndexFailure(error));
	}
}

export function* resetApproveRangeLoanIndexDetailsResponse() {
	yield put(approveRangeLoanIndexResponseChanged());
}

export function* onApproveRangeLoanIndexDetails() {
	yield takeLatest(
		RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_START,
		rangeLoanIndexApprove,
	);
}

export function* onApproveRangeLoanIndexDetailsResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.APPROVE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetApproveRangeLoanIndexDetailsResponse,
	);
}
/** *********************  END APPROVE RANGE LOAN INDEX ******************** */

/** *********************  START REVERT APPROVED RANGE LOAN INDEX  ******************** */
export function* rangeLoanIndexApproveRevert({ payload }) {
	try {
		const key = 'ApproveRevertRangeLoanIndexKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
			UserID: payload.userID,
			UserName: payload.userName,
			AppraisalLevel: payload.AppraisalLevel,
			AppraisalDoneBy: payload.AppraisalDoneBy,
			AppraisalDoneByUserName: payload.AppraisalDoneByUserName,
			AppraisalApproveSetupID: payload.AppraisalApproveSetupID,
			AppraisalApproveID: payload.AppraisalApproveID,
			IsAppraisalApproveNeeded: payload.IsAppraisalApproveNeeded,
			NoOfAppraisalApproval: payload.NoOfAppraisalApproval,
			AppraisalApproveDoneCount: payload.AppraisalApproveDoneCount,
			AppraisalNotes: payload.AppraisalNotes,
			LoanApproveStatus: payload.LoanApproveStatus,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanIndexDetailsApprovalRevert, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(revertApproveRangeLoanIndexFailure(responseData.data));
		} else {
			yield put(revertApproveRangeLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertApproveRangeLoanIndexFailure(error));
	}
}

export function* resetApproveRevertRangeLoanIndexDetailsResponse() {
	yield put(revertApproveRangeLoanIndexResponseChanged());
}

export function* onApproveRevertRangeLoanIndexDetails() {
	yield takeLatest(
		RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_START,
		rangeLoanIndexApproveRevert,
	);
}

export function* onApproveRevertRangeLoanIndexDetailsResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.REVERT_APPROVED_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetApproveRevertRangeLoanIndexDetailsResponse,
	);
}
/** *********************  END REVERT APPROVED RANGE LOAN INDEX ******************** */

/** *********************  START REJECT RANGE LOAN INDEX  ******************** */
export function* rangeLoanIndexReject({ payload }) {
	try {
		const key = 'RejectRangeLoanIndexKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanIndexID: payload.LoanIndexID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanIndexDetailsReject, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(rejectRangeLoanIndexFailure(responseData.data));
		} else {
			yield put(rejectRangeLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(rejectRangeLoanIndexFailure(error));
	}
}

export function* resetRejectRangeLoanIndexDetailsResponse() {
	yield put(rejectRangeLoanIndexResponseChanged());
}

export function* onRejectRangeLoanIndexDetails() {
	yield takeLatest(
		RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_START,
		rangeLoanIndexReject,
	);
}

export function* onRejectRangeLoanIndexDetailsResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.REJECT_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetRejectRangeLoanIndexDetailsResponse,
	);
}
/** *********************  END REJECT RANGE LOAN INDEX ******************** */

/** *********************  START DELETE RANGE LOAN PROPOSAL  ******************** */
export function* rangeLoanProposalDelete({ payload }) {
	try {
		const key = 'DeleteLoanIndexProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserName: payload.userName,
			UserID: payload.userID,
			LoanIndexID: payload.loanIndexID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(rangeLoanProposalDeleteAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteRangeLoanProposalFailure(responseData.data));
		} else {
			yield put(deleteRangeLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteRangeLoanProposalFailure(error));
	}
}

export function* resetDeleteRangeLoanProposalResponse() {
	yield put(deleteRangeLoanProposalResponseChanged());
}

export function* onDeleteRangeLoanProposal() {
	yield takeLatest(
		RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_START,
		rangeLoanProposalDelete,
	);
}

export function* onDeleteRangeLoanProposalResponseReset() {
	yield takeLatest(
		RangeLoanProposalActionTypes.DELETE_RANGE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetDeleteRangeLoanProposalResponse,
	);
}
/** *********************  END DELETE RANGE LOAN PROPOSAL ******************** */

export function* rangeLoanProposalSaga() {
	yield all([
		call(onGetRangeLoanProposal),
		call(onGetRangeLoanProposalListResponseReset),
		call(onGetRangeLoanProposalCenters),
		call(onGetRangeLoanProposalCenterListResponseReset),
		call(onGetRangeLoanCenterMembers),
		call(onGetRangeLoanCenterMemberListResponseReset),
		call(onGetRangeLoanProductList),
		call(onGetRangeLoanProductListResponseReset),
		call(onAddRangeLoanProposal),
		call(onAddRangeLoanProposalResponseReset),
		call(onUpdateRangeLoanProposal),
		call(onUpdateRangeLoanProposalResponseReset),
		call(onGetRangeLoanCenterMembersLead),
		call(onGetRangeLoanCenterMemberLeadListResponseReset),
		call(onGetRangeLoanIndexDetails),
		call(onGetRangeLoanIndexDetailsResponseReset),
		call(onApproveRangeLoanIndexDetails),
		call(onApproveRangeLoanIndexDetailsResponseReset),
		call(onRejectRangeLoanIndexDetails),
		call(onRejectRangeLoanIndexDetailsResponseReset),
		call(onApproveRevertRangeLoanIndexDetails),
		call(onApproveRevertRangeLoanIndexDetailsResponseReset),
		call(onDeleteRangeLoanProposal),
		call(onDeleteRangeLoanProposalResponseReset),
	]);
}
