/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import EndOfDayActionTypes from './endOfDay.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAllCollectionDetailsResponse: null,
	getAllCollectionDetailsArr: [],
	getAllCollectionDetailsStatus: null,
	getBranchEODWalletDetailsResponse: null,
	getBranchEODWalletDetailsArr: [],
	getBranchEODWalletDetailsStatus: null,
	getDemandCollectionTotalDetailsResponse: null,
	getDemandCollectionTotalDetailsArr: [],
	getDemandCollectionTotalDetailsStatus: null,
	getAdvanceCollectionTotalDetailsResponse: null,
	getAdvanceCollectionTotalDetailsArr: [],
	getAdvanceCollectionTotalDetailsStatus: null,
	getArrearCollectionTotalDetailsResponse: null,
	getArrearCollectionTotalDetailsArr: [],
	getArrearCollectionTotalDetailsStatus: null,
	getWalletCollectionTotalDetailsResponse: null,
	getWalletCollectionTotalDetailsArr: [],
	getWalletCollectionTotalDetailsStatus: null,
	getPrecloseCollectionTotalDetailsResponse: null,
	getPrecloseCollectionTotalDetailsArr: [],
	getPrecloseCollectionTotalDetailsStatus: null,
	getLoanAdvanceUsedTotalDetailsResponse: null,
	getLoanAdvanceUsedTotalDetailsArr: [],
	getLoanAdvanceUsedTotalDetailsStatus: null,
	getCloseLoanListDetailsResponse: null,
	getCloseLoanListDetailsArr: [],
	getCloseLoanListDetailsStatus: null,
	getPrecloseCollectionDetailsResponse: null,
	getPrecloseCollectionDetailsArr: [],
	getPrecloseCollectionDetailsStatus: null,
	getAdvanceCollectionDetailsResponse: null,
	getAdvanceCollectionDetailsArr: [],
	getAdvanceCollectionDetailsStatus: null,
	getArrearCollectionDetailsResponse: null,
	getArrearCollectionDetailsArr: [],
	getArrearCollectionDetailsStatus: null,
	getVoucherApprovedDetailsResponse: null,
	getVoucherApprovedDetailsArr: [],
	getVoucherApprovedDetailsStatus: null,
	getSubmitEODDetailsResponse: null,
	getSubmitEODDetailsArr: [],
	getRevertEODDetailsResponse: null,
	getRevertEODDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				getAllCollectionDetailsResponse: action.payload,
				getAllCollectionDetailsArr: docodeResponse(
					action.payload,
					'GetAllCollectionDetailsKey',
				),
				getAllCollectionDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_FAILURE:
			return { ...state, 
				getAllCollectionDetailsResponse: action.payload,
				getAllCollectionDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_ALL_COLLECTION_DETAILS_RESPONSE_CHANGED:
			return { ...state, getAllCollectionDetailsResponse: null };

		case EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_SUCCESS:
			return {
				...state,
				getBranchEODWalletDetailsResponse: action.payload,
				getBranchEODWalletDetailsArr: docodeResponse(
					action.payload,
					'GetEODWalletAmountDetailsKey',
				),
				getBranchEODWalletDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_FAILURE:
			return { ...state, 
				getBranchEODWalletDetailsResponse: action.payload,
				getBranchEODWalletDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_WALLET_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchEODWalletDetailsResponse: null };

		case EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_SUCCESS:
			return {
				...state,
				getBranchEODThriftDetailsResponse: action.payload,
				getBranchEODThriftDetailsArr: docodeResponse(
					action.payload,
					'GetBranchEODThriftDetailsKey',
				),
				getBranchEODThriftDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_FAILURE:
			return { ...state, getBranchEODThriftDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_BRANCH_EOD_THRIFT_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchEODThriftDetailsStatus: null };

		case EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_SUCCESS:
			return {
				...state,
				getBranchEODSavingsDetailsResponse: action.payload,
				getBranchEODSavingsDetailsArr: docodeResponse(
					action.payload,
					'GetBranchEODSavingsDetailsKey',
				),
				getBranchEODSavingsDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_FAILURE:
			return { ...state, getBranchEODSavingsDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_BRANCH_EOD_SAVINGS_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchEODSavingsDetailsStatus: null };

		case EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_SUCCESS:
			return {
				...state,
				getBranchEODFixedDepositDetailsResponse: action.payload,
				getBranchEODFixedDepositDetailsArr: docodeResponse(
					action.payload,
					'GetBranchEODFixedDepositDetailsKey',
				),
				getBranchEODFixedDepositDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_FAILURE:
			return { ...state, getBranchEODFixedDepositDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_BRANCH_EOD_FIXED_DEPOSIT_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchEODFixedDepositDetailsStatus: null };

		case EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getDemandCollectionTotalDetailsResponse: action.payload,
				getDemandCollectionTotalDetailsArr: docodeResponse(
					action.payload,
					'GetDemandCollectionTotalDetailsKey',
				),
				getDemandCollectionTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_FAILURE:
			return { ...state, getDemandCollectionTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_DEMAND_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getDemandCollectionTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getAdvanceCollectionTotalDetailsResponse: action.payload,
				getAdvanceCollectionTotalDetailsArr: docodeResponse(
					action.payload,
					'GetAdvanceCollectionTotalDetailsKey',
				),
				getAdvanceCollectionTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_FAILURE:
			return { ...state, getAdvanceCollectionTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getAdvanceCollectionTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getArrearCollectionTotalDetailsResponse: action.payload,
				getArrearCollectionTotalDetailsArr: docodeResponse(
					action.payload,
					'GetArrearCollectionTotalDetailsKey',
				),
				getArrearCollectionTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_FAILURE:
			return { ...state, getArrearCollectionTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getArrearCollectionTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getWalletCollectionTotalDetailsResponse: action.payload,
				getWalletCollectionTotalDetailsArr: docodeResponse(
					action.payload,
					'GetWalletCollectionTotalDetailsKey',
				),
				getWalletCollectionTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_FAILURE:
			return { ...state, getWalletCollectionTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_WALLET_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getWalletCollectionTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getPrecloseCollectionTotalDetailsResponse: action.payload,
				getPrecloseCollectionTotalDetailsArr: docodeResponse(
					action.payload,
					'GetPrecloseCollectionTotalDetailsKey',
				),
				getPrecloseCollectionTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_FAILURE:
			return { ...state, getPrecloseCollectionTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getPrecloseCollectionTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_SUCCESS:
			return {
				...state,
				getLoanAdvanceUsedTotalDetailsResponse: action.payload,
				getLoanAdvanceUsedTotalDetailsArr: docodeResponse(
					action.payload,
					'GetLoanAdvanceUsedTotalDetailsKey',
				),
				getLoanAdvanceUsedTotalDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_FAILURE:
			return { ...state, getLoanAdvanceUsedTotalDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_LOAN_ADVANCE_USED_TOTAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, getLoanAdvanceUsedTotalDetailsStatus: null };

		case EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_SUCCESS:
			return {
				...state,
				getCloseLoanListDetailsResponse: action.payload,
				getCloseLoanListDetailsArr: docodeResponse(
					action.payload,
					'CloseLoanListFetchKey',
				),
				getCloseLoanListDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_FAILURE:
			return { ...state, getCloseLoanListDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_CLOSE_LOAN_LIST_DETAILS_RESPONSE_CHANGED:
			return { ...state, getCloseLoanListDetailsStatus: null };


		case EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_SUCCESS:
			return {
				...state,
				getBranchEODDetailsResponse: action.payload,
				getBranchEODDetailsArr: docodeResponse(
					action.payload,
					'GetBranchEODDetailsKey',
				),
				getBranchEODDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_FAILURE:
			return { ...state, getBranchEODDetailsStatus: action.payload };

		case EndOfDayActionTypes.GET_BRANCH_EOD_DETAILS_RESPONSE_CHANGED:
			return { ...state, getBranchEODDetailsStatus: null };

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS:
			return {
				...state,
				getPreviousDayBankAccountDayBookReportResponse: action.payload,
				getPreviousDayBankAccountDayBookReportArr: docodeResponse(
					action.payload,
					'FetchAccountDayBookKey',
				),
			};

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE:
			return { ...state, 
				getPreviousDayBankAccountDayBookReportResponse: action.payload,
				getPreviousDayBankAccountDayBookReportArr: [],
			 };

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED:
			return { ...state, getPreviousDayBankAccountDayBookReportResponse: null };

		case EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_SUCCESS:
			return {
				...state,
				getBankAccountDayBookReportResponse: action.payload,
				getBankAccountDayBookReportArr: docodeResponse(
					action.payload,
					'FetchAccountDayBookKey',
				),
			};

		case EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_FAILURE:
			return { ...state, 
				getBankAccountDayBookReportResponse: action.payload,
				getBankAccountDayBookReportArr: [],
				};

		case EndOfDayActionTypes.GET_BANK_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED:
			return { ...state, getBankAccountDayBookReportResponse: null };

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS:
			return {
				...state,
				getPreviousDayCashAccountDayBookReportResponse: action.payload,
				getPreviousDayCashAccountDayBookReportArr: docodeResponse(
					action.payload,
					'FetchAccountDayBookKey',
				),
			};

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE:
			return { ...state, 
				getPreviousDayCashAccountDayBookReportResponse: action.payload,
				getPreviousDayCashAccountDayBookReportArr: [],
				};

		case EndOfDayActionTypes.GET_PREVIOUS_DAY_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED:
			return { ...state, getPreviousDayCashAccountDayBookReportResponse: null };

		case EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_SUCCESS:
			return {
				...state,
				getCashAccountDayBookReportResponse: action.payload,
				getCashAccountDayBookReportArr: docodeResponse(
					action.payload,
					'FetchAccountDayBookKey',
				),
			};

		case EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_FAILURE:
			return { ...state, 
				getCashAccountDayBookReportResponse: action.payload,
				getCashAccountDayBookReportArr: [],
				};

		case EndOfDayActionTypes.GET_CASH_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED:
			return { ...state, getCashAccountDayBookReportResponse: null };
	

		// Detailed view responses start 
		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				getPrecloseCollectionDetailsResponse: action.payload,
				getPrecloseCollectionDetailsArr: docodeResponse(
					action.payload,
					'GetPrecloseCollectionDetailsKey',
				),
				getPrecloseCollectionDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_FAILURE:
			return { ...state, 
				getPrecloseCollectionDetailsResponse: action.payload,
				getPrecloseCollectionDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_PRECLOSE_COLLECTION_DETAILS_RESPONSE_CHANGED:
			return { ...state, getPrecloseCollectionDetailsResponse: null };

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				getAdvanceCollectionDetailsResponse: action.payload,
				getAdvanceCollectionDetailsArr: docodeResponse(
					action.payload,
					'GetAdvanceCollectionDetailsKey',
				),
				getAdvanceCollectionDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_FAILURE:
			return { ...state, 
				getAdvanceCollectionDetailsResponse: action.payload,
				getAdvanceCollectionDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_ADVANCE_COLLECTION_DETAILS_RESPONSE_CHANGED:
			return { ...state, getAdvanceCollectionDetailsResponse: null };

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				getArrearCollectionDetailsResponse: action.payload,
				getArrearCollectionDetailsArr: docodeResponse(
					action.payload,
					'GetArrearCollectionDetailsKey',
				),
				getArrearCollectionDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_FAILURE:
			return { ...state, 
				getArrearCollectionDetailsResponse: action.payload,
				getArrearCollectionDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_ARREAR_COLLECTION_DETAILS_RESPONSE_CHANGED:
			return { ...state, getArrearCollectionDetailsResponse: null };

		case EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_SUCCESS:
			return {
				...state,
				getVoucherApprovedDetailsResponse: action.payload,
				getVoucherApprovedDetailsArr: docodeResponse(
					action.payload,
					'GetVoucherApprovedDetailsKey',
				),
				getArrearCollectionDetailsStatus: 'Success',
			};

		case EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_FAILURE:
			return { ...state, 
				getVoucherApprovedDetailsResponse: action.payload,
				getVoucherApprovedDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_VOUCHER_APPROVED_DETAILS_RESPONSE_CHANGED:
			return { ...state, getVoucherApprovedDetailsResponse: null };

		case EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_SUCCESS:
			return {
				...state,
				getSubmitEODDetailsResponse: action.payload,
				getSubmitEODDetailsArr: docodeResponse(
					action.payload,
					'GetSubmitEODDetailsKey',
				),
			};

		case EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_FAILURE:
			return { ...state, 
				getSubmitEODDetailsResponse: action.payload,
				getSubmitEODDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_SUBMIT_EOD_DETAILS_RESPONSE_CHANGED:
			return { ...state, getSubmitEODDetailsResponse: null };

		case EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_SUCCESS:
			return {
				...state,
				getRevertEODDetailsResponse: action.payload,
				getRevertEODDetailsArr: docodeResponse(
					action.payload,
					'GetRevertEODDetailsKey',
				),
			};

		case EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_FAILURE:
			return { ...state, 
				getRevertEODDetailsResponse: action.payload,
				getRevertEODDetailsArr: [],
			};

		case EndOfDayActionTypes.GET_REVERT_EOD_DETAILS_RESPONSE_CHANGED:
			return { ...state, getRevertEODDetailsResponse: null };
			
		default:
			return state;
	}
};
