/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChangeGroupOficerActionTypes from './changeGroupOfficer.types';

import {
	branchOfficerListGet,
	officerCenterListGet,
	loanGroupListGet,
	ChangeGroupOfficerListUpdate,
	ChangeProposedOfficerListUpdate,
	ChangeCenterLoanOfficerListUpdate,
} from './changeGroupOfficer.service';

import {
	getBranchOfficerDetailsSuccess,
	getBranchOfficerDetailsFailure,
	getBranchOfficerDetailsResponseChanged,
	getChangeBranchOfficerDetailsSuccess,
	getChangeBranchOfficerDetailsFailure,
	getChangeBranchOfficerDetailsResponseChanged,
	getOfficerCenterDetailsSuccess,
	getOfficerCenterDetailsFailure,
	getOfficerCenterDetailsResponseChanged,
	getLoanGroupDetailsSuccess,
	getLoanGroupDetailsFailure,
	getLoanGroupDetailsResponseChanged,
	updateChangeGroupOfficerDetailsSuccess,
	updateChangeGroupOfficerDetailsFailure,
	updateChangeGroupOfficerDetailsResponseChanged,
	updateChangeProposedOfficerDetailsSuccess,
	updateChangeProposedOfficerDetailsFailure,
	updateChangeProposedOfficerDetailsResponseChanged,
	updateChangeCenterLoanOfficerDetailsSuccess,
	updateChangeCenterLoanOfficerDetailsFailure,
	updateChangeCenterLoanOfficerDetailsResponseChanged,
} from './changeGroupOfficer.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET CHANGE GROUP OFFICER DETAILS  ******************** */


/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getBranchOfficerList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchOfficerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchOfficerDetailsFailure(responseData.data));
		} else {
			yield put(getBranchOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchOfficerDetailsFailure(error));
	}
}

export function* getOfficerCenterList({ payload }) {
	try {
		const key = 'GetOfficerCenterListKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			OfficerID: payload.OfficerID,
			AreaID: payload.AreaID,
			WhenToPay: '-',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerCenterListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerCenterDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerCenterDetailsFailure(error));
	}
}

export function* getLoanGroupList({ payload }) {
	try {
		const key = 'GetLoanGroupListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CenterID: payload.centerID,
			BranchID: payload.branchID,
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanGroupListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanGroupDetailsFailure(responseData.data));
		} else {
			yield put(getLoanGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanGroupDetailsFailure(error));
	}
}

export function* resetGetBranchOfficerListResponse() {
	yield put(getBranchOfficerDetailsResponseChanged());
}

export function* onGetBranchOfficerListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_START,
		getBranchOfficerList,
	);
}

export function* onGetBranchOfficerListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_BRANCH_OFFICER_LIST_RESPONSE_RESET,
		resetGetBranchOfficerListResponse,
	);
}


export function* getChangeBranchOfficerList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
			isNewOfficerAlso: '1',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchOfficerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getChangeBranchOfficerDetailsFailure(responseData.data));
		} else {
			yield put(getChangeBranchOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getChangeBranchOfficerDetailsFailure(error));
	}
}

export function* resetGetChangeBranchOfficerListResponse() {
	yield put(getChangeBranchOfficerDetailsResponseChanged());
}

export function* onGetChangeBranchOfficerListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_START,
		getChangeBranchOfficerList,
	);
}

export function* onGetChangeBranchOfficerListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_CHANGE_BRANCH_OFFICER_LIST_RESPONSE_RESET,
		resetGetChangeBranchOfficerListResponse,
	);
}


export function* resetGetOfficerCenterListResponse() {
	yield put(getOfficerCenterDetailsResponseChanged());
}

export function* onGetOfficerCenterListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_START,
		getOfficerCenterList,
	);
}

export function* onGetOfficerCenterListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_OFFICER_CENTER_LIST_RESPONSE_RESET,
		resetGetOfficerCenterListResponse,
	);
}

export function* resetGetLoanGroupListResponse() {
	yield put(getLoanGroupDetailsResponseChanged());
}

export function* onGetLoanGroupListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_START,
		getLoanGroupList,
	);
}

export function* onGetLoanGroupListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.GET_LOAN_GROUP_LIST_RESPONSE_RESET,
		resetGetLoanGroupListResponse,
	);
}
/** *********************  END UPDATE CHANGE GROUP OFFICER LIST ******************** */


/** *********************  START UPDATE CHANGE GROUP OFFICER DETAILS  ******************** */
export function* updateChangeGroupOfficerList({ payload }) {
	try {
		const key = 'ChangeGroupOfficerKey';
		const transferGroupList = JSON.stringify(payload.transferGroupList).replace(/"/g, "'");
		debugger;
		const jsonData = {
			GroupOfficerID: payload.changeofficerID,
			GroupOfficerName: payload.ChangeGroupOfficerName,
			CompanyID: payload.CompanyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			CurrentGroupOfficerID: payload.CurrentGroupOfficerID,
			CurrentGroupOfficerName: payload.CurrentGroupOfficerName,
			GroupName: payload.GroupName,
			CenterName: payload.CenterName,
			userID: payload.CreatedBy,
			ChangeBranchID: payload.changebranchID,
			isChangeOfficerOnlyLoanIndex: payload.changeForValue,
			DatabaseName: payload.DatabaseName,
			UserAccessBranchID: payload.branchID, // UserAccessBranchID,
			UserName: payload.UserName,
			GroupLoanIndexArr: transferGroupList,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeGroupOfficerListUpdate, encodeData);
		debugger;
		if (responseData.data.statusCode === '100') {
			yield put(updateChangeGroupOfficerDetailsFailure(responseData.data));
		} else {
			yield put(updateChangeGroupOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateChangeGroupOfficerDetailsFailure(error));
	}
}

export function* resetUpdateChangeGroupOfficerListResponse() {
	yield put(updateChangeGroupOfficerDetailsResponseChanged());
}

export function* onUpdateChangeGroupOfficerListDetails() {
	yield takeLatest(ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_START, updateChangeGroupOfficerList);
}

export function* onUpdateChangeGroupOfficerListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.UPDATE_CHANGE_GROUP_OFFICER_LIST_RESPONSE_RESET,
		resetUpdateChangeGroupOfficerListResponse,
	);
}
/** *********************  END UPDATE CHANGE GROUP OFFICER LIST ******************** */


/** *********************  START UPDATE CHANGE PROPOSED OFFICER DETAILS  ******************** */
export function* updateChangeProposedOfficerList({ payload }) {
	try {
		const key = 'ChangeProposedOfficerKey';
		const transferGroupList = JSON.stringify(payload.transferGroupList).replace(/"/g, "'");
		debugger;
		const jsonData = {
			GroupOfficerID: payload.changeofficerID,
			GroupOfficerName: payload.ChangeGroupOfficerName,
			CompanyID: payload.CompanyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			CurrentGroupOfficerID: payload.CurrentGroupOfficerID,
			CurrentGroupOfficerName: payload.CurrentGroupOfficerName,
			GroupName: payload.GroupName,
			CenterName: payload.CenterName,
			userID: payload.CreatedBy,
			ChangeBranchID: payload.changebranchID,
			isChangeOfficerOnlyLoanIndex: payload.changeForValue,
			DatabaseName: payload.DatabaseName,
			UserAccessBranchID: payload.branchID, // UserAccessBranchID,
			UserName: payload.UserName,
			GroupLoanIndexArr: transferGroupList,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeProposedOfficerListUpdate, encodeData);
		debugger;
		if (responseData.data.statusCode === '100') {
			yield put(updateChangeProposedOfficerDetailsFailure(responseData.data));
		} else {
			yield put(updateChangeProposedOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateChangeProposedOfficerDetailsFailure(error));
	}
}

export function* resetUpdateChangeProposedOfficerListResponse() {
	yield put(updateChangeProposedOfficerDetailsResponseChanged());
}

export function* onUpdateChangeProposedOfficerListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_START,
		updateChangeProposedOfficerList,
	);
}

export function* onUpdateChangeProposedOfficerListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.UPDATE_CHANGE_PROPOSED_OFFICER_LIST_RESPONSE_RESET,
		resetUpdateChangeProposedOfficerListResponse,
	);
}
/** *********************  END UPDATE CHANGE PROPOSED OFFICER LIST ******************** */

/** *********************  START UPDATE CHANGE CENTER LOAN OFFICER DETAILS  ******************** */
export function* updateChangeCenterLoanOfficerList({ payload }) {
	try {
		const key = 'ChangeCenterLoanOfficerKey';
		const transferCenterList = JSON.stringify(payload.transferCenterList).replace(/"/g, "'");

		const jsonData = {
			ChangeOfficerID: payload.changeofficerID,
			ChangeOfficerName: payload.ChangeGroupOfficerName,
			CompanyID: payload.CompanyID,
			BranchID: payload.branchID,
			userID: payload.CreatedBy,
			ChangeBranchID: payload.changebranchID,
			DatabaseName: payload.DatabaseName,
			UserName: payload.UserName,
			CurrentGroupOfficerID: payload.officerID,
			CurrentGroupOfficerName: payload.CurrentGroupOfficerName,
			LoanCenterArr: transferCenterList,
		};
		console.log(jsonData);
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ChangeCenterLoanOfficerListUpdate, encodeData);
		debugger;
		if (responseData.data.statusCode === '100') {
			yield put(updateChangeCenterLoanOfficerDetailsFailure(responseData.data));
		} else {
			yield put(updateChangeCenterLoanOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateChangeCenterLoanOfficerDetailsFailure(error));
	}
}

export function* resetUpdateChangeCenterLoanOfficerListResponse() {
	yield put(updateChangeCenterLoanOfficerDetailsResponseChanged());
}

export function* onUpdateChangeCenterLoanOfficerListDetails() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_START,
		updateChangeCenterLoanOfficerList,
	);
}

export function* onUpdateChangeCenterLoanOfficerListResponseReset() {
	yield takeLatest(
		ChangeGroupOficerActionTypes.UPDATE_CHANGE_CENTER_LOAN_OFFICER_LIST_RESPONSE_RESET,
		resetUpdateChangeCenterLoanOfficerListResponse,
	);
}
/** *********************  END UPDATE CHANGE CENTER LOAN OFFICER LIST ******************** */

export function* ChangeGroupOfficerSaga() {
	yield all([
		call(onGetBranchOfficerListDetails),
		call(onGetBranchOfficerListResponseReset),
		call(onGetChangeBranchOfficerListDetails),
		call(onGetChangeBranchOfficerListResponseReset),
		call(onGetOfficerCenterListDetails),
		call(onGetOfficerCenterListResponseReset),
		call(onGetLoanGroupListDetails),
		call(onGetLoanGroupListResponseReset),
		call(onUpdateChangeGroupOfficerListDetails),
		call(onUpdateChangeGroupOfficerListResponseReset),
		call(onUpdateChangeProposedOfficerListDetails),
		call(onUpdateChangeProposedOfficerListResponseReset),
		call(onUpdateChangeCenterLoanOfficerListDetails),
		call(onUpdateChangeCenterLoanOfficerListResponseReset),
	]);
}
