const DashboardTypes = {
	GET_DASHBOARD_DETAILS_START: 'get_dashboard_details_start',
	GET_DASHBOARD_DETAILS_SUCCESS: 'get_dashboard_details_success',
	GET_DASHBOARD_DETAILS_FAILURE: 'get_dashboard_details_failure',
	GET_DASHBOARD_DETAILS_RESPONSE_RESET: 'get_dashboard_details_response_reset',
	GET_DASHBOARD_DETAILS_RESPONSE_CHANGED: 'get_dashboard_details_response_changed',

	GET_LOAN_DETAILS_START: 'get_loan_details_start',
	GET_LOAN_DETAILS_SUCCESS: 'get_loan_details_success',
	GET_LOAN_DETAILS_FAILURE: 'get_loan_details_failure',
	GET_LOAN_DETAILS_RESPONSE_RESET: 'get_loan_details_response_reset',
	GET_LOAN_DETAILS_RESPONSE_CHANGED: 'get_loan_details_response_changed',

	GET_LOAN_INDEX_DETAILS_START: 'get_loan_index_details_start',
	GET_LOAN_INDEX_DETAILS_SUCCESS: 'get_loan_index_details_success',
	GET_LOAN_INDEX_DETAILS_FAILURE: 'get_loan_index_details_failure',
	GET_LOAN_INDEX_DETAILS_RESPONSE_RESET: 'get_loan_index_details_response_reset',
	GET_LOAN_INDEX_DETAILS_RESPONSE_CHANGED: 'get_loan_index_details_response_changed',

	COMPANY_EOD_STATUS_START: 'company_eod_status_start',
	COMPANY_EOD_STATUS_SUCCESS: 'company_eod_status_success',
	COMPANY_EOD_STATUS_FAILURE: 'company_eod_status_failure',
	COMPANY_EOD_STATUS_RESPONSE_RESET: 'company_eod_status_response_reset',
	COMPANY_EOD_STATUS_RESPONSE_CHANGED: 'company_eod_status_response_changed',

	GET_WORKFLOW_DASHBOARD_DETAILS_START: 'get_workflow_dashboard_details_start',
	GET_WORKFLOW_DASHBOARD_DETAILS_SUCCESS: 'get_workflow_dashboard_details_success',
	GET_WORKFLOW_DASHBOARD_DETAILS_FAILURE: 'get_workflow_dashboard_details_failure',
	GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_RESET: 'get_workflow_dashboard_details_response_reset',
	GET_WORKFLOW_DASHBOARD_DETAILS_RESPONSE_CHANGED:
		'get_workflow_dashboard_details_response_changed',

	GET_TODAY_GOLD_PRICE_START: 'get_today_gold_price_start',
	GETTODAY_GOLD_PRICE_SUCCESS: 'get_today_gold_price_success',
	GET_TODAY_GOLD_PRICE_FAILURE: 'get_today_gold_price_failure',
	GET_TODAY_GOLD_PRICE_RESPONSE_RESET: 'get_today_gold_price_response_reset',
	GET_TODAY_GOLD_PRICE_RESPONSE_CHANGED: 'get_today_gold_price_response_changed',

	GET_LOAN_DETAILS_EXPORT_START: 'get_loan_details_export_start',
	GET_LOAN_DETAILS_EXPORT_SUCCESS: 'get_loan_details_export_success',
	GET_LOAN_DETAILS_EXPORT_FAILURE: 'get_loan_details_export_failure',
	GET_LOAN_DETAILS_EXPORT_RESPONSE_RESET: 'get_loan_details_export_response_reset',
	GET_LOAN_DETAILS_EXPORT_RESPONSE_CHANGED: 'get_loan_details_export_response_changed',

	GET_LOAN_DETAILS_EXPORT_JSON_START: 'GET_LOAN_DETAILS_EXPORT_JSON_START',
	GET_LOAN_DETAILS_EXPORT_JSON_SUCCESS: 'GET_LOAN_DETAILS_EXPORT_JSON_SUCCESS',
	GET_LOAN_DETAILS_EXPORT_JSON_FAILURE: 'GET_LOAN_DETAILS_EXPORT_JSON_FAILURE',
	GET_LOAN_DETAILS_EXPORT_JSON_RESET: 'GET_LOAN_DETAILS_EXPORT_JSON_RESET',
	GET_LOAN_DETAILS_EXPORT_JSON_CHANGED: 'GET_LOAN_DETAILS_EXPORT_JSON_CHANGED',

	UPDATE_TODAY_GOLD_PRICE_START: 'update_today_gold_price_start',
	UPDATE_TODAY_GOLD_PRICE_SUCCESS: 'update_today_gold_price_success',
	UPDATE_TODAY_GOLD_PRICE_FAILURE: 'update_today_gold_price_failure',
	UPDATE_TODAY_GOLD_PRICE_RESPONSE_RESET: 'update_today_gold_price_response_reset',
	UPDATE_TODAY_GOLD_PRICE_RESPONSE_CHANGED: 'update_today_gold_price_response_changed',

	EOD_STATUS_PDF_START: 'eod_status_pdf_start',
	EOD_STATUS_PDF_SUCCESS: 'eod_status_pdf_success',
	EOD_STATUS_PDF_FAILURE: 'eod_status_pdf_failure',
	EOD_STATUS_PDF_RESPONSE_RESET: 'eod_status_pdf_response_reset',
	EOD_STATUS_PDF_RESPONSE_CHANGED: 'eod_status_pdf_response_changed',

	REJECT_MEMBER_LIST_START: 'REJECT_MEMBER_LIST_START',
	REJECT_MEMBER_LIST_SUCCESS: 'REJECT_MEMBER_LIST_SUCCESS',
	REJECT_MEMBER_LIST_FAILURE: 'REJECT_MEMBER_LIST_FAILURE',
	REJECT_MEMBER_LIST_RESET: 'REJECT_MEMBER_LIST_RESET',
	REJECT_MEMBER_LIST_CHANGED: 'REJECT_MEMBER_LIST_CHANGED',

	REJECT_NOTES_MEMBER_LIST_START: 'REJECT_NOTES_MEMBER_LIST_START',
	REJECT_NOTES_MEMBER_LIST_SUCCESS: 'REJECT_NOTES_MEMBER_LIST_SUCCESS',
	REJECT_NOTES_MEMBER_LIST_FAILURE: 'REJECT_NOTES_MEMBER_LIST_FAILURE',
	REJECT_NOTES_MEMBER_LIST_RESET: 'REJECT_NOTES_MEMBER_LIST_RESET',
	REJECT_NOTES_MEMBER_LIST_CHANGED: 'REJECT_NOTES_MEMBER_LIST_CHANGED',

	GET_DASHBOARD_PARTICULAR_DETAILS_START: 'GET_DASHBOARD_PARTICULAR_DETAILS_START',
	GET_DASHBOARD_PARTICULAR_DETAILS_SUCCESS: 'GET_DASHBOARD_PARTICULAR_DETAILS_SUCCESS',
	GET_DASHBOARD_PARTICULAR_DETAILS_FAILURE: 'GET_DASHBOARD_PARTICULAR_DETAILS_FAILURE',
	GET_DASHBOARD_PARTICULAR_DETAILS_RESET: 'GET_DASHBOARD_PARTICULAR_DETAILS_RESET',
	GET_DASHBOARD_PARTICULAR_DETAILS_CHANGED: 'GET_DASHBOARD_PARTICULAR_DETAILS_CHANGED',

	GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_START: 'GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_START',
	GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_SUCCESS: 'GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_SUCCESS',
	GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_FAILURE: 'GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_FAILURE',
	GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_RESET: 'GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_RESET',
	GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_CHANGED: 'GET_DASHBOARD_PARTICULAR_DEMAND_DETAILS_CHANGED',

	GET_DASHBOARD_STAFF_COLLECTION_START: 'GET_DASHBOARD_STAFF_COLLECTION_START',
	GET_DASHBOARD_STAFF_COLLECTION_SUCCESS: 'GET_DASHBOARD_STAFF_COLLECTION_SUCCESS',
	GET_DASHBOARD_STAFF_COLLECTION_FAILURE: 'GET_DASHBOARD_STAFF_COLLECTION_FAILURE',
	GET_DASHBOARD_STAFF_COLLECTION_RESET: 'GET_DASHBOARD_STAFF_COLLECTION_RESET',
	GET_DASHBOARD_STAFF_COLLECTION_CHANGED: 'GET_DASHBOARD_STAFF_COLLECTION_CHANGED',

	GET_STAFF_LOCATION_START: 'GET_STAFF_LOCATION_START',
	GET_STAFF_LOCATION_SUCCESS: 'GET_STAFF_LOCATION_SUCCESS',
	GET_STAFF_LOCATION_FAILURE: 'GET_STAFF_LOCATION_FAILURE',
	GET_STAFF_LOCATION_RESET: 'GET_STAFF_LOCATION_RESET',
	GET_STAFF_LOCATION_CHANGED: 'GET_STAFF_LOCATION_CHANGED',

	// DEMAND SECTION

	GET_PARTICULAR_DATE_DEMAND_START: 'GET_PARTICULAR_DATE_DEMAND_START',
	GET_PARTICULAR_DATE_DEMAND_SUCCESS: 'GET_PARTICULAR_DATE_DEMAND_SUCCESS',
	GET_PARTICULAR_DATE_DEMAND_FAILURE: 'GET_PARTICULAR_DATE_DEMAND_FAILURE',
	GET_PARTICULAR_DATE_DEMAND_RESET: 'GET_PARTICULAR_DATE_DEMAND_RESET',
	GET_PARTICULAR_DATE_DEMAND_CHANGED: 'GET_PARTICULAR_DATE_DEMAND_CHANGED',

	GET_PARTICULAR_DATE_DEMAND_INFO_START: 'GET_PARTICULAR_DATE_DEMAND_INFO_START',
	GET_PARTICULAR_DATE_DEMAND_INFO_SUCCESS: 'GET_PARTICULAR_DATE_DEMAND_INFO_SUCCESS',
	GET_PARTICULAR_DATE_DEMAND_INFO_FAILURE: 'GET_PARTICULAR_DATE_DEMAND_INFO_FAILURE',
	GET_PARTICULAR_DATE_DEMAND_INFO_RESET: 'GET_PARTICULAR_DATE_DEMAND_INFO_RESET',
	GET_PARTICULAR_DATE_DEMAND_INFO_CHANGED: 'GET_PARTICULAR_DATE_DEMAND_INFO_CHANGED',

	GET_PARTICULAR_DATE_MEMBER_ABSENT_START: 'GET_PARTICULAR_DATE_MEMBER_ABSENT_START',
	GET_PARTICULAR_DATE_MEMBER_ABSENT_SUCCESS: 'GET_PARTICULAR_DATE_MEMBER_ABSENT_SUCCESS',
	GET_PARTICULAR_DATE_MEMBER_ABSENT_FAILURE: 'GET_PARTICULAR_DATE_MEMBER_ABSENT_FAILURE',
	GET_PARTICULAR_DATE_MEMBER_ABSENT_RESET: 'GET_PARTICULAR_DATE_MEMBER_ABSENT_RESET',
	GET_PARTICULAR_DATE_MEMBER_ABSENT_CHANGED: 'GET_PARTICULAR_DATE_MEMBER_ABSENT_CHANGED',

	// COLLECTION SECTION
	GET_PARTICULAR_DATE_COLLECTION_START: 'GET_PARTICULAR_DATE_COLLECTION_START',
	GET_PARTICULAR_DATE_COLLECTION_SUCCESS: 'GET_PARTICULAR_DATE_COLLECTION_SUCCESS',
	GET_PARTICULAR_DATE_COLLECTION_FAILURE: 'GET_PARTICULAR_DATE_COLLECTION_FAILURE',
	GET_PARTICULAR_DATE_COLLECTION_RESET: 'GET_PARTICULAR_DATE_COLLECTION_RESET',
	GET_PARTICULAR_DATE_COLLECTION_CHANGED: 'GET_PARTICULAR_DATE_COLLECTION_CHANGED',

	GET_PARTICULAR_DATE_COLLECTION_INFO_START: 'GET_PARTICULAR_DATE_COLLECTION_INFO_START',
	GET_PARTICULAR_DATE_COLLECTION_INFO_SUCCESS: 'GET_PARTICULAR_DATE_COLLECTION_INFO_SUCCESS',
	GET_PARTICULAR_DATE_COLLECTION_INFO_FAILURE: 'GET_PARTICULAR_DATE_COLLECTION_INFO_FAILURE',
	GET_PARTICULAR_DATE_COLLECTION_INFO_RESET: 'GET_PARTICULAR_DATE_COLLECTION_INFO_RESET',
	GET_PARTICULAR_DATE_COLLECTION_INFO_CHANGED: 'GET_PARTICULAR_DATE_COLLECTION_INFO_CHANGED',

	// WEEK COLLECTION SECTION
	GET_WEEK_COLLECTION_START: 'GET_WEEK_COLLECTION_START',
	GET_WEEK_COLLECTION_SUCCESS: 'GET_WEEK_COLLECTION_SUCCESS',
	GET_WEEK_COLLECTION_FAILURE: 'GET_WEEK_COLLECTION_FAILURE',
	GET_WEEK_COLLECTION_RESET: 'GET_WEEK_COLLECTION_RESET',
	GET_WEEK_COLLECTION_CHANGED: 'GET_WEEK_COLLECTION_CHANGED',

	GET_WEEK_YT_COLLECTION_START: 'GET_WEEK_YT_COLLECTION_START',
	GET_WEEK_YT_COLLECTION_SUCCESS: 'GET_WEEK_YT_COLLECTION_SUCCESS',
	GET_WEEK_YT_COLLECTION_FAILURE: 'GET_WEEK_YT_COLLECTION_FAILURE',
	GET_WEEK_YT_COLLECTION_RESET: 'GET_WEEK_YT_COLLECTION_RESET',
	GET_WEEK_YT_COLLECTION_CHANGED: 'GET_WEEK_YT_COLLECTION_CHANGED',

	// SMM COLLECTION LIST SECTION
	SMS_USED_LIST_START: 'SMS_USED_LIST_START',
	SMS_USED_LIST_SUCCESS: 'SMS_USED_LIST_SUCCESS',
	SMS_USED_LIST_FAILURE: 'SMS_USED_LIST_FAILURE',
	SMS_USED_LIST_RESET: 'SMS_USED_LIST_RESET',
	SMS_USED_LIST_CHANGED: 'SMS_USED_LIST_CHANGED',

	// KYC COLLECTION LIST SECTION
	KYC_USED_LIST_START: 'KYC_USED_LIST_START',
	KYC_USED_LIST_SUCCESS: 'KYC_USED_LIST_SUCCESS',
	KYC_USED_LIST_FAILURE: 'KYC_USED_LIST_FAILURE',
	KYC_USED_LIST_RESET: 'KYC_USED_LIST_RESET',
	KYC_USED_LIST_CHANGED: 'KYC_USED_LIST_CHANGED',

	// ARREAR COLLECTION LIST SECTION
	ARREAR_COLLECTION_LIST_START: 'ARREAR_COLLECTION_LIST_START',
	ARREAR_COLLECTION_LIST_SUCCESS: 'ARREAR_COLLECTION_LIST_SUCCESS',
	ARREAR_COLLECTION_LIST_FAILURE: 'ARREAR_COLLECTION_LIST_FAILURE',
	ARREAR_COLLECTION_LIST_RESET: 'ARREAR_COLLECTION_LIST_RESET',
	ARREAR_COLLECTION_LIST_CHANGED: 'ARREAR_COLLECTION_LIST_CHANGED',

	// EXPORT SECTION
	// DEMAND COLLECTION EXPORT
	DEMAND_COLLECTION_EXPORT_START: 'DEMAND_COLLECTION_EXPORT_START',
	DEMAND_COLLECTION_EXPORT_SUCCESS: 'DEMAND_COLLECTION_EXPORT_SUCCESS',
	DEMAND_COLLECTION_EXPORT_FAILURE: 'DEMAND_COLLECTION_EXPORT_FAILURE',
	DEMAND_COLLECTION_EXPORT_RESET: 'DEMAND_COLLECTION_EXPORT_RESET',
	DEMAND_COLLECTION_EXPORT_CHANGED: 'DEMAND_COLLECTION_EXPORT_CHANGED',

	DASHBOARD_EOD_EXPORT_START: 'DASHBOARD_EOD_EXPORT_START',
	DASHBOARD_EOD_EXPORT_SUCCESS: 'DASHBOARD_EOD_EXPORT_SUCCESS',
	DASHBOARD_EOD_EXPORT_FAILURE: 'DASHBOARD_EOD_EXPORT_FAILURE',
	DASHBOARD_EOD_EXPORT_RESET: 'DASHBOARD_EOD_EXPORT_RESET',
	DASHBOARD_EOD_EXPORT_CHANGED: 'DASHBOARD_EOD_EXPORT_CHANGED',

	// STAFF COLLECTION
	DASHBOARD_STAFF_MAP_COLLECTION_START: 'DASHBOARD_STAFF_MAP_COLLECTION_START',
	DASHBOARD_STAFF_MAP_COLLECTION_SUCCESS: 'DASHBOARD_STAFF_MAP_COLLECTION_SUCCESS',
	DASHBOARD_STAFF_MAP_COLLECTION_FAILURE: 'DASHBOARD_STAFF_MAP_COLLECTION_FAILURE',
	DASHBOARD_STAFF_MAP_COLLECTION_RESET: 'DASHBOARD_STAFF_MAP_COLLECTION_RESET',
	DASHBOARD_STAFF_MAP_COLLECTION_CHANGED: 'DASHBOARD_STAFF_MAP_COLLECTION_CHANGED',

	// STAFF COLLECTION
	DASHBOARD_COLLECTION_NOTE_INFO_START: 'DASHBOARD_COLLECTION_NOTE_INFO_START',
	DASHBOARD_COLLECTION_NOTE_INFO_SUCCESS: 'DASHBOARD_COLLECTION_NOTE_INFO_SUCCESS',
	DASHBOARD_COLLECTION_NOTE_INFO_FAILURE: 'DASHBOARD_COLLECTION_NOTE_INFO_FAILURE',
	DASHBOARD_COLLECTION_NOTE_INFO_RESET: 'DASHBOARD_COLLECTION_NOTE_INFO_RESET',
	DASHBOARD_COLLECTION_NOTE_INFO_CHANGED: 'DASHBOARD_COLLECTION_NOTE_INFO_CHANGED',

};

export default DashboardTypes;
