/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import PortFlioAtRiskTypes from './portflioAtRiskReport.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getportflioAtRiskStart = (getData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_START,
	payload: getData,
});

export const getportflioAtRiskSuccess = (successData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_SUCCESS,
	payload: successData,
});

export const getportflioAtRiskFailure = (failureData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_FAILURE,
	payload: failureData,
});

export const getportflioAtRiskResponseResetStart = () => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_RESPONSE_RESET,
});

export const getportflioAtRiskResponseChanged = () => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_RESPONSE_CHANGED,
});

export const getportflioAtRiskExportStart = (getData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_START,
	payload: getData,
});

export const getportflioAtRiskExportSuccess = (successData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_SUCCESS,
	payload: successData,
});

export const getportflioAtRiskExportFailure = (failureData) => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_FAILURE,
	payload: failureData,
});

export const getportflioAtRiskResponseExportResetStart = () => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_RESPONSE_RESET,
});

export const getportflioAtRiskExportResponseChanged = () => ({
	type: PortFlioAtRiskTypes.PORTFLIO_AT_RISK_EXPORT_RESPONSE_CHANGED,
});



/** ***************GET USER DETAILS START************ */

export const getUserDetailsStart = (getData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_START,
	payload: getData,
});

export const getUserDetailsSuccess = (successData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_SUCCESS,
	payload: successData,
});

export const getUserDetailsFailure = (failureData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_FAILURE,
	payload: failureData,
});

export const getUserDetailsResponseResetStart = () => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_RESET,
});

export const getUserDetailsResponseChanged = () => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_CHANGED,
});

/** ***************GET USER DETAILS END************* */

/** *********************LOAN_INFO_BASEDON_MEMBER START***** */
export const getLoanInfoBasedOnMemberStart = (getData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_START,
	payload: getData,
});

export const getLoanInfoBasedOnMemberSuccess = (successData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_SUCCESS,
	payload: successData,
});

export const getLoanInfoBasedOnMemberFailure = (failureData) => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_FAILURE,
	payload: failureData,
});

export const getLoanInfoBasedOnMemberResponseResetStart = () => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_RESET,
});

export const getLoanInfoBasedOnMemberResponseChanged = () => ({
	type: PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_CHANGED,
});
