/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProductTypeActionTypes from './product-type.types';
import {
	productTypeDetailsAdd,
	productTypeDetailsUpdate,
	productTypeListGet,
	productTypeDetailsFetch,
	productTypeDetailsDelete,
} from './product-type.service';

import {
	addProductTypeDetailsSuccess,
	addProductTypeDetailsFailure,
	addProductTypeDetailsResponseChanged,
	updateProductTypeDetailsSuccess,
	updateProductTypeDetailsFailure,
	updateProductTypeDetailsResponseChanged,
	getProductTypeListSuccess,
	getProductTypeListFailure,
	getProductTypeListResponseChanged,
	fetchProductTypeDetailsSuccess,
	fetchProductTypeDetailsFailure,
	fetchProductTypeDetailsResponseChanged,
	deleteProductTypeDetailsSuccess,
	deleteProductTypeDetailsFailure,
	deleteProductTypeDetailsResponseChanged,
} from './product-type.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PRODUCT TYPE DETAILS  ******************** */
export function* createProductType({ payload }) {
	try {
		const key = 'AddProductTypeDetailKey';
		const jsonData = {
			ProductTypeName: payload.productTypeName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productTypeDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductTypeDetailsFailure(responseData.data));
		} else {
			yield put(addProductTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductTypeDetailsFailure(error));
	}
}

export function* resetAddProductTypeResponse() {
	yield put(addProductTypeDetailsResponseChanged());
}

export function* onCreateProductTypeDetails() {
	yield takeLatest(ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_START, createProductType);
}

export function* onCreateProductTypeResponseReset() {
	yield takeLatest(
		ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
		resetAddProductTypeResponse,
	);
}
/** *********************  END ADD PRODUCT TYPE DETAILS ******************** */

/** *********************  START UPDATE PRODUCT TYPE DETAILS  ******************** */
export function* productTypeUpdate({ payload }) {
	try {
		const key = 'UpdateProductTypeDetailsKey';
		const jsonData = {
			ProductTypeID: payload.productTypeID,
			ProductTypeName: payload.productTypeName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productTypeDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductTypeDetailsFailure(responseData.data));
		} else {
			yield put(updateProductTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductTypeDetailsFailure(error));
	}
}

export function* resetUpdateProductTypeDetailsResponse() {
	yield put(updateProductTypeDetailsResponseChanged());
}

export function* onUpdateProductTypeDetails() {
	yield takeLatest(ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_START, productTypeUpdate);
}

export function* onUpdateProductTypeResponseReset() {
	yield takeLatest(
		ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
		resetUpdateProductTypeDetailsResponse,
	);
}
/** *********************  END UPDATE PRODUCT TYPE DETAILS ******************** */

/** *********************  START GET PRODUCT TYPE LIST  ******************** */
export function* productTypeDetailsListGet({ payload }) {
	try {
		const key = 'GetProductTypeListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			InputSearch: payload.searchValueSet,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productTypeListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductTypeListFailure(responseData.data));
		} else {
			yield put(getProductTypeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductTypeListFailure(error));
	}
}

export function* resetGetProductTypeListResponse() {
	yield put(getProductTypeListResponseChanged());
}

export function* onGetProductTypeListDetails() {
	yield takeLatest(ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_START, productTypeDetailsListGet);
}

export function* onGetProductTypeListResponseReset() {
	yield takeLatest(
		ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_RESPONSE_RESET,
		resetGetProductTypeListResponse,
	);
}
/** *********************  END GET PRODUCT TYPE LIST ******************** */

/** *********************  START FETCH PRODUCT TYPE DETAILS  ******************** */
export function* fetchProductTypeData({ payload }) {
	try {
		const key = 'FetchProductTypeDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductTypeID: payload.productTypeID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productTypeDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductTypeDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductTypeDetailsFailure(error));
	}
}

export function* resetFetchProductTypeDetailsResponse() {
	yield put(fetchProductTypeDetailsResponseChanged());
}

export function* onFetchProductTypeDetails() {
	yield takeLatest(ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_START, fetchProductTypeData);
}

export function* onFetchProductTypeResponseReset() {
	yield takeLatest(
		ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
		resetFetchProductTypeDetailsResponse,
	);
}
/** *********************  END FETCH PRODUCT TYPE DETAILS ******************** */

/** *********************  START DELETE PRODUCT TYPE DETAILS  ******************** */
export function* productTypeDelete({ payload }) {
	try {
		const key = 'DeleteProductTypeDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductTypeID: payload.productTypeID,
			ProductTypeName: payload.productTypeName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productTypeDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteProductTypeDetailsFailure(responseData.data));
		} else {
			yield put(deleteProductTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteProductTypeDetailsFailure(error));
	}
}

export function* resetDeleteProductTypeDetailsResponse() {
	yield put(deleteProductTypeDetailsResponseChanged());
}

export function* onDeleteProductTypeDetail() {
	yield takeLatest(ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_START, productTypeDelete);
}

export function* onDeleteProductTypeResponseReset() {
	yield takeLatest(
		ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
		resetDeleteProductTypeDetailsResponse,
	);
}
/** *********************  END DELETE PRODUCT TYPE DETAILS ******************** */

export function* productTypeMasterSaga() {
	yield all([
		call(onCreateProductTypeDetails),
		call(onCreateProductTypeResponseReset),
		call(onUpdateProductTypeDetails),
		call(onUpdateProductTypeResponseReset),
		call(onGetProductTypeListDetails),
		call(onGetProductTypeListResponseReset),
		call(onFetchProductTypeDetails),
		call(onFetchProductTypeResponseReset),
		call(onDeleteProductTypeDetail),
		call(onDeleteProductTypeResponseReset),
	]);
}
