// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const profileDetailsGet = (listData) => {
	const data = {
		FetchProfileDetailsToken: listData,
	};
	const url = `${API_URL}fetchProfileDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const profileDetailsUpdate = (updateData) => {
	const data = {
		UpdateProfileToken: updateData,
	};
	const url = `${API_URL}UpdateProfileDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const passwordUpdate = (updatPasswordData) => {
	const data = {
		UpdatePasswordToken: updatPasswordData,
	};
	const url = `${API_URL}UpdateUserPassword`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const secreteAuthKeyGenerate = (updatData) => {
	const data = {
		GenerateSecreteAuthToken: updatData,
	};
	const url = `${API_URL}GenerateSecreteAuthKey`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
