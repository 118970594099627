const AssignUserRightsTypes = {
	CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START: 'CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START',
	CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS:
		'CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS',
	CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE:
		'CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE',
	CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET:
		'CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET',
	CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED:
		'CREATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED',

	UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START: 'UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_START',
	UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS:
		'UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_SUCCESS',
	UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE:
		'UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_FAILURE',
	UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET:
		'UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_RESET',
	UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED:
		'UPDATE_USER_ASSIGN_CONTROL_RIGHTS_PAGE_RESPONSE_CHANGED',

	SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_START: 'SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_START',
	SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_SUCCESS: 'SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_SUCCESS',
	SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_FAILURE: 'SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_FAILURE',
	SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_RESET:
		'SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_RESET',
	SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_CHANGED:
		'SAVE_USER_ASSIGN_CONTROL_ALL_PAGE_RESPONSE_CHANGED',

	FETCH_LIST_USER_CONTROL_DETAILS_START: 'FETCH_LIST_USER_CONTROL_DETAILS_START',
	FETCH_LIST_USER_CONTROL_DETAILS_SUCCESS: 'FETCH_LIST_USER_CONTROL_DETAILS_SUCCESS',
	FETCH_LIST_USER_CONTROL_DETAILS_FAILURE: 'FETCH_LIST_USER_CONTROL_DETAILS_FAILURE',
	FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_RESET:
		'FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_RESET',
	FETCH_LIST_USER_CONTROL_DETAILS_ARR_RESET: 'FETCH_LIST_USER_CONTROL_DETAILS_ARR_RESET',
	FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_CHANGED:
		'FETCH_LIST_USER_CONTROL_DETAILS_RESPONSE_CHANGED',
	FETCH_LIST_USER_CONTROL_DETAILS_ARR_CHANGED: 'FETCH_LIST_USER_CONTROL_DETAILS_ARR_CHANGED',
};
export default AssignUserRightsTypes;
