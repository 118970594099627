const LeadReportActionTypes = {
	FETCH_LEAD_LIST_START: 'fetch_lead_list_start',
	FETCH_LEAD_LIST_SUCCESS: 'fetch_lead_list_success',
	FETCH_LEAD_LIST_FAILURE: 'fetch_lead_list_failure',
	FETCH_LEAD_LIST_RESET_START: 'fetch_lead_list_reset_start',
	FETCH_LEAD_LIST_CHANGED: 'fetch_lead_list_changed',

	FETCH_OFFICER_LIST_START: 'fetch_officer_list_start',
	FETCH_OFFICER_LIST_SUCCESS: 'fetch_officer_list_success',
	FETCH_OFFICER_LIST_FAILURE: 'fetch_officer_list_failure',
	FETCH_OFFICER_LIST_RESET_START: 'fetch_officer_list_reset_start',
	FETCH_OFFICER_LIST_CHANGED: 'fetch_officer_list_changed',
};
export default LeadReportActionTypes;
