const OfficialDetailsActionType = {
	OFFICIAL_DETAILS_ADD_START: 'official_details_add_start',
	OFFICIAL_DETAILS_ADD_SUCCESS: 'official_details_add_success',
	OFFICIAL_DETAILS_ADD_FAILURE: 'official_details_add_failure',
	OFFICIAL_DETAILS_ADD_RESPONSE_REST_START: 'official_details_add_response_reset_start',
	OFFICIAL_DETAILS_ADD_RESPONSE_CHANGED: 'official_details_add_response_changed',

	ECONOMIC_ACTIVITY_LIST_START: 'economic_activity_list_start',
	ECONOMIC_ACTIVITY_LIST_SUCCESS: 'economic_activity_list_success',
	ECONOMIC_ACTIVITY_LIST_FAILURE: 'economic_activity_list_failure',
	ECONOMIC_ACTIVITY_LIST_RESPONSE_REST_START: 'economic_activity_list_response_reset_start',
	ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED: 'economic_activity_list_response_changed',

	CENTER_LIST_START: 'center_list_start',
	CENTER_LIST_SUCCESS: 'center_list_success',
	CENTER_LIST_FAILURE: 'center_list_failure',
	CENTER_LIST_RESPONSE_REST_START: 'center_list_response_reset_start',
	CENTER_LIST_RESPONSE_CHANGED: 'center_list_response_changed',

	PRODUCT_TYPE_LIST_START: 'product_type_list_start',
	PRODUCT_TYPE_LIST_SUCCESS: 'product_type_list_success',
	PRODUCT_TYPE_LIST_FAILURE: 'product_type_list_failure',
	PRODUCT_TYPE_LIST_RESPONSE_RESET_START: 'product_type_list_response_reset_start',
	PRODUCT_TYPE_LIST_RESPONSE_CHANGED: 'product_type_list_response_changed',
};

export default OfficialDetailsActionType;
