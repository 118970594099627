// eslint-disable-next-line import/no-unresolved
import SignatureActionType from './signature.types';

// START UPLOAD SIGNATURE
export const uploadSignatureStart = (signatureUploadCredentials) => ({
	type: SignatureActionType.UPLOAD_SIGNATURE_START,
	payload: signatureUploadCredentials,
});

export const uploadSignatureSuccess = (signatureUploadSuccess) => ({
	type: SignatureActionType.UPLOAD_SIGNATURE_SUCCESS,
	payload: signatureUploadSuccess,
});

export const uploadSignatureFailure = (signatureUploadFailure) => ({
	type: SignatureActionType.UPLOAD_SIGNATURE_FAILURE,
	payload: signatureUploadFailure,
});

export const uploadSignatureResponseResetStart = () => ({
	type: SignatureActionType.UPLOAD_SIGNATURE_RESPONSE_REST_START,
});

export const uploadSignatureUserResponseChanged = () => ({
	type: SignatureActionType.UPLOAD_SIGNATURE_RESPONSE_CHANGED,
});
// END UPLOAD SIGNATURE
