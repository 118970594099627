const LoanActionTypes = {
	FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_START: 'fetch_all_custom_product_loan_proposal_start',
	FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_SUCCESS:
		'fetch_all_custom_product_loan_proposal_success',
	FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_FAILURE:
		'fetch_all_custom_product_loan_proposal_failure',
	FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET:
		'fetch_all_custom_product_loan_proposal_response_reset',
	FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'fetch_all_custom_product_loan_proposal_response_changed',
};
export default LoanActionTypes;
