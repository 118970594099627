/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LoanActionTypes from './custom-product-loan-proposal.types';

/** ***************** GET PRODUCT LOAN PROPOSAL START ***************** */
export const getCustomProductLoanProposalStart = (getData) => ({
	type: LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_START,
	payload: getData,
});

export const getCustomProductLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const getCustomProductLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const getCustomProductLoanProposalResponseReset = () => ({
	type: LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const getCustomProductLoanProposalListResponseChanged = () => ({
	type: LoanActionTypes.FETCH_ALL_CUSTOM_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LOAN PROPOSAL END ************ */