/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const AllRuleEngineGet = (listData) => {
	const data = {
		getRuledetailsToken: listData,
	};
	const url = `${API_URL}GetRuleEngine`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const AddRuleEngine = (listData) => {
	const data = {
		addRuledetailsToken: listData,
	};
	const url = `${API_URL}CreateRuleEngine`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const DeleteRuleEngine = (listData) => {
	const data = {
		deleteRuleDetailsToken: listData,
	};
	const url = `${API_URL}DeleteRuleEngine`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};