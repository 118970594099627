/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import RuleEngineActionTypes from './ruleEngine.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getRuleEngineListStatus: null,
	getRuleEngineListArr: [],
	addRuleEngineListStatus: null,
	addRuleEngineListArr: [],
	deleteRuleEngineListStatus: null,
	deleteRuleEngineListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RuleEngineActionTypes.GET_RULE_ENGINE_LIST_SUCCESS:
			return {
				...state,
				getRuleEngineListStatus: 'SUCCESS',
				getRuleEngineListArr: docodeResponse(action.payload, 'getRuledetailsKey'),
			};

		case RuleEngineActionTypes.GET_RULE_ENGINE_LIST_FAILURE:
			return { ...state, getRuleEngineListStatus: 'FAILURE' };

		case RuleEngineActionTypes.GET_RULE_ENGINE_LIST_CHANGED:
			return { ...state, getRuleEngineListStatus: null };

		case RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_SUCCESS:
			return {
				...state,
				addRuleEngineListStatus: 'SUCCESS',
				addRuleEngineListArr: docodeResponse(action.payload, 'addRuledetailsKey'),
			};

		case RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_FAILURE:
			return { ...state, addRuleEngineListStatus: 'FAILURE' };

		case RuleEngineActionTypes.ADD_RULE_ENGINE_LIST_CHANGED:
			return { ...state, addRuleEngineListStatus: null };

		case RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_SUCCESS:
			return {
				...state,
				deleteRuleEngineListStatus: 'SUCCESS',
				deleteRuleEngineListArr: docodeResponse(action.payload, 'deleteRuleDetailsKey'),
			};

		case RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_FAILURE:
			return { ...state, deleteRuleEngineListStatus: 'FAILURE' };

		case RuleEngineActionTypes.DELETE_RULE_ENGINE_LIST_CHANGED:
			return { ...state, deleteRuleEngineListStatus: null };

		default:
			return state;
	}
};
