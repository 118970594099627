/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import RequestReportActionTypes from './requestReport.types';

/** ***************** FETCH REQUEST REPORT DETAILS START *********************** */
export const fetchRequestReportDetailsStart = (fetchData) => ({
	type: RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_START,
	payload: fetchData,
});

export const fetchRequestReportDetailsSuccess = (successData) => ({
	type: RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchRequestReportDetailsFailure = (failureData) => ({
	type: RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchRequestReportDetailsResponseResetStart = () => ({
	type: RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_RESPONSE_RESET,
});

export const fetchRequestReportDetailsResponseChanged = () => ({
	type: RequestReportActionTypes.FETCH_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH REQUEST REPORT DETAILS END *********************** */
/** ***************** ADD REQUEST REPORT DETAILS START *********************** */
export const addRequestReportDetailsStart = (fetchData) => ({
	type: RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_START,
	payload: fetchData,
});

export const addRequestReportDetailsSuccess = (successData) => ({
	type: RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_SUCCESS,
	payload: successData,
});

export const addRequestReportDetailsFailure = (failureData) => ({
	type: RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_FAILURE,
	payload: failureData,
});

export const addRequestReportDetailsResponseResetStart = () => ({
	type: RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_RESPONSE_RESET,
});

export const addRequestReportDetailsResponseChanged = () => ({
	type: RequestReportActionTypes.ADD_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD REQUEST REPORT DETAILS END *********************** */


export const deleteRequestReportStart = (data) => ({
	type: RequestReportActionTypes.DELETE_REQUEST_REPORT_START,
	payload: data,
});

export const deleteRequestReportSuccess = (successData) => ({
	type: RequestReportActionTypes.DELETE_REQUEST_REPORT_SUCCESS,
	payload: successData,
});

export const deleteRequestReportFailure = (failureData) => ({
	type: RequestReportActionTypes.DELETE_REQUEST_REPORT_FAILURE,
	payload: failureData,
});

export const deleteRequestReportReset = () => ({
	type: RequestReportActionTypes.DELETE_REQUEST_REPORT_RESET,
});

export const deleteRequestReportChanged = () => ({
	type: RequestReportActionTypes.DELETE_REQUEST_REPORT_CHANGED,
});
