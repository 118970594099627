// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable radix */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Lottie from 'react-lottie';
import { decode } from 'js-base64';
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import Input from '../../components/bootstrap/forms/Input';
import Loader from '../../assets/Loader.json';
import { demoPages } from '../../menu';
import {
	getUserDetailsStart,
	getUserDetailsReset,
	updateUserPasswordStart,
	updateUserPasswordReset,
	addExistVersionDetails,
	signInReset,
} from '../../redux/login/login.actions';
import {
	getLoginObjDetails,
	selectGetUserDetailsStatus,
	selectGetUserDetailsResp,
	selectUpdateUserPasswordStatus,
	selectUpdateUserPasswordResp,
	getVersionDetails,
	getUserPasswordDetails,
} from '../../redux/login/login.selector';
import {
	fetchAllBranchesStart,
	fetchAllBranchesResponseResetStart,
	fetchAllCenterListStart,
	fetchAllCenterListResponseResetStart,
	fetchVersionStart,
	fetchVersionResponseResetStart,
} from '../../redux/general/general.action';
import {
	selectFetchAllBranchesResponse,
	selectGetAllCenterResponse,
	selectFetchVersionDetailsResponse,
	selectFetchVersionDetailsData,
} from '../../redux/general/general.selector';
import { CardBody, CardFooter } from '../../components/bootstrap/Card';
import { setLocalStore, deleteLocalStore } from '../../indexedDb/db';

const CommonPasswordUpdate = () => {
	// const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loginData = useSelector(getLoginObjDetails);
	const versionData = useSelector(getVersionDetails);
	const passwordData = useSelector(getUserPasswordDetails);
	const timeoutRef = useRef(null);
	const [timeOutTimerValue, setTimeOutTimerValue] = useState(600000);

	const userDetailsStatus = useSelector(selectGetUserDetailsStatus);
	const userDetailsResp = useSelector(selectGetUserDetailsResp);

	const userPasswordUpdateStatus = useSelector(selectUpdateUserPasswordStatus);
	const fetchVersionResp = useSelector(selectFetchVersionDetailsResponse);
	const fetchVersionData = useSelector(selectFetchVersionDetailsData);
	const fetchAllBranchResp = useSelector(selectFetchAllBranchesResponse);
	const fetchAllCenterResp = useSelector(selectGetAllCenterResponse);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [isVersionUpdate, setIsVersionUpdate] = useState(false);
	const { companyID, databaseName, userID, userLevel, userRole, userName, UserPhoneNumber } =
		loginData;

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Loader,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const validate = (values) => {
		const errors = {};
		if (!values.currentPassword) {
			errors.currentPassword = 'Required';
		}
		if (!values.newPassword) {
			errors.newPassword = 'Required';
		} else if (values.newPassword.length < 8) {
			errors.newPassword = 'Minimum 8 character length';
		} else if (
			values.newPassword.length >= 8 &&
			(!/[A-Z]/.test(values.newPassword) ||
				!/\d/.test(values.newPassword) ||
				!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.newPassword))
		) {
			errors.newPassword =
				'Atleast 1 letter should be Uppercase and 1 number and 1 special Characters';
		} else if (values.newPassword === values.currentPassword) {
			errors.newPassword = 'New Password should be not equal to Current Password';
		}
		if (!values.confirmPassword) {
			errors.confirmPassword = 'Required';
		} else if (values.confirmPassword.length < 8) {
			errors.confirmPassword = 'Minimum 8 character length';
		} else if (
			values.confirmPassword.length >= 8 &&
			(!/[A-Z]/.test(values.confirmPassword) ||
				!/\d/.test(values.confirmPassword) ||
				!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.confirmPassword))
		) {
			errors.confirmPassword =
				'Atleast 1 letter should be Uppercase and 1 number and 1 special Characters';
		} else if (values.confirmPassword !== values.newPassword) {
			errors.confirmPassword = 'New Password and Confirm must be equal';
		}
		return errors;
	};
	const formik = useFormik({
		initialValues: {
			companyID,
			databaseName,
			userID,
			userLevel,
			userRole,
			userName,
			UserPhoneNumber,
			isModalOpen: false,
			staticArrTitle: 'Change Password',
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		validate,
		// eslint-disable-next-line consistent-return
		onSubmit: (values) => {
			dispatch(updateUserPasswordStart(values));
		},
	});
	useLayoutEffect(() => {
		if (loginData) {
			dispatch(getUserDetailsStart(loginData));
			dispatch(fetchVersionStart(loginData));
		}
	}, []);

	useEffect(() => {
		const currentDate = new Date();
		if (databaseName === 'SMCF') {
			setTimeOutTimerValue(1200000); // 20 mins
		}

		startTimeout();

		// Event listeners for user activity
		const resettableEvents = [
			'keydown',
			'keyup',
			'click',
			'mousemove',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'scroll',
			'focus',
		];
		resettableEvents.forEach((event) => {
			document.addEventListener(event, resetTimeout);
		});

		return () => {
			// Cleanup event listeners
			resettableEvents.forEach((event) => {
				document.removeEventListener(event, resetTimeout);
			});
			clearTimeout(timeoutRef.current);
		};
	}, []);

	const startTimeout = () => {
		timeoutRef.current = setTimeout(() => {
			logoutByTimer();
		}, timeOutTimerValue);
	};

	const resetTimeout = () => {
		clearTimeout(timeoutRef.current);
		startTimeout();
	};

	const logoutByTimer = () => {
		if (
			['WorldVision', 'SMCF', 'FinCare'].includes(databaseName) ||
			companyID === '61' ||
			companyID === '118'
		) {
			logout();
		}
	};

	useEffect(() => {
		if (userDetailsStatus !== null) {
			if (userDetailsStatus === 'SUCCESS') {
				if (
					userDetailsResp[0].UserCredentials !== null &&
					userDetailsResp[0].UserCredentials !== undefined
				) {
					const userPassword = decode(userDetailsResp[0].UserCredentials);
					if (userPassword !== passwordData.UserPassword) {
						setShowLogoutModal(true);
					}
				}
				if (
					parseInt(userDetailsResp[0].isPasswordExpiredDays) > 25 &&
					parseInt(userDetailsResp[0].isPasswordExpiredDays) < 30
				) {
					swal(
						'warning !',
						`Your password is going to expire in ${userDetailsResp[0].expiredCount} days.  Please Update Your Password.`,
						'warning',
					);
				} else if (userDetailsResp[0].isPasswordExpired === 1) {
					formik.setFieldValue('isModalOpen', true);
				}
			}
			dispatch(getUserDetailsReset());
		}
		if (userPasswordUpdateStatus !== null) {
			if (userPasswordUpdateStatus === 'SUCCESS') {
				swal('Success', `Your password is Updated`, 'success');
			}
			formik.setFieldValue('isModalOpen', false);
			dispatch(updateUserPasswordReset());
		}
		if (fetchVersionResp && fetchVersionData !== null && fetchVersionData !== undefined) {
			if (
				fetchVersionData.data !== null &&
				fetchVersionData.data !== undefined &&
				fetchVersionData.data.length > 0
			) {
				if (
					fetchVersionData.data[0].Company !== null &&
					fetchVersionData.data[0].Company !== undefined &&
					fetchVersionData.data[0].Company.length > 0
				) {
					const { BranchVersion, CenterVersion } = fetchVersionData.data[0].Company[0];

					// Branch
					if (BranchVersion !== versionData?.BranchVersion) {
						dispatch(fetchAllBranchesStart(loginData));
						setIsVersionUpdate(true);
					}

					// Center
					if (CenterVersion !== versionData?.CenterVersion) {
						loginData.branchID = 'All';
						dispatch(fetchAllCenterListStart(loginData));
						setIsVersionUpdate(true);
					}
				}
			}

			dispatch(fetchVersionResponseResetStart());
		}
		if (fetchAllBranchResp) {
			if (fetchAllBranchResp.statusCode === '01') {
				setLocalStore(fetchAllBranchResp?.response, 'Branch').then(() => {
					if (isVersionUpdate) {
						dispatch(addExistVersionDetails(fetchVersionData));
					}
				});
			}
			dispatch(fetchAllBranchesResponseResetStart());
		}

		if (fetchAllCenterResp) {
			if (fetchAllCenterResp.statusCode === '01') {
				setLocalStore(fetchAllCenterResp?.response, 'Center').then(() => {
					if (isVersionUpdate) {
						dispatch(addExistVersionDetails(fetchVersionData));
					}
				});
			}
			dispatch(fetchAllCenterListResponseResetStart());
		}
	}, [
		userDetailsStatus,
		userPasswordUpdateStatus,
		fetchVersionResp,
		fetchAllBranchResp,
		fetchAllCenterResp,
	]);

	const logout = () => {
		deleteLocalStore().then(() => {
			setShowLogoutModal(false);
			dispatch(signInReset());
			navigate(`${demoPages.login.path}`);
		});
	};

	return (
		<>
			<Modal
				isOpen={formik.values.isModalOpen}
				size='lg'
				isCentered
				isStaticBackdrop
				isClose={() => formik.setFieldValue('isModalOpen', !formik.values.isModalOpen)}>
				<ModalHeader>
					<div className='row g-4 mb-3'>
						<div className='col-12'>
							<h3>{formik.values.staticArrTitle}</h3>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className='col-md-12 align-items-center'>
						<div className='row g-4'>
							<div className='col-md-12'>
								<InputGroup>
									<InputGroupText>Current Password</InputGroupText>
									<Input
										placeholder='Current Password'
										id='currentPassword'
										name='currentPassword'
										type='text'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.currentPassword}
										isValid={formik.isValid.currentPassword}
										isTouched={formik.touched.currentPassword}
										invalidFeedback={formik.errors.currentPassword}
										validFeedback='Looks good!'
									/>
								</InputGroup>
							</div>
							<div className='col-md-12'>
								<InputGroup>
									<InputGroupText>New Password</InputGroupText>
									<Input
										placeholder='New Password'
										id='newPassword'
										type='text'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.newPassword}
										isValid={formik.isValid.newPassword}
										isTouched={formik.touched.newPassword}
										invalidFeedback={formik.errors.newPassword}
										validFeedback='Looks good!'
									/>
								</InputGroup>
							</div>
							<div className='col-md-12'>
								<InputGroup>
									<InputGroupText>Confirm Password</InputGroupText>
									<Input
										placeholder='Confirm Password'
										id='confirmPassword'
										type='text'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.confirmPassword}
										isValid={formik.isValid}
										isTouched={formik.touched.confirmPassword}
										invalidFeedback={formik.errors.confirmPassword}
										validFeedback='Looks good!'
									/>
								</InputGroup>
							</div>
						</div>

						<div className='row' style={{ padding: '10px' }}>
							<div className='col-md-12'>
								<p className='text-start fs-6 text-danger'>* Minimum 8 Digit</p>
								<p className='text-start fs-6 text-danger'>
									* Atleast 1 Letter should be in Uppercase Case (A-Z)
								</p>
								<p className='text-start fs-6 text-danger'>
									* Atleast 1 Letter should be in Numbers (0-9)
								</p>
								<p className='text-start fs-6 text-danger'>
									* Atleast 1 special characters (@_^$*#/-%&!+)
								</p>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ justifyContent: 'space-between' }}>
					<Button color='primary' isOutline onClick={formik.handleSubmit}>
						UPDATE
					</Button>
				</ModalFooter>
			</Modal>
			{showLogoutModal ? (
				<Modal
					setIsOpen={() => {}}
					isOpen={showLogoutModal}
					isCentered
					isAnimation
					isStaticBackdrop
					size='lg'>
					<ModalHeader>
						<div className='row g-4 mb-3'>
							<div className='col-12'>
								<h3>Security Check</h3>
							</div>
						</div>
					</ModalHeader>
					<ModalBody>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								height: '45vh',
								flexDirection: 'column',
							}}>
							<Lottie options={defaultOptions} height={200} width={200} />
							<h3 style={{ textAlign: 'center' }}>
								Please check your current Password. Password mismatch is there.
							</h3>
						</div>
						<div style={{ textAlign: 'end' }}>
							<Button
								color='primary'
								isLight
								className='px-5 py-3'
								onClick={() => logout()}>
								OK
							</Button>
						</div>
					</ModalBody>
				</Modal>
			) : null}
		</>
	);
};

export default CommonPasswordUpdate;
