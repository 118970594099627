/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import CloseLoanActionTypes from './closeLoan.types';

/** ***************** GET Close Loan START *********************** */
export const getCloseLoanDetailsStart = (getData) => ({
	type: CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const getCloseLoanDetailsSuccess = (successData) => ({
	type: CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getCloseLoanDetailsFailure = (failureData) => ({
	type: CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const getCloseLoanDetailsResponseResetStart = () => ({
	type: CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_RESPONSE_RESET,
});

export const getCloseLoanDetailsResponseChanged = () => ({
	type: CloseLoanActionTypes.GET_CLOSE_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET Close Loan END *********************** */

/** ***************** UPDATE Close Loan START *********************** */
export const updateCloseLoanDetailsStart = (getData) => ({
	type: CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const updateCloseLoanDetailsSuccess = (successData) => ({
	type: CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const updateCloseLoanDetailsFailure = (failureData) => ({
	type: CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const updateCloseLoanDetailsResponseResetStart = () => ({
	type: CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_RESPONSE_RESET,
});

export const updateCloseLoanDetailsResponseChanged = () => ({
	type: CloseLoanActionTypes.UPDATE_CLOSE_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** UPDATE Close Loan END *********************** */

/** ***************** REVERT Close Loan START *********************** */
export const revertCloseLoanDetailsStart = (getData) => ({
	type: CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_START,
	payload: getData,
});

export const revertCloseLoanDetailsSuccess = (successData) => ({
	type: CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const revertCloseLoanDetailsFailure = (failureData) => ({
	type: CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const revertCloseLoanDetailsResponseResetStart = () => ({
	type: CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_RESPONSE_RESET,
});

export const revertCloseLoanDetailsResponseChanged = () => ({
	type: CloseLoanActionTypes.REVERT_CLOSE_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** REVERT Close Loan END *********************** */
