const LoanActionTypes = {
	ADD_GOLD_LOAN_PROPOSAL_START: 'addGoldLoanProposalStart',
	ADD_GOLD_LOAN_PROPOSAL_SUCCESS: 'addGoldLoanProposalSuccess',
	ADD_GOLD_LOAN_PROPOSAL_FAILURE: 'addGoldLoanProposalFailure',
	ADD_GOLD_LOAN_PROPOSAL_RESPONSE_RESET: 'addGoldLoanProposalResponseReset',
	ADD_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED: 'addGoldLoanProposalResponseChanged',

	UPDATE_LOAN_PROPOSAL_START: 'updateLoanProposalStart',
	UPDATE_LOAN_PROPOSAL_SUCCESS: 'updateLoanProposalSuccess',
	UPDATE_LOAN_PROPOSAL_FAILURE: 'updateLoanProposalFailure',
	UPDATE_LOAN_PROPOSAL_RESPONSE_RESET: 'updateLoanProposalResponseReset',
	UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'updateLoanProposalResponseChanged',

	DELETE_LOAN_PROPOSAL_START: 'deleteLoanProposalStart',
	DELETE_LOAN_PROPOSAL_SUCCESS: 'deleteLoanProposalSuccess',
	DELETE_LOAN_PROPOSAL_FAILURE: 'deleteLoanProposalFailure',
	DELETE_LOAN_PROPOSAL_RESPONSE_RESET: 'deleteLoanProposalResponseReset',
	DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'deleteLoanProposalResponseChanged',

	FETCH_GOLD_LOAN_PROPOSAL_START: 'fetchGoldLoanProposalStart',
	FETCH_GOLD_LOAN_PROPOSAL_SUCCESS: 'fetchGoldLoanProposalSuccess',
	FETCH_GOLD_LOAN_PROPOSAL_FAILURE: 'fetchGoldLoanProposalFailure',
	FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_RESET: 'fetchGoldLoanProposalResponseReset',
	FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED: 'fetchGoldLoanProposalResponseChanged',

	GET_GOLD_LOAN_PROPOSAL_START: 'getGoldLoanProposalStart',
	GET_GOLD_LOAN_PROPOSAL_SUCCESS: 'getGoldLoanProposalSuccess',
	GET_GOLD_LOAN_PROPOSAL_FAILURE: 'getGoldLoanProposalFailure',
	GET_GOLD_LOAN_PROPOSAL_RESPONSE_RESET: 'getGoldLoanProposalResponseReset',
	GET_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED: 'getGoldLoanProposalResponseChanged',

	FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_START: 'fetchGoldLoanProposalGroupOfficerStart',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_SUCCESS: 'fetchGoldLoanProposalGroupOfficerSuccess',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_FAILURE: 'fetchGoldLoanProposalGroupOfficerFailure',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_RESET:
		'fetchGoldLoanProposalGroupOfficerResponseReset',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_CHANGED:
		'fetchGoldLoanProposalGroupOfficerResponseChanged',

	APPROVE_LOAN_PROPOSAL_START: 'approveLoanProposalStart',
	APPROVE_LOAN_PROPOSAL_SUCCESS: 'approveLoanProposalSuccess',
	APPROVE_LOAN_PROPOSAL_FAILURE: 'approveLoanProposalFailure',
	APPROVE_LOAN_PROPOSAL_RESPONSE_RESET: 'approveLoanProposalResponseReset',
	APPROVE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'approveLoanProposalResponseChanged',

	REVERT_LOAN_PROPOSAL_START: 'revertLoanProposalStart',
	REVERT_LOAN_PROPOSAL_SUCCESS: 'revertLoanProposalSuccess',
	REVERT_LOAN_PROPOSAL_FAILURE: 'revertLoanProposalFailure',
	REVERT_LOAN_PROPOSAL_RESPONSE_RESET: 'revertLoanProposalResponseReset',
	REVERT_LOAN_PROPOSAL_RESPONSE_CHANGED: 'revertLoanProposalResponseChanged',

	FETCH_GOLD_LOAN_PROPOSAL_GROUP_START: 'fetchGoldLoanProposalGroupStart',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_SUCCESS: 'fetchGoldLoanProposalGroupSuccess',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_FAILURE: 'fetchGoldLoanProposalGroupFailure',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_RESET: 'fetchGoldLoanProposalGroupResponseReset',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_CHANGED: 'fetchGoldLoanProposalGroupResponseChanged',

	FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_START: 'fetchGoldLoanProposalGroupMemberStart',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_SUCCESS: 'fetchGoldLoanProposalGroupMemberSuccess',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_FAILURE: 'fetchGoldLoanProposalGroupMemberFailure',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_RESET:
		'fetchGoldLoanProposalGroupMemberResponseReset',
	FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_CHANGED:
		'fetchGoldLoanProposalGroupMemberResponseChanged',
};
export default LoanActionTypes;
