const CreditCheckActionType = {
	CREDIT_CHECK_MEMBER_ADD_START: 'credit_check_member_add_start',
	CREDIT_CHECK_MEMBER_ADD_SUCCESS: 'credit_check_member_add_success',
	CREDIT_CHECK_MEMBER_ADD_FAILURE: 'credit_check_member_add_failure',
	CREDIT_CHECK_MEMBER_ADD_RESPONSE_REST_START: 'credit_check_member_add_response_reset_start',
	CREDIT_CHECK_MEMBER_ADD_RESPONSE_CHANGED: 'credit_check_member_add_response_changed',

	CREDIT_CHECK_CALL_START: 'credit_check_call_start',
	CREDIT_CHECK_CALL_SUCCESS: 'credit_check_call_success',
	CREDIT_CHECK_CALL_FAILURE: 'credit_check_call_failure',
	CREDIT_CHECK_CALL_RESPONSE_REST_START: 'credit_check_call_response_reset_start',
	CREDIT_CHECK_CALL_RESPONSE_CHANGED: 'credit_check_call_response_changed',

	VERIFY_MEMBER_START: 'verify_member_start',
	VERIFY_MEMBER_SUCCESS: 'verify_member_success',
	VERIFY_MEMBER_FAILURE: 'verify_member_failure',
	VERIFY_MEMBER_RESPONSE_RESET_START: 'verify_member_response_reset_start',
	VERIFY_MEMBER_RESPONSE_CHANGED: 'verify_member_response_changed',

	SEND_VERIFICATION_OTP_START: 'send_verification_otp_start',
	SEND_VERIFICATION_OTP_SUCCESS: 'send_verification_otp_success',
	SEND_VERIFICATION_OTP_FAILURE: 'send_verification_otp_failure',
	SEND_VERIFICATION_OTP_RESPONSE_RESET_START: 'send_verification_otp_response_reset_start',
	SEND_VERIFICATION_OTP_RESPONSE_CHANGED: 'send_verification_otp_response_changed',

	VERIFY_CREDIT_CHECK_AADHAR_OTP_START: 'verify_credit_check_aadhar_otp_start',
	VERIFY_CREDIT_CHECK_AADHAR_OTP_SUCCESS: 'verify_credit_check_aadhar_otp_success',
	VERIFY_CREDIT_CHECK_AADHAR_OTP_FAILURE: 'verify_credit_check_aadhar_otp_failure',
	VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_credit_check_aadhar_otp_response_reset_start',
	VERIFY_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_credit_check_aadhar_otp_response_changed',

	VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_START: 'verify_validate_credit_check_aadhar_otp_start',
	VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_SUCCESS:
		'verify_validate_credit_check_aadhar_otp_success',
	VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_FAILURE:
		'verify_validate_credit_check_aadhar_otp_failure',
	VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_RESET_START:
		'verify_validate_credit_check_aadhar_otp_response_reset_start',
	VERIFY_VALIDATE_CREDIT_CHECK_AADHAR_OTP_RESPONSE_CHANGED:
		'verify_validate_credit_check_aadhar_otp_response_changed',

	RECHECK_MEMBER_DETAILS_FOR_CRIF_START: 'recheck_member_details_for_crif_start',
	RECHECK_MEMBER_DETAILS_FOR_CRIF_SUCCESS: 'recheck_member_details_for_crif_success',
	RECHECK_MEMBER_DETAILS_FOR_CRIF_FAILURE: 'recheck_member_details_for_crif_failure',
	RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_RESET_START:
		'recheck_member_details_for_crif_response_reset_start',
	RECHECK_MEMBER_DETAILS_FOR_CRIF_RESPONSE_CHANGED:
		'recheck_member_details_for_crif_response_changed',

	EQUIFAX_CHECK_CALL_START: 'equifax_check_call_start',
	EQUIFAX_CHECK_CALL_SUCCESS: 'equifax_check_call_success',
	EQUIFAX_CHECK_CALL_FAILURE: 'equifax_check_call_failure',
	EQUIFAX_CHECK_CALL_RESPONSE_REST_START: 'equifax_check_call_response_reset_start',
	EQUIFAX_CHECK_CALL_RESPONSE_CHANGED: 'equifax_check_call_response_changed',
};

export default CreditCheckActionType;
