/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import GeneralActionTypes from './general.types';
import {
	getAllCountriesList,
	getAllStateList,
	getAllCityList,
	getAllPincodeList,
	getDistrictList,
	getAllIndustryList,
	getSubIndustryList,
	allBranchesFetch,
	allPurposeOfLoanListFetch,
	allPurposeOfLoanTypeFetch,
	allBusinessDetailsFetch,
	allProductTypeFetch,
	allProductsFetch,
	allBranchOfficerFetch,
	allCenterFetch,
	allInvestorFetch,
	fetchallGroup,
	fetchGroupCenterFetch,
	fetchGroupMembersFetch,
	BranchAccountListFetch,
	fetchGroupLoanIndexListAPI,
	fetchSearchLoanIndexListAPI,
	fetchParticularLoanIndexListAPI,
	VersionDetailsFetch,
	VersionDetailsUpdate,
	PincodeDetailsFetch,
	getAllLocationListBasedLevel,
	checkAuthenticationKeyAPI,
} from './general.service';
import {
	getCountryListSuccess,
	getCountryListFailure,
	getCountryListResponseChanged,
	getStateListSuccess,
	getStateListFailure,
	getStateListResponseChanged,
	getCityListSuccess,
	getCityListFailure,
	getCityListResponseChanged,
	getDistrictListSuccess,
	getDistrictListFailure,
	getDistrictListResponseChanged,
	getAllIndustryListSuccess,
	getAllIndustryListFailure,
	getAllIndustryListResponseChanged,
	getSubIndustryListSuccess,
	getSubIndustryListFailure,
	getSubIndustryListResponseChanged,
	fetchAllBranchesSuccess,
	fetchAllBranchesFailure,
	fetchAllBranchesResponseChanged,
	fetchAllPurposeOfLoanListSuccess,
	fetchAllPurposeOfLoanListFailure,
	fetchAllPurposeOfLoanListResponseChanged,
	fetchAllPurposeOfLoanTypeSuccess,
	fetchAllPurposeOfLoanTypeFailure,
	fetchAllPurposeOfLoanTypeResponseChanged,
	fetchAllBusinessListSuccess,
	fetchAllBusinessListFailure,
	fetchAllBusinessListResponseChanged,
	fetchAllProductTypeSuccess,
	fetchAllProductTypeFailure,
	fetchAllProductTypeResponseChanged,
	fetchAllProductListSuccess,
	fetchAllProductListFailure,
	fetchAllProductListResponseChanged,
	fetchAllBranchOfficerSuccess,
	fetchAllBranchOfficerFailure,
	fetchAllBranchOfficerResponseChanged,
	fetchAllCenterListSuccess,
	fetchAllCenterListFailure,
	fetchAllCenterListResponseChanged,
	fetchCenterGroupListSuccess,
	fetchCenterGroupListFailure,
	fetchCenterGroupListResponseChanged,
	fetchGroupMemberListSuccess,
	fetchGroupMemberListFailure,
	fetchGroupMemberListResponseChanged,
	fetchAllInverstorListSuccess,
	fetchAllInverstorListFailure,
	fetchAllInverstorListResponseChanged,
	fetchAllGroupFailure,
	fetchAllGroupSuccess,
	fetchAllGroupResponseChanged,
	fetchAllGroupResponseReset,
	getPinCodeListFailure,
	getPinCodeListSuccess,
	getPinCodeListResponseChanged,
	fetchBranchAccountListSuccess,
	fetchBranchAccountListFailure,
	fetchBranchAccountListResponseChanged,
	fetchGroupLoanIndexSuccess,
	fetchGroupLoanIndexFailure,
	fetchGroupLoanIndexResponseChanged,
	fetchSearchLoanIndexSuccess,
	fetchSearchLoanIndexFailure,
	fetchSearchLoanIndexResponseChanged,
	fetchParticularLoanIndexSuccess,
	fetchParticularLoanIndexFailure,
	fetchParticularLoanIndexResponseChanged,
	fetchVersionSuccess,
	fetchVersionFailure,
	fetchVersionResponseChanged,
	updateVersionSuccess,
	updateVersionFailure,
	updateVersionResponseChanged,
	fetchPincodeDetailsSuccess,
	fetchPincodeDetailsFailure,
	fetchPincodeDetailsResponseChanged,
	getLocationListBasedLevelSuccess,
	getLocationListBasedLevelFailure,
	getLocationListBasedLevelResponseChanged,
	checkGoogleAuthenticationSuccess,
	checkGoogleAuthenticationFailure,
	checkGoogleAuthenticationResponseChanged,
} from './general.action';
import { encodeJson } from '../enode-decode';

/** *********************  START COUNTRY LIST  ******************** */
export function* getCountryList() {
	try {
		const jsonData = {
			CountryID: '',
		};
		const countryData = encodeJson(jsonData, 'GetCountryListKey');
		const responseData = yield call(getAllCountriesList, countryData);
		if (responseData.data.statusCode === '500') {
			yield put(getCountryListFailure(responseData.data));
		} else {
			yield put(getCountryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCountryListFailure(error));
	}
}

export function* resetCountryList() {
	yield put(getCountryListResponseChanged());
}

export function* onGetCountryList() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_START, getCountryList);
}

export function* onResetCountryResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_RESET, resetCountryList);
}
/** *********************  END COUNTRY LIST  ******************** */

/** *********************  START STATE LIST  ******************** */
export function* getStateList({ payload }) {
	try {
		const jsonData = {
			CountryID: payload.countryID,
			isDoubleEncode: 1,
		};
		const stateData = encodeJson(jsonData, 'CountryStateListGetKey');
		const responseData = yield call(getAllStateList, stateData);
		if (responseData.data.statusCode === '500') {
			yield put(getStateListFailure(responseData.data));
		} else {
			yield put(getStateListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStateListFailure(error));
	}
}

export function* resetStateList() {
	yield put(getStateListResponseChanged());
}

export function* onGetStateList() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_START, getStateList);
}

export function* onResetStateResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_RESPONSE_RESET, resetStateList);
}
/** *********************  END STATE LIST  ******************** */

/** *********************  START CITY LIST  ******************** */
export function* getCityList({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
			isDoubleEncode: 1,
		};
		const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		const responseData = yield call(getAllCityList, cityData);
		if (responseData.data.statusCode === '500') {
			yield put(getCityListFailure(responseData.data));
		} else {
			yield put(getCityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCityListFailure(error));
	}
}

export function* resetCityList() {
	yield put(getCityListResponseChanged());
}

export function* onGetCityList() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_START, getCityList);
}

export function* onResetCityResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_RESPONSE_RESET, resetCityList);
}
/** *********************  END CITY LIST  ******************** */

/** *********************  START PIN LIST  ******************** */
export function* getPincodeList({ payload }) {
	try {
		// const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		const responseData = yield call(getAllPincodeList, payload);
		if (responseData.data.statusCode === '500') {
			yield put(getPinCodeListFailure(responseData.data[0]));
		} else {
			yield put(getPinCodeListSuccess(responseData.data[0]));
		}
	} catch (error) {
		yield put(getCityListFailure(error));
	}
}

export function* resetPincodeList() {
	yield put(getPinCodeListResponseChanged());
}

export function* onGetPincodeList() {
	yield takeLatest(GeneralActionTypes.GET_PINCODE_LIST_START, getPincodeList);
}

export function* onResetPincodeResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_RESET, resetPincodeList);
}
/** *********************  END CITY LIST  ******************** */

/** *********************  START DISTRICT LIST  ******************** */
export function* districtListGet({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const districtData = encodeJson(jsonData, 'DistrictCityListGetKey');
		const responseData = yield call(getDistrictList, districtData);
		if (responseData.data.statusCode === '500') {
			yield put(getDistrictListFailure(responseData.data));
		} else {
			yield put(getDistrictListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDistrictListFailure(error));
	}
}

export function* resetDistrictList() {
	yield put(getDistrictListResponseChanged());
}

export function* onGetDistrictList() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_START, districtListGet);
}

export function* onResetDistrictResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_RESET, resetDistrictList);
}
/** *********************  END DISTRICT LIST  ******************** */

/** *********************  START GET ALL INDUSTRY   ******************** */
export function* allIndustryListGet({ payload }) {
	try {
		const key = 'GetAllIndustryListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAllIndustryList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllIndustryListFailure(responseData.data));
		} else {
			yield put(getAllIndustryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllIndustryListFailure(error));
	}
}

export function* resetAllIndustryListReset() {
	yield put(getAllIndustryListResponseChanged());
}

export function* onGetIndustryListDetails() {
	yield takeLatest(GeneralActionTypes.GET_INDUSTRY_LIST_START, allIndustryListGet);
}

export function* onGetIndustryListResponseReset() {
	yield takeLatest(
		GeneralActionTypes.GET_INDUSTRY_LIST_RESPONSE_RESET_START,
		resetAllIndustryListReset,
	);
}
/** *********************  END GET ALL INDUSTRY ******************** */

/** *********************  START GET SUB INDUSTRY   ******************** */
export function* allSubIndustryListGet({ payload }) {
	try {
		const key = 'GetSubIndustryListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			IndustryID: payload.industryID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getSubIndustryList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSubIndustryListFailure(responseData.data));
		} else {
			yield put(getSubIndustryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSubIndustryListFailure(error));
	}
}

export function* resetSubIndustryListReset() {
	yield put(getSubIndustryListResponseChanged());
}

export function* onGetSubIndustryListDetails() {
	yield takeLatest(GeneralActionTypes.GET_SUB_INDUSTRY_LIST_START, allSubIndustryListGet);
}

export function* onGetSubIndustryListResponseReset() {
	yield takeLatest(
		GeneralActionTypes.GET_SUB_INDUSTRY_LIST_RESPONSE_RESET_START,
		resetSubIndustryListReset,
	);
}
/** *********************  END GET SUB INDUSTRY ******************** */

/** *********************  START FETCH ALL BRANCHES LIST  ******************** */
export function* fetchAllBranchesList({ payload }) {
	try {
		const key = 'FetchAllBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allBranchesFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchesFailure(responseData.data));
		} else {
			yield put(fetchAllBranchesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchesFailure(error));
	}
}

export function* resetFetchAllBranchesListResponse() {
	yield put(fetchAllBranchesResponseChanged());
}

export function* onFetchAllBranchesListDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCHES_START, fetchAllBranchesList);
}

export function* onFetchAllBranchesResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_RESET_START,
		resetFetchAllBranchesListResponse,
	);
}
/** *********************  END FETCH ALL BRANCHES LIST ******************** */

/** *********************  START FETCH ALL PURPOSE OF LOAN TYPE LIST  ******************** */
export function* fetchAllPurposeofLoanList({ payload }) {
	try {
		const key = 'FetchAllPurposeOfLoansKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allPurposeOfLoanListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllPurposeOfLoanListFailure(responseData.data));
		} else {
			yield put(fetchAllPurposeOfLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllPurposeOfLoanListFailure(error));
	}
}

export function* resetFetchAllPurposeofLoanListResponse() {
	yield put(fetchAllPurposeOfLoanListResponseChanged());
}

export function* onFetchAllPurposeofLoanListDetails() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_START,
		fetchAllPurposeofLoanList,
	);
}

export function* onFetchAllPurposeofLoanResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET_START,
		resetFetchAllPurposeofLoanListResponse,
	);
}
/** *********************  END FETCH ALL PURPOSE OF LOAN TYPE LIST ******************** */

/** *********************  START FETCH ALL PURPOSE OF LOAN TYPE LIST  ******************** */
export function* fetchAllPurposeofLoanTypeList({ payload }) {
	try {
		const key = 'FetchAllPurposeOfLoanTypesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allPurposeOfLoanTypeFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllPurposeOfLoanTypeFailure(responseData.data));
		} else {
			yield put(fetchAllPurposeOfLoanTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllPurposeOfLoanTypeFailure(error));
	}
}

export function* resetFetchAllPurposeofLoanTypeListResponse() {
	yield put(fetchAllPurposeOfLoanTypeResponseChanged());
}

export function* onFetchAllPurposeofLoanTypeListDetails() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START,
		fetchAllPurposeofLoanTypeList,
	);
}

export function* onFetchAllPurposeofLoanTypeResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_RESET_START,
		resetFetchAllPurposeofLoanTypeListResponse,
	);
}
/** *********************  END FETCH ALL PURPOSE OF LOAN TYPE LIST ******************** */

/** *********************  START FETCH ALL BUSINESS LIST  ******************** */
export function* fetchAllBusinessList({ payload }) {
	try {
		const key = 'GetAllBusinessListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allBusinessDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBusinessListFailure(responseData.data));
		} else {
			yield put(fetchAllBusinessListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBusinessListFailure(error));
	}
}

export function* resetFetchAllBusinessListResponse() {
	yield put(fetchAllBusinessListResponseChanged());
}

export function* onFetchAllBusinessListDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_START, fetchAllBusinessList);
}

export function* onFetchAllBusinessResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_RESPONSE_RESET_START,
		resetFetchAllBusinessListResponse,
	);
}
/** *********************  END FETCH ALL BUSINESS LIST ******************** */

/** *********************  START FETCH ALL PRODUCT TYPE LIST  ******************** */
export function* fetchAllProductTypesList({ payload }) {
	try {
		const key = 'FetchAllProductTypesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allProductTypeFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductTypeFailure(responseData.data));
		} else {
			yield put(fetchAllProductTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductTypeFailure(error));
	}
}

export function* resetFetchAllProductTypesResponse() {
	yield put(fetchAllProductTypeResponseChanged());
}

export function* onFetchAllProductTypesDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_START, fetchAllProductTypesList);
}

export function* onFetchAllProductTypesResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_RESET,
		resetFetchAllProductTypesResponse,
	);
}
/** *********************  END FETCH ALL PRODUCT TYPE LIST ******************** */

/** *********************  START FETCH ALL PRODUCTS LIST  ******************** */
export function* fetchAllProductsList({ payload }) {
	try {
		const key = 'FetchAllProductList';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductType: payload.productTypeID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allProductsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductListFailure(responseData.data));
		} else {
			yield put(fetchAllProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductListFailure(error));
	}
}

export function* resetFetchAllProductsResponse() {
	yield put(fetchAllProductListResponseChanged());
}

export function* onFetchAllProductsDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_PRODUCT_START, fetchAllProductsList);
}

export function* onFetchAllProductsResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET,
		resetFetchAllProductsResponse,
	);
}
/** *********************  END FETCH ALL PRODUCTS LIST ******************** */

/** *********************  START FETCH STAFF BASED ON BRANCH LIST  ******************** */
export function* fetchAllBranchOfficerList({ payload }) {
	try {
		const key = 'GetAllBranchOfficerList';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allBranchOfficerFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchOfficerFailure(responseData.data));
		} else {
			yield put(fetchAllBranchOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchOfficerFailure(error));
	}
}

export function* resetFetchAllBranchOfficerResponse() {
	yield put(fetchAllBranchOfficerResponseChanged());
}

export function* onFetchAllBranchOfficerDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_START, fetchAllBranchOfficerList);
}

export function* onFetchAllBranchOfficerResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_RESET_START,
		resetFetchAllBranchOfficerResponse,
	);
}
/** *********************  END STAFF BASED ON BRANCH LIST ******************** */

/** *********************  CENTER FETCH BASED ON BRANCH LIST  ******************** */

export function* fetchAllCenterList({ payload }) {
	try {
		const key = 'GetAllCentersList';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allCenterFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllCenterListFailure(responseData.data));
		} else {
			yield put(fetchAllCenterListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllCenterListFailure(error));
	}
}

export function* resetFetchAllCenterResponse() {
	yield put(fetchAllCenterListResponseChanged());
}

export function* onFetchAllCenterDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_CENTERS_START, fetchAllCenterList);
}

export function* onFetchAllCenterResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_RESET_START,
		resetFetchAllCenterResponse,
	);
}
/** *********************  END CENTER FETCH BASED ON BRANCH LIST ******************** */

/** *********************  GROUP FETCH BASED ON CENTER LIST  ******************** */
export function* fetchCenterGroupList({ payload }) {
	try {
		const key = 'FetchAllGroupListKey';
		const jsonData = {
			LocationID: payload.centerID !== undefined ? payload.centerID : payload.locationID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupCenterFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterGroupListFailure(responseData.data));
		} else {
			yield put(fetchCenterGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterGroupListFailure(error));
	}
}

export function* resetFetchCenterGroupResponse() {
	yield put(fetchCenterGroupListResponseChanged());
}

export function* onFetchCenterGroupDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_CENTER_GROUPS_START, fetchCenterGroupList);
}

export function* onFetchCenterGroupResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_RESET_START,
		resetFetchCenterGroupResponse,
	);
}
/** *********************  END GROUP FETCH BASED ON CENTER LIST ******************** */

/** *********************  MEMBER FETCH BASED ON GROUP LIST  ******************** */
export function* fetchGroupMemberList({ payload }) {
	try {
		const key = 'FetchGroupMembersListKey';
		const jsonData = {
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupMembersFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupMemberListFailure(responseData.data));
		} else {
			yield put(fetchGroupMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupMemberListFailure(error));
	}
}

export function* resetFetchGroupMemberResponse() {
	yield put(fetchGroupMemberListResponseChanged());
}

export function* onFetchGroupMemberDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_GROUP_MEMBERS_START, fetchGroupMemberList);
}

export function* onFetchGroupMemberResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_GROUP_MEMBERS_RESPONSE_RESET_START,
		resetFetchGroupMemberResponse,
	);
}
/** *********************  END GROUP FETCH BASED ON CENTER LIST ******************** */

/** *********************  START FETCH STAFF BASED ON BRANCH LIST  ******************** */

export function* fetchAllInvestorList({ payload }) {
	try {
		const key = 'GetAllInvestorListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allInvestorFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllInverstorListFailure(responseData.data));
		} else {
			yield put(fetchAllInverstorListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllInverstorListFailure(error));
	}
}

export function* resetFetchAllInvestorResponse() {
	yield put(fetchAllInverstorListResponseChanged());
}

export function* onFetchAllInvestorDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_START, fetchAllInvestorList);
}

export function* onFetchAllInvestorResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_RESPONSE_RESET_START,
		resetFetchAllInvestorResponse,
	);
}
/** *********************  END STAFF BASED ON BRANCH LIST ******************** */

/** *********************  START FETCH All GROUP  ******************** */
export function* fetchAllGroupData({ payload }) {
	try {
		const key = 'GetAllGroupsListkey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			LocationID: payload.centerID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchallGroup, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllGroupFailure(responseData.data));
		} else {
			yield put(fetchAllGroupSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllGroupFailure(error));
	}
}

export function* resetFetchGoldLoanLoanProposalGroupResponse() {
	yield put(fetchAllGroupResponseChanged());
}

export function* onFetchAllGroupDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_GROUP_START, fetchAllGroupData);
}

export function* onFetchAllGroupResponseReset() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET, fetchAllGroupResponseReset);
}

/** *********************  END FETCH All GROUP  ******************** */

/** *********************  START FETCH ALL BRANCHES LIST  ******************** */
export function* fetchBranchAccountList({ payload }) {
	try {
		const key = 'FetchBranchAccountDetailsKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			ViewUser: payload.ViewUser,
			DatabaseName: payload.DatabaseName,
			UserAccessBranchID: payload.UserAccessBranchID,
			InfogID: payload.InfogID,
			Type: payload.Type,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(BranchAccountListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchAccountListFailure(responseData.data));
		} else {
			yield put(fetchBranchAccountListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchAccountListFailure(error));
	}
}

export function* resetFetchBranchAccountListResponse() {
	yield put(fetchBranchAccountListResponseChanged());
}

export function* onFetchBranchAccountListDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_START, fetchBranchAccountList);
}

export function* onFetchBranchAccountListResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_RESET_START,
		resetFetchBranchAccountListResponse,
	);
}
/** *********************  END FETCH ALL BRANCHES LIST ******************** */

/** *********************  LOAN INDEX FETCH BASED ON GROUP LIST  ******************** */
export function* fetchGroupLoanIndexList({ payload }) {
	try {
		const key = 'FetchAllLoanIndexListKey';
		const jsonData = {
			BranchID: payload.branchID,
			LocationID: payload.centerID,
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Type: payload.type,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupLoanIndexListAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupLoanIndexFailure(responseData.data));
		} else {
			yield put(fetchGroupLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupLoanIndexFailure(error));
	}
}

export function* resetFetchGroupLoanIndexResponse() {
	yield put(fetchGroupLoanIndexResponseChanged());
}

export function* onFetchGroupLoanIndexDetails() {
	yield takeLatest(
		GeneralActionTypes.FETCH_LOAN_INDEX_BASED_GROUP_START,
		fetchGroupLoanIndexList,
	);
}

export function* onFetchGroupLoanIndexResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_LOAN_INDEX_BASED_GROUP_RESPONSE_RESET_START,
		resetFetchGroupLoanIndexResponse,
	);
}
/** *********************  END LOAN INDEX FETCH BASED ON GROUP LIST ******************** */

/** *********************  LOAN INDEX FETCH BY SEARCH LIST  ******************** */
export function* fetchSearchLoanIndexList({ payload }) {
	try {
		const key = 'SearchLoanIndexDetailsTokenKey';
		const jsonData = {
			BranchID: payload.branchID || 0,
			CenterID: payload.centerID || 0,
			GroupID: payload.groupID || 0,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			LoanIndexType: payload.loanIndexType,
			Limit: payload.limit || 0,
			SearchData: payload.searchInput || '',
			LoginUserRoleID: payload.userRoleID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchLoanIndexListAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSearchLoanIndexFailure(responseData.data));
		} else {
			yield put(fetchSearchLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSearchLoanIndexFailure(error));
	}
}

export function* resetFetchSearchLoanIndexResponse() {
	yield put(fetchSearchLoanIndexResponseChanged());
}

export function* onFetchSearchLoanIndexDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_SEARCH_LOAN_INDEX_START, fetchSearchLoanIndexList);
}

export function* onFetchSearchLoanIndexResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_SEARCH_LOAN_INDEX_RESPONSE_RESET_START,
		resetFetchSearchLoanIndexResponse,
	);
}
/** *********************  END LOAN INDEX FETCH BY SEARCH LIST ******************** */

/** *********************  LOAN INDEX FETCH BY SEARCH LIST  ******************** */
export function* fetchParticularLoanIndexList({ payload }) {
	try {
		const key = 'FetchParticularLoanIndexKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			loanIndexID: payload.loanIndexID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchParticularLoanIndexListAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchParticularLoanIndexFailure(responseData.data));
		} else {
			yield put(fetchParticularLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchParticularLoanIndexFailure(error));
	}
}

export function* resetFetchParticularLoanIndexResponse() {
	yield put(fetchParticularLoanIndexResponseChanged());
}

export function* onFetchParticularLoanIndexDetails() {
	yield takeLatest(
		GeneralActionTypes.FETCH_PARTICULAR_LOAN_INDEX_START,
		fetchParticularLoanIndexList,
	);
}

export function* onFetchParticularLoanIndexResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_PARTICULAR_LOAN_INDEX_RESPONSE_RESET_START,
		resetFetchParticularLoanIndexResponse,
	);
}
/** *********************  END LOAN INDEX FETCH BY SEARCH LIST ******************** */

/** *********************  START FETCH VERSION LIST  ******************** */
export function* fetchVersionList({ payload }) {
	try {
		const key = 'GetVersionDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(VersionDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchVersionFailure(responseData.data));
		} else {
			yield put(fetchVersionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchVersionFailure(error));
	}
}

export function* resetFetchVersionResponse() {
	yield put(fetchVersionResponseChanged());
}

export function* onFetchVersionDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_VERSION_START, fetchVersionList);
}

export function* onFetchVersionResponseReset() {
	yield takeLatest(GeneralActionTypes.FETCH_VERSION_RESPONSE_RESET, resetFetchVersionResponse);
}
/** *********************  END FETCH VERSION LIST ******************** */

/** *********************  START UPDATE VERSION LIST  ******************** */
export function* updateVersionList({ payload }) {
	try {
		const key = 'UpdateVersionDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			IsBranch: payload.isBranch,
			IsCenter: payload.isCenter,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(VersionDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateVersionFailure(responseData.data));
		} else {
			yield put(updateVersionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateVersionFailure(error));
	}
}

export function* resetUpdateVersionResponse() {
	yield put(updateVersionResponseChanged());
}

export function* onUpdateVersionDetails() {
	yield takeLatest(GeneralActionTypes.UPDATE_VERSION_START, updateVersionList);
}

export function* onUpdateVersionResponseReset() {
	yield takeLatest(GeneralActionTypes.UPDATE_VERSION_RESPONSE_RESET, resetUpdateVersionResponse);
}
/** *********************  END UPDATE VERSION LIST ******************** */

/** *********************  START FETCH VERSION LIST  ******************** */
export function* fetchPincodeDetailsList({ payload }) {
	try {
		const key = 'FetchPincodeListKey';
		const jsonData = {
			Pincode: payload.pincode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PincodeDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPincodeDetailsFailure(responseData.data));
		} else {
			yield put(fetchPincodeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPincodeDetailsFailure(error));
	}
}

export function* resetFetchPincodeDetailsResponse() {
	yield put(fetchPincodeDetailsResponseChanged());
}

export function* onFetchPincodeDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_PINCODE_DETAILS_START, fetchPincodeDetailsList);
}

export function* onFetchPincodeDetailsResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_PINCODE_DETAILS_RESPONSE_RESET,
		resetFetchPincodeDetailsResponse,
	);
}
/** *********************  END FETCH VERSION LIST ******************** */

/** *********************  Get Location List based on Level START  ******************** */
export function* getLocationBasedLevelList({ payload }) {
	try {
		const key = 'AllLocationBasedLevelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.level,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAllLocationListBasedLevel, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLocationListBasedLevelFailure(responseData.data));
		} else {
			yield put(getLocationListBasedLevelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLocationListBasedLevelFailure(error));
	}
}

export function* resetGetLocationBasedLevelResponse() {
	yield put(getLocationListBasedLevelResponseChanged());
}

export function* onGetLocationListBasedLevelDetails() {
	yield takeLatest(
		GeneralActionTypes.GET_LOCATION_LIST_BASED_LEVEL_START,
		getLocationBasedLevelList,
	);
}

export function* onGetLocationListBasedLevelSuccessReset() {
	yield takeLatest(
		GeneralActionTypes.GET_LOCATION_LIST_BASED_LEVEL_RESPONSE_RESET,
		resetGetLocationBasedLevelResponse,
	);
}
/** *********************  Get Location List based on Level END ******************** */

/** *********************  CHECK GOOGLE AUTHENTICATION START  ******************** */
export function* checkAuthenticationKey({ payload }) {
	try {
		const key = 'CheckUserAuthKey';
		const jsonData = {
			Secrate: payload.SecrateAuth,
			AuthCode: payload.AuthenticationCode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(checkAuthenticationKeyAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(checkGoogleAuthenticationFailure(responseData.data));
		} else {
			yield put(checkGoogleAuthenticationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkGoogleAuthenticationFailure(error));
	}
}

export function* resetAuthenticationResponse() {
	yield put(checkGoogleAuthenticationResponseChanged());
}

export function* onCheckAuthenticationKeyStart() {
	yield takeLatest(GeneralActionTypes.CHECK_GOOGLE_AUTHENTICATION_START, checkAuthenticationKey);
}

export function* onCheckAuthenticationKeyResponseReset() {
	yield takeLatest(
		GeneralActionTypes.CHECK_GOOGLE_AUTHENTICATION_RESPONSE_RESET,
		resetAuthenticationResponse,
	);
}
/** *********************  CHECK GOOGLE AUTHENTICATION END ******************** */

export function* generalSaga() {
	yield all([
		call(onGetCountryList),
		call(onResetCountryResponseStart),
		call(onGetStateList),
		call(onResetStateResponseStart),
		call(onGetCityList),
		call(onResetCityResponseStart),
		call(onGetPincodeList),
		call(onResetPincodeResponseStart),
		call(onGetDistrictList),
		call(onResetDistrictResponseStart),
		call(onGetIndustryListDetails),
		call(onGetIndustryListResponseReset),
		call(onGetSubIndustryListDetails),
		call(onGetSubIndustryListResponseReset),
		call(onFetchAllBranchesListDetails),
		call(onFetchAllBranchesResponseReset),
		call(onFetchAllPurposeofLoanListDetails),
		call(onFetchAllPurposeofLoanResponseReset),
		call(onFetchAllPurposeofLoanTypeListDetails),
		call(onFetchAllPurposeofLoanTypeResponseReset),
		call(onFetchAllBusinessListDetails),
		call(onFetchAllBusinessResponseReset),
		call(onFetchAllProductTypesDetails),
		call(onFetchAllProductTypesResponseReset),
		call(onFetchAllProductsDetails),
		call(onFetchAllProductsResponseReset),
		call(onFetchAllBranchOfficerDetails),
		call(onFetchAllBranchOfficerResponseReset),
		call(onFetchAllCenterDetails),
		call(onFetchAllCenterResponseReset),
		call(onFetchAllInvestorDetails),
		call(onFetchAllInvestorResponseReset),
		call(onFetchAllGroupResponseReset),
		call(onFetchAllGroupDetails),
		call(onFetchCenterGroupDetails),
		call(onFetchCenterGroupResponseReset),
		call(onFetchGroupMemberDetails),
		call(onFetchGroupMemberResponseReset),
		call(onFetchBranchAccountListDetails),
		call(onFetchBranchAccountListResponseReset),
		call(onFetchGroupLoanIndexDetails),
		call(onFetchGroupLoanIndexResponseReset),
		call(onFetchSearchLoanIndexDetails),
		call(onFetchSearchLoanIndexResponseReset),
		call(onFetchParticularLoanIndexDetails),
		call(onFetchParticularLoanIndexResponseReset),
		call(onFetchVersionDetails),
		call(onFetchVersionResponseReset),
		call(onUpdateVersionDetails),
		call(onUpdateVersionResponseReset),
		call(onFetchPincodeDetails),
		call(onFetchPincodeDetailsResponseReset),
		call(onGetLocationListBasedLevelDetails),
		call(onGetLocationListBasedLevelSuccessReset),
		call(onCheckAuthenticationKeyStart),
		call(onCheckAuthenticationKeyResponseReset),
	]);
}
