/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import memberDetailsReportTypes from './member-details-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchMemberDetailsResponse: null,
	fetchMemberDetailsArr: [],
	getMemberDetailsExcelListResponse: null,
	getMemberDetailsExcelListArr: [],
	getMemberExperianExcelListResponse: null,
	getMemberExperianExcelListArr: [],
	getHighMarkExcelListResponse: null,
	getHighMarkExcelListArr: [],
	getCommonExcelListResponse: null,
	getCommonExcelListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_SUCCESS:
			return {
				...state,
				fetchMemberDetailsResponse: action.payload,
				fetchMemberDetailsArr: docodeResponse(
					action.payload,
					'PostAllMemberDetailReportKey',
				),
			};

		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_FAILURE:
			return {
				...state,
				fetchMemberDetailsResponse: action.payload,
				fetchMemberDetailsArr: [],
			};

		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchMemberDetailsResponse: null };

		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				getMemberDetailsExcelListResponse: action.payload,
				getMemberDetailsExcelListArr: docodeResponse(
					action.payload,
					'MemberDetailsExcelKey',
				),
			};

		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_FAILURE:
			return {
				...state,
				getMemberDetailsExcelListResponse: action.payload,
				getMemberDetailsExcelListArr: [],
			};

		case memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_CHANGED:
			return { ...state, getMemberDetailsExcelListResponse: null };

		case memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				getMemberExperianExcelListResponse: action.payload,
				getMemberExperianExcelListArr: docodeResponse(
					action.payload,
					'MemberExperianExcelKey',
				),
			};

		case memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_FAILURE:
			return {
				...state,
				getMemberExperianExcelListResponse: action.payload,
				getMemberExperianExcelListArr: [],
			};

		case memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_CHANGED:
			return { ...state, getMemberExperianExcelListResponse: null };

		case memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				getHighMarkExcelListResponse: action.payload,
				getHighMarkExcelListArr: docodeResponse(action.payload, 'HighmarkExcelKey'),
			};

		case memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_FAILURE:
			return {
				...state,
				getHighMarkExcelListResponse: action.payload,
				getHighMarkExcelListArr: [],
			};

		case memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_CHANGED:
			return { ...state, getHighMarkExcelListResponse: null };

		case memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				getCommonExcelListResponse: action.payload,
				getCommonExcelListArr: docodeResponse(action.payload, 'CommonMemberExportKey'),
			};

		case memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_FAILURE:
			return {
				...state,
				getCommonExcelListResponse: action.payload,
				getCommonExcelListArr: [],
			};

		case memberDetailsReportTypes.FETCH_COMMON_REPORT_RESPONSE_EXCEL_CHANGED:
			return { ...state, getCommonExcelListResponse: null };

		default:
			return state;
	}
};
