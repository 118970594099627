/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ArrearFollowupActionTypes from './arrear-followup-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {

	getArrearFollowupListResponse: null,
	getArrearFollowupListArr: [],
	getArrearFollowupListStatus: null,

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_SUCCESS:
			return {
				...state,
				getArrearFollowupListResponse: action.payload,
				getArrearFollowupListArr: docodeResponse(action.payload, 'GetArrearFollowUpReportKey'),
				getArrearFollowupListStatus: 'Success',
			};

		case ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_FAILURE:
			return { ...state, getArrearFollowupListStatus: action.payload };

		case ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_CHANGED:
			return { ...state, getArrearFollowupListResponse: null };

		case ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED:
			return { ...state, getArrearFollowupListArr: null };

		default:
			return state;
	}
};
