/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const AllCollectionDetailsGet = (listData) => {
	const data = {
		GetAllCollectionDetailsToken: listData,
	};
	const url = `${API_URL}GetAllCollectionDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODWalletDetailsGet = (listData) => {
	const data = {
		GetEODWalletAmountDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchEODWalletAmountDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODThriftDetailsGet = (listData) => {
	const data = {
		GetBranchEODThriftDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchEODThriftDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODSavingsDetailsGet = (listData) => {
	const data = {
		GetBranchEODSavingsDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchEODSavingsDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODFixedDepositDetailsGet = (listData) => {
	const data = {
		GetBranchEODFixedDepositDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchEODFixedDepositDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODYetToCollectArrearDetailsGet = (listData) => {
	const data = {
		GetBranchEODYetToCollectArrearDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchYetToCollectMoveToArrearWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// Total Amount fetch starts
export const DemandCollectionTotalDetailsGet = (listData) => {
	const data = {
		GetDemandCollectionTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetDemandCollectionTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const AdvanceCollectionTotalDetailsGet = (listData) => {
	const data = {
		GetAdvanceCollectionTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetAdvanceCollectionTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ArrearCollectionTotalDetailsGet = (listData) => {
	const data = {
		GetArrearCollectionTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetArrearCollectionTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const WalletCollectionTotalDetailsGet = (listData) => {
	const data = {
		GetWalletCollectionTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetWalletCollectionTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const PrecloseCollectionTotalDetailsGet = (listData) => {
	const data = {
		GetPrecloseCollectionTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetPrecloseCollectionTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const LoanAdvanceUsedTotalDetailsGet = (listData) => {
	const data = {
		GetLoanAdvanceUsedTotalDetailsToken: listData,
	};
	const url = `${API_URL}GetLoanAdvanceUsedTotalDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// Total Amount fetch ends

export const CloseLoanListDetailsGet = (listData) => {
	const data = {
		CloseLoanListFetchToken: listData,
	};
	const url = `${API_URL}FetchCloseLoanListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const BranchEODDetailsGet = (listData) => {
	const data = {
		BranchEODFetchToken: listData,
	};
	const url = `${API_URL}GetBranchEODDetailsWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const accountDayBookReportGet = (listData) => {
	const data = {
		AccountDayBookPostDataToken: listData,
	};
	const url = `${API_URL}FetchAccountDayBookToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


export const PrecloseCollectionDetailsGet = (listData) => {
	const data = {
		GetPrecloseCollectionDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchPreCloseEODDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const AdvanceCollectionDetailsGet = (listData) => {
	const data = {
		GetAdvanceCollectionDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchAdvanceCollectionEODDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ArrearCollectionDetailsGet = (listData) => {
	const data = {
		GetArrearCollectionDetailsToken: listData,
	};
	const url = `${API_URL}GetBranchArrearCollectionEODDetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const VoucherApprovedDetailsGet = (listData) => {
	const data = {
		GetVoucherApprovedDetailsToken: listData,
	};
	const url = `${API_URL}CheckVoucherApprovedToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const SubmitEODDetailsGet = (listData) => {
	const data = {
		GetSubmitEODDetailsToken: listData,
	};
	const url = `${API_URL}GetEODLogetailsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const RevertEODDetailsGet = (listData) => {
	const data = {
		RevertEODSubmissionToken: listData,
	};
	const url = `${API_URL}RevertEODSubmissionToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
