/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
/* eslint-disable array-callback-return */

export const arrageMemberTab = (dataArr, loginData) => {
	const arrCopy = dataArr;
	arrCopy.map((item) => {
		if (parseFloat(loginData.ISHighMarkCheck) === 1 && item.label === 'Credit Check') {
			item.isShow = true;
		} else if (parseFloat(loginData.ISHighMarkCheck) === 0 && item.label === 'Credit Check') {
			item.isShow = false;
		}
	});
	return arrCopy;
};

export const updateMemberTab = (dataArr, tabName) => {
	const arrCopy = dataArr;
	const currentIndex = arrCopy.findIndex((x) => x.label === tabName);

	if (tabName === 'RESET') {
		arrCopy.map((item, index) => {
			if (index === 0) {
				item.isDisable = false;
				item.isCompleted = false;
			} else {
				item.isDisable = true;
				item.isCompleted = false;
			}
		});
	} else if (tabName === 'Completed') {
		arrCopy.map((item) => {
			item.isDisable = false;
			item.isCompleted = true;
		});
	} else {
		arrCopy.map((item, index) => {
			if (index <= currentIndex) {
				item.isDisable = false;
				if (index < currentIndex && !item.isCompleted) {
					item.isCompleted = true;
				}
			} else if (!item.isCompleted) {
				item.isDisable = true;
				item.isCompleted = false;
			}
			if (item.label === 'Member KYC') {
				item.isDisable = false;
			}
		});
	}

	return arrCopy;
};

export const nextNavigatePage = (memberDetails) => {
	let navigationPage = '';
	const stageStatus = parseInt(
		memberDetails?.StageCompleted !== undefined ? memberDetails.StageCompleted : 1,
	);

	if (stageStatus === 1 || stageStatus === 2) {
		navigationPage = 'Basic Details';
	} else if (stageStatus === 3) {
		navigationPage = 'Personal Details';
	} else if (stageStatus === 4 || stageStatus === 5) {
		navigationPage = 'Personal Details';
	} else if (stageStatus === 6) {
		navigationPage = 'Family Details';
	} else if (stageStatus === 7) {
		navigationPage = 'Family Details';
	} else if (stageStatus === 8) {
		navigationPage = 'Nominee Details';
	} else if (stageStatus === 9) {
		navigationPage = 'Bank Details';
	} else if (stageStatus === 10) {
		navigationPage = 'House Details';
	} else if (stageStatus === 11 || stageStatus === 12 || stageStatus === 13) {
		navigationPage = 'Member Documents';
	} else if (stageStatus === 14) {
		navigationPage = 'Nominee Documents';
	} else if (stageStatus === 15 || stageStatus === 16) {
		navigationPage = 'Nominee Documents';
	} else if (stageStatus === 17) {
		navigationPage = 'House Images';
	} else if (stageStatus === 18) {
		navigationPage = 'House Images';
	} else if (stageStatus === 19) {
		navigationPage = 'Extra Information';
	} else if (stageStatus === 20) {
		navigationPage = 'Official Details';
	} else if (stageStatus === 21) {
		navigationPage = 'Co Applicant';
	} else if (stageStatus === 22) {
		navigationPage = 'Completed';
	} else {
		navigationPage = 'Verification';
	}

	if (
		stageStatus === 0 &&
		memberDetails?.IsHighMarkCheckNeeded !== undefined &&
		memberDetails?.IsHighMarkCheckNeeded === 1
	) {
		navigationPage = 'Credit Check';
	}

	return navigationPage;
};

export const checkFamilyDetails = (famArr, mandData) => {
	let missingData = '';
	const {
		FamilyMemberName,
		FamilyMemberGender,
		FamilyMemberAadhar,
		FamilyMemberAge,
		FamilyMemberRelationship,
		FamilyMemberEducation,
		FamilyMemberOccupation,
		FamilyMonthlyIncode,
	} = mandData;
	if (famArr !== undefined && famArr !== null && famArr !== '') {
		if (famArr.length > 0) {
			for (let i = 0; i < famArr.length; i += 1) {
				if (
					FamilyMemberName === '1' &&
					(famArr[i].FamilyMemberName === '' ||
						famArr[i].FamilyMemberName === null ||
						famArr[i].FamilyMemberName === undefined)
				) {
					missingData = 'Enter Family Member Name.';
					break;
				} else if (
					FamilyMemberGender === '1' &&
					(famArr[i].FamilyMemberGender === '' ||
						famArr[i].FamilyMemberGender === null ||
						famArr[i].FamilyMemberGender === undefined)
				) {
					missingData = `Select Family Member ${famArr[i].FamilyMemberName} Gender.`;
					break;
				} else if (
					FamilyMemberAadhar === '1' &&
					(famArr[i].FamilyMemberAadharNumber === '' ||
						famArr[i].FamilyMemberAadharNumber === null ||
						famArr[i].FamilyMemberAadharNumber === undefined)
				) {
					missingData = `Enter Family Member ${famArr[i].FamilyMemberName} Aadhar.`;
					break;
				} else if (
					FamilyMemberAge === '1' &&
					(famArr[i].FamilyMemberAge === '' ||
						famArr[i].FamilyMemberAge === null ||
						famArr[i].FamilyMemberAge === undefined)
				) {
					missingData = `Enter Family Member ${famArr[i].FamilyMemberName} Age.`;
					break;
				} else if (
					FamilyMemberRelationship === '1' &&
					(famArr[i].FamilyMemberRelationship === '' ||
						famArr[i].FamilyMemberRelationship === null ||
						famArr[i].FamilyMemberRelationship === undefined)
				) {
					missingData = `Select Family Member ${famArr[i].FamilyMemberName} Relationship.`;
					break;
				} else if (
					FamilyMemberEducation === '1' &&
					(famArr[i].education === '' ||
						famArr[i].education === null ||
						famArr[i].education === undefined)
				) {
					missingData = `Select Family Member ${famArr[i].FamilyMemberName} Education.`;
					break;
				} else if (
					FamilyMemberOccupation === '1' &&
					(famArr[i].occupation === '' ||
						famArr[i].occupation === null ||
						famArr[i].occupation === undefined)
				) {
					missingData = `Select Family Member ${famArr[i].FamilyMemberName} Education.`;
					break;
				} else if (
					FamilyMonthlyIncode === '1' &&
					(famArr[i].monthlyIncome === '' ||
						famArr[i].monthlyIncome === null ||
						famArr[i].monthlyIncome === undefined)
				) {
					missingData = `Enter Family Member ${famArr[i].FamilyMemberName} Income.`;
					break;
				}
			}
		}
	}
	return missingData;
};

export const maxDOBLimit = () => {
	const today = new Date();
	const maxDOB = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
	return maxDOB.toISOString().split('T')[0]; // Returns a string in 'YYYY-MM-DD' format
};

export const tillTodayLimit = () => {
	const today = new Date();
	return today.toISOString().split('T')[0]; // Returns a string in 'YYYY-MM-DD' format
};
