/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import PortfolioReportActionTypes from './portfolioReport.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getPortfolioReportDetailsStart = (getData) => ({
	type: PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_START,
	payload: getData,
});

export const getPortfolioReportDetailsSuccess = (successData) => ({
	type: PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getPortfolioReportDetailsFailure = (failureData) => ({
	type: PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getPortfolioReportDetailsResponseResetStart = () => ({
	type: PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_RESPONSE_RESET,
});

export const getPortfolioReportDetailsResponseChanged = () => ({
	type: PortfolioReportActionTypes.GET_PORTFOLIO_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */


/** ***************** FETCH PROGRESS REPORT EXCEL DETAILS START *********************** */
export const fetchPortfolioExcelStart = (getData) => ({
	type: PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_START,
	payload: getData,
});

export const fetchPortfolioExcelSuccess = (successData) => ({
	type: PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchPortfolioExcelFailure = (failureData) => ({
	type: PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchPortfolioExcelReset = () => ({
	type: PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_RESET,
});

export const fetchPortfolioExcelChanged = () => ({
	type: PortfolioReportActionTypes.FETCH_PORTFOLIO_EXCEL_CHANGED,
});
/** ***************** FETCH PROGRESS REPORT EXCEL DETAILS END *********************** */
