/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const staffDetailsAdd = (addData) => {
	const data = {
		AddStaffDetailsToken: addData,
	};
	const url = `${API_URL}addStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffListGet = (listData) => {
	const data = {
		GetStaffBasedOnCompanyToken: listData,
	};
	// const url = `${API_URL}getStaffList`;
	const url = `${API_URL}StaffBasedOnComapany`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsFetch = (fetchData) => {
	const data = {
		FetchStaffDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsDelete = (deleteData) => {
	const data = {
		DeleteStaffDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffPublishStatusUpdate = (deleteData) => {
	const data = {
		UpdateStaffPublishToken: deleteData,
	};
	const url = `${API_URL}updatePublishStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allStaffFetch = (fetchBranchesData) => {
	const data = {
		FetchAllBranchKeyToken: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllBranches`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAllStaffExcel = (postData) => {
	const params = new URLSearchParams();
	params.append('AllStaffExportReportToken', postData);
	const url = `${API_URL}Report/Excel/AllStaffsReportExport.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const staffIsPrintStatusUpdate = (deleteData) => {
	const data = {
		UpdateStaffIsPrintToken: deleteData,
	};
	const url = `${API_URL}UpdateIsPrintReceiptAsManagerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allStaffListExportGet = (postData) => {
	const params = new URLSearchParams();
	params.append('ExportAllStaffToken', postData);
	const url = `${API_URL}Report/Excel/ExportAllStaff.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};