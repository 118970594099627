/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import BankDetailsActionType from './bank-details.types';
import {
	bankCreateSuccess,
	bankCreateFailure,
	bankResponseChanged,
	bankIFSCcodeSuccess,
	bankIFSCcodeFailure,
	bankIFSCcodeChanged,
} from './bank-details.action';
import { encodeJson } from '../../../enode-decode';
import { basnkMemberDetailsAdd, BankIFSCCreate } from './bank-details.service';

// Start Bank Details Add
export function* AddBankDetails({ payload }) {
	try {
		const key = 'AddMemberBankDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			AccountNumber: payload.accountNumber,
			ReEnterAccountNumber: payload.reEnterAccountNumber,
			AccountHolderName: payload.accountHolderName,
			IFSCCode: payload.ifscCode,
			BankName: payload.bankName,
			BankBranchName: payload.bankBranchName,
			RbiStatus: payload.rbiStatus,
			AccountType: payload.accountType,
			IsBankTransaction: payload.isBankTransaction,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(basnkMemberDetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(bankCreateFailure(responseData.data));
		} else {
			yield put(bankCreateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bankCreateFailure(error));
	}
}

export function* AddBankDetailsResponseClear() {
	yield put(bankResponseChanged());
}

export function* onBankDetailsAddStart() {
	yield takeLatest(BankDetailsActionType.BANK_DETAILS_CREATE_START, AddBankDetails);
}

export function* onBankDetailsAddResponseRest() {
	yield takeLatest(
		BankDetailsActionType.BANK_DETAILS_CREATE_RESPONSE_REST_START,
		AddBankDetailsResponseClear,
	);
}
// End Bank Details Add

// Start Bank IFSC Code Add
export function* bankIFSCcode({ payload: { ifscCode } }) {
	try {
		const responseData = yield call(BankIFSCCreate, ifscCode);
		if (responseData.status === 200) {
			yield put(bankIFSCcodeSuccess(responseData.data));
		} else {
			yield put(bankIFSCcodeFailure(responseData.data));
		}
	} catch (error) {
		yield put(bankIFSCcodeFailure(error));
	}
}

export function* bankIFSCcodeChange() {
	yield put(bankIFSCcodeChanged());
}

export function* onBankIFSCcodeStart() {
	yield takeLatest(BankDetailsActionType.BANK_IFSC_CODE_RESPONSE_START, bankIFSCcode);
}

export function* onBankIFSCcodeChange() {
	yield takeLatest(BankDetailsActionType.BANK_IFSC_CODE_RESPONSE_RESET, bankIFSCcodeChange);
}
// End Bank IFSC Code

export function* memberBankDetailsSaga() {
	yield all([
		call(onBankDetailsAddStart),
		call(onBankDetailsAddResponseRest),
		call(onBankIFSCcodeStart),
		call(onBankIFSCcodeChange),
	]);
}
