/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */

import { decodeTokenJson } from '../redux/enode-decode';
import { decodeBase64Response } from '../redux/common/common.utils';

const indexedLocalDb =
	window.indexedDB ||
	window.mozIndexedDB ||
	window.webkitIndexedDB ||
	window.msIndexedDB ||
	window.webkitGetDatabaseNames;

// Function to Create Store
export const createLocalStore = () => {
	return new Promise((resolve, reject) => {
		// Open or create a database
		let db = '';

		try {
			if (indexedLocalDb) {
				const openDBRequest = indexedLocalDb.open('SquareNow', 1);

				openDBRequest.onerror = (event) => {
					reject('Failure');
				};

				openDBRequest.onupgradeneeded = (event) => {
					db = event.target.result;

					// Create or upgrade database schema
					if (!db.objectStoreNames.contains('mfiapi')) {
						const objectStore = db.createObjectStore('mfiapi');
					}

					resolve('Success');
					// db.close(); // Close the database once data retrieval is done
				};

				openDBRequest.onsuccess = (event) => {
					resolve('Success');
					// event.target.result.close(); // Close the database once data retrieval is done
				};
			} else {
				reject('IndexedDb Not exists');
			}
		} catch (err) {
			reject('Failure');
		}
	});
};

// Function to Store local data
export const setLocalStore = (data, type) => {
	return new Promise((resolve, reject) => {
		try {
			if (indexedLocalDb) {
				let db = '';

				checkIndexedDBExists().then((isExists) => {
					// Local store not exists
					if (!isExists) {
						createLocalStore().then((response) => {
							if (response === 'Success') {
								// Database
								const openDBRequest = indexedLocalDb.open('SquareNow');

								// error
								openDBRequest.onerror = (event) => {
									reject('Failure');
								};

								// if db exists
								openDBRequest.onsuccess = (event) => {
									db = event.target.result;

									// check store exists or not
									// if (!db.objectStoreNames.contains('mfiapi')) {
									// 	const objectStore = db.createObjectStore('mfiapi');
									// }

									// set local data
									if (db.objectStoreNames.contains('mfiapi')) {
										const transaction = db.transaction(['mfiapi'], 'readwrite');
										const objectStore = transaction.objectStore('mfiapi');

										// Check if entry with "type" exists
										const getRequest = objectStore.get(type);

										getRequest.onsuccess = () => {
											const existingEntry = getRequest.result;

											if (existingEntry && existingEntry.length > 0) {
												// Entry with name "type" exists, update its value
												const updateRequest = objectStore.put(data, type);

												updateRequest.onsuccess = (event) => {
													resolve('Success');
												};

												updateRequest.onerror = (event) => {
													reject('Failure');
												};
											} else {
												// Entry with name "type" does not exist, add new entry
												const storeRequest = objectStore.put(data, type);

												storeRequest.onsuccess = (event) => {
													resolve('Success');
												};

												storeRequest.onerror = (event) => {
													reject('Failure');
												};
											}
										};

										getRequest.onerror = (event) => {
											reject('Failure');
										};
									}
								};

								// if db doesn't exists
								openDBRequest.onupgradeneeded = (event) => {
									db = event.target.result;

									// Create or upgrade database schema
									if (!db.objectStoreNames.contains('mfiapi')) {
										const objectStore = db.createObjectStore('mfiapi');

										// Check if entry with "type" exists
										const getRequest = objectStore.get(type);

										getRequest.onsuccess = () => {
											const existingEntry = getRequest.result;

											if (existingEntry && existingEntry.length > 0) {
												// Entry with name "type" exists, update its value
												const updateRequest = objectStore.put(data, type);

												updateRequest.onsuccess = (event) => {
													resolve('Success');
												};

												updateRequest.onerror = (event) => {
													reject('Failure');
												};
											} else {
												// Entry with name "type" does not exist, add new entry
												const storeRequest = objectStore.put(data, type);

												storeRequest.onsuccess = (event) => {
													resolve('Success');
												};

												storeRequest.onerror = (event) => {
													reject('Failure');
												};
											}
										};

										getRequest.onerror = (event) => {
											reject('Failure');
										};
									} else {
										const transaction = db.transaction(['mfiapi'], 'readwrite');
										const objectStore = transaction.objectStore('mfiapi');

										// Check if entry with "type" exists
										const getRequest = objectStore.get(type);

										getRequest.onsuccess = () => {
											const existingEntry = getRequest.result;

											if (existingEntry && existingEntry.length > 0) {
												// Entry with name "type" exists, update its value
												const updateRequest = objectStore.put(data, type);

												updateRequest.onsuccess = (event) => {
													resolve('Success');
												};

												updateRequest.onerror = (event) => {
													reject('Failure');
												};
											} else {
												// Entry with name "type" does not exist, add new entry
												const storeRequest = objectStore.put(data, type);

												storeRequest.onsuccess = (event) => {
													resolve('Success');
												};

												storeRequest.onerror = (event) => {
													reject('Failure');
												};
											}
										};

										getRequest.onerror = (event) => {
											reject('Failure');
										};
									}
								};
							}
						});
					} else {
						// Database
						const openDBRequest = indexedLocalDb.open('SquareNow');

						// error
						openDBRequest.onerror = (event) => {
							reject('Failure');
						};

						// if db exists
						openDBRequest.onsuccess = (event) => {
							db = event.target.result;

							// check store exists or not
							// if (!db.objectStoreNames.contains('mfiapi')) {
							// 	const objectStore = db.createObjectStore('mfiapi');
							// }

							// set local data
							if (db.objectStoreNames.contains('mfiapi')) {
								const transaction = db.transaction(['mfiapi'], 'readwrite');
								const objectStore = transaction.objectStore('mfiapi');

								// Check if entry with "type" exists
								const getRequest = objectStore.get(type);

								getRequest.onsuccess = () => {
									const existingEntry = getRequest.result;

									if (existingEntry && existingEntry.length > 0) {
										// Entry with name "type" exists, update its value
										const updateRequest = objectStore.put(data, type);

										updateRequest.onsuccess = (event) => {
											resolve('Success');
										};

										updateRequest.onerror = (event) => {
											reject('Failure');
										};
									} else {
										// Entry with name "type" does not exist, add new entry
										const storeRequest = objectStore.put(data, type);

										storeRequest.onsuccess = (event) => {
											resolve('Success');
										};

										storeRequest.onerror = (event) => {
											reject('Failure');
										};
									}
								};

								getRequest.onerror = (event) => {
									reject('Failure');
								};
							}
						};

						// if db doesn't exists
						openDBRequest.onupgradeneeded = (event) => {
							db = event.target.result;

							// Create or upgrade database schema
							if (!db.objectStoreNames.contains('mfiapi')) {
								const objectStore = db.createObjectStore('mfiapi');

								// Check if entry with "type" exists
								const getRequest = objectStore.get(type);

								getRequest.onsuccess = () => {
									const existingEntry = getRequest.result;

									if (existingEntry && existingEntry.length > 0) {
										// Entry with name "type" exists, update its value
										const updateRequest = objectStore.put(data, type);

										updateRequest.onsuccess = (event) => {
											resolve('Success');
										};

										updateRequest.onerror = (event) => {
											reject('Failure');
										};
									} else {
										// Entry with name "type" does not exist, add new entry
										const storeRequest = objectStore.put(data, type);

										storeRequest.onsuccess = (event) => {
											resolve('Success');
										};

										storeRequest.onerror = (event) => {
											reject('Failure');
										};
									}
								};

								getRequest.onerror = (event) => {
									reject('Failure');
								};
							} else {
								const transaction = db.transaction(['mfiapi'], 'readwrite');
								const objectStore = transaction.objectStore('mfiapi');

								// Check if entry with "type" exists
								const getRequest = objectStore.get(type);

								getRequest.onsuccess = () => {
									const existingEntry = getRequest.result;

									if (existingEntry && existingEntry.length > 0) {
										// Entry with name "type" exists, update its value
										const updateRequest = objectStore.put(data, type);

										updateRequest.onsuccess = (event) => {
											resolve('Success');
										};

										updateRequest.onerror = (event) => {
											reject('Failure');
										};
									} else {
										// Entry with name "type" does not exist, add new entry
										const storeRequest = objectStore.put(data, type);

										storeRequest.onsuccess = (event) => {
											resolve('Success');
										};

										storeRequest.onerror = (event) => {
											reject('Failure');
										};
									}
								};

								getRequest.onerror = (event) => {
									reject('Failure');
								};
							}
						};
					}
				});
			} else {
				reject('IndexedDb Not exists');
			}
		} catch (err) {
			reject('Failure');
		}
	});
};

// Function to Remove Store
export const deleteLocalStore = () => {
	return new Promise((resolve, reject) => {
		try {
			const deleteRequest = indexedLocalDb.deleteDatabase('SquareNow');

			deleteRequest.onerror = (event) => {
				reject('Failure');
			};

			deleteRequest.onsuccess = (event) => {
				resolve('Success');
				// event.target.result.close(); // Close the database once data retrieval is done
			};
		} catch (err) {
			reject('Failure');
		}
	});
};

// Function to Get data
export const getLocalStore = (type) => {
	return new Promise((resolve, reject) => {
		try {
			let db = '';
			let key = '';

			if (type === 'Branch') {
				key = 'FetchAllBranchKey';
			} else if (type === 'Center') {
				key = 'GetAllCentersList';
			} else if (type === 'Country') {
				key = 'GetCountryListKey';
			} else if (type === 'State') {
				key = 'CountryStateListGetKey';
			} else if (type === 'City') {
				key = 'StateCityListGetKey';
			}

			if (indexedLocalDb) {
				// Database
				const openDBRequest = indexedLocalDb.open('SquareNow');

				// error
				openDBRequest.onerror = () => {
					reject('Failure');
				};

				// if db exists
				openDBRequest.onsuccess = (event) => {
					db = event.target.result;

					// set local data
					if (db.objectStoreNames.contains('mfiapi')) {
						const transaction = db.transaction(['mfiapi'], 'readwrite');
						const objectStore = transaction.objectStore('mfiapi');

						// Check if entry with "type" exists
						const getRequest = objectStore.get(type);

						getRequest.onsuccess = () => {
							const existingToken = getRequest.result;

							if (existingToken) {
								// Entry with name "type" exists, update its value
								let decodeToken = '';
								if (type === 'State' || type === 'City') {
									decodeToken = decodeBase64Response(existingToken, key);
								} else {
									decodeToken = decodeTokenJson(existingToken, key);
								}

								if (decodeToken?.data) {
									resolve(decodeToken.data);
								} else {
									resolve(existingToken);
								}
								// db.close(); // Close the database once data retrieval is done
							} else {
								resolve(existingToken);
							}
						};

						getRequest.onerror = () => {
							reject('Failure');
						};
					}
				};
			} else {
				reject('IndexedDb Not exists');
			}
		} catch (err) {
			reject('Failure');
		}
	});
};

// Function to check indexedDb exists or not
export const checkIndexedDBExists = async () => {
	return new Promise((resolve, reject) => {
		if (indexedLocalDb) {
			// Open IndexedDB database
			const request = indexedLocalDb.open('SquareNow');

			// Handle success or error
			request.onsuccess = (event) => {
				const db = event.target.result;

				if (db.objectStoreNames.contains('mfiapi')) {
					const transaction = db.transaction(['mfiapi'], 'readwrite');
					const objectStore = transaction.objectStore('mfiapi');

					// Check if there is data in the object store
					const request = objectStore.getAll();
					request.onsuccess = (event) => {
						const data = event.target.result;
						if (data.length > 0) {
							resolve(data); // Data exists
						} else {
							resolve(null); // No data
						}
					};
					request.onerror = (event) => {
						reject(event.target.error);
					};
				} else {
					resolve(null); // No data
				}
			};

			// if db doesn't exists
			request.onupgradeneeded = (event) => {
				const db = event.target.result;

				// Create or upgrade database schema
				if (!db.objectStoreNames.contains('mfiapi')) {
					resolve(null); // Database Yet To Created
				}
			};

			request.onerror = (event) => {
				reject(event.target.error);
			};
		}
	});
};

// Function to get center list
export const getCenterList = async (branchIDs) => {
	try {
		const response = await getLocalStore('Center');
		if (response) {
			// If branchIDs is a comma-separated string, split it into an array
			const branchIDArray = typeof branchIDs === 'string' ? branchIDs.split(',') : branchIDs;

			// Filter items where the AreaID is included in the branchIDArray
			return response.filter((item) => branchIDArray.includes(item.AreaID));
		}
		return [];
	} catch (error) {
		return [];
	}
};

// createLocalStore()
// 	.then((response) => {
// 		console.log('Create IndexedDB:', response);
// 	})
// 	.catch((error) => {
// 		console.error('Create IndexedDB Error:', error);
// 	});

// setLocalStore(data, 'Sundar')
// 	.then((response) => {
// 		console.log('Add Data:', response);
// 	})
// 	.catch((error) => {
// 		console.error('Add Data Error:', error);
// 	});

// getLocalStore('Branch')
// 	.then((response) => {
// 		console.log('Data from IndexedDB:', response);
// 	})
// 	.catch((error) => {
// 		console.error(error);
// 	});

// deleteLocalStore()
// 	.then((response) => {
// 		console.log('Delete IndexedDB:', response);
// 	})
// 	.catch((error) => {
// 		console.error('Delete IndexedDB Error:', error);
// 	});
