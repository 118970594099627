/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import AddMemberActionType from './add-member.types';
import {
	fetchExistsMemberDetailsSuccess,
	fetchExistsMemberDetailsFailure,
	fetchExistsMemberDetailsResponseChanged,
	rejectMemberDetailsSuccess,
	rejectMemberDetailsFailure,
	rejectMemberDetailsResponseChanged,
	fetchMemberDataResponseChanged,
	fetchMemberDataFailure,
	fetchMemberDataSuccess,
	getMemberMandatoryResponseChanged,
	getMemberMandatoryFailure,
	getMemberMandatorySuccess,
	getAddMemberNavListResponseChanged,
	getAddMemberNavListFailure,
	getAddMemberNavListSuccess,
	checkMandatoryFieldsRespChanged,
	checkMandatoryFieldsSuccess,
	checkMandatoryFieldsFailure,
	checkMandatoryScreenStatusSuccess,
	checkMandatoryScreenStatusFailure,
	checkMandatoryScreenStatusRespChanged,
	fetchAddMemberListFailure,
	fetchAddMemberListSuccess,
	fetchAddMemberListResponseChanged,
	exportMemberListSuccess,
	exportMemberListFailure,
	exportMemberListResponseChanged,
	deleteMemberResponseChanged,
	deleteMemberSuccess,
	deleteMemberFailure,
} from './add-member.action';
import { encodeJson } from '../../../enode-decode';
import {
	checkMAndatoryDataAPI,
	checkMandatoryScreenAPI,
	fetchAddMemberNavAPI,
	fetchMemberData,
	memberDetailsGetService,
	memberMandatoryGetService,
	memberDetailsRejectService,
	addMemberListFetchService,
	fetchAllMemberExcel,
	deleteMemberService,
} from './add-member.service';

// Start Get Member Details
export function* memberDetailsGet({ payload }) {
	try {
		const key = 'MemberProfileDetails';
		const jsonData = {
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsGetService, memberData);
		if (responseData.status === '100') {
			yield put(fetchExistsMemberDetailsFailure(responseData.data));
		} else {
			yield put(fetchExistsMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchExistsMemberDetailsFailure(error));
	}
}

export function* resetMemberDetails() {
	yield put(fetchExistsMemberDetailsResponseChanged());
}

export function* onGetMemberDetailsStart() {
	yield takeLatest(AddMemberActionType.GET_MEMBER_DETAILS_START, memberDetailsGet);
}

export function* onResetGetMemberDetails() {
	yield takeLatest(
		AddMemberActionType.GET_MEMBER_DETAILS_RESPONSE_REST_START,
		resetMemberDetails,
	);
}

// Start Get Member Details
export function* memberDataGet({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			MemberID: payload.MemberID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberData, memberData, payload.serviceName);
		if (responseData.status === '100') {
			yield put(fetchMemberDataFailure(responseData.data));
		} else {
			yield put(fetchMemberDataSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberDataFailure(error));
	}
}

export function* resetMemberData() {
	yield put(fetchMemberDataResponseChanged());
}

export function* onGetMemberDataStart() {
	yield takeLatest(AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_START, memberDataGet);
}

export function* onResetGetMemberData() {
	yield takeLatest(
		AddMemberActionType.FETCH_PARTICULAR_MEMBER_DETAILS_RESPONSE_REST_START,
		resetMemberData,
	);
}

export function* memberMandatoryGet({ payload }) {
	try {
		const key = 'MemberMandatoryDetailskey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			OfficerID: payload.userID,
			AccessType: payload.type,
			UserPassword: payload.UserPassword,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(memberMandatoryGetService, memberData);
		if (responseData.status === '100') {
			yield put(getMemberMandatoryFailure(responseData.data));
		} else {
			yield put(getMemberMandatorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberMandatoryFailure(error));
	}
}

export function* resetMemberMandatoryData() {
	yield put(getMemberMandatoryResponseChanged());
}

export function* onGetMemberMandatoryStart() {
	yield takeLatest(AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_START, memberMandatoryGet);
}

export function* onResetMemberMandatoryData() {
	yield takeLatest(
		AddMemberActionType.GET_MEMBER_MANDATORY_FIELDS_RESPONSE_REST_START,
		resetMemberMandatoryData,
	);
}
// End Bank IFSC Code

// START ADD MEMBER NAV LIST FETCH
export function* toFetchNavList({ payload }) {
	try {
		const key = 'AddMemberNavigationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const jsonEncoded = encodeJson(jsonData, key);
		const responseData = yield call(fetchAddMemberNavAPI, jsonEncoded);
		if (responseData.data.statusCode === '100') {
			yield put(getAddMemberNavListFailure(responseData.data));
		} else {
			yield put(getAddMemberNavListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAddMemberNavListFailure(error));
	}
}

export function* addMemberNavResponseClear() {
	yield put(getAddMemberNavListResponseChanged());
}

export function* onFetchAddMemberNavStart() {
	yield takeLatest(AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_START, toFetchNavList);
}

export function* onFetchAddMemberNavReset() {
	yield takeLatest(
		AddMemberActionType.GET_ADD_MEMBER_NAV_LIST_RESET_START,
		addMemberNavResponseClear,
	);
}
// END ADD MEMBER NAV LIST FETCH

// START CHECK MANDATORY FIELDS
export function* toCheckMandatoryFields({ payload }) {
	try {
		const key = 'MemberMandatoryCheckKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			OfficerID: payload.userID,
			AccessType: payload.accessType,
			Secratekey: key,
		};
		const jsonEncoded = encodeJson(jsonData, key);
		const responseData = yield call(checkMAndatoryDataAPI, jsonEncoded);
		if (responseData.data.statusCode === '100') {
			yield put(checkMandatoryFieldsFailure(responseData.data));
		} else {
			yield put(checkMandatoryFieldsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkMandatoryFieldsFailure(error));
	}
}

export function* mandatoryCheckResponseClear() {
	yield put(checkMandatoryFieldsRespChanged());
}

export function* onMemberMandatoryCheckStart() {
	yield takeLatest(AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_START, toCheckMandatoryFields);
}

export function* onCheckMandatoryFieldsReset() {
	yield takeLatest(
		AddMemberActionType.CHECK_ALL_MANDATORY_FIELDs_RESET_START,
		mandatoryCheckResponseClear,
	);
}
// END CHECK MANDATORY FIELDS

// START CHECK MANDATORY STATUS
export function* toCheckMandatoryStatus({ payload }) {
	try {
		const key = 'MemberMandatoryCheckKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const jsonEncoded = encodeJson(jsonData, key);
		const responseData = yield call(checkMandatoryScreenAPI, jsonEncoded);
		if (responseData.data.statusCode === '100') {
			yield put(checkMandatoryScreenStatusFailure(responseData.data));
		} else {
			yield put(checkMandatoryScreenStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkMandatoryScreenStatusFailure(error));
	}
}

export function* mandatoryStatusResponseClear() {
	yield put(checkMandatoryScreenStatusRespChanged());
}

export function* onMemberMandatoryStatusStart() {
	yield takeLatest(
		AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_START,
		toCheckMandatoryStatus,
	);
}

export function* onCheckMandatoryStatusReset() {
	yield takeLatest(
		AddMemberActionType.FETCH_SCREEN_MANDATORY_STATUS_RESET_START,
		mandatoryStatusResponseClear,
	);
}

export function* memberDetailsReject({ payload }) {
	try {
		const key = 'RejectMemberProfileDetails';
		const jsonData = {
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			CompanyID: payload.companyID,
			UserName: payload.UserName,
			ResonForReject: payload.resonForReject,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsRejectService, memberData);
		if (responseData.status === '100') {
			yield put(rejectMemberDetailsFailure(responseData.data));
		} else {
			yield put(rejectMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(rejectMemberDetailsFailure(error));
	}
}

export function* resetRejectMemberDetails() {
	yield put(rejectMemberDetailsResponseChanged());
}

export function* onRejectMemberDetailsStart() {
	yield takeLatest(AddMemberActionType.REJECT_MEMBER_DETAILS_START, memberDetailsReject);
}

export function* onResetRejectMemberDetails() {
	yield takeLatest(
		AddMemberActionType.REJECT_MEMBER_DETAILS_RESPONSE_REST_START,
		resetRejectMemberDetails,
	);
}

export function* addMemberListFetch({ payload }) {
	try {
		const key = 'MemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			Limit: payload.limit,
			InputSearch: payload.InputSearch,
			UserRole: payload.userRole,
			LoginUserRoleID: payload.userRoleID,
			Level: payload.userLevel,
			BranchID: payload.userAccessBranchID,
			MemberStatus: payload.Status,
			DatabaseName: payload.databaseName,
			MemberName: payload.MemberName,
			FromDate: payload.CreatedFrom,
			ToDate: payload.CreatedTo,
			AgeFrom: payload.FromAge,
			AgeTo: payload.ToAge,
			EconomicActivityID: payload.economicActivityID,
			GroupID: payload.groupID,
			CenterID: payload.centerID,
			AreaID: payload.branchID,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(addMemberListFetchService, memberData, payload.serviceName);
		if (responseData.status === '100') {
			yield put(fetchAddMemberListFailure(responseData.data));
		} else {
			yield put(fetchAddMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAddMemberListFailure(error));
	}
}

export function* resetFetchAddMemberList() {
	yield put(fetchAddMemberListResponseChanged());
}

export function* onFetchAddMemberListStart() {
	yield takeLatest(AddMemberActionType.FETCH_ADD_MEMBER_LIST_START, addMemberListFetch);
}

export function* onResetFetchAddMemberList() {
	yield takeLatest(
		AddMemberActionType.FETCH_ADD_MEMBER_LIST_RESPONSE_REST_START,
		resetFetchAddMemberList,
	);
}
// END CHECK MANDATORY STATUS

export function* exportMemberListReport({ payload }) {
	try {
		const key = 'AllMemberReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			ProvisionType: payload.ProvisionType,
			MemberStatus: payload.Status,
			AreaID: payload.AreaID,
			BranchID: payload.BranchID,
			GroupID: payload.GroupID,
			MemberName: payload.MemberName,
			UserLevel: payload.userLevel,
			AgeFrom: payload.AgeFrom,
			AgeTo: payload.AgeTo,
			EconomicActivityID: payload.EconomicActivityID,
			IsAdvanceSearch: payload.IsAdvanceSearch,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllMemberExcel, memberData);
		if (responseData.status === '500') {
			yield put(exportMemberListFailure(responseData.data));
		} else {
			yield put(exportMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(exportMemberListFailure(error));
	}
}

export function* resetReportExportMemberList() {
	yield put(exportMemberListResponseChanged());
}

export function* onExportReportMemberListStart() {
	yield takeLatest(AddMemberActionType.EXPORT_MEMBER_LIST_START, exportMemberListReport);
}

export function* onResetReportExportMemberList() {
	yield takeLatest(
		AddMemberActionType.EXPORT_MEMBER_LIST_RESPONSE_REST_START,
		resetReportExportMemberList,
	);
}

export function* deleteMemberDetails({ payload }) {
	try {
		const key = 'MemberDeleteKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			MemberID: payload.MemberID,
			AreaID: payload.AreaID,
			UserName: payload.userName,
			MemberName: payload.MemberName,
			MemberInActiveReason: payload.MemberInActiveReason,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const memberData = encodeJson(jsonData, key);
		const responseData = yield call(deleteMemberService, memberData);
		if (responseData.status === '100') {
			yield put(deleteMemberFailure(responseData.data));
		} else {
			yield put(deleteMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteMemberFailure(error));
	}
}

export function* resetMemberDelete() {
	yield put(deleteMemberResponseChanged());
}

export function* onMemberDeleteStart() {
	yield takeLatest(AddMemberActionType.DELETE_MEMBER_START, deleteMemberDetails);
}

export function* onResetDeleteMember() {
	yield takeLatest(AddMemberActionType.DELETE_MEMBER_RESPONSE_RESET_START, resetMemberDelete);
}

export function* memberDetailsSaga() {
	yield all([
		call(onGetMemberDetailsStart),
		call(onResetGetMemberDetails),
		call(onGetMemberDataStart),
		call(onResetGetMemberData),
		call(onGetMemberMandatoryStart),
		call(onResetMemberMandatoryData),
		call(onFetchAddMemberNavStart),
		call(onFetchAddMemberNavReset),
		call(onMemberMandatoryCheckStart),
		call(onCheckMandatoryFieldsReset),
		call(onMemberMandatoryStatusStart),
		call(onCheckMandatoryStatusReset),
		call(onRejectMemberDetailsStart),
		call(onResetRejectMemberDetails),
		call(onFetchAddMemberListStart),
		call(onResetFetchAddMemberList),
		call(onExportReportMemberListStart),
		call(onResetReportExportMemberList),
		call(onMemberDeleteStart),
		call(onResetDeleteMember),
	]);
}
