/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable camelcase */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectGeneral = (state) => state.general;

export const selectEditDetails = createSelector(
	[selectGeneral],
	(general) => general.isDetailsEdit,
);

export const selectOpenOffcanvas = createSelector(
	[selectGeneral],
	(general) => general.isOffcanvasOpen,
);

export const selectCountryList = createSelector(
	[selectGeneral],
	(general) => general.getCountryListResponse,
);

export const selectCountryListResp = createSelector(
	[selectGeneral],
	(general) => general.getCountryListToken,
);

export const selectStateList = createSelector(
	[selectGeneral],
	(general) => general.getStateListResponse,
);

export const selectStateListResp = createSelector(
	[selectGeneral],
	(general) => general.getStateListToken,
);

export const selectCityList = createSelector(
	[selectGeneral],
	(general) => general.getCityListResponse,
);

export const selectCityListResp = createSelector(
	[selectGeneral],
	(general) => general.getCityListToken,
);

export const selectPincodeList = createSelector(
	[selectGeneral],
	(general) => general.getPinCodeListResponse,
);

export const selectDistrictList = createSelector(
	[selectGeneral],
	(general) => general.getDistrictListResponse,
);

//  Industry List Response
export const selectGetIndustryListResponse = createSelector(
	[selectGeneral],
	(general) => general.getIndustryListResponse,
);

export const selectGetAllIndustryList = createSelector(
	[selectGeneral],
	(general) => general.getAllIndustryListArr,
);

//  Sub Industry List Response
export const selectGetSubIndustryListResponse = createSelector(
	[selectGeneral],
	(general) => general.getSubIndustryListResponse,
);

export const selectGetSubIndustryListArr = createSelector(
	[selectGeneral],
	(general) => general.getSubIndustryListArr,
);

// Fetch All Branches
export const selectFetchAllBranchesResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBranchesResponse,
);

export const selectFetchAllBranchesData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBranchesArr,
);

// Fetch All Purpose Of Loan List
export const selectFetchAllPurposeOfLoanListResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllPurposeOfLoanListResponse,
);

export const selectFetchAllPurposeOfLoanListData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllPurposeOfLoanListArr,
);

// Fetch All Purpose Of Loan Type List
export const selectFetchAllPurposeOfLoanTypeResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllPurposeOfLoanTypeResponse,
);

export const selectFetchAllPurposeOfLoanTypeData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllPurposeOfLoanTypeArr,
);

// Fetch All Business List
export const selectFetchAllBusinessResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBusinessResponse,
);

export const selectFetchAllBusinessData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBusinessArr,
);

// Fetch All ProductTypes
export const selectAllProductTypeFetchResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllProductTypesResponse,
);

export const selectFetchAllProductTypeData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllProductTypesArr,
);

// Fetch All Product
export const selectAllProductFetchResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllProductListResponse,
);

export const selectFetchAllProductData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllProductListArr,
);

// Fetch Staff Based On Branch
export const selectGetBranchAllOfficerResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBranchOfficerResponse,
);

export const selectGetBranchAllOfficerData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllBranchOfficerArr,
);

// Fetch All Center
export const selectGetAllCenterResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllCenterResponse,
);

export const selectGetAllCenterData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllCenterArr,
);

// Fetch All Inverstor
export const selectGetAllInverstorResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchAllInverstorResponse,
);

export const selectGetAllInverstorData = createSelector(
	[selectGeneral],
	(general) => general.fetchAllInverstorArr,
);

// FETCH ALL GROUP

export const selectallGroupReponse = createSelector(
	[selectGeneral],
	(general) => general.fetchallGroupResponse,
);

export const selectallGroupData = createSelector(
	[selectGeneral],
	(general) => general.fetchallGroupArr,
);

// Fetch Center based Group
export const selectGetCenterGroupResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchCenterGroupResponse,
);

export const selectGetCenterGroupData = createSelector(
	[selectGeneral],
	(general) => general.fetchCenterGroupArr,
);

// Fetch Member based Group
export const selectGetGroupMemberResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchGroupMemberResponse,
);

export const selectGetGroupMemberData = createSelector(
	[selectGeneral],
	(general) => general.fetchGroupMemberArr,
);

// Fetch Branch Account List
export const selectFetchBranchAccountListResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchBranchAccountListResponse,
);

export const selectFetchBranchAccountListData = createSelector(
	[selectGeneral],
	(general) => general.fetchBranchAccountListArr,
);

// FETCH GROUP LOAN INDEX LIST
export const selectFetchGroupLoanIndexReponse = createSelector(
	[selectGeneral],
	(general) => general.fetchGroupLoanIndexListResponse,
);

export const selectFetchGroupLoanIndexData = createSelector(
	[selectGeneral],
	(general) => general.fetchGroupLoanIndexListArr,
);

// FETCH SEARCH LOAN INDEX LIST
export const selectFetchSearchLoanIndexReponse = createSelector(
	[selectGeneral],
	(general) => general.fetchSearchLoanIndexListResponse,
);

export const selectFetchSearchLoanIndexData = createSelector(
	[selectGeneral],
	(general) => general.fetchSearchLoanIndexListArr,
);

// FETCH PARTICULAR LOAN INDEX LIST
export const selectFetchParticularLoanIndexReponse = createSelector(
	[selectGeneral],
	(general) => general.fetchParticularLoanIndexListResponse,
);

export const selectFetchParticularLoanIndexData = createSelector(
	[selectGeneral],
	(general) => general.fetchParticularLoanIndexListArr,
);

// Fetch Version Details
export const selectFetchVersionDetailsResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchVersionResponse,
);

export const selectFetchVersionDetailsData = createSelector(
	[selectGeneral],
	(general) => general.fetchVersionArr,
);

// Fetch Version Details
export const selectUpdateVersionDetailsResponse = createSelector(
	[selectGeneral],
	(general) => general.updateVersionResponse,
);

export const selectUpdateVersionDetailsData = createSelector(
	[selectGeneral],
	(general) => general.updateVersionArr,
);

// Fetch Version Details
export const selectFetchPincodeDetailsResponse = createSelector(
	[selectGeneral],
	(general) => general.fetchPincodeDetailsResponse,
);

export const selectFetchPincodeDetailsData = createSelector(
	[selectGeneral],
	(general) => general.fetchPincodeDetailsArr,
);

// Get Location List based on Level
export const selectGetLocationListBasedLevelResp = createSelector(
	[selectGeneral],
	(general) => general.getLocationListBasedLevelResponse,
);

export const selectGetLocationListBasedLevelArr = createSelector(
	[selectGeneral],
	(general) => general.getLocationListBasedLevelArray,
);

// Check Authentication Key
export const selectCheckAuthenticationResp = createSelector(
	[selectGeneral],
	(general) => general.checkGoogleAuthenticationResponse,
);
