import AadharDetailsActionType from './aadhar-details.types';

export const aadharFrontImageChangedStart = (aadharFront) => ({
	type: AadharDetailsActionType.AADHAR_FRONT_IMAGE_CHANGED,
	payload: aadharFront,
});

export const aadharBackImageChangedStart = (aadharBack) => ({
	type: AadharDetailsActionType.AADHAR_BACK_IMAGE_CHANGED,
	payload: aadharBack,
});

// update Aadhar
export const updateAadharStart = (aadharCredentials) => ({
	type: AadharDetailsActionType.UPDATE_AADHAR_START,
	payload: aadharCredentials,
});

export const updateAadharSuccess = (aadharSuccess) => ({
	type: AadharDetailsActionType.UPDATE_AADHAR_SUCCESS,
	payload: aadharSuccess,
});

export const updateAadharFailure = (aadharFailure) => ({
	type: AadharDetailsActionType.UPDATE_AADHAR_FAILURE,
	payload: aadharFailure,
});

export const updateAadharResponseResetStart = () => ({
	type: AadharDetailsActionType.UPDATE_AADHAR_RESPONSE_REST_START,
});

export const updateAadharResponseChanged = () => ({
	type: AadharDetailsActionType.UPDATE_AADHAR_RESPONSE_CHANGED,
});
