/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const highmarkRulesAdd = (addData) => {
	const data = {
		UpdateHighmarkRulesDataToken: addData,
	};
	const url = `${API_URL}UpdateHighmarkRuleToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const highmarkRulesFetch = (fetchData) => {
	const data = {
		FetchHighmarkRulesToken: fetchData,
	};
	const url = `${API_URL}GetAllHighmarkRuleListToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
