const arrearCollectionActionTypes = {
	GET_ARREAR_LOAN_LIST_START: 'GET_ARREAR_MEMBER_LOAN_LIST_START',
	GET_ARREAR_LOAN_LIST_SUCCESS: 'GET_ARREAR_MEMBER_LOAN_LIST_SUCCESS',
	GET_ARREAR_LOAN_LIST_FAILURE: 'GET_ARREAR_MEMBER_LOAN_LIST_FAILURE',
	GET_ARREAR_LOAN_LIST_RESPONSE_RESET: 'GET_ARREAR_MEMBER_LOAN_LIST_RESPONSE_RESET',
	GET_ARREAR_LOAN_LIST_RESPONSE_CHANGED: 'GET_ARREAR_MEMBER_LOAN_LIST_RESPONSE_CHANGED',

	UPDATE_ARREAR_COLLECTION_START: 'UPDATE_ARREAR_COLLECTION_START',
	UPDATE_ARREAR_COLLECTION_SUCCESS: 'UPDATE_ARREAR_COLLECTION_SUCCESS',
	UPDATE_ARREAR_COLLECTION_FAILURE: 'UPDATE_ARREAR_COLLECTION_FAILURE',
	UPDATE_ARREAR_COLLECTION_RESET: 'UPDATE_ARREAR_COLLECTION_RESET',
	UPDATE_ARREAR_COLLECTION_CHANGED: 'UPDATE_ARREAR_COLLECTION_CHANGED',

	UPDATE_ARREAR_COLLECTION_REVERT_START: 'UPDATE_ARREAR_COLLECTION_REVERT_START',
	UPDATE_ARREAR_COLLECTION_REVERT_SUCCESS: 'UPDATE_ARREAR_COLLECTION_REVERT_SUCCESS',
	UPDATE_ARREAR_COLLECTION_REVERT_FAILURE: 'UPDATE_ARREAR_COLLECTION_REVERT_FAILURE',
	UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_RESET: 'UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_RESET',
	UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_CHANGED: 'UPDATE_ARREAR_COLLECTION_REVERT_RESPONSE_CHANGED',
};
export default arrearCollectionActionTypes;
