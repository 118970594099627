/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { decodeBase64Response } from '../../../common/common.utils';
import BasicDetailsActionType from './basic-details.types';

const INITIAL_STATE = {
	basicDetailsResponse: null,
	aadharNumber: '',
	aadharScanJson: '',
	skipMemberDetailsResp: null,
	verifyKYCResp: null,
	verifyKYCResult: [],
	verifyAadharOtpResp: null,
	verifyAadharOtpResult: [],
	verifyValidateAadharOtpResp: null,
	verifyValidateAadharOtpResult: [],
	fetchKYCVerificationDetails: [],
	fetchKYCVerificationResp: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BasicDetailsActionType.BASIC_DETAILS_ADD_SUCCESS:
			return { ...state, basicDetailsResponse: action.payload };

		case BasicDetailsActionType.BASIC_DETAILS_ADD_FAILURE:
			return { ...state, basicDetailsResponse: action.payload };

		case BasicDetailsActionType.BASIC_DETAILS_ADD_RESPONSE_CHANGED:
			return { ...state, basicDetailsResponse: null };

		case BasicDetailsActionType.SCAN_AADHAR_NUMBER:
			return { ...state, aadharNumber: action.payload };

		case BasicDetailsActionType.SCAN_AADHAR_NUMBER_RESET:
			return {
				...state,
				aadharNumber: '',
			};

		case BasicDetailsActionType.AADHAR_QR_CODE_SCAN_DATA:
			return { ...state, aadharScanJson: action.payload };

		case BasicDetailsActionType.SKIP_MEMBER_DETAILS_SUCCESS:
			return { ...state, skipMemberDetailsResp: action.payload };

		case BasicDetailsActionType.SKIP_MEMBER_DETAILS_FAILURE:
			return { ...state, skipMemberDetailsResp: action.payload };

		case BasicDetailsActionType.SKIP_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, skipMemberDetailsResp: null };

		case BasicDetailsActionType.VERIFY_KYC_DETAILS_SUCCESS:
			return { ...state, verifyKYCResp: action.payload };

		case BasicDetailsActionType.VERIFY_KYC_DETAILS_FAILURE:
			return { ...state, verifyKYCResp: action.payload };

		case BasicDetailsActionType.VERIFY_KYC_DETAILS_RESPONSE_CHANGED:
			return { ...state, verifyKYCResp: null };

		case BasicDetailsActionType.VERIFY_AADHAR_OTP_SUCCESS:
			return { ...state, verifyAadharOtpResp: action.payload };

		case BasicDetailsActionType.VERIFY_AADHAR_OTP_FAILURE:
			return { ...state, verifyAadharOtpResp: action.payload };

		case BasicDetailsActionType.VERIFY_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyAadharOtpResp: null };

		case BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_SUCCESS:
			return { ...state, verifyValidateAadharOtpResp: action.payload };

		case BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_FAILURE:
			return { ...state, verifyValidateAadharOtpResp: action.payload };

		case BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_CHANGED:
			return { ...state, verifyValidateAadharOtpResp: null };

		case BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_SUCCESS:
			return {
				...state,
				fetchKYCVerificationResp: action.payload,
				fetchKYCVerificationDetails: decodeBase64Response(
					action.payload,
					'AppVerifyKYCFetchKey',
				),
			};

		case BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_FAILURE:
			return { ...state, fetchKYCVerificationResp: action.payload };

		case BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchKYCVerificationResp: null };

		case BasicDetailsActionType.KYC_RESPONSE_RESET:
			return { ...state, fetchKYCVerificationDetails: null };

		default:
			return state;
	}
};
