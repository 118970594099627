import AddressExtraProofActionType from './address-extra-proof.types';

// update AddressExtraProofId
export const updateAddressExtraProofStart = (addressExtraProofIdCredentials) => ({
	type: AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_START,
	payload: addressExtraProofIdCredentials,
});

export const updateAddressExtraProofSuccess = (addressExtraProofIdSuccess) => ({
	type: AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_SUCCESS,
	payload: addressExtraProofIdSuccess,
});

export const updateAddressExtraProofFailure = (addressExtraProofIdFailure) => ({
	type: AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_FAILURE,
	payload: addressExtraProofIdFailure,
});

export const updateAddressExtraProofResponseResetStart = () => ({
	type: AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_REST_START,
});

export const updateAddressExtraProofResponseChanged = () => ({
	type: AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_CHANGED,
});
