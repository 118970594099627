/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import FamilyActionType from './family-details.types';
import {
	familyIncomeSuccess,
	familyIncomeFailure,
	familyIncomeUserResponseChanged,
	fetchFamilyMemberUserResponseChanged,
	fetchFamilyMemberFailure,
	fetchFamilyMemberSuccess,
	familyMemberAddResponseChanged,
	familyMemberAddFailure,
	familyMemberAddSuccess,
	fetchParticularFamFailure,
	fetchParticularFamSuccess,
	fetchParticularFamResponseChanged,
	deleteFamilyFailure,
	deleteFamilySuccess,
	deleteFamilyResponseChanged,
	verifyFamilyAadharOtpResponseChanged,
	verifyFamilyAadharOtpFailure,
	verifyFamilyAadharOtpSuccess,
	verifyValidateFamilyAadharOtpResponseChanged,
	verifyValidateFamilyAadharOtpFailure,
	verifyValidateFamilyAadharOtpSuccess,
} from './family-details.action';
import { encodeJson } from '../../../enode-decode';
import {
	DeleteMemberFamilyAPI,
	familyMemberAddDetails,
	familyMemberFetch,
	familyMemberFetchParticular,
	familyMemberIncomeAdd,
	verifyFamilyAadharOtpAPI,
	verifyValidateFamilyAadharOtpAPI,
} from './family-details.service';

// Start Family Income Add
export function* AddFamilyIncome({ payload }) {
	try {
		const key = 'AddMemberFamilyIncome';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			FamilyMonthlyIncome: payload.familyMonthlyIncome,
			FamilyMonthlyExpenses: payload.familyMonthlyExpenses,
			FamilyMonthlyExtraSpent: payload.familyMonthlyExtraSpent,
			FamilyType: payload.familyType,
			OfficerID: payload.userID,
			CityType: payload.cityType,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(familyMemberIncomeAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(familyIncomeFailure(responseData.data));
		} else {
			yield put(familyIncomeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(familyIncomeFailure(error));
	}
}

export function* AddFamilyIncomeResponseClear() {
	yield put(familyIncomeUserResponseChanged());
}

export function* onFamilyIncomeAddStart() {
	yield takeLatest(FamilyActionType.FAMILY_MEMBER_INCOME_START, AddFamilyIncome);
}

export function* onFamilyIncomeAddResponseRest() {
	yield takeLatest(
		FamilyActionType.FAMILY_MEMBER_INCOME_RESPONSE_REST_START,
		AddFamilyIncomeResponseClear,
	);
}
// End Family Income Add

// Start Family Income Add
export function* fetchFamilyMember({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(familyMemberFetch, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFamilyMemberFailure(responseData.data));
		} else {
			yield put(fetchFamilyMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFamilyMemberFailure(error));
	}
}

export function* FetchFamilyResponseClear() {
	yield put(fetchFamilyMemberUserResponseChanged());
}

export function* onFamilyFetchStart() {
	yield takeLatest(FamilyActionType.FETCH_FAMILY_MEMBERS_START, fetchFamilyMember);
}

export function* onFamilyFetchResponseRest() {
	yield takeLatest(
		FamilyActionType.FETCH_FAMILY_MEMBERS_RESPONSE_REST_START,
		FetchFamilyResponseClear,
	);
}
// End Family Income Add

// Start Family Income Add
export function* AddMemberFamilyDetails({ payload }) {
	try {
		const key = 'AddMemberFamilyDetails';
		const jsonData = {
			FamilyMemberID: payload.familyMemberID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			FamilyMemberName: payload.name,
			FamilyMemberAadharNumber: payload.aadharNumber,
			FamilyMemberGender: payload.gender,
			FamilyMemberDOB: payload.dob,
			FamilyMemberAge: payload.age,
			FamilyMemberOccupation: payload.occupation,
			FamilyMemberMonthlyIncome: payload.monthlyIncome,
			FamilyMemberRelationship: payload.relationship,
			FamilyMemberEducation: payload.education,
			FamilyMemberEducationCenter: payload.educationCenter,
			FamilyMemberEducationType: payload.educationType,
			FamilyType: payload.familyType,
			SponsoredChildren: payload.isSponsoredChildren,
			ChildrenWithDisability: payload.isChildrenWithDisability,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(familyMemberAddDetails, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(familyMemberAddFailure(responseData.data));
		} else {
			yield put(familyMemberAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(familyMemberAddFailure(error));
	}
}

export function* AddFamilyResponseClear() {
	yield put(familyMemberAddResponseChanged());
}

export function* onFamilyAddStart() {
	yield takeLatest(FamilyActionType.ADD_FAMILY_MEMBERS_START, AddMemberFamilyDetails);
}

export function* onFamilyAddResponseRest() {
	yield takeLatest(
		FamilyActionType.ADD_FAMILY_MEMBERS_RESPONSE_REST_START,
		AddFamilyResponseClear,
	);
}
// End Family Income Add

// Start Family Income Add
export function* FetchMemberFamilyDetails({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			FamilyMemberID: payload.MemberFamiliesID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(familyMemberFetchParticular, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchParticularFamFailure(responseData.data));
		} else {
			yield put(fetchParticularFamSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchParticularFamFailure(error));
	}
}

export function* FetchMemberFamilyResponseClear() {
	yield put(fetchParticularFamResponseChanged());
}

export function* onFamilyFethcIndividualStart() {
	yield takeLatest(
		FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_START,
		FetchMemberFamilyDetails,
	);
}

export function* onFamilyFetchDetailsRest() {
	yield takeLatest(
		FamilyActionType.FETCH_PARTICULAR_FAMILY_MEMBERS_RESPONSE_REST_START,
		FetchMemberFamilyResponseClear,
	);
}
// End Family Income Add

// Start Family Member Delete
export function* DeleteMemberFamilyDetails({ payload }) {
	try {
		const key = 'DeleteFamMemberDataKey';
		const jsonData = {
			FamilyMemberID: payload.MemberFamiliesID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(DeleteMemberFamilyAPI, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteFamilyFailure(responseData.data));
		} else {
			yield put(deleteFamilySuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteFamilyFailure(error));
	}
}

export function* DeleteMemberFamilyResponseClear() {
	yield put(deleteFamilyResponseChanged());
}

export function* onFamilyMemberDeleteStart() {
	yield takeLatest(FamilyActionType.DELETE_FAMILY_MEMBERS_START, DeleteMemberFamilyDetails);
}

export function* onFamilyDeleteDetailsRest() {
	yield takeLatest(
		FamilyActionType.DELETE_FAMILY_MEMBERS_RESPONSE_REST_START,
		DeleteMemberFamilyResponseClear,
	);
}
// End Family Income Add

// Start verify Aadhar Otp
export function* toVerifyFamilyAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(verifyFamilyAadharOtpAPI, verifyData, payload.serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(verifyFamilyAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyFamilyAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyFamilyAadharOtpFailure(error));
	}
}

export function* VerifyFamilyAadharOtpResponseClear() {
	yield put(verifyFamilyAadharOtpResponseChanged());
}

export function* onVerifyFamilyAadharOtpStart() {
	yield takeLatest(FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_START, toVerifyFamilyAadharOtp);
}

export function* onVerifyFamilyAadharOtpRest() {
	yield takeLatest(
		FamilyActionType.VERIFY_FAMILY_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyFamilyAadharOtpResponseClear,
	);
}
// End Verify Aadhar Otp

// Start verify validate Aadhar Otp
export function* toVerifyValidateFamilyAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			RandNum: payload.randNum,
			Otp: payload.otp,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyValidateFamilyAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyValidateFamilyAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyValidateFamilyAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyValidateFamilyAadharOtpFailure(error));
	}
}

export function* VerifyValidateFamilyAadharOtpResponseClear() {
	yield put(verifyValidateFamilyAadharOtpResponseChanged());
}

export function* onVerifyValidateFamilyAadharOtpStart() {
	yield takeLatest(
		FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_START,
		toVerifyValidateFamilyAadharOtp,
	);
}

export function* onVerifyValidateFamilyAadharOtpRest() {
	yield takeLatest(
		FamilyActionType.VERIFY_VALIDATE_FAMILY_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyValidateFamilyAadharOtpResponseClear,
	);
}
// End Verify validate Aadhar Otp

export function* familyDetailSaga() {
	yield all([
		call(onFamilyIncomeAddStart),
		call(onFamilyIncomeAddResponseRest),
		call(onFamilyFetchResponseRest),
		call(onFamilyFetchStart),
		call(onFamilyAddResponseRest),
		call(onFamilyAddStart),
		call(onFamilyFetchDetailsRest),
		call(onFamilyFethcIndividualStart),
		call(onFamilyMemberDeleteStart),
		call(onFamilyDeleteDetailsRest),
		call(onVerifyFamilyAadharOtpStart),
		call(onVerifyFamilyAadharOtpRest),
		call(onVerifyValidateFamilyAadharOtpStart),
		call(onVerifyValidateFamilyAadharOtpRest),
	]);
}
