/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ReconciliationForQRTypes from './reconciliationForQR.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getReconciliationForQRListArr: [],
	getReconciliationForQRListStatus: null,
	updateReconciliationForQRListStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_SUCCESS:
			return {
				...state,
				getReconciliationForQRListArr: docodeResponse(
					action.payload,
					'fetchQRPaymentForReconciliationKey',
				),
				getReconciliationForQRListStatus: 'SUCCESS',
			};

		case ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_FAILURE:
			return { ...state, getReconciliationForQRListStatus: 'FAILURE' };

		case ReconciliationForQRTypes.GET_QR_PAYMENTS_RECONCILIATION_LIST_CHANGED:
			return { ...state, getReconciliationForQRListStatus: null };

			case ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_SUCCESS:
				return {
					...state,
					updateReconciliationForQRListStatus: 'SUCCESS',
				};
	
			case ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_FAILURE:
				return { ...state, updateReconciliationForQRListStatus: 'FAILURE' };
	
			case ReconciliationForQRTypes.UPDATE_QR_PAYMENTS_RECONCILIATION_CHANGED:
				return { ...state, updateReconciliationForQRListStatus: null };

		default:
			return state;
	}
};
