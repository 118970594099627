/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CreditBureauReportActionTypes from './creditBureauReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getCreditBureauReportListResponse: null,
	getCreditBureauReportListArr: [],
	getCreditBureauExcelListResponse: null,
	getCreditBureauExcelListArr: [],
	getCreditBureauExcelListStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_SUCCESS:
			return {
				...state,
				getCreditBureauReportListResponse: action.payload,
				getCreditBureauReportListArr: docodeResponse(
					action.payload,
					'CreditCheckDetailsReportKey',
				),
			};

		case CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_FAILURE:
			return {
				...state,
				getCreditBureauReportListResponse: action.payload,
				getCreditBureauReportListArr: [],
			};

		case CreditBureauReportActionTypes.GET_CREDIT_BUREAU_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getCreditBureauReportListResponse: null };

		case CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_SUCCESS:
			return {
				...state,
				getCreditBureauExcelListResponse: action.payload,
				getCreditBureauExcelListArr: docodeResponse(
					action.payload,
					'CreditBureauReportExcelKey',
				),
				getCreditBureauExcelListStatus: 'Success',
			};

		case CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_FAILURE:
			return {
				...state,
				getCreditBureauExcelListStatus: action.payload,
				getCreditBureauExcelListArr: [],
			};

		case CreditBureauReportActionTypes.FETCH_CREDIT_BUREAU_EXCEL_RESPONSE_CHANGED:
			return { ...state, getCreditBureauExcelListStatus: null };

		default:
			return state;
	}
};
