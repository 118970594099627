/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import memberDetailsReportTypes from './member-details-report.types';

/** ***************** FETCH MEMBER DETAILS REPORT START *********************** */
export const fetchMemberDetailsReportStart = (addData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_START,
	payload: addData,
});

export const fetchMemberDetailsReportSuccess = (successData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_SUCCESS,
	payload: successData,
});

export const fetchMemberDetailsReportFailure = (failureData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_FAILURE,
	payload: failureData,
});

export const fetchMemberDetailsReportResponseResetStart = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_RESET_START,
});

export const fetchMemberDetailsReportResponseResetChanged = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH MEMBER DETAILS REPORT END *********************** */

/** ***************** FETCH MEMBER DETAILS REPORT EXCEL DETAILS START *********************** */
export const fetchMemberDetailsReportExcelStart = (addData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchMemberDetailsReportExcelSuccess = (successData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchMemberDetailsReportExcelFailure = (failureData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchMemberDetailsReportResponseExcelResetStart = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchMemberDetailsReportResponseExcelResetChanged = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_CHANGED,
});

/** ***************** FETCH MEMBER DETAILS REPORT EXCEL DETAILS END *********************** */

/** ***************** FETCH MEMBER EXPERIAN REPORT EXCEL DETAILS START *********************** */
export const fetchMemberExperianReportExcelStart = (addData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchMemberExperianReportExcelSuccess = (successData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchMemberExperianReportExcelFailure = (failureData) => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchMemberExperianReportResponseExcelResetStart = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchMemberExperianReportResponseExcelResetChanged = () => ({
	type: memberDetailsReportTypes.FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_CHANGED,
});
/** ***************** FETCH MEMBER EXPERIAN REPORT EXCEL DETAILS END *********************** */

/** ***************** FETCH HIGH MARK REPORT EXCEL DETAILS START *********************** */
export const fetchHighMarkReportExcelStart = (addData) => ({
	type: memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchHighMarkReportExcelSuccess = (successData) => ({
	type: memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchHighMarkReportExcelFailure = (failureData) => ({
	type: memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchHighMarkReportResponseExcelResetStart = () => ({
	type: memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchHighMarkReportResponseExcelResetChanged = () => ({
	type: memberDetailsReportTypes.FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_CHANGED,
});
/** ***************** FETCH HIGH MARK REPORT EXCEL DETAILS END *********************** */

/** ***************** FETCH COMMON EXCEL DETAILS START *********************** */
export const fetchCommonReportExcelStart = (addData) => ({
	type: memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchCommonReportExcelSuccess = (successData) => ({
	type: memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchCommonReportExcelFailure = (failureData) => ({
	type: memberDetailsReportTypes.FETCH_COMMON_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchCommonReportResponseExcelResetStart = () => ({
	type: memberDetailsReportTypes.FETCH_COMMON_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchCommonReportResponseExcelResetChanged = () => ({
	type: memberDetailsReportTypes.FETCH_COMMON_REPORT_RESPONSE_EXCEL_CHANGED,
});
/** ***************** FETCH COMMON EXCEL DETAILS END *********************** */
