/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import MemberRejectionReportActionTypes from './memberRejectionReport.types';

import { decodeBase64Response } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchMemberRejectionReportResponse: null,
	fetchMemberRejectionReportArr: [],
	fetchMemberRejectionReasoncountResponse: null,
	fetchMemberRejectionReasoncountArr: [],
	exportMemberRejectionReportResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_SUCCESS:
			return {
				...state,
				fetchMemberRejectionReportResponse: action.payload,
				fetchMemberRejectionReportArr: decodeBase64Response(
					action.payload,
					'MemberRejectionReportKey',
				),
			};

		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_FAILURE:
			return { ...state, fetchMemberRejectionReportResponse: action.payload };

		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				fetchMemberRejectionReportResponse: null,
			};
		case MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_SUCCESS:
			return {
				...state,
				exportMemberRejectionReportResponse: action.payload,
			};

		case MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_FAILURE:
			return { ...state, exportMemberRejectionReportResponse: action.payload };

		case MemberRejectionReportActionTypes.EXPORT_MEMBER_REJECTION_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				exportMemberRejectionReportResponse: null,
			};

		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_SUCCESS:
			return {
				...state,
				fetchMemberRejectionReasoncountResponse: action.payload,
				fetchMemberRejectionReasoncountArr: decodeBase64Response(
					action.payload,
					'MemberRejectionReasoncountKey',
				),
			};

		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_FAILURE:
			return { ...state, fetchMemberRejectionReasoncountResponse: action.payload };

		case MemberRejectionReportActionTypes.FETCH_MEMBER_REJECTION_REASON_COUNT_RESPONSE_CHANGED:
			return {
				...state,
				fetchMemberRejectionReasoncountResponse: null,
			};
		default:
			return state;
	}
};
