/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import QrReportActionTypes from './qr-report.types';

import { fetchQrReport, exportQrExcel, fetchParticularQrReport } from './qr-report.service';

import {
	fetchQrReportSuccess,
	fetchQrReportFailure,
	fetchQrReportResponseChanged,
	fetchParticularQrReportSuccess,
	fetchParticularQrReportFailure,
	fetchParticularQrReportResponseChanged,
	exportQrReportResponseChanged,
	exportQrReportSuccess,
	exportQrReportFailure,
} from './qr-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* fetchQrReportDetails({ payload }) {
	try {
		const key = 'QRPaymentReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchQrReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchQrReportFailure(responseData.data));
		} else {
			yield put(fetchQrReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchQrReportFailure(error));
	}
}

export function* resetFetchMRRReportResponse() {
	yield put(fetchQrReportResponseChanged());
}

export function* onFetchQrReport() {
	yield takeLatest(QrReportActionTypes.FETCH_QR_REPORT_START, fetchQrReportDetails);
}

export function* onFetchQrReportResponseReset() {
	yield takeLatest(
		QrReportActionTypes.FETCH_QR_REPORT_RESPONSE_RESET,
		resetFetchMRRReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* fetchParticularQrReportDetails({ payload }) {
	try {
		const key = 'ParticularQRPaymentReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			callBackID: payload.callBackID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchParticularQrReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchParticularQrReportFailure(responseData.data));
		} else {
			yield put(fetchParticularQrReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchParticularQrReportFailure(error));
	}
}

export function* resetFetchParticularQRReportResponse() {
	yield put(fetchParticularQrReportResponseChanged());
}

export function* onFetchParticularQrReport() {
	yield takeLatest(
		QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_START,
		fetchParticularQrReportDetails,
	);
}

export function* onFetchParticularQrReportResponseReset() {
	yield takeLatest(
		QrReportActionTypes.FETCH_PARTICULAR_QR_REPORT_RESPONSE_RESET,
		resetFetchParticularQRReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* exportQrReportDetails({ payload }) {
	try {
		const key = 'FetchQrRepaymentReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			Location: payload.centerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			RequestID: payload.requestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(exportQrExcel, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(exportQrReportFailure(responseData.data));
		} else {
			yield put(exportQrReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(exportQrReportFailure(error));
	}
}

export function* resetExportMRRReportResponse() {
	yield put(exportQrReportResponseChanged());
}

export function* onExportQrReport() {
	yield takeLatest(QrReportActionTypes.EXPORT_QR_REPORT_START, exportQrReportDetails);
}

export function* onExportQrReportResponseReset() {
	yield takeLatest(
		QrReportActionTypes.EXPORT_QR_REPORT_RESPONSE_RESET,
		resetExportMRRReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */

export function* qrReportSaga() {
	yield all([
		call(onFetchQrReport),
		call(onFetchQrReportResponseReset),
		call(onFetchParticularQrReport),
		call(onFetchParticularQrReportResponseReset),
		call(onExportQrReport),
		call(onExportQrReportResponseReset),
	]);
}
