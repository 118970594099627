/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import WalletReportActionTypes from './walletReport.types';

import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchGroupMemberResponse: null,
	fetchGroupMemberArr: [],

	fetchWalletReportResponse: null,
	fetchWalletReportArr: [],
	TotalWalletAdvanceAmount: 0,
	TotalWalletPayAmount: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case WalletReportActionTypes.FETCH_GROUP_MEMBER_SUCCESS:
			return {
				...state,
				fetchGroupMemberResponse: action.payload,
				fetchGroupMemberArr: docodeResponse(action.payload, 'GetGroupMemberListKey'),
			};

		case WalletReportActionTypes.FETCH_GROUP_MEMBER_FAILURE:
			return { ...state, fetchGroupMemberResponse: action.payload };

		case WalletReportActionTypes.FETCH_GROUP_MEMBER_RESPONSE_CHANGED:
			return {
				...state,
				fetchGroupMemberResponse: null,
			};

		case WalletReportActionTypes.FETCH_WALLET_REPORT_SUCCESS:
			return {
				...state,
				fetchWalletReportResponse: action.payload,
				fetchWalletReportArr: docodeResponse(
					action.payload,
					'GetWalletCollectionReportKey',
				),

				TotalWalletAdvanceAmount: action.payload.TotalWalletAdvanceAmount,
				TotalWalletPayAmount: action.payload.TotalWalletPayAmount,
			};

		case WalletReportActionTypes.FETCH_WALLET_REPORT_FAILURE:
			return { ...state, fetchWalletReportResponse: action.payload };

		case WalletReportActionTypes.FETCH_WALLET_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				fetchWalletReportResponse: null,
			};
		default:
			return state;
	}
};
