const DeadMemberDetailsActionTypes = {
	GET_DEAD_MEMBER_DETAILS_LIST_START: 'get_dead_member_details_start',
	GET_DEAD_MEMBER_DETAILS_LIST_SUCCESS: 'get_dead_member_details_success',
	GET_DEAD_MEMBER_DETAILS_LIST_FAILURE: 'get_dead_member_details_failure',
	GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_RESET: 'get_dead_member_details_response_reset',
	GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_CHANGED: 'get_dead_member_details_response_changed',

	GET_DEAD_MEMBER_DETAILS_EXPORT_START: 'get_dead_member_details_export_start',
	GET_DEAD_MEMBER_DETAILS_EXPORT_SUCCESS: 'get_dead_member_details_export_success',
	GET_DEAD_MEMBER_DETAILS_EXPORT_FAILURE: 'get_dead_member_details_export_failure',
	GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_RESET: 'get_dead_member_details_export_response_reset',
	GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_CHANGED:
		'get_dead_member_details_export_response_changed',
};
export default DeadMemberDetailsActionTypes;
