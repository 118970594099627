const LoanCardReportActionTypes = {
	GET_LOAN_CARD_REPORT_START: 'GET_LOAN_CARD_REPORT_START',
	GET_LOAN_CARD_REPORT_SUCCESS: 'GET_LOAN_CARD_REPORT_SUCCESS',
	GET_LOAN_CARD_REPORT_FAILURE: 'GET_LOAN_CARD_REPORT_FAILURE',
	GET_LOAN_CARD_REPORT_RESPONSE_RESET: 'GET_LOAN_CARD_REPORT_RESPONSE_RESET',
	GET_LOAN_CARD_REPORT_RESPONSE_CHANGED: 'GET_LOAN_CARD_REPORT_RESPONSE_CHANGED',

	GET_MEMBER_LOAN_CARD_START: 'GET_MEMBER_LOAN_CARD_START',
	GET_MEMBER_LOAN_CARD_SUCCESS: 'GET_MEMBER_LOAN_CARD_SUCCESS',
	GET_MEMBER_LOAN_CARD_FAILURE: 'GET_MEMBER_LOAN_CARD_FAILURE',
	GET_MEMBER_LOAN_CARD_RESPONSE_RESET: 'GET_MEMBER_LOAN_CARD_RESPONSE_RESET',
	GET_MEMBER_LOAN_CARD_RESPONSE_CHANGED: 'GET_MEMBER_LOAN_CARD_RESPONSE_CHANGED',

	GET_PARTICULAR_LOAN_CARD_START: 'GET_PARTICULAR_LOAN_CARD_START',
	GET_PARTICULAR_LOAN_CARD_SUCCESS: 'GET_PARTICULAR_LOAN_CARD_SUCCESS',
	GET_PARTICULAR_LOAN_CARD_FAILURE: 'GET_PARTICULAR_LOAN_CARD_FAILURE',
	GET_PARTICULAR_LOAN_CARD_RESPONSE_RESET: 'GET_PARTICULAR_LOAN_CARD_RESPONSE_RESET',
	GET_PARTICULAR_LOAN_CARD_RESPONSE_CHANGED: 'GET_PARTICULAR_LOAN_CARD_RESPONSE_CHANGED',

	FETCH_LOAN_CARD_EXCEL_START: 'FETCH_LOAN_CARD_EXCEL_START',
	FETCH_LOAN_CARD_EXCEL_SUCCESS: 'FETCH_LOAN_CARD_EXCEL_SUCCESS',
	FETCH_LOAN_CARD_EXCEL_FAILURE: 'FETCH_LOAN_CARD_EXCEL_FAILURE',
	FETCH_LOAN_CARD_EXCEL_RESET: 'FETCH_LOAN_CARD_EXCEL_RESET',
	FETCH_LOAN_CARD_EXCEL_CHANGED: 'FETCH_LOAN_CARD_EXCEL_CHANGED',

};
export default LoanCardReportActionTypes;
