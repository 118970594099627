/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BasicDetailsActionType from './basic-details.types';

// Otp Verify Mobile Number
export const basicDetailsAddStart = (basicDetailsCredentials) => ({
	type: BasicDetailsActionType.BASIC_DETAILS_ADD_START,
	payload: basicDetailsCredentials,
});

export const basicDetailsAddSuccess = (basicDetailsSuccess) => ({
	type: BasicDetailsActionType.BASIC_DETAILS_ADD_SUCCESS,
	payload: basicDetailsSuccess,
});

export const basicDetailsAddFailure = (basicDetailsFailure) => ({
	type: BasicDetailsActionType.BASIC_DETAILS_ADD_FAILURE,
	payload: basicDetailsFailure,
});

export const basicDetailsAddResponseResetStart = () => ({
	type: BasicDetailsActionType.BASIC_DETAILS_ADD_RESPONSE_REST_START,
});

export const basicDetailsAddUserResponseChanged = () => ({
	type: BasicDetailsActionType.BASIC_DETAILS_ADD_RESPONSE_CHANGED,
});

export const scanedAadharNumber = (aadharNumber) => ({
	type: BasicDetailsActionType.SCAN_AADHAR_NUMBER,
	payload: aadharNumber,
});

export const scanedAadharNumberReset = () => ({
	type: BasicDetailsActionType.SCAN_AADHAR_NUMBER_RESET,
});

export const scanAadharQrCodeData = (aadharScanData) => ({
	type: BasicDetailsActionType.AADHAR_QR_CODE_SCAN_DATA,
	payload: aadharScanData,
});

// TO SKIP MEMBER DETAILS SCREENS
export const skipMemberDetailsStart = (DetailsCredentials) => ({
	type: BasicDetailsActionType.SKIP_MEMBER_DETAILS_START,
	payload: DetailsCredentials,
});

export const skipMemberDetailsSuccess = (DetailsSuccess) => ({
	type: BasicDetailsActionType.SKIP_MEMBER_DETAILS_SUCCESS,
	payload: DetailsSuccess,
});

export const skipMemberDetailsFailure = (DetailsFailure) => ({
	type: BasicDetailsActionType.SKIP_MEMBER_DETAILS_FAILURE,
	payload: DetailsFailure,
});

export const skipMemberDetailsResponseResetStart = () => ({
	type: BasicDetailsActionType.SKIP_MEMBER_DETAILS_RESPONSE_RESET_START,
});

export const skipMemberDetailsResponseChanged = () => ({
	type: BasicDetailsActionType.SKIP_MEMBER_DETAILS_RESPONSE_CHANGED,
});

// TO VERIFY KYC DETAILS SCREENS
export const verifyKYCStart = (DetailsCredentials) => ({
	type: BasicDetailsActionType.VERIFY_KYC_DETAILS_START,
	payload: DetailsCredentials,
});

export const verifyKYCSuccess = (DetailsSuccess) => ({
	type: BasicDetailsActionType.VERIFY_KYC_DETAILS_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyKYCFailure = (DetailsFailure) => ({
	type: BasicDetailsActionType.VERIFY_KYC_DETAILS_FAILURE,
	payload: DetailsFailure,
});

export const verifyKYCResponseResetStart = () => ({
	type: BasicDetailsActionType.VERIFY_KYC_DETAILS_RESPONSE_RESET_START,
});

export const verifyKYCResponseChanged = () => ({
	type: BasicDetailsActionType.VERIFY_KYC_DETAILS_RESPONSE_CHANGED,
});

// TO UPDATE MEMBER COMPLETION
export const memberCompletionStart = (Credentials) => ({
	type: BasicDetailsActionType.MEMBER_COMPLETION_START,
	payload: Credentials,
});

export const memberCompletionSuccess = (Credentials) => ({
	type: BasicDetailsActionType.MEMBER_COMPLETION_SUCCESS,
	payload: Credentials,
});

export const memberCompletionFailure = (Credentials) => ({
	type: BasicDetailsActionType.MEMBER_COMPLETION_FAILURE,
	payload: Credentials,
});

export const memberCompletionResponseResetStart = () => ({
	type: BasicDetailsActionType.MEMBER_COMPLETION_RESPONSE_RESET_START,
});

export const memberCompletionResponseChanged = () => ({
	type: BasicDetailsActionType.MEMBER_COMPLETION_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR OTP DETAILS
export const verifyAadharOtpStart = (DetailsCredentials) => ({
	type: BasicDetailsActionType.VERIFY_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyAadharOtpSuccess = (DetailsSuccess) => ({
	type: BasicDetailsActionType.VERIFY_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyAadharOtpFailure = (DetailsFailure) => ({
	type: BasicDetailsActionType.VERIFY_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyAadharOtpResponseResetStart = () => ({
	type: BasicDetailsActionType.VERIFY_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyAadharOtpResponseChanged = () => ({
	type: BasicDetailsActionType.VERIFY_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR VALIDATE OTP DETAILS
export const verifyValidateAadharOtpStart = (DetailsCredentials) => ({
	type: BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyValidateAadharOtpSuccess = (DetailsSuccess) => ({
	type: BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyValidateAadharOtpFailure = (DetailsFailure) => ({
	type: BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyValidateAadharOtpResponseResetStart = () => ({
	type: BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyValidateAadharOtpResponseChanged = () => ({
	type: BasicDetailsActionType.VERIFY_VALIDATE_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY FETCH DETAILS
export const fetchVerificationDetailsStart = (DetailsCredentials) => ({
	type: BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_START,
	payload: DetailsCredentials,
});

export const fetchVerificationDetailsSuccess = (DetailsSuccess) => ({
	type: BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_SUCCESS,
	payload: DetailsSuccess,
});

export const fetchVerificationDetailsFailure = (DetailsFailure) => ({
	type: BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_FAILURE,
	payload: DetailsFailure,
});

export const fetchVerificationDetailsResponseResetStart = () => ({
	type: BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_RESPONSE_RESET_START,
});

export const fetchVerificationDetailsResponseChanged = () => ({
	type: BasicDetailsActionType.FETCH_VERIFICATION_DETAILS_RESPONSE_CHANGED,
});

export const kycRespDataReset = () => ({
	type: BasicDetailsActionType.KYC_RESPONSE_RESET,
});
