/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import CoApplicantActionType from './co-applicant.types';
import {
	updateCoApplicantSuccess,
	updateCoApplicantFailure,
	updateCoApplicantResponseChanged,
	addCoBorrowerResponseChanged,
	addCoBorrowerSuccess,
	addCoBorrowerFailure,
	fetchCoBorrowerResponseChanged,
	fetchCoBorrowerFailure,
	fetchCoBorrowerSuccess,
	deleteCoBorrowerResponseChanged,
	deleteCoBorrowerSuccess,
	deleteCoBorrowerFailure,
	checkCoBorrowerHighMarkSuccess,
	checkCoBorrowerHighMarkFailure,
	checkCoBorrowerHighMarkRespChanged,
	verifyCoApplicantAadharOtpResponseChanged,
	verifyCoApplicantAadharOtpFailure,
	verifyCoApplicantAadharOtpSuccess,
	verifyValidateCoApplicantAadharOtpResponseChanged,
	verifyValidateCoApplicantAadharOtpFailure,
	verifyValidateCoApplicantAadharOtpSuccess,
	fetchRelationshipFailure,
	fetchRelationshipSuccess,
	fetchRelationshipResponseChanged,
} from './co-applicant.action';
import { encodeJson } from '../../../enode-decode';
import {
	coApplicantAdd,
	coApplicantCrifAPI,
	coApplicantDelete,
	coApplicantFetch,
	coApplicantUpdate,
	verifyCoApplicantAadharOtpAPI,
	verifyValidateCoApplicantAadharOtpAPI,
} from './co-applicant.service';

// Start Co Applicant Details Add
export function* updateCoApplicantIdDetails({ payload }) {
	try {
		const key = 'updateCoApplicantID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			UserID: payload.userID,
			CoApplicantDetailsArr: payload.coApplicantsDetailsArr,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantUpdate, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCoApplicantFailure(responseData.data));
		} else {
			yield put(updateCoApplicantSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCoApplicantFailure(error));
	}
}

export function* updateCoApplicantResponseClear() {
	yield put(updateCoApplicantResponseChanged());
}

export function* onCoApplicantUpdateStart() {
	yield takeLatest(CoApplicantActionType.UPDATE_CO_APPLICANT_START, updateCoApplicantIdDetails);
}

export function* onCoApplicantUpdateResponseRest() {
	yield takeLatest(
		CoApplicantActionType.UPDATE_CO_APPLICANT_RESPONSE_REST_START,
		updateCoApplicantResponseClear,
	);
}
// End Co Applicant Details Add

// Start Co Borrower Details Add
export function* addCoBorrowerDetails({ payload }) {
	try {
		const key = 'updateCoApplicantID';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			UserID: payload.userID,
			CoApplicantAadharNumber: payload.coApplicantAadharNumber,
			CoApplicantAddressProof: payload.coApplicantAddressProof,
			CoApplicantAddressProofNumber: payload.coApplicantAddressProofNumber,
			CoApplicantDOB: payload.coApplicantDOB,
			CoApplicantIDProof: payload.coApplicantIDProof,
			CoApplicantIDProofNumber: payload.coApplicantIDProofNumber,
			CoApplicantMonthlyIncome: payload.coApplicantMonthlyIncome,
			CoApplicantName: payload.coApplicantName,
			CoApplicantOccupationSelectValue: payload.coApplicantOccupationSelectValue,
			CoApplicantPhoneNumber: payload.coApplicantPhoneNumber,
			CoApplicantProfileImg: payload.coApplicantProfileImg,
			CoApplicantRelationship: payload.coApplicantRelationship,
			CoApplicantFrontAadhar: payload.coApplicantFrontAadhar,
			CoApplicantBackAadhar: payload.coApplicantBackAadhar,
			CoApplicantFrontID: payload.coApplicantFrontID,
			coApplicantBackID: payload.coApplicantBackID,
			CoApplicantFrontAddress: payload.coApplicantFrontAddress,
			CoApplicantBackAddress: payload.coApplicantBackAddress,
			CoAppSignatureImg: payload.coAppSignatureImg,
			DoorNo: payload.doorNo,
			AddressStreet: payload.addressStreet,
			Country: payload.country,
			State: payload.state,
			District: payload.district,
			City: payload.city,
			Pincode: payload.pincode,
			CoApplicantGender: payload.memberGender,
			MaritalStatus: payload.maritalStatus,
			AppliedAmount: payload.appliedAmount,
			CoApplicantRelationshipWithMember: payload.relationshipwithMember,
			CoApplicantID: payload.coApplicantID,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantAdd, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(addCoBorrowerFailure(responseData.data));
		} else {
			yield put(addCoBorrowerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCoBorrowerFailure(error));
	}
}

export function* addCoApplicantResponseClear() {
	yield put(addCoBorrowerResponseChanged());
}

export function* onCoApplicantAddStart() {
	yield takeLatest(CoApplicantActionType.ADD_COBORROWER_START, addCoBorrowerDetails);
}

export function* onCoApplicantAddResponseRest() {
	yield takeLatest(
		CoApplicantActionType.ADD_COBORROWER_RESPONSE_RESET_START,
		addCoApplicantResponseClear,
	);
}

// Fetch Co Applicant Details Add
export function* fetchCoApplicantIdDetails({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CoApplicantID: payload.coApplicantID,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantFetch, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCoBorrowerFailure(responseData.data));
		} else {
			yield put(fetchCoBorrowerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCoBorrowerFailure(error));
	}
}

export function* fetchCoApplicantResponseClear() {
	yield put(fetchCoBorrowerResponseChanged());
}

export function* onCoApplicantFetchStart() {
	yield takeLatest(CoApplicantActionType.FETCH_COBORROWER_START, fetchCoApplicantIdDetails);
}

export function* onCoApplicantFetchResponseRest() {
	yield takeLatest(
		CoApplicantActionType.FETCH_COBORROWER_RESPONSE_RESET_START,
		fetchCoApplicantResponseClear,
	);
}
// End Co Applicant Details Add

// Start Co Applicant Details Delete
export function* deleteCoApplicantDetails({ payload }) {
	try {
		const key = 'DeleteCoBorrowerKey';
		const jsonData = {
			CoApplicantID: payload.coApplicantID,
			DatabaseName: payload.databaseName,
			MemberID: payload.coApplicantMemberID,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantDelete, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteCoBorrowerFailure(responseData.data));
		} else {
			yield put(deleteCoBorrowerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteCoBorrowerFailure(error));
	}
}

export function* deleteCoApplicantResponseClear() {
	yield put(deleteCoBorrowerResponseChanged());
}

export function* onCoApplicantDeleteStart() {
	yield takeLatest(CoApplicantActionType.DELETE_CO_APPLICANT_START, deleteCoApplicantDetails);
}

export function* onCoApplicantDeleteResponseRest() {
	yield takeLatest(
		CoApplicantActionType.DELETE_CO_APPLICANT_RESPONSE_REST_START,
		deleteCoApplicantResponseClear,
	);
}
// End Co Applicant Details Delete

// Start Co Applicant HighMark Check
export function* checkCoApplicantCrif({ payload }) {
	try {
		const key = 'CoBorrowerCrifCheckKey';
		const jsonData = {
			CoApplicantID: payload.coApplicantID,
			DatabaseName: payload.databaseName,
			MemberID: payload.coApplicantMemberID,
			User: payload.userID,
			CompanyID: payload.companyID,
			IsConsumer: payload.IsConsumer,
			AppliedAmount: payload.coApplicantLoanAppliedAmount,
			GeneralCompanyID: payload.generalCompanyID,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantCrifAPI, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(checkCoBorrowerHighMarkFailure(responseData.data));
		} else {
			yield put(checkCoBorrowerHighMarkSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkCoBorrowerHighMarkFailure(error));
	}
}

export function* CoApplicantCrifResponseClear() {
	yield put(checkCoBorrowerHighMarkRespChanged());
}

export function* onCoApplicantCrifStart() {
	yield takeLatest(CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_START, checkCoApplicantCrif);
}

export function* onCoApplicantCrifResponseRest() {
	yield takeLatest(
		CoApplicantActionType.CHECK_COBORROWER_HIGHMARK_RESPONSE_RESET_START,
		CoApplicantCrifResponseClear,
	);
}
// End Co Applicant HighMark Check

// Start verify Aadhar Otp
export function* toVerifyCoApplicantAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			MemberCredential: payload.memberCredential,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyCoApplicantAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyCoApplicantAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyCoApplicantAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyCoApplicantAadharOtpFailure(error));
	}
}

export function* VerifyCoApplicantAadharOtpResponseClear() {
	yield put(verifyCoApplicantAadharOtpResponseChanged());
}

export function* onVerifyCoApplicantAadharOtpStart() {
	yield takeLatest(
		CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_START,
		toVerifyCoApplicantAadharOtp,
	);
}

export function* onVerifyCoApplicantAadharOtpRest() {
	yield takeLatest(
		CoApplicantActionType.VERIFY_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyCoApplicantAadharOtpResponseClear,
	);
}
// End Verify Aadhar Otp

// Start verify validate Aadhar Otp
export function* toVerifyValidateCoApplicantAadharOtp({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			GeneralCompanyID: payload.generalCompanyID,
			RandNum: payload.randNum,
			Otp: payload.otp,
			Type: payload.type,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyValidateCoApplicantAadharOtpAPI,
			verifyData,
			payload.serviceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyValidateCoApplicantAadharOtpFailure(responseData.data));
		} else {
			yield put(verifyValidateCoApplicantAadharOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyValidateCoApplicantAadharOtpFailure(error));
	}
}

export function* VerifyValidateCoApplicantAadharOtpResponseClear() {
	yield put(verifyValidateCoApplicantAadharOtpResponseChanged());
}

export function* onVerifyValidateCoApplicantAadharOtpStart() {
	yield takeLatest(
		CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_START,
		toVerifyValidateCoApplicantAadharOtp,
	);
}

export function* onVerifyValidateCoApplicantAadharOtpRest() {
	yield takeLatest(
		CoApplicantActionType.VERIFY_VALIDATE_CO_APPLICANT_AADHAR_OTP_RESPONSE_RESET_START,
		VerifyValidateCoApplicantAadharOtpResponseClear,
	);
}
// End Verify validate Aadhar Otp

// Start Fetch Relationship Details
export function* fetchRelationshipBasedDetails({ payload }) {
	try {
		const key = 'FetchMemberDataKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			Relationship: payload.nomineeRelationship,
			Secratekey: key,
		};
		const coApplicantData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantFetch, coApplicantData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchRelationshipFailure(responseData.data));
		} else {
			yield put(fetchRelationshipSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchRelationshipFailure(error));
	}
}
export function* fetchRelationshipResponseClear() {
	yield put(fetchRelationshipResponseChanged());
}
export function* onFetchRelationshipDetailsStart() {
	yield takeLatest(
		CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_START,
		fetchRelationshipBasedDetails,
	);
}
export function* onFetchRelationshipDetailsResponseRest() {
	yield takeLatest(
		CoApplicantActionType.FETCH_DTEAILS_BASED_RELATIONSHIP_RESPONSE_RESET_START,
		fetchRelationshipResponseClear,
	);
}
// End Fetch Relationship Details

export function* coApplicantSaga() {
	yield all([
		call(onCoApplicantUpdateStart),
		call(onCoApplicantUpdateResponseRest),
		call(onCoApplicantAddStart),
		call(onCoApplicantAddResponseRest),
		call(onCoApplicantFetchStart),
		call(onCoApplicantFetchResponseRest),
		call(onCoApplicantDeleteStart),
		call(onCoApplicantDeleteResponseRest),
		call(onCoApplicantCrifStart),
		call(onCoApplicantCrifResponseRest),
		call(onVerifyCoApplicantAadharOtpStart),
		call(onVerifyCoApplicantAadharOtpRest),
		call(onVerifyValidateCoApplicantAadharOtpStart),
		call(onVerifyValidateCoApplicantAadharOtpRest),
		call(onFetchRelationshipDetailsStart),
		call(onFetchRelationshipDetailsResponseRest),
	]);
}
