/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import WorkFlowManagementActionTypes from './workflowManagement.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAllRoleListResponse: null,
	getAllRoleListArr: [],
	getAllQuestionnaireListResponse: null,
	getAllQuestionnaireListArr: [],
	addCreditAppraisalDetailsResponse: null,
	getAllCreditAppraisalListResponse: null,
	getAllCreditAppraisalListArr: [],
	deleteCreditAppraisalDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_SUCCESS:
			return {
				...state,
				getAllRoleListResponse: action.payload,
				getAllRoleListArr: docodeResponse(
					action.payload,
					'FetchAllRoleListForCreditAppraisalListKey',
				),
			};

		case WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_FAILURE:
			return { ...state, getAllRoleListResponse: action.payload };

		case WorkFlowManagementActionTypes.GET_ALL_ROLE_LIST_RESPONSE_CHANGED:
			return { ...state, getAllRoleListResponse: null };

		case WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_SUCCESS:
			return {
				...state,
				getAllQuestionnaireListResponse: action.payload,
				getAllQuestionnaireListArr: docodeResponse(
					action.payload,
					'GetAllQuestionnaireListKey',
				),
			};

		case WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_FAILURE:
			return { ...state, getAllQuestionnaireListResponse: action.payload };

		case WorkFlowManagementActionTypes.GET_ALL_QUESTIONNAIRE_LIST_RESPONSE_CHANGED:
			return { ...state, getAllQuestionnaireListResponse: null };

		case WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_SUCCESS:
			return { ...state, addCreditAppraisalDetailsResponse: action.payload };

		case WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_FAILURE:
			return { ...state, addCreditAppraisalDetailsResponse: action.payload };

		case WorkFlowManagementActionTypes.ADD_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, addCreditAppraisalDetailsResponse: null };

		case WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_SUCCESS:
			return {
				...state,
				getAllCreditAppraisalListResponse: action.payload,
				getAllCreditAppraisalListArr: docodeResponse(
					action.payload,
					'GetAllCreditAppraisalListKey',
				),
			};

		case WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_FAILURE:
			return { ...state, getAllCreditAppraisalListResponse: action.payload };

		case WorkFlowManagementActionTypes.GET_ALL_CREDIT_APPRAISAL_LIST_RESPONSE_CHANGED:
			return { ...state, getAllCreditAppraisalListResponse: null };

		case WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_SUCCESS:
			return { ...state, deleteCreditAppraisalDetailsResponse: action.payload };

		case WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_FAILURE:
			return { ...state, deleteCreditAppraisalDetailsResponse: action.payload };

		case WorkFlowManagementActionTypes.DELETE_CREDIT_APPRAISAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteCreditAppraisalDetailsResponse: null };

		default:
			return state;
	}
};
