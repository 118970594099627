const loginActionTypes = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	SIGN_IN_LOGOUT: 'SING_IN_LOGOUT',
	SIGN_IN_CHANGED: 'SIGN_IN_CHANGED',
	SIGN_IN_RESET: 'SIGN_IN_RESET',

	FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
	FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
	FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
	FORGOT_PASSWORD_CHANGED: 'FORGOT_PASSWORD_CHANGED',
	FORGOT_PASSWORD_RESET: 'FORGOT_PASSWORD_RESET',

	SIGN_IN_COMPANYID: 'SIGN_IN_COMPANYID',
	SIGN_IN_USER: 'SIGN_IN_USER',
	SIGN_IN_USERTYPE: 'SIGN_IN_USERTYPE',

	// PASSWORD UPDATE SECTION
	GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
	GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
	GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
	GET_USER_DETAILS_CHANGED: 'GET_USER_DETAILS_CHANGED',
	GET_USER_DETAILS_RESET: 'GET_USER_DETAILS_RESET',

	// PASSWORD UPDATE SECTION
	UPDATE_USER_PASSWORD_START: 'UPDATE_USER_PASSWORD_START',
	UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
	UPDATE_USER_PASSWORD_FAILURE: 'UPDATE_USER_PASSWORD_FAILURE',
	UPDATE_USER_PASSWORD_CHANGED: 'UPDATE_USER_PASSWORD_CHANGED',
	UPDATE_USER_PASSWORD_RESET: 'UPDATE_USER_PASSWORD_RESET',

	ADD_PERSIST_LOGIN_DATA_START: 'add_persist_login_data_start',
	ADD_EXIST_VERSION_DETAILS: 'add_exist_version_details',
};
export default loginActionTypes;
