const QrReportActionTypes = {
	FETCH_QR_REPORT_START: 'fetch_qr_report_Start',
	FETCH_QR_REPORT_SUCCESS: 'fetch_qr_report_Success',
	FETCH_QR_REPORT_FAILURE: 'fetch_qr_report_Failure',
	FETCH_QR_REPORT_RESPONSE_RESET: 'fetch_qr_report_ResponseReset',
	FETCH_QR_REPORT_RESPONSE_CHANGED: 'fetch_qr_report_ResponseChanged',

	FETCH_PARTICULAR_QR_REPORT_START: 'fetch_particular_qr_report_Start',
	FETCH_PARTICULAR_QR_REPORT_SUCCESS: 'fetch_particular_qr_report_Success',
	FETCH_PARTICULAR_QR_REPORT_FAILURE: 'fetch_particular_qr_report_Failure',
	FETCH_PARTICULAR_QR_REPORT_RESPONSE_RESET: 'fetch_particular_qr_report_ResponseReset',
	FETCH_PARTICULAR_QR_REPORT_RESPONSE_CHANGED: 'fetch_particular_qr_report_ResponseChanged',

	EXPORT_QR_REPORT_START: 'export_qr_report_Start',
	EXPORT_QR_REPORT_SUCCESS: 'export_qr_report_Success',
	EXPORT_QR_REPORT_FAILURE: 'export_qr_report_Failure',
	EXPORT_QR_REPORT_RESPONSE_RESET: 'export_qr_report_ResponseReset',
	EXPORT_QR_REPORT_RESPONSE_CHANGED: 'export_qr_report_ResponseChanged',
};
export default QrReportActionTypes;
