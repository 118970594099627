// eslint-disable-next-line import/no-unresolved
import BasicDetailsActionType from './bank-details.types';

export const bankCreateStart = (bankCredentils) => ({
	type: BasicDetailsActionType.BANK_DETAILS_CREATE_START,
	payload: bankCredentils,
});

export const bankCreateSuccess = (bank) => ({
	type: BasicDetailsActionType.BANK_DETAILS_CREATE_SUCCESS,
	payload: bank,
});

export const bankCreateFailure = (error) => ({
	type: BasicDetailsActionType.BANK_DETAILS_CREATE_FAILURE,
	payload: error,
});

export const bankResponseChanged = () => ({
	type: BasicDetailsActionType.BANK_DETAILS_CREATE_RESPONSE_CHANGED,
});

export const bankResponseReset = () => ({
	type: BasicDetailsActionType.BANK_DETAILS_CREATE_RESPONSE_REST_START,
});

export const bankIFSCcodeStart = (IFSCcredentails) => ({
	type: BasicDetailsActionType.BANK_IFSC_CODE_RESPONSE_START,
	payload: IFSCcredentails,
});

export const bankIFSCcodeSuccess = (IFSC) => ({
	type: BasicDetailsActionType.BANK_IFSC_CODE_RESPONSE_SUCCESS,
	payload: IFSC,
});

export const bankIFSCcodeFailure = (IFSC) => ({
	type: BasicDetailsActionType.BANK_IFSC_CODE_RESPONSE_FAILURE,
	payload: IFSC,
});

export const bankIFSCcodeChanged = () => ({
	type: BasicDetailsActionType.BANK_IFSC_CODE_RESPONSE_CHANGED,
});

export const bankIFSCcodeReset = () => ({
	type: BasicDetailsActionType.BANK_IFSC_CODE_RESPONSE_RESET,
});
