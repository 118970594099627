const CenterWiseLoanProposalActionTypes = {
	ADD_CENTER_WISE_LOAN_PROPOSAL_START: 'ADD_CENTER_WISE_LOAN_PROPOSAL_START',
	ADD_CENTER_WISE_LOAN_PROPOSAL_SUCCESS: 'ADD_CENTER_WISE_LOAN_PROPOSAL_SUCCESS',
	ADD_CENTER_WISE_LOAN_PROPOSAL_FAILURE: 'ADD_CENTER_WISE_LOAN_PROPOSAL_FAILURE',
	ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET: 'ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET',
	ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'ADD_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED',

	UPDATE_CENTER_WISE_LOAN_PROPOSAL_START: 'UPDATE_CENTER_WISE_LOAN_PROPOSAL_START',
	UPDATE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS: 'UPDATE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS',
	UPDATE_CENTER_WISE_LOAN_PROPOSAL_FAILURE: 'UPDATE_CENTER_WISE_LOAN_PROPOSAL_FAILURE',
	UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET:
		'UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET',
	UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'UPDATE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED',

	GET_CENTER_WISE_LOAN_PROPOSAL_LIST_START: 'GET_CENTER_WISE_LOAN_PROPOSAL_LIST_START',
	GET_CENTER_WISE_LOAN_PROPOSAL_LIST_SUCCESS: 'GET_CENTER_WISE_LOAN_PROPOSAL_LIST_SUCCESS',
	GET_CENTER_WISE_LOAN_PROPOSAL_LIST_FAILURE: 'GET_CENTER_WISE_LOAN_PROPOSAL_LIST_FAILURE',
	GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_RESET:
		'GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_RESET',
	GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
		'GET_CENTER_WISE_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED',

	FETCH_CENTER_WISE_LOAN_PROPOSAL_START: 'FETCH_CENTER_WISE_LOAN_PROPOSAL_START',
	FETCH_CENTER_WISE_LOAN_PROPOSAL_SUCCESS: 'FETCH_CENTER_WISE_LOAN_PROPOSAL_SUCCESS',
	FETCH_CENTER_WISE_LOAN_PROPOSAL_FAILURE: 'FETCH_CENTER_WISE_LOAN_PROPOSAL_FAILURE',
	FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET:
		'FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET',
	FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'FETCH_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED',

	APPROVE_CENTER_WISE_PROPOSAL_START: 'APPROVE_CENTER_WISE_PROPOSAL_START',
	APPROVE_CENTER_WISE_PROPOSAL_SUCCESS: 'APPROVE_CENTER_WISE_PROPOSAL_SUCCESS',
	APPROVE_CENTER_WISE_PROPOSAL_FAILURE: 'APPROVE_CENTER_WISE_PROPOSAL_FAILURE',
	APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_RESET_START:
		'APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_RESET_START',
	APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_CHANGED: 'APPROVE_CENTER_WISE_PROPOSAL_RESPONSE_CHANGED',

	DELETE_CENTER_WISE_LOAN_PROPOSAL_START: 'DELETE_CENTER_WISE_LOAN_PROPOSAL_START',
	DELETE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS: 'DELETE_CENTER_WISE_LOAN_PROPOSAL_SUCCESS',
	DELETE_CENTER_WISE_LOAN_PROPOSAL_FAILURE: 'DELETE_CENTER_WISE_LOAN_PROPOSAL_FAILURE',
	DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET:
		'DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_RESET',
	DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'DELETE_CENTER_WISE_LOAN_PROPOSAL_RESPONSE_CHANGED',

	FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_START: 'FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_START',
	FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_SUCCESS: 'FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_SUCCESS',
	FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_FAILURE: 'FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_FAILURE',
	FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_RESET:
		'FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_RESET',
	FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_CHANGED:
		'FETCH_MEMBERS_LIST_FOR_CENTER_LOAN_RESPONSE_CHANGED',
};
export default CenterWiseLoanProposalActionTypes;
