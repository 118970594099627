/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import loginActionTypes from './login.types';
import { decodeApiResponse, docodeResponse } from '../common/common.utils';
import generalTypesAction from '../genera.types';
import { getCurrencyList, UserAccessDataUpdate } from './login.utils';

const INITIAL_STATE = {
	loginStatus: '',
	loginResponse: null,
	loginDeocodedResponse: null,
	currencySymbols: '',
	forgotPasswordResponse: null,
	forgotPasswordDeocodedResponse: null,
	getUserDetailsStatus: null,
	getUserDetailsResponse: [],
	updateUserPasswordStatus: null,
	updateUserPasswordResponse: [],
	loginWhiteListStorage: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loginActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				loginResponse: action.payload,
				loginStatus: 'Success',
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
				versionDeocodedResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
				userPasswordResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
				currencySymbols: getCurrencyList('INR'),
			};

		case loginActionTypes.SIGN_IN_FAILURE:
			return {
				...state,
				loginResponse: action.payload,
				loginStatus: null,
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
			};

		case loginActionTypes.LOGIN_USER_RESPONSE_CHANGED:
			return {
				...state,
				loginResponse: null,
				loginStatus: null,
				loginDeocodedResponse: null,
			};

		case loginActionTypes.ADD_PERSIST_LOGIN_DATA_START:
			return { ...state, loginDeocodedResponse: action.payload };

		case loginActionTypes.SIGN_IN_CHANGED:
			return {
				...state,
				loginStatus: null,
				loginResponse: null,
				loginDeocodedResponse: null,
				// isLogin: 0,
				// companyID: null,
				// userID: null,
				// userType: null,
			};

		case loginActionTypes.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				forgotPasswordResponse: action.payload,
				forgotPasswordDeocodedResponse: decodeApiResponse(
					action.payload.response,
					'FORGOTPASSWORDKEY',
				),
			};

		case loginActionTypes.FORGOT_PASSWORD_FAILURE:
			return {
				...state,
				forgotPasswordResponse: action.payload,
			};

		case loginActionTypes.FORGOT_PASSWORD_CHANGED:
			return {
				...state,
				forgotPasswordResponse: null,
				forgotPasswordDeocodedResponse: null,
			};

		case loginActionTypes.SIGN_IN_LOGOUT:
			return { ...state, INITIAL_STATE };

		// USER DETAILS SECTION
		case loginActionTypes.GET_USER_DETAILS_SUCCESS:
			return {
				...state,
				getUserDetailsStatus: 'SUCCESS',
				getUserDetailsResponse: docodeResponse(action.payload, 'GETUSERDETAILSKEY'),
				loginDeocodedResponse: UserAccessDataUpdate(
					decodeApiResponse(action.payload.response, 'GETUSERDETAILSKEY'),
					state.loginDeocodedResponse,
				),
			};

		case loginActionTypes.GET_USER_DETAILS_FAILURE:
			return {
				...state,
				getUserDetailsStatus: 'FAILURE',
			};

		case loginActionTypes.GET_USER_DETAILS_CHANGED:
			return {
				...state,
				getUserDetailsStatus: null,
			};

		// USER PASSWORD SECTION
		case loginActionTypes.UPDATE_USER_PASSWORD_SUCCESS:
			return {
				...state,
				updateUserPasswordStatus: 'SUCCESS',
				updateUserPasswordResponse: docodeResponse(action.payload, 'UPDATEUSERPASSWORDKEY'),
			};

		case loginActionTypes.UPDATE_USER_PASSWORD_FAILURE:
			return {
				...state,
				updateUserPasswordStatus: 'FAILURE',
			};

		case loginActionTypes.UPDATE_USER_PASSWORD_CHANGED:
			return {
				...state,
				updateUserPasswordStatus: null,
			};

		case loginActionTypes.ADD_EXIST_VERSION_DETAILS:
			return {
				...state,
				versionDeocodedResponse: action.payload,
			};

		default:
			return state;
	}
};

export default userReducer;
