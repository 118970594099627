/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const purposeofLoanDetailsAdd = (addData) => {
	const data = {
		AddPurposeOfLoanDetailsToken: addData,
	};
	const url = `${API_URL}addPurposeOfLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const purposeofLoanDetailsUpdate = (updateData) => {
	const data = {
		UpdatePurposeOfLoanDetailsToken: updateData,
	};
	const url = `${API_URL}updatePurposeOfLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const purposeofLoanListGet = (listData) => {
	const data = {
		GetPurposeOfLoanListToken: listData,
	};
	const url = `${API_URL}getPurposeOfLoanList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const purposeofLoanDetailsFetch = (fetchData) => {
	const data = {
		FetchPurposeOfLoanDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchPurposeOfLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const purposeofLoanDetailsDelete = (deleteData) => {
	const data = {
		DeletePurposeOfLoanDetailsToken: deleteData,
	};
	const url = `${API_URL}deletePurposeOfLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const purposeOfLoanIsPublishUpdate = (updateData) => {
	const data = {
		UpdatePurposeOfLoanPublishStatusToken: updateData,
	};
	const url = `${API_URL}updatePurposeOfLoanPublishStatusToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// PURPOSE OF LOAN CATEGORY
export const getPurposeOfLoanCategoryList = (Data) => {
	const data = {
		PurposeOfLoanCategoryListToken: Data,
	};
	const url = `${API_URL}GetPurposeOfLoanCategory`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addPurposeOfLoanCategory = (Data) => {
	const data = {
		PurposeOfLoanCategoryCreateToken: Data,
	};
	const url = `${API_URL}AddPurposeOfLoanCategory`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAllPurposeOfLoanType = (Data) => {
	const data = {
		FetchAllPurposeOfLoanTypeListData: Data,
	};
	const url = `${API_URL}fetchAllPurposeOfLoanTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
