/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import AddressExtraProofActionType from './address-extra-proof.types';

const INITIAL_STATE = {
	updateAddressExtraProofResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_SUCCESS:
			return { ...state, updateAddressExtraProofResponse: action.payload };

		case AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_FAILURE:
			return { ...state, updateAddressExtraProofResponse: action.payload };

		case AddressExtraProofActionType.UPDATE_ADDRESS_EXTRA_PROOF_RESPONSE_CHANGED:
			return { ...state, updateAddressExtraProofResponse: null };

		default:
			return state;
	}
};
