/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import HouseDetailsActionType from './house-details.types';
import {
	houseCreateSuccess,
	houseCreateFailure,
	houseResponseChanged,
} from './house-details.action';
import { encodeJson } from '../../../enode-decode';
import { houseMemberDetailsAdd } from './house-details.service';

// Start House Details Add
export function* AddHouseDetails({ payload }) {
	try {
		const key = 'AddMemberHouseDetails';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			HouseType: payload.houseType,
			LandValue: payload.landValue,
			LandSize: payload.landSize,
			DurationOfStay: payload.durationOfStay,
			AssetsType: payload.assetsType,
			NoOfRooms: payload.noOfRooms,
			FacilityType: payload.facilityType,
			HavingAgriculturalLand: payload.havingAgriculturalLand,
			AgriculturalLandValue: payload.agriculturalLandValue,
			IncomeFromAgriculturalLand: payload.incomeFromAgriculturalLand,
			HavingLivestock: payload.havingLivestock,
			IncomeFromLiveStock: payload.incomeFromLiveStock,
			OfficerID: payload.userID,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(houseMemberDetailsAdd, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(houseCreateFailure(responseData.data));
		} else {
			yield put(houseCreateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(houseCreateFailure(error));
	}
}

export function* AddHouseDetailsResponseClear() {
	yield put(houseResponseChanged());
}

export function* onHouseDetailsAddStart() {
	yield takeLatest(HouseDetailsActionType.HOUSE_DETAILS_CREATE_START, AddHouseDetails);
}

export function* onHouseDetailsAddResponseRest() {
	yield takeLatest(
		HouseDetailsActionType.HOUSE_DETAILS_CREATE_RESPONSE_REST_START,
		AddHouseDetailsResponseClear,
	);
}
// End House Details Add

export function* houseDetailsSaga() {
	yield all([call(onHouseDetailsAddStart), call(onHouseDetailsAddResponseRest)]);
}
