/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchGroupMemberList = (branchData) => {
	const data = {
		GroupMemberList: branchData,
	};
	const url = `${API_URL}GetGroupMemberListWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchPostRepaymentCollection = (branchData) => {
	const data = {
		FetchRepayMembersListToken: branchData,
	};
	const url = `${API_URL}RepaymentCollectionSimpleWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchPostRepaymentCollectionExcel = (branchData) => {
	const params = new URLSearchParams();
	params.append('FetchRepayMembersListToken', branchData);
	const url = `${API_URL}Report/Excel/RepaymentReportSimpleMemberWiseWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
