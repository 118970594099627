const memberDetailsReportTypes = {
	FETCH_MEMBER_DETAILS_REPORT_START: 'FETCH_MEMBER_DETAILS_REPORT_START',
	FETCH_MEMBER_DETAILS_REPORT_SUCCESS: 'FETCH_MEMBER_DETAILS_REPORT_SUCCESS',
	FETCH_MEMBER_DETAILS_REPORT_FAILURE: 'FETCH_MEMBER_DETAILS_REPORT_FAILURE',
	FETCH_MEMBER_DETAILS_REPORT_RESPONSE_RESET_START:
		'FETCH_MEMBER_DETAILS_REPORT_RESPONSE_RESET_START',
	FETCH_MEMBER_DETAILS_REPORT_RESPONSE_CHANGED: 'FETCH_MEMBER_DETAILS_REPORT_RESPONSE_CHANGED',

	FETCH_MEMBER_DETAILS_REPORT_EXCEL_START: 'fetchMemberDetailsReportExcelStart',
	FETCH_MEMBER_DETAILS_REPORT_EXCEL_SUCCESS: 'fetchMemberDetailsReportExcelSuccess',
	FETCH_MEMBER_DETAILS_REPORT_EXCEL_FAILURE: 'fetchMemberDetailsReportExcelFailure',
	FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_RESET_START:
		'fetchMemberDetailsReportResponsExceleResetStart',
	FETCH_MEMBER_DETAILS_REPORT_RESPONSE_EXCEL_CHANGED:
		'fetchMemberDetailsReportResponseExcelResetChanged',

	FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_START: 'fetchMemberExperianReportExcelStart',
	FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_SUCCESS: 'fetchMemberExperianReportExcelSuccess',
	FETCH_MEMBER_EXPERIAN_REPORT_EXCEL_FAILURE: 'fetchMemberExperianReportExcelFailure',
	FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_RESET_START:
		'fetchMemberExperianReportResponsExceleResetStart',
	FETCH_MEMBER_EXPERIAN_REPORT_RESPONSE_EXCEL_CHANGED:
		'fetchMemberExperianReportResponseExcelResetChanged',

	FETCH_HIGH_MARK_REPORT_EXCEL_START: 'fetchHighMarkReportExcelStart',
	FETCH_HIGH_MARK_REPORT_EXCEL_SUCCESS: 'fetchHighMarkReportExcelSuccess',
	FETCH_HIGH_MARK_REPORT_EXCEL_FAILURE: 'fetchHighMarkReportExcelFailure',
	FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_RESET_START: 'fetchHighMarkReportResponsExceleResetStart',
	FETCH_HIGH_MARK_REPORT_RESPONSE_EXCEL_CHANGED: 'fetchHighMarkReportResponseExcelResetChanged',

	FETCH_COMMON_REPORT_EXCEL_START: 'fetchCommonReportExcelStart',
	FETCH_COMMON_REPORT_EXCEL_SUCCESS: 'fetchCommonReportExcelSuccess',
	FETCH_COMMON_REPORT_EXCEL_FAILURE: 'fetchCommonReportExcelFailure',
	FETCH_COMMON_REPORT_RESPONSE_EXCEL_RESET_START: 'fetchCommonReportResponsExceleResetStart',
	FETCH_COMMON_REPORT_RESPONSE_EXCEL_CHANGED: 'fetchCommonReportResponseExcelResetChanged',
};
export default memberDetailsReportTypes;
