/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import StaffActionTypes from './staff.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addStaffDetailsResponse: null,
	updateStaffDetailsResponse: null,
	getStaffListResponse: null,
	getStaffListArr: [],
	TotalStaffCount: 0,
	fetchStaffDetailsResponse: null,
	fetchStaffDetailsArr: [],
	deleteStaffDetailsResponse: null,
	updateIsPublishStatus: null,
	fetchAllStaffsResponse: null,
	fetchAllStaffsArr: [],
	exportStaffListResp: null,
	exportStaffListArr: [],
	updateIsPrintStaff: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case StaffActionTypes.ADD_STAFF_DETAILS_SUCCESS:
			return { ...state, addStaffDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_DETAILS_FAILURE:
			return { ...state, addStaffDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, addStaffDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_SUCCESS:
			return { ...state, updateStaffDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_FAILURE:
			return { ...state, updateStaffDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffDetailsResponse: null };

		case StaffActionTypes.GET_STAFF_LIST_SUCCESS:
			return {
				...state,
				getStaffListResponse: action.payload,
				getStaffListArr: docodeResponse(action.payload, 'GetStaffBasedOnCompanyKey'),
			};

		case StaffActionTypes.GET_STAFF_LIST_FAILURE:
			return { ...state, getStaffListResponse: action.payload };

		case StaffActionTypes.GET_STAFF_LIST_RESPONSE_CHANGED:
			return { ...state, getStaffListResponse: null };

		case StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS:
			return {
				...state,
				fetchStaffDetailsResponse: action.payload,
				fetchStaffDetailsArr: docodeResponse(action.payload, 'FetchStaffDetailsKey'),
			};

		case StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE:
			return { ...state, fetchStaffDetailsResponse: action.payload };

		case StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchStaffDetailsResponse: null, fetchStaffDetailsArr: [] };

		case StaffActionTypes.DELETE_STAFF_DETAILS_SUCCESS:
			return { ...state, deleteStaffDetailsResponse: action.payload };

		case StaffActionTypes.DELETE_STAFF_DETAILS_FAILURE:
			return { ...state, deleteStaffDetailsResponse: action.payload };

		case StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteStaffDetailsResponse: null };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_SUCCESS:
			return { ...state, updateIsPublishStatus: action.payload };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_FAILURE:
			return { ...state, updateIsPublishStatus: action.payload };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED:
			return { ...state, updateIsPublishStatus: null };

		case StaffActionTypes.FETCH_ALL_STAFF_SUCCESS:
			return {
				...state,
				fetchAllStaffsResponse: action.payload,
				fetchAllStaffsArr: docodeResponse(action.payload, 'GetBranchListKey'),
			};

		case StaffActionTypes.FETCH_ALL_STAFF_FAILURE:
			return { ...state, fetchAllStaffsResponse: action.payload };

		case StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_CHANGED:
			return { ...state, fetchAllStaffsResponse: null };

		case StaffActionTypes.EXPORT_STAFF_LIST_SUCCESS:
			return {
				...state,
				exportStaffListResp: action.payload,
				exportStaffListArr: docodeResponse(action.payload, 'AllStaffReportExcelKey'),
			};

		case StaffActionTypes.EXPORT_STAFF_LIST_FAILURE:
			return { ...state, exportStaffListResp: action.payload };

		case StaffActionTypes.EXPORT_STAFF_LIST_RESPONSE_CHANGED:
			return { ...state, exportStaffListResp: null };

		case StaffActionTypes.UPDATE_ISPRINT_STAFF_SUCCESS:
			return { ...state, updateIsPrintStaff: action.payload };

		case StaffActionTypes.UPDATE_ISPRINT_STAFF_FAILURE:
			return { ...state, updateIsPrintStaff: action.payload };

		case StaffActionTypes.UPDATE_ISPRINT_STAFF_RESPONSE_CHANGED:
			return { ...state, updateIsPrintStaff: null };

		case StaffActionTypes.GET_ALL_STAFF_LIST_EXPORT_SUCCESS:
			return {
				...state,
				getAllStaffListExportResponse: action.payload,
			};

		case StaffActionTypes.GET_ALL_STAFF_LIST_EXPORT_FAILURE:
			return { ...state, getAllStaffListExportResponse: action.payload };

		case StaffActionTypes.GET_ALL_STAFF_LIST_EXPORT_RESPONSE_CHANGED:
			return { ...state, getAllStaffListExportResponse: null };

		default:
			return state;
	}
};
