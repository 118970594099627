/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import VerifyOtpActionType from './verify-otp.types';
import {
	verifyMemberNumberOtpSuccess,
	verifyMemberNumberOtpFailure,
	verifyMemberNumberOtpUserResponseChanged,
	reSendOtpSuccess,
	reSendOtpFailure,
	reSendOtpResponseChanged,
} from './verify-otp.action';
import { encodeJson } from '../../../enode-decode';
import { addMemberMobileNumberVeriyOtp, verifyResendOtp } from './verify-otp.service';

// Verify Member OTP
export function* verifyOtpMemberPhoneNumber({ payload }) {
	try {
		const key = 'AddMemberOtpVerify';
		const jsonData = {
			VerifyOtp: payload.verifyOtp,
			UserID: payload.userID,
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const verifyData = encodeJson(jsonData, key);
		const responseData = yield call(addMemberMobileNumberVeriyOtp, verifyData);
		if (responseData.data.statusCode === '100') {
			yield put(verifyMemberNumberOtpFailure(responseData.data));
		} else {
			yield put(verifyMemberNumberOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyMemberNumberOtpFailure(error));
	}
}

export function* verifyMemberOtpResponseClear() {
	yield put(verifyMemberNumberOtpUserResponseChanged());
}

export function* onVeriyMemberOptStart() {
	yield takeLatest(
		VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_START,
		verifyOtpMemberPhoneNumber,
	);
}

export function* onVeriyMemberOptResponseRest() {
	yield takeLatest(
		VerifyOtpActionType.VERIFY_MEMBER_NUMBER_OTP_RESPONSE_REST_START,
		verifyMemberOtpResponseClear,
	);
}

// START RE SEND OTP

export function* verifyOtpReSendRequest({ payload }) {
	try {
		const key = 'ReSendOtp';
		const jsonData = {
			PhoneNumber: payload.phoneNumber,
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
			MemberCompanyID: payload.companyID,
			Secratekey: key,
		};
		const resendOtpData = encodeJson(jsonData, key);
		const responseData = yield call(verifyResendOtp, resendOtpData);
		if (responseData.data.statusCode === '100') {
			yield put(reSendOtpFailure(responseData.data));
		} else {
			yield put(reSendOtpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(reSendOtpFailure(error));
	}
}

export function* resetReSendOtpStates() {
	yield put(reSendOtpResponseChanged());
}

export function* onVerifyOtpReSendStart() {
	yield takeLatest(VerifyOtpActionType.RE_SEND_OTP_START, verifyOtpReSendRequest);
}

export function* onReSendOtpResponseRest() {
	yield takeLatest(
		VerifyOtpActionType.RE_SEND_OTP_START_RESPONSE_RESET_START,
		resetReSendOtpStates,
	);
}

export function* verifyOtpSaga() {
	yield all([
		call(onVeriyMemberOptStart),
		call(onVeriyMemberOptResponseRest),
		call(onVerifyOtpReSendStart),
		call(onReSendOtpResponseRest),
	]);
}
