const LoanActionTypes = {
	FETCH_ALL_PRODUCT_LOAN_PROPOSAL_START: 'fetch_all_product_loan_proposal_start',
	FETCH_ALL_PRODUCT_LOAN_PROPOSAL_SUCCESS: 'fetch_all_product_loan_proposal_success',
	FETCH_ALL_PRODUCT_LOAN_PROPOSAL_FAILURE: 'fetch_all_product_loan_proposal_failure',
	FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET:
		'fetch_all_product_loan_proposal_response_reset',
	FETCH_ALL_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'fetch_all_product_loan_proposal_response_changed',

	FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_START: 'fetch_particular_product_loan_proposal_start',
	FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_SUCCESS:
		'fetch_particular_product_loan_proposal_success',
	FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_FAILURE:
		'fetch_particular_product_loan_proposal_failure',
	FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET:
		'fetch_particular_product_loan_proposal_response_reset',
	FETCH_PARTICULAR_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'fetch_particular_product_loan_proposal_response_changed',

	FETCH_WHOLE_GROUP_MEMBER_START: 'fetch_whole_group_member_start',
	FETCH_WHOLE_GROUP_MEMBER_SUCCESS: 'fetch_whole_group_member_success',
	FETCH_WHOLE_GROUP_MEMBER_FAILURE: 'fetch_whole_group_member_failure',
	FETCH_WHOLE_GROUP_MEMBER_RESPONSE_RESET: 'fetch_whole_group_member_response_reset',
	FETCH_WHOLE_GROUP_MEMBER_RESPONSE_CHANGED: 'fetch_whole_group_member_response_changed',

	ADD_PRODUCT_LOAN_PROPOSAL_START: 'add_product_loan_proposal_start',
	ADD_PRODUCT_LOAN_PROPOSAL_SUCCESS: 'add_product_loan_proposal_success',
	ADD_PRODUCT_LOAN_PROPOSAL_FAILURE: 'add_product_loan_proposal_failure',
	ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_RESET: 'add_product_loan_proposal_response_reset',
	ADD_PRODUCT_LOAN_PROPOSAL_RESPONSE_CHANGED: 'add_product_loan_proposal_response_changed',
};
export default LoanActionTypes;
