/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import ArrearFollowupActionTypes from './arrear-followup-report.types';

/** ***************** GET LOAN DISBURSEMENT START *********************** */
export const getArrearFollowupDetailsStart = (getData) => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_START,
	payload: getData,
});

export const getArrearFollowupDetailsSuccess = (successData) => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_SUCCESS,
	payload: successData,
});

export const getArrearFollowupDetailsFailure = (failureData) => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_FAILURE,
	payload: failureData,
});

export const getArrearFollowupDetailsResponseResetStart = () => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_RESET,
});

export const getArrearFollowupDetailsResponseChanged = () => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_CHANGED,
});

export const getArrearFollowupDetailsResponseArrayChanged = () => ({
	type: ArrearFollowupActionTypes.GET_ARREAR_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED,
});
/** ***************** GET ARREAR FOLLOWUP END *********************** */
