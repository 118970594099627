/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DeadMemberDetailsActionTypes from './dead-member-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getDeadMemberDetailsListResponse: null,
	getDeadMemberDetailsListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_SUCCESS:
			return {
				...state,
				getDeadMemberDetailsListResponse: action.payload,
				getDeadMemberDetailsListArr: docodeResponse(
					action.payload,
					'GetDeadMemberReportKey',
				),
			};

		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_FAILURE:
			return { ...state, getDeadMemberDetailsListResponse: action.payload };

		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_LIST_RESPONSE_CHANGED:
			return { ...state, getDeadMemberDetailsListResponse: null };

		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_SUCCESS:
			return { ...state, exportDeadMemberDetailsResponse: action.payload };

		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_FAILURE:
			return { ...state, exportDeadMemberDetailsListResponse: action.payload };

		case DeadMemberDetailsActionTypes.GET_DEAD_MEMBER_DETAILS_EXPORT_RESPONSE_CHANGED:
			return { ...state, exportDeadMemberDetailsListResponse: null };
		default:
			return state;
	}
};
