// eslint-disable-next-line import/no-unresolved
import NomineeDetailsActionType from './basic-nominee-details.types';

export const nomineeDetailsDataStart = (saveData) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_CHANGED,
	payload: saveData,
});

export const nomineeDetailsAddStart = (nomineeDetailsCredentials) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_START,
	payload: nomineeDetailsCredentials,
});

export const nomineeDetailsAddSuccess = (nomineeDetailsSuccess) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_SUCCESS,
	payload: nomineeDetailsSuccess,
});

export const nomineeDetailsAddFailure = (nomineeDetailsFailure) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_FAILURE,
	payload: nomineeDetailsFailure,
});

export const nomineeDetailsAddResponseResetStart = () => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_RESPONSE_REST_START,
});

export const nomineeDetailsAddUserResponseChanged = () => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADD_RESPONSE_CHANGED,
});

export const nomineeAddressListStart = (nomineeAddressCredentials) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_START,
	payload: nomineeAddressCredentials,
});

export const nomineeAddressListSuccess = (nomineeAddressSuccess) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_SUCCESS,
	payload: nomineeAddressSuccess,
});

export const nomineeAddressListFailure = (nomineeAddressFailure) => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_FAILURE,
	payload: nomineeAddressFailure,
});

export const nomineeAddressListResponseResetStart = () => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_REST_START,
});

export const nomineeAddressListUserResponseChanged = () => ({
	type: NomineeDetailsActionType.NOMINEE_DETAILS_ADDRESS_LIST_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR OTP DETAILS
export const verifyNomineeAadharOtpStart = (DetailsCredentials) => ({
	type: NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyNomineeAadharOtpSuccess = (DetailsSuccess) => ({
	type: NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyNomineeAadharOtpFailure = (DetailsFailure) => ({
	type: NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyNomineeAadharOtpResponseResetStart = () => ({
	type: NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyNomineeAadharOtpResponseChanged = () => ({
	type: NomineeDetailsActionType.VERIFY_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED,
});

// TO VERIFY AADHAR VALIDATE OTP DETAILS
export const verifyValidateNomineeAadharOtpStart = (DetailsCredentials) => ({
	type: NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_START,
	payload: DetailsCredentials,
});

export const verifyValidateNomineeAadharOtpSuccess = (DetailsSuccess) => ({
	type: NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_SUCCESS,
	payload: DetailsSuccess,
});

export const verifyValidateNomineeAadharOtpFailure = (DetailsFailure) => ({
	type: NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_FAILURE,
	payload: DetailsFailure,
});

export const verifyValidateNomineeAadharOtpResponseResetStart = () => ({
	type: NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_RESET_START,
});

export const verifyValidateNomineeAadharOtpResponseChanged = () => ({
	type: NomineeDetailsActionType.VERIFY_VALIDATE_NOMINEE_AADHAR_OTP_RESPONSE_CHANGED,
});

// Fetch nominee relationship
export const fetchNomineeRelationshipStart = (nomineeDetailsCredentials) => ({
	type: NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_START,
	payload: nomineeDetailsCredentials,
});

export const fetchNomineeRelationshipSuccess = (nomineeDetailsSuccess) => ({
	type: NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_SUCCESS,
	payload: nomineeDetailsSuccess,
});

export const fetchNomineeRelationshipFailure = (nomineeDetailsFailure) => ({
	type: NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_FAILURE,
	payload: nomineeDetailsFailure,
});

export const fetchNomineeRelationshipResponseResetStart = () => ({
	type: NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_RESET_START,
});

export const fetchNomineeRelationshipResponseChanged = () => ({
	type: NomineeDetailsActionType.FETCH_NOMINEE_BASED_RELATIONSHIP_RESPONSE_CHANGED,
});
