const LoanDisbursementActionTypes = {
	FETCH_PARTICULAR_LOAN_INDEX_DETAILS_START: 'fetch_particular_loan_index_details_start',
	FETCH_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS: 'fetch_particular_loan_index_details_success',
	FETCH_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE: 'fetch_particular_loan_index_details_failure',
	FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET:
		'fetch_particular_loan_index_details_response_reset',
	FETCH_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED:
		'fetch_particular_loan_index_details_response_changed',

	DELETE_PARTICULAR_LOAN_INDEX_DETAILS_START: 'delete_particular_loan_index_details_start',
	DELETE_PARTICULAR_LOAN_INDEX_DETAILS_SUCCESS: 'delete_particular_loan_index_details_success',
	DELETE_PARTICULAR_LOAN_INDEX_DETAILS_FAILURE: 'delete_particular_loan_index_details_failure',
	DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_RESET: 'delete_particular_loan_index_details_response_reset',
	DELETE_PARTICULAR_LOAN_INDEX_DETAILS_RESPONSE_CHANGED: 'delete_particular_loan_index_details_response_changed',

	FETCH_FINANCE_ACCOUNTS_START: 'fetch_finance_accounts_start',
	FETCH_FINANCE_ACCOUNTS_SUCCESS: 'fetch_finance_accounts_success',
	FETCH_FINANCE_ACCOUNTS_FAILURE: 'fetch_finance_accounts_failure',
	FETCH_FINANCE_ACCOUNTS_RESPONSE_RESET: 'fetch_finance_accounts_response_reset',
	FETCH_FINANCE_ACCOUNTS_RESPONSE_CHANGED: 'fetch_finance_accounts_response_changed',

	FETCH_HOLIDAY_LIST_START: 'fetch_holiday_list_start',
	FETCH_HOLIDAY_LIST_SUCCESS: 'fetch_holiday_list_success',
	FETCH_HOLIDAY_LIST_FAILURE: 'fetch_holiday_list_failure',
	FETCH_HOLIDAY_LIST_RESPONSE_RESET: 'fetch_holiday_list_response_reset',
	FETCH_HOLIDAY_LIST_RESPONSE_CHANGED: 'fetch_holiday_list_response_changed',

	FETCH_LOAN_SCHEDULE_LIST_START: 'fetch_schedule_list_start',
	FETCH_LOAN_SCHEDULE_LIST_SUCCESS: 'fetch_schedule_list_success',
	FETCH_LOAN_SCHEDULE_LIST_FAILURE: 'fetch_schedule_list_failure',
	FETCH_LOAN_SCHEDULE_LIST_RESPONSE_RESET: 'fetch_schedule_list_response_reset',
	FETCH_LOAN_SCHEDULE_LIST_RESPONSE_CHANGED: 'fetch_schedule_list_response_changed',

	UPDATE_LOANINDEX_FUNDER_START: 'update_loanindex_funder_start',
	UPDATE_LOANINDEX_FUNDER_SUCCESS: 'update_loanindex_funder_success',
	UPDATE_LOANINDEX_FUNDER_FAILURE: 'update_loanindex_funder_failure',
	UPDATE_LOANINDEX_FUNDER_RESPONSE_RESET: 'update_loanindex_funder_response_reset',
	UPDATE_LOANINDEX_FUNDER_RESPONSE_CHANGED: 'update_loanindex_funder_response_changed',

	UPDATE_LOANINDEX_MEETTIME_START: 'update_loanindex_meettime_start',
	UPDATE_LOANINDEX_MEETTIME_SUCCESS: 'update_loanindex_meettime_success',
	UPDATE_LOANINDEX_MEETTIME_FAILURE: 'update_loanindex_meettime_failure',
	UPDATE_LOANINDEX_MEETTIME_RESPONSE_RESET: 'update_loanindex_meettime_response_reset',
	UPDATE_LOANINDEX_MEETTIME_RESPONSE_CHANGED: 'update_loanindex_meettime_response_changed',

	// POL => Purpose Of Loan
	UPDATE_LOANINDEX_POL_START: 'update_loanindex_pol_start',
	UPDATE_LOANINDEX_POL_SUCCESS: 'update_loanindex_pol_success',
	UPDATE_LOANINDEX_POL_FAILURE: 'update_loanindex_pol_failure',
	UPDATE_LOANINDEX_POL_RESPONSE_RESET: 'update_loanindex_pol_response_reset',
	UPDATE_LOANINDEX_POL_RESPONSE_CHANGED: 'update_loanindex_pol_response_changed',

	// POL => Purpose Of Loan
	CREATE_LOANINDEX_DISBURSEMENT_START: 'create_loanindex_disbursement_start',
	CREATE_LOANINDEX_DISBURSEMENT_SUCCESS: 'create_loanindex_disbursement_success',
	CREATE_LOANINDEX_DISBURSEMENT_FAILURE: 'create_loanindex_disbursement_failure',
	CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_RESET: 'create_loanindex_disbursement_response_reset',
	CREATE_LOANINDEX_DISBURSEMENT_RESPONSE_CHANGED: 'create_loanindex_disbursement_response_changed',

	CREATE_TRANSACTION_DISBURSEMENT_START: 'create_transaction_disbursement_start',
	CREATE_TRANSACTION_DISBURSEMENT_SUCCESS: 'create_transaction_disbursement_success',
	CREATE_TRANSACTION_DISBURSEMENT_FAILURE: 'create_transaction_disbursement_failure',
	CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_RESET: 'create_transaction_disbursement_response_reset',
	CREATE_TRANSACTION_DISBURSEMENT_RESPONSE_CHANGED: 'create_transaction_disbursement_response_changed',

	CREATE_TRANSACTION_ADDITIONAL_CHARGES_START: 'create_transaction_additional_charges_start',
	CREATE_TRANSACTION_ADDITIONAL_CHARGES_SUCCESS: 'create_transaction_additional_charges_success',
	CREATE_TRANSACTION_ADDITIONAL_CHARGES_FAILURE: 'create_transaction_additional_charges_failure',
	CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_RESET: 'create_transaction_additional_charges_response_reset',
	CREATE_TRANSACTION_ADDITIONAL_CHARGES_RESPONSE_CHANGED: 'create_transaction_additional_charges_response_changed',

    FETCH_NEXT_TRANSACTION_SERIAL_NO_START: 'fetch_next_transaction_serial_no_start',
	FETCH_NEXT_TRANSACTION_SERIAL_NO_SUCCESS: 'fetch_next_transaction_serial_no_success',
	FETCH_NEXT_TRANSACTION_SERIAL_NO_FAILURE: 'fetch_next_transaction_serial_no_failure',
	FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_RESET: 'fetch_next_transaction_serial_no_response_reset',
	FETCH_NEXT_TRANSACTION_SERIAL_NO_RESPONSE_CHANGED: 'fetch_next_transaction_serial_no_response_changed',

	FETCH_PRODUCT_ACCOUNT_DETAILS_START: 'fetch_product_account_details_start',
	FETCH_PRODUCT_ACCOUNT_DETAILS_SUCCESS: 'fetch_product_account_details_success',
	FETCH_PRODUCT_ACCOUNT_DETAILS_FAILURE: 'fetch_product_account_details_failure',
	FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_RESET: 'fetch_product_account_details_response_reset',
	FETCH_PRODUCT_ACCOUNT_DETAILS_RESPONSE_CHANGED: 'fetch_product_account_details_response_changed',

    FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_START: 'fetch_voucher_count_based_on_company_start',
	FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_SUCCESS: 'fetch_voucher_count_based_on_company_success',
	FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_FAILURE: 'fetch_voucher_count_based_on_company_failure',
	FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_RESET: 'fetch_voucher_count_based_on_company_response_reset',
	FETCH_VOUCHER_COUNT_BASED_ON_COMPANY_RESPONSE_CHANGED: 'fetch_voucher_count_based_on_company_response_changed',

	FETCH_INTER_BRANCH_CONTRA_START: 'fetch_inter_branch_contra_account_start',
	FETCH_INTER_BRANCH_CONTRA_SUCCESS: 'fetch_inter_branch_contra_account_success',
	FETCH_INTER_BRANCH_CONTRA_FAILURE: 'fetch_inter_branch_contra_account_failure',
	FETCH_INTER_BRANCH_CONTRA_RESPONSE_RESET: 'fetch_inter_branch_contra_account_response_reset',
	FETCH_INTER_BRANCH_CONTRA_RESPONSE_CHANGED: 'fetch_inter_branch_contra_account_response_changed',

	FETCH_LOAN_DISBURSEMENT_PDF_START: 'fetch_loan_disbursement_pdf_start',
	FETCH_LOAN_DISBURSEMENT_PDF_SUCCESS: 'fetch_loan_disbursement_pdf_success',
	FETCH_LOAN_DISBURSEMENT_PDF_FAILURE: 'fetch_loan_disbursement_pdf_failure',
	FETCH_LOAN_DISBURSEMENT_PDF_RESPONSE_RESET: 'fetch_loan_disbursement_pdf_response_reset',
	FETCH_LOAN_DISBURSEMENT_PDF_RESPONSE_CHANGED: 'fetch_loan_disbursement_pdf_response_changed',

	FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_START: 'fetch_all_product_additional_account_start',
	FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_SUCCESS: 'fetch_all_product_additional_account_success',
	FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_FAILURE: 'fetch_all_product_additional_account_failure',
	FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_RESET: 'fetch_all_product_additional_account_response_reset',
	FETCH_ALL_PRODUCT_ADDITIONAL_ACCOUNT_RESPONSE_CHANGED: 'fetch_all_product_additional_account_response_changed',

	FETCH_ALL_RANGE_PRODUCT_START: 'fetch_all_range_product_start',
	FETCH_ALL_RANGE_PRODUCT_SUCCESS: 'fetch_all_range_product_success',
	FETCH_ALL_RANGE_PRODUCT_FAILURE: 'fetch_all_range_product_failure',
	FETCH_ALL_RANGE_PRODUCT_RESPONSE_RESET: 'fetch_all_range_product_response_reset',
	FETCH_ALL_RANGE_PRODUCT_RESPONSE_CHANGED: 'fetch_all_range_product_response_changed',

	CREATE_TOPUP_LOAN_INDEX_START: 'create_topup_loanindex_start',
	CREATE_TOPUP_LOAN_INDEX_SUCCESS: 'create_topup_loanindex_success',
	CREATE_TOPUP_LOAN_INDEX_FAILURE: 'create_topup_loanindex_failure',
	CREATE_TOPUP_LOAN_INDEX_RESPONSE_RESET: 'create_topup_loanindex_response_reset',
	CREATE_TOPUP_LOAN_INDEX_RESPONSE_CHANGED: 'create_topup_loanindex_response_changed',
};
export default LoanDisbursementActionTypes;
