import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.sagas';
import { teamSaga } from './operations/team/team.sagas';
import { staffSaga } from './operations/staff/staff.sagas';
import { staffListSaga } from './operations/staffAllList/staffAllList.sagas';
import { executiveSaga } from './operations/executive/executive.sagas';
import { repaymentCollectionReportSaga } from './reports/RepaymentCollectionReport/RepaymentCollectionReport.sagas';
import { progressReportSaga } from './reports/ProgressReport/ProgressReport.sagas';

// General Saga
import { generalSaga } from './general/general.saga';

import { assignUserRightsMasterSaga } from './userControlAccess/assignUserRights/assignUserRights.saga';
import { memberMandatoryAccessMasterSaga } from './userControlAccess/memberMandatoryAccess/memberMandatoryAccess.saga';

// Business Saga
import { businessDetailsSaga } from './operations/business/business-details/business-details.sagas';
import { branchDetailsSaga } from './operations/business/branch-details/branch-details.sagas';
import { partnerDetailsSaga } from './operations/business/partner-details/partner-details.sagas';
import { bankDetailsSaga } from './operations/business/bank-details/bank-details.sagas';
import { proofDetailsSaga } from './operations/business/proof-details/proof-details.sagas';
import { verificationDetailsSaga } from './operations/business/verification-details/verification-details.sagas';
import { partiesDetailsSaga } from './operations/business/related-parties/related-parties.sagas';

// Masters
import { branchMasterSaga } from './masters/branch/branch.saga';
import { memberMasterSaga } from './masters/member/member.saga';
import { verifyOtpSaga } from './masters/member-add/verify-otp/verify-otp.saga';
import { basicDetailsSaga } from './masters/member-add/basic-details/basic-details.saga';
import { addMemberPhotoSaga } from './masters/member-add/photo-verification/photo-verification.saga';
import { signatureSaga } from './masters/member-add/signature/signature.saga';
import { personalDetailsSaga } from './masters/member-add/personal-details/personal-details.saga';
import { familyDetailSaga } from './masters/member-add/family-details/family-details.saga';
import { nomineeDetailsSaga } from './masters/member-add/basic-nominee-details/basic-nominee-details.saga';
import { memberBankDetailsSaga } from './masters/member-add/bank-details/bank-details.saga';
import { houseDetailsSaga } from './masters/member-add/house-details/house-details.saga';
import { aadharDetailsSaga } from './masters/member-add/aadhar-details/aadhar-details.saga';
import { drivingLicenceSaga } from './masters/member-add/driving-licence-details/driving-licence-details.saga';
import { pancardDetailsSaga } from './masters/member-add/pancard-details/pancard-details.saga';
import { passportSaga } from './masters/member-add/passport-details/passport-details.saga';
import { rationCardDetailsSaga } from './masters/member-add/ration-card-details/ration-card-details.saga';
import { voterIdDetailsSaga } from './masters/member-add/voter-id-details/voter-id-details.saga';
import { addressExtraProofSaga } from './masters/member-add/address-extra-proof/address-extra-proof.saga';
import { passbookChequeDetailsSaga } from './masters/member-add/passbook-cheque-details/passbook-cheque-details.saga';
import { housePhotosSaga } from './masters/member-add/house-photos/house-photos.saga';
import { extraInformationSaga } from './masters/member-add/extra-infromation/extra-information.saga';
import { officialDetailsSaga } from './masters/member-add/official-details/official-details.saga';
import { nocPhotosSaga } from './masters/member-add/noc-photos/noc-photos.saga';
import { currentAddressSaga } from './masters/member-add/current-address/current-address.saga';
import { residentialDetailsSaga } from './masters/member-add/residential-details/residential-details.saga';
import { coApplicantSaga } from './masters/member-add/co-applicant/co-applicant.saga';
import { creditCheckDetailsSaga } from './masters/member-add/credit-check/credit-check.saga';
import { memberDetailsSaga } from './masters/member-add/add-member/add-member.saga';
import { centerMasterSaga } from './masters/center/center.saga';
import { roleMasterSaga } from './masters/role/role.saga';
import { loanMasterSaga } from './masters/loanApplication/loanApplication.saga';
import { staffMasterSaga } from './masters/staff/staff.saga';
import { groupMasterSaga } from './masters/group/group.saga';
import { thriftMasterSaga } from './masters/thrift/thrift.saga';
import { productTypeMasterSaga } from './masters/product-type/product-type.saga';
import { insuranceCompanySaga } from './masters/insurance-company/insurance-company.saga';
import { productMasterSaga } from './masters/product/product.saga';
import { economicActivityTypeMasterSaga } from './masters/economic-activity-type/economic-activity-type.saga';
import { economicActivityMasterSaga } from './masters/economic-activity/economic-activity.saga';
import { funderMasterSaga } from './masters/funder/funder.saga';
import { businessfundMasterSaga } from './masters/business-fund/business-fund.saga';
import { questionnaireSaga } from './masters/questionnaire/questionnaire.saga';
import { purposeofLoanMasterSaga } from './masters/purpose-of-loan/purposeofloan.saga';

// Operations - Collection
import { collectionSaga } from './operations/collection/collection.saga';
import { memberCollectionSaga } from './operations/collection/memberCollection/memberCollection.saga';
import { advanceCollectionSaga } from './operations/collection/advanceCollection/advanceCollection.saga';
import { todayCollectionSaga } from './operations/todayCollection/todayCollection.saga';
import { demandCollectionSaga } from './operations/collection/demandCollection/demandCollection.saga';
import { arrearCollectionSaga } from './operations/collection/arrearCollection/arrearCollection.saga';
import { CloseLoanMemberSaga } from './operations/closeLoan/closeLoan.saga';
import { PreCloseLoanMemberSaga } from './operations/preCloseLoan/preCloseLoan.saga';
import { ChangeGroupOfficerSaga } from './operations/changeGroupOfficer/changeGroupOfficer.saga';
import { reportAuditingToolSaga } from './operations/auditing-tool/auditing-tool.saga';
import { ChangeMeetingDateSaga } from './operations/ChangeMeetingDate/changeMeetingDate.saga';
import { EndOfDaySaga } from './operations/endOfDay/endOfDay.saga';
import { ReconciliationForQRMemberSaga } from './operations/reconciliationForQR/reconciliationForQR.saga';

// Business Loan Proposal
import { businessLoanProposalSaga } from './operations/loan-proposal/business-loan-proposal/business-loan-proposal.saga';

// Center Wise Loan Proposal
import { centerWiseLoanProposalSaga } from './operations/loan-proposal/centerWiseLoanProposal/centerWiseLoanProposal.saga';

// Gold Loan Proposal
import { goldLoanProposalSaga } from './operations/loan-proposal/goldLoanProposal/goldLoanProposal.saga';

// Product Loan Proposal
import { productLoanProposalSaga } from './operations/loan-proposal/product-loan-proposal/product-loan-proposal.saga';

// Custom Product Loan Proposal
import { customproductLoanProposalSaga } from './operations/loan-proposal/custom-product-loan-proposal/custom-product-loan-proposal.saga';

// Range Loan Proposal
import { rangeLoanProposalSaga } from './operations/loan-proposal/range-loan-proposal/range-loan-proposal.saga';

// Auto Loan Proposal
// import { autoLoanProposalSaga } from './loan-proposal/auto-loan-proposal/autoloanproposal.saga'

// Business Loan Disbursement
import { businessLoanDisbursementSaga } from './operations/loan-disbursement/business-loan-disbursment/business-loan-disbmt.saga';
import { loanIndexDisbursementSaga } from './operations/loan-disbursement/loan-index-disbursement/loan-index-disbursement.saga';

// Reports
import { tatReportSaga } from './reports/tat-report/tat-report.saga';
import { requestReportSaga } from './reports/requestReport/requestReport.saga';
import { attendanceReportSaga } from './reports/attendanceReport/attendanceReport.saga';
import { reportProgressSaga } from './reports/reportProgress/reportProgress.saga';
import { monthlyProgressSaga } from './reports/monthlyProgress/monthlyProgress.saga';
import { branchReportSaga } from './reports/branchReport/branchReport.saga';
import { branchProfileReportSaga } from './reports/branchProfileReport/branchProfileReport.saga';
import { loanCardReportSaga } from './reports/loanCardReport/loanCardReport.saga';
import { accountDayBookReportSaga } from './reports/accountDayBookReport/accountDayBookReport.saga';
import { postAuditReportSaga } from './reports/postauditingReport/postauditingReport.saga';
import { reportPrecloseSaga } from './reports/preclose-loan/preclose-loan.saga';
import { reportClosedLoanSaga } from './reports/closed-loan-details/closed-loan-details.saga';
import { walletReportSaga } from './reports/walletReport/walletReport.saga';
import { branchCollectionReportSaga } from './reports/branchCollectionReport/branchCollectionReport.saga';
import { branchDCBReportSaga } from './reports/branchDCBReport/branchDCBReport.saga';
import { dailyCollectionReportSaga } from './reports/dailyCollectionReport/dailyCollectionReport.saga';
import { futureDemandReportSaga } from './reports/future-demand-report/future-demand-report.saga';
import { reportDemandCollectionSaga } from './reports/demand-collection-report/demand-collection-report.saga';
import { reportDemandFollowupSaga } from './reports/demand-followup-report/demand-followup-report.saga';
import { reportArrearFollowupSaga } from './reports/arrear-followup-report/arrear-followup-report.saga';
import { regularDemandReportSaga } from './reports/regular-demand-report/regular-demand-report.saga';
import { sanctionReportSaga } from './reports/sanction-report/sanction-report.saga';
import { kycSourcedReportSaga } from './reports/kyc-sourced-report/kyc-sourced-report.saga';
import { insuranceReportSaga } from './reports/insurance-report/insurance-report.saga';
import { LTVReportSaga } from './reports/ltv-report/ltv-report.saga';
import { NPAReportSaga } from './reports/npa-report/npa-report.saga';
import { portfolioAtRiskReportSaga } from './reports/portflioAtRistReport/portflioAtRiskReport.saga';
import { portfolioReportSaga } from './reports/portfolioReport/portfolioReport.saga';
import { reportActiveMemberSaga } from './reports/active-member/active-member.saga';
import { reportDataVerificationSaga } from './reports/data-verification/data-verification.saga';
import { reportTrainingToolSaga } from './reports/training/training.sagas';
import { reportChangeMeetingSaga } from './reports/change-meeting/change-meeting.saga';
import { reportInActiveMemberSaga } from './reports/in-active-member/in-active-member.saga';
import { reportMonthWiseInterestSaga } from './reports/MonthWiseInterestReport/MonthWiseInterestReport.saga';
import { reportLoanDisbursementSaga } from './reports/loan-disbursement/loan-disbursement.saga';
import { overDueReportSaga } from './reports/overDueReport/overDueReport.sagas';
import { auditTrailReportSaga } from './reports/auditTrailReport/auditTrailReport.saga';
import { StaffReportSaga } from './reports/staffReport/staffReport.saga';
import { memberRejectionReportSaga } from './reports/memberRejectionReport/memberRejectionReport.saga';
import { qrReportSaga } from './reports/qr-report/qr-report.saga';
import { branchCBIReportSaga } from './reports/creditBureauReport/creditBureauReport.saga';
import { memberDetailsReportSaga } from './reports/member-details-report/member-details-report.saga';
import { LeadReportSaga } from './reports/leadReport/leadReport.saga';
import { reportDeadMemberSaga } from './reports/deadMemberReport/dead-member-report.saga';

// verifications

import { kYCVerificationSaga } from './operations/business/kyc-verification/kyc-verification.saga';

// PROFILE DETAILS

import { profileSaga } from './profile/profile.sagas';

// Dashboard DETAILS
import { dashboardSaga } from './Dashboard/Dashboard.sagas';
import { highmarkRulesSaga } from './userControlAccess/highmarkRules/highmarkRules.saga';
import { workflowManagementSaga } from './userControlAccess/workflowManagement/workflowManagement.saga';
import { ruleEngineSaga } from './userControlAccess/ruleEngine/ruleEngine.saga';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(teamSaga),
		call(staffSaga),
		call(staffListSaga),
		call(executiveSaga),
		call(repaymentCollectionReportSaga),
		call(progressReportSaga),
		call(overDueReportSaga),
		call(generalSaga),
		call(assignUserRightsMasterSaga),
		call(memberMandatoryAccessMasterSaga),
		call(businessDetailsSaga),
		call(branchDetailsSaga),
		call(partnerDetailsSaga),
		call(bankDetailsSaga),
		call(proofDetailsSaga),
		call(verificationDetailsSaga),
		call(partiesDetailsSaga),
		call(branchMasterSaga),
		call(memberMasterSaga),
		call(verifyOtpSaga),
		call(basicDetailsSaga),
		call(addMemberPhotoSaga),
		call(signatureSaga),
		call(personalDetailsSaga),
		call(familyDetailSaga),
		call(nomineeDetailsSaga),
		call(memberBankDetailsSaga),
		call(houseDetailsSaga),
		call(aadharDetailsSaga),
		call(drivingLicenceSaga),
		call(pancardDetailsSaga),
		call(passportSaga),
		call(rationCardDetailsSaga),
		call(voterIdDetailsSaga),
		call(addressExtraProofSaga),
		call(passbookChequeDetailsSaga),
		call(housePhotosSaga),
		call(extraInformationSaga),
		call(officialDetailsSaga),
		call(nocPhotosSaga),
		call(currentAddressSaga),
		call(residentialDetailsSaga),
		call(coApplicantSaga),
		call(creditCheckDetailsSaga),
		call(memberDetailsSaga),
		call(centerMasterSaga),
		call(loanMasterSaga),
		call(roleMasterSaga),
		call(staffMasterSaga),
		call(groupMasterSaga),
		call(thriftMasterSaga),
		call(productTypeMasterSaga),
		call(insuranceCompanySaga),
		call(productMasterSaga),
		call(economicActivityTypeMasterSaga),
		call(economicActivityMasterSaga),
		call(businessfundMasterSaga),
		call(questionnaireSaga),
		call(tatReportSaga),
		call(requestReportSaga),
		call(attendanceReportSaga),
		call(reportProgressSaga),
		call(collectionSaga),
		call(todayCollectionSaga),
		call(memberCollectionSaga),
		call(advanceCollectionSaga),
		call(businessLoanProposalSaga),
		call(centerWiseLoanProposalSaga),
		call(businessLoanDisbursementSaga),
		call(goldLoanProposalSaga),
		call(productLoanProposalSaga),
		call(customproductLoanProposalSaga),
		call(rangeLoanProposalSaga),
		call(funderMasterSaga),
		call(purposeofLoanMasterSaga),
		call(monthlyProgressSaga),
		call(branchReportSaga),
		call(branchProfileReportSaga),
		call(loanCardReportSaga),
		call(accountDayBookReportSaga),
		call(postAuditReportSaga),
		call(reportPrecloseSaga),
		call(reportClosedLoanSaga),
		call(walletReportSaga),
		call(branchCollectionReportSaga),
		call(branchDCBReportSaga),
		call(dailyCollectionReportSaga),
		call(futureDemandReportSaga),
		call(reportDemandCollectionSaga),
		call(reportDemandFollowupSaga),
		call(reportArrearFollowupSaga),
		call(regularDemandReportSaga),
		call(sanctionReportSaga),
		call(kycSourcedReportSaga),
		call(insuranceReportSaga),
		call(LTVReportSaga),
		call(reportActiveMemberSaga),
		call(reportDataVerificationSaga),
		call(reportTrainingToolSaga),
		call(reportAuditingToolSaga),
		call(reportChangeMeetingSaga),
		call(reportInActiveMemberSaga),
		call(NPAReportSaga),
		call(portfolioAtRiskReportSaga),
		call(portfolioReportSaga),
		call(reportMonthWiseInterestSaga),
		call(reportLoanDisbursementSaga),
		call(kYCVerificationSaga),
		call(auditTrailReportSaga),
		call(StaffReportSaga),
		call(memberRejectionReportSaga),
		call(qrReportSaga),
		call(branchCBIReportSaga),
		call(demandCollectionSaga),
		call(arrearCollectionSaga),
		call(profileSaga),
		call(memberDetailsReportSaga),
		call(LeadReportSaga),
		call(CloseLoanMemberSaga),
		call(PreCloseLoanMemberSaga),
		call(ChangeGroupOfficerSaga),
		call(ChangeMeetingDateSaga),
		call(EndOfDaySaga),
		call(dashboardSaga),
		call(highmarkRulesSaga),
		call(workflowManagementSaga),
		call(ruleEngineSaga),
		call(loanIndexDisbursementSaga),
		call(ReconciliationForQRMemberSaga),
		call(reportDeadMemberSaga),
	]);
}
