const demandCollectionActionTypes = {
	GET_DEMAND_LOAN_LIST_START: 'GET_DEMAND_MEMBER_LOAN_LIST_START',
	GET_DEMAND_LOAN_LIST_SUCCESS: 'GET_DEMAND_MEMBER_LOAN_LIST_SUCCESS',
	GET_DEMAND_LOAN_LIST_FAILURE: 'GET_DEMAND_MEMBER_LOAN_LIST_FAILURE',
	GET_DEMAND_LOAN_LIST_RESPONSE_RESET: 'GET_DEMAND_MEMBER_LOAN_LIST_RESPONSE_RESET',
	GET_DEMAND_LOAN_LIST_RESPONSE_CHANGED: 'GET_DEMAND_MEMBER_LOAN_LIST_RESPONSE_CHANGED',

	UPDATE_DEMAND_COLLECTION_REVERT_START: 'UPDATE_DEMAND_COLLECTION_REVERT_START',
	UPDATE_DEMAND_COLLECTION_REVERT_SUCCESS: 'UPDATE_DEMAND_COLLECTION_REVERT_SUCCESS',
	UPDATE_DEMAND_COLLECTION_REVERT_FAILURE: 'UPDATE_DEMAND_COLLECTION_REVERT_FAILURE',
	UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_RESET: 'UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_RESET',
	UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_CHANGED: 'UPDATE_DEMAND_COLLECTION_REVERT_RESPONSE_CHANGED',
};
export default demandCollectionActionTypes;
